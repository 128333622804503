import React from "react";
import { Container, makeStyles } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

/* local imports */
import MyAccount from "./MyAccount";
import CreateUser from "./CreateUser";
import EditUser from "./EditUser";

/* federated imports */
/* eslint-disable */
import { TitleBar } from "@/shared-components";
/* eslint-enable */

const useStyles = makeStyles((theme) => ({
	content: {
		marginTop: "2rem",
	},
}));
const UserDetails = (props) => {
	const classes = useStyles();
	const { id: userID } = useParams();
	const { pageType = "edit" } = props;
	const currentUser = useSelector((state) => state.user);
	const [user, setUser] = React.useState(currentUser);

	const handleSetUser = (u) => setUser(u);

	const { register, handleSubmit, errors, control, getValues } = useForm({
		mode: "onBlur",
	});

	// console.log("formState :>> ", formState);

	const fetchContents = (ptype, props) => {
		switch (ptype) {
			case "edit":
				return (
					<EditUser
						{...props}
						userId={userID}
						resourceURL={`/users/${userID}`}
						handleSetUser={handleSetUser}
					/>
				);

			case "myAccount":
				return <MyAccount {...props} user={currentUser} />;

			case "create":
				return <CreateUser {...props} resourceURL="/users" />;
			default:
				break;
		}
	};
	const fetchTitle = (ptype) => {
		switch (ptype) {
			case "myAccount":
				return currentUser.name;
			case "edit":
				return user.name;

			case "create":
				return "Create new user";
			default:
				break;
		}
	};
	const fetchOverline = (ptype) => {
		switch (ptype) {
			case "edit":
				return "Edit User's Settings";

			case "myAccount":
				return "Edit My Settings";

			case "create":
				return "";
			default:
				break;
		}
	};

	return (
		<>
			<TitleBar
				maxWidth="lg"
				renderTitle={() => <div>{fetchTitle(pageType)}</div>}
				overline={fetchOverline(pageType)}
			/>
			<Container maxWidth="lg" className={classes.content}>
				{fetchContents(pageType, {
					register,
					handleSubmit,
					errors,
					control,
					getValues,
				})}
			</Container>
		</>
	);
};

export default UserDetails;
