import React from "react";
import {
	Button,
	FormControl,
	FormHelperText,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	useTheme,
} from "@material-ui/core";
import { Controller } from "react-hook-form";
import { useSelector } from "react-redux";

/* local imports */
import { useFetchOnce, useEntityList } from "../../utils";
import TwoColumnForm from "../shared/TwoColumnForm";
import InlineAlert from "../shared/InlineAlert";

/* federated imports */
/* eslint-disable */
import { Input } from "@/shared-components";
import { axiosPrivate } from "../../utils/api";
/* eslint-enable */

const EditUser = (props) => {
	const {
		register,
		errors,
		control,
		getValues,
		userId,
		handleSubmit,
		resourceURL,
		handleSetUser,
	} = props;
	const theme = useTheme();
	const [result, setResult] = React.useState({ res: null, err: null });
	const [isSubmitting, setIsSubmitting] = React.useState(false);
	const { data: fetchedUser, isLoading: userLoading } = useEntityList({
		resource: resourceURL,
	});
	const authToken = useSelector((state) => state.auth.accessToken);

	const { data: userRoles, isFetching: userRolesLoading } = useFetchOnce({
		resource: `/acl/roles/users/${userId}`,
		params: { organization: fetchedUser?.organization?.id },
	});

	const { data: rolesList, isLoading: rolesLoading } = useEntityList({
		resource: "/acl/roles",
		params: {
			limit: "all",
			organization: fetchedUser?.organization?.id,
		},
		fetchCondition: fetchedUser,
	});

	React.useEffect(() => {
		if (fetchedUser) {
			handleSetUser(fetchedUser?.user);
		}
	}, [fetchedUser]);

	const onSubmit = (data) => {
		// Determine if roles changed
		const arrIsEqual = (a, b) =>
			Array.isArray(a) &&
			Array.isArray(b) &&
			a.length === b.length &&
			a.every((val, idx) => val === b[idx]);

		const submittedRoles = data.user.roles.filter((r) => r.length > 0);
		let prevRoles = [];

		if (userRoles && userRoles.user_roles.length) {
			prevRoles = userRoles.user_roles[0].roles.map((role) => role.id);
		}

		if (!arrIsEqual(submittedRoles, prevRoles)) {
			setIsSubmitting(true);
			axiosPrivate
				.post(`/acl/roles/users/${userId}`, {
					roles: submittedRoles,
					param: {
						organization: fetchedUser?.organization?.id,
					},
				})
				.then((res) => {
					setResult((r) => ({ ...r, res: res.data.data }));
					setIsSubmitting(false);
					setTimeout(() => {
						setResult((r) => ({ ...r, res: null }));
					}, 1500);
				})
				.catch((err) => {
					setResult((r) => ({ ...r, err: err.response.data }));
					setIsSubmitting(false);
					setTimeout(() => {
						setResult((r) => ({ ...r, err: null }));
					}, 3000);
				});
		}

		// Update user params normally
		// quick trick to remove key "roles" and "password" (if unchanged) from submitted object
		let dropKeys = { ...data.user };
		dropKeys.roles = undefined;
		if (!dropKeys.password.length) {
			dropKeys.password = undefined;
		}
		dropKeys = JSON.parse(JSON.stringify(dropKeys));
		setIsSubmitting(true);
		axiosPrivate
			.put(resourceURL, {
				...dropKeys,
				params: {
					organization: fetchedUser?.organization?.id,
				},
			})
			.then((res) => {
				setResult((r) => ({ ...r, res: res.data.data }));
				setIsSubmitting(false);
				setTimeout(() => {
					setResult((r) => ({ ...r, res: null }));
				}, 1500);
			})
			.catch((err) => {
				setResult((r) => ({ ...r, err: err.response.data }));
				setIsSubmitting(false);
				setTimeout(() => {
					setResult((r) => ({ ...r, err: null }));
				}, 3000);
			});
	};

	const selectedRoles = () => {
		if (userRoles && userRoles?.user_roles.length) {
			return userRoles.user_roles[0].roles.map((role) => role.id);
		}
		if (rolesList && !userRoles) {
			return [rolesList.roles.map((r) => r.id)[0]];
		}
		return [""];
	};

	return (
		<>
			<TwoColumnForm
				title="Basics"
				subtitle="Manage user credentails"
				handleSubmit={handleSubmit}
				onSubmit={onSubmit}
				isLoading={userLoading || rolesLoading || userRolesLoading}
				cardActions={() => (
					<>
						<Button
							variant="contained"
							color="primary"
							type="submit"
							disabled={isSubmitting}
						>
							Save
						</Button>
					</>
				)}
			>
				<InlineAlert type="success" showAlert={result.res}>
					{result.res?.message
						? result.res.message
						: "Your changes have been saved."}
				</InlineAlert>
				<InlineAlert type="error" showAlert={result.err}>
					{result.err?.message
						? result.err.message
						: "An error occured while saving, please contact an Administrator."}
				</InlineAlert>
				<Grid container spacing={1}>
					<Grid item xs={12} md={7}>
						<Input
							ref={register({
								required: {
									value: true,
									message: "Don't forget to fill out your name!",
								},
							})}
							autoComplete="name"
							label="Name"
							name="user.name"
							type="text"
							defaultValue={fetchedUser?.user?.name || ""}
							margin="dense"
							error={!!errors?.user?.name}
							helperText={errors?.user?.name?.message}
						/>
					</Grid>
					<Grid item xs={12} md={5}>
						<Input
							ref={register({
								required: {
									value: true,
									message: "An email address is required",
								},
								pattern: {
									value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
									message:
										"Hmmm... that email address doesn't look quite right 🤔",
								},
							})}
							autoComplete="email"
							label="Work email"
							name="user.email"
							type="text"
							defaultValue={fetchedUser?.user?.email || ""}
							margin="dense"
							error={!!errors?.user?.email}
							helperText={errors?.user?.email?.message}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<Input
							ref={register({
								minLength: {
									value: 6,
									message: "Password should be at least 6 characters long",
								},
							})}
							autoComplete="password"
							label="Password"
							name="user.password"
							type="password"
							margin="dense"
							error={!!errors?.user?.password}
							helperText={errors?.user?.password?.message}
						/>
					</Grid>
					<Grid item xs={12} md={4}>
						<FormControl
							fullWidth
							variant="outlined"
							style={{ marginTop: theme.spacing(1) }}
							error={!!errors?.user?.roles}
						>
							<InputLabel id="role-select">Roles</InputLabel>
							<Controller
								as={
									<Select
										multiple
										fullWidth
										labelId="role-select"
										label="Role"
										margin="dense"
									>
										<MenuItem disabled value="none">
											Select Role(s)
										</MenuItem>
										{rolesList?.roles?.map((role) => (
											<MenuItem key={role.id} value={role.id}>
												{role.name}
											</MenuItem>
										))}
									</Select>
								}
								label="Role"
								name="user.roles"
								defaultValue={selectedRoles()}
								control={control}
								rules={{
									required: {
										value: true,
									},
									validate: () => {
										return Boolean(
											getValues("user.roles").filter((r) => r.length > 0).length
										);
									},
								}}
							/>
							<FormHelperText>
								{!!errors?.user?.roles && "Must select at least one role"}
							</FormHelperText>
						</FormControl>
					</Grid>
				</Grid>
			</TwoColumnForm>
		</>
	);
};

export default EditUser;
