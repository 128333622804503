import React from "react";
import { Container, Divider, makeStyles } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import * as _datefns from "date-fns";
import { ErrorBoundary } from "react-error-boundary";
/* local imports */
import ProjectSettings from "./components/ProjectSettings";
import ServiceSettings from "./components/ServiceSettings";

/* federated imports */
/* eslint-disable */
import { TitleBar } from "@/shared-components";
/* eslint-enable */

const useStyles = makeStyles(() => ({
	content: {
		marginTop: "2rem",
	},
}));

const ProjectDetails = () => {
	const classes = useStyles();
	const { id: projectId } = useParams();
	const project = useSelector((state) =>
		state.user.projects.find((p) => p.id === projectId)
	);

	return (
		<>
			<TitleBar
				maxWidth="lg"
				renderTitle={() => (
					<div>{`${project?.name} in ${project?.city}, ${project?.country}`}</div>
				)}
				overline={`Last updated ${_datefns.formatDistanceToNow(
					new Date(project?.updated_at || null)
				)} ago`}
			/>
			<Container maxWidth="lg" className={classes.content}>
				<ProjectSettings />
				<Divider style={{ margin: "3rem 0" }} />
				<ErrorBoundary>
					<ServiceSettings />
				</ErrorBoundary>
			</Container>
		</>
	);
};

export default ProjectDetails;
