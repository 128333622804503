import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import theme from "@/theme";

const useStyles = makeStyles(() => ({
	formRoot: {
		width: "100%",
		marginTop: theme.spacing(1),
	},
}));

const Form = ({ children, className, ...props }) => {
	const styles = useStyles();

	return (
		<form className={clsx(styles.formRoot, className)} noValidate {...props}>
			{children}
		</form>
	);
};

export default Form;
