import React from "react";
import {
	Button,
	FormControl,
	FormHelperText,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	useTheme,
} from "@material-ui/core";
import { Controller } from "react-hook-form";
import { useSelector } from "react-redux";
/* local imports */
import TwoColumnForm from "../shared/TwoColumnForm";
import { useEntityList, useFetchOnce } from "../../utils";
import InlineAlert from "../shared/InlineAlert";

/* federated imports */
/* eslint-disable */
import { Input } from "@/shared-components";
import { axiosPrivate } from "../../utils/api";
/* eslint-enable */

const MyAccount = (props) => {
	const { register, errors, control, handleSubmit, getValues, user } = props;
	const orgId = useSelector((state) => state.user.organization.id);
	const theme = useTheme();

	const [isSubmitting, setIsSubmitting] = React.useState(false);
	const [result, setResult] = React.useState({ res: null, err: null });

	const { data: rolesList, isLoading: rolesLoading } = useEntityList({
		resource: "/acl/roles",
		params: {
			limit: "all",
			organization: orgId,
		},
	});

	const { data: userRoles, isFetching: userRolesLoading } = useFetchOnce({
		resource: `/acl/roles/users/${user.id}`,
		params: { organization: orgId },
	});

	const onSubmit = (data) => {
		const handleUpdate = (method, url, params, body) =>
			axiosPrivate(url, { method, params, data: body })
				.then((res) => {
					setResult((r) => ({ ...r, res: res.data.data }));
					setIsSubmitting(false);

					setTimeout(() => {
						setResult((r) => ({ ...r, res: null }));
					}, 1500);
				})
				.catch((err) => {
					setResult((r) => ({ ...r, err: err }));
					setIsSubmitting(false);

					setTimeout(() => {
						setResult((r) => ({ ...r, err: null }));
					}, 3000);
				});

		// Determine if roles changed
		const arrIsEqual = (a, b) =>
			Array.isArray(a) &&
			Array.isArray(b) &&
			a.length === b.length &&
			a.every((val, idx) => val === b[idx]);

		const submittedRoles = data.user.roles.filter((r) => r.length > 0);
		let prevRoles = [];

		if (userRoles && userRoles.user_roles.length) {
			prevRoles = userRoles.user_roles[0].roles.map((role) => role.id);
		}

		if (!arrIsEqual(submittedRoles, prevRoles)) {
			setIsSubmitting(true);
			handleUpdate("POST", `/acl/roles/users/${user.id}`, params, {
				roles: submittedRoles,
			});
		}

		// Update user params normally
		// quick trick to remove key "roles" and "password" (if unchanged) from submitted object
		let dropKeys = { ...data.user };
		dropKeys.roles = undefined;
		if (!dropKeys.password.length) {
			dropKeys.password = undefined;
		}
		dropKeys = JSON.parse(JSON.stringify(dropKeys));
		setIsSubmitting(true);
		const params = { organization: orgId };
		handleUpdate("PUT", `/users/${user.id}`, params, dropKeys);
	};

	const selectedRoles = () => {
		if (userRoles && userRoles?.user_roles.length) {
			return userRoles.user_roles[0].roles.map((role) => role.id);
		}
		if (rolesList && !userRoles) {
			return [rolesList.roles.map((r) => r.id)[0]];
		}
		return [""];
	};

	return (
		<>
			<TwoColumnForm
				title="Basics"
				subtitle="Manage user credentails"
				handleSubmit={handleSubmit}
				onSubmit={onSubmit}
				isLoading={rolesLoading || userRolesLoading}
				cardActions={() => (
					<>
						<Button
							variant="contained"
							color="primary"
							type="submit"
							disabled={isSubmitting}
						>
							Save
						</Button>
					</>
				)}
			>
				<InlineAlert type="success" showAlert={result.res}>
					{result.res?.message
						? result.res.message
						: "Your changes have been saved."}
				</InlineAlert>
				<InlineAlert type="error" showAlert={result.err}>
					{result.err?.message
						? result.err.message
						: "An error occured while saving, please contact an Administrator."}
				</InlineAlert>
				<Grid container spacing={1}>
					<Grid item xs={12} md={7}>
						<Input
							ref={register({
								required: {
									value: true,
									message: "Name cannot be left empty.",
								},
							})}
							autoComplete="name"
							label="Name"
							name="user.name"
							type="text"
							defaultValue={user?.name || ""}
							margin="dense"
							error={!!errors?.user?.name}
							helperText={errors?.user?.name?.message}
						/>
					</Grid>
					<Grid item xs={12} md={5}>
						<Input
							ref={register({
								required: {
									value: true,
									message: "An email address is required",
								},
								pattern: {
									value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
									message:
										"Hmmm... that email address doesn't look quite right 🤔",
								},
							})}
							autoComplete="email"
							label="Work email"
							name="user.email"
							type="text"
							defaultValue={user?.email || ""}
							margin="dense"
							error={!!errors?.user?.email}
							helperText={errors?.user?.email?.message}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<Input
							ref={register({
								minLength: {
									value: 6,
									message: "Password should be at least 6 characters long",
								},
							})}
							autoComplete="password"
							label="Password"
							name="user.password"
							type="password"
							margin="dense"
							error={!!errors?.user?.password}
							helperText={errors?.user?.password?.message}
						/>
					</Grid>
					<Grid item xs={12} md={4}>
						<FormControl
							fullWidth
							variant="outlined"
							style={{ marginTop: theme.spacing(1) }}
							error={!!errors?.user?.roles}
						>
							<InputLabel id="role-select">Roles</InputLabel>
							<Controller
								as={
									<Select
										multiple
										fullWidth
										labelId="role-select"
										label="Role"
										margin="dense"
									>
										<MenuItem disabled value="none">
											Select Role(s)
										</MenuItem>
										{rolesList?.roles?.map((role) => (
											<MenuItem key={role.id} value={role.id}>
												{role.name}
											</MenuItem>
										))}
									</Select>
								}
								name="user.roles"
								defaultValue={selectedRoles()}
								control={control}
								rules={{
									required: {
										value: true,
									},
									validate: () => {
										return Boolean(
											getValues("user.roles").filter((r) => r.length > 0).length
										);
									},
								}}
							/>
							<FormHelperText>
								{!!errors?.user?.roles && "Must select at least one role"}
							</FormHelperText>
						</FormControl>
					</Grid>
				</Grid>
			</TwoColumnForm>
		</>
	);
};

export default MyAccount;
