import React from "react";
import ReactDOM from "react-dom";

/* local imports */
import Shell from "./Shell";

/* federated imports */
/* eslint-disable */
import { store } from "@/redux";
import theme from "@/theme";
/* eslint-enable */

const App = () => <Shell />;

ReactDOM.render(<App />, document.getElementById("app"));
