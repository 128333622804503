import React from "react";
import { LinearProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import MaterialTable from "material-table";
import * as _datefns from "date-fns";

/* icon imports */
import { useFetchOnce, useEntityList, tableIcons } from "../../utils";

/* local imports */
import { useStyles } from "./styles";

const RoleTemplatesTables = () => {
	const history = useHistory();
	const classes = useStyles();

	const { data: perms, isFetching: permsLoading } = useFetchOnce({
		resource: "/acl/permissions",
		params: { limit: "all", grouped: true },
	});
	const { data: rolesList, isLoading: rolesLoading } = useEntityList({
		resource: "/acl/roles",
		params: {
			limit: 10,
			page: 1,
		},
	});

	return (
		<MaterialTable
			title=""
			icons={tableIcons}
			columns={[
				{ field: "name", title: "Role Name" },
				{
					field: "permissions",
					title: "Authority Level",
					// eslint-disable-next-line
					render: (rowData) => {
						const authLevel = Math.round(
							(rowData.permissions.length / perms?.count) * 100
						);
						const authColor =
							authLevel > 90
								? classes.highAuth
								: authLevel > 60
								? classes.medAuth
								: classes.lowAuth;
						return (
							<LinearProgress
								variant="determinate"
								value={authLevel}
								classes={{
									colorPrimary: classes.barColor,
									barColorPrimary: authColor,
								}}
							/>
						);
					},
				},
				{
					field: "created_at",
					title: "Created On",
					// eslint-disable-next-line
					render: (rowData) => (
						<div>
							{_datefns.formatDistanceToNow(new Date(rowData.updated_at))} ago
						</div>
					),
				},
			]}
			actions={[
				{
					icon: tableIcons.Add,
					tooltip: "Add New Template",
					isFreeAction: true,
					onClick: () => history.push("/home/role/create"),
				},
			]}
			options={{
				search: false,
				actionsColumnIndex: 10,
				// exportButton: true,
				// searchFieldAlignment: "left",
			}}
			onRowClick={(e, rowData) =>
				history.push({
					pathname: `/home/role/${rowData.id}`,
					state: { name: rowData.name, permissions: rowData.permissions },
				})
			}
			localization={{
				header: {
					actions: "",
				},
			}}
			isLoading={rolesLoading || permsLoading}
			data={rolesList?.roles || []}
		/>
	);
};

export default RoleTemplatesTables;
