import React from "react";
import { Button, Container, Grid, makeStyles } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import qs from "qs";

/* local imports */
import TwoColumnForm from "../shared/TwoColumnForm";
import InlineAlert from "../shared/InlineAlert";

/* federated imports */
/* eslint-disable */
import { TitleBar, Input } from "@/shared-components";
import { refetchDatasets } from "@/redux";
import { axiosPrivate } from "../../utils/api";
/* eslint-enable */

const useStyles = makeStyles(() => ({
	content: {
		marginTop: "2rem",
	},
}));

const CreateDataset = () => {
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const authToken = useSelector((state) => state.auth.accessToken);
	const orgId = useSelector((state) => state.user.organization.id);
	const [result, setResult] = React.useState({ res: null, err: null });

	const onSubmit = (data) => {
		axiosPrivate
			.post("/port/datasets", {
				...data.dataset,
				params: { organization_id: orgId },
			})
			.then((data) => {
				console.log("POST return :>> ", data);
				dispatch(refetchDatasets({ orgId }));
				setResult((r) => ({ ...r, res: data }));
				setTimeout(() => {
					history.push("/home");
				}, 1500);
			})
			.catch((err) => {
				setResult((r) => ({ ...r, err: err.response.data }));
				setTimeout(() => {
					setResult((r) => ({ ...r, err: null }));
				}, 2000);
			});
	};
	const { register, handleSubmit, errors } = useForm({
		mode: "onBlur",
	});

	return (
		<>
			<TitleBar
				renderTitle={() => <div>Create new dataset</div>}
				maxWidth="lg"
				overline=""
			/>
			<Container maxWidth="lg" className={classes.content}>
				<TwoColumnForm
					title="Basic"
					subtitle="Initial dataset parameters"
					handleSubmit={handleSubmit}
					onSubmit={onSubmit}
					cardActions={() => (
						<>
							<Button variant="contained" color="primary" type="submit">
								Save
							</Button>
						</>
					)}
				>
					<InlineAlert type="success" showAlert={result.res}>
						{result.res?.message ? result.res.message : "Dataset created."}
					</InlineAlert>
					<InlineAlert type="error" showAlert={result.err}>
						{result.err?.message
							? result.err.message
							: "An error occured while saving, please contact an Administrator."}
					</InlineAlert>
					<Grid container spacing={1}>
						<Grid item xs={12} md={8}>
							<Input
								margin="dense"
								ref={register({
									required: {
										value: true,
										message: "Dataset name cannot be empty.",
									},
								})}
								label="Dataset name"
								name="dataset.name"
								type="text"
								error={!!errors.dataset?.name}
								helperText={errors?.dataset?.name?.message}
							/>
						</Grid>
					</Grid>
				</TwoColumnForm>
			</Container>
		</>
	);
};

export default CreateDataset;
