import React from "react";
import { Button, Grid } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import qs from "qs";
/* local imports */
import TwoColumnForm from "../../shared/TwoColumnForm";
import InlineAlert from "../../shared/InlineAlert";

/* federated imports */
/* eslint-disable */
import { Input } from "@/shared-components";
import { refetchDatasets } from "@/redux";
import { axiosPrivate } from "../../../utils/api";
/* eslint-enable */

const DatasetSettings = () => {
	const dispatch = useDispatch();
	const { id: datasetId } = useParams();
	const dataset = useSelector((state) =>
		state.user.datasets.find((d) => d.id === datasetId)
	);
	const authToken = useSelector((state) => state.auth.accessToken);
	const orgId = useSelector((state) => state.user.organization.id);

	const { register, handleSubmit, errors } = useForm({
		mode: "onBlur",
	});

	const [result, setResult] = React.useState({ res: null, err: null });

	const onSubmit = (data) => {
		axiosPrivate
			.put(`/port/datasets/${datasetId}`, {
				...data.dataset,
				params: { organization_id: orgId },
			})
			.then((data) => {
				console.log("PUT return :>> ", data);
				dispatch(refetchDatasets({ orgId }));
				setResult((r) => ({ ...r, res: data }));
				setTimeout(() => {
					setResult((r) => ({ ...r, res: null }));
				}, 1500);
			})
			.catch((err) => {
				setResult((r) => ({ ...r, err: err.response.data }));
				setTimeout(() => {
					setResult((r) => ({ ...r, err: null }));
				}, 2000);
			});
	};
	return (
		<TwoColumnForm
			title="Basics"
			subtitle="Manage dataset parameters"
			handleSubmit={handleSubmit}
			onSubmit={onSubmit}
			cardActions={() => (
				<>
					<Button variant="contained" color="primary" type="submit">
						Save
					</Button>
				</>
			)}
		>
			<InlineAlert type="success" showAlert={result.res}>
				{result.res?.message
					? result.res.message
					: "Your changes have been saved."}
			</InlineAlert>
			<InlineAlert type="error" showAlert={result.err}>
				{result.err?.message
					? result.err.message
					: "An error occured while saving, please contact an Administrator."}
			</InlineAlert>
			<Grid container spacing={1}>
				<Grid item xs={12} md={8}>
					<Input
						margin="dense"
						defaultValue={dataset?.name}
						ref={register({
							required: {
								value: true,
								message: "Dataset name cannot be empty.",
							},
						})}
						label="Dataset name"
						name="dataset.name"
						type="text"
						error={!!errors.dataset?.name}
						helperText={errors?.dataset?.name?.message}
					/>
				</Grid>
			</Grid>
		</TwoColumnForm>
	);
};

export default DatasetSettings;
