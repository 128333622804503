import React from "react";
import { Typography } from "@material-ui/core";
import { GeoJSON, Popup } from "react-leaflet";
import { NoMapDataAvailable } from "../Leaflet";

const getGeoJSONMap = (args) => {
	const { geometry } = args;
	if (Array.isArray(geometry)) {
		return geometry.map((g, idx) => (
			<GeoJSON key={g?.properties?.id || g?.id || idx} data={g?.geometry}>
				<Popup>
					<div>
						<Typography variant="h6">
							{g?.name ||
								g?.name_local ||
								g?.properties?.name ||
								g?.properties?.name_local}
						</Typography>
					</div>
				</Popup>
			</GeoJSON>
		));
	}
	if (geometry) {
		return <GeoJSON data={geometry} />;
	}
	return <NoMapDataAvailable />;
};

export default getGeoJSONMap;
