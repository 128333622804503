import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: 32,
		height: "100%",
		[theme.breakpoints.up("sm")]: {
			paddingTop: "3rem",
		},
		overflowX: "hidden",
	},
	content: {
		marginTop: "2rem",
	},
	lowAuth: {
		backgroundColor: theme.palette.twind.red[300],
	},
	medAuth: {
		backgroundColor: theme.palette.twind.orange[300],
	},
	highAuth: {
		backgroundColor: theme.palette.twind.teal[300],
	},
	barColor: {
		backgroundColor: theme.palette.twind.gray[200],
	},
}));
