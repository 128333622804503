import React, { createContext, useState, useContext } from "react";

const RegistrationContext = createContext();

const RegistrationProvider = ({ children }) => {
	const [data, setData] = useState({});

	const setValues = (values) => {
		setData((prevData) => ({
			...prevData,
			...values,
		}));
	};
	return (
		<RegistrationContext.Provider value={{ data, setValues }}>
			{children}
		</RegistrationContext.Provider>
	);
};

export default RegistrationProvider;

export const useRegistration = () => useContext(RegistrationContext);
