import axios from "axios";
import { store } from "../redux";
import { asyncRefreshToken } from "../redux/slices/auth";
import jwtDecode from "jwt-decode";

export const baseURL = import.meta.env.VITE_REACT_APP_API;

export const axiosPublic = axios.create({
	baseURL,
	withCredentials: true,
	headers: {
		"Content-Type": "application/json",
		"x-client-type": "RouteLab-v1.0-alpha",
	},
});

export const getAxiosPrivate = () => {
	const axiosPrivate = axios.create({
		baseURL,
		withCredentials: true,
		headers: {
			"Content-Type": "application/json",
			"x-client-type": "RouteLab-v1.0-alpha",
		},
	});

	axiosPrivate.interceptors.request.use(
		async function (config) {
			// Add bearer token to headers
			// Check token expiration
			const accessToken = store?.getState()?.auth?.accessToken;

			const decodedToken = jwtDecode(accessToken);
			if (decodedToken.exp * 1000 < new Date().getTime()) {
				console.log(
					"Access token expired. Requesting new token using refresh token "
				);
				// Update the access token using the refresh_token endpoint
				await store.dispatch(asyncRefreshToken());
			} else {
				console.log("Access token valid.");
			}
			config.headers = {
				...config.headers,
				Authorization: `Bearer ${store?.getState()?.auth?.accessToken}`,
			};
			// Do something before request is sent
			return config;
		},
		function (error) {
			// Do something with request error
			return Promise.reject(error);
		}
	);

	return axiosPrivate;
};
