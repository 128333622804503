import React from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";

const FormCheckbox = ({ name, value, register, ...other }) => (
	<FormControlLabel
		value={value?._id}
		control={<Checkbox color="primary" />}
		name={name}
		inputRef={register}
		label={value?.name}
		{...other}
	/>
);

export default FormCheckbox;
