import React from "react";
import clsx from "clsx";
import {
	Button,
	makeStyles,
	Grid,
	Container,
	Typography,
	Card,
	Box,
	LinearProgress,
	Tooltip,
	Fade,
} from "@material-ui/core";
import { CheckCircleOutline } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ErrorBoundary } from "react-error-boundary";

/* icon imports */

/* local imports */
import useEntityList from "../../utils/useEntityList";
import ObserverLogo from "../../assets/observerLogo.svg";
import LensLogo from "../../assets/lensLogo.svg";
import PortLogo from "../../assets/portLogo.svg";

/* federated imports */
/* eslint-disable */
// import theme from "@/theme";
import {
	TitleBar,
	SearchInput,
	IconMenuButton,
	TextMenuButton,
} from "@/shared-components";

import { setHasSidebar, setActiveProject, setActiveDataset } from "@/redux";
/* eslint-enable */

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: 32,
		height: "100%",
		[theme.breakpoints.up("sm")]: {
			paddingTop: "3rem",
		},
		overflowX: "hidden",
	},
	content: {
		marginTop: "2rem",
	},
	cardRoot: {
		minHeight: "7rem",
	},
	cardContainer: {
		padding: "0.75rem 1.5rem",
	},
	primaryProgressColor: {
		backgroundColor: theme.palette.twind.teal[500],
	},
	successColor: {
		color: theme.palette.twind.green[500],
	},
	barColor: {
		backgroundColor: theme.palette.twind.gray[200],
	},
}));

const ObserverStats = () => {
	const classes = useStyles();
	const activeProjectId = useSelector((state) => state.user.activeProject?.id);
	const {
		data: observerStats,
		isLoading: observerStatsLoading,
		isError: observerStatsError,
	} = useEntityList({
		resource: `/observer/stats/overall`,
		params: { project_id: activeProjectId },
	});
	const stats = [
		{
			label: "Onboard Surveys",
			active: observerStats?.required_onboard_count > 0,
			count: observerStats?.onboard_count,
			total: observerStats?.required_onboard_count,
		},

		{
			label: "Frequency Surveys",
			active: observerStats?.required_frequency_count > 0,
			count: observerStats?.frequency_count,
			total: observerStats?.required_frequency_count,
		},

		{
			label: "Origin-Destination Surveys",
			active: observerStats?.required_od_count > 0,
			count: observerStats?.od_submitted_count,
			total: observerStats?.required_od_count,
		},
		{
			label: "Household Surveys",
			active: observerStats?.required_td_count > 0,
			count: observerStats?.td_submitted_count,
			total: observerStats?.required_td_count,
		},
	];

	return (
		<div style={{ marginTop: "0.5rem" }}>
			{observerStatsLoading ? (
				<>
					<Skeleton width="70%" />
					<Skeleton width="70%" />
					<div>
						<Skeleton>
							<Typography variant="overline">OnBoard Surveys</Typography>
						</Skeleton>
						<Skeleton width="100%" />
					</div>
					<div style={{ marginTop: "0.5rem" }}>
						<Skeleton>
							<Typography variant="overline">Frequency Surveys</Typography>
						</Skeleton>
						<Skeleton width="100%" />
					</div>
				</>
			) : !observerStatsError ? (
				<>
					<div>
						<Typography variant="body1">
							{`${observerStats?.route_count ?? 0} unique routes & ${
								observerStats?.terminal_count ?? 0
							} terminals observed`}
						</Typography>
					</div>
					<div style={{ marginTop: "0.5rem" }}>
						{stats.map(({ active, label, count, total }) =>
							active ? (
								<div key="label" style={{ marginTop: "0.5rem" }}>
									<Typography variant="overline">{label}</Typography>
									<LinearProgressWithLabel
										value={Math.round((count / total) * 100)}
										progressColor={classes.barColor}
										barColor={classes.primaryProgressColor}
									/>
								</div>
							) : null
						)}
					</div>
				</>
			) : null}
		</div>
	);
};
const Services = () => {
	const classes = useStyles();
	const activeProjectId = useSelector((state) => state.user.activeProject?.id);
	const activeDatasetId = useSelector((state) => state.user.activeDataset?.id);
	const projectList = useSelector((state) => state?.user?.projects);
	const datasetList = useSelector((state) => state?.user?.datasets);

	const isObserverActive = !!projectList?.length;
	const isPortActive = !!datasetList?.length;

	const dispatch = useDispatch();

	React.useEffect(() => {
		dispatch(setHasSidebar(false));
	}, [dispatch]);

	return (
		<>
			<TitleBar
				maxWidth="lg"
				renderTitle={() => <div>Services</div>}
				renderActions={() => (
					<SearchInput InputProps={{ placeholder: "Find Service" }} />
				)}
			/>
			<Container maxWidth="lg" className={classes.content}>
				<Grid container spacing={5}>
					<Grid item container direction="column" spacing={1} xs={12} md={6}>
						<Grid item>
							<Typography variant="overline">CURRENTLY ACTIVE</Typography>
						</Grid>
						<Grid item container spacing={2} direction="row">
							{isObserverActive ? (
								<Grid item xs={12}>
									<ServiceCard
										name="observer"
										logoSrc={ObserverLogo}
										subtitle=""
										linkIsButton={true}
										buttonText="Go to Project"
										linkTo={
											activeProjectId
												? `/${activeProjectId}/observer/home`
												: `/home/project/create`
										}
									>
										<div style={{ marginTop: "0.5rem" }}>
											<ProjectSwitcher />
										</div>
										<ErrorBoundary>
											<ObserverStats />
										</ErrorBoundary>
									</ServiceCard>
								</Grid>
							) : null}
							{isPortActive ? (
								<Grid item xs={12}>
									<ServiceCard
										name="port"
										logoSrc={PortLogo}
										linkIsButton={true}
										buttonText="Open Dataset"
										linkTo={
											activeDatasetId
												? `/${activeDatasetId}/port`
												: `/home/dataset/create`
										}
										subtitle="Integrated geospatial data management"
									>
										<div style={{ marginTop: "0.5rem" }}>
											<DatasetSwitcher />
										</div>
									</ServiceCard>
								</Grid>
							) : null}
							{/* <Grid item xs={12}>
                <ServiceCard
                  name="snapper"
                  linkTo={`/${activeProjectId}/snapper`}
                  logoSrc={LensLogo}
                  subtitle="50 surveys processed"
                />
              </Grid> */}
							{/* <Grid item xs={12}>
                <ServiceCard
                  name="snapper"
                  linkTo={`/${activeProjectId}/snapper`}
                  logoSrc={LensLogo}
                  subtitle="50 surveys processed"
                />
              </Grid> */}
							{/* <Grid item xs={12}>
                <ServiceCard
                  name="lens"
                  linkTo={`/${activeProjectId}/lens`}
                  logoSrc={LensLogo}
                  subtitle="50 videos processed, 7 videos queued"
                />
              </Grid> */}
						</Grid>
					</Grid>
					<Grid item container direction="column" spacing={1} xs={12} md={6}>
						<Grid item>
							<Typography variant="overline">EXPLORE</Typography>
						</Grid>
						<Grid item container spacing={2} direction="row">
							<Grid item xs={12}>
								<ServiceCard
									name="lens"
									logoSrc={LensLogo}
									linkIsButton={false}
									linkTo="/"
									subtitle="AI-powered traffic video analysis"
								/>
							</Grid>
							{!isPortActive ? (
								<Grid item xs={12}>
									<ServiceCard
										name="port"
										linkIsButton={false}
										linkTo="/home/dataset/create"
										logoSrc={PortLogo}
										subtitle="Integrated geospatial data management"
									/>
								</Grid>
							) : null}
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</>
	);
};

export default Services;

const ServiceCard = ({
	className,
	logoSrc,
	subtitle,
	linkIsButton,
	linkTo,
	buttonText,
	children,
}) => {
	const classes = useStyles();
	const [cardElevation, setCardElevation] = React.useState(3);
	const onMouseOver = () => setCardElevation(6);
	const onMouseOut = () => setCardElevation(3);
	const cardContents = (
		<Card
			className={clsx(classes.cardRoot, className)}
			elevation={cardElevation}
			onMouseOver={onMouseOver}
			onMouseOut={onMouseOut}
		>
			<Grid container direction="column" className={classes.cardContainer}>
				<Grid item>
					<img src={logoSrc} alt="service-title" />
				</Grid>
				{subtitle ? (
					<Grid item>
						<Typography variant="subtitle1">{subtitle}</Typography>
					</Grid>
				) : null}
				{children}
				{linkIsButton ? (
					<Grid
						item
						container
						style={{ justifyContent: "flex-end", marginTop: "1rem" }}
					>
						<Grid item>
							<Link to={linkTo} style={{ textDecoration: "none" }}>
								<Button color={classes.primaryProgressColor} variant="outlined">
									{buttonText}
								</Button>
							</Link>
						</Grid>
					</Grid>
				) : null}
			</Grid>
		</Card>
	);

	return (
		<>
			{linkIsButton ? (
				cardContents
			) : (
				<Link to={linkTo} style={{ textDecoration: "none" }}>
					{cardContents}
				</Link>
			)}
		</>
	);
};

const ProjectSwitcher = () => {
	const history = useHistory();

	const dispatch = useDispatch();
	const activeProject = useSelector((state) => state?.user?.activeProject);
	const projectList = useSelector((state) => state?.user?.projects);
	const projectDropdownItems = projectList.map((p) => ({
		...p,
		name: `${p.name} in ${p.city}, ${p.country}`,
		handleItemClick: () => {
			dispatch(setActiveProject(p));
		},
		handleSettingsClick: () => history.push(`/home/project/${p.id}`),
	}));

	return (
		<TextMenuButton
			buttonSize="large"
			buttonText={`${activeProject?.name ?? "Create New Project"}`}
			items={projectDropdownItems}
			handleAddNew={() => history.push("/home/project/create")}
		/>
	);
};
const DatasetSwitcher = ({ service }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const activeDataset = useSelector((state) => state?.user?.activeDataset);
	const datasetList = useSelector((state) => state?.user?.datasets || []);
	const datasetDropdownItems = datasetList.map((d) => ({
		...d,
		name: `${d.name}`,
		handleItemClick: () => {
			dispatch(setActiveDataset(d));
		},
		handleSettingsClick: () => history.push(`/home/dataset/${d.id}`),
	}));

	return (
		<TextMenuButton
			buttonSize="large"
			buttonText={`${activeDataset?.name ?? "Create New Dataset"}`}
			items={datasetDropdownItems}
			addNewText="Add new Dataset"
			handleAddNew={() => history.push("/home/dataset/create")}
		/>
	);
};

const LinearProgressWithLabel = (props) => {
	const { progressColor, barColor, value, ...rest } = props;
	return (
		<Box display="flex" alignItems="center">
			<Box width="100%" mr={1}>
				<LinearProgress
					variant="determinate"
					classes={{
						colorPrimary: progressColor,
						barColorPrimary: barColor,
					}}
					value={Math.min(100, value)}
					{...rest}
				/>
			</Box>
			<Box minWidth={35}>
				<div
					style={{
						display: "flex",
						alignItems: "center",
					}}
				>
					<Typography variant="body2" color="textSecondary">{`${Math.round(
						value
					)}%`}</Typography>
					{value >= 100 ? (
						<Tooltip
							title="Target Achieved Successfully!"
							placement="top"
							arrow
							TransitionComponent={Fade}
						>
							<CheckCircleOutline
								style={{ fontSize: "15px", margin: "0 5px" }}
							/>
						</Tooltip>
					) : null}
				</div>
			</Box>
		</Box>
	);
};
