import React from "react";
import {
	CircleMarker,
	Popup,
	useMapEvents,
	FeatureGroup,
	GeoJSON,
} from "react-leaflet";
import { Button } from "@material-ui/core";
import { toBBOX } from "country-to-bbox";

const getDebugMap = (args) => {
	const { handleFetchNearby, geometry = null, country } = args;

	const geoCoded = toBBOX(country);
	/* geoCoded format is [south, west, north, east]. 
  Leaflet requires NorthWest point -> SouthEast point */
	const countryBbox =
		[
			[geoCoded[1], geoCoded[2]],
			[geoCoded[3], geoCoded[0]],
		] || [];

	const [markerPos, setMarkerPos] = React.useState(L.latLng(30.059, 31.223));

	React.useEffect(() => {
		if (country) {
			const boundingRect = L.rectangle(countryBbox).addTo(map);
			const newPos = boundingRect.getCenter();
			setMarkerPos(newPos);
			handleFetchNearby([newPos.lat, newPos.lng]);
			map.fitBounds(boundingRect.getBounds());
			map.removeLayer(boundingRect);
		}
	}, [country]);

	const map = useMapEvents({
		click(e) {
			setMarkerPos(e.latlng);
			map.flyTo(e.latlng, map.getZoom());
		},
	});

	return (
		<FeatureGroup>
			<CircleMarker
				center={markerPos}
				radius={15}
				color={"#0043ce"}
				fillOpacity={0.9}
				opacity={0.3}
				weight={35}
			>
				<Popup minWidth={90}>
					<Button
						variant="outlined"
						size="small"
						color="primary"
						onClick={() => handleFetchNearby([markerPos.lat, markerPos.lng])}
					>
						Fetch Nearby Assignments
					</Button>
				</Popup>
			</CircleMarker>
			{geometry ? (
				<GeoJSON key={JSON.stringify(geometry)} data={geometry} />
			) : null}
		</FeatureGroup>
	);
};

export default getDebugMap;
