import React from "react";
import {
	makeStyles,
	Grid,
	Container,
	Typography,
	Divider,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

/* local imports */
import RolesTable from "../Roles/RolesTable";
import UsersTable from "../Users/UsersTable";
import ProjectsTable from "../Project/ProjectsTable";
import useEntityList from "../../utils/useEntityList";
import OrganizationSettings from "./OrganizationSettings";

/* federated imports */
/* eslint-disable */
import { TitleBar } from "@/shared-components";
/* eslint-enable */

const useStyles = makeStyles(() => ({
	content: {
		marginTop: "2rem",
	},
}));

const OrganizationDetails = () => {
	const classes = useStyles();
	const [preload, setPreload] = React.useState({});
	const isAdmin = useSelector((state) => state.user.isAdmin);

	const { id: orgID } = useParams();
	const { data: org, isLoading: orgLoading } = useEntityList({
		resource: `/organizations/${orgID}`,
		params: { organization: orgID },
	});
	console.log("preload :>> ", org);

	React.useEffect(() => {
		if (org) {
			setPreload(org);
		}
	}, [org]);
	return (
		<>
			<TitleBar
				maxWidth="lg"
				renderTitle={() => (
					<div>{org?.organization?.name || "Organization_Name"}</div>
				)}
				overline="Organization Details"
				// renderActions={() => <SearchInput placeholder="Find Service" />}
			/>
			<Container maxWidth="lg" className={classes.content}>
				<OrganizationSettings
					preload={preload}
					setPreload={setPreload}
					isLoading={orgLoading}
					orgID={orgID}
				/>
				<Grid container spacing={8}>
					<Grid item xs={12} md={2}>
						<Typography variant="button">Projects</Typography>
					</Grid>
					<Grid item xs={12} md={10}>
						<ProjectsTable
							users={org?.organization?.users}
							projects={org?.organization?.projects}
						/>
					</Grid>
				</Grid>
				<Divider style={{ margin: "3rem 0" }} />
				<Grid container spacing={8}>
					<Grid item xs={12} md={2}>
						<Typography variant="button">Users</Typography>
					</Grid>
					<Grid item xs={12} md={10}>
						<UsersTable users={org?.organization?.users} />
					</Grid>
				</Grid>
				{isAdmin ? (
					<>
						<Divider style={{ margin: "3rem 0" }} />
						<Grid container spacing={8}>
							<Grid item xs={12} md={2}>
								<Typography variant="button">Roles</Typography>
							</Grid>
							<Grid item xs={12} md={10}>
								<RolesTable />
							</Grid>
						</Grid>
					</>
				) : null}
				<Divider style={{ margin: "3rem 0" }} />
			</Container>
		</>
	);
};

export default OrganizationDetails;
