export const loadState = (key) => {
	try {
		const serializedValue = localStorage.getItem(key);
		if (serializedValue === null) {
			return undefined;
		}
		return JSON.parse(serializedValue);
	} catch (err) {
		return undefined;
	}
};

export const saveState = (key, value) => {
	try {
		const serializedValue = JSON.stringify(value);
		localStorage.setItem(key, serializedValue);
	} catch (err) {
		console.log(`LocalStorage Save ${key} failed.`, err);
	}
};

export const deleteState = (key) => {
	try {
		const serializedValue = localStorage.getItem(key);
		if (serializedValue === null) {
			return undefined;
		}
		return localStorage.removeItem(key);
	} catch (err) {
		console.log(`LocalStorage delete ${key} failed.`, err);
	}
};
