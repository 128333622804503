import theme from "@/theme";

export const statusPreset = {
	default: {
		text: "Default Tag",
		bgColor: theme.palette.twind.gray[200],
		textColor: theme.palette.twind.gray[700],
	},
	none: {
		text: "No data available",
		bgColor: theme.palette.twind.gray[200],
		textColor: theme.palette.twind.gray[700],
	},
	// survey validation status
	pending: {
		text: "Pending Review",
		bgColor: theme.palette.twind.yellow[200],
		textColor: theme.palette.twind.yellow[700],
	},
	valid: {
		text: "Valid Trace",
		bgColor: theme.palette.twind.teal[200],
		textColor: theme.palette.twind.teal[700],
	},
	invalid: {
		text: "Invalid Trace",
		bgColor: theme.palette.twind.red[200],
		textColor: theme.palette.twind.red[600],
	},
	// freq observation capacity
	low: {
		text: "Low",
		bgColor: theme.palette.twind.blue[200],
		textColor: theme.palette.twind.blue[700],
	},
	medium: {
		text: "Medium",
		bgColor: theme.palette.twind.orange[200],
		textColor: theme.palette.twind.orange[700],
	},
	high: {
		text: "High",
		bgColor: theme.palette.twind.red[200],
		textColor: theme.palette.twind.red[700],
	},
	empty: {
		text: "Empty",
		bgColor: theme.palette.twind.gray[200],
		textColor: theme.palette.twind.gray[700],
	},
	// survey completion status
	init: {
		text: "Initiated",
		bgColor: theme.palette.twind.blue[200],
		textColor: theme.palette.twind.blue[700],
	},
	finished: {
		text: "Finished",
		bgColor: theme.palette.twind.green[300],
		textColor: theme.palette.twind.green[700],
	},
	notfound: {
		text: "Trip not found",
		bgColor: theme.palette.twind.yellow[200],
		textColor: theme.palette.twind.yellow[700],
	},
	notfinished: {
		text: "Trip not finished",
		bgColor: theme.palette.twind.gray[200],
		textColor: theme.palette.twind.gray[600],
	},
	canceled: {
		text: "Canceled",
		bgColor: theme.palette.twind.red[200],
		textColor: theme.palette.twind.red[600],
	},
	// action log status
	onboarding: {
		text: "Boarding",
		bgColor: theme.palette.twind.purple[200],
		textColor: theme.palette.twind.purple[700],
	},
	onboarded: {
		text: "Onboarded",
		bgColor: theme.palette.twind.indigo[200],
		textColor: theme.palette.twind.indigo[700],
	},
	departed: {
		text: "Departed",
		bgColor: theme.palette.twind.blue[200],
		textColor: theme.palette.twind.blue[700],
	},
	arrived: {
		text: "Arrived",
		bgColor: theme.palette.twind.teal[200],
		textColor: theme.palette.twind.teal[700],
	},
	notvalid: {
		text: "Invalid Trace",
		bgColor: theme.palette.twind.red[200],
		textColor: theme.palette.twind.red[600],
	},
	accepted: {
		text: "Accepted",
		bgColor: theme.palette.twind.green[300],
		textColor: theme.palette.twind.green[700],
	},
	rejected: {
		text: "Rejected",
		bgColor: theme.palette.twind.red[200],
		textColor: theme.palette.twind.red[600],
	},
	duplicated: {
		text: "Marked as Duplicate",
		bgColor: theme.palette.twind.indigo[200],
		textColor: theme.palette.twind.indigo[600],
	},
	outOfScope: {
		text: "Out of Scope",
		bgColor: theme.palette.twind.gray[200],
		textColor: theme.palette.twind.gray[600],
	},
};
