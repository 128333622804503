import React from "react";
import clsx from "clsx";
import { Collapse, makeStyles, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const useAlertTitleStyles = makeStyles((theme) => ({
	root: {
		fontWeight: theme.typography.fontWeightMedium,
		marginTop: -2,
		marginRight: "0.35em",
	},
}));

const InlineAlertTitle = React.forwardRef(function InlineAlertTitle(
	props,
	ref
) {
	const classes = useAlertTitleStyles();
	const { className, ...rest } = props;

	return (
		<Typography
			gutterBottom
			component="span"
			ref={ref}
			className={clsx(classes.root, className)}
			{...rest}
		/>
	);
});

const InlineAlert = (props) => {
	const {
		type = "info",
		showAlert = false,
		title = null,
		children,
		alertTitleProps,
		...alertProps
	} = props;

	switch (type) {
		case "success":
			return (
				<Collapse in={Boolean(showAlert)}>
					<Alert severity="success" {...alertProps}>
						<InlineAlertTitle {...alertTitleProps}>
							{title ? title : "Success!"}
						</InlineAlertTitle>
						{children}
					</Alert>
				</Collapse>
			);
		case "info":
			return (
				<Collapse in={Boolean(showAlert)}>
					<Alert severity="info" {...alertProps}>
						<InlineAlertTitle {...alertTitleProps}>
							{title ? title : "Note"}
						</InlineAlertTitle>
						{children}
					</Alert>
				</Collapse>
			);
		case "warning":
			return (
				<Collapse in={Boolean(showAlert)}>
					<Alert severity="warning" {...alertProps}>
						<InlineAlertTitle {...alertTitleProps}>
							{title ? title : "Warning"}
						</InlineAlertTitle>
						{children}
					</Alert>
				</Collapse>
			);

		case "error":
			return (
				<Collapse in={Boolean(showAlert)}>
					<Alert severity="error" {...alertProps}>
						<InlineAlertTitle {...alertTitleProps}>
							{title ? title : "Error"}
						</InlineAlertTitle>
						{children}
					</Alert>
				</Collapse>
			);
	}
};

export default InlineAlert;
