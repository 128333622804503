import React from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

/* local imports */
import InlineAlert from "../../shared/InlineAlert";

/* federated imports */
/* eslint-disable */
import { TwoColumnForm } from "@/shared-components";
import { refetchProjects } from "@/redux";
import { axiosPrivate } from "../../../utils/api";
/* eslint-enable */

const ServiceSettings = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const authToken = useSelector((state) => state.auth.accessToken);
	const orgId = useSelector((state) => state.user.organization.id);
	const { id: projectId } = useParams();
	const project = useSelector((state) =>
		state.user.projects.find((p) => p.id === projectId)
	);
	const [result, setResult] = React.useState({ res: null, err: null });

	const onSubmit = () => {
		axiosPrivate
			.post("/observer/settings", { project_id: projectId })
			.then((res) => {
				dispatch(refetchProjects({ token: authToken, orgId }));
				setResult((r) => ({ ...r, res: res.data }));
				setTimeout(() => {
					setResult((r) => ({ ...r, res: null }));
				}, 1500);
			})
			.catch((err) => {
				setResult((r) => ({ ...r, err: err.response.data }));
				setTimeout(() => {
					setResult((r) => ({ ...r, err: null }));
				}, 2000);
			});
	};
	return (
		<TwoColumnForm
			title="Services"
			subtitle="Manage active services for this project"
			cardActions={null}
		>
			<InlineAlert type="success" showAlert={result.res}>
				{result.res?.message
					? result.res.message
					: "Your changes have been saved."}
			</InlineAlert>
			<InlineAlert type="error" showAlert={result.err}>
				{result.err?.message
					? result.err.message
					: "An error occured while saving, please contact an Administrator."}
			</InlineAlert>
			<Grid container spacing={2} alignItems="center">
				<Grid item xs={12} md={8}>
					<Typography variant="h5"> Observer </Typography>
				</Grid>
				<Grid item xs={12} md={2}>
					<Button
						fullWidth
						color="primary"
						variant="contained"
						onClick={onSubmit}
						disabled={!!project?.observer}
					>
						{project?.observer ? "Enabled" : "Enable"}
					</Button>
				</Grid>
				<Grid item xs={12} md={2}>
					<Button
						fullWidth
						color="primary"
						variant="contained"
						onClick={() => history.push(`/${project.id}/observer/home`)}
						disabled={!project?.observer}
					>
						Open
					</Button>
				</Grid>
				{/* <Grid item xs={12} md={10}>
          <Typography variant="h5"> Lens </Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            onClick={onSubmit}
            disabled={!!project?.observer}
          >
            {project?.observer ? "Enabled" : "Enable"}
          </Button>
        </Grid> */}
			</Grid>
		</TwoColumnForm>
	);
};

export default ServiceSettings;
