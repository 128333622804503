import React from "react";
import clsx from "clsx";
import { Tooltip, Typography, makeStyles } from "@material-ui/core";
import { statusPreset } from "./presets";

const useStyles = makeStyles(() => ({
	root: {
		// fontWeight: 600,
		// maxHeight: "2rem",
		padding: "0.5rem 2rem",
		marginTop: "auto",
		marginBottom: "0.25rem",
		maxWidth: "12rem",
	},
	sm: {
		// fontWeight: 600,
		// maxHeight: "1.5rem",
		padding: "0.25rem 1rem",
		marginTop: "auto",
		marginBottom: "0.25rem",
		maxWidth: "10rem",
	},
	rounded: {
		borderRadius: "0.5rem",
	},
	pillShaped: {
		borderRadius: "2rem",
	},
	multiline: {
		whiteSpace: "pre-wrap",
		width: "fit-content",
	},
}));

const Tag = React.forwardRef(function Tag(props, ref) {
	const {
		className,
		text: customText,
		bgColor: customBgColor,
		textColor: customTextColor,
		size = "md",
		shape = "pillShaped",
		typography = "overline",
		multiline = false,
		status,
		children,
		...rest
	} = props;
	const classes = useStyles();

	const getSizeStyles = () => {
		switch (size) {
			case "sm":
				return classes.sm;

			case "md":
				return classes.root;

			default:
				return classes.root;
		}
	};
	const getRadiusStyles = () => {
		switch (shape) {
			case "pillShaped":
				return classes.pillShaped;

			case "roundedCorners":
				return classes.rounded;

			default:
				return classes.rounded;
		}
	};

	return (
		<Typography
			ref={ref}
			className={clsx(
				getSizeStyles(),
				getRadiusStyles(),
				multiline ? classes.multiline : null,
				className
			)}
			variant={typography}
			noWrap
			style={{
				backgroundColor: customBgColor || statusPreset[status]?.bgColor,
				color: customTextColor || statusPreset[status]?.textColor,
			}}
			{...rest}
		>
			{customText ?? statusPreset[status]?.text}
			{children}
		</Typography>
	);
});

const WrappedWithTooltip = ({
	status = "default",
	text: customText,
	tooltipText,
	...rest
}) => {
	let isStatusSafe = true;
	if (status === null || status === undefined || !status.length) {
		console.log("Received invalid status", status, "falling back to default");
		isStatusSafe = false;
	}
	if (statusPreset[status] === null || statusPreset[status] === undefined) {
		console.log(
			"Status Preset does not exist for status",
			status,
			"falling back to default"
		);
		isStatusSafe = false;
	}

	return (
		<Tooltip
			title={
				tooltipText ??
				customText ??
				statusPreset[isStatusSafe ? status : "default"]?.text
			}
			aria-label={
				"tagged " + customText ??
				"tagged " + statusPreset[isStatusSafe ? status : "default"]?.text
			}
		>
			<Tag
				status={isStatusSafe ? status : "default"}
				text={customText}
				{...rest}
			/>
		</Tooltip>
	);
};
export default WrappedWithTooltip;
