import React from "react";
import clsx from "clsx";
import {
	Card,
	CardActions,
	CardContent,
	Divider,
	Grid,
	makeStyles,
	Typography,
	useMediaQuery,
} from "@material-ui/core";
import TwoColumnFormSkeleton from "./TwoColumnFormSkeleton";

/* local imports */
import Form from "../FormElements/Form";
import theme from "@/theme";

const useStyles = makeStyles(() => ({
	contentRoot: {},
}));

const TwoColumnForm = (props) => {
	const classes = useStyles();
	const {
		className,
		title,
		subtitle,
		cardActions = null,
		handleSubmit = () => {},
		renderSubtitle = null,
		onSubmit,
		isLoading,
		children,
		titleGridProps,
		contentGridProps,
		containerProps,
	} = props;
	const xl = useMediaQuery(theme.breakpoints.up("xl"));
	const md = useMediaQuery(theme.breakpoints.up("md"));

	if (isLoading) {
		return <TwoColumnFormSkeleton title={title} />;
	} else {
		return (
			<Grid container spacing={xl ? 8 : md ? 4 : 2} {...containerProps}>
				<Grid item xs={12} md={2} {...titleGridProps}>
					<Typography variant="button">{title}</Typography>
					{subtitle && <Typography variant="subtitle2">{subtitle}</Typography>}
					{!subtitle && renderSubtitle && renderSubtitle()}
				</Grid>
				<Grid item xs={12} md={10} {...contentGridProps}>
					<Card elevation={2} className={clsx(className, classes.contentRoot)}>
						<Form onSubmit={handleSubmit(onSubmit)}>
							<CardContent>{children}</CardContent>
							{cardActions && (
								<>
									<Divider />
									<CardActions
										style={{ display: "flex", justifyContent: "flex-end" }}
									>
										{cardActions()}
									</CardActions>
								</>
							)}
						</Form>
					</Card>
				</Grid>
			</Grid>
		);
	}
};

export default TwoColumnForm;
