import { createSlice } from "@reduxjs/toolkit";
import { getAxiosPrivate } from "../../utils/api";

const axiosPrivate = getAxiosPrivate();

const userSlice = createSlice({
	name: "user",
	initialState: {
		id: "",
		name: "",
		email: "",
		type: "",
		isAdmin: false,
		projects: [],
		organization: {},
		activeProject: {},
	},
	reducers: {
		loadUserData: (state, action) => {
			state.id = action.payload.user.id;
			state.name = action.payload.user.name;
			state.email = action.payload.user.email;
			state.type = action.payload.user.type;
			state.isAdmin = action.payload.user.is_admin;
			state.organization = action.payload.organization;
			state.projects = action.payload.projects;
			state.activeProject = action.payload.projects[0];
		},
		setActiveProject: (state, action) => {
			state.activeProject = action.payload;
		},
		setActiveDataset: (state, action) => {
			state.activeDataset = action.payload;
		},
		loadProjects: (state, action) => {
			state.projects = action.payload.projects;
		},
		loadDatasets: (state, action) => {
			state.datasets = action.payload.datasets;
			state.activeDataset = action.payload.datasets[0];
		},
		loadProjectSettings: (state, action) => {
			const projIndex = state.projects.findIndex(
				(p) => p.id === action.payload.projectId
			);
			state.projects[projIndex][action.payload.service] = action.payload.data;
		},
	},
});

export const {
	loadUserData,
	setActiveProject,
	setActiveDataset,
	loadProjects,
	loadDatasets,
	loadProjectSettings,
} = userSlice.actions;

export const getUserData = () => async (dispatch) => {
	await axiosPrivate
		.get("/users/me")
		.then((res) => {
			res.data.data.projects.forEach((proj) =>
				dispatch(getProjectSettings(proj.id))
			);
			dispatch(getDatasets(res.data.data.organization.id));
			dispatch(loadUserData(res.data.data));
		})
		.catch((err) => new Error(err));
};

const getProjectSettings = (projectId) => async (dispatch) => {
	await axiosPrivate
		.get(`/observer/settings`, { params: { project_id: projectId } })
		.then((res) => {
			dispatch(
				loadProjectSettings({
					service: "observer",
					projectId,
					data: res.data.data,
				})
			);
		})
		.catch((err) => {
			const error = new Error(err);
			throw error;
		});
};
const getDatasets = (orgId) => async (dispatch) => {
	await axiosPrivate
		.get("/port/datasets", {
			params: { organization_id: orgId },
		})
		.then((res) => {
			dispatch(loadDatasets(res.data.data));
		})
		.catch((err) => {
			const error = new Error(err);
			throw error;
		});
};

export const refetchProjects =
	({ orgId }) =>
	async (dispatch) => {
		await axiosPrivate
			.get(`/organizations/${orgId}/projects`)
			.then((res) => {
				dispatch(loadProjects(res.data.data));
			})
			.catch((err) => new Error(err));
	};

export const refetchDatasets =
	({ orgId }) =>
	async (dispatch) => {
		await axiosPrivate
			.get(`/port/datasets`, {
				params: { organization_id: orgId },
			})
			.then((res) => {
				dispatch(loadDatasets(res.data.data));
			})
			.catch((err) => new Error(err));
	};

const userReducer = userSlice.reducer;
export default userReducer;
