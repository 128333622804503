import React from "react";
import { axiosPrivate } from "./api";

const useFetchOnce = ({ resource, params, fetchCondition = true }) => {
	const [data, setData] = React.useState(null);
	const [error, setError] = React.useState(null);
	const [isFetching, setIsFetching] = React.useState(false);

	React.useEffect(() => {
		const fetchData = () => {
			setIsFetching(true);
			axiosPrivate
				.get(resource, { params })
				.then((res) => {
					setData(res.data.data);
					setIsFetching(false);
				})
				.catch((err) => {
					console.log("err :>> ", err);
					setIsFetching(false);
				});
		};
		if (fetchCondition) {
			fetchData();
		}
	}, [fetchCondition]);

	return { data, error, isFetching };
};

export default useFetchOnce;
