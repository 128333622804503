import React from "react";
import clsx from "clsx";
import {
	MenuItem,
	Menu,
	IconButton,
	ListItemIcon,
	ListItemText,
	Typography,
	Popover,
	makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
	root: {
		color: "inherit",
	},
	paper: { overflowY: "hidden" },
}));
const IconMenuButton = (props) => {
	const {
		className,
		iconType,
		items = [],
		menuType = "list",
		children,
		...menuProps
	} = props;

	const classes = useStyles();

	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);

	const IconWrapper = iconType;
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const menuItems = items.map(
		(
			{
				handleClick = () => {},
				name,
				startIcon = null,
				endIcon = null,
				...menuItemProps
			},
			idx
		) => {
			const StartIconWrapper = startIcon;
			const EndIconWrapper = endIcon;
			return (
				<MenuItem key={idx} onClick={handleClick} {...menuItemProps}>
					{startIcon && (
						<ListItemIcon>
							<StartIconWrapper fontSize="small" />
						</ListItemIcon>
					)}
					<ListItemText>
						<Typography
							variant="body1"
							style={startIcon && { marginLeft: "-1.5rem" }}
						>
							{name}
						</Typography>
					</ListItemText>
					{endIcon && (
						<ListItemIcon>
							<EndIconWrapper fontSize="small" />
						</ListItemIcon>
					)}
				</MenuItem>
			);
		}
	);

	return (
		<>
			<IconButton
				className={clsx(classes.root, className)}
				aria-owns={
					open
						? menuType === "list"
							? "menu-appbar"
							: "custom-menu-popover"
						: null
				}
				aria-haspopup="true"
				aria-controls="account-menu"
				color="inherit"
				size="small"
				onClick={handleClick}
			>
				<IconWrapper />
			</IconButton>
			{menuType === "list" && (
				<Menu
					{...menuProps}
					id="menu-appbar"
					anchorEl={anchorEl}
					getContentAnchorEl={null}
					disableScrollLock={true}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "center",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "center",
					}}
					open={open}
					onClose={handleClose}
				>
					{menuItems}
				</Menu>
			)}
			{menuType === "custom" && (
				<Popover
					classes={{ paper: classes.paper }}
					id="custom-menu-popover"
					anchorEl={anchorEl}
					disableScrollLock={true}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "right",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "right",
					}}
					open={open}
					onClose={handleClose}
				>
					{children}
				</Popover>
			)}
		</>
	);
};

export default IconMenuButton;
