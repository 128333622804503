import React from "react";
import {
	Grid,
	Button,
	Typography,
	Box,
	CssBaseline,
	CircularProgress,
	Link as MUILink,
	makeStyles,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useLocation, Redirect, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
// import { useSWR } from "swr";

/* local imports */
import SideImage from "../../assets/sign-in-sidebar-blue-compressed.jpg";
import SidebarLogo from "../../assets/tfc_logo.png";
import CopyrightFooter from "../shared/CopyrightFooter";
// import { Form } from "./Form";
// import { Input } from "./Input";

/* federated imports */
/* eslint-disable */
import { asyncLogin } from "@/redux";
import { Input, Form } from "@/shared-components";
/* eslint-enable */

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.default,
		height: "100vh",
	},
	grid: {
		height: "100%",
	},
	quoteContainer: {
		[theme.breakpoints.down("md")]: {
			display: "none",
		},
	},
	quote: {
		backgroundColor: theme.palette.neutral,
		height: "100%",
		display: "flex",
		flexWrap: "wrap",
		justifyContent: "center",
		alignItems: "center",
		backgroundImage: `url(${SideImage})`,
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center",
	},
	quoteInner: {
		textAlign: "center",
		flexBasis: "600px",
		marginTop: "auto",
		padding: "0.5em",
	},
	quoteText: {
		color: theme.palette.white,
		fontWeight: 300,
		"&::before": {
			fontFamily: "Georgia, serif",
			fontSize: "250px",
			position: "absolute",
			fontStyle: "italic",
			opacity: "70%",
			margin: "0.15em 0",
			content: '"\\201C"',
		},
	},
	name: {
		marginTop: theme.spacing(3),
		color: theme.palette.white,
	},
	bio: {
		color: theme.palette.white,
	},
	contentContainer: {},
	content: {
		height: "100%",
		display: "flex",
		flexDirection: "column",
	},
	contentBody: {
		flexGrow: 1,
		display: "flex",
		alignItems: "center",
		[theme.breakpoints.down("md")]: {
			justifyContent: "center",
		},
	},
	form: {
		paddingLeft: 100,
		paddingRight: 100,
		paddingBottom: 125,
		flexBasis: 700,
		[theme.breakpoints.down("sm")]: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
		},
	},
	title: {
		marginTop: theme.spacing(3),
	},
	socialButtons: {
		marginTop: theme.spacing(3),
	},
	socialIcon: {
		marginRight: theme.spacing(1),
	},
	mt_theme_2: {
		marginTop: theme.spacing(2),
	},
	mt_theme_1: {
		marginTop: theme.spacing(1),
	},
	forgotPassword: {
		display: "block",
		marginTop: theme.spacing(1),
		textDecoration: "none",
		color: theme.palette.ibmBlue[500],
	},
	sidebarLogoImage: {
		width: "250px",
		marginTop: "auto",
		marginBottom: "1em",
	},
}));

const ResetPassword = () => {
	const classes = useStyles();
	const { register, handleSubmit, errors } = useForm({
		mode: "onBlur",
	});

	const onSubmit = (data) => console.log("data", data);
	return (
		<div className={classes.root}>
			<Grid className={classes.grid} container>
				<CssBaseline />
				<Grid className={classes.quoteContainer} item lg={4}>
					<div className={classes.quote}>
						<div className={classes.quoteInner}>
							<Typography className={classes.quoteText} variant="h1">
								Access to transportation is the single most important factor in
								an individual&apos;s ability to escape poverty
							</Typography>
							{/* <div className={classes.person}>
      <Typography className={classes.name} variant="body1">
        Bishoy Maher
      </Typography>
      <Typography className={classes.bio} variant="body2">
        TreeHugger at TfC
      </Typography>
    </div> */}
						</div>
						<img
							alt="Logo"
							src={SidebarLogo}
							className={classes.sidebarLogoImage}
						/>
					</div>
				</Grid>
				<Grid className={classes.content} item lg={7} xs={12}>
					<div className={classes.contentBody}>
						<Form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
							<Typography className={classes.title} variant="h2">
								Reset Password
							</Typography>
							<Typography className={classes.mt_theme_1} variant="subtitle2">
								Enter the email you used at the time of registration, and
								we&apos;ll send you a link to reset your password.
							</Typography>
							<Input
								className={classes.mt_theme_2}
								ref={register({
									required: {
										value: true,
										message: "An email address is required",
									},
									pattern: {
										value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
										message:
											"Hmmm... that email address doesn't look quite right 🤔",
									},
								})}
								autoComplete="email"
								label="Email address"
								name="email"
								type="text"
								error={!!errors.email}
								helperText={errors?.email?.message}
							/>

							{/* <Input
              className={classes.mt_theme_2}
              ref={register({
                required: {
                  value: true,
                  message: "Password cannot be empty",
                },
                minLength: {
                  value: 8,
                  message: "Password should be at least 8 characters long",
                },
              })}
              autoComplete="password"
              label="Password"
              name="password"
              type="password"
              error={!!errors.password}
              helperText={errors?.password?.message}
            /> */}
							{/* {isLoading && (
          <CircularProgress className={classes.mt_theme_2} size={25} />
        )} */}
							<Button
								className={classes.mt_theme_1}
								color="primary"
								fullWidth
								size="large"
								type="submit"
								variant="contained"
								//   disabled={isLoading}
							>
								Submit
							</Button>

							<Typography
								className={classes.forgotPassword}
								variant="subtitle1"
								component={Link}
								to="/login"
							>
								Back to login
							</Typography>
							<Box mt={5}>
								<CopyrightFooter />
							</Box>
						</Form>
					</div>
				</Grid>
			</Grid>
		</div>
	);
};

export default ResetPassword;
