import React from "react";
import clsx from "clsx";
import {
	Button,
	Card,
	CardActions,
	CardContent,
	Divider,
	Grid,
	makeStyles,
	Typography,
} from "@material-ui/core";
import TwoColumnFormSkeleton from "./TwoColumnFormSkeleton";

import { Form } from "@/shared-components";

const useStyles = makeStyles((theme) => ({
	contentRoot: {},
}));

const TwoColumnForm = (props) => {
	const classes = useStyles();
	const {
		className,
		title,
		subtitle,
		cardActions = null,
		handleSubmit = () => {},
		onSubmit,
		isLoading,
		children,
	} = props;

	if (isLoading) {
		return <TwoColumnFormSkeleton title={title} />;
	} else {
		return (
			<Grid container spacing={8}>
				<Grid item xs={12} md={2}>
					<Typography variant="button">{title}</Typography>
					<Typography variant="subtitle2">{subtitle}</Typography>
				</Grid>
				<Grid item xs={12} md={10}>
					<Card elevation={2} className={clsx(className, classes.contentRoot)}>
						<Form onSubmit={handleSubmit(onSubmit)}>
							<CardContent>{children}</CardContent>
							{cardActions && (
								<>
									<Divider />
									<CardActions
										style={{ display: "flex", justifyContent: "flex-end" }}
									>
										{cardActions()}
									</CardActions>
								</>
							)}
						</Form>
					</Card>
				</Grid>
			</Grid>
		);
	}
};

export default TwoColumnForm;
