import React from "react";
import { CircleMarker } from "react-leaflet";
import { NoMapDataAvailable } from "../Leaflet";

const getFrequencySurveyMap = (args) => {
	const { geometry } = args;
	if (geometry) {
		return (
			<CircleMarker
				center={[geometry.coordinates[1], geometry.coordinates[0]]}
				radius={50}
			/>
		);
	} else {
		return <NoMapDataAvailable />;
	}
};

export default getFrequencySurveyMap;
