export default [
	{
		name: "Industry",
		value: "Industry",
	},
	{
		name: "Accounting",
		value: "Accounting",
	},
	{
		name: "Airlines/Aviation",
		value: "Airlines/Aviation",
	},
	{
		name: "Alternative Dispute Resolution",
		value: "Alternative Dispute Resolution",
	},
	{
		name: "Alternative Medicine",
		value: "Alternative Medicine",
	},
	{
		name: "Animation",
		value: "Animation",
	},
	{
		name: "Apparel/Fashion",
		value: "Apparel/Fashion",
	},
	{
		name: "Architecture/Planning",
		value: "Architecture/Planning",
	},
	{
		name: "Arts/Crafts",
		value: "Arts/Crafts",
	},
	{
		name: "Automotive",
		value: "Automotive",
	},
	{
		name: "Aviation/Aerospace",
		value: "Aviation/Aerospace",
	},
	{
		name: "Banking/Mortgage",
		value: "Banking/Mortgage",
	},
	{
		name: "Biotechnology/Greentech",
		value: "Biotechnology/Greentech",
	},
	{
		name: "Broadcast Media",
		value: "Broadcast Media",
	},
	{
		name: "Building Materials",
		value: "Building Materials",
	},
	{
		name: "Business Supplies/Equipment",
		value: "Business Supplies/Equipment",
	},
	{
		name: "Capital Markets/Hedge Fund/Private Equity",
		value: "Capital Markets/Hedge Fund/Private Equity",
	},
	{
		name: "Chemicals",
		value: "Chemicals",
	},
	{
		name: "Civic/Social Organization",
		value: "Civic/Social Organization",
	},
	{
		name: "Civil Engineering",
		value: "Civil Engineering",
	},
	{
		name: "Commercial Real Estate",
		value: "Commercial Real Estate",
	},
	{
		name: "Computer Games",
		value: "Computer Games",
	},
	{
		name: "Computer Hardware",
		value: "Computer Hardware",
	},
	{
		name: "Computer Networking",
		value: "Computer Networking",
	},
	{
		name: "Computer Software/Engineering",
		value: "Computer Software/Engineering",
	},
	{
		name: "Computer/Network Security",
		value: "Computer/Network Security",
	},
	{
		name: "Construction",
		value: "Construction",
	},
	{
		name: "Consumer Electronics",
		value: "Consumer Electronics",
	},
	{
		name: "Consumer Goods",
		value: "Consumer Goods",
	},
	{
		name: "Consumer Services",
		value: "Consumer Services",
	},
	{
		name: "Cosmetics",
		value: "Cosmetics",
	},
	{
		name: "Dairy",
		value: "Dairy",
	},
	{
		name: "Defense/Space",
		value: "Defense/Space",
	},
	{
		name: "Design",
		value: "Design",
	},
	{
		name: "E-Learning",
		value: "E-Learning",
	},
	{
		name: "Education Management",
		value: "Education Management",
	},
	{
		name: "Electrical/Electronic Manufacturing",
		value: "Electrical/Electronic Manufacturing",
	},
	{
		name: "Entertainment/Movie Production",
		value: "Entertainment/Movie Production",
	},
	{
		name: "Environmental Services",
		value: "Environmental Services",
	},
	{
		name: "Events Services",
		value: "Events Services",
	},
	{
		name: "Executive Office",
		value: "Executive Office",
	},
	{
		name: "Facilities Services",
		value: "Facilities Services",
	},
	{
		name: "Farming",
		value: "Farming",
	},
	{
		name: "Financial Services",
		value: "Financial Services",
	},
	{
		name: "Fine Art",
		value: "Fine Art",
	},
	{
		name: "Fishery",
		value: "Fishery",
	},
	{
		name: "Food Production",
		value: "Food Production",
	},
	{
		name: "Food/Beverages",
		value: "Food/Beverages",
	},
	{
		name: "Fundraising",
		value: "Fundraising",
	},
	{
		name: "Furniture",
		value: "Furniture",
	},
	{
		name: "Gambling/Casinos",
		value: "Gambling/Casinos",
	},
	{
		name: "Glass/Ceramics/Concrete",
		value: "Glass/Ceramics/Concrete",
	},
	{
		name: "Government Administration",
		value: "Government Administration",
	},
	{
		name: "Government Relations",
		value: "Government Relations",
	},
	{
		name: "Graphic Design/Web Design",
		value: "Graphic Design/Web Design",
	},
	{
		name: "Health/Fitness",
		value: "Health/Fitness",
	},
	{
		name: "Higher Education/Acadamia",
		value: "Higher Education/Acadamia",
	},
	{
		name: "Hospital/Health Care",
		value: "Hospital/Health Care",
	},
	{
		name: "Hospitality",
		value: "Hospitality",
	},
	{
		name: "Human Resources/HR",
		value: "Human Resources/HR",
	},
	{
		name: "Import/Export",
		value: "Import/Export",
	},
	{
		name: "Individual/Family Services",
		value: "Individual/Family Services",
	},
	{
		name: "Industrial Automation",
		value: "Industrial Automation",
	},
	{
		name: "Information Services",
		value: "Information Services",
	},
	{
		name: "Information Technology/IT",
		value: "Information Technology/IT",
	},
	{
		name: "Insurance",
		value: "Insurance",
	},
	{
		name: "International Affairs",
		value: "International Affairs",
	},
	{
		name: "International Trade/Development",
		value: "International Trade/Development",
	},
	{
		name: "Internet",
		value: "Internet",
	},
	{
		name: "Investment Banking/Venture",
		value: "Investment Banking/Venture",
	},
	{
		name: "Investment Management/Hedge Fund/Private Equity",
		value: "Investment Management/Hedge Fund/Private Equity",
	},
	{
		name: "Judiciary",
		value: "Judiciary",
	},
	{
		name: "Law Enforcement",
		value: "Law Enforcement",
	},
	{
		name: "Law Practice/Law Firms",
		value: "Law Practice/Law Firms",
	},
	{
		name: "Legal Services",
		value: "Legal Services",
	},
	{
		name: "Legislative Office",
		value: "Legislative Office",
	},
	{
		name: "Leisure/Travel",
		value: "Leisure/Travel",
	},
	{
		name: "Library",
		value: "Library",
	},
	{
		name: "Logistics/Procurement",
		value: "Logistics/Procurement",
	},
	{
		name: "Luxury Goods/Jewelry",
		value: "Luxury Goods/Jewelry",
	},
	{
		name: "Machinery",
		value: "Machinery",
	},
	{
		name: "Management Consulting",
		value: "Management Consulting",
	},
	{
		name: "Maritime",
		value: "Maritime",
	},
	{
		name: "Market Research",
		value: "Market Research",
	},
	{
		name: "Marketing/Advertising/Sales",
		value: "Marketing/Advertising/Sales",
	},
	{
		name: "Mechanical or Industrial Engineering",
		value: "Mechanical or Industrial Engineering",
	},
	{
		name: "Media Production",
		value: "Media Production",
	},
	{
		name: "Medical Equipment",
		value: "Medical Equipment",
	},
	{
		name: "Medical Practice",
		value: "Medical Practice",
	},
	{
		name: "Mental Health Care",
		value: "Mental Health Care",
	},
	{
		name: "Military Industry",
		value: "Military Industry",
	},
	{
		name: "Mining/Metals",
		value: "Mining/Metals",
	},
	{
		name: "Motion Pictures/Film",
		value: "Motion Pictures/Film",
	},
	{
		name: "Museums/Institutions",
		value: "Museums/Institutions",
	},
	{
		name: "Music",
		value: "Music",
	},
	{
		name: "Nanotechnology",
		value: "Nanotechnology",
	},
	{
		name: "Newspapers/Journalism",
		value: "Newspapers/Journalism",
	},
	{
		name: "Non-Profit/Volunteering",
		value: "Non-Profit/Volunteering",
	},
	{
		name: "Oil/Energy/Solar/Greentech",
		value: "Oil/Energy/Solar/Greentech",
	},
	{
		name: "Online Publishing",
		value: "Online Publishing",
	},
	{
		name: "Other Industry",
		value: "Other Industry",
	},
	{
		name: "Outsourcing/Offshoring",
		value: "Outsourcing/Offshoring",
	},
	{
		name: "Package/Freight Delivery",
		value: "Package/Freight Delivery",
	},
	{
		name: "Packaging/Containers",
		value: "Packaging/Containers",
	},
	{
		name: "Paper/Forest Products",
		value: "Paper/Forest Products",
	},
	{
		name: "Performing Arts",
		value: "Performing Arts",
	},
	{
		name: "Pharmaceuticals",
		value: "Pharmaceuticals",
	},
	{
		name: "Philanthropy",
		value: "Philanthropy",
	},
	{
		name: "Photography",
		value: "Photography",
	},
	{
		name: "Plastics",
		value: "Plastics",
	},
	{
		name: "Political Organization",
		value: "Political Organization",
	},
	{
		name: "Primary/Secondary Education",
		value: "Primary/Secondary Education",
	},
	{
		name: "Printing",
		value: "Printing",
	},
	{
		name: "Professional Training",
		value: "Professional Training",
	},
	{
		name: "Program Development",
		value: "Program Development",
	},
	{
		name: "Public Relations/PR",
		value: "Public Relations/PR",
	},
	{
		name: "Public Safety",
		value: "Public Safety",
	},
	{
		name: "Publishing Industry",
		value: "Publishing Industry",
	},
	{
		name: "Railroad Manufacture",
		value: "Railroad Manufacture",
	},
	{
		name: "Ranching",
		value: "Ranching",
	},
	{
		name: "Real Estate/Mortgage",
		value: "Real Estate/Mortgage",
	},
	{
		name: "Recreational Facilities/Services",
		value: "Recreational Facilities/Services",
	},
	{
		name: "Religious Institutions",
		value: "Religious Institutions",
	},
	{
		name: "Renewables/Environment",
		value: "Renewables/Environment",
	},
	{
		name: "Research Industry",
		value: "Research Industry",
	},
	{
		name: "Restaurants",
		value: "Restaurants",
	},
	{
		name: "Retail Industry",
		value: "Retail Industry",
	},
	{
		name: "Security/Investigations",
		value: "Security/Investigations",
	},
	{
		name: "Semiconductors",
		value: "Semiconductors",
	},
	{
		name: "Shipbuilding",
		value: "Shipbuilding",
	},
	{
		name: "Sporting Goods",
		value: "Sporting Goods",
	},
	{
		name: "Sports",
		value: "Sports",
	},
	{
		name: "Staffing/Recruiting",
		value: "Staffing/Recruiting",
	},
	{
		name: "Supermarkets",
		value: "Supermarkets",
	},
	{
		name: "Telecommunications",
		value: "Telecommunications",
	},
	{
		name: "Textiles",
		value: "Textiles",
	},
	{
		name: "Think Tanks",
		value: "Think Tanks",
	},
	{
		name: "Tobacco",
		value: "Tobacco",
	},
	{
		name: "Translation/Localization",
		value: "Translation/Localization",
	},
	{
		name: "Transportation",
		value: "Transportation",
	},
	{
		name: "Utilities",
		value: "Utilities",
	},
	{
		name: "Venture Capital/VC",
		value: "Venture Capital/VC",
	},
	{
		name: "Veterinary",
		value: "Veterinary",
	},
	{
		name: "Warehousing",
		value: "Warehousing",
	},
	{
		name: "Wholesale",
		value: "Wholesale",
	},
	{
		name: "Wine/Spirits",
		value: "Wine/Spirits",
	},
	{
		name: "Wireless",
		value: "Wireless",
	},
	{
		name: "Writing/Editing",
		value: "Writing/Editing",
	},
];
