import { createSlice } from "@reduxjs/toolkit";

const globalSlice = createSlice({
	name: "global",
	initialState: {
		hasSidebar: false,
		isSidebarOpen: true,
	},
	reducers: {
		setSidebarOpen: (state, action) => {
			state.isSidebarOpen = action.payload;
		},
		setHasSidebar: (state, action) => {
			state.hasSidebar = action.payload;
		},
	},
});

export const { setSidebarOpen, setHasSidebar } = globalSlice.actions;

const globalReducer = globalSlice.reducer;
export default globalReducer;
