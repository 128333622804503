import React from "react";
import clsx from "clsx";
import L from "leaflet";
import {
	MapContainer,
	TileLayer,
	FeatureGroup,
	useMap,
	CircleMarker,
	Tooltip,
} from "react-leaflet";
import {
	CircularProgress,
	makeStyles,
	Menu,
	MenuItem,
	Typography,
} from "@material-ui/core";

import "leaflet/dist/leaflet.css";
import { useSelector } from "react-redux";
/* local imports */
import theme from "@/theme";
import { getMapType } from "./MapTypes";
import { BASEMAPS, tileOptions, getTileLayer } from "./basemaps";
import DrawControl from "./features/DrawControl";
import { toBBOX } from "country-to-bbox";

// work around broken icons when using webpack, see https://github.com/PaulLeCam/react-leaflet/issues/255
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
	iconRetinaUrl:
		"https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-icon.png",
	iconUrl:
		"https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-icon.png",
	shadowUrl:
		"https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-shadow.png",
});

const useStyles = makeStyles(() => ({
	root: {
		height: 270,
		width: "100%",
	},
	loader: {
		height: 270,
		width: "100%",
		backgroundColor: theme.palette.twind.gray[200],
		display: "grid",
		placeItems: "center",
	},
}));
const FeatureLayer = ({
	feature,
	editor = false,
	multiLayer = false,
	layers = null,
	...rest
}) => {
	const projectCountry = useSelector(
		(state) => state.user.activeProject?.country
	);
	const map = useMap();

	if (multiLayer && !layers)
		throw new Error("MultiLayer mode specified, received invalid layers array");

	if (multiLayer) {
		return (
			<FeatureGroup
				eventHandlers={{
					add(e) {
						try {
							map.fitBounds(e?.target?.getBounds());
						} catch (e) {
							// Fallback to project country bounds
							if (projectCountry) {
								const [minLng, maxLat, maxLng, minLat] = toBBOX(projectCountry);
								map.fitBounds([
									[minLng, minLat],
									[maxLng, maxLat],
								]);
							}
						}
					},
				}}
			>
				{layers.map((l) =>
					getMapType(l.feature, { geometry: l.geometry, ...rest })
				)}
			</FeatureGroup>
		);
	}

	if (editor) {
		return <DrawControl editor={editor} {...rest} />;
	}

	return (
		<FeatureGroup
			eventHandlers={{
				add(e) {
					try {
						map.fitBounds(e?.target?.getBounds());
					} catch (e) {
						// Fallback to project country bounds
						if (projectCountry) {
							const [minLng, maxLat, maxLng, minLat] = toBBOX(projectCountry);
							map.fitBounds([
								// [Lat, Lng] as opposed to [Lng, Lat] in Snapper's code
								[minLat, minLng],
								[maxLat, maxLng],
							]);
						}
					}
				},
			}}
		>
			{getMapType(feature, { ...rest })}
		</FeatureGroup>
	);
};
const Leaflet = ({ className, isLoading, feature, ...rest }) => {
	const classes = useStyles();
	const [mousePos, setMousePos] = React.useState({
		mouseX: null,
		mouseY: null,
	});
	const [baseMap, setBaseMap] = React.useState(BASEMAPS.CARTO);

	const handleContextClick = (event) => {
		event.preventDefault();
		setMousePos({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4,
		});
	};

	const handleClose = () => {
		setMousePos({
			mouseX: null,
			mouseY: null,
		});
	};
	const handleChangeBasemap = (baseMap) => {
		setBaseMap(baseMap);
		handleClose();
	};

	if (isLoading) {
		return <MapLoader className={className} />;
	}
	return (
		<span onContextMenu={handleContextClick}>
			<MapContainer
				center={[30.059, 31.223]}
				zoom={10}
				maxZoom={18}
				className={clsx(classes.root, className)}
			>
				<TileLayer key={baseMap} {...getTileLayer(baseMap)} />
				<FeatureLayer feature={feature} {...rest} />
			</MapContainer>
			<Menu
				keepMounted
				open={mousePos.mouseY !== null}
				onClose={handleClose}
				anchorReference="anchorPosition"
				anchorPosition={
					mousePos.mouseY !== null && mousePos.mouseX !== null
						? { top: mousePos.mouseY, left: mousePos.mouseX }
						: undefined
				}
			>
				{tileOptions.map((t) => (
					<MenuItem
						key={`tile_${t.id}`}
						onClick={() => handleChangeBasemap(t.id)}
					>
						{t.title}
					</MenuItem>
				))}
			</Menu>
		</span>
	);
};

export default Leaflet;

export const NoMapDataAvailable = ({ className }) => {
	const classes = useStyles();
	return (
		<div className={clsx(classes.loader, className)}>
			<CircleMarker center={[0, 0]} radius={50}>
				<Tooltip permanent>
					<Typography variant="caption">No Map Data Available</Typography>
				</Tooltip>
			</CircleMarker>
		</div>
	);
};

const MapLoader = ({ className }) => {
	const classes = useStyles();
	return (
		<div className={clsx(classes.loader, className)}>
			<CircularProgress size={24} />
		</div>
	);
};
