import React from "react";
import L from "leaflet";
import { FeatureGroup, useMap } from "react-leaflet";
import { toBBOX } from "country-to-bbox";

import "leaflet-draw/dist/leaflet.draw.css";

/* local imports */
import EditControl from "./EditControl";

const DrawControl = (props) => {
	const { geometry, country, onMapDrawChange, editControlProps = {} } = props;
	const [hasPreviouslyMounted, setHasMounted] = React.useState(false);
	const _editableFG = React.useRef(null);
	const map = useMap();

	const _onEdited = (e) => {
		let numEdited = 0;
		let layers = [];

		e.layers.eachLayer((layer) => {
			numEdited += 1;
			layers.push(layer);
		});
		console.log(`_onEdited: edited ${numEdited} layers`, e); // eslint-disable-line
		_onChange(layers);
	};

	const _onCreated = (e) => {
		const type = e.layerType;
		const layer = e.layer;
		console.log("_onCreated:", type, layer, e); // eslint-disable-line

		_onChange(layer);
	};

	const _onDeleted = (e) => {
		let numDeleted = 0;
		let layers = [];
		e.layers.eachLayer((layer) => {
			numDeleted += 1;
			layers.push(layer);
		});

		console.log(`onDeleted: removed ${numDeleted} layers`, e); // eslint-disable-line
		_onChange();
	};

	const _onMounted = (drawControl) => {
		console.log("_onMounted", drawControl); // eslint-disable-line
		if (!geometry) {
			const geoCoded = toBBOX(country ? country : "Egypt");
			/* geoCoded format is [south, west, north, east]. 
      Leaflet requires NorthWest point -> SouthEast point */
			const countryBbox =
				[
					[geoCoded[1], geoCoded[2]],
					[geoCoded[3], geoCoded[0]],
				] || [];

			const boundingRect = L.rectangle(countryBbox).addTo(map);
			map.fitBounds(boundingRect.getBounds());
			map.removeLayer(boundingRect);
		}
		setHasMounted(true);
	};

	const _onChange = () => {
		const updatedLayers = _editableFG.current
			.getLayers()
			.map((l) => l.toGeoJSON());

		const updatedCoords = updatedLayers.map((l) => l.geometry.coordinates);

		if (!updatedCoords.length) return onMapDrawChange(null);

		const isSinglePoint =
			updatedLayers.length === 1 && updatedLayers[0].geometry.type === "Point";

		onMapDrawChange(
			isSinglePoint
				? { type: "Point", coordinates: updatedLayers[0].geometry.coordinates }
				: mergePolygons(updatedCoords)
		);
	};
	const _onFeatureGroupReady = (reactFGref) => {
		if (!geometry) return (_editableFG.current = reactFGref);

		if (!hasPreviouslyMounted) {
			const leafletGeoJSON = new L.GeoJSON(splitGeometries(geometry));

			leafletGeoJSON.eachLayer((layer) => {
				reactFGref.addLayer(layer);
			});
		}

		// store the ref for future access to content
		_editableFG.current = reactFGref;
	};

	return (
		<FeatureGroup
			ref={(reactFGref) => {
				if (reactFGref) _onFeatureGroupReady(reactFGref);
			}}
			eventHandlers={{
				add(e) {
					if (!geometry) return;
					map.fitBounds(e.target.getBounds());
				},
			}}
		>
			<EditControl
				leaflet={{ map }}
				position="bottomright"
				onEdited={_onEdited}
				onCreated={_onCreated}
				onDeleted={_onDeleted}
				onMounted={_onMounted}
				draw={{
					circle: false,
					circlemarker: false,
					polyline: false,
					marker: false,
				}}
				{...editControlProps}
			/>
		</FeatureGroup>
	);
};

export default DrawControl;

const splitGeometries = (geometry) => {
	const toPolygon = (coordinates) => ({
		type: "Polygon",
		coordinates,
	});

	switch (geometry.type) {
		case "MultiPolygon": {
			return geometry.coordinates.map((poly) => toPolygon(poly));
		}

		default:
			console.log(
				"unhandled geometry type in splitGeometries: ",
				geometry.type
			);
			return geometry;
	}
};

const mergePolygons = (polygons) => ({
	type: "MultiPolygon",
	coordinates: polygons,
});
