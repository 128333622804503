import React from "react";
import {
	CircularProgress,
	Typography,
	StylesProvider,
	createGenerateClassName,
	Collapse,
} from "@material-ui/core";
import { SnackbarProvider } from "notistack";

import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from "react-router-dom";
import { Provider as StateProvider, useSelector } from "react-redux";

/* local imports */
import { ServiceProvider } from "./Service";
import Header from "./Header";

import { ThemeProvider } from "@material-ui/core";

import { QueryParamProvider } from "use-query-params";
import { ReactRouter5Adapter } from "use-query-params/adapters/react-router-5";

/* federated imports */
/* eslint-disable */
const Home = React.lazy(() =>
	import("home/Home").then((module) => ({ default: module.Home }))
);
const Login = React.lazy(() =>
	import("home/Login").then((module) => ({ default: module.Login }))
);
// const Registration = React.lazy(() =>
//   import("home/Registration").then((module) => ({
//     default: module.Registration,
//   }))
// );
const Observer = React.lazy(() => import("observer/ObserverService"));
const Snapper = React.lazy(() => import("snapper/SnapperService"));
// const Port = React.lazy(() => import("port/PortService"));
// const Lens = React.lazy(() => import("lens/LensService"));
import theme from "@/theme";
import { store } from "@/redux";
import { RegistrationProvider } from "home/Registration";
import { ResetPassword } from "home/Home";
import { AdminDashboard } from "home/Admin";
/* eslint-enable */

// const LensRoute = () => (
//   <React.Suspense fallback={<LoadingShell message="Preparing Lens..." />}>
//     <Lens />
//   </React.Suspense>
// );
// const PortRoute = () => (
//   <React.Suspense fallback={<LoadingShell message="Preparing Port..." />}>
//     <Port />
//   </React.Suspense>
// );
const SnapperRoute = () => (
	<React.Suspense fallback={<LoadingShell message="Preparing Snapper..." />}>
		<Snapper />
	</React.Suspense>
);
const ObserverRoute = () => (
	<React.Suspense fallback={<LoadingShell message="Preparing Observer..." />}>
		<Observer />
	</React.Suspense>
);
const HomeRoute = () => (
	<React.Suspense fallback={<LoadingShell message="Preparing RouteLab..." />}>
		<Home />
	</React.Suspense>
);
const LoginRoute = () => (
	<React.Suspense fallback={<LoadingShell message="Checking credentials..." />}>
		<Login />
	</React.Suspense>
);
const RegistrationRoute = () => (
	<React.Suspense fallback={<LoadingShell message="Welcome to RouteLab!" />}>
		{/* <Registration /> */}
	</React.Suspense>
);
const AdminRoute = () => (
	<React.Suspense fallback={<LoadingShell message="Welcome to RouteLab!" />}>
		<AdminDashboard />
	</React.Suspense>
);

const ProtectedRoute = ({ component: Component, ...rest }) => {
	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
	return (
		<Route
			{...rest}
			render={(props) =>
				isAuthenticated ? (
					<Component {...props} />
				) : (
					<Redirect
						to={{ pathname: "/login", state: { from: props.location } }}
					/>
				)
			}
		/>
	);
};

// const RouteWithHeader = ({ header: Header, component: Component, ...rest }) => (
//   <Route
//     {...rest}
//     render={(props) => (
//       <>
//         <Component {...props} />
//       </>
//     )}
//   />
// );

const ServiceRoutes = () => (
	<Switch>
		{/* <ProtectedRoute path="/:projectId/lens" component={LensRoute} /> */}
		<ProtectedRoute path="/:projectId/observer" component={ObserverRoute} />
		<ProtectedRoute path="/:projectId/snapper" component={SnapperRoute} />
		{/* <ProtectedRoute path="/:datasetId/port" component={PortRoute} /> */}
	</Switch>
);

const HomeComponent = () => (
	<>
		<Header />
		<HomeRoute />
	</>
);
const AdminComponent = () => (
	<>
		<Header />
		<AdminRoute />{" "}
	</>
);

const generateClassName = createGenerateClassName({
	// disableGlobal: true,
	productionPrefix: "shell",
	seed: "shell",
});
const Shell = () => {
	return (
		<ThemeProvider theme={theme}>
			<StateProvider store={store}>
				<StylesProvider generateClassName={generateClassName}>
					<ServiceProvider>
						<RegistrationProvider>
							<SnackbarProvider
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "center",
								}}
								TransitionComponent={Collapse}
								autoHideDuration={3000}
							>
								<Router>
									<QueryParamProvider
										adapter={ReactRouter5Adapter}
										options={{ removeDefaultsFromUrl: true }}
									>
										<Switch>
											<Route path="/reset-password" component={ResetPassword} />
											<Route path="/login" component={LoginRoute} />
											<Route path="/register" component={RegistrationRoute} />

											<ProtectedRoute path="/home" component={HomeComponent} />

											<ProtectedRoute
												exact
												path="/admin"
												component={AdminComponent}
											/>

											<Route path="/:id/:service">
												<Header />
												<ServiceRoutes />
											</Route>

											<Redirect exact from="/" to="/home" />
											<Route component={Error404} />
										</Switch>
									</QueryParamProvider>
								</Router>
							</SnackbarProvider>
						</RegistrationProvider>
					</ServiceProvider>
				</StylesProvider>
			</StateProvider>
		</ThemeProvider>
	);
};

export default Shell;

const Error404 = () => (
	<div stle={{ marginTop: "4em", fontSize: 64 }}>404 Not Found</div>
);

const LoadingShell = (props) => (
	<div
		style={{
			display: "flex",
			height: "80vh",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center",
		}}
	>
		<CircularProgress />
		<br />
		<Typography variant="h4">{props.message}</Typography>
	</div>
);
