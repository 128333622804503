import { Button, withStyles } from "@material-ui/core";
import { red } from "@material-ui/core/colors";

const DangerButton = withStyles((theme) => ({
	root: {
		color: theme.palette.getContrastText(red[600]),
		backgroundColor: red[600],
		"&:hover": {
			backgroundColor: red[800],
		},
	},
}))(Button);

export default DangerButton;
