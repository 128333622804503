import _ from "lodash";
import React from "react";
import { Typography } from "@material-ui/core";
import { CircleMarker, GeoJSON, useMap, Tooltip } from "react-leaflet";

import { NoMapDataAvailable } from "../Leaflet";

const geoToFeature = (geometry, properties = {}) => ({
	type: "Feature",
	geometry,
	properties,
});

const getTripsMap = (args) => {
	const { geometry } = args;
	const geoJsonRef = React.useRef();
	const selected = React.useRef(null);
	const [selectedGeo, setSelectedGeo] = React.useState(null);
	const [activeTerminals, setActiveTerminals] = React.useState([]);
	const map = useMap();
	// const map = useMapEvents({
	//   click(e) {
	//     console.log("click", e);
	//     // setMarkerPos(e.latlng);
	//     map.fitBounds(e.target.getBounds());
	//   },
	// });

	function highlight(layer) {
		if (
			selected.current === null ||
			selected.current._leaflet_id !== layer._leaflet_id
		)
			layer.setStyle({
				color: "yellow",
				weight: 5,
				dashArray: "",
			});
		if (!L.Browser.ie && !L.Browser.opera) {
			layer.bringToFront();
		}
	}

	function dehighlight(layer) {
		if (
			selected.current === null ||
			selected.current._leaflet_id !== layer._leaflet_id
		) {
			geoJsonRef.current.resetStyle(layer);
		}
	}

	function select(layer) {
		let previous = null;
		if (selected.current !== null) {
			previous = selected.current;
		}
		layer.setStyle({
			color: "red",
			weight: 5,
			dashArray: "",
		});
		map.fitBounds(layer.getBounds());
		selected.current = layer;
		setSelectedGeo(layer.feature.geometry);
		console.log({ layer });
		setActiveTerminals([
			geoToFeature(
				_.omit(layer.feature.properties.route.origin_terminal.geometry, "crs"),
				_.omit(layer.feature.properties.route.origin_terminal, "geometry")
			),
			geoToFeature(
				_.omit(
					layer.feature.properties.route.destination_terminal.geometry,
					"crs"
				),
				_.omit(layer.feature.properties.route.destination_terminal, "geometry")
			),
		]);
		if (previous) {
			dehighlight(previous);
		}
	}

	const handleFeatureEvents = (feature, layer) => {
		layer.on({
			mouseover: function (e) {
				highlight(e.target);
			},
			mouseout: function (e) {
				dehighlight(e.target);
			},
			click: function (e) {
				select(e.target);
			},
		});
	};
	// const renderLine = (lineString, key) => (
	//   <GeoJSON key={key} weight={5} data={lineString} />
	// );
	if (!geometry) {
		return <NoMapDataAvailable />;
	}

	return (
		<div>
			{selectedGeo && (
				<ODMarkers
					// key={selected.current.feature.properties.id}
					lineString={selectedGeo}
				/>
			)}
			{activeTerminals.length && <TerminalLabels terminals={activeTerminals} />}
			{/* {geometry?.lineStrings?.length &&
        geometry?.lineStrings.map((ls, idx) => renderLine(ls, idx))} */}
			{geometry?.lineStrings?.length && (
				<GeoJSON
					ref={geoJsonRef}
					weight={2}
					data={geometry.lineStrings}
					onEachFeature={handleFeatureEvents}
				/>
			)}
			{/* {geometry && getGeoJSONMap({ geometry })} */}
		</div>
	);
};

export default getTripsMap;

const ODMarkers = ({ lineString }) => {
	if (lineString && lineString?.coordinates?.length) {
		const lastEl = lineString.coordinates.length - 1;
		const lineStart = lineString.coordinates[0] || [0, 0];
		const lineEnd = lineString.coordinates[lastEl] || [0, 1];

		const originLatLng = L.marker([lineStart[1], lineStart[0]]).getLatLng();
		const destinationLatLng = L.marker([lineEnd[1], lineEnd[0]]).getLatLng();

		return (
			<div>
				<CircleMarker
					center={originLatLng}
					radius={7}
					color={"#3e3636"}
					fillOpacity={1}
					opacity={0.3}
					weight={7}
				/>
				<CircleMarker
					center={destinationLatLng}
					radius={7}
					color={"#ff1f5a"}
					fillOpacity={1}
					opacity={0.3}
					weight={12}
				/>
			</div>
		);
	}

	return null;
};

const TerminalLabels = ({ terminals }) => {
	console.log(terminals);
	return terminals.map((t) => (
		<GeoJSON data={t} key={t.properties.id}>
			<Tooltip permanent opacity={0.8}>
				<Typography>{t.properties.name}</Typography>
			</Tooltip>
		</GeoJSON>
	));
};
