export default {
	countries: [
		{
			states: [
				{
					cities: [
						"Eshkashem",
						"Fayzabad",
						"Jurm",
						"Khandud",
						"Qal'eh-ye Panjeh",
					],
					stateName: "Badakhshan",
				},
				{ cities: ["Bala Morghab", "Qal'eh-ye Naw"], stateName: "Badgis" },
				{
					cities: [
						"Andarab",
						"Baghlan",
						"Dahaneh-ye Ghawri",
						"Nahrin",
						"Pol-e Khumri",
					],
					stateName: "Baglan",
				},
				{
					cities: [
						"Balkh",
						"Dawlatabad",
						"Mazar-e Sharif",
						"Qarchi Gak",
						"Shulgara",
						"Tash Gozar",
					],
					stateName: "Balkh",
				},
				{ cities: ["Bamiyan", "Panjab", "Qil Qal'eh"], stateName: "Bamiyan" },
				{ cities: ["Anar Darreh", "Farah", "Shindand"], stateName: "Farah" },
				{
					cities: ["Andkhvoy", "Darzi Ab", "Dawlatabad", "Maymanah"],
					stateName: "Faryab",
				},
				{ cities: ["Chaghcharan", "Shahrak", "Taywarah"], stateName: "Gawr" },
				{ cities: ["Ghazni"], stateName: "Gazni" },
				{
					cities: [
						"Awbeh",
						"Eslam Qal'eh",
						"Ghurian",
						"Herat",
						"Karukh",
						"Kuhestan",
						"Kushk",
						"Qarabagh",
						"Tawraghudi",
						"Tir Pol",
						"Zendejan",
					],
					stateName: "Herat",
				},
				{
					cities: [
						"Baghran",
						"Darwishan",
						"Deh Shu",
						"Gereshk",
						"Lashkar Gah",
						"Sangin",
					],
					stateName: "Hilmand",
				},
				{
					cities: ["Aqchah", "Qarqin", "Sang-e Charak", "Shibarghan"],
					stateName: "Jawzjan",
				},
				{
					cities: [
						"Baghrami",
						"Kabul",
						"Mir Bachchekut",
						"Paghman",
						"Qarabagh",
						"Sarawbi",
					],
					stateName: "Kabul",
				},
				{ cities: ["Mahmud-e Raqi", "Taghab"], stateName: "Kapisa" },
				{ cities: ["Khawst"], stateName: "Khawst" },
				{ cities: ["Asadabad", "Asmar"], stateName: "Kunar" },
				{ cities: ["Mehtar Lam"], stateName: "Lagman" },
				{
					cities: ["Azraw", "Baraki Barak", "Pol-e Alam"],
					stateName: "Lawghar",
				},
				{
					cities: [
						"Achin",
						"Batsawul",
						"Hugyani",
						"Jalalabad",
						"Nader Shah Kawt",
					],
					stateName: "Nangarhar",
				},
				{
					cities: [
						"Chahar Burjak",
						"Chakhansur",
						"Khash",
						"Mirabad",
						"Rudbar",
						"Zaranj",
					],
					stateName: "Nimruz",
				},
				{ cities: ["Nuristan"], stateName: "Nuristan" },
				{
					cities: ["Orgun", "Zareh Sharan", "Zarghun Shahr"],
					stateName: "Paktika",
				},
				{ cities: ["'Ali Khayl", "Ghardez"], stateName: "Paktiya" },
				{ cities: ["Charikar", "Jabal-os-Saraj"], stateName: "Parwan" },
				{ cities: ["Qandahar"], stateName: "Qandahar" },
				{
					cities: [
						"Dasht-e Archa",
						"Emam Saheb",
						"Hazart Imam",
						"Khanabad",
						"Qal'eh-ye Zal",
						"Qunduz",
					],
					stateName: "Qunduz",
				},
				{ cities: ["Aybak", "Kholm"], stateName: "Samangan" },
				{ cities: [], stateName: "Sar-e Pul" },
				{
					cities: [
						"Chah Ab",
						"Eshkamesh",
						"Farkhar",
						"Khwajeh Ghar",
						"Rostaq",
						"Taloqan",
						"Yangi Qal'eh",
					],
					stateName: "Takhar",
				},
				{
					cities: ["Deh Rawud", "Gaz Ab", "Tarin Kawt", "Uruzgan"],
					stateName: "Uruzgan",
				},
				{ cities: ["Gardan Diwal", "Maydanshahr"], stateName: "Wardag" },
				{ cities: ["Qalat-e Ghilzay"], stateName: "Zabul" },
			],
			countryName: "Afghanistan",
		},
		{
			states: [
				{ cities: ["Berat", "Polican", "Ure Vajgurore"], stateName: "Berat" },
				{ cities: ["Bulqize"], stateName: "Bulqize" },
				{ cities: ["Delvine"], stateName: "Delvine" },
				{ cities: ["Bilisht"], stateName: "Devoll" },
				{ cities: ["Maqellare", "Peshkopi"], stateName: "Dibre" },
				{ cities: ["Durres", "Shijak"], stateName: "Durres" },
				{ cities: ["Cerrik", "Elbasan", "Kerrabe"], stateName: "Elbasan" },
				{ cities: ["Fier", "Patos", "Roskovec"], stateName: "Fier" },
				{ cities: ["Gjirokaster", "Libohove"], stateName: "Gjirokaster" },
				{ cities: ["Gramsh"], stateName: "Gramsh" },
				{ cities: ["Krume"], stateName: "Has" },
				{ cities: ["Kavaje"], stateName: "Kavaje" },
				{ cities: ["Erseke", "Leskovik"], stateName: "Kolonje" },
				{ cities: ["Korce", "Maliq"], stateName: "Korce" },
				{
					cities: ["Fushe-Kruje", "Kruje", "Mamurras", "Milot"],
					stateName: "Kruje",
				},
				{ cities: ["Kucove"], stateName: "Kucove" },
				{ cities: ["Kukes"], stateName: "Kukes" },
				{ cities: ["Lac"], stateName: "Kurbin" },
				{ cities: ["Lezhe", "Shengjin"], stateName: "Lezhe" },
				{ cities: ["Librazhd", "Perrenjas"], stateName: "Librazhd" },
				{ cities: ["Lushnje", "Rrogozhine"], stateName: "Lushnje" },
				{ cities: ["Ballsh"], stateName: "Mallakaster" },
				{ cities: [], stateName: "Malsi e Madhe" },
				{ cities: ["Burrel", "Klos", "Kraste", "Ulze"], stateName: "Mat" },
				{ cities: ["Kurbnesh", "Rreshen", "Rubik"], stateName: "Mirdite" },
				{ cities: ["Peqin"], stateName: "Peqin" },
				{ cities: ["Kelcyre", "Permet"], stateName: "Permet" },
				{ cities: ["Pogradec"], stateName: "Pogradec" },
				{ cities: ["Fierze", "Fushe-Arrez", "Puke"], stateName: "Puke" },
				{ cities: ["Konispol", "Sarande"], stateName: "Sarande" },
				{ cities: ["Shkoder"], stateName: "Shkoder" },
				{ cities: ["Corovode"], stateName: "Skrapar" },
				{ cities: ["Memaliaj", "Tepelene"], stateName: "Tepelene" },
				{
					cities: ["Kamze", "Paskuqan", "Tirane", "Vore"],
					stateName: "Tirane",
				},
				{ cities: ["Bajram Curri"], stateName: "Tropoje" },
				{
					cities: ["Himare", "Orikum", "Selenice", "Vlore"],
					stateName: "Vlore",
				},
			],
			countryName: "Albania",
		},
		{
			states: [
				{ cities: [], stateName: "'Ayn Daflah" },
				{ cities: [], stateName: "'Ayn Tamushanat" },
				{ cities: ["Adrar", "Awlaf", "Rijan", "Timimun"], stateName: "Adrar" },
				{ cities: ["Hydra", "Kouba"], stateName: "Algiers" },
				{
					cities: [
						"Annabah",
						"Birrahhal",
						"Saraydih",
						"Sidi Amar",
						"al-Buni",
						"al-Hajar",
					],
					stateName: "Annabah",
				},
				{
					cities: ["'Abadlah", "Bani Wanif", "Bashshar", "Qanadsan", "Taghit"],
					stateName: "Bashshar",
				},
				{
					cities: [
						"'Aris",
						"'Ayn Tutah",
						"Barikah",
						"Batnah",
						"Marwanah",
						"Naghaus",
						"Ra's-al-'Ayun",
						"Tazult",
					],
					stateName: "Batnah",
				},
				{
					cities: [
						"'Ayt Rizin",
						"Akbu",
						"Amizur",
						"Barbasha",
						"Bijayah",
						"Farrawn",
						"Ighram",
						"Sadduk",
						"Shamini",
						"Sidi 'Aysh",
						"Taskaryut",
						"Tazmalt",
						"Timazrit",
						"Uz-al-Laqin",
						"al-Qasr",
					],
					stateName: "Bijayah",
				},
				{
					cities: [
						"Awlad Jallal",
						"Biskrah",
						"Sidi Khalid",
						"Sidi Ukbah",
						"Tulja",
						"Um'ash",
						"Zaribat-al-Wad",
					],
					stateName: "Biskrah",
				},
				{
					cities: [
						"Awlad Salam",
						"Awlad Yaysh",
						"Bani Khalil",
						"Bani Marad",
						"Bani Tamu",
						"Blidah",
						"Bu Arfa",
						"Bufarik",
						"Buinan",
						"Buqara",
						"Maftah",
						"Muzayah",
						"Shabli",
						"Shari'ah",
						"Shiffa",
						"Sidi Mussa",
						"Suma",
						"Wadi al-Allagh",
						"al-'Afrun",
						"al-Arba'a",
					],
					stateName: "Blidah",
				},
				{
					cities: [
						"'Ayn Bissim",
						"Aghbalu",
						"Bi'r Ghabalu",
						"Buirah",
						"Lakhdariyah",
						"Shurfa",
						"Sur-al-Ghuzlan",
					],
					stateName: "Buirah",
				},
				{
					cities: [
						"'Ayn Tayah",
						"Awlad Haddaj",
						"Awlad Mussa",
						"Bani Amran",
						"Budwawu",
						"Budwawu al-Bahri",
						"Bumardas",
						"Burj Minayal",
						"Dalis",
						"Hammadi",
						"Issar",
						"Khamis-al-Khashnah",
						"Nasiriyah",
						"Raghayah",
						"Sa'abat",
						"Tinyah",
						"al-Arba'a Tash",
						"ar-Ruwibah",
					],
					stateName: "Bumardas",
				},
				{ cities: [], stateName: "Burj Bu Arririj" },
				{
					cities: [
						"Ammi Mussa",
						"Ghalizan",
						"Jidiwiyah",
						"Mazunah",
						"Sidi Muhammad Ban 'Ali",
						"Wadi Rahiyu",
						"Zammurah",
					],
					stateName: "Ghalizan",
				},
				{
					cities: [
						"Biryan",
						"Bu Nura",
						"Ghardaia",
						"Ghardayah",
						"Matlili",
						"al-Ghuli'ah",
						"al-Qararah",
					],
					stateName: "Ghardayah",
				},
				{ cities: ["Ilizi"], stateName: "Ilizi" },
				{
					cities: [
						"Amir 'Abd-al-Qadar",
						"Jijili",
						"Shifka",
						"Tahar",
						"al-Miliyah",
					],
					stateName: "Jijili",
				},
				{
					cities: [
						"'Ayn Wissarah",
						"'Ayn-al-Ibil",
						"Birin",
						"Dar Shiyukh",
						"Hassi Bahbah",
						"Jilfah",
						"Mis'ad",
						"Sharif",
						"al-Idrisiyah",
					],
					stateName: "Jilfah",
				},
				{
					cities: ["Khanshalah", "Sharshar", "Tawziyanat", "al-Mahmal"],
					stateName: "Khanshalah",
				},
				{
					cities: [
						"'Ayn-al-Hajal",
						"'Ayn-al-Milh",
						"Bu Sa'adah",
						"Hammam Dhala'a",
						"Ma'adid",
						"Maghra",
						"Masilah",
						"Sidi 'Aysa",
						"Wanugha",
					],
					stateName: "Masilah",
				},
				{
					cities: [
						"'Ayn Bu Sif",
						"Birwaghiyah",
						"Midyah",
						"Qasr-al-Bukhari",
						"Shillalah",
						"Tablat",
					],
					stateName: "Midyah",
				},
				{
					cities: [
						"Farjiwah",
						"Milah",
						"Qararam Quqa",
						"Ruwashad",
						"Salghum-al-'Ayd",
						"Sidi Maruf",
						"Sidi Marwan",
						"Tajananah",
						"Talighmah",
						"Wadi Athmaniyah",
					],
					stateName: "Milah",
				},
				{
					cities: [
						"Bu Khanifiyah",
						"Muaskar",
						"Muhammadiyah",
						"Siq",
						"Tighinnif",
						"Wadi al-Abtal",
						"Zahana",
					],
					stateName: "Muaskar",
				},
				{
					cities: [
						"'Ayn Tadalas",
						"Hassi Mamash",
						"Mazaghran",
						"Mustaghanam",
						"Sidi Ali",
					],
					stateName: "Mustaghanam",
				},
				{ cities: ["'Ayn Safra", "Mishriyah", "Naama"], stateName: "Naama" },
				{ cities: ["Oran"], stateName: "Oran" },
				{ cities: ["Ouargla"], stateName: "Ouargla" },
				{
					cities: [
						"'Ayn Bardah",
						"Bumahra Ahmad",
						"Hamman Awlad 'Ali",
						"Qalmah",
						"Wadi Zinati",
					],
					stateName: "Qalmah",
				},
				{
					cities: [
						"'Ayn Abid",
						"'Ayn Samara",
						"Didush Murad",
						"Hamma Bu Ziyan",
						"Qustantinah",
						"Zighut Yusuf",
						"al-Khurub",
					],
					stateName: "Qustantinah",
				},
				{
					cities: [
						"'Azzabah",
						"Amjaz Adshish",
						"Fil Fila",
						"Karkira",
						"Ramadan Jamal",
						"Sakikdah",
						"Shataybih",
						"Tamalus",
						"al-Harush",
						"al-Qull",
					],
					stateName: "Sakikdah",
				},
				{
					cities: [
						"'Ayn 'Azl",
						"'Ayn Arnat",
						"'Ayn Taqrut",
						"'Ayn Wilman",
						"'Ayn-al-Khabira",
						"Bouira",
						"Buq'ah",
						"Salah Bay",
						"Satif",
						"Setif",
						"Ziyama Mansuriyah",
						"al-'Ulmah",
					],
					stateName: "Satif",
				},
				{ cities: ["'Ayn-al-Hajar", "Sayda'"], stateName: "Sayda'" },
				{ cities: [], stateName: "Sidi ban-al-'Abbas" },
				{ cities: [], stateName: "Suq Ahras" },
				{
					cities: ["'Ayn Qazzan", "'Ayn Salah", "Tamanghasat"],
					stateName: "Tamanghasat",
				},
				{
					cities: [
						"'Ayn Binyan",
						"Bu Isma'il",
						"Bu Midfar'ah",
						"Damus",
						"Duwirah",
						"Hajut",
						"Hammam Righa",
						"Sawlah",
						"Shiragha",
						"Shirshall",
						"Sidi Farj",
						"Stawali",
						"Tibazah",
						"Ziralda",
						"al-Qull'ah",
					],
					stateName: "Tibazah",
				},
				{
					cities: [
						"Bi'r-al-'Itir",
						"Hammamat",
						"Mursut",
						"Shariyah",
						"Tibissah",
						"Winzah",
						"al-'Awaynat",
					],
					stateName: "Tibissah",
				},
				{
					cities: [
						"Awlad Mimun",
						"Bani Mastar",
						"Bani Sikran",
						"Ghazawat",
						"Hannayah",
						"Maghniyah",
						"Nidruma",
						"Ramsh",
						"Sabra",
						"Shatwan",
						"Sibdu",
						"Sidi 'Abdallah",
						"Tilimsan",
						"al-Mansurah",
					],
					stateName: "Tilimsan",
				},
				{ cities: ["Tinduf"], stateName: "Tinduf" },
				{ cities: ["Thaniyat-al-Had", "Tisamsilt"], stateName: "Tisamsilt" },
				{
					cities: [
						"'Ayn Dhahab",
						"Firindah",
						"Mahdiyah",
						"Mashra'a Asfa",
						"Qasr Shillalah",
						"Rahuyah",
						"Sughar",
						"Takhamarat",
						"Tiyarat",
					],
					stateName: "Tiyarat",
				},
				{ cities: [], stateName: "Tizi Wazu" },
				{
					cities: [
						"'Ayn Bayda",
						"'Ayn Fakrun",
						"'Ayn Kirshah",
						"'Ayn Malilah",
						"Bi'r Shuhada",
						"Miskyanah",
						"Shamurah",
						"Umm-al-Bawaghi",
					],
					stateName: "Umm-al-Bawaghi",
				},
				{
					cities: [
						"'Ayn Biya",
						"'Ayn-at-Turk",
						"Arzu",
						"Bi'r-al-Jir",
						"Butlilis",
						"Hassi Bu Nif",
						"Mars-al-Kabir",
						"Qadayal",
						"Sidi ash-Shami",
						"Wadi Thalatha",
						"Wahran",
						"al-Ansur",
						"as-Saniyah",
					],
					stateName: "Wahran",
				},
				{
					cities: [
						"Hassi Mas'ud",
						"Nazla",
						"Ruwisiyat",
						"Tabisbast",
						"Tamalhat",
						"Tamasin",
						"Tayabat-al-Janubiyah",
						"Tughghurt",
						"Warqla",
						"al-Hajirah",
					],
					stateName: "Warqla",
				},
				{ cities: [], stateName: "Wilaya d Alger" },
				{ cities: [], stateName: "Wilaya de Bejaia" },
				{ cities: [], stateName: "Wilaya de Constantine" },
				{
					cities: ["Aflu", "Hassi al-Raml", "al-Aghwat"],
					stateName: "al-Aghwat",
				},
				{
					cities: ["Brizyanah", "al-Abyad Sidi Shaykh", "al-Bayadh"],
					stateName: "al-Bayadh",
				},
				{
					cities: [
						"Bab Azwar",
						"Baraki",
						"Bir Murad Rais",
						"Birkhadam",
						"Burj-al-Kiffan",
						"Dar-al-Bayda",
						"al-Jaza'ir",
					],
					stateName: "al-Jaza'ir",
				},
				{
					cities: [
						"Bayadha",
						"Dabilah",
						"Hassan 'Abd-al-Karim",
						"Hassi Halifa",
						"Jama'a",
						"Maqran",
						"Qamar",
						"Raqiba",
						"Rubbah",
						"Sidi Amran",
						"al-Mighair",
						"al-Wad",
					],
					stateName: "al-Wad",
				},
				{
					cities: [
						"'Ayn Maran",
						"Abu al-Hassan",
						"Bani Hawa",
						"Bu Qadir",
						"Sidi Ukaskah",
						"Tanas",
						"Wadi Sali",
						"Wadi al-Fiddah",
						"ash-Shalif",
						"ash-Shattiyah",
					],
					stateName: "ash-Shalif",
				},
				{
					cities: [
						"Ban Mahdi",
						"Bani Amar",
						"Basbas",
						"Dariyan",
						"Saba'ita Muk",
						"al-Qal'ah",
						"at-Tarif",
					],
					stateName: "at-Tarif",
				},
			],
			countryName: "Algeria",
		},
		{
			states: [
				{
					cities: [
						"Afono",
						"Alao",
						"Alofau",
						"Amouli",
						"Aoa",
						"Atu'u",
						"Aua",
						"Aunu'u",
						"Faga'alu",
						"Faga'itua",
						"Fagasa",
						"Fagatogo",
						"Lauli'i",
						"Leloaloa",
						"Nu'uuli",
						"Pago Pago",
						"Tula",
						"Utulei",
						"Vatia",
					],
					stateName: "Eastern",
				},
				{ cities: ["Ofu"], stateName: "Manu'a" },
				{ cities: [], stateName: "Swains Island" },
				{
					cities: [
						"Aoloau",
						"Faleniu",
						"Futiga",
						"Ili'ili",
						"Leone",
						"Malaeimi",
						"Malaeloa-Aitulagi",
						"Malaeloa-Ituau",
						"Mapusagafou",
						"Mesepa",
						"Pava'ia'i",
						"Tafuna",
						"Taputimu",
						"Vailoatai",
						"Vaitogi",
					],
					stateName: "Western",
				},
			],
			countryName: "American Samoa",
		},
		{
			states: [
				{ cities: [], stateName: "Andorra la Vella" },
				{ cities: ["Canillo"], stateName: "Canillo" },
				{ cities: ["Encamp"], stateName: "Encamp" },
				{ cities: [], stateName: "La Massana" },
				{ cities: [], stateName: "Les Escaldes" },
				{ cities: ["Ordino"], stateName: "Ordino" },
				{ cities: [], stateName: "Sant Julia de Loria" },
			],
			countryName: "Andorra",
		},
		{
			states: [
				{ cities: ["Caxito"], stateName: "Bengo" },
				{ cities: ["Benguela", "Lobito"], stateName: "Benguela" },
				{
					cities: ["Camacupa", "Catabola", "Catumbela", "Chissamba", "Kuito"],
					stateName: "Bie",
				},
				{ cities: ["Cabinda"], stateName: "Cabinda" },
				{ cities: ["Ondjiva"], stateName: "Cunene" },
				{
					cities: ["Caala", "Catchiungo", "Huambo", "Longonjo"],
					stateName: "Huambo",
				},
				{ cities: ["Caconda", "Caluquembe", "Lubango"], stateName: "Huila" },
				{ cities: ["Menongue"], stateName: "Kuando-Kubango" },
				{ cities: [], stateName: "Kwanza Norte" },
				{ cities: [], stateName: "Kwanza Sul" },
				{ cities: ["Luanda"], stateName: "Luanda" },
				{ cities: [], stateName: "Lunda Norte" },
				{ cities: [], stateName: "Lunda Sul" },
				{ cities: ["Malanje"], stateName: "Malanje" },
				{
					cities: ["Cazaje", "Leua", "Luau", "Luena", "Lumeje"],
					stateName: "Moxico",
				},
				{ cities: ["Namibe"], stateName: "Namibe" },
				{ cities: ["Camabatela", "Uige"], stateName: "Uige" },
				{ cities: ["M'banza-Kongo", "N'zeto", "Soyo"], stateName: "Zaire" },
			],
			countryName: "Angola",
		},
		{
			states: [{ cities: [], stateName: "Other Provinces" }],
			countryName: "Anguilla",
		},
		{
			states: [
				{ cities: [], stateName: "Sector claimed by Argentina/Ch" },
				{ cities: [], stateName: "Sector claimed by Argentina/UK" },
				{ cities: [], stateName: "Sector claimed by Australia" },
				{ cities: [], stateName: "Sector claimed by France" },
				{ cities: [], stateName: "Sector claimed by New Zealand" },
				{ cities: [], stateName: "Sector claimed by Norway" },
				{ cities: [], stateName: "Unclaimed Sector" },
			],
			countryName: "Antarctica",
		},
		{
			states: [
				{ cities: ["Codrington"], stateName: "Barbuda" },
				{ cities: [], stateName: "Saint George" },
				{ cities: [], stateName: "Saint John" },
				{ cities: [], stateName: "Saint Mary" },
				{ cities: [], stateName: "Saint Paul" },
				{ cities: [], stateName: "Saint Peter" },
				{ cities: [], stateName: "Saint Philip" },
			],
			countryName: "Antigua And Barbuda",
		},
		{
			states: [
				{ cities: [], stateName: "Buenos Aires" },
				{ cities: ["Catamarca"], stateName: "Catamarca" },
				{
					cities: ["Presidencia Roque Saenz Pena", "Resistencia"],
					stateName: "Chaco",
				},
				{
					cities: ["Comodoro Rivadavia", "Puerto Madryn", "Rawson", "Trelew"],
					stateName: "Chubut",
				},
				{
					cities: [
						"Alta Gracia",
						"Bell Ville",
						"Cordoba",
						"Rio Cuarto",
						"Rio Tercero",
						"San Francisco",
						"Villa Carlos Paz",
						"Villa Maria",
					],
					stateName: "Cordoba",
				},
				{
					cities: ["Corrientes", "Goya", "Mercedes", "San Lorenzo"],
					stateName: "Corrientes",
				},
				{ cities: [], stateName: "Distrito Federal" },
				{ cities: [], stateName: "Entre Rios" },
				{ cities: ["Formosa"], stateName: "Formosa" },
				{
					cities: ["Jujuy", "Libertador General San Martin", "San Pedro"],
					stateName: "Jujuy",
				},
				{ cities: [], stateName: "La Pampa" },
				{ cities: [], stateName: "La Rioja" },
				{
					cities: [
						"Godoy Cruz",
						"Lujan",
						"Mendoza",
						"Rivadavia",
						"San Martin",
						"San Rafael",
					],
					stateName: "Mendoza",
				},
				{ cities: ["Eldorado", "Obera", "Posadas"], stateName: "Misiones" },
				{ cities: ["Cutral Co", "Neuquen"], stateName: "Neuquen" },
				{ cities: [], stateName: "Rio Negro" },
				{
					cities: ["Salta", "San Ramon de la Nueva Oran", "Tartagal"],
					stateName: "Salta",
				},
				{ cities: [], stateName: "San Juan" },
				{ cities: [], stateName: "San Luis" },
				{ cities: [], stateName: "Santa Cruz" },
				{ cities: [], stateName: "Santa Fe" },
				{ cities: [], stateName: "Santiago del Estero" },
				{ cities: [], stateName: "Tierra del Fuego" },
				{ cities: ["Tucuman"], stateName: "Tucuman" },
			],
			countryName: "Argentina",
		},
		{
			states: [
				{ cities: ["Ashtarak", "Talin"], stateName: "Aragatsotn" },
				{ cities: ["Ararat", "Artashat"], stateName: "Ararat" },
				{ cities: ["Armavir", "Vagharshapat"], stateName: "Armavir" },
				{
					cities: ["Gavar", "Martuni", "Sevan", "Vardenis"],
					stateName: "Gegharkunik",
				},
				{
					cities: ["Abovyan", "Arzni", "Charentsavan", "Hrazdan"],
					stateName: "Kotaik",
				},
				{
					cities: [
						"Akhtala",
						"Alaverdi",
						"Spitak",
						"Stepanavan",
						"Tashir",
						"Vanadzor",
					],
					stateName: "Lori",
				},
				{ cities: ["Artik", "Gyumri"], stateName: "Shirak" },
				{ cities: ["Stepanakert"], stateName: "Stepanakert" },
				{
					cities: [
						"Angeghakot",
						"Goris",
						"Kajaran",
						"Kapan",
						"Meghri",
						"Sisian",
					],
					stateName: "Syunik",
				},
				{ cities: ["Dilijan", "Ijevan"], stateName: "Tavush" },
				{ cities: [], stateName: "Vayots Dzor" },
				{ cities: ["Yerevan"], stateName: "Yerevan" },
			],
			countryName: "Armenia",
		},
		{
			states: [{ cities: ["Aruba"], stateName: "Aruba" }],
			countryName: "Aruba",
		},
		{
			states: [
				{
					cities: ["Glenfield", "Henderson", "Milford"],
					stateName: "Auckland",
				},
				{ cities: [], stateName: "Australian Capital Territory" },
				{ cities: ["Balgowlah"], stateName: "Balgowlah" },
				{ cities: ["Balmain"], stateName: "Balmain" },
				{ cities: ["Bankstown"], stateName: "Bankstown" },
				{ cities: [], stateName: "Baulkham Hills" },
				{ cities: [], stateName: "Bonnet Bay" },
				{ cities: ["Camberwell"], stateName: "Camberwell" },
				{ cities: [], stateName: "Carole Park" },
				{ cities: [], stateName: "Castle Hill" },
				{ cities: ["Caulfield"], stateName: "Caulfield" },
				{ cities: ["Chatswood"], stateName: "Chatswood" },
				{ cities: ["Cheltenham"], stateName: "Cheltenham" },
				{ cities: ["Cherrybrook"], stateName: "Cherrybrook" },
				{ cities: ["Clayton"], stateName: "Clayton" },
				{ cities: ["Collingwood"], stateName: "Collingwood" },
				{ cities: [], stateName: "Frenchs Forest" },
				{ cities: ["Hawthorn"], stateName: "Hawthorn" },
				{ cities: ["Jannnali"], stateName: "Jannnali" },
				{ cities: ["Knoxfield"], stateName: "Knoxfield" },
				{ cities: ["Melbourne", "South Oakleigh"], stateName: "Melbourne" },
				{ cities: [], stateName: "New South Wales" },
				{ cities: [], stateName: "Northern Territory" },
				{
					cities: [
						"Cottesole",
						"Darch",
						"Mullaloo",
						"Noranda",
						"Osborne Park",
						"Willetton",
					],
					stateName: "Perth",
				},
				{
					cities: [
						"Acacia Ridge",
						"Aitkenvale",
						"Alderley",
						"Alexander Hills",
						"Archerfield",
						"Ashmore",
						"Atherton",
						"Ayr",
						"Beachmere",
						"Beenleigh",
						"Beerwah",
						"Bell",
						"Belmont",
						"Biloela",
						"Birkdale",
						"Blackwater",
						"Bongaree",
						"Bonogin",
						"Boonah",
						"Bowen",
						"Brendale",
						"Brisbane",
						"Buddina",
						"Buderim",
						"Bundaberg",
						"Bundall",
						"Burleigh Heads",
						"Burpengary",
						"Cabaland",
						"Caboolture",
						"Cairns",
						"Cairns Northern Beaches",
						"Caloundra",
						"Capalaba",
						"Charters Towers",
						"Childers",
						"Cleveland",
						"Coolum Beach",
						"Coopers Plain",
						"Dalby",
						"Deception Bay",
						"Eagle Farm",
						"Eagleby",
						"Emerald",
						"Emundi",
						"Forest Lake",
						"Gatton",
						"Gladstone",
						"Gold Coast",
						"Gowrie Junction",
						"Gympie",
						"Helensvale",
						"Hervey Bay",
						"Ingham",
						"Innisfail",
						"Ipswich",
						"Jindalee",
						"Julatten",
						"Kawana Waters",
						"Kingaroy",
						"Lawnton",
						"Loganholme",
						"Mackay",
						"Mareeba",
						"Maroochydore",
						"Maroochydore-Mooloolaba",
						"Maryborough",
						"Miami",
						"Milton",
						"Molendinar",
						"Moranbah",
						"Morayfield",
						"Mossman",
						"Mount Glorious",
						"Mount Isa",
						"Murarrie",
						"Nairand Kay",
						"Nambour",
						"Nerang",
						"Nobby Beach",
						"Noosa Heads",
						"Noosaville",
						"Paddington",
						"Port Douglas",
						"Robina",
						"Rockhampton",
						"Rocklea",
						"Roma",
						"Rubyvale",
						"Sanctuary Cove",
						"Slade Point",
						"Southport",
						"Suburb",
						"Sunshine Coast",
						"Tewantin-Noosa",
						"The Summit",
						"Toowong",
						"Toowoomba",
						"Townsville",
						"Victoria Point",
						"Warwick",
						"Willawong",
						"Yatala",
						"Yeppoon",
						"Zillmere",
					],
					stateName: "Queensland",
				},
				{ cities: [], stateName: "South Australia" },
				{
					cities: [
						"Bridgewater-Gagebrook",
						"Burnie",
						"Burnie-Devonport",
						"Glenorchy",
						"Hobart",
						"Kingston-Blackmans Bay",
						"Launceston",
						"New Norfolk",
						"Rowella",
						"Sheffield",
						"Ulverstone",
					],
					stateName: "Tasmania",
				},
				{ cities: ["Templestowe"], stateName: "Templestowe" },
				{
					cities: [
						"Albion",
						"Ararat",
						"Avenel",
						"Avondale Heights",
						"Baccus Marsh",
						"Bairnsdale",
						"Ballarat",
						"Balwyn",
						"Batman",
						"Bayswater",
						"Belmont",
						"Benalla",
						"Bendigo",
						"Bentleigh East",
						"Berwick",
						"Blackburn",
						"Blue Mountains",
						"Boonah",
						"Boronia",
						"Box Hill",
						"Brighton East",
						"Bundoora",
						"Campbellfield",
						"Carlton South",
						"Caroline Springs",
						"Castle Hill",
						"Castlemaine",
						"Caulfield",
						"Cheltenham",
						"Chester",
						"Clayton South",
						"Clifton Springs",
						"Coburg",
						"Colac",
						"Collingwood",
						"Craigieburn",
						"Cranbourne",
						"Crib Point",
						"Dandenong",
						"Devon Meadows",
						"Diamond Creek",
						"Doncaster East",
						"Doncester",
						"Donvale",
						"Echuca-Moama",
						"Elsternwick",
						"Eltham",
						"Fairfield",
						"Fitzroy",
						"Frnakston",
						"Geelong",
						"Glen Waverley",
						"Glenorchy",
						"Hallam",
						"Hamilton",
						"Hastings",
						"Healesville",
						"Horsham",
						"Hughesdale",
						"Ivanhoe",
						"Keilor",
						"Kensington",
						"Kerang",
						"Kilmore",
						"Kilsyth",
						"Kyabram",
						"Lakes Entrance",
						"Lalor",
						"Lara",
						"Laverton North",
						"Leopold",
						"Lower Templestowe",
						"Maidstone",
						"Malvern",
						"Maryborough",
						"Melbourne",
						"Melton",
						"Middle Park",
						"Mildura",
						"Mitcham",
						"Moe-Yallourn",
						"Moorabbin",
						"Mordialloc",
						"Morwell",
						"Mulgrave",
						"Murrumbeena",
						"Neerim South",
						"Noble Park North",
						"Notting Hill",
						"Oak Park",
						"Ocean Grove-Barwon Heads",
						"Olinda",
						"Pakenham",
						"Park Orchards",
						"Port Melbourne",
						"Portland",
						"Prahran",
						"Preston",
						"Pritbourne",
						"Queenscliff",
						"Reservoir",
						"Ringwood",
						"Rowville",
						"Sale",
						"Sandringham",
						"Scoresby",
						"Sebastopol",
						"Seymour",
						"Shepparton-Mooroopna",
						"Somerville",
						"South Yarra",
						"Southbank",
						"St. Albans",
						"St. Kilda East",
						"Stawell",
						"Sunbury",
						"Sunshine West",
						"Swan Hill",
						"Tatura",
						"Thomastown",
						"Thornbury",
						"Tongala",
						"Torquay",
						"Traralgon",
						"Tullamarine",
						"Vermont",
						"Wangaratta",
						"Wantirna",
						"Warragul",
						"Warrnambool",
						"Welshpool",
						"Wendouree",
						"Wonthaggi",
						"Woodend",
						"Yarrawonga-Mulwala",
						"Yea",
					],
					stateName: "Victoria",
				},
				{ cities: [], stateName: "Werribee south" },
				{ cities: [], stateName: "Western Australia" },
				{ cities: ["Wheeler"], stateName: "Wheeler" },
			],
			countryName: "Australia",
		},
		{
			states: [
				{ cities: [], stateName: "Bundesland Salzburg" },
				{ cities: [], stateName: "Bundesland Steiermark" },
				{ cities: [], stateName: "Bundesland Tirol" },
				{
					cities: [
						"Eisenstadt",
						"GroBpetersdorf",
						"Jennersdorf",
						"Kemeten",
						"Mattersburg",
						"Neudorfl",
						"Neusiedl am See",
						"Oberwart",
						"Pinkafeld",
						"Rust",
					],
					stateName: "Burgenland",
				},
				{ cities: ["Maria Rain", "Poggersdorf"], stateName: "Carinthia" },
				{
					cities: [
						"Althofen",
						"Arnoldstein",
						"Bad Sankt Leonhard",
						"Bleiburg",
						"Ebenthal",
						"Eberndorf",
						"Feldkirchen",
						"Ferlach",
						"Finkenstein",
						"Friesach",
						"Hermagor",
						"Klagenfurt",
						"Klagenfurt ",
						"Lohnsburg",
						"Moosburg",
						"Paternion",
						"Radentheim",
						"Sankt Andra",
						"Sankt Jakob",
						"Sankt Veit",
						"Seeboden",
						"Spittal",
						"Velden am Worthersee",
						"Villach",
						"Volkermarkt",
						"Wernberg",
						"Wolfsberg",
					],
					stateName: "Karnten",
				},
				{ cities: [], stateName: "Liezen" },
				{ cities: [], stateName: "Lower Austria" },
				{
					cities: [
						"Amstetten",
						"Bad Voslau",
						"Baden",
						"Berndorf",
						"Boheimkirchen",
						"Bruck an der Leitha",
						"Brunn",
						"Deutsch-Wagram",
						"Ebreichsdorf",
						"Eggendorf",
						"Fischamend",
						"Gablitz",
						"Ganserndorf",
						"Gerasdorf",
						"Gloggnitz",
						"Gmund",
						"Greifenstein",
						"GroB-Enzersdorf",
						"GroB-Gerungs",
						"Guntramsdorf",
						"Haag",
						"Hainburg",
						"Heidenreichstein",
						"Herzogenburg",
						"Himberg",
						"Hollabrunn",
						"Horn",
						"Klosterneuburg",
						"Korneuburg",
						"Kottingbrunn",
						"Krems",
						"Laa",
						"Langenlois",
						"Langenzersdorf",
						"Leobendorf",
						"Leopoldsdorf",
						"Lilienfeld",
						"Loipersdorf",
						"Maria Enzersdorf",
						"Melk",
						"Mistelbach",
						"Modling",
						"Neulengbach",
						"Neunkirchen",
						"Niederleis",
						"Ober-Grabendorf",
						"Perchtoldsdorf",
						"Pernitz",
						"Pottendorf",
						"Poysdorf",
						"Pressbaum",
						"Purgstall",
						"Purkersdorf",
						"Reichenau",
						"Retz",
						"Sankt Andra-Wordern",
						"Sankt Peter in der Au",
						"Sankt Polten",
						"Sankt Valentin",
						"Scheibbs",
						"Schrems",
						"Schwechat",
						"Seitenstetten",
						"Sollenau",
						"Stockerau",
						"Strasshof",
						"Ternitz",
						"Traiskirchen",
						"Traismauer",
						"Tulln",
						"Vosendorf",
						"Waidhofen",
						"Wiener Neudorf",
						"Wiener Neustadt",
						"Wieselburg",
						"Wilhelmsburg",
						"Wolkersdorf",
						"Ybbs",
						"Ybbsitz",
						"Zistersdorf",
						"Zwettl",
					],
					stateName: "Niederosterreich",
				},
				{
					cities: [
						"Alkoven",
						"Altheim",
						"Altmunster",
						"Andorf",
						"Ansfelden",
						"Asten",
						"Attnang-Puchheim",
						"Aurolzmunster",
						"Bad Goisern",
						"Bad Hall",
						"Bad Ischl",
						"Braunau",
						"Breitenfurt",
						"Ebensee",
						"Eferding",
						"Engerwitzdorf",
						"Enns",
						"Feldkirchen an der Donau",
						"Frankenburg",
						"Freistadt",
						"Gallneukirchen",
						"Garsten",
						"Gmunden",
						"Gramastetten",
						"Grieskirchen",
						"Gunskirchen",
						"Horsching",
						"Kirchdorf an der Krems",
						"Kremsmunster",
						"Krenglbach",
						"Laakirchen",
						"Lenzing",
						"Leonding",
						"Linz",
						"Loibichl",
						"Marchtrenk",
						"Mattighofen",
						"Mauthausen",
						"Micheldorf",
						"Neuhofen an der Krems",
						"Ohlsdorf",
						"Ottensheim",
						"Pasching",
						"Perg",
						"Pettenbach",
						"Pram",
						"Pregarten",
						"Puchenau",
						"Regau",
						"Ried",
						"Rohrbach in Oberosterreich",
						"Rutzenmoos",
						"Sankt Florian",
						"Sankt Georgen",
						"Sankt Marien",
						"Scharding",
						"Scharnstein",
						"Schwertberg",
						"Seewalchen",
						"Sierning",
						"Stadl-Paura",
						"Steyr",
						"Steyregg",
						"Steyrermuhl",
						"Thalheim",
						"Timelkam",
						"Traun",
						"Vocklabruck",
						"Vocklamarkt",
						"Vorchdorf",
						"Wels",
						"Wilhering",
					],
					stateName: "Oberosterreich",
				},
				{
					cities: [
						"Abtenau",
						"Anif",
						"Bad Gastein",
						"Bad Hofgastein",
						"Bergheim",
						"Bischofshofen",
						"Bruck an der GroBglocknerstraB",
						"Burmoos",
						"Elsbethen",
						"Eugendorf",
						"Forstau",
						"Grodig",
						"Hallein",
						"Hallwang",
						"Henndorf",
						"Kuchl",
						"Mayrhofen",
						"Mittersill",
						"Neumarkt",
						"Oberndorf",
						"Obertrum am See",
						"Piesendorf",
						"Puch",
						"Radstadt",
						"Saalfelden",
						"Salzburg",
						"Sankt Johann im Pongau",
						"Seekirchen",
						"Sieghartskirchen",
						"StraBwalchen",
						"Strobl",
						"Tamsweg",
						"Thalgau",
						"Wals-Siezenheim",
						"Wolfgangsee",
						"Zell am See",
					],
					stateName: "Salzburg",
				},
				{ cities: ["Albersdorf"], stateName: "Schleswig-Holstein" },
				{
					cities: [
						"Bad Aussee",
						"Barnbach",
						"Bruck an der Mur",
						"Deutschlandsberg",
						"Eisenerz",
						"Feldbach",
						"Feldkirchen bei Graz",
						"Fohnsdorf",
						"Frohnleiten",
						"Furstenfeld",
						"Gleisdorf",
						"Gratkorn",
						"Graz",
						"Hartberg",
						"Judenburg",
						"Judendorf-StraBengel",
						"Kapfenberg",
						"Karlsdorf",
						"Kindberg",
						"Knittelfeld",
						"Koflach",
						"Krieglach",
						"Lannach",
						"Leibnitz",
						"Leoben",
						"Liezen",
						"Murzzuschlag",
						"Rottenmann",
						"Schladming",
						"Seiersberg",
						"Spielberg",
						"Trofaiach",
						"Voitsberg",
						"Wagna",
						"Weiz",
						"Zeltweg",
					],
					stateName: "Steiermark",
				},
				{
					cities: ["Deutschfeistritz", "Liezen", "Sankt Bartholoma"],
					stateName: "Styria",
				},
				{
					cities: [
						"Absam",
						"Axams",
						"Ebbs",
						"Fugen",
						"Hall",
						"Haselgehr",
						"Hopfgarten",
						"Imst",
						"Innsbruck",
						"Jenbach",
						"Kirchberg",
						"Kirchbichl",
						"Kitzbuhel",
						"Kramsach",
						"Kufstein",
						"Landeck",
						"Lienz",
						"Matrei",
						"Neustift im Stubaital",
						"Reutte",
						"Rum",
						"Sankt Johann in Tirol",
						"Scheffau",
						"Schwaz",
						"St. Johann Am Walde",
						"Telfs",
						"Vols",
						"Vomp",
						"Wattens",
						"Worgl",
						"Zirl",
					],
					stateName: "Tirol",
				},
				{ cities: [], stateName: "Upper Austria" },
				{
					cities: [
						"Altach",
						"Bludenz",
						"Bregenz",
						"Chassieu",
						"Dietmannsried",
						"Dornbirn",
						"Feldkirch",
						"Frastanz",
						"Gotzis",
						"Hard",
						"Hochst",
						"Hohenems",
						"Horbranz",
						"Hufingen",
						"Lauterach",
						"Lochau",
						"Lustenau",
						"Mittelberg",
						"Nenzing",
						"Nuziders",
						"Rankweil",
						"Schruns",
						"Thuringen",
						"Wolfurt",
					],
					stateName: "Vorarlberg",
				},
				{ cities: ["Vienna", "Wien"], stateName: "Wien" },
			],
			countryName: "Austria",
		},
		{
			states: [
				{
					cities: [
						"Alat",
						"Artyom",
						"Baki",
						"Bakixanov",
						"Balaxani",
						"Bilacari",
						"Bilqax",
						"Bina",
						"Buzovna",
						"Haci Zeynalabdin",
						"Hovsan",
						"Lokbatan",
						"Mastaga",
						"Puta",
						"Qarasuxur",
						"Qobustan",
						"Rasulzada",
						"Sabuncu",
						"Sanqacal",
						"Sumqayit",
						"Suraxani",
						"Xirdalan",
						"Zirya",
					],
					stateName: "Abseron",
				},
				{ cities: [], stateName: "Baki Sahari" },
				{ cities: ["Daskasan", "Ganca", "Xanlar"], stateName: "Ganca" },
				{ cities: ["Ganja"], stateName: "Ganja" },
				{ cities: ["Cabrayil", "Kalbacar", "Lacin"], stateName: "Kalbacar" },
				{
					cities: ["Astara", "Goytapa", "Lankaran", "Masalli", "Neftcala"],
					stateName: "Lankaran",
				},
				{
					cities: [
						"Agcabadi",
						"Agdam",
						"Barda",
						"Mingacevir",
						"Tartar",
						"Yevlax",
					],
					stateName: "Mil-Qarabax",
				},
				{
					cities: [
						"Ali Bayramli",
						"Bilasuvar",
						"Calilabad",
						"Qarasu",
						"Qazimammad",
						"Saatli",
						"Sabirabad",
						"Salyan",
					],
					stateName: "Mugan-Salyan",
				},
				{
					cities: ["Susa", "Xankandi", "Xocavand"],
					stateName: "Nagorni-Qarabax",
				},
				{
					cities: ["Culfa", "Naxcivan", "Ordubad", "Sadarak", "Sarur"],
					stateName: "Naxcivan",
				},
				{ cities: ["Beylaqan", "Fuzuli", "Imisli"], stateName: "Priaraks" },
				{
					cities: [
						"Agstafa",
						"Gadabay",
						"Kovlar",
						"Qaracamirli",
						"Qazax",
						"Samkir",
						"Tovuz",
					],
					stateName: "Qazax",
				},
				{
					cities: [
						"Amircan",
						"Balakan",
						"Katex",
						"Oguz",
						"Qabala",
						"Qax",
						"Saki",
						"Zaqatala",
					],
					stateName: "Saki",
				},
				{
					cities: [
						"Agdas",
						"Agsu",
						"Goycay",
						"Ismayilli",
						"Kurdamir",
						"Samaxi",
						"Ucar",
						"Zardab",
					],
					stateName: "Sirvan",
				},
				{
					cities: ["Davaci", "Quba", "Qusar", "Siyazan", "Xacmaz", "Xudat"],
					stateName: "Xacmaz",
				},
			],
			countryName: "Azerbaijan",
		},
		{
			states: [
				{ cities: ["Coopers Town", "Marsh Harbour"], stateName: "Abaco" },
				{ cities: [], stateName: "Acklins Island" },
				{ cities: ["Andros Town", "Nicholls Town"], stateName: "Andros" },
				{ cities: [], stateName: "Berry Islands" },
				{ cities: ["Alice Town"], stateName: "Biminis" },
				{ cities: [], stateName: "Cat Island" },
				{ cities: [], stateName: "Crooked Island" },
				{ cities: ["Freetown", "Rock Sound"], stateName: "Eleuthera" },
				{ cities: [], stateName: "Exuma and Cays" },
				{ cities: [], stateName: "Grand Bahama" },
				{ cities: [], stateName: "Inagua Islands" },
				{ cities: [], stateName: "Long Island" },
				{ cities: ["Pirates Well"], stateName: "Mayaguana" },
				{ cities: [], stateName: "New Providence" },
				{ cities: [], stateName: "Ragged Island" },
				{ cities: [], stateName: "Rum Cay" },
				{ cities: [], stateName: "San Salvador" },
			],
			countryName: "Bahamas The",
		},
		{
			states: [
				{ cities: ["'Isa"], stateName: "'Isa" },
				{ cities: ["Badiyah"], stateName: "Badiyah" },
				{ cities: ["Hidd"], stateName: "Hidd" },
				{ cities: [], stateName: "Jidd Hafs" },
				{ cities: ["Mahama"], stateName: "Mahama" },
				{ cities: ["Manama"], stateName: "Manama" },
				{ cities: ["Sitrah"], stateName: "Sitrah" },
				{ cities: ["al-Manamah"], stateName: "al-Manamah" },
				{ cities: ["al-Muharraq"], stateName: "al-Muharraq" },
				{ cities: ["ar-Rifa'a"], stateName: "ar-Rifa'a" },
			],
			countryName: "Bahrain",
		},
		{
			states: [
				{ cities: [], stateName: "Bagar Hat" },
				{ cities: ["Bandarban"], stateName: "Bandarban" },
				{ cities: ["Barguna"], stateName: "Barguna" },
				{
					cities: ["Barisal", "Gaurnadi", "Mehendiganj", "Nalchiti"],
					stateName: "Barisal",
				},
				{
					cities: ["Bhola", "Burhanuddin", "Char Fasson", "Lalmohan"],
					stateName: "Bhola",
				},
				{ cities: ["Adamighi", "Bogora", "Sherpur"], stateName: "Bogora" },
				{ cities: [], stateName: "Brahman Bariya" },
				{ cities: ["Chandpur", "Hajiganj"], stateName: "Chandpur" },
				{
					cities: [
						"Boalkhali",
						"Chattagam",
						"Fatikchhari",
						"Lohagara",
						"Patiya",
						"Rangunia",
						"Raozan",
						"Sandip",
						"Satkaniya",
					],
					stateName: "Chattagam",
				},
				{ cities: [], stateName: "Chittagong Division" },
				{
					cities: ["Alamdanga", "Chuadanga", "Damurhuda"],
					stateName: "Chuadanga",
				},
				{ cities: ["Dhaka", "Dhamrai", "Dohar"], stateName: "Dhaka" },
				{
					cities: ["Bochanganj", "Dinajpur", "Fulbari", "Parbatipur"],
					stateName: "Dinajpur",
				},
				{
					cities: ["Bhanga", "Char Bhadrasan", "Faridpur"],
					stateName: "Faridpur",
				},
				{ cities: ["Chhagalnaiya", "Feni"], stateName: "Feni" },
				{ cities: ["Gaybanda"], stateName: "Gaybanda" },
				{ cities: ["Gazipur", "Tungi"], stateName: "Gazipur" },
				{ cities: ["Gopalganj", "Tungi Para"], stateName: "Gopalganj" },
				{ cities: ["Baniachang", "Habiganj"], stateName: "Habiganj" },
				{ cities: [], stateName: "Jaipur Hat" },
				{ cities: ["Jamalpur", "Sarishabari"], stateName: "Jamalpur" },
				{
					cities: ["Abhaynagar", "Jessor", "Jhikargachha", "Keshabpur"],
					stateName: "Jessor",
				},
				{ cities: ["Jhalakati"], stateName: "Jhalakati" },
				{
					cities: ["Jhanaydah", "Kaliganj", "Kotchandpur", "Shailkupa"],
					stateName: "Jhanaydah",
				},
				{
					cities: ["Khagrachari", "Manikchhari", "Ramgarh"],
					stateName: "Khagrachhari",
				},
				{ cities: ["Khulna", "Phultala"], stateName: "Khulna" },
				{
					cities: ["Bajitpur", "Bhairab Bazar", "Itna", "Kishorganj"],
					stateName: "Kishorganj",
				},
				{ cities: [], stateName: "Koks Bazar" },
				{ cities: ["Komilla", "Laksham"], stateName: "Komilla" },
				{
					cities: ["Chilmari", "Kurigram", "Nageshwari", "Ulipur"],
					stateName: "Kurigram",
				},
				{ cities: ["Bheramara", "Kushtiya"], stateName: "Kushtiya" },
				{
					cities: ["Lakshmipur", "Ramganj", "Ramgati", "Raypur"],
					stateName: "Lakshmipur",
				},
				{ cities: [], stateName: "Lalmanir Hat" },
				{ cities: ["Madaripur"], stateName: "Madaripur" },
				{ cities: ["Magura"], stateName: "Magura" },
				{
					cities: [
						"Bhaluka",
						"Fulbaria",
						"Gafargaon",
						"Ishwarganj",
						"Maimansingh",
						"Muktagachha",
						"Trishal",
					],
					stateName: "Maimansingh",
				},
				{ cities: ["Manikganj"], stateName: "Manikganj" },
				{ cities: [], stateName: "Maulvi Bazar" },
				{ cities: ["Meherpur"], stateName: "Meherpur" },
				{ cities: ["Munshiganj"], stateName: "Munshiganj" },
				{ cities: ["Kalia", "Naral"], stateName: "Naral" },
				{ cities: ["Narayanganj", "Rupganj"], stateName: "Narayanganj" },
				{ cities: ["Narsingdi", "Roypura"], stateName: "Narsingdi" },
				{ cities: ["Gurudaspur", "Nator"], stateName: "Nator" },
				{ cities: ["Naugaon"], stateName: "Naugaon" },
				{
					cities: ["Gomastapur", "Nawabganj", "Shibganj"],
					stateName: "Nawabganj",
				},
				{ cities: ["Netrakona"], stateName: "Netrakona" },
				{
					cities: ["Domar", "Nilphamari", "Sa'idpur"],
					stateName: "Nilphamari",
				},
				{ cities: ["Begamganj", "Noakhali", "Senbagh"], stateName: "Noakhali" },
				{
					cities: ["Bera", "Bhangura", "Ishurdi", "Pabna"],
					stateName: "Pabna",
				},
				{ cities: ["Panchagarh"], stateName: "Panchagarh" },
				{ cities: ["Patuakhali"], stateName: "Patuakhali" },
				{
					cities: ["Bhandaria", "Mathbaria", "Nesarabad", "Pirojpur"],
					stateName: "Pirojpur",
				},
				{ cities: ["Pangsha", "Rajbari"], stateName: "Rajbari" },
				{ cities: ["Rajshahi"], stateName: "Rajshahi" },
				{ cities: ["Kaptai", "Rangamati"], stateName: "Rangamati" },
				{ cities: ["Badarganj", "Kaunia", "Rangpur"], stateName: "Rangpur" },
				{ cities: ["Satkhira"], stateName: "Satkhira" },
				{ cities: ["Palang"], stateName: "Shariatpur" },
				{ cities: ["Nalitabari", "Sherpur"], stateName: "Sherpur" },
				{ cities: ["Silhat"], stateName: "Silhat" },
				{ cities: ["Shahjadpur", "Sirajganj"], stateName: "Sirajganj" },
				{ cities: ["Chhatak", "Sunamganj"], stateName: "Sunamganj" },
				{
					cities: ["Gopalpur", "Mirzapur", "Sakhipur", "Tangayal"],
					stateName: "Tangayal",
				},
				{ cities: ["Pirganj", "Thakurgaon"], stateName: "Thakurgaon" },
			],
			countryName: "Bangladesh",
		},
		{
			states: [
				{ cities: [], stateName: "Christ Church" },
				{ cities: [], stateName: "Saint Andrew" },
				{ cities: [], stateName: "Saint George" },
				{ cities: [], stateName: "Saint James" },
				{ cities: [], stateName: "Saint John" },
				{ cities: [], stateName: "Saint Joseph" },
				{ cities: [], stateName: "Saint Lucy" },
				{ cities: [], stateName: "Saint Michael" },
				{ cities: [], stateName: "Saint Peter" },
				{ cities: [], stateName: "Saint Philip" },
				{ cities: [], stateName: "Saint Thomas" },
			],
			countryName: "Barbados",
		},
		{
			states: [
				{
					cities: [
						"Baranavichy",
						"Bjaroza",
						"Bjeloozersk",
						"Brest",
						"David-Haradok",
						"Drahichyn",
						"Hancavichy",
						"Ivacevichy",
						"Ivanava",
						"Kamjenec",
						"Kobryn",
						"Kosava",
						"Ljahavichy",
						"Luninjec",
						"Malaryta",
						"Mikashevichy",
						"Pinsk",
						"Pruzhany",
						"Stolin",
						"Vysokoje",
						"Zhabinka",
					],
					stateName: "Brest",
				},
				{
					cities: [
						"Buda Kosheljovo",
						"Chechersk",
						"Dobrush",
						"Hojniki",
						"Homjel'",
						"Jel'sk",
						"Kalinkavichy",
						"Kascjukovka",
						"Mazyr",
						"Narovlja",
						"Pjetrykav",
						"Rahachow",
						"Rechyca",
						"Svetlahorsk",
						"Vasilevichy",
						"Vjetka",
						"Zhlobin",
						"Zhytkavichy",
					],
					stateName: "Homjel'",
				},
				{
					cities: [
						"Ashmjany",
						"Berjozovka",
						"Hrodna",
						"Lida",
						"Masty",
						"Navahrudak",
						"Shchuchyn",
						"Skidel'",
						"Slonim",
						"Smarhon'",
						"Vawkavysk",
					],
					stateName: "Hrodna",
				},
				{
					cities: [
						"Asipovichy",
						"Babrujsk",
						"Byhau",
						"Chausy",
						"Cherikov",
						"Horki",
						"Kascjukovichy",
						"Klimavichy",
						"Krychaw",
						"Mahiljow",
						"Mstislav",
						"Shklov",
						"Slaeuharad",
					],
					stateName: "Mahiljow",
				},
				{ cities: [], stateName: "Mahilyowskaya Voblasts" },
				{ cities: ["Minsk"], stateName: "Minsk" },
				{ cities: [], stateName: "Minskaja Voblasts'" },
				{ cities: ["Petrikov"], stateName: "Petrik" },
				{
					cities: [
						"Baran'",
						"Braslav",
						"Chashniki",
						"Disna",
						"Dokshicy",
						"Dubrovna",
						"Haradok",
						"Hlybokaje",
						"Ljepjel'",
						"Mjory",
						"Navapolack",
						"Novalukoml'",
						"Orsha",
						"Pastavy",
						"Polack",
						"Polotsk",
						"Senno",
						"Talachyn",
						"Verhnjadzvimsk",
						"Vicebsk",
					],
					stateName: "Vicebsk",
				},
			],
			countryName: "Belarus",
		},
		{
			states: [
				{
					cities: [
						"Aartselaar",
						"Antwerpen",
						"Arendonk",
						"Baarle-Hertog",
						"Balen",
						"Beerse",
						"Berlaar",
						"Boechout",
						"Bonheiden",
						"Boom",
						"Bornem",
						"Borsbeek",
						"Brasschaat",
						"Brecht",
						"Dessel",
						"Deurne",
						"Duffel",
						"Edegem",
						"Ekeren",
						"Essen",
						"Geel",
						"Geluvele",
						"Grobbendonk",
						"Heist-op-den-Berg",
						"Hemiksem",
						"Herentals",
						"Herenthout",
						"Herselt",
						"Hoogstraten",
						"Hove",
						"Hulshout",
						"Kalmpthout",
						"Kalmthout",
						"Kapellen",
						"Kasterlee",
						"Kontich",
						"Laakdal",
						"Lier",
						"Lille",
						"Lint",
						"Malle",
						"Mechelen",
						"Meerhout",
						"Merksplas",
						"Mol",
						"Mortsel",
						"Niel",
						"Nijlen",
						"Olen",
						"Oud-Turnhout",
						"Putte",
						"Puurs",
						"Ranst",
						"Ravels",
						"Retie",
						"Rijkevorsel",
						"Robrechts",
						"Rumst",
						"Schelle",
						"Schilde",
						"Schoten",
						"Sint-Amands",
						"Sint-Katelijne-Waver",
						"Stabroek",
						"Turnhout",
						"Vorselaar",
						"Vosselaar",
						"Westerlo",
						"Wijnegem",
						"Willebroek",
						"Wilrijk",
						"Wommelgem",
						"Wuustwezel",
						"Zandhoven",
						"Zoersel",
						"Zwijndrecht",
					],
					stateName: "Antwerpen",
				},
				{ cities: ["Berchem"], stateName: "Berchem" },
				{
					cities: [
						"Alsemberg",
						"Asse Zellik",
						"Brussels",
						"Bruxelles",
						"Diegem",
					],
					stateName: "Brabant",
				},
				{
					cities: [
						"Alsemberg",
						"Asse Zellik",
						"Brussels",
						"Bruxelles",
						"Diegem",
					],
					stateName: "Brabant Wallon",
				},
				{ cities: ["Brussel"], stateName: "Brussel" },
				{ cities: [], stateName: "East Flanders" },
				{
					cities: [
						"Aiseau-Presles",
						"Anderlues",
						"Antoing",
						"Ath",
						"Beaumont",
						"Beloeil",
						"Bernissart",
						"Binche",
						"Boussu",
						"Braine-le-Comte",
						"Brugelette",
						"Brunehaut",
						"Celles",
						"Chapelle-lez-Herlaimont",
						"Charleroi",
						"Chatelet",
						"Chievres",
						"Chimay",
						"Colfontaine",
						"Comines-Warneton",
						"Courcelles",
						"Dour",
						"Ecaussinnes",
						"Ellezelles",
						"Enghien",
						"Erquelinnes",
						"Estaimpuis",
						"Estinnes",
						"Farciennes",
						"Fleurus",
						"Flobecq",
						"Fontaine-l'Eveque",
						"Frameries",
						"Frasnes-lez-Anvaing",
						"Froidchapelle",
						"Gerpinnes",
						"Haine-Saint-Pierre",
						"Ham-sur-Heure-Nalinnes",
						"Hensies",
						"Honnelles",
						"Jurbise",
						"La Louviere",
						"Le Roeulx",
						"Lens",
						"Les Bons Villers",
						"Lessines",
						"Leuze-en-Hainaut",
						"Lobbes",
						"Manage",
						"Merbes-le-Chateau",
						"Momignies",
						"Mons",
						"Mont-de-l'Enclus",
						"Montigny-le-Tilleul",
						"Morlanwelz",
						"Mouscron",
						"Pecq",
						"Peruwelz",
						"Pont-a-Celles",
						"Quaregnon",
						"Quevy",
						"Quievrain",
						"Rumes",
						"Saint-Ghislain",
						"Seneffe",
						"Silly",
						"Sivry-Rance",
						"Soignies",
						"Thuin",
						"Tournai",
					],
					stateName: "Hainaut",
				},
				{
					cities: [
						"Amay",
						"Amel",
						"Angleur",
						"Ans",
						"Anthisnes",
						"Aubel",
						"Awans",
						"Aywaille",
						"Baelen",
						"Bassenge",
						"Berloz",
						"Beyne-Heusay",
						"Blegny",
						"Braives",
						"Bullingen",
						"Burdinne",
						"Burg-Reuland",
						"Butgenbach",
						"Chaudfontaine",
						"Clavier",
						"Comblain-au-Pont",
						"Crisnee",
						"Dalhem",
						"Dison",
						"Donceel",
						"Engis",
						"Esneux",
						"Eupen",
						"Faimes",
						"Ferrieres",
						"Fexhe-le-Haut-Clocher",
						"Flemalle",
						"Fleron",
						"Geer",
						"Grace-Hollogne",
						"Hamoir",
						"Hannut",
						"Heron",
						"Herstal",
						"Herve",
						"Huy",
						"Jalhay",
						"Juprelle",
						"Kelmis",
						"Liege",
						"Lierneux",
						"Limbourg",
						"Lincent",
						"Lontzen",
						"Malmedy",
						"Marchin",
						"Modave",
						"Montzen",
						"Nandrin",
						"Neupre",
						"Olne",
						"Oreye",
						"Ouffet",
						"Oupeye",
						"Pepinster",
						"Plombieres",
						"Racour",
						"Raeren",
						"Remicourt",
						"Saint-Georges-sur-Meuse",
						"Saint-Nicolas",
						"Sankt Vith",
						"Seraing",
						"Soumagne",
						"Spa",
						"Sprimont",
						"Stavelot",
						"Stoumont",
						"Theux",
						"Thimister-Clermont",
						"Tinlot",
						"Trois-Ponts",
						"Trooz",
						"Verlaine",
						"Verviers",
						"Villers-le-Bouillet",
						"Vise",
						"Waimes",
						"Wanze",
						"Waremme",
						"Wasseiges",
						"Welkenraedt",
					],
					stateName: "Liege",
				},
				{
					cities: [
						"Alken",
						"As",
						"Beringen",
						"Bilzen",
						"Bocholt",
						"Borgloon",
						"Bree",
						"Diepenbeek",
						"Dilsen-Stokkem",
						"Genk",
						"Gingelom",
						"Halen",
						"Ham",
						"Hamont-Achel",
						"Hasselt",
						"Hechtel-Eksel",
						"Heers",
						"Herk-de-Stad",
						"Herstappe",
						"Heusden-Zolder",
						"Hoeselt",
						"Houthalen-Helchteren",
						"Kinrooi",
						"Kortessem",
						"Kuringen",
						"Lanaken",
						"Leopoldsburg",
						"Lommel",
						"Lummen",
						"Maaseik",
						"Maasmechelen",
						"Meeuwen-Gruitrode",
						"Neerpelt",
						"Nieuwerkerken",
						"Opglabbeek",
						"Overpelt",
						"Peer",
						"Riemst",
						"Sint-Truiden",
						"Tessenderlo",
						"Tongeren",
						"Voeren",
						"Wellen",
						"Zonhoven",
						"Zutendaal",
					],
					stateName: "Limburg",
				},
				{
					cities: [
						"Arlon",
						"Attert",
						"Aubange",
						"Bastogne",
						"Bertogne",
						"Bertrix",
						"Bouillon",
						"Chiny",
						"Daverdisse",
						"Durbuy",
						"Erezee",
						"Etalle",
						"Fauvillers",
						"Florenville",
						"Gouvy",
						"Grapfontaine",
						"Habay",
						"Herbeumont",
						"Hotton",
						"Houffalize",
						"La Roche-en-Ardenne",
						"Leglise",
						"Libin",
						"Libramont-Chevigny",
						"Manhay",
						"Marche-en-Famenne",
						"Marloie",
						"Martelange",
						"Meix-devant-Virton",
						"Messancy",
						"Musson",
						"Nassogne",
						"Neufchateau",
						"Paliseul",
						"Rendeux",
						"Rouvroy",
						"Saint-Hubert",
						"Saint-Leger",
						"Sainte-Ode",
						"Tellin",
						"Tenneville",
						"Tintigny",
						"Vaux-sur-Sure",
						"Vielsalm",
						"Virton",
						"Wellin",
					],
					stateName: "Luxembourg",
				},
				{
					cities: [
						"Andenne",
						"Anhee",
						"Assesse",
						"Beauraing",
						"Bievre",
						"Cerfontaine",
						"Ciney",
						"Couvin",
						"Dinant",
						"Doische",
						"Eghezee",
						"Fernelmont",
						"Floreffe",
						"Florennes",
						"Fosses-la-Ville",
						"Gedinne",
						"Gembloux",
						"Gesves",
						"Hamois",
						"Hastiere",
						"Havelange",
						"Houyet",
						"Jambes",
						"Jemeppe-sur-Sambre",
						"La Bruyere",
						"Malonne",
						"Mettet",
						"Namur",
						"Ohey",
						"Onhaye",
						"Philippeville",
						"Profondeville",
						"Rochefort",
						"Sambreville",
						"Sombreffe",
						"Somme-Leuze",
						"Suarlee",
						"Viroinval",
						"Vresse-sur-Semois",
						"Walcourt",
						"Yvoir",
					],
					stateName: "Namur",
				},
				{ cities: ["Gullegem", "Langdorp"], stateName: "Ontario" },
				{
					cities: [
						"Aalst",
						"Aalter",
						"Assenede",
						"Berlare",
						"Beveren",
						"Brakel",
						"Buggenhout",
						"De Pinte",
						"Deinze",
						"Denderleeuw",
						"Dendermonde",
						"Destelbergen",
						"Drongen",
						"Eeklo",
						"Erembodegem",
						"Erpe-Mere",
						"Evergem",
						"Gavere",
						"Gent",
						"Geraardsbergen",
						"Ghent",
						"Haaltert",
						"Hamme",
						"Herzele",
						"Horebeke",
						"Kaprijke",
						"Kerksken",
						"Kluisbergen",
						"Knesselare",
						"Kruibeke",
						"Kruishoutem",
						"Laarne",
						"Lebbeke",
						"Lede",
						"Lierde",
						"Lochristi",
						"Lokeren",
						"Lovendegem",
						"Maarkedal",
						"Maldegem",
						"Melle",
						"Merelbeke",
						"Moerbeke",
						"Nazareth",
						"Nevele",
						"Ninove",
						"Oosterzele",
						"Oudenaarde",
						"Ronse",
						"Semmerzake",
						"Sint-Gillis-Waas",
						"Sint-Laureins",
						"Sint-Lievens-Houtem",
						"Sint-Martens-Latem",
						"Sint-Niklaas",
						"Stekene",
						"Temse",
						"Waarschoot",
						"Waasmunster",
						"Wachtebeke",
						"Wetteren",
						"Wichelen",
						"Wortegem-Petegem",
						"Zele",
						"Zelzate",
						"Zingem",
						"Zomergem",
						"Zottegem",
						"Zulte",
						"Zwalm",
						"Zwijnaarde",
					],
					stateName: "Oost-Vlaanderen",
				},
				{ cities: [], stateName: "Provincie Brabant" },
				{
					cities: [
						"Aarschot",
						"Affligem",
						"Asse",
						"Beersel",
						"Begijnendijk",
						"Bekkevoort",
						"Bertem",
						"Bever",
						"Bierbeek",
						"Boortmeerbeek",
						"Boutersem",
						"Diest",
						"Dilbeek",
						"Drogenbos",
						"Galmaarden",
						"Geetbets",
						"Glabbeek",
						"Gooik",
						"Grimbergen",
						"Haacht",
						"Halle",
						"Herent",
						"Herne",
						"Hoegaarden",
						"Hoeilaart",
						"Holsbeek",
						"Huldenberg",
						"Kampenhout",
						"Kapelle-op-den-Bos",
						"Keerbergen",
						"Kortenaken",
						"Kortenberg",
						"Kraainem",
						"Landen",
						"Lennik",
						"Leuven",
						"Liedekerke",
						"Linkebeek",
						"Linter",
						"Londerzeel",
						"Lubbeek",
						"Machelen",
						"Meise",
						"Merchtem",
						"Opwijk",
						"Oud-Heverlee",
						"Overijse",
						"Pepingen",
						"Roosdaal",
						"Rotselaar",
						"Scherpenheuvel-Zichem",
						"Sint-Genesius-Rode",
						"Sint-Pieters-Leeuw",
						"Steenokkerzeel",
						"Ternat",
						"Tervuren",
						"Tielt-Winge",
						"Tienen",
						"Tremelo",
						"Vilvoorde",
						"Wemmel",
						"Wezembeek-Oppem",
						"Zaventem",
						"Zemst",
						"Zoutleeuw",
					],
					stateName: "Vlaams-Brabant",
				},
				{
					cities: ["Callenelle", "Marcinelle", "Strepy-Bracquegnies"],
					stateName: "Wallonne",
				},
				{
					cities: [
						"Alveringem",
						"Anzegem",
						"Ardooie",
						"Avelgem",
						"Beernem",
						"Blankenberge",
						"Bredene",
						"Brugge",
						"Damme",
						"De Haan",
						"De Panne",
						"Deerlijk",
						"Dentergem",
						"Diksmuide",
						"Geluveld",
						"Gistel",
						"Harelbeke",
						"Heuvelland",
						"Hooglede",
						"Houthulst",
						"Ichtegem",
						"Ieper",
						"Ingelmunster",
						"Ingooigem",
						"Izegem",
						"Jabbeke",
						"Knokke-Heist",
						"Koekelare",
						"Koksijde",
						"Kortemark",
						"Kortrijk",
						"Kuurne",
						"Langemark-Poelkapelle",
						"Ledegem",
						"Lendelede",
						"Lichtervelde",
						"Lo-Reninge",
						"Menen",
						"Mesen",
						"Meulebeke",
						"Middelkerke",
						"Moorslede",
						"Nieuwpoort",
						"Oostende",
						"Oostkamp",
						"Oostrozebeke",
						"Oudenburg",
						"Pittem",
						"Poperinge",
						"Roeselare",
						"Ruiselede",
						"Spiere-Helkijn",
						"Staden",
						"Tielt",
						"Torhout",
						"Veurne",
						"Vleteren",
						"Waregem",
						"Wervik",
						"Wevelgem",
						"Wielsbeke",
						"Wingene",
						"Zedelgem",
						"Zeebrugge",
						"Zonnebeke",
						"Zuienkerke",
						"Zwevegem",
					],
					stateName: "West-Vlaanderen",
				},
			],
			countryName: "Belgium",
		},
		{
			states: [
				{ cities: ["Belize", "San Pedro"], stateName: "Belize" },
				{
					cities: ["Belmopan", "Benque Viejo", "San Ignacio"],
					stateName: "Cayo",
				},
				{ cities: ["Corozal"], stateName: "Corozal" },
				{ cities: [], stateName: "Orange Walk" },
				{ cities: [], stateName: "Stann Creek" },
				{ cities: ["Punta Gorda"], stateName: "Toledo" },
			],
			countryName: "Belize",
		},
		{
			states: [
				{ cities: ["Kandi", "Malanville"], stateName: "Alibori" },
				{
					cities: ["Batia", "Boukombe", "Kouande", "Natitingou"],
					stateName: "Atacora",
				},
				{
					cities: ["Allada", "Cotonou", "Ouidah", "Tanguiete"],
					stateName: "Atlantique",
				},
				{
					cities: ["Bembereke", "Nikki", "Parakou", "Tchaourou"],
					stateName: "Borgou",
				},
				{ cities: ["Dassa", "Savalou", "Save"], stateName: "Collines" },
				{ cities: ["Aplahoue", "Dogbo"], stateName: "Couffo" },
				{ cities: ["Djougou"], stateName: "Donga" },
				{ cities: ["Cotonou"], stateName: "Littoral" },
				{
					cities: ["Athieme", "Come", "Grand Popo", "Lokossa"],
					stateName: "Mono",
				},
				{ cities: ["Adjohoun", "Porto Novo"], stateName: "Oueme" },
				{ cities: ["Ketou", "Pobe", "Sakete"], stateName: "Plateau" },
				{
					cities: ["Abomey", "Bohicon", "Cove", "Zagnanado"],
					stateName: "Zou",
				},
			],
			countryName: "Benin",
		},
		{
			states: [
				{ cities: ["Hamilton"], stateName: "Hamilton" },
				{ cities: [], stateName: "Saint George" },
			],
			countryName: "Bermuda",
		},
		{
			states: [
				{ cities: ["Jakar"], stateName: "Bumthang" },
				{ cities: ["Chhukha", "Phuentsholing"], stateName: "Chhukha" },
				{ cities: ["Damphu"], stateName: "Chirang" },
				{ cities: ["Taga Dzong"], stateName: "Daga" },
				{ cities: ["Geylegphug"], stateName: "Geylegphug" },
				{ cities: ["Ha"], stateName: "Ha" },
				{ cities: ["Lhuntshi"], stateName: "Lhuntshi" },
				{ cities: ["Mongar"], stateName: "Mongar" },
				{ cities: ["Pemagatsel"], stateName: "Pemagatsel" },
				{ cities: ["Gasa", "Punakha"], stateName: "Punakha" },
				{ cities: ["Paro"], stateName: "Rinpung" },
				{ cities: ["Phuntsholing", "Samchi"], stateName: "Samchi" },
				{ cities: [], stateName: "Samdrup Jongkhar" },
				{ cities: ["Shemgang"], stateName: "Shemgang" },
				{ cities: ["Tashigang"], stateName: "Tashigang" },
				{ cities: ["Thimphu", "Timphu"], stateName: "Timphu" },
				{ cities: ["Tongsa"], stateName: "Tongsa" },
				{ cities: ["Wangdiphodrang"], stateName: "Wangdiphodrang" },
			],
			countryName: "Bhutan",
		},
		{
			states: [
				{
					cities: [
						"Guayaramerin",
						"Magdalena",
						"Reyes",
						"Riberalta",
						"Rurrenabaque",
						"San Borja",
						"San Ignacio",
						"San Ramon",
						"Santa Ana",
						"Santa Rosa",
						"Trinidad",
					],
					stateName: "Beni",
				},
				{
					cities: [
						"Camargo",
						"Monteagudo",
						"Muyupampa",
						"Padilla",
						"Sucre",
						"Tarabuco",
						"Villa Serano",
					],
					stateName: "Chuquisaca",
				},
				{
					cities: [
						"Aiquile",
						"Arani",
						"Capinota",
						"Chimore",
						"Cliza",
						"Cochabamba",
						"Colomi",
						"Entre Rios",
						"Irpa Irpa",
						"Ivirgarzama",
						"Mizque",
						"Punata",
						"Shinahota",
						"Sipe Sipe",
						"Tarata",
						"Ucurena",
					],
					stateName: "Cochabamba",
				},
				{ cities: [], stateName: "La Paz" },
				{
					cities: [
						"Caracollo",
						"Challapata",
						"Eucaliptus",
						"Huanuni",
						"Machacamarca",
						"Oruro",
						"Poopo",
						"Santiago de Huari",
						"Totoral",
					],
					stateName: "Oruro",
				},
				{ cities: ["Cobija"], stateName: "Pando" },
				{
					cities: [
						"Atocha",
						"Betanzos",
						"Colquechaca",
						"Llallagua",
						"Potosi",
						"Santa Barbara",
						"Tupiza",
						"Uncia",
						"Uyuni",
						"Villazon",
					],
					stateName: "Potosi",
				},
				{ cities: [], stateName: "Santa Cruz" },
				{
					cities: [
						"Bermejo",
						"Entre Rios",
						"San Lorenzo",
						"Tarija",
						"Villamontes",
						"Yacuiba",
					],
					stateName: "Tarija",
				},
			],
			countryName: "Bolivia",
		},
		{
			states: [
				{ cities: [], stateName: "Federacija Bosna i Hercegovina" },
				{ cities: [], stateName: "Republika Srpska" },
			],
			countryName: "Bosnia and Herzegovina",
		},
		{
			states: [
				{ cities: [], stateName: "Central Bobonong" },
				{ cities: [], stateName: "Central Boteti" },
				{ cities: [], stateName: "Central Mahalapye" },
				{ cities: [], stateName: "Central Serowe-Palapye" },
				{ cities: [], stateName: "Central Tutume" },
				{
					cities: [
						"Kachikau",
						"Kasane",
						"Kavimba",
						"Kazungula",
						"Lesoma",
						"Muchinje-Mabale",
						"Pandamatenga",
						"Pandamatenga Botswana Defence ",
						"Parakarungu",
						"Satau",
					],
					stateName: "Chobe",
				},
				{ cities: ["Francistown"], stateName: "Francistown" },
				{ cities: ["Gaborone"], stateName: "Gaborone" },
				{
					cities: [
						"Bere",
						"Charles Hill",
						"Chobokwane",
						"Dekar",
						"East Hanahai",
						"Ghanzi",
						"Groote Laagte",
						"Kacgae",
						"Karakobis",
						"Kuke Quarantine Camp",
						"Kule",
						"Makunda",
						"Ncojane",
						"New Xade",
						"New Xanagas",
						"Qabo",
						"Tsootsha",
						"West Hanahai",
					],
					stateName: "Ghanzi",
				},
				{ cities: ["Jwaneng"], stateName: "Jwaneng" },
				{ cities: [], stateName: "Kgalagadi North" },
				{ cities: [], stateName: "Kgalagadi South" },
				{
					cities: [
						"Artisia",
						"Bokaa",
						"Dikgonye",
						"Dikwididi",
						"Kgomodiatshaba",
						"Khurutshe",
						"Leshibitse",
						"Mabalane",
						"Malolwane",
						"Malotwana Siding",
						"Matebeleng",
						"Mmamashia",
						"Mmathubudukwane",
						"Mochudi",
						"Modipane",
						"Morwa",
						"Oliphants Drift",
						"Oodi",
						"Pilane",
						"Ramonaka",
						"Ramotlabaki",
						"Rasesa",
						"Sikwane",
					],
					stateName: "Kgatleng",
				},
				{
					cities: [
						"Boatlaname",
						"Botlhapatlou",
						"Diagane",
						"Diphudugodu",
						"Diremogolo Lands",
						"Ditshegwane",
						"Ditshukudu",
						"Dumadumane",
						"Dutlwe",
						"Gabane",
						"Gakgatla",
						"Gakuto",
						"Galekgatshwane",
						"Gamodubu",
						"Gaphatshwa",
						"Hatsalatladi",
						"Kamenakwe",
						"Kaudwane",
						"Kgaphamadi",
						"Kgope",
						"Khekhenya-Chepetese",
						"Khudumelapye",
						"Kopong",
						"Kotolaname",
						"Kubung",
						"Kumakwane",
						"Kweneng",
						"Lentsweletau",
						"Lephepe",
						"Lesirane",
						"Letlhakeng",
						"Losilakgokong",
						"Maboane",
						"Mahetlwe",
						"Makabanyane-Dikgokong",
						"Malwelwe",
						"Mamhiko",
						"Manaledi",
						"Mantshwabisi",
						"Marejwane",
						"Masebele",
						"Medie",
						"Metsibotlhoko",
						"Metsimotlhaba",
						"Mmakanke",
						"Mmankgodi",
						"Mmanoko",
						"Mmokolodi",
						"Mmopane",
						"Mmopane Lands",
						"Mogoditshane",
						"Mogoditshane Botswana Defence ",
						"Mogoditshane Lands",
						"Mogonono",
						"Molepolole",
						"Mononyane",
						"Monwane",
						"Morabane",
						"Morope",
						"Moshaweng",
						"Mosokotswe",
						"Motokwe",
						"Ngware",
						"Nkoyaphiri",
						"Ramaphatlhe",
						"Salajwe",
						"Serinane",
						"Sesung",
						"Shadishadi",
						"Sojwe",
						"Sorilatholo",
						"Suping",
						"Takatokwane",
						"Thamaga",
						"Thebephatshwa",
						"Tlowaneng",
						"Tsetseng",
						"Tswaane",
					],
					stateName: "Kweneng",
				},
				{ cities: ["Lobatse"], stateName: "Lobatse" },
				{
					cities: [
						"Bodibeng",
						"Boro",
						"Botlhatlogo",
						"Chanoga",
						"Chuchubega",
						"Daonara",
						"Ditshiping",
						"Habu",
						"Jao",
						"Kareng",
						"Katamaga",
						"Kgakge",
						"Khwai Camp",
						"Komana",
						"Legotlhwana",
						"Mababe",
						"Makalamabedi",
						"Matlapana",
						"Matsaudi",
						"Mawana",
						"Mokgalo-Haka",
						"Morutsha",
						"Nxharaga",
						"Phuduhudu",
						"Samodupi",
						"Sankuyo",
						"Sehithwa",
						"Semboyo",
						"Sexaxa",
						"Shakawe",
						"Shorobe",
						"Somela",
						"Toteng",
						"Tsanekona",
						"Tsao",
						"Xaxaba",
						"Xhobe",
					],
					stateName: "Ngamiland",
				},
				{
					cities: [
						"Bethel",
						"Borobadilepe",
						"Diabo",
						"Digawana",
						"Dikhukhung",
						"Dinatshana",
						"Dipotsana",
						"Ditlharapa",
						"Gamajalela",
						"Gasita",
						"Gathwane",
						"Good Hope",
						"Goora-seno",
						"Gopong",
						"Hebron",
						"Itholoke",
						"Kanaku",
						"Kangwe",
						"Kanye",
						"Keng",
						"Kgomokasitwa",
						"Kgoro",
						"Khakhea",
						"Khonkhwa",
						"Kokong",
						"Lehoko",
						"Lejwana",
						"Lekgolobotlo",
						"Leporung",
						"Logagane",
						"Lorolwana",
						"Lorwana",
						"Lotlhakane",
						"Lotlhakane West",
						"Mabule",
						"Mabutsane",
						"Madingwana",
						"Magoriapitse",
						"Magotlhawane",
						"Mahotshwane",
						"Maisane",
						"Makokwe",
						"Malokaganyane",
						"Manyana",
						"Maokane",
						"Marojane",
						"Maruswa",
						"Metlobo",
						"Metlojane",
						"Mmakgori",
						"Mmathethe",
						"Mogojogojo",
						"Mogonye",
						"Mogwalale",
						"Mokatako",
						"Mokgomane",
						"Mokhomba",
						"Molapowabojang",
						"Molete",
						"Morwamosu",
						"Moshaneng",
						"Moshupa",
						"Motlhwatse",
						"Motsentshe",
						"Musi",
						"Ngwatsau",
						"Ntlhantlhe",
						"Papatlo",
						"Phihitshwane",
						"Pitsana-Potokwe",
						"Pitsane",
						"Pitseng-Ralekgetho",
						"Pitshane Molopo",
						"Rakhuna",
						"Ralekgetho",
						"Ramatlabama",
						"Ranaka",
						"Sedibeng",
						"Segakwana",
						"Segwagwa",
						"Seherelela",
						"Sekhutlane",
						"Sekoma",
						"Selokolela",
						"Semane",
						"Sese",
						"Sesung",
						"Sheep Farm",
						"Tlhankane",
						"Tlhareseleele",
						"Tshidilamolomo",
						"Tshwaane",
						"Tsonyane",
						"Tswaaneng",
						"Tswagare-Lothoje-Lokalana",
						"Tswanyaneng",
					],
					stateName: "Ngwaketse",
				},
				{ cities: [], stateName: "North East" },
				{
					cities: [
						"Beetsha",
						"Eretsha",
						"Etsha 1",
						"Etsha 13",
						"Etsha 6",
						"Etsha 8",
						"Etsha 9",
						"Gane",
						"Gonutsuga",
						"Gowe",
						"Gudingwa",
						"Gumare",
						"Ikoga",
						"Kajaja",
						"Kapotora Lands",
						"Kauxwhi",
						"Matswee",
						"Maun",
						"Moaha",
						"Mohembo East",
						"Mohembo West",
						"Mokgacha",
						"Ngarange",
						"Nokaneng",
						"Nxamasere",
						"Nxaunxau",
						"Nxwee",
						"Qangwa",
						"Roye",
						"Samochema",
						"Sekondomboro",
						"Sepopa",
						"Seronga",
						"Shaowe",
						"Tobere Lands",
						"Tubu",
						"Tubu Lands",
						"Xadau",
						"Xakao",
						"Xaxa",
						"Xhauga",
						"Xurube",
					],
					stateName: "Okavango",
				},
				{ cities: ["Orapa"], stateName: "Orapa" },
				{ cities: [], stateName: "Selibe Phikwe" },
				{ cities: [], stateName: "South East" },
				{ cities: ["Sowa"], stateName: "Sowa" },
			],
			countryName: "Botswana",
		},
		{
			states: [{ cities: [], stateName: "Bouvet Island" }],
			countryName: "Bouvet Island",
		},
		{
			states: [
				{
					cities: [
						"Acrelandia",
						"Brasileia",
						"Cruzeiro do Sul",
						"Epitaciolandia",
						"Feijo",
						"Mancio Lima",
						"Manoel Urbano",
						"Marechal Thaumaturgo",
						"Placido de Castro",
						"Porto Walter",
						"Rio Branco",
						"Rodrigues Alves",
						"Sena Madureira",
						"Senador Guiomard",
						"Tarauaca",
						"Xapuri",
					],
					stateName: "Acre",
				},
				{
					cities: [
						"Agua Branca",
						"Anadia",
						"Arapiraca",
						"Atalaia",
						"Barra de Santo Antonio",
						"Batalha",
						"Boca da Mata",
						"Cacimbinhas",
						"Cajueiro",
						"Campo Alegre",
						"Campo Grande",
						"Canapi",
						"Capela",
						"Coite do Noia",
						"Colonia Leopoldina",
						"Coruripe",
						"Craibas",
						"Delmiro Gouveia",
						"Dois Riachos",
						"Estrela de Alagoas",
						"Feira Grande",
						"Flexeiras",
						"Girau do Ponciano",
						"Ibateguara",
						"Igaci",
						"Igreja Nova",
						"Inhapi",
						"Joaquim Gomes",
						"Jundia",
						"Junqueiro",
						"Lagoa da Canoa",
						"Limoeiro de Anadia",
						"Maceio",
						"Major Isidoro",
						"Maragogi",
						"Maravilha",
						"Marechal Deodoro",
						"Maribondo",
						"Mata Grande",
						"Matriz de Camaragibe",
						"Messias",
						"Minador do Negrao",
						"Murici",
						"Novo Lino",
						"Olho d'Agua das Flores",
						"Olivenca",
						"Palmeira dos Indios",
						"Pao de Acucar",
						"Passo de Camaragibe",
						"Penedo",
						"Piacabucu",
						"Pilar",
						"Piranhas",
						"Poco das Trincheiras",
						"Porto Calvo",
						"Porto Real do Colegio",
						"Quebrangulo",
						"Rio Largo",
						"Santana do Ipanema",
						"Santana do Mundau",
						"Sao Jose da Laje",
						"Sao Jose da Tapera",
						"Sao Luis do Quitunde",
						"Sao Miguel dos Campos",
						"Sao Sebastiao",
						"Satuba",
						"Senador Rui Palmeira",
						"Taquarana",
						"Teotonio Vilela",
						"Traipu",
						"Uniao dos Palmares",
						"Vicosa",
					],
					stateName: "Alagoas",
				},
				{
					cities: [
						"Amapa",
						"Laranjal do Jari",
						"Macapa",
						"Mazagao",
						"Oiapoque",
						"Santana",
					],
					stateName: "Amapa",
				},
				{
					cities: [
						"Alvaraes",
						"Anori",
						"Apui",
						"Autazes",
						"Barcelos",
						"Barreirinha",
						"Benjamin Constant",
						"Boca do Acre",
						"Borba",
						"Canutama",
						"Carauari",
						"Careiro",
						"Careiro da Varzea",
						"Coari",
						"Codajas",
						"Eirunepe",
						"Envira",
						"Fonte Boa",
						"Guajara",
						"Humaita",
						"Ipixuna",
						"Iranduba",
						"Itacoatiara",
						"Japura",
						"Jutai",
						"Labrea",
						"Manacapuru",
						"Manaquiri",
						"Manaus",
						"Manicore",
						"Maraa",
						"Maues",
						"Nhamunda",
						"Nova Olinda do Norte",
						"Novo Airao",
						"Novo Aripuana",
						"Parintins",
						"Pauini",
						"Rio Preto da Eva",
						"Santa Isabel do Rio Negro",
						"Santo Antonio do Ica",
						"Sao Gabriel da Cachoeira",
						"Sao Paulo de Olivenca",
						"Tabatinga",
						"Tapaua",
						"Tefe",
						"Tonantins",
						"Uarini",
						"Urucara",
						"Urucurituba",
					],
					stateName: "Amazonas",
				},
				{
					cities: [
						"Acajutiba",
						"Alagoinhas",
						"Amargosa",
						"Amelia Rodrigues",
						"America Dourada",
						"Anage",
						"Araci",
						"Aurelino Leal",
						"Baixa Grande",
						"Barra",
						"Barra da Estiva",
						"Barra do Choca",
						"Barreiras",
						"Belmonte",
						"Boa Vista do Tupim",
						"Bom Jesus da Lapa",
						"Boquira",
						"Brumado",
						"Buerarema",
						"Cachoeira",
						"Cacule",
						"Caetite",
						"Cafarnaum",
						"Camacan",
						"Camacari",
						"Camamu",
						"Campo Alegre de Lourdes",
						"Campo Formoso",
						"Canarana",
						"Canavieiras",
						"Candeias",
						"Candido Sales",
						"Cansancao",
						"Capim Grosso",
						"Caravelas",
						"Carinhanha",
						"Casa Nova",
						"Castro Alves",
						"Catu",
						"Cicero Dantas",
						"Cipo",
						"Coaraci",
						"Conceicao da Feira",
						"Conceicao do Almeida",
						"Conceicao do Coite",
						"Conceicao do Jacuipe",
						"Conde",
						"Coracao de Maria",
						"Coronel Joao Sa",
						"Correntina",
						"Cruz das Almas",
						"Curaca",
						"Dias d'Avila",
						"Encruzilhada",
						"Entre Rios",
						"Esplanada",
						"Euclides da Cunha",
						"Eunapolis",
						"Feira de Santana",
						"Filadelfia",
						"Formosa do Rio Preto",
						"Gandu",
						"Guanambi",
						"Guaratinga",
						"Iacu",
						"Ibicarai",
						"Ibicui",
						"Ibipeba",
						"Ibirapitanga",
						"Ibirataia",
						"Ibotirama",
						"Iguai",
						"Ilheus",
						"Inhambupe",
						"Ipiau",
						"Ipira",
						"Iraquara",
						"Irara",
						"Irece",
						"Itabela",
						"Itaberaba",
						"Itabuna",
						"Itacare",
						"Itagi",
						"Itagiba",
						"Itajuipe",
						"Itamaraju",
						"Itambe",
						"Itanhem",
						"Itaparica",
						"Itapetinga",
						"Itapicuru",
						"Itarantim",
						"Itirucu",
						"Itiuba",
						"Itororo",
						"Ituacu",
						"Itubera",
						"Jacobina",
						"Jaguaquara",
						"Jaguarari",
						"Jequie",
						"Jeremoabo",
						"Jitauna",
						"Joao Dourado",
						"Juazeiro",
						"Jussara",
						"Laje",
						"Lapao",
						"Lauro de Freitas",
						"Livramento",
						"Macarani",
						"Macaubas",
						"Madre de Deus",
						"Mairi",
						"Maracas",
						"Maragogipe",
						"Marau",
						"Mascote",
						"Mata de Sao Joao",
						"Medeiros Neto",
						"Miguel Calmon",
						"Milagres",
						"Monte Santo",
						"Morro de Chapeu",
						"Mucuri",
						"Mundo Novo",
						"Muritiba",
						"Mutuipe",
						"Nazare",
						"Nova Soure",
						"Nova Vicosa",
						"Olindina",
						"Oliveira dos Brejinhos",
						"Palmas de Monte Alto",
						"Paramirim",
						"Paratinga",
						"Paripiranga",
						"Pau Brasil",
						"Paulo Afonso",
						"Pilao Arcado",
						"Pindobacu",
						"Piritiba",
						"Planalto",
						"Pocoes",
						"Pojuca",
						"Ponto Novo",
						"Porto Seguro",
						"Prado",
						"Presidente Tancredo Neves",
						"Queimadas",
						"Quijingue",
						"Rafael Jambeiro",
						"Remanso",
						"Riachao das Neves",
						"Riachao do Jacuipe",
						"Riacho de Santana",
						"Ribeira do Pombal",
						"Rio Real",
						"Ruy Barbosa",
						"Salvador",
						"Santa Cruz Cabralia",
						"Santa Ines",
						"Santa Maria da Vitoria",
						"Santa Rita de Cassia",
						"Santaluz",
						"Santana",
						"Santo Amaro",
						"Santo Antonio de Jesus",
						"Santo Estevao",
						"Sao Desiderio",
						"Sao Felipe",
						"Sao Francisco do Conde",
						"Sao Gabriel",
						"Sao Goncalo dos Campos",
						"Sao Sebastiao do Passe",
						"Saubara",
						"Seabra",
						"Senhor do Bonfim",
						"Sento Se",
						"Serra Dourada",
						"Serra do Ramalho",
						"Serrinha",
						"Simoes Filho",
						"Sobradinho",
						"Souto Soares",
						"Tanhacu",
						"Taperoa",
						"Tapiramuta",
						"Teixeira de Freitas",
						"Teofilandia",
						"Terra Nova",
						"Tremedal",
						"Tucano",
						"Uaua",
						"Ubaira",
						"Ubaitaba",
						"Ubata",
						"Una",
						"Urucuca",
						"Utinga",
						"Valenca",
						"Valente",
						"Vera Cruz",
						"Vitoria da Conquista",
						"Wenceslau Guimaraes",
						"Xique-Xique",
					],
					stateName: "Bahia",
				},
				{
					cities: [
						"Acarau",
						"Acopiara",
						"Amontada",
						"Aquiraz",
						"Aracati",
						"Aracoiaba",
						"Araripe",
						"Assare",
						"Aurora",
						"Barbalha",
						"Barro",
						"Barroquinha",
						"Baturite",
						"Beberibe",
						"Bela Cruz",
						"Boa Viagem",
						"Brejo Santo",
						"Camocim",
						"Campos Sales",
						"Caninde",
						"Carire",
						"Caririacu",
						"Cascavel",
						"Caucaia",
						"Cedro",
						"Chorozinho",
						"Coreau",
						"Crateus",
						"Crato",
						"Cruz",
						"Eusebio",
						"Farias Brito",
						"Forquilha",
						"Fortaleza",
						"Granja",
						"Guaiuba",
						"Guaraciaba do Norte",
						"Hidrolandia",
						"Horizonte",
						"Ibiapina",
						"Ico",
						"Iguatu",
						"Independencia",
						"Ipu",
						"Ipueiras",
						"Iraucuba",
						"Itaitinga",
						"Itapage",
						"Itapipoca",
						"Itarema",
						"Jaguaribe",
						"Jaguaruana",
						"Jardim",
						"Juazeiro do Norte",
						"Jucas",
						"Lavras da Mangabeira",
						"Limoeiro do Norte",
						"Maracanau",
						"Maranguape",
						"Marco",
						"Massape",
						"Mauriti",
						"Milagres",
						"Missao Velha",
						"Mombaca",
						"Morada Nova",
						"Nova Russas",
						"Novo Oriente",
						"Ocara",
						"Oros",
						"Pacajus",
						"Pacatuba",
						"Paracuru",
						"Paraipaba",
						"Parambu",
						"Pedra Branca",
						"Pentecoste",
						"Quixada",
						"Quixeramobim",
						"Quixere",
						"Redencao",
						"Reriutaba",
						"Russas",
						"Santa Quiteria",
						"Santana do Acarau",
						"Sao Benedito",
						"Sao Goncalo do Amarante",
						"Senador Pompeu",
						"Sobral",
						"Tabuleiro do Norte",
						"Tamboril",
						"Taua",
						"Tiangua",
						"Trairi",
						"Ubajara",
						"Umirim",
						"Uruburetama",
						"Varjota",
						"Varzea Alegre",
						"Vicosa do Ceara",
					],
					stateName: "Ceara",
				},
				{ cities: [], stateName: "Distrito Federal" },
				{ cities: [], stateName: "Espirito Santo" },
				{ cities: [], stateName: "Estado de Sao Paulo" },
				{
					cities: [
						"Abadiania",
						"Acreuna",
						"Aguas Lindas de Goias",
						"Alexania",
						"Anapolis",
						"Anicuns",
						"Aparecida de Goiania",
						"Aragarcas",
						"Bela Vista de Goias",
						"Bom Jesus de Goias",
						"Buriti Alegre",
						"Cacu",
						"Caiaponia",
						"Caldas Novas",
						"Campos Belos",
						"Campos Verdes",
						"Carmo do Rio Verde",
						"Catalao",
						"Cavalcante",
						"Ceres",
						"Cidade Ocidental",
						"Cocalzinho de Coias",
						"Cristalina",
						"Crixas",
						"Doverlandia",
						"Edeia",
						"Firminopolis",
						"Formosa",
						"Goianapolis",
						"Goianesia",
						"Goiania",
						"Goianira",
						"Goias",
						"Goiatuba",
						"Guapo",
						"Hidrolandia",
						"Iaciara",
						"Indiara",
						"Inhumas",
						"Ipameri",
						"Ipora",
						"Itaberai",
						"Itapaci",
						"Itapirapua",
						"Itapuranga",
						"Itumbiara",
						"Jaragua",
						"Jatai",
						"Jussara",
						"Luziania",
						"Mara Rosa",
						"Minacu",
						"Mineiros",
						"Morrinhos",
						"Mozarlandia",
						"Neropolis",
						"Niquelandia",
						"Nova Crixas",
						"Novo Gama",
						"Orizona",
						"Padre Bernardo",
						"Palmeiras de Goias",
						"Parauna",
						"Petrolina de Goias",
						"Piracanjuba",
						"Piranhas",
						"Pirenopolis",
						"Pires do Rio",
						"Planaltina",
						"Pontalina",
						"Porangatu",
						"Posse",
						"Quirinopolis",
						"Rialma",
						"Rio Verde",
						"Rubiataba",
						"Santa Helena de Goias",
						"Santa Terezinha de Goias",
						"Santo Antonio do Descoberto",
						"Sao Domingos",
						"Sao Luis de Montes Belos",
						"Sao Miguel do Araguaia",
						"Sao Simao",
						"Senador Canedo",
						"Silvania",
						"Trindade",
						"Uruacu",
						"Uruana",
						"Valparaiso de Goias",
						"Vianopolis",
					],
					stateName: "Goias",
				},
				{
					cities: [
						"Acailandia",
						"Alcantara",
						"Aldeias Altas",
						"Alto Alegre do Pindare",
						"Amarante do Maranhao",
						"Anajatuba",
						"Araioses",
						"Arame",
						"Arari",
						"Bacabal",
						"Balsas",
						"Barra do Corda",
						"Barreirinhas",
						"Bequimao",
						"Bom Jardim",
						"Brejo",
						"Buriti",
						"Buriti Bravo",
						"Buriticupu",
						"Candido Mendes",
						"Cantanhede",
						"Carolina",
						"Carutapera",
						"Caxias",
						"Chapadinha",
						"Codo",
						"Coelho Neto",
						"Colinas",
						"Coroata",
						"Cururupu",
						"Davinopolis",
						"Dom Pedro",
						"Esperantinopolis",
						"Estreito",
						"Fortuna",
						"Godofredo Viana",
						"Governador Eugenio Barros",
						"Governador Nunes Freire",
						"Grajau",
						"Humberto de Campos",
						"Icatu",
						"Imperatriz",
						"Itapecuru Mirim",
						"Itinga do Maranhao",
						"Joao Lisboa",
						"Lago da Pedra",
						"Lago do Junco",
						"Maracacume",
						"Matinha",
						"Matoes",
						"Mirador",
						"Miranda do Norte",
						"Moncao",
						"Montes Altos",
						"Morros",
						"Nova Olinda do Maranhao",
						"Olho d'Agua das Cunhas",
						"Paco do Lumiar",
						"Paraibano",
						"Parnarama",
						"Passagem Franca",
						"Pastos Bons",
						"Paulo Ramos",
						"Pedreiras",
						"Penalva",
						"Pindare Mirim",
						"Pinheiro",
						"Pio XII",
						"Pirapemas",
						"Pocao de Pedras",
						"Porto Franco",
						"Presidente Dutra",
						"Raposa",
						"Riachao",
						"Rosario",
						"Santa Helena",
						"Santa Ines",
						"Santa Luzia",
						"Santa Luzia do Parua",
						"Santa Quiteria do Maranhao",
						"Santa Rita",
						"Sao Benedito do Rio Preto",
						"Sao Bento",
						"Sao Bernardo",
						"Sao Domingos do Maranhao",
						"Sao Joao Batista",
						"Sao Joao dos Patos",
						"Sao Jose de Ribamar",
						"Sao Luis",
						"Sao Luis Gonzaga do Maranhao",
						"Sao Mateus do Maranhao",
						"Sao Pedro da Agua Branca",
						"Sao Raimundo das Mangabeiras",
						"Timbiras",
						"Timon",
						"Trizidela do Vale",
						"Tuntum",
						"Turiacu",
						"Tutoia",
						"Urbano Santos",
						"Vargem Grande",
						"Viana",
						"Vitoria do Mearim",
						"Vitorino Freire",
						"Ze Doca",
					],
					stateName: "Maranhao",
				},
				{ cities: [], stateName: "Mato Grosso" },
				{ cities: [], stateName: "Mato Grosso do Sul" },
				{ cities: [], stateName: "Minas Gerais" },
				{
					cities: [
						"Abaetetuba",
						"Acara",
						"Afua",
						"Agua Azul do Norte",
						"Alenquer",
						"Almeirim",
						"Altamira",
						"Ananindeua",
						"Augusto Correa",
						"Baiao",
						"Barcarena",
						"Belem",
						"Benevides",
						"Braganca",
						"Breu Branco",
						"Breves",
						"Bujaru",
						"Cameta",
						"Capanema",
						"Capitao Poco",
						"Castanhal",
						"Conceicao do Araguaia",
						"Concordia do Para",
						"Curionopolis",
						"Curuca",
						"Dom Eliseu",
						"Eldorado dos Carajas",
						"Garrafao do Norte",
						"Goianesia do Para",
						"Gurupa",
						"Igarape-Acu",
						"Igarape-Miri",
						"Irituia",
						"Itaituba",
						"Itupiranga",
						"Jacareacanga",
						"Jacunda",
						"Juruti",
						"Limoeiro do Ajuru",
						"Mae do Rio",
						"Maraba",
						"Maracana",
						"Marapanim",
						"Marituba",
						"Medicilandia",
						"Mocajuba",
						"Moju",
						"Monte Alegre",
						"Muana",
						"Novo Progresso",
						"Novo Repartimento",
						"Obidos",
						"Oeiras do Para",
						"Oriximina",
						"Ourem",
						"Ourilandia",
						"Pacaja",
						"Paragominas",
						"Parauapebas",
						"Portel",
						"Porto de Moz",
						"Prainha",
						"Redencao",
						"Rio Maria",
						"Rondon do Para",
						"Ruropolis",
						"Salinopolis",
						"Santa Isabel do Para",
						"Santa Luzia do Para",
						"Santa Maria do Para",
						"Santana do Araguaia",
						"Santarem",
						"Santo Antonio do Taua",
						"Sao Caetano de Odivelas",
						"Sao Domingos do Araguaia",
						"Sao Domingos do Capim",
						"Sao Felix do Xingu",
						"Sao Geraldo do Araguaia",
						"Sao Joao de Pirabas",
						"Sao Miguel do Guama",
						"Senador Jose Porfirio",
						"Soure",
						"Tailandia",
						"Terra Santa",
						"Tome-Acu",
						"Tucuma",
						"Tucurui",
						"Ulianopolis",
						"Uruara",
						"Vigia",
						"Viseu",
						"Xinguara",
					],
					stateName: "Para",
				},
				{
					cities: [
						"Alagoa Grande",
						"Alagoa Nova",
						"Alagoinha",
						"Alhandra",
						"Aracagi",
						"Arara",
						"Araruna",
						"Areia",
						"Aroeiras",
						"Bananeiras",
						"Barra de Santa Rosa",
						"Bayeux",
						"Belem",
						"Boqueirao",
						"Brejo do Cruz",
						"Caapora",
						"Cabedelo",
						"Cacimba de Dentro",
						"Cajazeiras",
						"Campina Grande",
						"Catole do Rocha",
						"Conceicao",
						"Conde",
						"Coremas",
						"Cruz do Espirito Santo",
						"Cuite",
						"Desterro",
						"Dona Ines",
						"Esperanca",
						"Fagundes",
						"Guarabira",
						"Gurinhem",
						"Imaculada",
						"Inga",
						"Itabaiana",
						"Itaporanga",
						"Itapororoca",
						"Itatuba",
						"Jacarau",
						"Joao Pessoa",
						"Juazeirinho",
						"Juripiranga",
						"Juru",
						"Lagoa Seca",
						"Mamanguape",
						"Manaira",
						"Mari",
						"Massaranduba",
						"Mogeiro",
						"Monteiro",
						"Mulungu",
						"Natuba",
						"Nova Floresta",
						"Patos",
						"Paulista",
						"Pedras de Fogo",
						"Pianco",
						"Picui",
						"Pilar",
						"Pirpirituba",
						"Pitimbu",
						"Pocinhos",
						"Pombal",
						"Princesa Isabel",
						"Puxinana",
						"Queimadas",
						"Remigio",
						"Rio Tinto",
						"Salgado de Sao Felix",
						"Santa Luzia",
						"Santa Rita",
						"Sao Bento",
						"Sao Joao do Rio do Peixe",
						"Sao Jose de Piranhas",
						"Sao Sebastiao de Lagoa de Roca",
						"Sape",
						"Serra Branca",
						"Solanea",
						"Soledade",
						"Sousa",
						"Sume",
						"Taperoa",
						"Tavares",
						"Teixeira",
						"Triunfo",
						"Uirauna",
						"Umbuzeiro",
					],
					stateName: "Paraiba",
				},
				{
					cities: [
						"Almirante Tamandare",
						"Alto Parana",
						"Alto Piquiri",
						"Altonia",
						"Ampere",
						"Andira",
						"Antonina",
						"Apucarana",
						"Arapongas",
						"Arapoti",
						"Araucaria",
						"Assai",
						"Assis Chateaubriand",
						"Astorga",
						"Bandeirantes",
						"Barbosa Ferraz",
						"Bela Vista do Paraiso",
						"Cambara",
						"Cambe",
						"Campina Grande do Sul",
						"Campina da Lagoa",
						"Campo Largo",
						"Campo Murao",
						"Candido de Abreu",
						"Capitao Leonidas Marques",
						"Carambei",
						"Cascavel",
						"Castro",
						"Centenario do Sul",
						"Chopinzinho",
						"Cianorte",
						"Clevelandia",
						"Colombo",
						"Colorado",
						"Contenda",
						"Corbelia",
						"Cornelio Procopio",
						"Coronel Vivida",
						"Cruzeiro do Oeste",
						"Curitiba",
						"Dois Vizinhos",
						"Engenheiro Beltrao",
						"Faxinal",
						"Fazenda Rio Grande",
						"Florestopolis",
						"Foz do Iguacu",
						"Francisco Beltrao",
						"Goioere",
						"Guaira",
						"Guaraniacu",
						"Guarapuava",
						"Guaratuba",
						"Ibaiti",
						"Ibipora",
						"Imbituva",
						"Ipora",
						"Irati",
						"Itaperucu",
						"Ivaipora",
						"Jacarezinho",
						"Jaguariaiva",
						"Jandaia do Sul",
						"Jataizinho",
						"Lapa",
						"Laranjeiras do Sul",
						"Loanda",
						"Londrina",
						"Mandaguacu",
						"Mandaguari",
						"Marechal Candido Rondon",
						"Marialva",
						"Maringa",
						"Matelandia",
						"Matinhos",
						"Medianeira",
						"Moreira Sales",
						"Nova Aurora",
						"Nova Esperanca",
						"Nova Londrina",
						"Ortigueira",
						"Paicandu",
						"Palmas",
						"Palmeira",
						"Palotina",
						"Paranagua",
						"Paranavai",
						"Pato Branco",
						"Peabiru",
						"Pinhais",
						"Pinhao",
						"Pirai do Sul",
						"Piraquara",
						"Pitanga",
						"Ponta Grossa",
						"Pontal do Parana",
						"Porecatu",
						"Primero de Maio",
						"Prudentopolis",
						"Quatro Barras",
						"Quedas do Iguacu",
						"Realeza",
						"Reserva",
						"Ribeirao do Pinhal",
						"Rio Branco do Sul",
						"Rio Negro",
						"Rolandia",
						"Santa Helena",
						"Santa Terezinha de Itaipu",
						"Santo Antonio da Platina",
						"Santo Antonio do Sudoeste",
						"Sao Joao do Ivai",
						"Sao Jose dos Pinhais",
						"Sao Mateus do Sul",
						"Sao Miguel do Iguacu",
						"Sarandi",
						"Senges",
						"Sertanopolis",
						"Siquera Campos",
						"Tapejara",
						"Telemaco Borba",
						"Terra Boa",
						"Terra Rica",
						"Terra Roxa",
						"Tibagi",
						"Toledo",
						"Ubirata",
						"Umuarama",
						"Uniao da Victoria",
						"Wenceslau Braz",
					],
					stateName: "Parana",
				},
				{
					cities: [
						"Abreu e Lima",
						"Afogados da Ingazeira",
						"Agrestina",
						"Agua Preta",
						"Aguas Belas",
						"Alianca",
						"Altinho",
						"Amaraji",
						"Aracoiaba",
						"Araripina",
						"Arcoverde",
						"Barra de Guabiraba",
						"Barreiros",
						"Belem de Sao Francisco",
						"Belo Jardim",
						"Bezerros",
						"Bodoco",
						"Bom Conselho",
						"Bom Jardim",
						"Bonito",
						"Brejo da Madre de Deus",
						"Buique",
						"Cabo de Santo Agostinho",
						"Cabrobo",
						"Cachoeirinha",
						"Caetes",
						"Camaragibe",
						"Camocim de Sao Felix",
						"Canhotinho",
						"Capoeiras",
						"Carnaiba",
						"Carpina",
						"Caruaru",
						"Catende",
						"Cha Grande",
						"Condado",
						"Cumaru",
						"Cupira",
						"Custodia",
						"Escada",
						"Exu",
						"Feira Nova",
						"Fernando de Noronha",
						"Flores",
						"Floresta",
						"Gameleira",
						"Garanhuns",
						"Gloria do Goita",
						"Goiana",
						"Gravata",
						"Ibimirim",
						"Igarassu",
						"Inaja",
						"Ipojuca",
						"Ipubi",
						"Itaiba",
						"Itamaraca",
						"Itambe",
						"Itapissuma",
						"Itaquitinga",
						"Jaboatao",
						"Joao Alfredo",
						"Joaquim Nabuco",
						"Lagoa do Itaenga",
						"Lajedo",
						"Limoeiro",
						"Macaparana",
						"Maraial",
						"Moreno",
						"Nazare da Mata",
						"Olinda",
						"Orobo",
						"Ouricuri",
						"Palmares",
						"Panelas",
						"Parnamirim",
						"Passira",
						"Paudalho",
						"Paulista",
						"Pedra",
						"Pesqueira",
						"Petrolandia",
						"Petrolina",
						"Pombos",
						"Quipapa",
						"Recife",
						"Ribeirao",
						"Rio Formoso",
						"Salgueiro",
						"Santa Cruz do Capibaribe",
						"Santa Maria da Boa Vista",
						"Sao Bento do Una",
						"Sao Caitano",
						"Sao Joao",
						"Sao Joaquim do Monte",
						"Sao Jose da Coroa Grande",
						"Sao Jose do Belmonte",
						"Sao Jose do Egito",
						"Sao Lourenco da Mata",
						"Serra Talhada",
						"Sertania",
						"Sirinhaem",
						"Surubim",
						"Tabira",
						"Tamandare",
						"Taquaritinga do Norte",
						"Timbauba",
						"Toritama",
						"Trindade",
						"Triunfo",
						"Tupanatinga",
						"Vicencia",
						"Vitoria de Santo Antao",
					],
					stateName: "Pernambuco",
				},
				{
					cities: [
						"Agua Branca",
						"Alto Longa",
						"Altos",
						"Amarante",
						"Avelino Lopes",
						"Barras",
						"Batalha",
						"Beneditinos",
						"Bom Jesus",
						"Buriti dos Lopes",
						"Campo Maior",
						"Canto do Buriti",
						"Castelo do Piaui",
						"Cocal",
						"Corrente",
						"Demerval Lobao",
						"Elesbao Veloso",
						"Esperantina",
						"Floriano",
						"Gilbues",
						"Guadalupe",
						"Inhuma",
						"Itainopolis",
						"Itaueira",
						"Jaicos",
						"Joaquim Pires",
						"Jose de Freitas",
						"Luis Correia",
						"Luzilandia",
						"Matias Olimpio",
						"Miguel Alves",
						"Monsenhor Gil",
						"Oeiras",
						"Palmeirais",
						"Parnaiba",
						"Pedro II",
						"Picos",
						"Pimenteiras",
						"Pio IX",
						"Piracuruca",
						"Piripiri",
						"Porto",
						"Regeneracao",
						"Sao Joao do Piaui",
						"Sao Miguel do Tapuio",
						"Sao Pedro do Piaui",
						"Sao Raimundo Nonato",
						"Simoes",
						"Simplicio Mendes",
						"Teresina",
						"Uniao",
						"Urucui",
						"Valenca do Piaui",
					],
					stateName: "Piaui",
				},
				{ cities: [], stateName: "Rio Grande do Norte" },
				{ cities: [], stateName: "Rio Grande do Sul" },
				{ cities: [], stateName: "Rio de Janeiro" },
				{
					cities: [
						"Alta Floresta d'Oeste",
						"Alto Alegre do Parecis",
						"Alto Paraiso",
						"Alvorada d'Oeste",
						"Ariquemes",
						"Buritis",
						"Cacoal",
						"Candeias do Jamari",
						"Cerejeiras",
						"Colorado do Oeste",
						"Corumbiara",
						"Espigao d'Oeste",
						"Governador Jorge Teixeira",
						"Guajara-Mirim",
						"Jaru",
						"Ji-Parana",
						"Machadinho d'Oeste",
						"Ministro Andreazza",
						"Mirante da Serra",
						"Nova Brasilandia d'Oeste",
						"Nova Mamore",
						"Novo Horizonte do Oeste",
						"Ouro Preto do Oeste",
						"Pimenta Bueno",
						"Porto Velho",
						"Presidente Medici",
						"Rolim de Moura",
						"Santa Luzia d'Oeste",
						"Sao Miguel do Guapore",
						"Urupa",
						"Vale do Paraiso",
						"Vilhena",
					],
					stateName: "Rondonia",
				},
				{
					cities: [
						"Alto Alegre",
						"Boa Vista",
						"Bonfim",
						"Caracarai",
						"Mucajai",
						"Normandia",
						"Sao Joao da Baliza",
						"Sao Luiz",
					],
					stateName: "Roraima",
				},
				{ cities: [], stateName: "Santa Catarina" },
				{ cities: [], stateName: "Sao Paulo" },
				{
					cities: [
						"Aquidaba",
						"Aracaju",
						"Araua",
						"Areia Branca",
						"Barra dos Coqueiros",
						"Boquim",
						"Campo do Brito",
						"Caninde de Sao Francisco",
						"Capela",
						"Carira",
						"Cristinapolis",
						"Estancia",
						"Frei Paulo",
						"Gararu",
						"Indiaroba",
						"Itabaiana",
						"Itabaianinha",
						"Itaporanga d'Ajuda",
						"Japaratuba",
						"Japoata",
						"Lagarto",
						"Laranjeiras",
						"Malhador",
						"Maruim",
						"Moita Bonita",
						"Monte Alegre de Sergipe",
						"Neopolis",
						"Nossa Senhora da Gloria",
						"Nossa Senhora das Dores",
						"Nossa Senhora do Socorro",
						"Pacatuba",
						"Poco Verde",
						"Porto da Folha",
						"Propria",
						"Riachao do Dantas",
						"Ribeiropolis",
						"Salgado",
						"Santa Luzia do Itanhy",
						"Santo Amaro das Brotas",
						"Sao Cristovao",
						"Simao Dias",
						"Tobias Barreto",
						"Tomar do Geru",
						"Umbauba",
					],
					stateName: "Sergipe",
				},
				{
					cities: [
						"Alvorada",
						"Ananas",
						"Araguacu",
						"Araguaina",
						"Araguatins",
						"Arraias",
						"Augustinopolis",
						"Axixa do Tocantins",
						"Colinas do Tocantins",
						"Dianopolis",
						"Formoso do Araguaia",
						"Goiatins",
						"Guarai",
						"Gurupi",
						"Miracema do Tocantins",
						"Miranorte",
						"Palmas",
						"Paraiso",
						"Parana",
						"Porto Nacional",
						"Sitio Novo do Tocantins",
						"Taguatinga",
						"Tocantinopolis",
						"Wanderlandia",
						"Xambioa",
					],
					stateName: "Tocantins",
				},
			],
			countryName: "Brazil",
		},
		{
			states: [{ cities: [], stateName: "British Indian Ocean Territory" }],
			countryName: "British Indian Ocean Territory",
		},
		{
			states: [
				{ cities: ["Kuala Belait", "Seria"], stateName: "Belait" },
				{ cities: ["Bandar Seri Begawan"], stateName: "Brunei-Muara" },
				{ cities: ["Bangar"], stateName: "Temburong" },
				{ cities: ["Tutong"], stateName: "Tutong" },
			],
			countryName: "Brunei",
		},
		{
			states: [
				{
					cities: [
						"Bansko",
						"Belica",
						"Blagoevgrad",
						"Goce Delchev",
						"Hadzhidimovo",
						"Jakoruda",
						"Kresna",
						"Melnik",
						"Petrich",
						"Razlog",
						"Sandanski",
						"Simitli",
					],
					stateName: "Blagoevgrad",
				},
				{
					cities: [
						"Ahtopol",
						"Ajtos",
						"Balgarovo",
						"Bourgas",
						"Burgas",
						"Carevo",
						"Kableshkovo",
						"Kameno",
						"Karnobat",
						"Malko Tarnovo",
						"Nesebar",
						"Obzor",
						"Pomorie",
						"Primorsko",
						"Sozopol",
						"Sredec",
						"Sungurlare",
						"Tvardica",
					],
					stateName: "Burgas",
				},
				{
					cities: [
						"Balchik",
						"Dobrich",
						"General-Toshevo",
						"Kavarna",
						"Loznica",
						"Shabla",
						"Tervel",
					],
					stateName: "Dobrich",
				},
				{
					cities: ["Drjanovo", "Gabrovo", "Plachkovci", "Sevlievo", "Trjavna"],
					stateName: "Gabrovo",
				},
				{
					cities: [
						"Dimitrovgrad",
						"Harmanli",
						"Haskovo",
						"Ivajlovgrad",
						"Ljubimec",
						"Madzharovo",
						"Merichleri",
						"Simeonovgrad",
						"Svilengrad",
					],
					stateName: "Haskovo",
				},
				{
					cities: ["Boljarovo", "Elhovo", "Jambol", "Straldzha", "Topolovgrad"],
					stateName: "Jambol",
				},
				{
					cities: [
						"Ardino",
						"Dzhebel",
						"Kardzhali",
						"Krumovgrad",
						"Momchilgrad",
					],
					stateName: "Kardzhali",
				},
				{
					cities: [
						"Boboshevo",
						"Bobovdol",
						"Dupnica",
						"Kjustendil",
						"Kocherinovo",
						"Rila",
						"Sapareva Banja",
						"Zemen",
					],
					stateName: "Kjustendil",
				},
				{
					cities: [
						"Aprilci",
						"Jablanica",
						"Letnica",
						"Lovech",
						"Lukovit",
						"Sopot",
						"Teteven",
						"Trojan",
						"Ugarchin",
					],
					stateName: "Lovech",
				},
				{
					cities: [
						"Berkovica",
						"Bojchinovci",
						"Brusarci",
						"Chiprovci",
						"Lom",
						"Montana",
						"Valchedram",
						"Varshec",
					],
					stateName: "Montana",
				},
				{ cities: [], stateName: "Oblast Sofiya-Grad" },
				{
					cities: [
						"Batak",
						"Belovo",
						"Bracigovo",
						"Koprivshtica",
						"Panagjurishte",
						"Pazardzhik",
						"Peshtera",
						"Rakitovo",
						"Septemvri",
						"Strelcha",
						"Velingrad",
					],
					stateName: "Pazardzhik",
				},
				{
					cities: [
						"Bankja",
						"Batanovci",
						"Breznik",
						"Pernik",
						"Radomir",
						"Tran",
					],
					stateName: "Pernik",
				},
				{
					cities: [
						"Belene",
						"Cherven Brjag",
						"Dolna Mitropolija",
						"Dolni Dabnik",
						"Guljanci",
						"Levski",
						"Nikopol",
						"Pleven",
						"Pordim",
						"Slavjanovo",
						"Trashtenik",
						"Varbica",
					],
					stateName: "Pleven",
				},
				{
					cities: [
						"Asenovgrad",
						"Brezovo",
						"Car Kalojan",
						"Hisarja",
						"Kalofer",
						"Karlovo",
						"Klisura",
						"Krichim",
						"Parvomaj",
						"Perushtica",
						"Plovdiv",
						"Rakovski",
						"Sadovo",
						"Saedinenie",
						"Stambolijski",
					],
					stateName: "Plovdiv",
				},
				{
					cities: ["Isperih", "Kubrat", "Razgrad", "Senovo", "Zavet"],
					stateName: "Razgrad",
				},
				{
					cities: ["Bjala", "Borovo", "Dve Mogili", "Ruse", "Russe", "Vetovo"],
					stateName: "Ruse",
				},
				{
					cities: [
						"Kaolinovo",
						"Kaspichan",
						"Novi Pazar",
						"Pliska",
						"Shumen",
						"Smjadovo",
						"Veliki Preslav",
					],
					stateName: "Shumen",
				},
				{
					cities: ["Alfatar", "Dulovo", "Glavinica", "Silistra", "Tutrakan"],
					stateName: "Silistra",
				},
				{
					cities: ["Kermen", "Kotel", "Nova Zagora", "Shivachevo", "Sliven"],
					stateName: "Sliven",
				},
				{
					cities: [
						"Chepelare",
						"Devin",
						"Dospat",
						"Laki",
						"Madan",
						"Nedelino",
						"Rudozem",
						"Smoljan",
						"Zlatograd",
					],
					stateName: "Smoljan",
				},
				{ cities: [], stateName: "Sofija grad" },
				{ cities: [], stateName: "Sofijska oblast" },
				{ cities: [], stateName: "Stara Zagora" },
				{
					cities: ["Antonovo", "Omurtag", "Opaka", "Popovo", "Targovishte"],
					stateName: "Targovishte",
				},
				{
					cities: [
						"Beloslav",
						"Bjala",
						"Dalgopol",
						"Devnja",
						"Iskar",
						"Provadija",
						"Suvorovo",
						"Valchi Dol",
						"Varna",
					],
					stateName: "Varna",
				},
				{ cities: [], stateName: "Veliko Tarnovo" },
				{
					cities: [
						"Belogradchik",
						"Bregovo",
						"Dimovo",
						"Dolni Chiflik",
						"Dunavci",
						"Gramada",
						"Kula",
						"Vidin",
					],
					stateName: "Vidin",
				},
				{
					cities: [
						"Bjala Slatina",
						"Knezha",
						"Kojnare",
						"Kozloduj",
						"Krivodol",
						"Mezdra",
						"Mizija",
						"Orjahovo",
						"Roman",
						"Vraca",
					],
					stateName: "Vraca",
				},
				{ cities: ["Yablaniza"], stateName: "Yablaniza" },
			],
			countryName: "Bulgaria",
		},
		{
			states: [
				{ cities: ["Boromo"], stateName: "Bale" },
				{ cities: ["Kongoussi"], stateName: "Bam" },
				{ cities: ["Kombissiri"], stateName: "Bazega" },
				{ cities: ["Diebougou", "Pa"], stateName: "Bougouriba" },
				{ cities: ["Garango", "Tenkodogo"], stateName: "Boulgou" },
				{ cities: ["Koudougou"], stateName: "Boulkiemde" },
				{ cities: ["Banfora"], stateName: "Comoe" },
				{ cities: ["Zorgo"], stateName: "Ganzourgou" },
				{ cities: ["Bogande"], stateName: "Gnagna" },
				{ cities: ["Fada N'gourma"], stateName: "Gourma" },
				{ cities: ["Bekuy", "Bobo Dioulasso"], stateName: "Houet" },
				{ cities: ["Dano"], stateName: "Ioba" },
				{ cities: ["Ouagadougou"], stateName: "Kadiogo" },
				{ cities: ["Koalla", "Koloko", "Orodara"], stateName: "Kenedougou" },
				{ cities: ["Gayeri"], stateName: "Komandjari" },
				{ cities: ["Pama"], stateName: "Kompienga" },
				{ cities: ["Nouna"], stateName: "Kossi" },
				{ cities: ["Koupela"], stateName: "Kouritenga" },
				{ cities: ["Bousse"], stateName: "Kourweogo" },
				{ cities: ["Sindou"], stateName: "Leraba" },
				{ cities: ["Dedougou"], stateName: "Mouhoun" },
				{ cities: ["Po"], stateName: "Nahouri" },
				{ cities: ["Boulsa"], stateName: "Namentenga" },
				{ cities: ["Batie"], stateName: "Noumbiel" },
				{ cities: ["Ziniare"], stateName: "Oubritenga" },
				{ cities: ["Gorom-Gorom"], stateName: "Oudalan" },
				{ cities: ["Yako"], stateName: "Passore" },
				{ cities: ["Gaoua", "Kampti", "Loropeni"], stateName: "Poni" },
				{ cities: ["Reo"], stateName: "Sanguie" },
				{ cities: ["Kaya"], stateName: "Sanmatenga" },
				{ cities: ["Dori"], stateName: "Seno" },
				{ cities: ["Gao", "Leo"], stateName: "Sissili" },
				{ cities: ["Aribinda", "Djibo"], stateName: "Soum" },
				{ cities: ["Louta", "Tougan"], stateName: "Sourou" },
				{ cities: ["Diapaga", "Kantchari"], stateName: "Tapoa" },
				{ cities: ["Hounde"], stateName: "Tuy" },
				{ cities: ["Ouahigouya"], stateName: "Yatenga" },
				{ cities: ["Gourcy"], stateName: "Zondoma" },
				{ cities: ["Manga"], stateName: "Zoundweogo" },
			],
			countryName: "Burkina Faso",
		},
		{
			states: [
				{ cities: ["Bubanza"], stateName: "Bubanza" },
				{ cities: ["Bujumbura"], stateName: "Bujumbura" },
				{ cities: ["Bururi"], stateName: "Bururi" },
				{ cities: ["Cankuzo"], stateName: "Cankuzo" },
				{ cities: ["Cibitoke"], stateName: "Cibitoke" },
				{ cities: ["Gitega"], stateName: "Gitega" },
				{ cities: ["Karuzi"], stateName: "Karuzi" },
				{ cities: ["Kayanza"], stateName: "Kayanza" },
				{ cities: ["Kirundo"], stateName: "Kirundo" },
				{ cities: ["Makamba"], stateName: "Makamba" },
				{ cities: ["Muramvya"], stateName: "Muramvya" },
				{ cities: ["Muyinga"], stateName: "Muyinga" },
				{ cities: ["Ngozi"], stateName: "Ngozi" },
				{ cities: ["Rutana"], stateName: "Rutana" },
				{ cities: ["Ruyigi"], stateName: "Ruyigi" },
			],
			countryName: "Burundi",
		},
		{
			states: [
				{ cities: [], stateName: "Banteay Mean Chey" },
				{ cities: [], stateName: "Bat Dambang" },
				{ cities: [], stateName: "Kampong Cham" },
				{ cities: [], stateName: "Kampong Chhnang" },
				{ cities: [], stateName: "Kampong Spoeu" },
				{ cities: [], stateName: "Kampong Thum" },
				{ cities: ["Kampot"], stateName: "Kampot" },
				{ cities: ["Ta Khmau"], stateName: "Kandal" },
				{ cities: [], stateName: "Kaoh Kong" },
				{ cities: ["Kracheh"], stateName: "Kracheh" },
				{ cities: [], stateName: "Krong Kaeb" },
				{ cities: [], stateName: "Krong Pailin" },
				{ cities: [], stateName: "Krong Preah Sihanouk" },
				{ cities: [], stateName: "Mondol Kiri" },
				{ cities: [], stateName: "Otdar Mean Chey" },
				{ cities: [], stateName: "Phnum Penh" },
				{ cities: ["Pousat"], stateName: "Pousat" },
				{ cities: [], stateName: "Preah Vihear" },
				{ cities: [], stateName: "Prey Veaeng" },
				{ cities: [], stateName: "Rotanak Kiri" },
				{ cities: [], stateName: "Siem Reab" },
				{ cities: [], stateName: "Stueng Traeng" },
				{ cities: [], stateName: "Svay Rieng" },
				{ cities: ["Phumi Takaev"], stateName: "Takaev" },
			],
			countryName: "Cambodia",
		},
		{
			states: [
				{
					cities: ["Banyo", "Meiganga", "Ngaoundere", "Tibati", "Tignere"],
					stateName: "Adamaoua",
				},
				{
					cities: [
						"Akonolinga",
						"Bafia",
						"Eseka",
						"Mbalmayo",
						"Mfou",
						"Monatele",
						"Nanga Eboko",
						"Obala",
						"Ombesa",
						"Saa",
						"Yaounde",
					],
					stateName: "Centre",
				},
				{
					cities: [
						"Abong Mbang",
						"Batouri",
						"Bertoua",
						"Betare Oya",
						"Djoum",
						"Doume",
						"Lomie",
						"Yokadouma",
					],
					stateName: "Est",
				},
				{
					cities: [
						"Bonaberi",
						"Dibombari",
						"Douala",
						"Edea",
						"Loum",
						"Manjo",
						"Mbanga",
						"Nkongsamba",
						"Yabassi",
					],
					stateName: "Littoral",
				},
				{
					cities: [
						"Figuif",
						"Garoua",
						"Guider",
						"Lagdo",
						"Poli",
						"Rey Bouba",
						"Tchollire",
					],
					stateName: "Nord",
				},
				{
					cities: [
						"Figuif",
						"Garoua",
						"Guider",
						"Lagdo",
						"Poli",
						"Rey Bouba",
						"Tchollire",
					],
					stateName: "Nord Extreme",
				},
				{
					cities: [
						"Bamenda",
						"Kumbo",
						"Mbengwi",
						"Mme",
						"Njinikom",
						"Nkambe",
						"Wum",
					],
					stateName: "Nordouest",
				},
				{
					cities: [
						"Bafang",
						"Bafoussam",
						"Bafut",
						"Bali",
						"Bana",
						"Bangangte",
						"Djang",
						"Fontem",
						"Foumban",
						"Foumbot",
						"Mbouda",
					],
					stateName: "Ouest",
				},
				{
					cities: [
						"Akom",
						"Ambam",
						"Ebolowa",
						"Kribi",
						"Lolodorf",
						"Moloundou",
						"Mvangue",
						"Sangmelima",
					],
					stateName: "Sud",
				},
				{
					cities: [
						"Buea",
						"Idenao",
						"Kumba",
						"Limbe",
						"Mamfe",
						"Muyuka",
						"Tiko",
					],
					stateName: "Sudouest",
				},
			],
			countryName: "Cameroon",
		},
		{
			states: [
				{
					cities: [
						"Airdrie",
						"Athabasca",
						"Banff",
						"Barrhead",
						"Bassano",
						"Beaumont",
						"Beaverlodge",
						"Black Diamond",
						"Blackfalds",
						"Blairmore",
						"Bon Accord",
						"Bonnyville",
						"Bow Island",
						"Brooks",
						"Calgary",
						"Calmar",
						"Camrose",
						"Canmore",
						"Cardston",
						"Carstairs",
						"Chateau Lake Louise",
						"Chestermere",
						"Clairmont",
						"Claresholm",
						"Coaldale",
						"Coalhurst",
						"Cochrane",
						"Crossfield",
						"Devon",
						"Didsbury",
						"Drayton Valley",
						"Drumheller",
						"Edmonton",
						"Edson",
						"Elk Point",
						"Fairview",
						"Falher",
						"Fort MacLeod",
						"Fox Creek",
						"Gibbons",
						"Grand Centre",
						"Grande Cache",
						"Grande Prairie",
						"Grimshaw",
						"Hanna",
						"High Level",
						"High Prairie",
						"High River",
						"Hinton",
						"Irricana",
						"Jasper",
						"Killam",
						"La Crete",
						"Lac la Biche",
						"Lacombe",
						"Lamont",
						"Leduc",
						"Lethbridge",
						"Lloydminster",
						"Magrath",
						"Manning",
						"Mayerthorpe",
						"McMurray",
						"Medicine Hat",
						"Millet",
						"Morinville",
						"Nanton",
						"Okotoks",
						"Olds",
						"Peace River",
						"Penhold",
						"Picture Butte",
						"Pincher Creek",
						"Ponoka",
						"Provost",
						"Raymond",
						"Red Deer",
						"Redwater",
						"Rimbey",
						"Rocky Mountain House",
						"Rocky View",
						"Saint Paul",
						"Sexsmith",
						"Sherwood Park",
						"Slave Lake",
						"Smoky Lake",
						"Spirit River",
						"Spruce Grove",
						"Stettler",
						"Stony Plain",
						"Strathmore",
						"Sundre",
						"Swan Hills",
						"Sylvan Lake",
						"Taber",
						"Three Hills",
						"Tofield",
						"Two Hills",
						"Valleyview",
						"Vegreville",
						"Vermilion",
						"Viking",
						"Vulcan",
						"Wainwright",
						"Wembley",
						"Westlock",
						"Wetaskiwin",
						"Whitecourt",
						"Wood Buffalo",
					],
					stateName: "Alberta",
				},
				{ cities: [], stateName: "British Columbia" },
				{
					cities: [
						"Altona",
						"Beausejour",
						"Boissevain",
						"Brandon",
						"Carberry",
						"Carman",
						"Dauphin",
						"Deloraine",
						"Dugald",
						"Flin Flon",
						"Gimli",
						"Hamiota",
						"Killarney",
						"Lac du Bonnet",
						"Leaf Rapids",
						"Lorette",
						"Melita",
						"Minnedosa",
						"Morden",
						"Morris",
						"Neepawa",
						"Niverville",
						"Pinawa",
						"Portage la Prairie",
						"Ritchot",
						"Rivers",
						"Roblin",
						"Saint Adolphe",
						"Sainte Anne",
						"Sainte Rose du Lac",
						"Selkirk",
						"Shilo",
						"Snow Lake",
						"Souris",
						"Springfield",
						"Steinbach",
						"Stonewall",
						"Stony Mountain",
						"Swan River",
						"The Pas",
						"Thompson",
						"Virden",
						"Winkler",
						"Winnipeg",
					],
					stateName: "Manitoba",
				},
				{ cities: [], stateName: "New Brunswick" },
				{ cities: [], stateName: "Newfoundland and Labrador" },
				{ cities: [], stateName: "Northwest Territories" },
				{ cities: [], stateName: "Nova Scotia" },
				{
					cities: [
						"Clyde River",
						"Iqaluit",
						"Kangerdlinerk",
						"Oqsuqtooq",
						"Pangnirtung",
						"Tununirusiq",
					],
					stateName: "Nunavut",
				},
				{
					cities: [
						"Acton",
						"Ajax",
						"Alexandria",
						"Alfred",
						"Alliston",
						"Almonte",
						"Amherstburg",
						"Amigo Beach",
						"Angus-Borden",
						"Arnprior",
						"Arthur",
						"Athens",
						"Atikokan",
						"Attawapiskat",
						"Aurora",
						"Aylmer",
						"Ayr",
						"Barrie",
						"Barry's Bay",
						"Beamsville",
						"Beaverton",
						"Beeton",
						"Belleville",
						"Belmont",
						"Blenheim",
						"Blind River",
						"Bobcaygeon",
						"Bolton",
						"Bourget",
						"Bowmanville-Newcastle",
						"Bracebridge",
						"Bradford",
						"Brampton",
						"Brantford",
						"Bridgenorth-Chemong Park Area",
						"Brighton",
						"Brockville",
						"Brooklin",
						"Brussels",
						"Burford",
						"Burlington",
						"Caledon",
						"Caledon East",
						"Caledonia",
						"Cambridge",
						"Campbellford",
						"Campbellville",
						"Cannington",
						"Capreol",
						"Cardinal",
						"Carleton Place",
						"Carlisle",
						"Casselman",
						"Cayuga",
						"Chalk River",
						"Chapleau",
						"Chatham",
						"Chesley",
						"Chesterville",
						"Clinton",
						"Cobourg",
						"Cochrane",
						"Colborne",
						"Colchester",
						"Collingwood",
						"Concord",
						"Constance Bay",
						"Cookstown",
						"Cornwall",
						"Creemore",
						"Crystal Beach",
						"Deep River",
						"Delhi",
						"Deseronto",
						"Downsview",
						"Drayton",
						"Dresden",
						"Dryden",
						"Dundalk",
						"Dunnville",
						"Durham",
						"Dutton",
						"Eganville",
						"Elliot Lake",
						"Elmira",
						"Elmvale",
						"Embrun",
						"Englehart",
						"Erin",
						"Espanola",
						"Essex",
						"Etobicoke",
						"Everett",
						"Exeter",
						"Fenelon Falls",
						"Fergus",
						"Forest",
						"Fort Erie",
						"Fort Frances",
						"Frankford",
						"Gananoque",
						"Georgetown",
						"Georgina",
						"Geraldton",
						"Glencoe",
						"Goderich",
						"Golden",
						"Gormley",
						"Grand Bend",
						"Grand Valley",
						"Gravenhurst",
						"Guelph",
						"Hagersville",
						"Haileybury",
						"Hamilton",
						"Hanover",
						"Harriston",
						"Harrow",
						"Hastings",
						"Havelock",
						"Hawkesbury",
						"Hearst",
						"Hensall",
						"Hillsburgh",
						"Hornepayne",
						"Huntsville",
						"Ingersoll",
						"Innisfil",
						"Iroquois",
						"Iroquois Falls",
						"Jarvis",
						"Kanata",
						"Kapuskasing",
						"Kars",
						"Kemptville",
						"Kenora",
						"Kincardine",
						"Kingston",
						"Kirkland Lake",
						"Kitchener",
						"L'Original",
						"Lakefield",
						"Lanark",
						"Leamington",
						"Lindsay",
						"Listowel",
						"Little Current",
						"Lively",
						"London",
						"Longlac",
						"Lucan",
						"Lucknow",
						"Madoc",
						"Manitouwadge",
						"Maple",
						"Marathon",
						"Markdale",
						"Markham",
						"Marmora",
						"Mattawa",
						"Meaford",
						"Metcalfe",
						"Midland",
						"Mildmay",
						"Millbrook",
						"Milton",
						"Milverton",
						"Mississauga",
						"Mississauga Beach",
						"Mitchell",
						"Moose Factory",
						"Morrisburg",
						"Mount Albert",
						"Mount Brydges",
						"Mount Forest",
						"Munster",
						"Nanticoke",
						"Napanee",
						"Nepean",
						"New Hamburg",
						"Newmarket",
						"Newtonville",
						"Nobleton",
						"North Bay",
						"North Gower",
						"North York",
						"Norwich",
						"Norwood",
						"Oakville",
						"Omemee",
						"Onaping-Levack",
						"Ontario",
						"Orangeville",
						"Orillia",
						"Orono",
						"Osgoode",
						"Oshawa",
						"Ottawa",
						"Owen Sound",
						"Paisley",
						"Palmerston",
						"Paris",
						"Parkhill",
						"Parry Sound",
						"Pembroke",
						"Perth",
						"Petawawa",
						"Peterborough",
						"Petrolia",
						"Pickering",
						"Picton",
						"Porcupine",
						"Port Credit",
						"Port Dover",
						"Port Elgin",
						"Port Hope",
						"Port Perry",
						"Port Stanley",
						"Powassan",
						"Prescott",
						"Queensville",
						"Renfrew",
						"Richmond",
						"Richmond Hill",
						"Ridgetown",
						"Rockland",
						"Rockwood",
						"Rodney",
						"Saint Catharines",
						"Saint Catharines-Niagara",
						"Saint George",
						"Saint Jacobs",
						"Saint Marys",
						"Saint Thomas",
						"Sarnia",
						"Sault Sainte Marie",
						"Scarborough",
						"Schomberg",
						"Seaforth",
						"Shelburne",
						"Simcoe",
						"Sioux Lookout",
						"Smiths Falls",
						"Smithville",
						"South River",
						"Southampton",
						"Stayner",
						"Stirling",
						"Stoney Creek",
						"Stoney Point",
						"Stouffville",
						"Stratford",
						"Strathroy",
						"Sturgeon Falls",
						"Sudbury",
						"Sutton",
						"Tavistock",
						"Teeswater",
						"Terrace Bay",
						"Thamesford",
						"Thessalon",
						"Thornbury",
						"Thornhill",
						"Thunder Bay",
						"Tilbury",
						"Tilsonburg",
						"Timmins",
						"Toronto",
						"Tory Hill",
						"Tottenham",
						"Tweed",
						"Uxbridge",
						"Valley East",
						"Vankleek Hill",
						"Vaughan",
						"Vineland",
						"Walkerton",
						"Wallaceburg",
						"Wasaga Beach",
						"Waterdown",
						"Waterford",
						"Waterloo",
						"Watford",
						"Wawa",
						"Welland",
						"Wellesley",
						"Wellington",
						"West Lorne",
						"Wheatley",
						"Whitby",
						"Whitchurch-Stouffville",
						"Wiarton",
						"Wikwemikong",
						"Willowdale",
						"Winchester",
						"Windsor",
						"Wingham",
						"Woodbridge",
						"Woodstock",
						"Wyoming",
					],
					stateName: "Ontario",
				},
				{ cities: [], stateName: "Prince Edward Island" },
				{
					cities: [
						"Acton Vale",
						"Albanel",
						"Alencon",
						"Alma",
						"Amos",
						"Amqui",
						"Anjou",
						"Asbestos",
						"Bagotville",
						"Baie-Comeau",
						"Baie-Saint-Paul",
						"Barraute",
						"Beauceville",
						"Beaupre",
						"Bedford",
						"Beloeil",
						"Bernierville",
						"Berthierville",
						"Betsiamites",
						"Boisbriand",
						"Bonaventure",
						"Boucherville",
						"Bromont",
						"Brossard",
						"Brownsburg",
						"Buckingham",
						"Cabano",
						"Candiac",
						"Cap-Chat",
						"Cap-aux-Meules",
						"Carleton",
						"Causapscal",
						"Chandler",
						"Chapais",
						"Charlesbourg",
						"Chateau-Richer",
						"Chibougamou",
						"Chicoutimi-Jonquiere",
						"Chisasibi",
						"Chute-aux-Outardes",
						"Clermont",
						"Coaticook",
						"Coleraine",
						"Contrecoeur",
						"Cookshire",
						"Cowansville",
						"Crabtree",
						"Danville",
						"Daveluyville",
						"Degelis",
						"Desbiens",
						"Disraeli",
						"Dolbeau",
						"Donnacona",
						"Dorval",
						"Drummondville",
						"East Angus",
						"East Broughton",
						"Farnham",
						"Ferme-Neuve",
						"Fermont",
						"Filion",
						"Forestville",
						"Fort-Coulonge",
						"Gaspe",
						"Gentilly",
						"Granby",
						"Grande-Riviere",
						"Grenville",
						"Ham Nord",
						"Hampstead",
						"Hauterive",
						"Havre-Saint-Pierre",
						"Hebertville",
						"Huntingdon",
						"Joliette",
						"Kingsey Falls",
						"L'Annonciation",
						"L'Ascension-de-Notre-Seigneur",
						"L'Epiphanie",
						"La Malbaie",
						"La Pocatiere",
						"La Sarre",
						"La Tuque",
						"Labelle",
						"Lac-Etchemin",
						"Lac-Lapierre",
						"Lac-Megantic",
						"Lac-au-Saumon",
						"Lachine",
						"Lachute",
						"Lacolle",
						"Lasalle",
						"Laurentides",
						"Laurier-Station",
						"Laval",
						"Lavaltrie",
						"Le Bic",
						"Lebel-sur-Quevillon",
						"Les Cedres",
						"Les Coteaux",
						"Les Escoumins",
						"Liniere",
						"Longueuil",
						"Louiseville",
						"Luceville",
						"Macamic",
						"Magog",
						"Malartic",
						"Maniwaki",
						"Marieville",
						"Maskinonge",
						"Matagami",
						"Matane",
						"Metabetchouan",
						"Mirabel",
						"Mistissini",
						"Mont-Joli",
						"Mont-Laurier",
						"Montmagny",
						"Montreal",
						"Murdochville",
						"Napierville",
						"New Richmond",
						"Nicolet",
						"Normandin",
						"Notre-Dame-du-Bon-Conseil",
						"Notre-Dame-du-Lac",
						"Notre-Dame-du-Mont-Carmel",
						"Oka-Kanesatake",
						"Ormstown",
						"Papineauville",
						"Pierreville",
						"Plessisville",
						"Pointe-Claire",
						"Pont-Rouge",
						"Port-Alfred-Bagotville",
						"Port-Cartier",
						"Portneuf",
						"Price",
						"Princeville",
						"Quebec",
						"Rawdon",
						"Repentigny",
						"Richmond",
						"Rigaud",
						"Rimouski",
						"Riviere-au-Renard",
						"Riviere-du-Loup",
						"Roberval",
						"Rougemont",
						"Rouyn-Noranda",
						"Saint-Agapit",
						"Saint-Alexandre",
						"Saint-Alexis-des-Monts",
						"Saint-Ambroise",
						"Saint-Andre-Avellin",
						"Saint-Anselme",
						"Saint-Apollinaire",
						"Saint-Augustin",
						"Saint-Basile-Sud",
						"Saint-Bruno",
						"Saint-Canut",
						"Saint-Cesaire",
						"Saint-Cyrill-de-Wendover",
						"Saint-Damase",
						"Saint-Damien-de-Buckland",
						"Saint-Denis",
						"Saint-Donat-de-Montcalm",
						"Saint-Ephrem-de-Tring",
						"Saint-Fabien",
						"Saint-Felicien",
						"Saint-Felix-de-Valois",
						"Saint-Gabriel",
						"Saint-Gedeon",
						"Saint-Georges",
						"Saint-Germain-de-Grantham",
						"Saint-Gregoire",
						"Saint-Henri-de-Levis",
						"Saint-Honore",
						"Saint-Hyacinthe",
						"Saint-Jacques",
						"Saint-Jean-Port-Joli",
						"Saint-Jean-de-Dieu",
						"Saint-Jean-sur-Richelieu",
						"Saint-Jerome",
						"Saint-Josephe-de-Beauce",
						"Saint-Josephe-de-Lanoraie",
						"Saint-Josephe-de-la-Riviere-Bl",
						"Saint-Jovite",
						"Saint-Laurent",
						"Saint-Liboire",
						"Saint-Marc-des-Carrieres",
						"Saint-Martin",
						"Saint-Michel-des-Saints",
						"Saint-Pacome",
						"Saint-Pascal",
						"Saint-Pie",
						"Saint-Prosper",
						"Saint-Raphael",
						"Saint-Raymond",
						"Saint-Remi",
						"Saint-Roch-de-l'Achigan",
						"Saint-Sauveur-des-Monts",
						"Saint-Tite",
						"Sainte-Adele",
						"Sainte-Agathe-des-Monts",
						"Sainte-Anne-des-Monts",
						"Sainte-Anne-des-Plaines",
						"Sainte-Catherine",
						"Sainte-Claire",
						"Sainte-Julienne",
						"Sainte-Justine",
						"Sainte-Madeleine",
						"Sainte-Marie",
						"Sainte-Martine",
						"Sainte-Sophie",
						"Sainte-Thecle",
						"Sainte-Therese",
						"Salaberry-de-Valleyfield",
						"Sayabec",
						"Senneterre",
						"Sept-Iles",
						"Shawinigan",
						"Shawville",
						"Sherbrooke",
						"Sorel",
						"St Faustin",
						"St. Hubert",
						"St. Jean Chrysostome",
						"Temiscaming",
						"Terrebonne",
						"Thetford Mines",
						"Thurso",
						"Trois-Pistoles",
						"Trois-Rivieres",
						"Val-David",
						"Val-d'Or",
						"Valcourt",
						"Vallee-Jonction",
						"Vaudreuil",
						"Vercheres",
						"Victoriaville",
						"Ville-Marie",
						"Warwick",
						"Waterloo",
						"Weedon Centre",
						"Westmount",
						"Wickham",
						"Windsor",
						"Yamachiche",
					],
					stateName: "Quebec",
				},
				{
					cities: [
						"Assiniboia",
						"Biggar",
						"Canora",
						"Carlyle",
						"Carnduff",
						"Caronport",
						"Carrot",
						"Dalmeny",
						"Davidson",
						"Esterhazy",
						"Estevan",
						"Eston",
						"Foam Lake",
						"Fort Qu'Appelle",
						"Gravelbourg",
						"Grenfell",
						"Gull Lake",
						"Hudson Bay",
						"Humboldt",
						"Indian Head",
						"Kamsack",
						"Kelvington",
						"Kerrobert",
						"Kindersley",
						"Kipling",
						"La Ronge",
						"Langenburg",
						"Langham",
						"Lanigan",
						"Lloydminster",
						"Lumsden",
						"Macklin",
						"Maple Creek",
						"Martensville",
						"Meadow Lake",
						"Melfort",
						"Melville",
						"Moose Jaw",
						"Moosomin",
						"Nipawin",
						"North Battleford",
						"Outlook",
						"Oxbow",
						"Pilot Butte",
						"Preeceville",
						"Prince Albert",
						"Regina",
						"Rosetown",
						"Rosthem",
						"Saskatoon",
						"Shaunavon",
						"Shellbrook",
						"Swift Current",
						"Tisdale",
						"Unity",
						"Wadena",
						"Warman",
						"Watrous",
						"Weyburn",
						"White City",
						"Wilkie",
						"Wynyard",
						"Yorkton",
					],
					stateName: "Saskatchewan",
				},
				{
					cities: ["Haines Junction", "Mayo", "Whitehorse"],
					stateName: "Yukon",
				},
			],
			countryName: "Canada",
		},
		{
			states: [
				{ cities: ["Sal Rei"], stateName: "Boavista" },
				{ cities: ["Nova Sintra"], stateName: "Brava" },
				{ cities: ["Mosteiros", "Sao Filipe"], stateName: "Fogo" },
				{ cities: ["Vila do Maio"], stateName: "Maio" },
				{ cities: ["Santa Maria"], stateName: "Sal" },
				{ cities: [], stateName: "Santo Antao" },
				{ cities: [], stateName: "Sao Nicolau" },
				{ cities: [], stateName: "Sao Tiago" },
				{ cities: [], stateName: "Sao Vicente" },
			],
			countryName: "Cape Verde",
		},
		{
			states: [{ cities: [], stateName: "Grand Cayman" }],
			countryName: "Cayman Islands",
		},
		{
			states: [
				{ cities: ["Ndele"], stateName: "Bamingui-Bangoran" },
				{ cities: ["Bangui"], stateName: "Bangui" },
				{ cities: ["Alindao", "Kembe", "Mobaye"], stateName: "Basse-Kotto" },
				{ cities: ["Obo", "Zemio"], stateName: "Haut-Mbomou" },
				{ cities: ["Bria", "Ouadda"], stateName: "Haute-Kotto" },
				{ cities: ["Dekoa", "Sibut"], stateName: "Kemo" },
				{ cities: ["Boda", "Mbaiki", "Mongoumba"], stateName: "Lobaye" },
				{
					cities: ["Berberati", "Carnot", "Gamboula"],
					stateName: "Mambere-Kadei",
				},
				{
					cities: ["Bangassou", "Gambo", "Ouango", "Rafai"],
					stateName: "Mbomou",
				},
				{ cities: ["Kaga-Bandoro"], stateName: "Nana-Gribizi" },
				{ cities: ["Baboua", "Baoro", "Bouar"], stateName: "Nana-Mambere" },
				{ cities: [], stateName: "Ombella Mpoko" },
				{
					cities: ["Bambari", "Grimari", "Ippy", "Kouango"],
					stateName: "Ouaka",
				},
				{
					cities: ["Batangafo", "Bossangoa", "Bouca", "Kabo"],
					stateName: "Ouham",
				},
				{ cities: ["Bocaranga", "Bozoum", "Paoua"], stateName: "Ouham-Pende" },
				{ cities: ["Nola"], stateName: "Sangha-Mbaere" },
				{ cities: ["Birao"], stateName: "Vakaga" },
			],
			countryName: "Central African Republic",
		},
		{
			states: [
				{ cities: ["Ati", "Oum Hadjer"], stateName: "Batha" },
				{ cities: ["Biltine"], stateName: "Biltine" },
				{
					cities: ["Aouzou", "Bardai", "Fada", "Faya"],
					stateName: "Bourkou-Ennedi-Tibesti",
				},
				{
					cities: [
						"Bokoro",
						"Bousso",
						"Dourbali",
						"Massaguet",
						"Massakory",
						"Massenya",
						"N'Djamena",
						"Ngama",
					],
					stateName: "Chari-Baguirmi",
				},
				{ cities: ["Bitkine", "Melfi", "Mongo"], stateName: "Guera" },
				{ cities: ["Mao", "Moussoro", "Rig-Rig"], stateName: "Kanem" },
				{ cities: ["Bol"], stateName: "Lac" },
				{ cities: [], stateName: "Logone Occidental" },
				{ cities: [], stateName: "Logone Oriental" },
				{
					cities: [
						"Bongor",
						"Fianga",
						"Gounou Gaya",
						"Guelengdeng",
						"Lere",
						"Pala",
					],
					stateName: "Mayo-Kebbi",
				},
				{
					cities: ["Goundi", "Koumra", "Kyabe", "Moissala", "Sarh"],
					stateName: "Moyen-Chari",
				},
				{ cities: ["Abeche", "Adre", "Am Dam"], stateName: "Ouaddai" },
				{
					cities: ["Abou Deia", "Am Timan", "Mangueigne"],
					stateName: "Salamat",
				},
				{ cities: ["Benoy", "Bere", "Kelo", "Lai"], stateName: "Tandjile" },
			],
			countryName: "Chad",
		},
		{
			states: [
				{
					cities: [
						"Aisen",
						"Chile Chico",
						"Cisnes",
						"Cochrane",
						"Coihaique",
						"Guaitecas",
						"Lago Verde",
						"O'Higgins",
						"Rio Ibanez",
						"Tortel",
					],
					stateName: "Aisen",
				},
				{
					cities: [
						"Antofagasta",
						"Calama",
						"Maria Elena",
						"Mejilones",
						"Ollague",
						"San Pedro de Atacama",
						"Sierra Gorda",
						"Taltal",
						"Tocopilla",
					],
					stateName: "Antofagasta",
				},
				{
					cities: [
						"Angol",
						"Carahue",
						"Collipulli",
						"Cunco",
						"Curacautin",
						"Curarrehue",
						"Ercilla",
						"Freire",
						"Galvarino",
						"Gorbea",
						"Lautaro",
						"Loncoche",
						"Lonquimay",
						"Los Sauces",
						"Lumaco",
						"Melipeuco",
						"Nueva Imperial",
						"Padre las Casas",
						"Perquenco",
						"Pitrufquen",
						"Pucon",
						"Puren",
						"Renaico",
						"Saavedra",
						"Temuco",
						"Teodoro Schmidt",
						"Tolten",
						"Traiguen",
						"Victoria",
						"Vilcun",
						"Villarica",
					],
					stateName: "Araucania",
				},
				{
					cities: [
						"Alto del Carmen",
						"Caldera",
						"Chanaral",
						"Copiapo",
						"Diego de Almagro",
						"Freirina",
						"Huasco",
						"Tierra Amarilla",
						"Vallenar",
					],
					stateName: "Atacama",
				},
				{ cities: [], stateName: "Bio Bio" },
				{
					cities: [
						"Andacollo",
						"Canela",
						"Combarbala",
						"Coquimbo",
						"Illapel",
						"La Higuera",
						"La Serena",
						"Los Vilos",
						"Monte Patria",
						"Ovalle",
						"Paiguano",
						"Punitaci",
						"Rio Hurtado",
						"Salamanca",
						"Vicuna",
					],
					stateName: "Coquimbo",
				},
				{ cities: [], stateName: "Libertador General Bernardo O'" },
				{ cities: [], stateName: "Los Lagos" },
				{
					cities: [
						"Cabo de Horno",
						"Laguna Blanca",
						"Natales",
						"Porvenir",
						"Primavera",
						"Punta Arenas",
						"Rio Verde",
						"San Gregorio",
						"Timaukel",
						"Torres del Paine",
					],
					stateName: "Magellanes",
				},
				{
					cities: [
						"Cauquenes",
						"Chanco",
						"Colbun",
						"Constitucion",
						"Curepto",
						"Curico",
						"Empedrado",
						"Hualane",
						"Licanten",
						"Linares",
						"Longavi",
						"Maule",
						"Molina",
						"Parral",
						"Pelarco",
						"Pelluhue",
						"Pencahue",
						"Rauco",
						"Retiro",
						"Rio Claro",
						"Romeral",
						"Sagrada Familia",
						"San Clemente",
						"San Javier",
						"San Rafael",
						"Talca",
						"Teno",
						"Vichuquen",
						"Villa Alegre",
						"Yerbas Buenas",
					],
					stateName: "Maule",
				},
				{
					cities: [
						"Alhue",
						"Buin",
						"Calera de Tango",
						"Colina",
						"Curacavi",
						"El Monte",
						"Isla de Maipo",
						"Lampa",
						"Maria Pinto",
						"Melipilla",
						"Padre Hurtado",
						"Paine",
						"Penaflor",
						"Pirque",
						"Puente Alto",
						"Quilicura",
						"San Bernardo",
						"San Jose de Maipo",
						"San Pedro",
						"Santiago",
						"Talagante",
						"Tiltil",
					],
					stateName: "Metropolitana",
				},
				{
					cities: [
						"Alhue",
						"Buin",
						"Calera de Tango",
						"Colina",
						"Curacavi",
						"El Monte",
						"Isla de Maipo",
						"Lampa",
						"Maria Pinto",
						"Melipilla",
						"Padre Hurtado",
						"Paine",
						"Penaflor",
						"Pirque",
						"Puente Alto",
						"Quilicura",
						"San Bernardo",
						"San Jose de Maipo",
						"San Pedro",
						"Santiago",
						"Talagante",
						"Tiltil",
					],
					stateName: "Metropolitana de Santiago",
				},
				{
					cities: [
						"Arica",
						"Camarones",
						"Camina",
						"Colchane",
						"General Lagos",
						"Huara",
						"Iquique",
						"Pica",
						"Pozo Almonte",
						"Putre",
					],
					stateName: "Tarapaca",
				},
				{
					cities: [
						"Algarrobo",
						"Cabildo",
						"Calera",
						"Calle Larga",
						"Cartagena",
						"Casablanca",
						"Catemu",
						"Concon",
						"El Quisco",
						"El Tabo",
						"Hijuelas",
						"La Cruz",
						"La Ligua",
						"Limache",
						"Llaillay",
						"Los Andes",
						"Nogales",
						"Olmue",
						"Panquehue",
						"Papudo",
						"Petorca",
						"Puchuncavi",
						"Putaendeo",
						"Quillota",
						"Quilpue",
						"Quintero",
						"Rinconada",
						"San Antonio",
						"San Esteban",
						"San Felipe",
						"Santa Maria",
						"Santo Domingo",
						"Valparaiso",
						"Villa Alemana",
						"Vina del Mar",
						"Zapallar",
					],
					stateName: "Valparaiso",
				},
			],
			countryName: "Chile",
		},
		{
			states: [
				{
					cities: [
						"Fengyang",
						"Guangde",
						"Liuan",
						"Ningguo",
						"Shucheng",
						"Xinchang",
						"Xuancheng",
					],
					stateName: "Anhui",
				},
				{
					cities: [
						"Fengyang",
						"Guangde",
						"Liuan",
						"Ningguo",
						"Shucheng",
						"Xinchang",
						"Xuancheng",
					],
					stateName: "Anhui Province",
				},
				{
					cities: [
						"Fengyang",
						"Guangde",
						"Liuan",
						"Ningguo",
						"Shucheng",
						"Xinchang",
						"Xuancheng",
					],
					stateName: "Anhui Sheng",
				},
				{ cities: ["Aomen"], stateName: "Aomen" },
				{
					cities: [
						"Beijing",
						"Changping",
						"Fangshan",
						"Huangcun",
						"Liangxiang",
						"Mentougou",
						"Shunyi",
						"Tongzhou",
					],
					stateName: "Beijing",
				},
				{
					cities: [
						"Beijing",
						"Changping",
						"Fangshan",
						"Huangcun",
						"Liangxiang",
						"Mentougou",
						"Shunyi",
						"Tongzhou",
					],
					stateName: "Beijing Shi",
				},
				{
					cities: [
						"Beibei",
						"Chongqing",
						"Fuling",
						"Longhua",
						"Nantongkuang",
						"Wanxian",
						"Xiuma",
						"Yubei",
						"Yudong",
					],
					stateName: "Chongqing",
				},
				{
					cities: [
						"Bantou",
						"Dongshan",
						"Fuan",
						"Fujian",
						"Fuqing",
						"Fuzhou",
						"Gantou",
						"Hanyang",
						"Jiangkou",
						"Jiaocheng",
						"Jinjiang",
						"Jinshang",
						"Longhai",
						"Longyan",
						"Luoyang",
						"Nanan",
						"Nanping",
						"Nanpu",
						"Putian",
						"Qingyang",
						"Quanzhou",
						"Rongcheng",
						"Sanming",
						"Shaowu",
						"Shima",
						"Shishi",
						"Tantou",
						"Tongshan",
						"Xiamen",
						"Xiapu",
						"Xiapu Ningde",
						"Ximei",
						"Yongan",
						"Zhangzhou",
						"Zhicheng",
					],
					stateName: "Fujian",
				},
				{
					cities: [
						"Bantou",
						"Dongshan",
						"Fuan",
						"Fujian",
						"Fuqing",
						"Fuzhou",
						"Gantou",
						"Hanyang",
						"Jiangkou",
						"Jiaocheng",
						"Jinjiang",
						"Jinshang",
						"Longhai",
						"Longyan",
						"Luoyang",
						"Nanan",
						"Nanping",
						"Nanpu",
						"Putian",
						"Qingyang",
						"Quanzhou",
						"Rongcheng",
						"Sanming",
						"Shaowu",
						"Shima",
						"Shishi",
						"Tantou",
						"Tongshan",
						"Xiamen",
						"Xiapu",
						"Xiapu Ningde",
						"Ximei",
						"Yongan",
						"Zhangzhou",
						"Zhicheng",
					],
					stateName: "Fujian Sheng",
				},
				{
					cities: [
						"Baiyin",
						"Baoji",
						"Beidao",
						"Jiayuguan",
						"Jinchang",
						"Jiuquan",
						"Lanzhou",
						"Linxia",
						"Pingliang",
						"Qincheng",
						"Wuwei",
						"Yaojie",
						"Yumen",
						"Zhangye",
						"Zhuanglang",
					],
					stateName: "Gansu",
				},
				{
					cities: [
						"Anbu",
						"Chaozhou",
						"Chenghai",
						"Chuncheng",
						"Daliang",
						"Danshui",
						"Dongguan",
						"Donghai",
						"Dongli",
						"Dongzhen",
						"Ducheng",
						"Encheng",
						"Foahn",
						"Foshan",
						"Gaozhou",
						"Guangdong",
						"Guangzhou",
						"Guanjiao",
						"Haicheng",
						"Haimen",
						"Hepo",
						"Houpu",
						"Huaicheng",
						"Huanggang",
						"Huangpu",
						"Huazhou",
						"Huicheng",
						"Huizhou",
						"Humen",
						"Jiangmen",
						"Jiazi",
						"Jieshi",
						"Jieyang",
						"Lecheng",
						"Leicheng",
						"Liancheng",
						"Lianzhou",
						"Licheng",
						"Liusha",
						"Longgang",
						"Lubu",
						"Luocheng",
						"Luohu",
						"Luoyang",
						"Maba",
						"Maoming",
						"Mata",
						"Meilu",
						"Meizhou",
						"Mianchang",
						"Nanfeng",
						"Nanhai",
						"Pingshan",
						"Qingtang",
						"Qingyuan",
						"Rongcheng",
						"Sanbu",
						"Shantou",
						"Shanwei",
						"Shaoguan",
						"Shaping",
						"Shenzhen",
						"Shilong",
						"Shiqiao",
						"Shiwan",
						"Shuizhai",
						"Shunde",
						"Suicheng",
						"Taicheng",
						"Tangping",
						"Xiaolan",
						"Xinan",
						"Xingcheng",
						"Xiongzhou",
						"Xucheng",
						"Yangjiang",
						"Yingcheng",
						"Yuancheng",
						"Yuncheng",
						"Yunfu",
						"Zengcheng",
						"Zhanjiang",
						"Zhaoqing",
						"Zhilong",
						"Zhongshan",
						"Zhuhai",
					],
					stateName: "Guangdong",
				},
				{
					cities: [
						"Anbu",
						"Chaozhou",
						"Chenghai",
						"Chuncheng",
						"Daliang",
						"Danshui",
						"Dongguan",
						"Donghai",
						"Dongli",
						"Dongzhen",
						"Ducheng",
						"Encheng",
						"Foahn",
						"Foshan",
						"Gaozhou",
						"Guangdong",
						"Guangzhou",
						"Guanjiao",
						"Haicheng",
						"Haimen",
						"Hepo",
						"Houpu",
						"Huaicheng",
						"Huanggang",
						"Huangpu",
						"Huazhou",
						"Huicheng",
						"Huizhou",
						"Humen",
						"Jiangmen",
						"Jiazi",
						"Jieshi",
						"Jieyang",
						"Lecheng",
						"Leicheng",
						"Liancheng",
						"Lianzhou",
						"Licheng",
						"Liusha",
						"Longgang",
						"Lubu",
						"Luocheng",
						"Luohu",
						"Luoyang",
						"Maba",
						"Maoming",
						"Mata",
						"Meilu",
						"Meizhou",
						"Mianchang",
						"Nanfeng",
						"Nanhai",
						"Pingshan",
						"Qingtang",
						"Qingyuan",
						"Rongcheng",
						"Sanbu",
						"Shantou",
						"Shanwei",
						"Shaoguan",
						"Shaping",
						"Shenzhen",
						"Shilong",
						"Shiqiao",
						"Shiwan",
						"Shuizhai",
						"Shunde",
						"Suicheng",
						"Taicheng",
						"Tangping",
						"Xiaolan",
						"Xinan",
						"Xingcheng",
						"Xiongzhou",
						"Xucheng",
						"Yangjiang",
						"Yingcheng",
						"Yuancheng",
						"Yuncheng",
						"Yunfu",
						"Zengcheng",
						"Zhanjiang",
						"Zhaoqing",
						"Zhilong",
						"Zhongshan",
						"Zhuhai",
					],
					stateName: "Guangdong Sheng",
				},
				{
					cities: [
						"Babu",
						"Baihe",
						"Baise",
						"Beihai",
						"Binzhou",
						"Bose",
						"Fangchenggang",
						"Guicheng",
						"Guilin",
						"Guiping",
						"Jinchengjiang",
						"Jinji",
						"Laibin",
						"Lianzhou",
						"Liuzhou",
						"Luorong",
						"Matong",
						"Nandu",
						"Nanning",
						"Pingnan",
						"Pumiao",
						"Qinzhou",
						"Songhua",
						"Wuzhou",
						"Yashan",
						"Yulin",
					],
					stateName: "Guangxi",
				},
				{
					cities: [
						"Anshun",
						"Bijie",
						"Caohai",
						"Duyun",
						"Guiyang",
						"Kaili",
						"Liupanshui",
						"Luoyang",
						"Pingzhai",
						"Tongren",
						"Tongzi",
						"Xiaoweizhai",
						"Xingyi",
						"Zunyi",
					],
					stateName: "Guizhou",
				},
				{
					cities: [
						"Chengmai",
						"Dingan",
						"Haikou",
						"Lingao",
						"Qiongshan",
						"Sansha ",
						"Sanya",
						"Wanning",
					],
					stateName: "Hainan",
				},
				{
					cities: [
						"Anping",
						"Baoding",
						"Botou",
						"Cangzhou",
						"Changli",
						"Chengde",
						"Dingzhou",
						"Fengfeng",
						"Fengrun",
						"Guye",
						"Handan",
						"Hebei",
						"Hecun",
						"Hejian",
						"Hengshui",
						"Huanghua",
						"Jingxingkuang",
						"Jinzhou",
						"Langfang",
						"Lianzhou",
						"Linshui",
						"Linxi",
						"Longyao County",
						"Nangong",
						"Pengcheng",
						"Qinhuangdao",
						"Renqiu",
						"Shahe",
						"Shijiazhuang",
						"Tangjiazhuang",
						"Tangshan",
						"Wuan",
						"Xian County",
						"Xingtai",
						"Xinji",
						"Xinle",
						"Xuanhua",
						"Zhangjiakou",
						"Zhaogezhuang",
						"Zhuozhou",
					],
					stateName: "Hebei",
				},
				{
					cities: [
						"Acheng",
						"Anda",
						"Angangxi",
						"Baiquan",
						"Bamiantong",
						"Baoqing",
						"Baoshan",
						"Bayan",
						"Beian",
						"Binzhou",
						"Boli",
						"Chaihe",
						"Chengzihe",
						"Cuiluan",
						"Daqing",
						"Didao",
						"Dongning",
						"Fujin",
						"Fuli",
						"Fulitun",
						"Fuyu",
						"Gannan",
						"Hailin",
						"Hailun",
						"Harbin",
						"Hegang",
						"Heihe",
						"Hengshan",
						"Honggang",
						"Huanan",
						"Hulan",
						"Hulan Ergi",
						"Jiamusi",
						"Jidong",
						"Jixi",
						"Keshan",
						"Langxiang",
						"Lanxi",
						"Lingdong",
						"Linkou",
						"Lishu",
						"Longfeng",
						"Longjiang",
						"Mingshui",
						"Mishan",
						"Mudanjiang",
						"Nancha",
						"Nehe",
						"Nenjiang",
						"Nianzishan",
						"Ningan",
						"Qingan",
						"Qinggang",
						"Qiqihar",
						"Qitaihe",
						"Ranghulu",
						"Saertu",
						"Shangzhi",
						"Shanhetun",
						"Shuangcheng",
						"Shuangyashan",
						"Sifantan",
						"Suifenhe",
						"Suihua",
						"Suileng",
						"Tahe",
						"Taikang",
						"Tailai",
						"Tieli",
						"Wangkui",
						"Weihe",
						"Wuchang",
						"Xinglongzhen",
						"Xinqing",
						"Yian",
						"Yichun",
						"Yilan",
						"Youhao",
						"Zhaodong",
						"Zhaoyuan",
						"Zhaozhou",
					],
					stateName: "Heilongjiang",
				},
				{
					cities: [
						"Anyang",
						"Changying",
						"Dancheng",
						"Daokou",
						"Dengzhou",
						"Gongyi",
						"Gushi",
						"Hebi",
						"Huaidian",
						"Huangchuan",
						"Huangzhai",
						"Jiaozuo",
						"Jishui",
						"Kaifeng",
						"Liupen",
						"Luohe",
						"Luoyang",
						"Luyang",
						"Mengzhou",
						"Minggang",
						"Nandun",
						"Nanyang",
						"Pingdingshan",
						"Puyang",
						"Sanmenxia",
						"Shangqiu",
						"Tanghe",
						"Xiaoyi",
						"Xihua",
						"Xinxiang",
						"Xinyang",
						"Xinye",
						"Xixiang",
						"Xuanwu",
						"Xuchang",
						"Yigou",
						"Yima",
						"Yinzhuang",
						"Yunyang",
						"Yuzhou",
						"Zhecheng",
						"Zhengzhou",
						"Zhenping",
						"Zhoukou",
						"Zhumadian",
					],
					stateName: "Henan",
				},
				{
					cities: [
						"Anlu",
						"Baisha",
						"Buhe",
						"Caidian",
						"Caohe",
						"Danjiangkou",
						"Daye",
						"Duobao",
						"Enshi",
						"Ezhou",
						"Fengkou",
						"Guangshui",
						"Gucheng",
						"Hanchuan",
						"Hongan",
						"Honghu",
						"Huangmei",
						"Huangpi",
						"Huangshi",
						"Huangzhou",
						"Jingmen",
						"Jingzhou",
						"Laohekou",
						"Lichuan",
						"Macheng",
						"Nanhai",
						"Nanzhang",
						"Puqi",
						"Qianjiang",
						"Qingquan",
						"Qixingtai",
						"Rongcheng",
						"Shashi",
						"Shishou",
						"Shiyan",
						"Suizhou",
						"Tianmen",
						"Tongcheng",
						"Wuhan",
						"Wuxue",
						"Xiangfan",
						"Xianning",
						"Xiantao",
						"Xiaogan",
						"Xiaoxita",
						"Xiaxindian",
						"Xihe",
						"Xinpu",
						"Xinshi",
						"Xinzhou",
						"Yichang",
						"Yicheng",
						"Yingcheng",
						"Yingzhong",
						"Zaoyang",
						"Zhengchang",
						"Zhicheng",
						"Zhifang",
						"Zhongxiang",
					],
					stateName: "Hubei",
				},
				{
					cities: [
						"Anjiang",
						"Anxiang",
						"Changde",
						"Changsha",
						"Chenzhou",
						"Dayong",
						"Hengyang",
						"Hongjiang",
						"Huaihua",
						"Jinshi",
						"Jishou",
						"Leiyang",
						"Lengshuijiang",
						"Lengshuitan",
						"Lianyuan",
						"Liling",
						"Liuyang",
						"Loudi",
						"Matian",
						"Nanzhou",
						"Ningxiang",
						"Qidong",
						"Qiyang",
						"Shaoyang",
						"Xiangtan",
						"Xiangxiang",
						"Xiangyin",
						"Xinhua",
						"Yiyang",
						"Yongfeng",
						"Yongzhou",
						"Yuanjiang",
						"Yueyang",
						"Zhuzhou",
					],
					stateName: "Hunan",
				},
				{
					cities: [
						"Baoying",
						"Changzhou",
						"Dachang",
						"Dafeng",
						"Danyang",
						"Dingshu",
						"Dongkan",
						"Dongtai",
						"Fengxian",
						"Gaogou",
						"Gaoyou",
						"Guiren",
						"Haian",
						"Haizhou",
						"Hede",
						"Huaicheng",
						"Huaiyin",
						"Huilong",
						"Hutang",
						"Jiangdu",
						"Jiangyan",
						"Jiangyin",
						"Jiangyuan",
						"Jianhu",
						"Jingcheng",
						"Jinsha",
						"Jintan",
						"Juegang",
						"Jurong",
						"Kunshan",
						"Lianyungang",
						"Liucheng",
						"Liyang",
						"Luodu",
						"Mudu",
						"Nanjing",
						"Nantong",
						"Pecheng",
						"Pukou",
						"Qidong",
						"Qinnan",
						"Qixia",
						"Rucheng",
						"Songling",
						"Sucheng",
						"Suicheng",
						"Suqian",
						"Suzhou",
						"Taicang",
						"Taixing",
						"Wujiang",
						"Wuxi",
						"Xiaolingwei",
						"Xiaoshi",
						"Xinan",
						"Xinpu",
						"Xuzhou",
						"Yancheng",
						"Yangshe",
						"Yangzhou",
						"Yizheng",
						"Yunhe",
						"Yunyang",
						"Yushan",
						"Zhangjiagang",
						"Zhangjiangang",
						"Zhaoyang",
						"Zhenjiang",
						"Zhongxing",
					],
					stateName: "Jiangsu",
				},
				{
					cities: [
						"Baoying",
						"Changzhou",
						"Dachang",
						"Dafeng",
						"Danyang",
						"Dingshu",
						"Dongkan",
						"Dongtai",
						"Fengxian",
						"Gaogou",
						"Gaoyou",
						"Guiren",
						"Haian",
						"Haizhou",
						"Hede",
						"Huaicheng",
						"Huaiyin",
						"Huilong",
						"Hutang",
						"Jiangdu",
						"Jiangyan",
						"Jiangyin",
						"Jiangyuan",
						"Jianhu",
						"Jingcheng",
						"Jinsha",
						"Jintan",
						"Juegang",
						"Jurong",
						"Kunshan",
						"Lianyungang",
						"Liucheng",
						"Liyang",
						"Luodu",
						"Mudu",
						"Nanjing",
						"Nantong",
						"Pecheng",
						"Pukou",
						"Qidong",
						"Qinnan",
						"Qixia",
						"Rucheng",
						"Songling",
						"Sucheng",
						"Suicheng",
						"Suqian",
						"Suzhou",
						"Taicang",
						"Taixing",
						"Wujiang",
						"Wuxi",
						"Xiaolingwei",
						"Xiaoshi",
						"Xinan",
						"Xinpu",
						"Xuzhou",
						"Yancheng",
						"Yangshe",
						"Yangzhou",
						"Yizheng",
						"Yunhe",
						"Yunyang",
						"Yushan",
						"Zhangjiagang",
						"Zhangjiangang",
						"Zhaoyang",
						"Zhenjiang",
						"Zhongxing",
					],
					stateName: "Jiangsu Sheng",
				},
				{
					cities: [
						"Fengxin",
						"Fenyi",
						"Ganzhou",
						"Jian",
						"Jiangguang",
						"Jingdezhen",
						"Jiujiang",
						"Leping",
						"Linchuan",
						"Nanchang",
						"Pingxiang",
						"Poyang",
						"Shangrao",
						"Xiangdong",
						"Xingan",
						"Xinjian",
						"Xinyu",
						"Xiongshi",
						"Yichun",
						"Yingtai",
						"Yingtan",
						"Zhangshui",
					],
					stateName: "Jiangxi",
				},
				{
					cities: [
						"Badaojiang",
						"Baicheng",
						"Baishishan",
						"Changchun",
						"Changling",
						"Chaoyang",
						"Daan",
						"Dashitou",
						"Dehui",
						"Dongchang",
						"Dongfeng",
						"Dunhua",
						"Erdaojiang",
						"Fuyu",
						"Gongzhuling",
						"Helong",
						"Hongmei",
						"Huadian",
						"Huangnihe",
						"Huinan",
						"Hunchun",
						"Jiaohe",
						"Jilin",
						"Jishu",
						"Jiutai",
						"Kaitong",
						"Kouqian",
						"Liaoyuan",
						"Linjiang",
						"Lishu",
						"Liuhe",
						"Longjing",
						"Meihekou",
						"Mingyue",
						"Nongan",
						"Panshi",
						"Pizhou",
						"Qianan",
						"Qianguo",
						"Sanchazi",
						"Shuangyang",
						"Shulan",
						"Siping",
						"Songjianghe",
						"Taonan",
						"Tumen",
						"Wangou",
						"Wangqing",
						"Xinglongshan",
						"Yanji",
						"Yantongshan",
						"Yushu",
						"Zhengjiatun",
						"Zhenlai",
					],
					stateName: "Jilin",
				},
				{
					cities: [
						"Anshan",
						"Beipiao",
						"Benxi",
						"Changtu",
						"Chaoyang",
						"Dalian",
						"Dalianwan",
						"Dalinghe",
						"Dandong",
						"Dashiqiao",
						"Dongling",
						"Fengcheng",
						"Fushun",
						"Fuxin",
						"Haicheng",
						"Heishan",
						"Huanren",
						"Huludao",
						"Hushitai",
						"Jinxi",
						"Jinzhou",
						"Jiupu",
						"Kaiyuan",
						"Kuandian",
						"Langtou",
						"Liaoyang",
						"Liaozhong",
						"Lingyuan",
						"Liuerbao",
						"Lushunkou",
						"Nantai",
						"Panjin",
						"Pulandian",
						"Shenyang",
						"Sujiatun",
						"Tieling",
						"Wafangdian",
						"Xiaoshi",
						"Xifeng",
						"Xinchengxi",
						"Xingcheng",
						"Xinmin",
						"Xiongyue",
						"Xiuyan",
						"Yebaishou",
						"Yingkou",
						"Yuhong",
						"Zhuanghe",
					],
					stateName: "Liaoning",
				},
				{
					cities: [
						"Anshan",
						"Beipiao",
						"Benxi",
						"Changtu",
						"Chaoyang",
						"Dalian",
						"Dalianwan",
						"Dalinghe",
						"Dandong",
						"Dashiqiao",
						"Dongling",
						"Fengcheng",
						"Fushun",
						"Fuxin",
						"Haicheng",
						"Heishan",
						"Huanren",
						"Huludao",
						"Hushitai",
						"Jinxi",
						"Jinzhou",
						"Jiupu",
						"Kaiyuan",
						"Kuandian",
						"Langtou",
						"Liaoyang",
						"Liaozhong",
						"Lingyuan",
						"Liuerbao",
						"Lushunkou",
						"Nantai",
						"Panjin",
						"Pulandian",
						"Shenyang",
						"Sujiatun",
						"Tieling",
						"Wafangdian",
						"Xiaoshi",
						"Xifeng",
						"Xinchengxi",
						"Xingcheng",
						"Xinmin",
						"Xiongyue",
						"Xiuyan",
						"Yebaishou",
						"Yingkou",
						"Yuhong",
						"Zhuanghe",
					],
					stateName: "Liaoning Sheng",
				},
				{ cities: [], stateName: "Nei Monggol" },
				{ cities: [], stateName: "Ningxia Hui" },
				{ cities: ["Qiatou", "Xining"], stateName: "Qinghai" },
				{
					cities: [
						"Ankang",
						"Baoji",
						"Guozhen",
						"Hancheng",
						"Hanzhong",
						"Lishan",
						"Qili",
						"Tongchuan",
						"Weinan",
						"Xian",
						"Xianyang",
						"Yanan",
						"Yanliang",
						"Yulin",
						"Yuxia",
					],
					stateName: "Shaanxi",
				},
				{
					cities: [
						"Anqiu",
						"Bianzhuang",
						"Binzhou",
						"Boshan",
						"Boxing County",
						"Caocheng",
						"Changqing",
						"Chengyang",
						"Dengzhou",
						"Dezhou",
						"Dingtao",
						"Dongcun",
						"Dongdu",
						"Donge County",
						"Dongying",
						"Feicheng",
						"Fushan",
						"Gaomi",
						"Haiyang",
						"Hanting",
						"Hekou",
						"Heze",
						"Jiaonan",
						"Jiaozhou",
						"Jiehu",
						"Jimo",
						"Jinan",
						"Jining",
						"Juxian",
						"Juye",
						"Kunlun",
						"Laiwu",
						"Laiyang",
						"Laizhou",
						"Leling",
						"Liaocheng",
						"Licung",
						"Linqing",
						"Linqu",
						"Linshu",
						"Linyi",
						"Longkou",
						"Mengyin",
						"Mingshui",
						"Nanchou",
						"Nanding",
						"Nanma",
						"Ninghai",
						"Ningyang",
						"Pingdu",
						"Pingyi",
						"Pingyin",
						"Qingdao",
						"Qingzhou",
						"Qixia",
						"Qufu",
						"Rizhao",
						"Rongcheng",
						"Shancheng",
						"Shanting",
						"Shengzhuang",
						"Shenxian",
						"Shizilu",
						"Shouguang",
						"Shuiji",
						"Sishui",
						"Suozhen",
						"Taian",
						"Tancheng",
						"Taozhuang",
						"Tengzhou",
						"Weifang",
						"Weihai",
						"Wencheng",
						"Wendeng",
						"Wenshang",
						"Wudi",
						"Xiazhen",
						"Xincheng",
						"Xindian",
						"Xintai",
						"Yanggu",
						"Yangshan",
						"Yantai",
						"Yanzhou",
						"Yatou",
						"Yidu",
						"Yishui",
						"Yucheng",
						"Yuncheng",
						"Zaozhuang",
						"Zhangdian",
						"Zhangjiawa",
						"Zhangqiu",
						"Zhaocheng",
						"Zhoucheng",
						"Zhoucun",
						"Zhucheng",
						"Zhuwang",
						"Zicheng",
						"Zouping",
						"Zouxian",
					],
					stateName: "Shandong",
				},
				{
					cities: [
						"Anqiu",
						"Bianzhuang",
						"Binzhou",
						"Boshan",
						"Boxing County",
						"Caocheng",
						"Changqing",
						"Chengyang",
						"Dengzhou",
						"Dezhou",
						"Dingtao",
						"Dongcun",
						"Dongdu",
						"Donge County",
						"Dongying",
						"Feicheng",
						"Fushan",
						"Gaomi",
						"Haiyang",
						"Hanting",
						"Hekou",
						"Heze",
						"Jiaonan",
						"Jiaozhou",
						"Jiehu",
						"Jimo",
						"Jinan",
						"Jining",
						"Juxian",
						"Juye",
						"Kunlun",
						"Laiwu",
						"Laiyang",
						"Laizhou",
						"Leling",
						"Liaocheng",
						"Licung",
						"Linqing",
						"Linqu",
						"Linshu",
						"Linyi",
						"Longkou",
						"Mengyin",
						"Mingshui",
						"Nanchou",
						"Nanding",
						"Nanma",
						"Ninghai",
						"Ningyang",
						"Pingdu",
						"Pingyi",
						"Pingyin",
						"Qingdao",
						"Qingzhou",
						"Qixia",
						"Qufu",
						"Rizhao",
						"Rongcheng",
						"Shancheng",
						"Shanting",
						"Shengzhuang",
						"Shenxian",
						"Shizilu",
						"Shouguang",
						"Shuiji",
						"Sishui",
						"Suozhen",
						"Taian",
						"Tancheng",
						"Taozhuang",
						"Tengzhou",
						"Weifang",
						"Weihai",
						"Wencheng",
						"Wendeng",
						"Wenshang",
						"Wudi",
						"Xiazhen",
						"Xincheng",
						"Xindian",
						"Xintai",
						"Yanggu",
						"Yangshan",
						"Yantai",
						"Yanzhou",
						"Yatou",
						"Yidu",
						"Yishui",
						"Yucheng",
						"Yuncheng",
						"Zaozhuang",
						"Zhangdian",
						"Zhangjiawa",
						"Zhangqiu",
						"Zhaocheng",
						"Zhoucheng",
						"Zhoucun",
						"Zhucheng",
						"Zhuwang",
						"Zicheng",
						"Zouping",
						"Zouxian",
					],
					stateName: "Shandong Sheng",
				},
				{
					cities: ["Jiading", "Minhang", "Shanghai", "Songjiang", "Trencin"],
					stateName: "Shanghai",
				},
				{
					cities: [
						"Changzhi",
						"Datong",
						"Houma",
						"Jiexiu",
						"Jincheng",
						"Linfen",
						"Taiyuan",
						"Xian",
						"Xinzhi",
						"Xinzhou",
						"Yangquan",
						"Yuanping",
						"Yuci",
						"Yuncheng",
					],
					stateName: "Shanxi",
				},
				{
					cities: [
						"Anju",
						"Baoning",
						"Chengdu",
						"Daan",
						"Dawan",
						"Daxian",
						"Deyang",
						"Dujiangyan City",
						"Guangkou",
						"Guangyuan",
						"Guihu",
						"Heyang",
						"Huayang",
						"Jiancheng",
						"Jiangyou",
						"Jijiang",
						"Leshan",
						"Linqiong",
						"Luocheng",
						"Luzhou",
						"Mianyang",
						"Nanchong",
						"Nanlong",
						"Neijiang",
						"Panzhihua",
						"Shifang",
						"Suining",
						"Taihe",
						"Tianpeng",
						"Tongchuan",
						"Xichang",
						"Xunchang",
						"Yaan",
						"Yibin",
						"Yongchang",
						"Zhonglong",
						"Zigong",
						"Ziyang",
					],
					stateName: "Sichuan",
				},
				{
					cities: [
						"Beichen",
						"Gangdong",
						"Hangu",
						"Jinghai",
						"Nankai",
						"Tanggu",
						"Tianjin",
						"Xianshuigu",
						"Yangcun",
						"Yangliuqing",
					],
					stateName: "Tianjin",
				},
				{
					cities: [
						"Guiqing",
						"Jiulong",
						"Quanwan",
						"Saigong",
						"Shatin",
						"Taipo",
						"Tuanmun",
						"Xianggang",
						"Yuanlong",
					],
					stateName: "Xianggang",
				},
				{
					cities: [
						"Aksu",
						"Baijiantan",
						"Changji",
						"Dongshan",
						"Hami",
						"Hetian",
						"Karamay",
						"Kashi",
						"Korla",
						"Kuche",
						"Kuytun",
						"Shache",
						"Shihezi",
						"Shuimogou",
						"Toutunhe",
						"Urumqi",
						"Yining",
					],
					stateName: "Xinjiang",
				},
				{ cities: ["Lasa"], stateName: "Xizang" },
				{
					cities: [
						"Dali",
						"Gejiu",
						"Haikou",
						"Heilin",
						"Jinma",
						"Kaihua",
						"Kaiyuan",
						"Kunming",
						"Lianran",
						"Longquan",
						"Lucheng",
						"Mabai",
						"Majie",
						"Miyang",
						"Qujing",
						"Rongcheng",
						"Simao",
						"Wufeng",
						"Yunjinghong",
						"Yuxi Municipal",
						"Zhaotong",
						"Zhenhai",
						"Zhongshu",
						"Zhoucheng",
						"Zhuocheng",
					],
					stateName: "Yunnan",
				},
				{
					cities: [
						"Aojiang",
						"Choucheng",
						"Cixi",
						"Daqiao",
						"Deqing",
						"Dinghai",
						"Dongyang",
						"Fuyang",
						"Haining",
						"Haiyan",
						"Hangzhou",
						"Huangyan",
						"Hushan",
						"Huzhou",
						"Jiaojiang",
						"Jiaxing",
						"Jinhua",
						"Jinxiang",
						"Kaihua",
						"Kunyang",
						"Lanxi",
						"Linan City",
						"Linhai",
						"Linping",
						"Lishui",
						"Liushi",
						"Ningbo",
						"Ninghai",
						"Pinghu",
						"Quzhou",
						"Ruian",
						"Shangyu",
						"Shaoxing",
						"Shenjiamen",
						"Taizhou City",
						"Tonglu",
						"Wenling",
						"Wenzhou",
						"Wuning",
						"Wuyi",
						"Xianju",
						"Xiaoshan",
						"Xiashi",
						"Xushan",
						"Yiwu",
						"Yongkang",
						"Yueqing",
						"Yuhuan",
						"Yuyao",
						"Zhejiang",
						"Zhenhai",
						"Zhicheng",
						"Zhuji",
						"fenghua",
						"jiashan",
					],
					stateName: "Zhejiang",
				},
				{
					cities: [
						"Aojiang",
						"Choucheng",
						"Cixi",
						"Daqiao",
						"Deqing",
						"Dinghai",
						"Dongyang",
						"Fuyang",
						"Haining",
						"Haiyan",
						"Hangzhou",
						"Huangyan",
						"Hushan",
						"Huzhou",
						"Jiaojiang",
						"Jiaxing",
						"Jinhua",
						"Jinxiang",
						"Kaihua",
						"Kunyang",
						"Lanxi",
						"Linan City",
						"Linhai",
						"Linping",
						"Lishui",
						"Liushi",
						"Ningbo",
						"Ninghai",
						"Pinghu",
						"Quzhou",
						"Ruian",
						"Shangyu",
						"Shaoxing",
						"Shenjiamen",
						"Taizhou City",
						"Tonglu",
						"Wenling",
						"Wenzhou",
						"Wuning",
						"Wuyi",
						"Xianju",
						"Xiaoshan",
						"Xiashi",
						"Xushan",
						"Yiwu",
						"Yongkang",
						"Yueqing",
						"Yuhuan",
						"Yuyao",
						"Zhejiang",
						"Zhenhai",
						"Zhicheng",
						"Zhuji",
						"fenghua",
						"jiashan",
					],
					stateName: "Zhejiang Sheng",
				},
			],
			countryName: "China",
		},
		{
			states: [{ cities: [], stateName: "Christmas Island" }],
			countryName: "Christmas Island",
		},
		{
			states: [{ cities: [], stateName: "Cocos (Keeling) Islands" }],
			countryName: "Cocos (Keeling) Islands",
		},
		{
			states: [
				{ cities: ["Leticia", "Puerto Narino"], stateName: "Amazonas" },
				{
					cities: [
						"Abejorral",
						"Abriaqui",
						"Alejandria",
						"Amaga",
						"Amalfi",
						"Andes",
						"Angelopolis",
						"Angostura",
						"Anori",
						"Antioquia",
						"Anza",
						"Apartado",
						"Arboletes",
						"Argelia",
						"Armenia",
						"Barbosa",
						"Bello",
						"Belmira",
						"Betania",
						"Betulia",
						"Bolivar",
						"Briceno",
						"Buritica",
						"Caceres",
						"Caicedo",
						"Caldas",
						"Campamento",
						"Canasgordas",
						"Caracoli",
						"Caramanta",
						"Carepa",
						"Carmen de Viboral",
						"Carolina",
						"Caucasia",
						"Chigorodo",
						"Cisneros",
						"Cocorna",
						"Concepcion",
						"Concordia",
						"Copacabana",
						"Dabeiba",
						"Don Matias",
						"Ebejico",
						"El Bagre",
						"Entrerrios",
						"Envigado",
						"Fredonia",
						"Frontino",
						"Giraldo",
						"Girardota",
						"Gomez Plata",
						"Granada",
						"Guadalupe",
						"Guarne",
						"Guatape",
						"Heliconia",
						"Hispania",
						"Itagui",
						"Ituango",
						"Jardin",
						"Jerico",
						"La Ceja",
						"La Estrella",
						"La Pintada",
						"La Union",
						"Liborina",
						"Maceo",
						"Marinilla",
						"Medellin",
						"Montebello",
						"Murindo",
						"Mutata",
						"Narino",
						"Nechi",
						"Necocli",
						"Olaya",
						"Penol",
						"Peque",
						"Pueblorrico",
						"Puerto Berrio",
						"Puerto Nare",
						"Puerto Triunfo",
						"Remedios",
						"Retiro",
						"Rionegro",
						"Sabanalarga",
						"Sabaneta",
						"Salgar",
						"San Andres",
						"San Carlos",
						"San Francisco",
						"San Jeronimo",
						"San Jose de la Montana",
						"San Juan de Uraba",
						"San Luis",
						"San Pedro",
						"San Pedro de Uraba",
						"San Rafael",
						"San Roque",
						"San Vicente",
						"Santa Barbara",
						"Santa Rosa de Osos",
						"Santo Domingo",
						"Santuario",
						"Segovia",
						"Sonson",
						"Sopetran",
						"Tamesis",
						"Taraza",
						"Tarso",
						"Titiribi",
						"Toledo",
						"Turbo",
						"Uramita",
						"Urrao",
						"Valdivia",
						"Valparaiso",
						"Vegachi",
						"Venecia",
						"Vigia del Fuerte",
						"Yali",
						"Yarumal",
						"Yolombo",
						"Yondo",
						"Zaragoza",
					],
					stateName: "Antioquia",
				},
				{
					cities: [
						"Arauca",
						"Arauquita",
						"Cravo Norte",
						"Fortul",
						"Puerto Rondon",
						"Saravena",
						"Tame",
					],
					stateName: "Arauca",
				},
				{
					cities: [
						"Baranoa",
						"Barranquilla",
						"Campo de la Cruz",
						"Candelaria",
						"Galapa",
						"Juan de Acosta",
						"Luruaco",
						"Malambo",
						"Manati",
						"Palmar de Varela",
						"Piojo",
						"Polo Nuevo",
						"Ponedera",
						"Puerto Colombia",
						"Repelon",
						"Sabanagrande",
						"Sabanalarga",
						"Santa Lucia",
						"Santo Tomas",
						"Soledad",
						"Suan",
						"Tubara",
						"Usiacuri",
					],
					stateName: "Atlantico",
				},
				{ cities: ["Bogota"], stateName: "Bogota" },
				{
					cities: [
						"Achi",
						"Altos del Rosario",
						"Arenal",
						"Arjona",
						"Arroyohondo",
						"Barranco de Loba",
						"Calamar",
						"Cantagallo",
						"Cartagena",
						"Cicuco",
						"Clemencia",
						"Cordoba",
						"El Carmen de Bolivar",
						"El Guamo",
						"El Penon",
						"Hatillo de Loba",
						"Magangue",
						"Mahates",
						"Margarita",
						"Maria la Baja",
						"Mompos",
						"Montecristo",
						"Morales",
						"Pinillos",
						"Regidor",
						"Rio Viejo",
						"San Cristobal",
						"San Estanislao",
						"San Fernando",
						"San Jacinto",
						"San Jacinto del Cauca",
						"San Juan Nepomuceno",
						"San Martin de Loba",
						"San Pablo",
						"Santa Catalina",
						"Santa Rosa",
						"Santa Rosa del Sur",
						"Simiti",
						"Soplaviento",
						"Talaigua Nuevo",
						"Tiquisio",
						"Turbaco",
						"Turbana",
						"Villanueva",
						"Zambrano",
					],
					stateName: "Bolivar",
				},
				{
					cities: [
						"Almeida",
						"Aquitania",
						"Arcabuco",
						"Belen",
						"Berbeo",
						"Beteitiva",
						"Boavita",
						"Boyaca",
						"Briceno",
						"Buenavista",
						"Busbanza",
						"Caldas",
						"Campohermoso",
						"Cerinza",
						"Chinavita",
						"Chiquinquira",
						"Chiquiza",
						"Chiscas",
						"Chita",
						"Chitaraque",
						"Chivata",
						"Chivor",
						"Cienega",
						"Combita",
						"Coper",
						"Corrales",
						"Covarachia",
						"Cubara",
						"Cucaita",
						"Cuitiva",
						"Duitama",
						"El Cocuy",
						"El Espino",
						"Firavitoba",
						"Floresta",
						"Gachantiva",
						"Gameza",
						"Garagoa",
						"Guacamayas",
						"Guateque",
						"Guayata",
						"Guican",
						"Iza",
						"Jenesano",
						"Jerico",
						"La Capilla",
						"La Uvita",
						"La Victoria",
						"Labranzagrande",
						"Leiva",
						"Macanal",
						"Maripi",
						"Miraflores",
						"Mongua",
						"Mongui",
						"Moniquira",
						"Motavita",
						"Muzo",
						"Nobsa",
						"Nuevo Colon",
						"Oicata",
						"Otanche",
						"Pachavita",
						"Paez",
						"Paipa",
						"Pajarito",
						"Panqueba",
						"Pauna",
						"Paya",
						"Paz del Rio",
						"Pesca",
						"Pisba",
						"Puerto Boyaca",
						"Quipama",
						"Ramiriqui",
						"Raquira",
						"Rondon",
						"Saboya",
						"Sachica",
						"Samaca",
						"San Eduardo",
						"San Jose de Pare",
						"San Luis de Gaceno",
						"San Mateo",
						"San Miguel de Sema",
						"San Pablo de Borbur",
						"Santa Maria",
						"Santa Rosa de Viterbo",
						"Santa Sofia",
						"Santana",
						"Sativanorte",
						"Sativasur",
						"Siachoque",
						"Soata",
						"Socha",
						"Socota",
						"Sogamoso",
						"Somondoco",
						"Sora",
						"Soraca",
						"Sotaquira",
						"Susacon",
						"Sutamarchan",
						"Sutatenza",
						"Tasco",
						"Tenza",
						"Tibana",
						"Tibasosa",
						"Tinjaca",
						"Tipacoque",
						"Toca",
						"Togui",
						"Topaga",
						"Tota",
						"Tunja",
						"Tunungua",
						"Turmeque",
						"Tuta",
						"Tutasa",
						"Umbita",
						"Ventaquemada",
						"Viracacha",
						"Zetaquira",
					],
					stateName: "Boyaca",
				},
				{
					cities: [
						"Aguadas",
						"Anserma",
						"Aranzazu",
						"Belalcazar",
						"Chinchina",
						"Filadelfia",
						"La Dorada",
						"La Merced",
						"Manizales",
						"Manzanares",
						"Marmato",
						"Marquetalia",
						"Marulanda",
						"Neira",
						"Norcasia",
						"Pacora",
						"Palestina",
						"Pensilvania",
						"Riosucio",
						"Risaralda",
						"Salamina",
						"Samana",
						"San Jose",
						"Supia",
						"Victoria",
						"Villamaria",
						"Viterbo",
					],
					stateName: "Caldas",
				},
				{
					cities: [
						"Albania",
						"Belen Andaquies",
						"Cartagena del Chaira",
						"Curillo",
						"El Doncello",
						"El Paujil",
						"Florencia",
						"La Montanita",
						"Milan",
						"Morelia",
						"Puerto Rico",
						"San Jose de Fragua",
						"San Vicente del Caguan",
						"Solano",
						"Solita",
						"Valparaiso",
					],
					stateName: "Caqueta",
				},
				{
					cities: [
						"Aguazul",
						"Chameza",
						"Hato Corozal",
						"La Salina",
						"Mani",
						"Monterrey",
						"Nunchia",
						"Orocue",
						"Paz de Ariporo",
						"Pore",
						"Recetor",
						"Sabanalarga",
						"Sacama",
						"San Luis de Palenque",
						"Tamara",
						"Tauramena",
						"Trinidad",
						"Villanueva",
						"Yopal",
					],
					stateName: "Casanare",
				},
				{
					cities: [
						"Almaguer",
						"Argelia",
						"Balboa",
						"Bolivar",
						"Buenos Aires",
						"Cajibio",
						"Caldono",
						"Caloto",
						"Corinto",
						"El Bordo",
						"El Tambo",
						"Florencia",
						"Guapi",
						"Inza",
						"Jambalo",
						"La Sierra",
						"La Vega",
						"Lopez",
						"Mercaderes",
						"Miranda",
						"Morales",
						"Padilla",
						"Paez",
						"Piamonte",
						"Piendamo",
						"Popayan",
						"Puerto Tejada",
						"Purace",
						"Rosas",
						"San Sebastian",
						"Santa Rosa",
						"Santander de Quilichao",
						"Silvia",
						"Sotara",
						"Suarez",
						"Sucre",
						"Timbio",
						"Timbiqui",
						"Toribio",
						"Totoro",
						"Villa Rica",
					],
					stateName: "Cauca",
				},
				{
					cities: [
						"Aguachica",
						"Agustin Codazzi",
						"Astrea",
						"Becerril",
						"Bosconia",
						"Chimichagua",
						"Chiriguana",
						"Curumani",
						"El Copey",
						"El Paso",
						"Gamarra",
						"Gonzalez",
						"La Gloria",
						"La Jagua Ibirico",
						"Manaure",
						"Pailitas",
						"Pelaya",
						"Pueblo Bello",
						"Rio de Oro",
						"Robles la Paz",
						"San Alberto",
						"San Diego",
						"San Martin",
						"Tamalameque",
						"Valledupar",
					],
					stateName: "Cesar",
				},
				{
					cities: [
						"Acandi",
						"Alto Baudo",
						"Atrato",
						"Bagado",
						"Bahia Solano",
						"Bajo Baudo",
						"Bojaya",
						"Canton de San Pablo",
						"Carmen del Darien",
						"Certegui",
						"Condoto",
						"El Carmen",
						"Istmina",
						"Jurado",
						"Litoral del San Juan",
						"Lloro",
						"Medio Atrato",
						"Medio Baudo",
						"Medio San Juan",
						"Novita",
						"Nuqui",
						"Quibdo",
						"Rio Iro",
						"Rio Quito",
						"Riosucio",
						"San Jose del Palmar",
						"Sipi",
						"Tado",
						"Unguia",
						"Union Panamericana",
					],
					stateName: "Choco",
				},
				{
					cities: [
						"Ayapel",
						"Buenavista",
						"Canalete",
						"Cerete",
						"Chima",
						"Chinu",
						"Cienaga de Oro",
						"Cotorra",
						"La Apartada",
						"Lorica",
						"Los Cordobas",
						"Momil",
						"Monitos",
						"Montelibano",
						"Monteria",
						"Planeta Rica",
						"Pueblo Nuevo",
						"Puerto Escondido",
						"Puerto Libertador",
						"Purisima",
						"Sahagun",
						"San Andres Sotavento",
						"San Antero",
						"San Bernardo Viento",
						"San Carlos",
						"San Pelayo",
						"Tierralta",
						"Valencia",
					],
					stateName: "Cordoba",
				},
				{
					cities: [
						"Agua de Dios",
						"Alban",
						"Anapoima",
						"Anolaima",
						"Arbelaez",
						"Beltran",
						"Bituima",
						"Bojaca",
						"Cabrera",
						"Cachipay",
						"Cajica",
						"Caparrapi",
						"Caqueza",
						"Carmen de Carupa",
						"Chaguani",
						"Chia",
						"Chipaque",
						"Choachi",
						"Choconta",
						"Cogua",
						"Cota",
						"Cucunuba",
						"El Colegio",
						"El Penon",
						"El Rosal",
						"Facatativa",
						"Fomeque",
						"Fosca",
						"Funza",
						"Fuquene",
						"Fusagasuga",
						"Gachala",
						"Gachancipa",
						"Gacheta",
						"Gama",
						"Girardot",
						"Granada",
						"Guacheta",
						"Guaduas",
						"Guasca",
						"Guataqui",
						"Guatavita",
						"Guayabal de Siquima",
						"Guayabetal",
						"Gutierrez",
						"Jerusalen",
						"Junin",
						"La Calera",
						"La Mesa",
						"La Palma",
						"La Pena",
						"La Vega",
						"Lenguazaque",
						"Macheta",
						"Madrid",
						"Manta",
						"Medina",
						"Mosquera",
						"Narino",
						"Nemocon",
						"Nilo",
						"Nimaima",
						"Nocaima",
						"Ospina Perez",
						"Pacho",
						"Paime",
						"Pandi",
						"Paratebueno",
						"Pasca",
						"Puerto Salgar",
						"Puli",
						"Quebradanegra",
						"Quetame",
						"Quipile",
						"Rafael Reyes",
						"Ricaurte",
						"San Antonio del Tequendama",
						"San Bernardo",
						"San Cayetano",
						"San Francisco",
						"San Juan de Rioseco",
						"Sasaima",
						"Sesquile",
						"Sibate",
						"Silvania",
						"Simijaca",
						"Soacha",
						"Sopo",
						"Subachoque",
						"Suesca",
						"Supata",
						"Susa",
						"Sutatausa",
						"Tabio",
						"Tausa",
						"Tena",
						"Tenjo",
						"Tibacuy",
						"Tibirita",
						"Tocaima",
						"Tocancipa",
						"Topaipi",
						"Ubala",
						"Ubaque",
						"Ubate",
						"Une",
						"Utica",
						"Vergara",
						"Viani",
						"Villagomez",
						"Villapinzon",
						"Villeta",
						"Viota",
						"Yacopi",
						"Zipacon",
						"Zipaquira",
					],
					stateName: "Cundinamarca",
				},
				{ cities: ["Inirida"], stateName: "Guainia" },
				{
					cities: [
						"Calamar",
						"El Retorno",
						"Miraflores",
						"San Jose del Guaviare",
					],
					stateName: "Guaviare",
				},
				{
					cities: [
						"Acevedo",
						"Agrado",
						"Aipe",
						"Algeciras",
						"Altamira",
						"Baraya",
						"Campoalegre",
						"Colombia",
						"Elias",
						"Garzon",
						"Gigante",
						"Guadalupe",
						"Hobo",
						"Iquira",
						"Isnos",
						"La Argentina",
						"La Plata",
						"Nataga",
						"Neiva",
						"Oporapa",
						"Paicol",
						"Palermo",
						"Palestina",
						"Pital",
						"Pitalito",
						"Rivera",
						"Saladoblanco",
						"San Agustin",
						"Santa Maria",
						"Suaza",
						"Tarqui",
						"Tello",
						"Teruel",
						"Tesalia",
						"Timana",
						"Villavieja",
						"Yaguara",
					],
					stateName: "Huila",
				},
				{ cities: [], stateName: "La Guajira" },
				{
					cities: [
						"Algarrobo",
						"Aracataca",
						"Ariguani",
						"Cerro San Antonio",
						"Chivolo",
						"Cienaga",
						"Concordia",
						"El Banco",
						"El Pinon",
						"El Reten",
						"Fundacion",
						"Guamal",
						"Nueva Granada",
						"Pedraza",
						"Pijino del Carmen",
						"Pivijay",
						"Plato",
						"Puebloviejo",
						"Remolino",
						"Sabanas de San Angel",
						"Salamina",
						"San Sebastian",
						"San Zenon",
						"Santa Ana",
						"Santa Barbara de Pinto",
						"Santa Marta",
						"Sitionuevo",
						"Tenerife",
						"Zapayan",
						"Zona Bananera",
					],
					stateName: "Magdalena",
				},
				{
					cities: [
						"Acacias",
						"Barranca de Upia",
						"Cabuyaro",
						"Castilla la Nueva",
						"Cubarral",
						"Cumaral",
						"El Calvario",
						"El Castillo",
						"El Dorado",
						"Fuente de Oro",
						"Granada",
						"Guamal",
						"La Macarena",
						"La Uribe",
						"Lejanias",
						"Mapiripan",
						"Mesetas",
						"Puerto Concordia",
						"Puerto Gaitan",
						"Puerto Lleras",
						"Puerto Lopez",
						"Puerto Rico",
						"Restrepo",
						"San Carlos Guaroa",
						"San Juan de Arama",
						"San Juanito",
						"San Martin",
						"Villavicencio",
						"Vista Hermosa",
					],
					stateName: "Meta",
				},
				{
					cities: [
						"Alban",
						"Aldana",
						"Ancuya",
						"Arboleda",
						"Barbacoas",
						"Belen",
						"Buesaco",
						"Chachagui",
						"Colon",
						"Consaca",
						"Contadero",
						"Cordoba",
						"Cuaspud",
						"Cumbal",
						"Cumbitara",
						"El Charco",
						"El Penol",
						"El Rosario",
						"El Tablon",
						"El Tambo",
						"Funes",
						"Guachucal",
						"Guaitarilla",
						"Gualmatan",
						"Iles",
						"Imues",
						"Ipiales",
						"La Cruz",
						"La Florida",
						"La Llanada",
						"La Tola",
						"La Union",
						"Leiva",
						"Linares",
						"Los Andes",
						"Magui",
						"Mallama",
						"Mosquera",
						"Narino",
						"Olaya Herrera",
						"Ospina",
						"Pasto",
						"Pizarro",
						"Policarpa",
						"Potosi",
						"Providencia",
						"Puerres",
						"Pupiales",
						"Ricaurte",
						"Roberto Payan",
						"Samaniego",
						"San Bernardo",
						"San Lorenzo",
						"San Pablo",
						"San Pedro de Cartago",
						"Sandona",
						"Santa Barbara",
						"Santacruz",
						"Sapuyes",
						"Taminango",
						"Tangua",
						"Tumaco",
						"Tuquerres",
						"Yacuanquer",
					],
					stateName: "Narino",
				},
				{ cities: [], stateName: "Norte de Santander" },
				{
					cities: [
						"Colon",
						"Mocoa",
						"Orito",
						"Puerto Asis",
						"Puerto Caycedo",
						"Puerto Guzman",
						"Puerto Leguizamo",
						"San Francisco",
						"San Miguel",
						"Santiago",
						"Sibundoy",
						"Valle del Guamuez",
						"Villagarzon",
					],
					stateName: "Putumayo",
				},
				{
					cities: [
						"Armenia",
						"Buenavista",
						"Calarca",
						"Circasia",
						"Cordoba",
						"Filandia",
						"Genova",
						"La Tebaida",
						"Montenegro",
						"Pijao",
						"Quimbaya",
						"Salento",
					],
					stateName: "Quindio",
				},
				{
					cities: [
						"Apia",
						"Balboa",
						"Belen de Umbria",
						"Dos Quebradas",
						"Guatica",
						"La Celia",
						"La Virginia",
						"Marsella",
						"Mistrato",
						"Pereira",
						"Pueblo Rico",
						"Quinchia",
						"Santa Rosa de Cabal",
						"Santuario",
					],
					stateName: "Risaralda",
				},
				{ cities: [], stateName: "San Andres y Providencia" },
				{
					cities: [
						"Aguada",
						"Albania",
						"Aratoca",
						"Barbosa",
						"Barichara",
						"Barrancabermeja",
						"Betulia",
						"Bolivar",
						"Bucaramanga",
						"Cabrera",
						"California",
						"Capitanejo",
						"Carcasi",
						"Cepita",
						"Cerrito",
						"Charala",
						"Charta",
						"Chima",
						"Chipata",
						"Cimitarra",
						"Concepcion",
						"Confines",
						"Contratacion",
						"Coromoro",
						"Curiti",
						"El Carmen",
						"El Guacamayo",
						"El Penon",
						"El Playon",
						"Encino",
						"Enciso",
						"Florian",
						"Floridablanca",
						"Galan",
						"Gambita",
						"Giron",
						"Guaca",
						"Guadalupe",
						"Guapota",
						"Guavata",
						"Guepsa",
						"Hato",
						"Jesus Maria",
						"Jordan",
						"La Belleza",
						"La Paz",
						"Landazuri",
						"Lebrija",
						"Los Santos",
						"Macaravita",
						"Malaga",
						"Matanza",
						"Mogotes",
						"Molagavita",
						"Ocamonte",
						"Oiba",
						"Onzaga",
						"Palmar",
						"Palmas del Socorro",
						"Paramo",
						"Piedecuesta",
						"Pinchote",
						"Puente Nacional",
						"Puerto Parra",
						"Puerto Wilches",
						"Rionegro",
						"Sabana de Torres",
						"San Andres",
						"San Benito",
						"San Gil",
						"San Joaquin",
						"San Jose de Miranda",
						"San Miguel",
						"San Vicente de Chucuri",
						"Santa Barbara",
						"Santa Helena",
						"Simacota",
						"Socorro",
						"Suaita",
						"Sucre",
						"Surata",
						"Tona",
						"Valle San Jose",
						"Velez",
						"Vetas",
						"Villanueva",
						"Zapatoca",
					],
					stateName: "Santander",
				},
				{
					cities: [
						"Buenavista",
						"Caimito",
						"Chalan",
						"Coloso",
						"Corozal",
						"El Roble",
						"Galeras",
						"Guaranda",
						"La Union",
						"Los Palmitos",
						"Majagual",
						"Morroa",
						"Ovejas",
						"Palmito",
						"Sampues",
						"San Benito Abad",
						"San Juan de Betulia",
						"San Marcos",
						"San Onofre",
						"San Pedro",
						"Since",
						"Sincelejo",
						"Sucre",
						"Tolu",
						"Toluviejo",
					],
					stateName: "Sucre",
				},
				{
					cities: [
						"Alpujarra",
						"Alvarado",
						"Ambalema",
						"Anzoategui",
						"Ataco",
						"Cajamarca",
						"Carmen de Apicala",
						"Casabianca",
						"Chaparral",
						"Coello",
						"Coyaima",
						"Cunday",
						"Dolores",
						"Espinal",
						"Falan",
						"Flandes",
						"Fresno",
						"Guamo",
						"Guayabal",
						"Herveo",
						"Honda",
						"Ibague",
						"Icononzo",
						"Lerida",
						"Libano",
						"Mariquita",
						"Melgar",
						"Murillo",
						"Natagaima",
						"Ortega",
						"Palocabildo",
						"Piedras",
						"Planadas",
						"Prado",
						"Purificacion",
						"Rioblanco",
						"Roncesvalles",
						"Rovira",
						"Saldana",
						"San Antonio",
						"San Luis",
						"Santa Isabel",
						"Suarez",
						"Valle de San Juan",
						"Venadillo",
						"Villahermosa",
						"Villarrica",
					],
					stateName: "Tolima",
				},
				{ cities: [], stateName: "Valle del Cauca" },
				{
					cities: [
						"Acaricuara",
						"Mitu",
						"Papunaua",
						"Taraira",
						"Villa Fatima",
						"Yavarate",
					],
					stateName: "Vaupes",
				},
				{
					cities: [
						"Cumaribo",
						"La Primavera",
						"Puerto Carreno",
						"Santa Rosalia",
					],
					stateName: "Vichada",
				},
			],
			countryName: "Colombia",
		},
		{
			states: [
				{ cities: ["Fomboni"], stateName: "Mwali" },
				{ cities: ["Mitsamiouli", "Moroni"], stateName: "Njazidja" },
				{ cities: ["Domoni", "Mutsamudu"], stateName: "Nzwani" },
			],
			countryName: "Comoros",
		},
		{
			states: [
				{ cities: ["Loudima", "Madingou", "Nkayi"], stateName: "Bouenza" },
				{ cities: ["Brazzaville"], stateName: "Brazzaville" },
				{
					cities: ["Ewo", "Kelle", "Makoua", "Mossaka", "Owando"],
					stateName: "Cuvette",
				},
				{
					cities: ["Loandjili", "Ngamaba-Mfilou", "Pointe Noire"],
					stateName: "Kouilou",
				},
				{ cities: ["Sibiti", "Zanaga"], stateName: "Lekoumou" },
				{ cities: ["Dongou", "Epena", "Impfondo"], stateName: "Likouala" },
				{
					cities: ["Kibangou", "Loubomo", "Matsanga", "Mossendjo"],
					stateName: "Niari",
				},
				{ cities: ["Djambala", "Gamboma"], stateName: "Plateaux" },
				{ cities: ["Boko", "Kinkala", "Mindouli"], stateName: "Pool" },
				{
					cities: ["Ikelemba", "Ouesso", "Sembe", "Souanke"],
					stateName: "Sangha",
				},
			],
			countryName: "Congo",
		},
		{
			states: [
				{
					cities: [
						"Bandundu",
						"Bolobo",
						"Bulungu",
						"Gungu",
						"Idiofa",
						"Inongo",
						"Kahemba",
						"Kasongo-Lunda",
						"Kenge",
						"Kikwit",
						"Kiri",
						"Kutu",
						"Lusanga",
						"Mangai",
						"Mushie",
						"Nioki",
					],
					stateName: "Bandundu",
				},
				{
					cities: [
						"Boma",
						"Kasangulu",
						"Kimpese",
						"Madimba",
						"Matadi",
						"Mbanza-Ngungu",
						"Muanda",
						"Tshela",
					],
					stateName: "Bas-Congo",
				},
				{
					cities: [
						"Basankusu",
						"Binga",
						"Bodalangi",
						"Boende",
						"Bongandanga",
						"Bosobolo",
						"Bumba",
						"Businga",
						"Gbadolite",
						"Gemena",
						"Ikela",
						"Libenge",
						"Lisala",
						"Makanza",
						"Mbandaka",
						"Mobayi-Mbongo",
						"Yakoma",
						"Yandongi",
						"Yumbi",
						"Zongo",
					],
					stateName: "Equateur",
				},
				{
					cities: [
						"Aba",
						"Aketi",
						"Bafwasende",
						"Banalia",
						"Basoko",
						"Bondo",
						"Bunia",
						"Buta",
						"Djugu",
						"Faradje",
						"Gwane",
						"Isiro",
						"Itoko",
						"Kisangani",
						"Mambasa",
						"Mongbwalu",
						"Niangara",
						"Poko",
						"Simba",
						"Titule",
						"Ubundu",
						"Wamba",
						"Watsa",
						"Yangambi",
					],
					stateName: "Haut-Congo",
				},
				{
					cities: [
						"Demba",
						"Dibaya",
						"Ilebo",
						"Kananga",
						"Kazumba",
						"Luebo",
						"Mweka",
						"Tshikapa",
					],
					stateName: "Kasai-Occidental",
				},
				{
					cities: [
						"Gandajika",
						"Kabinda",
						"Katako-Kombe",
						"Kole",
						"Lodja",
						"Lubao",
						"Lubefu",
						"Lusambo",
						"Mbuji-Mayi",
						"Mwene-Ditu",
						"Tshilenge",
						"Tshofa",
					],
					stateName: "Kasai-Oriental",
				},
				{
					cities: [
						"Bukama",
						"Dilolo",
						"Kabalo",
						"Kalemie",
						"Kambove",
						"Kamina",
						"Kaniama",
						"Kikondjo",
						"Kipushi",
						"Kolwezi",
						"Kongolo",
						"Le Marinel",
						"Likasi",
						"Lubudi",
						"Lubumbashi",
						"Malemba-Nkulu",
						"Manono",
						"Moba",
						"Mulongo",
						"Mwanza",
						"Nyunzu",
						"Sakania",
						"Shinkolobwe",
					],
					stateName: "Katanga",
				},
				{ cities: ["Kinshasa"], stateName: "Kinshasa" },
				{ cities: ["Kalima", "Kasongo", "Kindu"], stateName: "Maniema" },
				{ cities: ["Beni", "Butembo", "Goma"], stateName: "Nord-Kivu" },
				{
					cities: ["Bukavu", "Kabare", "Kama", "Kampene", "Kibombo", "Uvira"],
					stateName: "Sud-Kivu",
				},
			],
			countryName: "Congo The Democratic Republic Of The",
		},
		{
			states: [
				{ cities: ["Amuri"], stateName: "Aitutaki" },
				{ cities: ["Atiu"], stateName: "Atiu" },
				{ cities: ["Mangaia"], stateName: "Mangaia" },
				{ cities: ["Tauhunu"], stateName: "Manihiki" },
				{ cities: ["Mauke"], stateName: "Mauke" },
				{ cities: ["Mitiaro"], stateName: "Mitiaro" },
				{ cities: ["Nassau"], stateName: "Nassau" },
				{ cities: ["Roto"], stateName: "Pukapuka" },
				{ cities: ["Rakahanga"], stateName: "Rakahanga" },
				{ cities: ["Avarua"], stateName: "Rarotonga" },
				{ cities: ["Omoka"], stateName: "Tongareva" },
			],
			countryName: "Cook Islands",
		},
		{
			states: [
				{
					cities: [
						"Alajuela",
						"Atenas",
						"Bijagua",
						"Buenos Aires",
						"Carrillos",
						"Desemparados",
						"Dos Rios",
						"Esquipulas",
						"Florencia",
						"Fortuna",
						"Grecia",
						"Guacimo",
						"Laguna",
						"Los Chiles",
						"Mastate",
						"Naranjo",
						"Orotina",
						"Palmares",
						"Piedades Norte",
						"Pital",
						"Pocosol",
						"Quesada",
						"Rio Segundo",
						"Sabanilla",
						"San Antonio",
						"San Jose",
						"San Juan",
						"San Mateo",
						"San Pedro",
						"San Rafael",
						"San Ramon",
						"San Roque",
						"Santiago",
						"Sarchi Norte",
						"Sarchi Sur",
						"Tigra",
						"Turricares",
						"Upala",
						"Venado",
						"Zarcero",
					],
					stateName: "Alajuela",
				},
				{
					cities: [
						"Aguacaliente",
						"Capellades",
						"Carmen",
						"Cartago",
						"Concepcion",
						"Cot",
						"Dulce Nombre",
						"El Tejar",
						"Guadalupe",
						"Juan Vinas",
						"La Suiza",
						"Orosi",
						"Pacayas",
						"Paraiso",
						"Pejibaye",
						"San Diego",
						"San Isidro",
						"San Juan",
						"San Nicolas",
						"San Rafael",
						"San Ramon",
						"Tierra Blanca",
						"Tobosi",
						"Tres Rios",
						"Tucurrique",
						"Turrialba",
					],
					stateName: "Cartago",
				},
				{
					cities: [
						"Bagaces",
						"Belen",
						"Canas",
						"Filadeldia",
						"Fortuna",
						"Hojancha",
						"Juntas",
						"La Cruz",
						"Liberia",
						"Mogote",
						"Nandayure",
						"Nicoya",
						"Samara",
						"Santa Cruz",
						"Sardinal",
						"Tilaran",
					],
					stateName: "Guanacaste",
				},
				{
					cities: [
						"Angeles",
						"Asuncion",
						"Barrantes",
						"Barva",
						"Heredia",
						"Horquetas",
						"Llorente",
						"Mercedes",
						"Puerto Viejo",
						"Rivera",
						"San Antonio",
						"San Francisco",
						"San Isidro",
						"San Joaquin",
						"San Jose",
						"San Josecito",
						"San Juan",
						"San Miguel",
						"San Pablo",
						"San Pedro",
						"San Rafael",
						"San Roque",
						"San Vicente",
						"Santa Barbara",
						"Santa Lucia",
						"Santa Rosa",
						"Santiago",
						"Santo Domingo",
						"Santo Tomas",
						"Ulloa",
					],
					stateName: "Heredia",
				},
				{
					cities: [
						"Batan",
						"Cahuita",
						"Cariari",
						"Guacimo",
						"Guapiles",
						"Jimenez",
						"Limon",
						"Matina",
						"Pocora",
						"Rita",
						"Roxana",
						"Siquirres",
						"Sixaola",
						"Valle la Estrella",
					],
					stateName: "Limon",
				},
				{
					cities: [
						"Buenos Aires",
						"Canoas",
						"Chacarita",
						"Corredor",
						"Esparta",
						"Espiritu Santo",
						"Golfito",
						"Guaycara",
						"Jaco",
						"La Cuesta",
						"Macacona",
						"Manzanillo",
						"Miramar",
						"Palmar",
						"Paquera",
						"Parrita",
						"Puerto Cortes",
						"Puerto Jimenez",
						"Puntarenas",
						"Quepos",
						"San Vito",
					],
					stateName: "Puntarenas",
				},
				{ cities: [], stateName: "San Jose" },
			],
			countryName: "Costa Rica",
		},
		{
			states: [
				{ cities: ["Marcory"], stateName: "Abidjan" },
				{
					cities: ["Adzope", "Affery", "Agboville", "Akoupe", "Rubino"],
					stateName: "Agneby",
				},
				{ cities: ["Touba"], stateName: "Bafing" },
				{ cities: ["Bako", "Odienne"], stateName: "Denguele" },
				{ cities: [], stateName: "Dix-huit Montagnes" },
				{
					cities: [
						"Gagnoa",
						"Guiberoua",
						"Hire",
						"Ndouci",
						"Oume",
						"Ouragahio",
					],
					stateName: "Fromager",
				},
				{ cities: ["Daloa", "Issia", "Vavoua"], stateName: "Haut-Sassandra" },
				{
					cities: ["Ndiekro", "Tiebissou", "Toumodi", "Yamoussoukro"],
					stateName: "Lacs",
				},
				{
					cities: [
						"Abidjan",
						"Alepe",
						"Anyama",
						"Bingerville",
						"Dabou",
						"Grand-Lahou",
						"Jacqueville",
						"Tiassale",
					],
					stateName: "Lagunes",
				},
				{ cities: ["Bouafle", "Sinfra", "Zuenoula"], stateName: "Marahoue" },
				{
					cities: ["Duekoue", "Guiglo", "Toulepleu"],
					stateName: "Moyen-Cavally",
				},
				{ cities: ["Abengourou", "Agnibilekrou"], stateName: "Moyen-Comoe" },
				{
					cities: [
						"Arrah",
						"Bocanda",
						"Bongouanou",
						"Daoukro",
						"Dimbokro",
						"M'bahiakro",
						"Mbatto",
						"N'zi-Comoe",
					],
					stateName: "N'zi-Comoe",
				},
				{
					cities: ["San-Pedro", "Sassandra", "Soubre", "Tabou"],
					stateName: "Sassandra",
				},
				{
					cities: [
						"Boundiali",
						"Dikodougou",
						"Ferkessedougou",
						"Korhogo",
						"Tingrela",
					],
					stateName: "Savanes",
				},
				{ cities: ["Divo", "Fresco", "Lakota"], stateName: "Sud-Bandama" },
				{
					cities: ["Aboisso", "Adiake", "Ayame", "Bonoua", "Grand Bassam"],
					stateName: "Sud-Comoe",
				},
				{ cities: [], stateName: "Vallee du Bandama" },
				{ cities: ["Kani", "Mankono", "Seguela"], stateName: "Worodougou" },
				{ cities: ["Bondoukou", "Bouna", "Tanda"], stateName: "Zanzan" },
			],
			countryName: "Cote D'Ivoire (Ivory Coast)",
		},
		{
			states: [
				{
					cities: [
						"Bjelovar",
						"Brezovac",
						"Chazma",
						"Daruvar",
						"Daruvarski Brestovac",
						"Dezhanovac",
						"Dhulovac",
						"Gareshnica",
						"Gareshnichki Brestovac",
						"Grubishno Polje",
						"Gudovac",
						"Hercegovac",
						"Ivanska",
						"Klokochevac",
						"Konchanica",
						"Predavac",
						"Rovishce",
						"Shandrovac",
						"Sirach",
						"Trojstveni Markovac",
						"Velika Pisanica",
						"Veliki Grdhevac",
						"Veliki Zdenci",
						"Veliko Trojstvo",
						"Zhdralovi",
					],
					stateName: "Bjelovar-Bilogora",
				},
				{
					cities: [
						"Blato",
						"Cavtat",
						"Chibacha",
						"Chilipi",
						"Dubrovnik",
						"Komin",
						"Korchula",
						"Lumbarda",
						"Metkovic",
						"Mlini",
						"Mokoshica",
						"Nova Mokoshica",
						"Opuzen",
						"Orebic",
						"Otrich-Seoci",
						"Ploche",
						"Smokvica",
						"Stashevica",
						"Vela Luka",
						"Zaton",
						"Zhrnovo",
					],
					stateName: "Dubrovnik-Neretva",
				},
				{ cities: [], stateName: "Grad Zagreb" },
				{
					cities: [
						"Bale",
						"Banjole",
						"Brtonigla",
						"Buje",
						"Buzet",
						"Fazhana",
						"Funtana",
						"Galizhana",
						"Labin",
						"Lizhnjan",
						"Marchana",
						"Medulin",
						"Novigrad",
						"Pazin",
						"Porech",
						"Premantura",
						"Pula",
						"Rabac",
						"Rasha",
						"Rovinj",
						"Sveti Petar u Shumi",
						"Tar",
						"Umag",
						"Vinezh",
						"Vodnjan",
						"Vrsar",
					],
					stateName: "Istra",
				},
				{
					cities: [
						"Cerovac Vukmansichki",
						"Draganic",
						"Duga Resa",
						"Josipdol",
						"Karlovac",
						"Mrezhnichki Varosh",
						"Ogulin",
						"Oshtarije",
						"Ozalj",
						"Plashki",
						"Slunj",
						"Vojnic",
					],
					stateName: "Karlovac",
				},
				{
					cities: [
						"Dhelekovec",
						"Dhurdhevac",
						"Drnje",
						"Ferdinandovac",
						"Glogovac",
						"Gola",
						"Hlebine",
						"Kalinovac",
						"Kloshtar Podravski",
						"Koprivnica",
						"Koprivnichki Bregi",
						"Koprivnichki Ivanec",
						"Krizhevci",
						"Legrad",
						"Molve",
						"Novigrad Podravski",
						"Novo Virje",
						"Peteranec",
						"Podravske Sesvete",
						"Rasinja",
						"Reka",
						"Sigetec",
						"Starigrad",
						"Sveti Ivan Zhabno",
						"Trema",
						"Virje",
					],
					stateName: "Koprivnica-Krizhevci",
				},
				{
					cities: [
						"Andrashevec",
						"Bedekovchina",
						"Dhurmanec",
						"Donja Pachetina",
						"Donja Shemnica",
						"Donja Stubica",
						"Dubrovchan",
						"Gornja Stubica",
						"Hum na Sutli",
						"Klanjec",
						"Konjishchina",
						"Krapina",
						"Krapinske Toplice",
						"Laz Bistrichki",
						"Marija Bistrica",
						"Mihovljan",
						"Oroslavje",
						"Podgorje Bistrichko",
						"Poznanovec",
						"Pregrada",
						"Radoboj",
						"Shkaricevo",
						"Shpichkovina",
						"Stubichke Toplice",
						"Sveti Krizh Zachretje",
						"Veliko Trgovishce",
						"Zabok",
						"Zlatar",
						"Zlatar-Bistrica",
					],
					stateName: "Krapina-Zagorje",
				},
				{
					cities: [
						"Brinje",
						"Donji Lapac",
						"Gospic",
						"Korenica",
						"Lichki Osik",
						"Lichko Leshce",
						"Novalja",
						"Otochac",
						"Perushic",
						"Prozor",
						"Senj",
					],
					stateName: "Lika-Senj",
				},
				{
					cities: [
						"Belica",
						"Chakovec",
						"Cirkovljan",
						"Dekanovec",
						"Domashinec",
						"Donja Dubrava",
						"Donji Kraljevec",
						"Donji Vidovec",
						"Drzhimurec",
						"Dunjkovec",
						"Gardinovec",
						"Gorichan",
						"Gornji Hrashcan",
						"Hodoshan",
						"Ivanovec",
						"Kotoriba",
						"Kurshanec",
						"Lopatinec",
						"Machkovec",
						"Mala Subotica",
						"Mihovljan",
						"Mursko Sredishce",
						"Nedelishce",
						"Novakovec",
						"Novo Selo Rok",
						"Orehovica",
						"Palovec",
						"Peklenica",
						"Podturen",
						"Prelog",
						"Pribislavec",
						"Pushcine",
						"Savska Ves",
						"Selnica",
						"Shenkovec",
						"Strahoninec",
						"Sveta Marija",
						"Trnovec",
						"Vratishinec",
						"Zasadbreg",
					],
					stateName: "Medhimurje",
				},
				{ cities: [], stateName: "Medimurska Zupanija" },
				{
					cities: [
						"Antunovac",
						"Batina",
						"Beli Manastir",
						"Belishce",
						"Beljevina",
						"Bijelo Brdo",
						"Bilje",
						"Bistrinci",
						"Bizovac",
						"Branjin Vrh",
						"Brijeshce",
						"Brijest",
						"Ceminac",
						"Chepin",
						"Crnkovci",
						"Dalj",
						"Darda",
						"Dhakovo",
						"Dhurdhenovac",
						"Donja Motichina",
						"Donji Miholac",
						"Erdut",
						"Ernestinovo",
						"Ferichanci",
						"Gashinci",
						"Gorjani",
						"Grabovac",
						"Ivanovac",
						"Ivanovci Gorjanski",
						"Jagodnjak",
						"Jelisavac",
						"Josipovac",
						"Josipovac Punitovachki",
						"Karanac",
						"Keshinci",
						"Knezhevi Vinogradi",
						"Knezhevo",
						"Koritna",
						"Koshka",
						"Kushevac",
						"Ladimirevci",
						"Laslovo",
						"Lug",
						"Marijanci",
						"Markovac Nashichki",
						"Martin",
						"Mece",
						"Moslavina Podravska",
						"Nashice",
						"Osijek",
						"Ovchara",
						"Petlovac",
						"Petrijevci",
						"Pishkorevci",
						"Podgorach",
						"Popvac",
						"Rakitovica",
						"Sarvash",
						"Satnica Dhakovachka",
						"Selci Dhakovacki",
						"Semeljci",
						"Shiroko Polje",
						"Strizivojna",
						"Sveti Dhuradh",
						"Tenja",
						"Valpovo",
						"Velimirovac",
						"Viljevo",
						"Vishkovci",
						"Vishnjevac",
						"Vladislavci",
						"Vuka",
						"Vukojevci",
						"Zmajevac",
						"Zoljan",
					],
					stateName: "Osijek-Baranja",
				},
				{ cities: [], stateName: "Osjecko-Baranjska Zupanija" },
				{
					cities: [
						"Badljevina",
						"Brodski Drenovac",
						"Dervishaga",
						"Gradac",
						"Jakshic",
						"Kaptol",
						"Kutjevo",
						"Lipik",
						"Pakrac",
						"Pleternica",
						"Pozhega",
						"Prekopkra",
						"Trenkovo",
						"Velika",
						"Vetovo",
						"Vidovci",
					],
					stateName: "Pozhega-Slavonija",
				},
				{ cities: [], stateName: "Primorje-Gorski Kotar" },
				{
					cities: [
						"Bilice",
						"Brodarica",
						"Drnish",
						"Dubrava kod Shibenika",
						"Grebashtica",
						"Jezera",
						"Kistanje",
						"Knin",
						"Kovachic",
						"Murter",
						"Pirovac",
						"Primoshten",
						"Rogoznica",
						"Shibenik",
						"Skradin",
						"Tisno",
						"Tribunj",
						"Vodice",
						"Vrpolje",
						"Zaton",
					],
					stateName: "Shibenik-Knin",
				},
				{
					cities: [
						"Brestacha",
						"Brochice",
						"Budashevo",
						"Donja Grachenica",
						"Dvor",
						"Glina",
						"Gornja Grachenica",
						"Gornja Jelenska",
						"Greda",
						"Gvozd",
						"Hrastelnica",
						"Hrvatska Dubica",
						"Hrvatska Kostajnica",
						"Husain",
						"Ilova",
						"Kutina",
						"Lekenik",
						"Lipovljani",
						"Moshchenica",
						"Novska",
						"Odra Sisachka",
						"Osekovo",
						"Peshcenica",
						"Petrinja",
						"Popovacha",
						"Potok",
						"Rajic",
						"Repushnica",
						"Sisak",
						"Staro Prachno",
						"Sunja",
						"Topolovac",
						"Voloder",
					],
					stateName: "Sisak-Moslavina",
				},
				{ cities: [], stateName: "Slavonski Brod-Posavina" },
				{
					cities: [
						"Bashka Voda",
						"Bol",
						"Brela",
						"Brnaze",
						"Cista Velika",
						"Donji Prolozhac",
						"Donji Vinjani",
						"Duce",
						"Dugi Rat",
						"Dugopolje",
						"Gala",
						"Glavice",
						"Glavina Donja",
						"Gornji Vincjani",
						"Gradac",
						"Grubine",
						"Hrvace",
						"Hvar",
						"Imotski",
						"Jelsa",
						"Jesenice",
						"Kamen",
						"Kashtel Gambelovac",
						"Kashtel Gomilica",
						"Kashtel Lukshic",
						"Kashtel Novi",
						"Kashtel Shtafilic",
						"Kashtel Stari",
						"Kashtel Sucurac",
						"Katuni",
						"Klis",
						"Komizha",
						"Koshute",
						"Lovrec",
						"Makarska",
						"Marina",
						"Mastrinka",
						"Milna",
						"Mravince",
						"Neoric",
						"Obrovac Sinjski",
						"Okrug Gornji",
						"Omish",
						"Otok",
						"Podgora",
						"Podstrana",
						"Poljica",
						"Postira",
						"Postranje",
						"Potravlje",
						"Primorski Dolac",
						"Puchishca",
						"Ruda",
						"Runovic",
						"Seget Donji",
						"Seget Vranjica",
						"Selca",
						"Sinj",
						"Slatine",
						"Solin",
						"Split",
						"Srinjine",
						"Stari Grad",
						"Stobrech",
						"Supetar",
						"Trilj",
						"Trogir",
						"Tuchepi",
						"Turjaci",
						"Vinishce",
						"Vis",
						"Vranjic",
						"Vrgorac",
						"Vrlika",
						"Zagvozd",
						"Zhrnovnica",
						"Zmijavci",
					],
					stateName: "Split-Dalmacija",
				},
				{
					cities: [
						"Bedenec",
						"Beletinec",
						"Beretinec",
						"Breznica",
						"Chreshnjevo",
						"Donja Voca",
						"Donje Ladanje",
						"Gornje Ladanje",
						"Gornje Vratno",
						"Gornji Kneginec",
						"Gornji Kucan",
						"Hrashcica",
						"Hrastovsko",
						"Hrzhenica",
						"Ivanec",
						"Jalkovec",
						"Jalzhabet",
						"Jerovec",
						"Klenovnik",
						"Kljuch",
						"Kucan Marof",
						"Lepoglava",
						"Ljubeshcica",
						"Ludbreg",
						"Madzharevo",
						"Mali Bukovec",
						"Nedeljanec",
						"Nova Ves Petrijanec",
						"Novi Marof",
						"Petrijanec",
						"Podevchevo",
						"Presechno",
						"Remetinec",
						"Selnik",
						"Shemovec",
						"Srachinec",
						"Sveti Petar",
						"Svibovec Podravski",
						"Trnovec",
						"Turchin",
						"Tuzhno",
						"Varazhdin",
						"Varazhdin Breg",
						"Varazhdinske Toplice",
						"Vidovec",
						"Vinica",
						"Zavrshje Podbelsko",
						"Zharovnica",
					],
					stateName: "Varazhdin",
				},
				{
					cities: [
						"Borova",
						"Busetina",
						"Cabuna",
						"Chachinci",
						"Gradina",
						"Korija",
						"Mikleush",
						"Milanovac",
						"Nova Bukovica",
						"Orahovica",
						"Pitomacha",
						"Podgorje",
						"Rezovac",
						"Shpishic Bukovica",
						"Slatina",
						"Suhopolje",
						"Turanovac",
						"Virovitica",
						"Vocin",
						"Zdenci",
					],
					stateName: "Virovitica-Podravina",
				},
				{
					cities: [
						"Andrijashevci",
						"Antin",
						"Babina Greda",
						"Bapska",
						"Bobota",
						"Bogdanovci",
						"Borovo",
						"Boshnjaci",
						"Brshadin",
						"Ceric",
						"Cerna",
						"Drenovci",
						"Gradishte",
						"Gunja",
						"Ilacha",
						"Ilok",
						"Ivankovo",
						"Jarmina",
						"Komletinci",
						"Lipovac",
						"Lovas",
						"Markushica",
						"Mirkovci",
						"Negoslavci",
						"Nijemci",
						"Novi Jankovci",
						"Nushtar",
						"Otok",
						"Petrovci",
						"Posavski Podgajci",
						"Privlaka",
						"Rachinovci",
						"Rajevo Selo",
						"Retkovci",
						"Rokovci",
						"Sharengrad",
						"Shishkovci",
						"Shtitar",
						"Slakovci",
						"Soljani",
						"Sotin",
						"Stari Jankovci",
						"Stari Mikanovci",
						"Tordinci",
						"Tovarnik",
						"Trpinja",
						"Vinkovci",
						"Vodhinci",
						"Vrbanja",
						"Vukovar",
						"Zhupanja",
					],
					stateName: "Vukovar-Srijem",
				},
				{
					cities: [
						"Benkovac",
						"Bibinje",
						"Biograd na Moru",
						"Debeljak",
						"Galovac",
						"Gorica",
						"Gornji Karin",
						"Grachac",
						"Jasenice",
						"Kali",
						"Krushevo",
						"Nin",
						"Obrovac",
						"Pag",
						"Pakoshtane",
						"Polacha",
						"Polichnik",
						"Poljica",
						"Posedarje",
						"Preko",
						"Pridraga",
						"Privlaka",
						"Razhanac",
						"Shkabrnja",
						"Slivnica",
						"Starigrad",
						"Sukoshan",
						"Sveti Filip i Jakov",
						"Turanj",
						"Ugljan",
						"Vir",
						"Vrsi",
						"Zadar",
						"Zemunik Donji",
					],
					stateName: "Zadar",
				},
				{
					cities: [
						"Bestovje",
						"Bishkupec Zelinski",
						"Brckovljani",
						"Brdovec",
						"Bregana",
						"Brezje",
						"Bushevec",
						"Celine",
						"Domaslovec",
						"Donja Bistra",
						"Donja Kupchina",
						"Donja Lomnica",
						"Donja Zdenchina",
						"Donji Desinec",
						"Donji Stupnik",
						"Dubrava",
						"Dugo Selo",
						"Gornja Bistra",
						"Gornji Laduch",
						"Gornji Stupnik",
						"Grachec",
						"Gradici",
						"Ivan Bistranski",
						"Ivanic-Grad",
						"Jablanovec",
						"Jakovlje",
						"Jastrebarsko",
						"Kerestinec",
						"Klincha Sela",
						"Kloshtar Ivanic",
						"Kozinshchak",
						"Krizh",
						"Kuche",
						"Kupinec",
						"Lonjica",
						"Luka",
						"Lukarishce",
						"Lukavec",
						"Lupoglav",
						"Michevec",
						"Mraclin",
						"Novaki",
						"Novo Chiche",
						"Novoselec",
						"Oborovo Bistranski",
						"Oreshje",
						"Pojatno",
						"Poljanica Bistranska",
						"Prigorje Brdovechko",
						"Rakitje",
						"Rakov Potok",
						"Rude",
						"Samobor",
						"Strmec",
						"Sveta Nedelja",
						"Sveti Ivan Zelina",
						"Turopolje",
						"Velika Gorica",
						"Velika Mlaka",
						"Velika Ostrna",
						"Vrbovec",
						"Vukovina",
						"Zapreshic",
						"Zdenci Brdovechki",
					],
					stateName: "Zagreb",
				},
			],
			countryName: "Croatia (Hrvatska)",
		},
		{
			states: [
				{
					cities: [
						"Camaguey",
						"Caney",
						"Carlos Manuel de Cespedes",
						"Esmeralda",
						"Florida",
						"Guaimaro",
						"Minas",
						"Nuevitas",
						"Santa Cruz del Sur",
						"Sibanicu",
						"Vertientes",
					],
					stateName: "Camaguey",
				},
				{ cities: [], stateName: "Ciego de Avila" },
				{
					cities: ["Cienfuegos", "Cruces", "Cumanayagua", "Palmira", "Rodas"],
					stateName: "Cienfuegos",
				},
				{ cities: [], stateName: "Ciudad de la Habana" },
				{
					cities: [
						"Bayamo",
						"Campechuela",
						"Guisa",
						"Jiguani",
						"Manzanillo",
						"Media Luna",
						"Niquero",
						"Pilon",
						"Rio Cauto",
						"Yara",
					],
					stateName: "Granma",
				},
				{
					cities: ["Baracoa", "Guantanamo", "Yateras"],
					stateName: "Guantanamo",
				},
				{ cities: ["Havana"], stateName: "Habana" },
				{
					cities: [
						"Antilla",
						"Baguanos",
						"Banes",
						"Cacocum",
						"Cauto Cristo",
						"Cueto",
						"Gibara",
						"Holguin",
						"Jobabo",
						"Moa",
						"Sagua de Tanamo",
					],
					stateName: "Holguin",
				},
				{ cities: [], stateName: "Isla de la Juventud" },
				{ cities: [], stateName: "La Habana" },
				{ cities: [], stateName: "Las Tunas" },
				{
					cities: [
						"Abreus",
						"Agramonte",
						"Aguacate",
						"Aguada de Pasajeros",
						"Alacranes",
						"Bolondron",
						"Calimete",
						"Cardenas",
						"Carlos Rojas",
						"Colon",
						"Corralillo",
						"Jaguey Grande",
						"Jovellanos",
						"Juan Gualberto Gomez",
						"Los Arabos",
						"Manguito",
						"Marti",
						"Matanzas",
						"Maximo Gomez",
						"Pedro Betancourt",
						"Perico",
						"Union de Reyes",
						"Varadero",
					],
					stateName: "Matanzas",
				},
				{ cities: [], stateName: "Pinar del Rio" },
				{ cities: [], stateName: "Sancti Spiritus" },
				{ cities: [], stateName: "Santiago de Cuba" },
				{ cities: [], stateName: "Villa Clara" },
			],
			countryName: "Cuba",
		},
		{
			states: [
				{ cities: [], stateName: "Government controlled area" },
				{ cities: ["Limassol"], stateName: "Limassol" },
				{ cities: [], stateName: "Nicosia District" },
				{ cities: ["Paphos"], stateName: "Paphos" },
				{ cities: [], stateName: "Turkish controlled area" },
			],
			countryName: "Cyprus",
		},
		{
			states: [
				{ cities: [], stateName: "Central Bohemian" },
				{ cities: ["Frycovice"], stateName: "Frycovice" },
				{ cities: [], stateName: "Jihocesky Kraj" },
				{
					cities: [
						"Bechyne",
						"Blatna",
						"Cheske Budejovice",
						"Chesky Krumlov",
						"Dachice",
						"Jindrichuv Hradec",
						"Kaplice",
						"Milevsko",
						"Pisek",
						"Prachatice",
						"Protivin",
						"Sezimovo Usti",
						"Sobeslav",
						"Strakonice",
						"Tabor",
						"Trebon",
						"Tyn nad Vltavou",
						"Veseli nad Luzhnici",
						"Vimperk",
						"Vodnany",
					],
					stateName: "Jihochesky",
				},
				{
					cities: [
						"Adamov",
						"Blansko",
						"Boskovice",
						"Breclav",
						"Brno",
						"Buchovice",
						"Dubnany",
						"Hodonin",
						"Hrusky",
						"Hustopeche",
						"Ivanchice",
						"Kurim",
						"Kyjov",
						"Letovice",
						"Mikulov",
						"Moravsky Krumlov",
						"Namesht' nad Oslavou",
						"Rosice",
						"Shlapanice",
						"Slavkov u Brna",
						"Tishnov",
						"Vyshkov",
						"Znojmo",
					],
					stateName: "Jihomoravsky",
				},
				{
					cities: [
						"Ash",
						"Bozicany",
						"Cheb",
						"Chodov",
						"Frantishkovy Lazne",
						"Horni Slavkov",
						"Karlovy Vary",
						"Kraslice",
						"Kynshperk nad Ohri",
						"Marianske Lazne",
						"Nejdek",
						"Ostrov",
						"Sokolov",
					],
					stateName: "Karlovarsky",
				},
				{ cities: ["Klecany"], stateName: "Klecany" },
				{
					cities: [
						"Broumov",
						"Cherveny Kostelec",
						"Cheska Skalice",
						"Chlumec nad Cidlinou",
						"Dobrushka",
						"Dvur Kralove",
						"Habartov",
						"Holice",
						"Horice",
						"Hostinne",
						"Hradec Kralove",
						"Hronov",
						"Jaromer",
						"Jichin",
						"Kostelec nad Orlici",
						"Nachod",
						"Nova Paka",
						"Nove Mesto nad Metuji",
						"Novy Bydzhov",
						"Rychnov nad Knezhnou",
						"Trebechovice pod Orebem",
						"Trutnov",
						"Tynishte nad Orlici",
						"Upice",
						"Vrchlabi",
					],
					stateName: "Kralovehradecky",
				},
				{
					cities: [
						"Ceska Lipa",
						"Cheska Kamenice",
						"Cheska Lipa",
						"Chrastava",
						"Doksy",
						"Frydlant",
						"Hradek",
						"Jablonec",
						"Jilemnice",
						"Liberec",
						"Lomnice nad Popelkou",
						"Mimon",
						"Novy Bor",
						"Semily",
						"Tanvald",
						"Turnov",
						"Zhelezny Brod",
					],
					stateName: "Liberecky",
				},
				{ cities: ["Lipov"], stateName: "Lipov" },
				{
					cities: [
						"Bilovec",
						"Bohumin",
						"Bruntal",
						"Chesky Teshin",
						"Frenshtat",
						"Frydek-Mistek",
						"Frydlant nad Ostravici",
						"Fulnek",
						"Havirov",
						"Hluchin",
						"Hradec nad Moravice",
						"Jablunkov",
						"Karvina",
						"Koprivnice",
						"Kravare",
						"Krnov",
						"Novy Jichin",
						"Odry",
						"Opava",
						"Orlova",
						"Ostrava",
						"Petrvald",
						"Pribor",
						"Rychvald",
						"Rymarov",
						"Shenov",
						"Studenka",
						"Trinec",
						"Vitkov",
						"Vratimov",
						"Vrbno pod Pradedem",
					],
					stateName: "Moravskoslezsky",
				},
				{
					cities: [
						"Hranice",
						"Jesenik",
						"Kojetin",
						"Lipnik nad Becvou",
						"Litovel",
						"Mohelnice",
						"Olomouc",
						"Prerov",
						"Prostejov",
						"Shternberk",
						"Shumperk",
						"Unichov",
						"Zabreh",
					],
					stateName: "Olomoucky",
				},
				{
					cities: [
						"Hranice",
						"Jesenik",
						"Kojetin",
						"Lipnik nad Becvou",
						"Litovel",
						"Mohelnice",
						"Olomouc",
						"Prerov",
						"Prostejov",
						"Shternberk",
						"Shumperk",
						"Unichov",
						"Zabreh",
					],
					stateName: "Olomoucky Kraj",
				},
				{
					cities: [
						"Cheska Trebova",
						"Chocen",
						"Chrudim",
						"Chvaletice",
						"Hermanuv Mestec",
						"Hlinsko",
						"Lanshkroun",
						"Letohrad",
						"Litomyshl",
						"Moravska Trebova",
						"Pardubice",
						"Polichka",
						"Policka",
						"Prelouch",
						"Skutech",
						"Svitavy",
						"Usti nad Orlici",
						"Vysoke Myto",
						"Zhamberk",
					],
					stateName: "Pardubicky",
				},
				{
					cities: [
						"Dobrany",
						"Domazhlice",
						"Horazhd'ovice",
						"Horshovky Tyn",
						"Kdyne",
						"Klatovy",
						"Nyrany",
						"Nyrsko",
						"Plana",
						"Plzen",
						"Preshtice",
						"Radnice",
						"Rokycany",
						"Stribro",
						"Sushice",
						"Tachov",
					],
					stateName: "Plzensky",
				},
				{ cities: ["Prague", "Praha"], stateName: "Praha" },
				{ cities: ["Rajhrad"], stateName: "Rajhrad" },
				{ cities: ["Smirice"], stateName: "Smirice" },
				{ cities: [], stateName: "South Moravian" },
				{ cities: [], stateName: "Straz nad Nisou" },
				{
					cities: [
						"Benatky nad Jizerou",
						"Beneshov",
						"Beroun",
						"Brandys nad Labem-Stara Bolesl",
						"Chaslav",
						"Chavaletice",
						"Chelakovice",
						"Chesky Brod",
						"Dobrish",
						"Horovice",
						"Kladno",
						"Kolin",
						"Kralupy nad Vltavou",
						"Kutna Hora",
						"Lysa nad Labem",
						"Melnik",
						"Mlada Boleslav",
						"Mnichovo Hradishte",
						"Neratovice",
						"Nove Strasheci",
						"Nymburk",
						"Podebrady",
						"Pribram",
						"Rakovnik",
						"Richany",
						"Rousinov",
						"Roztoky",
						"Sedlcany",
						"Slany",
						"Stochov",
						"Vlashim",
						"Zruch nad Sazavou",
					],
					stateName: "Stredochesky",
				},
				{ cities: ["Unicov"], stateName: "Unicov" },
				{
					cities: [
						"Bilina",
						"Chomutov",
						"Dechin",
						"Dubi",
						"Duchcov",
						"Jilove",
						"Jirkov",
						"Kadan",
						"Klasterec nad Ohri",
						"Krupka",
						"Litomerice",
						"Litvinov",
						"Louny",
						"Lovosice",
						"Mezibori",
						"Most",
						"Osek",
						"Podborany",
						"Roudnice",
						"Rumburk",
						"Shluknov",
						"Shteti",
						"Teplice",
						"Usti",
						"Varnsdorf",
						"Zatec",
					],
					stateName: "Ustecky",
				},
				{ cities: ["Valletta"], stateName: "Valletta" },
				{ cities: ["Velesin"], stateName: "Velesin" },
				{
					cities: [
						"Bystrice nad Pernshtejnem",
						"Chotebor",
						"Havlichkuv Brod",
						"Humpolec",
						"Jihlava",
						"Ledech",
						"Moravske Budejovice",
						"Nove Mesto na Morave",
						"Okrisky",
						"Pacov",
						"Pelhrimov",
						"Polna",
						"Svetla nad Sazavou",
						"Telch",
						"Trebich",
						"Tresht'",
						"Velke Mezirichi",
						"Zhd'ar",
					],
					stateName: "Vysochina",
				},
				{
					cities: [
						"Brumov",
						"Bystrice pod Hostynem",
						"Chropyne",
						"Holeshov",
						"Hulin",
						"Kromerizh",
						"Kunovice",
						"Napajedla",
						"Otrokovice",
						"Rozhnov",
						"Roznov pod Radhostem",
						"Slavicin",
						"Slusovice",
						"Stare Mesto",
						"Strazhnice",
						"Uherske Hradishte",
						"Uhersky Brod",
						"Valashske Klobouky",
						"Valashske Mezirichi",
						"Veseli nad Moravou",
						"Vsetin",
						"Zborovice",
						"Zlin",
					],
					stateName: "Zlinsky",
				},
			],
			countryName: "Czech Republic",
		},
		{
			states: [
				{
					cities: [
						"Aarhus",
						"Allingabro",
						"Arhus",
						"Assentoft",
						"Auning",
						"Beder",
						"Brabrand",
						"Ebeltoft",
						"Framlev",
						"Galten",
						"Grenaa",
						"Hadsten",
						"Hammel",
						"Hinnerup",
						"Hjortshoj",
						"Horning",
						"Hornslet",
						"Kolt",
						"Langa",
						"Logten",
						"Lystrup",
						"Malling",
						"Mariager",
						"Marslet",
						"Odder",
						"Randers",
						"Risskov",
						"Ronde",
						"Ry",
						"Ryomgard",
						"Sabro",
						"Silkeborg",
						"Skanderborg",
						"Skovby",
						"Soften",
						"Solbjerg",
						"Spentrup",
						"Stavtrup",
						"Stilling",
						"Svejbak",
						"Tranbjerg",
						"Trige",
						"Virklund",
					],
					stateName: "Arhus",
				},
				{
					cities: ["Aakirkeby", "Allinge-Sandvig", "Nexo", "Ronne"],
					stateName: "Bornholm",
				},
				{
					cities: [
						"Allerod",
						"Birkerod",
						"Blovstrod",
						"Espergarde",
						"Farum",
						"Fredensborg",
						"Frederikssund",
						"Frederiksvark",
						"Ganlose",
						"Gilleleje",
						"Grasted",
						"Hellebak",
						"Helsinge",
						"Helsingor",
						"Hillerod",
						"Hornbak",
						"Horsholm",
						"Humlebak",
						"Hundested",
						"Jagerspris",
						"Kvistgaard",
						"Lillerod",
						"Liseleje",
						"Lynge",
						"Niva",
						"Nodebo",
						"Olstykke",
						"Skibby",
						"Slangerup",
						"Stavnsholt",
						"Stenlose",
						"Valby",
						"Vekso",
					],
					stateName: "Frederiksborg",
				},
				{
					cities: [
						"Aarup",
						"Arslev",
						"Assens",
						"Bellinge",
						"Blommenslyst",
						"Bogense",
						"Brenderup",
						"Broby",
						"Bullerup",
						"Ejby",
						"Faaborg",
						"Glamsbjerg",
						"Haarby",
						"Hojby",
						"Kerteminde",
						"Langeskov",
						"Marstal",
						"Middelfart",
						"Munkebo",
						"Neder Holluf",
						"Norre Aaby",
						"Nyborg",
						"Odense",
						"Otterup",
						"Ringe",
						"Rudkobing",
						"Sankt Klemens",
						"Seden",
						"Sonderso",
						"Stige",
						"Strib",
						"Svendborg",
						"Thuro",
						"Tommerup",
						"Ullerslev",
						"Vindeby",
						"Vissenbjerg",
					],
					stateName: "Fyn",
				},
				{
					cities: ["Ballerup", "Brondby", "Stenlose", "Vallensbaek"],
					stateName: "Hovedstaden",
				},
				{
					cities: [
						"Dragor",
						"Flong",
						"Gentofte",
						"Glostrup",
						"Herlev",
						"Hvidovre",
						"Ishoj",
						"Kastrup",
						"Lyngby",
						"Malov",
						"Smorumnedre",
						"Taastrup",
						"Trorod",
						"Vanlose",
						"Varlose",
					],
					stateName: "Kobenhavn",
				},
				{ cities: [], stateName: "Kobenhavns Amt" },
				{ cities: [], stateName: "Kobenhavns Kommune" },
				{
					cities: [
						"Aabybro",
						"Aalborg",
						"Aars",
						"Arden",
						"Bindslev",
						"Bronderslev",
						"Brovst",
						"Dronninglund",
						"Farso",
						"Fjerritslev",
						"Frederikshavn",
						"Frejlev",
						"Gistrup",
						"Gorlose",
						"Hadsund",
						"Hals",
						"Hirtshals",
						"Hjallerup",
						"Hjorring",
						"Hobro",
						"Kas",
						"Klarup",
						"Logstor",
						"Nibe",
						"Norresundby",
						"NÃÆÃÆÃâÃÂ¸rresundby",
						"Pandrup",
						"Saby",
						"Sindal",
						"Skagen",
						"Skorping",
						"Storvorde",
						"Stovring",
						"Strandby",
						"Sulsted",
						"Svenstrup",
						"Tars",
						"Tranekaer",
						"Vadum",
						"Vestbjerg",
						"Vester Hassing",
						"Vodskov",
						"Vra",
					],
					stateName: "Nordjylland",
				},
				{
					cities: [
						"Ansager",
						"Billund",
						"Bramming",
						"Brorup",
						"Esbjerg",
						"Grindsted",
						"Holsted",
						"Nordby",
						"Oksbol",
						"Olgod",
						"Ribe",
						"Tjareborg",
						"Varde",
						"Vejen",
						"Vorbasse",
					],
					stateName: "Ribe",
				},
				{
					cities: [
						"Aulum",
						"Bording",
						"Brande",
						"Gjellerup Kirkeby",
						"Hammerum",
						"Harboore",
						"Herning",
						"Holstebro",
						"Hvide Sande",
						"Ikast",
						"Kibak",
						"Lemvig",
						"Lind",
						"Ringkobing",
						"Skaerbaek",
						"Skjern",
						"Snejbjerg",
						"Struer",
						"Sunds",
						"Tarm",
						"Thyboron",
						"Ulfborg",
						"Videbak",
						"Vildbjerg",
						"Vinderup",
					],
					stateName: "Ringkobing",
				},
				{ cities: ["Roervig"], stateName: "Roervig" },
				{
					cities: [
						"Bjaverskov",
						"Borup",
						"Ejby",
						"Greve Strand",
						"Gundsomagle",
						"Harlev",
						"Havdrup",
						"Hvalso",
						"Jyllinge",
						"Koge",
						"Lejre",
						"Osted",
						"Roskilde",
						"Solrod",
						"Stroby Egede",
						"Svogerslev",
						"Tune",
						"Viby",
						"Vindinge",
					],
					stateName: "Roskilde",
				},
				{ cities: ["Glyngore"], stateName: "Roslev" },
				{ cities: ["Karise", "Naestved"], stateName: "Sjaelland" },
				{ cities: ["Soeborg"], stateName: "Soeborg" },
				{
					cities: [
						"Aabenraa",
						"Aarsleve",
						"Augustenborg",
						"Broager",
						"Christiansfeld",
						"Dybbol",
						"Gram",
						"Grasten",
						"Guderup",
						"Haderslev",
						"Horuphav",
						"Krusa",
						"Logumkloster",
						"Lojt Kirkeby",
						"Nordborg",
						"Padborg",
						"Rodding",
						"Rodekro",
						"Skarbak",
						"Sonderborg",
						"Starup",
						"Tinglev",
						"Toftlund",
						"Tonder",
						"Vojens",
					],
					stateName: "Sonderjylland",
				},
				{
					cities: [
						"Fakse",
						"Fakse Ladeplads",
						"Fensmark",
						"Holeby",
						"Maribo",
						"Nakskov",
						"Nastved",
						"Neder Vindinge",
						"Norre Alslev",
						"Nykobing",
						"Nyrad",
						"Orslev",
						"Prasto",
						"Rodby",
						"Rodbyhavn",
						"Ronnede",
						"Sakskobing",
						"Stege",
						"Store Heddinge",
						"Stubbekobing",
						"Sundby",
						"Vordingborg",
					],
					stateName: "Storstrom",
				},
				{ cities: ["Bylderup-Bov", "Fovling"], stateName: "Syddanmark" },
				{ cities: ["Toelloese"], stateName: "Toelloese" },
				{
					cities: [
						"Borkop",
						"Bradstrup",
						"Brejning",
						"Egtved",
						"Fredericia",
						"Give",
						"Hedensted",
						"Horsens",
						"Jelling",
						"Juelsminde",
						"Kolding",
						"Lunderskov",
						"Snoghoj",
						"Sonder Bjert",
						"Taulov",
						"Torring",
						"Vamdrup",
						"Vejle",
					],
					stateName: "Vejle",
				},
				{
					cities: [
						"Asnas",
						"Dianalund",
						"Forlev",
						"Frederiksberg",
						"Fuglebjerg",
						"Gorlev",
						"Haslev",
						"Holbaek",
						"Hong",
						"Horve",
						"Jyderup",
						"Kalundborg",
						"Korsor",
						"Nykobing",
						"Ringsted",
						"Skalskor",
						"Slagelse",
						"Soro",
						"Svebolle",
						"Svinninge",
						"Tollose",
						"Vipperod",
					],
					stateName: "Vestsjalland",
				},
				{
					cities: [
						"Aalestrup",
						"Bjerringbro",
						"Hanstholm",
						"Hojslev",
						"Hurup",
						"Karup",
						"Kjellerup",
						"Nykobing",
						"Skive",
						"Stoholm",
						"Thisted",
						"Ulstrup",
						"Viborg",
					],
					stateName: "Viborg",
				},
			],
			countryName: "Denmark",
		},
		{
			states: [
				{ cities: [], stateName: "'Ali Sabih" },
				{ cities: ["Dikhil"], stateName: "Dikhil" },
				{ cities: ["Jibuti"], stateName: "Jibuti" },
				{ cities: ["Tajurah"], stateName: "Tajurah" },
				{ cities: ["Ubuk"], stateName: "Ubuk" },
			],
			countryName: "Djibouti",
		},
		{
			states: [
				{ cities: [], stateName: "Saint Andrew" },
				{ cities: [], stateName: "Saint David" },
				{ cities: [], stateName: "Saint George" },
				{ cities: [], stateName: "Saint John" },
				{ cities: [], stateName: "Saint Joseph" },
				{ cities: [], stateName: "Saint Luke" },
				{ cities: [], stateName: "Saint Mark" },
				{ cities: [], stateName: "Saint Patrick" },
				{ cities: [], stateName: "Saint Paul" },
				{ cities: [], stateName: "Saint Peter" },
			],
			countryName: "Dominica",
		},
		{
			states: [
				{ cities: ["Azua", "Sabana Yegua"], stateName: "Azua" },
				{ cities: ["Neyba", "Tamayo"], stateName: "Bahoruco" },
				{ cities: ["Barahona", "Cabral", "El Penon"], stateName: "Barahona" },
				{ cities: ["Dajabon"], stateName: "Dajabon" },
				{ cities: [], stateName: "Distrito Nacional" },
				{
					cities: ["Las Guaranas", "Pimentel", "San Francisco de Macoris"],
					stateName: "Duarte",
				},
				{ cities: [], stateName: "El Seybo" },
				{ cities: [], stateName: "Elias Pina" },
				{ cities: ["Moca"], stateName: "Espaillat" },
				{ cities: [], stateName: "Hato Mayor" },
				{ cities: ["Duverge", "Jimani"], stateName: "Independencia" },
				{ cities: [], stateName: "La Altagracia" },
				{ cities: [], stateName: "La Romana" },
				{ cities: [], stateName: "La Vega" },
				{ cities: [], stateName: "Maria Trinidad Sanchez" },
				{ cities: [], stateName: "Monsenor Nouel" },
				{ cities: [], stateName: "Monte Cristi" },
				{ cities: [], stateName: "Monte Plata" },
				{ cities: ["Pedernales"], stateName: "Pedernales" },
				{ cities: ["Bani", "Ocoa"], stateName: "Peravia" },
				{ cities: [], stateName: "Puerto Plata" },
				{ cities: ["Salcedo"], stateName: "Salcedo" },
				{ cities: ["Samana", "Sanchez"], stateName: "Samana" },
				{ cities: [], stateName: "San Cristobal" },
				{ cities: [], stateName: "San Juan" },
				{ cities: [], stateName: "San Pedro de Macoris" },
				{ cities: [], stateName: "Sanchez Ramirez" },
				{
					cities: ["Santiago", "Tamboril", "Villa Bisono"],
					stateName: "Santiago",
				},
				{
					cities: ["Santiago", "Tamboril", "Villa Bisono"],
					stateName: "Santiago Rodriguez",
				},
				{ cities: ["Esperanza", "Mao"], stateName: "Valverde" },
			],
			countryName: "Dominican Republic",
		},
		{
			states: [
				{ cities: ["Aileu"], stateName: "Aileu" },
				{ cities: ["Ainaro"], stateName: "Ainaro" },
				{ cities: ["Pante Macassar"], stateName: "Ambeno" },
				{ cities: ["Baucau"], stateName: "Baucau" },
				{ cities: ["Auba", "Lolotoi", "Maliana"], stateName: "Bobonaro" },
				{ cities: [], stateName: "Cova Lima" },
				{ cities: ["Dare", "Dili", "Metinaro"], stateName: "Dili" },
				{ cities: ["Ermera"], stateName: "Ermera" },
				{ cities: ["Lautem", "Los Palos"], stateName: "Lautem" },
				{ cities: ["Bazartete", "Liquica"], stateName: "Liquica" },
				{ cities: ["Manatuto"], stateName: "Manatuto" },
				{ cities: ["Same"], stateName: "Manufahi" },
				{ cities: ["Viqueque"], stateName: "Viqueque" },
			],
			countryName: "East Timor",
		},
		{
			states: [
				{ cities: ["Cuenca", "Gualaceo"], stateName: "Azuay" },
				{ cities: ["Guaranda", "San Miguel"], stateName: "Bolivar" },
				{ cities: ["Azogues", "Canar", "La Troncal"], stateName: "Canar" },
				{ cities: ["El Angel", "San Gabriel", "Tulcan"], stateName: "Carchi" },
				{ cities: ["Alausi", "Guano", "Riobamba"], stateName: "Chimborazo" },
				{
					cities: ["La Mana", "Latacunga", "Pujili", "San Miguel", "Saquisili"],
					stateName: "Cotopaxi",
				},
				{ cities: [], stateName: "El Oro" },
				{
					cities: [
						"Esmeraldas",
						"Muisne",
						"Rosa Zarate",
						"San Lorenzo",
						"Valdez",
					],
					stateName: "Esmeraldas",
				},
				{
					cities: ["Puerto Ayora", "Puerto Baquerizo Moreno", "San Cristobal"],
					stateName: "Galapagos",
				},
				{
					cities: [
						"Alfredo Baquerizo Moreno",
						"Balao",
						"Balzar",
						"Colimes",
						"Coronel Mariduena",
						"Daule",
						"El Salitre",
						"El Triunfo",
						"Eloy Alfaro",
						"Guayaquil",
						"La Libertad",
						"Lomas de Sargentillo",
						"Mapasingue",
						"Milagro",
						"Naranjal",
						"Naranjito",
						"Palestina",
						"Pedro Carbo",
						"Playas",
						"Salinas",
						"Samborondon",
						"Santa Elena",
						"Santa Lucia",
						"Velasco Ibarra",
						"Yaguachi",
					],
					stateName: "Guayas",
				},
				{
					cities: ["Atuntaqui", "Cotacachi", "Ibarra", "Otavalo", "Pimampiro"],
					stateName: "Imbabura",
				},
				{
					cities: [
						"Alamor",
						"Cariamanga",
						"Catacocha",
						"Catamayo",
						"Celica",
						"Loja",
						"Macara",
					],
					stateName: "Loja",
				},
				{ cities: [], stateName: "Los Rios" },
				{
					cities: [
						"Bahia de Caraquez",
						"Calceta",
						"Chone",
						"El Carmen",
						"Jipijapa",
						"Junin",
						"Manta",
						"Montecristi",
						"Pajan",
						"Pedernales",
						"Portoviejo",
						"Rocafuerte",
						"Santa Ana",
						"Sucre",
						"Tosagua",
					],
					stateName: "Manabi",
				},
				{ cities: [], stateName: "Morona Santiago" },
				{ cities: ["Archidona", "Tena"], stateName: "Napo" },
				{ cities: ["Orellana"], stateName: "Orellana" },
				{ cities: ["Puyo"], stateName: "Pastaza" },
				{
					cities: [
						"Cayambe",
						"Machachi",
						"Quito",
						"Sangolqui",
						"Santo Domingo",
					],
					stateName: "Pichincha",
				},
				{ cities: ["Nueva Loja", "Shushufindi"], stateName: "Sucumbios" },
				{
					cities: ["Ambato", "Banos", "Pelileo", "Pillaro"],
					stateName: "Tungurahua",
				},
				{ cities: [], stateName: "Zamora Chinchipe" },
			],
			countryName: "Ecuador",
		},
		{
			states: [
				{
					cities: ["Aswan", "Daraw", "Kawm Umbu", "an-Nasir"],
					stateName: "Aswan",
				},
				{
					cities: [
						"Abnub",
						"Abu Tij",
						"Asyut",
						"Bani Muhammadiyat",
						"Dayrut",
						"Dayrut-ash-Sharif",
						"Manfalut",
						"Musha",
						"Sahil Salim",
						"Sanabu",
						"Umm-al-Qusur",
						"al-Badari",
						"al-Qusiyah",
						"an-Nukhaylah",
					],
					stateName: "Asyut",
				},
				{ cities: [], stateName: "Bani Suwayf" },
				{ cities: [], stateName: "Bur Sa'id" },
				{
					cities: [
						"6th of October City",
						"Ataba",
						"Cairo",
						"Nasr",
						"Nasr City",
						"Obour City",
					],
					stateName: "Cairo",
				},
				{
					cities: [
						"'Izbat-al-Burj",
						"Damietta",
						"Dumyat",
						"El-Zarka",
						"Faraskur",
						"Kafr Sa'd",
						"Kafr-al-Battikh",
						"az-Zarqa",
					],
					stateName: "Dumyat",
				},
				{
					cities: [
						"Biyala",
						"Disuq",
						"Fuwah",
						"Kafr-al-Jara'idah",
						"Kafr-ash-Shaykh",
						"Mutubis",
						"Qallin",
						"Sidi Salim",
						"al-Burj",
						"al-Burullus",
						"al-Haddadi",
						"al-Hamul",
					],
					stateName: "Kafr-ash-Shaykh",
				},
				{
					cities: [
						"Marsa Matruh",
						"Nasr",
						"Sidi Barrani",
						"Zawiyat Shammas",
						"ad-Da'ba",
					],
					stateName: "Matruh",
				},
				{ cities: [], stateName: "Muhafazat ad Daqahliyah" },
				{ cities: [], stateName: "Muhafazat al Fayyum" },
				{ cities: [], stateName: "Muhafazat al Gharbiyah" },
				{ cities: [], stateName: "Muhafazat al Iskandariyah" },
				{ cities: [], stateName: "Muhafazat al Qahirah" },
				{
					cities: [
						"Armant",
						"Asfun-al-Mata'inah",
						"Dandarah",
						"Dishna",
						"Farshut",
						"Hijazah",
						"Hiw",
						"Idfu",
						"Isna",
						"Kiman-al-Mata'inah",
						"Naj' Hammadi",
						"Naqadah",
						"Qift",
						"Qina",
						"Qus",
						"ad-Dabbiyah",
						"ad-Dayr",
						"al-Ballas",
						"al-Karnak",
						"al-Waqf",
						"ar-Radisiyat-al-Bahriyah",
					],
					stateName: "Qina",
				},
				{
					cities: [
						"Akhmim",
						"Awlad Tawq Sharq",
						"Dar-as-Salam",
						"Jirja",
						"Juhaynah",
						"Sawhaj",
						"Tahta",
						"Tima",
						"al-Balyana",
						"al-Manshah",
						"al-Maragah",
					],
					stateName: "Sawhaj",
				},
				{ cities: [], stateName: "Sina al-Janubiyah" },
				{ cities: [], stateName: "Sina ash-Shamaliyah" },
				{
					cities: [
						"Aja",
						"Bahut",
						"Bilqas",
						"Dikirnis",
						"Minyat-an-Nasr",
						"Mit Gamr",
						"Shirbin",
						"Talkha",
						"al-Jamaliyah",
						"al-Ma'sarah",
						"al-Mansurah",
						"al-Manzilah",
						"al-Matariyah",
						"as-Sinbillawayn",
					],
					stateName: "ad-Daqahliyah",
				},
				{
					cities: ["Ras Gharib", "Safaja", "al-Ghardaqah", "al-Qusayr"],
					stateName: "al-Bahr-al-Ahmar",
				},
				{
					cities: [
						"Abu Hummus",
						"Abu al-Matamir",
						"Buturis",
						"Damanhur",
						"Edfina",
						"Hawsh 'Isa",
						"Idku",
						"Ityay-al-Barud",
						"Kafr Salim",
						"Kafr-ad-Dawwar",
						"Kawm Hamada",
						"Nubaria",
						"Rashid",
						"Shubra Khit",
						"Zawiyat Sidi Gazi",
						"ad-Dilinjat",
						"al-Kawm-al-Akhdar",
						"al-Mahmudiyah",
						"ar-Rahmaniyah",
					],
					stateName: "al-Buhayrah",
				},
				{
					cities: [
						"Fidimin",
						"Ibshaway",
						"Itsa",
						"Qasr Qarun",
						"Sanhur",
						"Sinnuris",
						"Tamiyah",
						"al-Fayyum",
					],
					stateName: "al-Fayyum",
				},
				{
					cities: [
						"Abyar",
						"Basyun",
						"Kafr-az-Zayyat",
						"Mahallat Marhum",
						"Nisf Thani Bashbish",
						"Qutur",
						"Samannud",
						"Tanta",
						"Zifta",
						"ad-Daljamun",
						"al-Mahallah al-Kubra",
						"as-Santah",
					],
					stateName: "al-Gharbiyah",
				},
				{
					cities: ["Agamy", "al-Iskandariyah", "al-Maks"],
					stateName: "al-Iskandariyah",
				},
				{
					cities: ["Fa'id", "Sarabiyum", "al-Ismailiyah"],
					stateName: "al-Ismailiyah",
				},
				{
					cities: [
						"Atfih",
						"Awsim",
						"Giza",
						"Madinat Sittah Uktubar",
						"Nahya",
						"Saqqarah",
						"al-'Ayyat",
						"al-Badrashayn",
						"al-Hawamidiyah",
						"al-Jizah",
						"al-Mansuriyah",
						"al-Wahat-al-Bahriyah",
						"as-Saff",
					],
					stateName: "al-Jizah",
				},
				{
					cities: [
						"Ashmun",
						"Birkat-as-Sab'",
						"Milij",
						"Minuf",
						"Quwaysina",
						"Shibin-al-Kawm",
						"Sirs-al-Layyanah",
						"Tala",
						"al-Bajur",
						"al-Batanun",
						"ash-Shuhada",
					],
					stateName: "al-Minufiyah",
				},
				{
					cities: [
						"Abu Qurqas",
						"Bani Mazar",
						"Dayr Mawas",
						"Magagah",
						"Mallawi",
						"Matay",
						"Samalut",
						"Tallah",
						"Tandah",
						"al-Anayim",
						"al-Fikriyah",
						"al-Minya",
						"ar-Rawdah",
					],
					stateName: "al-Minya",
				},
				{
					cities: ["Badr City", "Heliopolis", "al-Qahira"],
					stateName: "al-Qahira",
				},
				{
					cities: [
						"Abu Za'bal",
						"Banha",
						"Qalyub",
						"Shubra al-Khaymah",
						"Sibin-al-Qanatir",
						"Tukh",
						"al-Khankah",
						"al-Qanatir-al-Khayriyah",
					],
					stateName: "al-Qalyubiyah",
				},
				{ cities: ["al-Uqsur"], stateName: "al-Uqsur" },
				{ cities: [], stateName: "al-Wadi al-Jadid" },
				{ cities: ["as-Suways"], stateName: "as-Suways" },
				{
					cities: [
						"Abu Hammad",
						"Abu Kabir",
						"Bilbays",
						"Diyarb Najm",
						"Faqus",
						"Hihya",
						"Kafr Saqr",
						"Mashtul-as-Suq",
						"Minyat-al-Qamh",
						"al-Ashir mir-Ramadan",
						"al-Husayniyah",
						"al-Ibrahimiyah",
						"al-Qanayat",
						"al-Qassasin",
						"al-Qurayn",
						"as-Salihiyah",
						"at-Tall-al-Kabir",
						"az-Zaqaziq",
					],
					stateName: "ash-Sharqiyah",
				},
			],
			countryName: "Egypt",
		},
		{
			states: [
				{
					cities: [
						"Ahuachapan",
						"Atiquizaya",
						"Concepcion de Ataco",
						"Guaymango",
						"Jujutla",
						"San Francisco Menendez",
						"Tacuba",
					],
					stateName: "Ahuachapan",
				},
				{
					cities: ["Ilobasco", "Sensuntepeque", "Victoria"],
					stateName: "Cabanas",
				},
				{
					cities: [
						"Chalatenango",
						"La Palma",
						"Nueva Concepcion",
						"San Francisco Morazan",
					],
					stateName: "Chalatenango",
				},
				{
					cities: [
						"Cojutepeque",
						"San Pedro Perulapan",
						"Suchitoto",
						"Tecoluca",
						"Tenancingo",
					],
					stateName: "Cuscatlan",
				},
				{ cities: [], stateName: "La Libertad" },
				{ cities: [], stateName: "La Paz" },
				{ cities: [], stateName: "La Union" },
				{
					cities: [
						"Cacaopera",
						"Corinto",
						"Gotera",
						"Guatajiagua",
						"Jocoro",
						"Sociedad",
					],
					stateName: "Morazan",
				},
				{ cities: [], stateName: "San Miguel" },
				{ cities: [], stateName: "San Salvador" },
				{ cities: [], stateName: "San Vicente" },
				{ cities: [], stateName: "Santa Ana" },
				{
					cities: [
						"Acajutla",
						"Armenia",
						"Izalco",
						"Juayua",
						"Nahuizalco",
						"San Antonio del Monte",
						"San Julian",
						"Sonsonate",
						"Sonzacate",
					],
					stateName: "Sonsonate",
				},
				{
					cities: [
						"Berlin",
						"Concepcion Batres",
						"Estanzuelas",
						"Jiquilisco",
						"Jucuapa",
						"Jucuaran",
						"Ozatlan",
						"Puerto El Triunfo",
						"San Agustin",
						"Santa Elena",
						"Santiago de Maria",
						"Usulutan",
					],
					stateName: "Usulutan",
				},
			],
			countryName: "El Salvador",
		},
		{
			states: [
				{ cities: ["Pale"], stateName: "Annobon" },
				{ cities: [], stateName: "Bioko Norte" },
				{ cities: [], stateName: "Bioko Sur" },
				{ cities: [], stateName: "Centro Sur" },
				{ cities: ["Ebebiyin", "Mikomeseng"], stateName: "Kie-Ntem" },
				{ cities: ["Bata", "Mbini"], stateName: "Litoral" },
				{
					cities: ["Aconibe", "Anisoc", "Mongomo", "Nsok"],
					stateName: "Wele-Nzas",
				},
			],
			countryName: "Equatorial Guinea",
		},
		{
			states: [
				{ cities: ["Keren"], stateName: "Anseba" },
				{ cities: ["Addi Kwala", "Addi Ugri"], stateName: "Debub" },
				{
					cities: ["Asseb", "Beylul", "Edd", "Mersa Fatma"],
					stateName: "Debub-Keih-Bahri",
				},
				{ cities: ["Ak'ordat", "Barentu", "Teseney"], stateName: "Gash-Barka" },
				{
					cities: ["Asmara", "Ginda", "Himbirti", "Nefasit"],
					stateName: "Maekel",
				},
				{
					cities: ["Addi K'eyih", "Dek'emhare", "Mitsiwa", "Sen'afe"],
					stateName: "Semien-Keih-Bahri",
				},
			],
			countryName: "Eritrea",
		},
		{
			states: [
				{
					cities: [
						"Aasmae",
						"Aaviku",
						"Aegviidu",
						"Aigrumae",
						"Aila",
						"Alavere",
						"Alliku",
						"Amari",
						"Anija",
						"Ardu",
						"Arukula",
						"Aruvalla",
						"Assaku",
						"Ellamaa",
						"Haabneeme",
						"Habaja",
						"Haiba",
						"Haljava",
						"Hara",
						"Harju-Risti",
						"Harku",
						"Harkujarve",
						"Harma",
						"Huuru",
						"Ilmandu",
						"Iru",
						"Jagala",
						"Jalgimae",
						"Jarsi",
						"Jarvekula",
						"Jogisoo",
						"Juri",
						"Kaasiku",
						"Kaberneeme",
						"Kahala",
						"Kalesi",
						"Kallavere",
						"Karjakula",
						"Karla",
						"Kasepere",
						"Kasispea",
						"Kehra",
						"Keila",
						"Keila-Joa",
						"Kelvingi",
						"Kiia",
						"Kiili",
						"Kiisa",
						"Kiiu",
						"Klooga",
						"Kloogaranna",
						"Kohatu",
						"Kolga",
						"Kolga-Aabla",
						"Kolgakula",
						"Konnu",
						"Kose",
						"Kose-Uuemoisa",
						"Kostivere",
						"Krei",
						"Kuivajoe",
						"Kumna",
						"Kurtna",
						"Kuusalu",
						"Laabi",
						"Laagri",
						"Lagedi",
						"Laitse",
						"Laulasmaa",
						"Lehetu",
						"Lehola",
						"Lehtmetsa",
						"Leppneeme",
						"Liikva",
						"Lilli",
						"Lohusalu",
						"Loksa",
						"Lokuti",
						"Loo",
						"Lubja",
						"Luige",
						"Maardu",
						"Maidla",
						"Manniku",
						"Metsakasti",
						"Metsanurme",
						"Miiduranna",
						"Munalaskme",
						"Muraste",
						"Muuga",
						"Nabala",
						"Neeme",
						"Ohtu",
						"Ojasoo",
						"Oru",
						"Padise",
						"Pae",
						"Paekna",
						"Pajupea",
						"Paldiski",
						"Palvere",
						"Parispea",
						"Patika",
						"Paunkula",
						"Peetri",
						"Peningi",
						"Perila",
						"Pikva",
						"Pillapalu",
						"Pringi",
						"Puunsi",
						"Raasiku",
						"Rae",
						"Randvere",
						"Rannamoisa",
						"Ravila",
						"Riisipere",
						"Rohuneeme",
						"Roobuka",
						"Ruila",
						"Rummu",
						"Saha",
						"Saku",
						"Saue",
						"Saula",
						"Saunja",
						"Suurpea",
						"Suurupi",
						"Tabasalu",
						"Tagadi",
						"Tagametsa",
						"Tallinn",
						"Tammneeme",
						"Tiskre",
						"Todva",
						"Turba",
						"Turisalu",
						"Tutermaa",
						"Tuula",
						"Tuulna",
						"Uksnurme",
						"Ulejoe",
						"Uuri",
						"Uuskula",
						"Vaana",
						"Vaana-Joesuu",
						"Vaida",
						"Vaidasoo",
						"Valingu",
						"Valkla",
						"Vanamoisa",
						"Vardja",
						"Vasalemma",
						"Vaskjala",
						"Vatsla",
						"Veskikula",
						"Vihasoo",
						"Viimsi",
						"Viinistu",
						"Viti",
					],
					stateName: "Harju",
				},
				{
					cities: [
						"Emmaste",
						"Jausa",
						"Kaina",
						"Kardla",
						"Korgessaare",
						"Lauka",
						"Lope",
						"Mannamaa",
						"Putkaste",
						"Suuremoisa",
					],
					stateName: "Hiiu",
				},
				{
					cities: [
						"Aa",
						"Alajoe",
						"Aseri",
						"Avinurme",
						"Edise",
						"Erra",
						"Iisaku",
						"Jarve",
						"Johvi",
						"Kahula",
						"Kiikla",
						"Kivioli",
						"Kohtla-Jarve",
						"Kohtla-Nomme",
						"Konju",
						"Kose",
						"Kuremae",
						"Kurtna",
						"Lohusuu",
						"Luganuse",
						"Maetaguse",
						"Maidla",
						"Narva",
						"Narva-Joesuu",
						"Olgina",
						"Pagari",
						"Puhajoe",
						"Purtse",
						"Pussi",
						"Rannu",
						"Saka",
						"Savala",
						"Sillamae",
						"Sinimae",
						"Soldina",
						"Sompa",
						"Sonda",
						"Tammiku",
						"Toila",
						"Tudulinna",
						"Ulvi",
						"Vaivara",
						"Varja",
						"Vasavere",
						"Voka",
					],
					stateName: "Ida-Viru",
				},
				{
					cities: [
						"Ahula",
						"Aiamaa",
						"Albu",
						"Ambla",
						"Anari",
						"Anna",
						"Aravete",
						"Ervita",
						"Imavere",
						"Janeda",
						"Jarva-Jaani",
						"Jogisoo",
						"Jootme",
						"Kaalepi",
						"Kabala",
						"Kahala",
						"Karavete",
						"Karevere",
						"Karinu",
						"Kasukonna",
						"Kirna",
						"Koeru",
						"Koigi",
						"Kolu",
						"Kuksema",
						"Laupa",
						"Lehtse",
						"Lokuta",
						"Loola",
						"Muusleri",
						"Oisu",
						"Paide",
						"Painurme",
						"Peetri",
						"Poikva",
						"Reopalu",
						"Retla",
						"Roa",
						"Roosna",
						"Roosna-Alliku",
						"Sarevere",
						"Sargvere",
						"Taikse",
						"Tarbja",
						"Turi",
						"Turi-Alliku",
						"Vaatsa",
						"Vahukula",
						"Vao",
						"Viisu",
						"Villevere",
					],
					stateName: "Jarva",
				},
				{
					cities: [
						"Adavere",
						"Esku",
						"Harjanurme",
						"Jogeva",
						"Kaarepere",
						"Kalana",
						"Kalme",
						"Kamari",
						"Karde",
						"Kasepaa",
						"Kassinurme",
						"Konnu",
						"Kudina",
						"Kukita",
						"Kuremaa",
						"Kurista",
						"Lahavere",
						"Laiuse",
						"Laiusevalja",
						"Leedi",
						"Lustivere",
						"Luua",
						"Maarja",
						"Mallikvere",
						"Metsakula",
						"Mohkula",
						"Mustvee",
						"Neanurme",
						"Nova",
						"Omedu",
						"Ouna",
						"Painkula",
						"Pajusi",
						"Pala",
						"Palamuse",
						"Pataste",
						"Pauastvere",
						"Pikkjarve",
						"Pikknurme",
						"Pisisaare",
						"Poltsamaa",
						"Poora",
						"Puurmani",
						"Raabise",
						"Raja",
						"Saare",
						"Sadala",
						"Sadukula",
						"Siimusti",
						"Tabivere",
						"Tahkvere",
						"Tiheda",
						"Toikvere",
						"Torma",
						"Umbusi",
						"Vagari",
						"Vaiatu",
						"Vaike-Kamari",
						"Vaimastvere",
						"Valgma",
						"Visusti",
						"Voduvere",
						"Vohmanomme",
						"Voisiku",
						"Voldi",
						"Voore",
						"Votikvere",
					],
					stateName: "Jogeva",
				},
				{
					cities: [
						"Haapsalu",
						"Hullo",
						"Joodre",
						"Kirbla",
						"Kirimae",
						"Koluvere",
						"Komsi",
						"Kullamaa",
						"Lihula",
						"Liivi",
						"Linnamae",
						"Martna",
						"Nigula",
						"Nova",
						"Palivere",
						"Palli",
						"Panga",
						"Paralepa",
						"Piirsalu",
						"Purksi",
						"Rannakula",
						"Risti",
						"Roude",
						"Sutlepa",
						"Taebla",
						"Tuudi",
						"Uuemoisa",
						"Variku",
						"Vatla",
						"Virtsu",
					],
					stateName: "Laane",
				},
				{
					cities: [
						"Aaspere",
						"Ama",
						"Arkna",
						"Assamalla",
						"Avanduse",
						"Avispea",
						"Ebavere",
						"Eipri",
						"Essu",
						"Haljala",
						"Hulja",
						"Imastu",
						"Inju",
						"Kadapiku",
						"Kadila",
						"Kadrina",
						"Kakumae",
						"Karitsa",
						"Karu",
						"Kasmu",
						"Kihlevere",
						"Kiku",
						"Kiltsi",
						"Kohala",
						"Kunda",
						"Kuti",
						"Laekvere",
						"Lasila",
						"Lepna",
						"Levala",
						"Liigvalla",
						"Modriku",
						"Moe",
						"Moora",
						"Muuga",
						"Napi",
						"Paasvere",
						"Pajusti",
						"Pandivere",
						"Piira",
						"Pikevere",
						"Podrangu",
						"Podruse",
						"Porkuni",
						"Rahkla",
						"Rakke",
						"Rakvere",
						"Ridakula",
						"Roela",
						"Roodevalja",
						"Saase",
						"Salda",
						"Salla",
						"Simuna",
						"Someru",
						"Taaravainu",
						"Tamsalu",
						"Tapa",
						"Torma",
						"Torremae",
						"Triigi",
						"Tudu",
						"Ubja",
						"Udriku",
						"Uhtna",
						"Ulvi",
						"Undla",
						"Ussimae",
						"Uudekula",
						"Vaekula",
						"Vahakulmu",
						"Vaiatu",
						"Vaike-Maarja",
						"Vajangu",
						"Vao",
						"Veltsi",
						"Venevere",
						"Vergi",
						"Vetiku",
						"Vihula",
						"Viitna",
						"Vinni",
						"Viru-Jaagupi",
						"Viru-Kabala",
						"Viru-Nigula",
						"Vohnja",
						"Vosu",
						"Vosupere",
					],
					stateName: "Laane-Viru",
				},
				{
					cities: [
						"Ahaste",
						"Aluste",
						"Are",
						"Arumetsa",
						"Audru",
						"Eametsa",
						"Haademeeste",
						"Halinga",
						"Ikla",
						"Ilvese",
						"Jaamakula",
						"Jaarja",
						"Joesuu",
						"Joopre",
						"Kaansoo",
						"Kabli",
						"Kadjaste",
						"Kaisma",
						"Kalita",
						"Kanakula",
						"Kergu",
						"Kihlepa",
						"Kilingi-Nomme",
						"Kilksama",
						"Koima",
						"Koonga",
						"Krundikula",
						"Laadi",
						"Langerma",
						"Lavassaare",
						"Leipste",
						"Lemmetsa",
						"Lemsi",
						"Libatse",
						"Linakula",
						"Lindi",
						"Liu",
						"Lodja",
						"Lope",
						"Malda",
						"Mannikuste",
						"Massiaru",
						"Metsaaare",
						"Metsapoole",
						"Moisakula",
						"Muraka",
						"Niidu",
						"Nurme",
						"Oara",
						"Oidrema",
						"Paikuse",
						"Papsaare",
						"Parnjoe",
						"Parnu",
						"Parnu-Jaagupi",
						"Penu",
						"Piistaoja",
						"Pohara",
						"Poldeotsa",
						"Pootsi",
						"Pulli",
						"Rannametsa",
						"Ratsepa",
						"Reiu",
						"Ridalepa",
						"Rootsikula",
						"Saarde",
						"Saare",
						"Sauga",
						"Seliste",
						"Selja",
						"Seljametsa",
						"Silla",
						"Sindi",
						"Soeva",
						"Soometsa",
						"Suigu",
						"Surju",
						"Suurejoe",
						"Taali",
						"Tahkuranna",
						"Tali",
						"Tammiste",
						"Tammuru",
						"Tihemetsa",
						"Tolla",
						"Tootsi",
						"Tori",
						"Tostamaa",
						"Tousi",
						"Treimani",
						"Urge",
						"Uulu",
						"Vahenurme",
						"Vaki",
						"Vandra",
						"Varbla",
						"Veelikse",
						"Vihtra",
						"Voidu",
						"Voiste",
					],
					stateName: "Parnu",
				},
				{
					cities: [
						"Aarna",
						"Ahja",
						"Erastvere",
						"Himma",
						"Himmaste",
						"Holvandi",
						"Ihamaru",
						"Jaanimoisa",
						"Kanepi",
						"Karilatsi",
						"Karsa",
						"Karste",
						"Kauksi",
						"Kiidjarve",
						"Kostrimae",
						"Krootuse",
						"Leevaku",
						"Leevi",
						"Leevijoe",
						"Linte",
						"Lootvina",
						"Maaritsa",
						"Mammaste",
						"Metste",
						"Mikitamae",
						"Mooste",
						"Naha",
						"Orava",
						"Pahtpaa",
						"Partsi",
						"Peri",
						"Piigandi",
						"Polgaste",
						"Polva",
						"Prangli",
						"Puuri",
						"Raadama",
						"Rahumae",
						"Raigla",
						"Rapina",
						"Rasina",
						"Ristipalo",
						"Rosma",
						"Ruusa",
						"Saverna",
						"Sillapaa",
						"Soodoma",
						"Suurkula",
						"Taevaskoja",
						"Tannassilma",
						"Tilsi",
						"Treski",
						"Vaike-Rosna",
						"Valgjarve",
						"Vana-Koiola",
						"Vardja",
						"Varska",
						"Vastse-Kuuste",
						"Veriora",
						"Viluste",
						"Voopsu",
					],
					stateName: "Polva",
				},
				{
					cities: [
						"Aespa",
						"Alu",
						"Eidapere",
						"Hageri",
						"Hagudi",
						"Haimre",
						"Hertu",
						"Iira",
						"Ingliste",
						"Jarlepa",
						"Jarvakandi",
						"Juuru",
						"Kaerepere",
						"Kaiu",
						"Kalbu",
						"Karu",
						"Kasti",
						"Keava",
						"Kehtna",
						"Kivi-Vigala",
						"Kodila",
						"Kohila",
						"Kuimetsa",
						"Kuusiku",
						"Laukna",
						"Lelle",
						"Lipa",
						"Lohu",
						"Lokuta",
						"Maidla",
						"Marjamaa",
						"Masti",
						"Moisamaa",
						"Naravere",
						"Orgita",
						"Paardu",
						"Pae",
						"Pahkla",
						"Pihali",
						"Pirgu",
						"Prillimae",
						"Purila",
						"Purku",
						"Rabivere",
						"Raikkula",
						"Rangu",
						"Rapla",
						"Salutaguse",
						"Sipa",
						"Sotke",
						"Sutlema",
						"Tamme",
						"Teenuse",
						"Uuskula",
						"Vahastu",
						"Valgu",
						"Valtu",
						"Vana-Kaiu",
						"Vana-Vigala",
						"Varbola",
						"Vilivere",
					],
					stateName: "Rapla",
				},
				{
					cities: [
						"Arandi",
						"Aste",
						"Eikla",
						"Hellamaa",
						"Kao",
						"Karja",
						"Karla",
						"Kihelkonna",
						"Koimla",
						"Koljala",
						"Korkkula",
						"Korkvere",
						"Kudjape",
						"Kuressaare",
						"Laatsa",
						"Lahekula",
						"Laimjala",
						"Leisi",
						"Liiva",
						"Lumanda",
						"Metskula",
						"Mustjala",
						"Nasva",
						"Nommkula",
						"Orissaare",
						"Pahkla",
						"Parsama",
						"Pihtla",
						"Sakla",
						"Salme",
						"Sandla",
						"Tagavere",
						"Tornimae",
						"Valjala",
						"Vohma",
					],
					stateName: "Saare",
				},
				{
					cities: [
						"Aardla",
						"Aksi",
						"Alasoo",
						"Alatskivi",
						"Annikoru",
						"Aravu",
						"Elva",
						"Erala",
						"Ervu",
						"Haage",
						"Haaslava",
						"Ignase",
						"Illi",
						"Ilmatsalu",
						"Kaagvere",
						"Kaardi",
						"Kaarlijarve",
						"Kallaste",
						"Kalme",
						"Kambja",
						"Kandikula",
						"Karevere",
						"Karkna",
						"Kasepaa",
						"Kavastu",
						"Kirepi",
						"Kodukula",
						"Kokora",
						"Kolkja",
						"Konguta",
						"Koosa",
						"Koruste",
						"Korvekula",
						"Kulitse",
						"Kurekula",
						"Kurista",
						"Laaniste",
						"Laeva",
						"Lahte",
						"Lalli",
						"Lapetukme",
						"Lemmatsi",
						"Lohkva",
						"Lossimae",
						"Luke",
						"Luunja",
						"Maksa",
						"Maramaa",
						"Marja",
						"Meeksi",
						"Meeri",
						"Mehikoorma",
						"Melliste",
						"Metsakivi",
						"Metsalaane",
						"Moisanurme",
						"Nina",
						"Nogiaru",
						"Noo",
						"Pilka",
						"Poka",
						"Pooritsa",
						"Puhja",
						"Rahinge",
						"Raigaste",
						"Ramsi",
						"Rani",
						"Rannu",
						"Rebase",
						"Reola",
						"Rohu",
						"Roiu",
						"Rongu",
						"Saadjarve",
						"Sinikula",
						"Sirgu",
						"Soinaste",
						"Sojamaa",
						"Tahtvere",
						"Tammiste",
						"Tammistu",
						"Tartu",
						"Teedla",
						"Tilga",
						"Toravere",
						"Torvandi",
						"Tuki",
						"Uderna",
						"Uhti",
						"Ulenurme",
						"Ulila",
						"Unikula",
						"Vahi",
						"Vaike-Rakke",
						"Valguta",
						"Vana-Kuuste",
						"Vara",
						"Varnja",
						"Vasula",
						"Vedu",
						"Vesneri",
						"Vissi",
						"Voibla",
						"Voika",
						"Vonnu",
						"Voopste",
						"Vorbuse",
					],
					stateName: "Tartu",
				},
				{
					cities: [
						"Aakre",
						"Ala",
						"Hargla",
						"Hellenurme",
						"Helme",
						"Hummuli",
						"Jeti",
						"Jogeveste",
						"Kaagjarve",
						"Kalme",
						"Karjatnurme",
						"Karula",
						"Keeni",
						"Koikkula",
						"Laanemetsa",
						"Laatre",
						"Laukula",
						"Leebiku",
						"Linna",
						"Lossikula",
						"Lullemae",
						"Moldre",
						"Nouni",
						"Nupli",
						"Oru",
						"Oruste",
						"Otepaa",
						"Paidla",
						"Paju",
						"Palupera",
						"Patkula",
						"Piiri",
						"Pikasilla",
						"Pilkuse",
						"Puhajarve",
						"Puka",
						"Restu",
						"Riidaja",
						"Sangaste",
						"Sihva",
						"Sooru",
						"Taagepera",
						"Tagula",
						"Taheva",
						"Tiidu",
						"Tolliste",
						"Torva",
						"Tsirguliina",
						"Valga",
						"Vana-Otepaa",
					],
					stateName: "Valga",
				},
				{
					cities: [
						"Abja-Paluoja",
						"Abja-Vanamoisa",
						"Aidu",
						"Angi",
						"Anikatsi",
						"Arikula",
						"Halliste",
						"Heimtali",
						"Holstre",
						"Intsu",
						"Jamejala",
						"Jaska",
						"Kaavere",
						"Kamara",
						"Karksi",
						"Karksi-Nuia",
						"Karstna",
						"Karula",
						"Kobruvere",
						"Koidama",
						"Koksvere",
						"Kolga-Jaani",
						"Koo",
						"Kopu",
						"Kulla",
						"Lalsi",
						"Leie",
						"Lilli",
						"Lohavere",
						"Loodi",
						"Maeltkula",
						"Matapera",
						"Meleski",
						"Metskula",
						"Moisakula",
						"Monnaste",
						"Mustivere",
						"Mustla",
						"Navesti",
						"Odiste",
						"Oisu",
						"Olustvere",
						"Pahuvere",
						"Paistu",
						"Parakula",
						"Pari",
						"Parsti",
						"Peetrimoisa",
						"Pilistvere",
						"Pinska",
						"Polde",
						"Polli",
						"Puiatu",
						"Ramsi",
						"Reegoldi",
						"Saarepeedi",
						"Savikoti",
						"Sinialliku",
						"Soe",
						"Soomevere",
						"Sudiste",
						"Suislepa",
						"Sultsi",
						"Supsi",
						"Surgavere",
						"Suure-Jaani",
						"Taaksi",
						"Tanassilma",
						"Tinnikuru",
						"Tohvri",
						"Tuhalaane",
						"Tusti",
						"Ulde",
						"Ulensi",
						"Uusna",
						"Valgita",
						"Valma",
						"Valuste",
						"Vana-Kariste",
						"Vana-Voidu",
						"Vardi",
						"Vardja",
						"Vastemoisa",
						"Veelikse",
						"Verilaske",
						"Veskimae",
						"Viiratsi",
						"Viljandi",
						"Villa",
						"Vohma",
						"Voivaku",
						"Vooru",
					],
					stateName: "Viljandi",
				},
				{
					cities: [
						"Antsla",
						"Haanja",
						"Jarvere",
						"Kaapa",
						"Kirumpaa",
						"Kobela",
						"Kollino",
						"Kose",
						"Kraavi",
						"Krabi",
						"Kulaoru",
						"Kuldre",
						"Kuutsi",
						"Lasva",
						"Loosu",
						"Lusti",
						"Meegomae",
						"Meremae",
						"Misso",
						"Moniste",
						"Navi",
						"Nursi",
						"Obinitsa",
						"Osula",
						"Otsa",
						"Parksepa",
						"Puiga",
						"Raiste",
						"Rimmi",
						"Rouge",
						"Ruusmae",
						"Saru",
						"Somerpalu",
						"Sulbi",
						"Taberlaane",
						"Tagakula",
						"Tsolgo",
						"Tsooru",
						"Urvaste",
						"Uue-Antsla",
						"Vaabina",
						"Vagula",
						"Vaimela",
						"Vana-Antsla",
						"Vana-Roosa",
						"Vana-Vastseliina",
						"Varstu",
						"Vastseliina",
						"Verijarve",
						"Viitina",
						"Viitka",
						"Visela",
						"Voru",
						"Vorumoisa",
					],
					stateName: "Voru",
				},
			],
			countryName: "Estonia",
		},
		{
			states: [
				{ cities: [], stateName: "Addis Abeba" },
				{ cities: ["Asayita", "Awash", "Dubti", "Gewane"], stateName: "Afar" },
				{
					cities: [
						"Addi Ark'ay",
						"Addis 'Alem",
						"Addis Zemen",
						"Adet",
						"Bahir Dar",
						"Bati",
						"Bichena",
						"Bure",
						"Chagni",
						"Dabat",
						"Dangla",
						"Debark",
						"Debre Birhan",
						"Debre Mark'os",
						"Debre Sina",
						"Debre Tabor",
						"Debre Werk",
						"Dejen",
						"Dese",
						"Finote Selam",
						"Gondar",
						"K'obo",
						"Kembolcha",
						"Kemise",
						"Lalibela",
						"Mott'a",
						"Nefas Mewcha",
						"Sek'ot'a",
						"Shewa Robit",
						"Weldiya",
						"Were Ilu",
						"Werota",
					],
					stateName: "Amhara",
				},
				{ cities: ["Asosa"], stateName: "Benishangul" },
				{ cities: ["Dire Dawa"], stateName: "Diredawa" },
				{ cities: ["Gambela"], stateName: "Gambella" },
				{ cities: ["Harer"], stateName: "Harar" },
				{ cities: ["Jigjiga"], stateName: "Jigjiga" },
				{ cities: [], stateName: "Mekele" },
				{
					cities: [
						"'Alem Maya",
						"Abomsa",
						"Agaro",
						"Asasa",
						"Asbe Teferi",
						"Assela",
						"Bako",
						"Bedele",
						"Bedesa",
						"Burayu",
						"Debre Zeyit",
						"Deder",
						"Dembi Dolo",
						"Dodola",
						"Fiche",
						"Gebre Guracha",
						"Gedo",
						"Gelemso",
						"Gimbi",
						"Ginch'i",
						"Ginir",
						"Giyon",
						"Goba",
						"Gore",
						"Guder",
						"Hagere Hiywet",
						"Hagere Maryam",
						"Hirna",
						"Holeta Genet",
						"Huruta",
						"Jimma",
						"Kibre Mengist",
						"Kofele",
						"Mega",
						"Mek'i",
						"Mendi",
						"Metehara",
						"Metu",
						"Mojo",
						"Moyale",
						"Nazret",
						"Nedjo",
						"Negele",
						"Nek'emte",
						"Robe",
						"Sebeta",
						"Sendafa",
						"Shakiso",
						"Shambu",
						"Shashemenne",
						"Sheh Hussen",
						"Sire",
						"Tulu Bolo",
						"Welench'iti",
						"Welk'it'e",
						"Wonji",
						"Yabelo",
						"Ziway",
					],
					stateName: "Oromia",
				},
				{
					cities: [
						"Bircot",
						"Degeh Bur",
						"Dollo Odo",
						"Imi",
						"Jijiga",
						"Werder",
					],
					stateName: "Somali",
				},
				{
					cities: [
						"Alaba Kulito",
						"Arba Minch",
						"Areka",
						"Awassa",
						"Bako",
						"Boditi",
						"Bonga",
						"Butajira",
						"Ch'ench'a",
						"Dilla",
						"Gidole",
						"Hossa'ina",
						"Jinka",
						"Leku",
						"Mizan Teferi",
						"Sawla",
						"Soddo",
						"Teppi",
						"Wendo",
						"Yirga Alem",
						"Yirga Ch'efe",
					],
					stateName: "Southern",
				},
				{
					cities: [
						"Abiy Adi",
						"Addigrat",
						"Adwa",
						"Aksum",
						"Alamat'a",
						"Endasilasie",
						"Hagere Selam",
						"Himora",
						"Korem",
						"Maych'ew",
						"Mek'ele",
						"Mekele",
						"Wik'ro",
					],
					stateName: "Tigray",
				},
			],
			countryName: "Ethiopia",
		},
		{
			states: [
				{ cities: [], stateName: "Christmas Island" },
				{ cities: [], stateName: "Cocos Islands" },
				{ cities: [], stateName: "Coral Sea Islands" },
			],
			countryName: "External Territories of Australia",
		},
		{
			states: [
				{ cities: [], stateName: "Falkland Islands" },
				{ cities: [], stateName: "South Georgia" },
			],
			countryName: "Falkland Islands",
		},
		{
			states: [
				{ cities: ["Hvannasund", "Klaksvik"], stateName: "Klaksvik" },
				{ cities: [], stateName: "Nor ara Eysturoy" },
				{ cities: [], stateName: "Nor oy" },
				{
					cities: ["Husavik", "Sands", "Skalavik", "Skopun", "Skuvoy"],
					stateName: "Sandoy",
				},
				{
					cities: [
						"Haldarsvik",
						"Hests",
						"Hosvik",
						"Hvalvik",
						"Kirkjubo",
						"Kollafjar ",
						"Kvivik",
						"Nolsoy",
						"Saksun",
						"Torshavn",
						"Vestmanna",
					],
					stateName: "Streymoy",
				},
				{ cities: [], stateName: "Su uroy" },
				{ cities: [], stateName: "Sy ra Eysturoy" },
				{ cities: ["Torshavn"], stateName: "Torshavn" },
				{
					cities: ["Biggjar", "Midvags", "Mykines", "Sandavags", "Sorvags"],
					stateName: "Vaga",
				},
			],
			countryName: "Faroe Islands",
		},
		{
			states: [
				{
					cities: [
						"Deuba",
						"Korovou",
						"Lami",
						"Namosi",
						"Nausori",
						"Navua",
						"Suva",
					],
					stateName: "Central",
				},
				{
					cities: ["Levuka", "Malhaha", "Tubou", "Vunisea"],
					stateName: "Eastern",
				},
				{ cities: ["Korokade", "Labasa", "Savusavu"], stateName: "Northern" },
				{ cities: [], stateName: "South Pacific" },
				{
					cities: [
						"Ba",
						"Lautoka",
						"Nadi",
						"Rakiraki",
						"Sigatoka",
						"Tavua",
						"Vatukoula",
					],
					stateName: "Western",
				},
			],
			countryName: "Fiji Islands",
		},
		{
			states: [
				{ cities: ["Maarianhamina"], stateName: "Ahvenanmaa" },
				{
					cities: ["Imatra", "Joutseno", "Lappeenranta"],
					stateName: "Etela-Karjala",
				},
				{
					cities: [
						"Hyllykallio",
						"Ilmajoki",
						"Kauhajoki",
						"Kurikka",
						"Lapua",
						"Seinajoki",
					],
					stateName: "Etela-Pohjanmaa",
				},
				{ cities: ["Mikkeli", "Savonlinna"], stateName: "Etela-Savo" },
				{ cities: [], stateName: "Etela-Suomen Laani" },
				{ cities: [], stateName: "Ita-Suomen Laani" },
				{
					cities: ["Loviisa", "Orimattila", "Porvoo"],
					stateName: "Ita-Uusimaa",
				},
				{ cities: ["Kajaani"], stateName: "Kainuu" },
				{
					cities: ["Forssa", "Hameenlinna", "Janakkala", "Riihimaki"],
					stateName: "Kanta-Hame",
				},
				{ cities: ["Kokkola"], stateName: "Keski-Pohjanmaa" },
				{
					cities: [
						"Aanekoski",
						"Jamsa",
						"Jyvaskyla",
						"Keuruu",
						"Laukaa",
						"Lieto",
					],
					stateName: "Keski-Suomi",
				},
				{
					cities: [
						"Anjalankoski",
						"Hamina",
						"Kotka",
						"Kouvola",
						"Kuusankoski",
						"Valkeala",
						"Vehkalahti",
					],
					stateName: "Kymenlaakso",
				},
				{ cities: [], stateName: "Lansi-Suomen Laani" },
				{
					cities: ["Kemi", "Kemijarvi", "Rovaniemi", "Sonka", "Tornio"],
					stateName: "Lappi",
				},
				{ cities: [], stateName: "Northern Savonia" },
				{ cities: ["Jakobstad", "Oulunsalo"], stateName: "Ostrobothnia" },
				{ cities: [], stateName: "Oulun Laani" },
				{
					cities: ["Heinola", "Hollola", "Lahti", "Nastola"],
					stateName: "Paijat-Hame",
				},
				{
					cities: [
						"Kangasala",
						"Lempaala",
						"Nokia",
						"Pirkkala",
						"Sastamala",
						"Tampere",
						"Valkeakoski",
						"Ylojarvi",
					],
					stateName: "Pirkanmaa",
				},
				{
					cities: [
						"Kristiinankaupunki",
						"Mustasaari",
						"Pietarsaari",
						"Uusikarlepyy",
						"Vaasa",
					],
					stateName: "Pohjanmaa",
				},
				{ cities: ["Joensuu", "Lieksa"], stateName: "Pohjois-Karjala" },
				{
					cities: [
						"Haukipudas",
						"Kempele",
						"Kuusamo",
						"Muhos",
						"Nivala",
						"Oulainen",
						"Oulu",
						"Raahe",
						"Ylivieska",
					],
					stateName: "Pohjois-Pohjanmaa",
				},
				{
					cities: ["Iisalmi", "Kuopio", "Sillinjarvi", "Varkaus"],
					stateName: "Pohjois-Savo",
				},
				{ cities: ["Saarijarvi"], stateName: "Saarijarvi" },
				{
					cities: [
						"Eura",
						"Eurajoki",
						"Harjavalta",
						"Huittinen",
						"Kankaanpaa",
						"Kokemaki",
						"Lappi",
						"Nakkila",
						"Noormarkku",
						"Pori",
						"Rauma",
						"Sakyla",
						"Ulvila",
						"Vammala",
					],
					stateName: "Satakunta",
				},
				{ cities: [], stateName: "Southern Savonia" },
				{ cities: [], stateName: "Tavastia Proper" },
				{ cities: [], stateName: "Uleaborgs Lan" },
				{
					cities: [
						"Espoo",
						"Hanko",
						"Helsinki",
						"Hyvinkaa",
						"Jarvenpaa",
						"Kauniainen",
						"Kerava",
						"Kirkkonummi",
						"Lohja",
						"Mantsala",
						"Nurmijarvi",
						"Sipoo",
						"Tammisaari",
						"Tuusula",
						"Vantaa",
						"Vihti",
					],
					stateName: "Uusimaa",
				},
				{
					cities: [
						"Kaarina",
						"Naantali",
						"Parainen",
						"Raisio",
						"Salo",
						"Turku",
						"Uusikaupunki",
					],
					stateName: "Varsinais-Suomi",
				},
			],
			countryName: "Finland",
		},
		{
			states: [
				{
					cities: [
						"Amberieu-en-Bugey",
						"Bellegarde-sur-Valserine",
						"Bourg-en-Bresse",
						"Oyonnax",
					],
					stateName: "Ain",
				},
				{
					cities: [
						"Chateau-Thierry",
						"Chauny",
						"Hirson",
						"Laon",
						"Saint-Quentin",
						"Soissons",
						"Strassbourg",
						"Tergnier",
					],
					stateName: "Aisne",
				},
				{ cities: [], stateName: "Albi Le Sequestre" },
				{
					cities: ["Cusset", "Montlucon", "Moulins", "Vichy", "Yzeure"],
					stateName: "Allier",
				},
				{ cities: [], stateName: "Alpes-Cote dAzur" },
				{
					cities: [
						"Antibes",
						"Beausoleil",
						"Cagnes-sur-Mer",
						"Cannes",
						"Carros",
						"Grasse",
						"La Trinite",
						"Le Cannet",
						"Mandelieu-la-Napoule",
						"Menton",
						"Mougins",
						"Nice",
						"Roquebrune-Cap-Martin",
						"Saint-Laurent-du-Var",
						"Valbonne",
						"Vallauris",
						"Vence",
						"Villeneuve-Loubet",
					],
					stateName: "Alpes-Maritimes",
				},
				{
					cities: ["Digne-les-Bains", "Manosque"],
					stateName: "Alpes-de-Haute-Provence",
				},
				{
					cities: [
						"Adainville",
						"Alby-sur-Cheran",
						"Aubervilliers",
						"Auterive",
						"Baillet",
						"Beaune",
						"Beauzelle",
						"Bonnet De Mure",
						"Bormes-les-Mimosas",
						"Brindas",
						"Burlats",
						"Buzancais",
						"Candillargues",
						"Carry-le-Rouet",
						"Cassis",
						"Cessenon-Sur-Orb",
						"Chanac",
						"Chevigny",
						"Cogolin",
						"Collioure",
						"Cremieu",
						"Drulingen",
						"Ecouen",
						"Eschau",
						"Feignies",
						"Ferus",
						"Fourqueux",
						"Franqueville",
						"Gignac",
						"Gravigny",
						"Hangenbieten",
						"Hunspach",
						"Kilstett",
						"La Chevroliere",
						"La-Fare-Les-Oliviers",
						"Lanvellec",
						"Le Faget",
						"Lesigny",
						"Lesquin",
						"Limonest",
						"Messein",
						"Morgat",
						"Mundolsheim",
						"Nantiat",
						"Niederbronn-les-Bain",
						"Nimes",
						"Opoul",
						"Pance",
						"Peronne",
						"Ploneour Lanvern",
						"Realmont",
						"Reichstett",
						"Saint Aubin",
						"Saint Christophe",
						"Saint Martin dÃÆÃÂ¢ÃâÃâ¬ÃâÃâ¢Here",
						"Saint-Berthevin",
						"Saint-Cergues",
						"Sancerre",
						"Sarzeau",
						"Seltz",
						"Seyssins",
						"Souffelweyersheim",
						"Vireux-Molhain",
						"Voves",
						"Wambrechies",
						"Wervocq Sud",
					],
					stateName: "Alsace",
				},
				{
					cities: [
						"Abzac",
						"Bidart",
						"Biganos",
						"Buzet-sur-Baise",
						"Coursac",
						"Hasparren",
						"Landiras",
						"Le Haillan",
						"Ledat",
						"Martillac",
						"Puyoo",
						"Saint-Jean-dIllac",
						"Seignosse",
						"Tresses",
					],
					stateName: "Aquitaine",
				},
				{
					cities: [
						"Annonay",
						"Aubenas",
						"Guilherand-Granges",
						"Privas",
						"Tournon-sur-Rhone",
					],
					stateName: "Ardeche",
				},
				{
					cities: [
						"Charleville-Mezieres",
						"Les Hautes-Rivieres",
						"Revin",
						"Sedan",
					],
					stateName: "Ardennes",
				},
				{ cities: ["Foix", "Pamier"], stateName: "Ariege" },
				{
					cities: [
						"Bar-sur-Seine",
						"Romilly-sur-Seine",
						"Saint-Andre-les-Vergers",
						"Sainte-Savine",
						"Troyes",
					],
					stateName: "Aube",
				},
				{
					cities: ["Carcassonne", "Castelnaudary", "Limoux", "Narbonne"],
					stateName: "Aude",
				},
				{
					cities: [
						"Ambert",
						"Creuzier-le-Vieux",
						"Ferrieres",
						"Peschadoires",
						"Riotord",
						"Saint-Pal-de-Chalencon",
						"Saint-Romain-Lachalm",
						"Saint-Vidal",
						"Sainte-Sigolene",
					],
					stateName: "Auvergne",
				},
				{
					cities: [
						"Millau",
						"Onet-le-Chataeu",
						"Rodez",
						"Villefranche-de-Rouergue",
					],
					stateName: "Aveyron",
				},
				{
					cities: [
						"Bischheim",
						"Bischwiller",
						"Haguenau",
						"Hoenheim",
						"Illkirch-Graffenstaden",
						"Lingolsheim",
						"Obernai",
						"Ostwald",
						"Saverne",
						"Schiltigheim",
						"Selestat",
						"Strasbourg",
					],
					stateName: "Bas-Rhin",
				},
				{ cities: ["Deauville"], stateName: "Basse-Normandie" },
				{
					cities: [
						"Aix-en-Provence",
						"Allauch",
						"Arles",
						"Aubagne",
						"Berre-l'Etang",
						"Bouc-Bel-Air",
						"Chateauneuf-les-Martigues",
						"Chateaurenard",
						"Fos-sur-Mer",
						"Gardanne",
						"Istres",
						"La Ciotat",
						"Les Pennes-Mirabeau",
						"Maillane",
						"Marignane",
						"Marseille",
						"Martigues",
						"Miramas",
						"Plan-de-Cuques",
						"Port-de-Bouc",
						"Rognac",
						"Saint-Martin-de-Crau",
						"Saint-Remy-de-Provence",
						"Salon-de-Provence",
						"Septemes-les-Vallons",
						"Tarascon",
						"Vitrolles",
					],
					stateName: "Bouches-du-Rhone",
				},
				{ cities: ["Migennes"], stateName: "Bourgogne" },
				{ cities: ["Javene", "Plouha"], stateName: "Bretagne" },
				{
					cities: [
						"Brehan",
						"Broons",
						"Guipry",
						"Miniac-Morvan",
						"Ploudaniel",
						"Vern-sur-Seiche",
					],
					stateName: "Brittany",
				},
				{ cities: ["Aillant-sur-Tholon"], stateName: "Burgundy" },
				{
					cities: [
						"Bayeux",
						"Caen",
						"Herouville-Saint-Clair",
						"Lisieux",
						"Mondeville",
						"Vire",
					],
					stateName: "Calvados",
				},
				{ cities: ["Aurillac"], stateName: "Cantal" },
				{
					cities: ["Brumath", "Courtaboeuf", "Iregny", "cedex"],
					stateName: "Cedex",
				},
				{
					cities: [
						"Azay-le-Rideau",
						"Chevillon-sur-Huillard",
						"Cloyes-sur-le-Loir",
						"Gellainville",
						"La Chaussse-Saint-Victor",
						"La Ville-aux-Clercs",
						"Ladon",
						"Le Chatelet",
					],
					stateName: "Centre",
				},
				{
					cities: ["Angouleme", "Cognac", "Lencloitre", "Soyaux"],
					stateName: "Charente",
				},
				{
					cities: ["La Rochelle", "Rochefort", "Royan", "Saintes"],
					stateName: "Charente-Maritime",
				},
				{
					cities: [
						"Bourges",
						"Saint-Amand-Montrond",
						"Saint-Doulchard",
						"Vierzon",
					],
					stateName: "Cher",
				},
				{
					cities: ["Brive-la-Gaillarde", "Tulle", "Ussel"],
					stateName: "Correze",
				},
				{ cities: ["Ajaccio", "Porto-Vecchio"], stateName: "Corse-du-Sud" },
				{
					cities: ["Beaune", "Chenove", "Dijon", "Quetigny", "Talant"],
					stateName: "Cote-d'Or",
				},
				{
					cities: [
						"Dinan",
						"Lamballe",
						"Lannion",
						"Loudeac",
						"Plerin",
						"Ploufragan",
						"Saint-Brieuc",
					],
					stateName: "Cotes-d'Armor",
				},
				{ cities: ["Gueret"], stateName: "Creuse" },
				{ cities: ["Crolles"], stateName: "Crolles" },
				{
					cities: ["Bressuire", "Niort", "Parthenay", "Thouars"],
					stateName: "Deux-Sevres",
				},
				{
					cities: ["Bergerac", "Perigueux", "Sarlat-la-Caneda"],
					stateName: "Dordogne",
				},
				{
					cities: [
						"Audincourt",
						"Besancon",
						"Beure",
						"Montbeliard",
						"Pontarlier",
						"Valentigney",
					],
					stateName: "Doubs",
				},
				{
					cities: [
						"Bourg-les-Valence",
						"Montelimar",
						"Pierrelatte",
						"Romans-sur-Isere",
						"Valence",
					],
					stateName: "Drome",
				},
				{
					cities: [
						"Athis-Mons",
						"Bretigny-sur-Orge",
						"Brunoy",
						"Bures-sur-Yvette",
						"Chilly-Mazarin",
						"Corbeil-Essonnes",
						"Courcouronnes",
						"Dourdan",
						"Draveil",
						"Epinay-sous-Senart",
						"Epinay-sur-Orge",
						"Etampes",
						"Evry",
						"Fleury-Merogis",
						"Gif-sur-Yvette",
						"Grigny",
						"Igny",
						"Juvisy-sur-Orge",
						"Les Ulis",
						"Longjumeau",
						"Massy",
						"Mennecy",
						"Montgeron",
						"Morangis",
						"Morsang-sur-Orge",
						"Orsay",
						"Palaiseau",
						"Ris-Orangis",
						"Saint-Michel-sur-Orge",
						"Sainte-Genevieve-des-Bois",
						"Savigny-sur-Orge",
						"Verrieres-le-Buisson",
						"Vigneux-sur-Seine",
						"Villebon-sur-Yvette",
						"Viry-Chatillon",
						"Yerres",
					],
					stateName: "Essonne",
				},
				{
					cities: [
						"Bernay",
						"Evreux",
						"Gisors",
						"Louviers",
						"Pont-Audemer",
						"Val-de-Reuil",
						"Vernon",
					],
					stateName: "Eure",
				},
				{
					cities: [
						"Chartres",
						"Chateaudun",
						"Dreux",
						"Luce",
						"Mainvillier",
						"Nogent-le-Rotrou",
						"Vernouillet",
					],
					stateName: "Eure-et-Loir",
				},
				{ cities: ["Feucherolles"], stateName: "Feucherolles" },
				{
					cities: [
						"Brest",
						"Concarneau",
						"Douarnenez",
						"Guipavas",
						"Landerneau",
						"Le Relecq-Kerhoun",
						"Morlaix",
						"Plougastel-Daoulas",
						"Plouzane",
						"Quimper",
						"Quimperle",
					],
					stateName: "Finistere",
				},
				{
					cities: [
						"Charquemont",
						"Chemaudin",
						"Pelousey",
						"Perrigny",
						"Pirey",
						"Villers-le-Lac",
					],
					stateName: "Franche-Comte",
				},
				{
					cities: [
						"Ales",
						"Bagnols-sur-Ceze",
						"Beaucaire",
						"Nimes",
						"Pont-Saint-Esprit",
						"Saint-Gilles",
						"Vauvert",
						"Villeneuve-les-Avignon",
					],
					stateName: "Gard",
				},
				{ cities: ["Auch", "Beraut"], stateName: "Gers" },
				{
					cities: [
						"Ambares-et-Lagrave",
						"Arcachon",
						"Begles",
						"Blanquefort",
						"Bordeaux",
						"Bruges",
						"Cenon",
						"Cestas",
						"Eysines",
						"Floirac",
						"Gradignan",
						"Gujan-Mestras",
						"La Teste-de-Buch",
						"Le Bouscat",
						"Libourne",
						"Lormont",
						"Merignac",
						"Pessac",
						"Saint-Medard-en-Jalles",
						"Talence",
						"Villenave-d'Ornon",
					],
					stateName: "Gironde",
				},
				{
					cities: [
						"Cernay",
						"Colmar",
						"Guebwiller",
						"Illzach",
						"Kingersheim",
						"Mulhouse",
						"Riediesheim",
						"Rixheim",
						"Saint-Louis",
						"Wittelsheim",
						"Wittenheim",
					],
					stateName: "Haut-Rhin",
				},
				{ cities: ["Bastia"], stateName: "Haute-Corse" },
				{
					cities: [
						"Balma",
						"Blagnac",
						"Colomiers",
						"Cugnaux",
						"L'Union",
						"Muret",
						"Plaisance-du-Touch",
						"Ramonville-Saint-Agne",
						"Saint-Gaudens",
						"Saint-Orens-de-Gameville",
						"Toulouse",
						"Tournefeuille",
					],
					stateName: "Haute-Garonne",
				},
				{ cities: ["Blanzac", "Le Puy-en-Velay"], stateName: "Haute-Loire" },
				{
					cities: ["Chaumont", "Langres", "Saint-Dizier"],
					stateName: "Haute-Marne",
				},
				{
					cities: ["Hericourt", "Lure", "Luxeuil-les-Bains", "Vesoul"],
					stateName: "Haute-Saone",
				},
				{
					cities: [
						"Annecy",
						"Annecy-le-Vieux",
						"Annemasse",
						"Archamps",
						"Bonneville",
						"Chamonix-Mont-Blanc",
						"Cluses",
						"Contamine sur Arve",
						"Cran-Gevrier",
						"Passy",
						"Rumilly",
						"Sallanches",
						"Seynod",
						"Thonon-les-Bains",
					],
					stateName: "Haute-Savoie",
				},
				{
					cities: ["Gaillard", "Limoges", "Saint-Junien"],
					stateName: "Haute-Vienne",
				},
				{ cities: ["Briancon", "Gap"], stateName: "Hautes-Alpes" },
				{ cities: ["Lourdes", "Tarbes"], stateName: "Hautes-Pyrenees" },
				{
					cities: [
						"Antony",
						"Asnieres-sur-Seine",
						"Bagneux",
						"Bois-Colombes",
						"Boulogne-Billancourt",
						"Bourg-la-Reine",
						"Chatenay-Malabry",
						"Chatillon",
						"Chaville",
						"Clamart",
						"Clichy",
						"Colombes",
						"Courbevoie",
						"Fontenay-aux-Roses",
						"Garches",
						"Gennevillers",
						"Issy-les-Moulineaux",
						"La Garenne-Colombes",
						"Le Plessis-Robinson",
						"Levallois-Perret",
						"Malakoff",
						"Meudon",
						"Montrouge",
						"Nanterre",
						"Neuilly-sur-Seine",
						"Puteaux",
						"Rueil-Malmaison",
						"Saint-Cloud",
						"Sceaux",
						"Sevres",
						"Suresnes",
						"Vanves",
						"Ville-d'Avray",
						"Villeneuve-la-Garenne",
					],
					stateName: "Hauts-de-Seine",
				},
				{
					cities: [
						"Agde",
						"Beziers",
						"Castelnau-le-Lez",
						"Frontignan",
						"Lattes",
						"Lunel",
						"Mauguio",
						"Montpellier",
						"Sete",
					],
					stateName: "Herault",
				},
				{
					cities: [
						"Champagne-sur-Oise",
						"Croissy-Beaubourg",
						"Gennevilliers",
						"Le Mesnil-le-Roi",
						"Le Plessis-Bouchard",
						"Rebais",
						"Saint-Thibault-des-Vignes",
					],
					stateName: "Ile-de-France",
				},
				{
					cities: [
						"Cesson-Sevigne",
						"Dinard",
						"Fougeres",
						"Rennes",
						"Saint-Malo",
						"Vitre",
					],
					stateName: "Ille-et-Vilaine",
				},
				{ cities: ["Chateauroux", "Issoudun"], stateName: "Indre" },
				{
					cities: [
						"Amboise",
						"Joue-les-Tours",
						"Saint-Avertin",
						"Saint-Cyr-sur-Loire",
						"Saint-Pierre-des-Corps",
						"Tours",
					],
					stateName: "Indre-et-Loire",
				},
				{
					cities: [
						"Bourgoin-Jallieu",
						"Crolles",
						"Echirolles",
						"Fontaine",
						"Grenoble",
						"Le Pont-de-Claix",
						"Meylan",
						"Saint-Egreve",
						"Saint-Martin-d'Heres",
						"Seyssinet-Pariset",
						"Vienne",
						"Villefontaine",
						"Voiron",
					],
					stateName: "Isere",
				},
				{
					cities: ["Champagnole", "Dole", "Lons-le-Saunier", "Saint-Claude"],
					stateName: "Jura",
				},
				{ cities: ["Sebastiangasse"], stateName: "Klagenfurt" },
				{
					cities: [
						"Biscarrosse",
						"Dax",
						"Hagetmau",
						"Landes",
						"Mont-de-Marsan",
						"Saint-Paul-les-Dax",
						"Tarnos",
					],
					stateName: "Landes",
				},
				{
					cities: [
						"Codolet",
						"Cuxac-d'Aude",
						"Gigean",
						"Grabels",
						"Lamalou-les-Bains",
						"Perols",
						"Peyrens",
						"Tuchan",
					],
					stateName: "Languedoc-Roussillon",
				},
				{ cities: ["Larcay"], stateName: "Larcay" },
				{ cities: [], stateName: "Le Castellet" },
				{ cities: [], stateName: "Le Creusot" },
				{ cities: ["Voutezac"], stateName: "Limousin" },
				{
					cities: ["Blois", "Romorantin-Lanthenay", "Vendome"],
					stateName: "Loir-et-Cher",
				},
				{
					cities: [
						"Andrezieux-Boutheon",
						"Firminy",
						"La Ricamarie",
						"Le Chambon-Feugerolles",
						"Montbrison",
						"Riorges",
						"Rive-de-Gier",
						"Roanne",
						"Roche-la-Moliere",
						"Saint-Chamond",
						"Saint-Etienne",
						"Saint-Just-Saint-Rambert",
					],
					stateName: "Loire",
				},
				{
					cities: [
						"Bouguenais",
						"Carquefou",
						"Chateaubriant",
						"Coueron",
						"Guerande",
						"La Baule-Escoublac",
						"La Chapelle-sur-Erdre",
						"Nantes",
						"Orvault",
						"Reze",
						"Saint Etienne de Mer Morte",
						"Saint-Herblain",
						"Saint-Nazaire",
						"Saint-Sebastien-sur-Loire",
						"Sainte-Luce-sur-Loire",
						"Vertou",
					],
					stateName: "Loire-Atlantique",
				},
				{
					cities: [
						"Amilly",
						"Chalette-sur-Loing",
						"Fleury-les-Aubrais",
						"Gien",
						"Montargis",
						"Olivet",
						"Orleans",
						"Pithiviers",
						"Saint-Jean-de-Braye",
						"Saint-Jean-de-la-Ruelle",
						"Saran",
					],
					stateName: "Loiret",
				},
				{ cities: ["Montbronn"], stateName: "Lorraine" },
				{ cities: ["Cahors", "Figeac"], stateName: "Lot" },
				{
					cities: [
						"Agen",
						"Le Passage",
						"Marmande",
						"Tonneins",
						"Villeneuve-sur-Lot",
					],
					stateName: "Lot-et-Garonne",
				},
				{ cities: [], stateName: "Lower Normandy" },
				{ cities: ["Mende"], stateName: "Lozere" },
				{
					cities: [
						"Angers",
						"Avrille",
						"Cholet",
						"Les Ponts-de-Ce",
						"Saint-Barthelemy-d'Anjou",
						"Saumur",
						"Torfou",
						"Trelaze",
					],
					stateName: "Maine-et-Loire",
				},
				{
					cities: [
						"Cherbourg",
						"Coutances",
						"Equeurdreville-Hainneville",
						"Granville",
						"Octeville",
						"Saint-Lo",
						"Tourlaville",
					],
					stateName: "Manche",
				},
				{
					cities: [
						"Chalons-en-Champagne",
						"Epernay",
						"Reims",
						"Tinqueux",
						"Vitry-le-Francois",
					],
					stateName: "Marne",
				},
				{
					cities: ["Chateau-Gontier", "Laval", "Mayenne", "Montsurs"],
					stateName: "Mayenne",
				},
				{
					cities: [
						"Dombasle-sur-Meurthe",
						"Jarville-la-Malgrange",
						"Laxou",
						"Longwy",
						"Luneville",
						"Nancy",
						"Pont-a-Mousson",
						"Saint-Max",
						"Toul",
						"Vandoeuvre-les-Nancy",
						"Villers-les-Nancy",
						"Villerupt",
					],
					stateName: "Meurthe-et-Moselle",
				},
				{ cities: ["Bar-le-Duc", "Verdun"], stateName: "Meuse" },
				{
					cities: [
						"Boissezon",
						"Hauterive",
						"Launaguet",
						"Mauleon",
						"Maurens-Scopont",
					],
					stateName: "Midi-Pyrenees",
				},
				{
					cities: [
						"Auray",
						"Guidel",
						"Hennebont",
						"Lanester",
						"Lorient",
						"Ploemeur",
						"Pontivy",
						"Vannes",
					],
					stateName: "Morbihan",
				},
				{
					cities: [
						"Amneville",
						"Behren-les-Forbach",
						"Creutzwald",
						"Fameck",
						"Florange",
						"Forbach",
						"Freyming-Merlebach",
						"Hagondange",
						"Hayange",
						"Hombourg-Haut",
						"Maizieres-les-Metz",
						"Marly",
						"Metz",
						"Montigny-les-Metz",
						"Moyeuvre-Grande",
						"Rombas",
						"Saint-Avold",
						"Sarrebourg",
						"Sarreguemines",
						"Stiring-Wendel",
						"Thionville",
						"Uckange",
						"Woippy",
						"Yutz",
					],
					stateName: "Moselle",
				},
				{
					cities: ["Cosne-Cours-sur-Loire", "Nevers", "Varennes-Vauzelles"],
					stateName: "Nievre",
				},
				{
					cities: [
						"Aniche",
						"Annoeullin",
						"Anzin",
						"Armentieres",
						"Aulnoye-Aymeries",
						"Bailleul",
						"Bondues",
						"Bruay-sur-l'Escaut",
						"Cambrai",
						"Cappelle-la-Grande",
						"Caudry",
						"Comines",
						"Conde-sur-l'Escaut",
						"Coudekerque-Branche",
						"Croix",
						"Denain",
						"Douai",
						"Douchy-les-Mines",
						"Dunkerque",
						"Escaudain",
						"Fache-Thumesnil",
						"Fourmies",
						"Grande-Synthe",
						"Graveline",
						"Halluin",
						"Haubourdin",
						"Hautmont",
						"Hazebrouck",
						"Hem",
						"Hulluch",
						"Jeumont",
						"La Madeleine",
						"Lambersart",
						"Leers",
						"Lille",
						"Lomme",
						"Loos",
						"Lys-lez-Lannoy",
						"Marcq-en-Baroeul",
						"Marennes",
						"Marly",
						"Marquette-lez-Lille",
						"Maubeuge",
						"Merville",
						"Mons-en-Baroeul",
						"Mouvaux",
						"Neuville-en-Ferrain",
						"Onnaing",
						"Raismes",
						"Ronchin",
						"Roncq",
						"Roubaix",
						"Saint-Amand-les-Eaux",
						"Saint-Andre-lez-Lille",
						"Saint-Pol-sur-Mer",
						"Saint-Saulve",
						"Seclin",
						"Sin-le-Noble",
						"Somain",
						"Tourcoing",
						"Valenciennes",
						"Vieux-Conde",
						"Villeneuve-d'Ascq",
						"Wasquehal",
						"Wattignies",
						"Wattrelos",
						"Waziers",
					],
					stateName: "Nord",
				},
				{ cities: ["Esquelbecq"], stateName: "Nord-Pas-de-Calais" },
				{
					cities: [
						"Beauvais",
						"Chantilly",
						"Clermont",
						"Compiegne",
						"Creil",
						"Crepy-en-Valois",
						"Gouvieux",
						"Meru",
						"Montataire",
						"Nogent-sur-Oise",
						"Noyon",
						"Pont-Sainte-Maxence",
						"Senlis",
					],
					stateName: "Oise",
				},
				{
					cities: ["Alencon", "Argentan", "Flers", "L'Aigle"],
					stateName: "Orne",
				},
				{ cities: ["Paris"], stateName: "Paris" },
				{
					cities: [
						"Aire-sur-la-Lys",
						"Arras",
						"Auchel",
						"Avion",
						"Berck",
						"Bethune",
						"Boulogne-sur-Mer",
						"Bruay-la-Brussiere",
						"Bully-les-Mines",
						"Calais",
						"Carvin",
						"Courrieres",
						"Etaples",
						"Harnes",
						"Henin-Beaumont",
						"Le Portel",
						"Lens",
						"Libercourt",
						"Lievin",
						"Lillers",
						"Longuenesse",
						"Marck",
						"Mericourt",
						"Montigny-en-Gohelle",
						"Noeux-les-Mines",
						"Oignies",
						"Outreau",
						"Rouvroy",
						"Saint-Martin-Boulogne",
						"Saint-Omer",
						"Sallaumines",
						"Vendin-le-Vieil",
					],
					stateName: "Pas-de-Calais",
				},
				{ cities: [], stateName: "Pays de la Loire" },
				{
					cities: [
						"Loiron",
						"Marolles-les-Braults",
						"Mortagne-sur-Sevre",
						"Mouzillon",
						"Noirmoutier-en-l'ÃÆÃÅ½le",
					],
					stateName: "Pays-de-la-Loire",
				},
				{
					cities: [
						"Friville",
						"Liancourt",
						"Maizy",
						"Oust-Marest",
						"Puiseux-le-Hauberger",
						"Saint-Crepin-Ibouvillers",
					],
					stateName: "Picardy",
				},
				{
					cities: [
						"Aubiere",
						"Beaumont",
						"Chamalieres",
						"Clermont-Ferrand",
						"Cournon-d'Auvergne",
						"Gerzat",
						"Issoire",
						"Riom",
						"Thiers",
					],
					stateName: "Puy-de-Dome",
				},
				{
					cities: [
						"Anglet",
						"Bayonne",
						"Biarritz",
						"Billere",
						"Hendaye",
						"Lons",
						"Oloron-Sainte-Marie",
						"Orthez",
						"Pau",
						"Saint-Jean-de-Luz",
					],
					stateName: "Pyrenees-Atlantiques",
				},
				{
					cities: ["Perpignan", "Saint-Esteve"],
					stateName: "Pyrenees-Orientales",
				},
				{ cities: ["Quelmes"], stateName: "Quelmes" },
				{
					cities: [
						"Brignais",
						"Bron",
						"Caluire-et-Cuire",
						"Decines-Charpieu",
						"Ecully",
						"Francheville",
						"Genas",
						"Genay",
						"Givors",
						"Lyon",
						"Meyzieu",
						"Mions",
						"Oullins",
						"Pierre-Benite",
						"Rillieux-la-Pape",
						"Saint-Fons",
						"Saint-Genis-Laval",
						"Saint-Priest",
						"Sainte-Foy-les-Lyon",
						"Tarare",
						"Tassin-la-Demi-Lune",
						"Vaulx-en-Velin",
						"Venissieux",
						"Villefranche-sur-Saone",
						"Villeurbanne",
					],
					stateName: "Rhone",
				},
				{
					cities: [
						"Beauvoir-en-Royans",
						"Belley",
						"Bons-en-Chablais",
						"Chalain-d'Uzore",
						"Chassieu",
						"Chavanod",
						"Chazay-d-Azergues",
						"Chimilin",
						"Civrieux-d Azergues",
						"Corbas",
						"Courzieu",
						"Dardilly",
						"Guereins",
						"Izernore",
						"La Talaudiere",
						"La Tronche",
						"La Verpilliere",
						"Le Cheylard",
						"Le Cheylas",
						"Mery",
						"Moirans",
						"Montalieu-Vercieu",
						"Montmiral",
						"Peronnas",
						"Poncin",
						"Quincie-en-Beaujolais",
						"Saint-Quentin-sur-Isere",
						"Sainte Agathe la Bouteresse",
						"Sainte-Consorce",
						"Sisteron",
						"Trevoux",
						"Villard-Bonnot",
					],
					stateName: "Rhone-Alpes",
				},
				{ cities: [], stateName: "Saint Ouen" },
				{ cities: [], stateName: "Saint Viatre" },
				{
					cities: [
						"Autun",
						"Chalon-sur-Saone",
						"Digoin",
						"Gueugnon",
						"Le Creusot",
						"Macon",
						"Montceau-les-Mines",
						"Paray-le-Monial",
						"Saint-Vallier",
					],
					stateName: "Saone-et-Loire",
				},
				{
					cities: [
						"Allonnes",
						"La Ferte-Bernard",
						"La Fleche",
						"Le Mans",
						"Sable-sur-Sarthe",
					],
					stateName: "Sarthe",
				},
				{
					cities: [
						"Aix-les-Bains",
						"Albertville",
						"Chambery",
						"La Motte-Servolex",
						"Saint-Jean-de-Maurienne",
					],
					stateName: "Savoie",
				},
				{
					cities: [
						"Barentin",
						"Bihorel",
						"Bois-Guillaume",
						"Bolbec",
						"Canteleu",
						"Caudebec-les-Elbeuf",
						"Darnetal",
						"Deville-les-Rouen",
						"Dieppe",
						"Elbeuf",
						"Fecamp",
						"Gonfreville-l'Orcher",
						"Grand-Couronne",
						"Harfleur",
						"Le Grand-Quevilly",
						"Le Havre",
						"Le Petit-Quevilly",
						"Lillebonne",
						"Maromme",
						"Mont-Saint-Aignan",
						"Montivilliers",
						"Notre-Dame-de-Gravenchon",
						"Oissel",
						"Rouen",
						"Saint-Etienne-du-Rouvray",
						"Sotteville-les-Rouen",
						"Yvetot",
					],
					stateName: "Seine-Maritime",
				},
				{
					cities: [
						"Aubervillers",
						"Aulnay-sous-Bois",
						"Bagnolet",
						"Bobigny",
						"Bondy",
						"Clichy-sous-Bois",
						"Drancy",
						"Epinay-sur-Seine",
						"Gagny",
						"La Courneuve",
						"Le Blanc-Mesnil",
						"Le Bourget",
						"Le Pre-Saint-Gervais",
						"Le Raincy",
						"Les Lilas",
						"Les Pavillons-sous-Bois",
						"Livry-Gargan",
						"Montfermeil",
						"Montreuil",
						"Neuilly-Plaisance",
						"Neuilly-sur-Marne",
						"Noisy-le-Grand",
						"Noisy-le-Sec",
						"Pantin",
						"Pierrefitte-sur-Seine",
						"Romainville",
						"Rosny-sous-Bois",
						"Saint-Denis",
						"Saint-Ouen",
						"Sevran",
						"Stains",
						"Tremblay-en-France",
						"Villemomble",
						"Villepinte",
						"Villetaneuse",
					],
					stateName: "Seine-Saint-Denis",
				},
				{
					cities: [
						"Avon",
						"Brie-Comte-Robert",
						"Champs-sur-Marne",
						"Chelles",
						"Claye-Souilly",
						"Combs-la-Ville",
						"Coulommiers",
						"Dammarie-les-Lys",
						"Fontainebleau",
						"Lagny-sur-Marne",
						"Le Mee-sur-Seine",
						"Lognes",
						"Meaux",
						"Melun",
						"Mitry-Mory",
						"Moissy-Cramayel",
						"Montereau-Fault-Yonne",
						"Nemours",
						"Noisiel",
						"Ozoir-la-Ferriere",
						"Pontault-Combault",
						"Provins",
						"Roissy-en-Brie",
						"Saint-Fargeau-Ponthierry",
						"Savigny-le-Temple",
						"Torcy",
						"Vaires-sur-Marne",
						"Veneux",
						"Villeparisis",
					],
					stateName: "Seine-et-Marne",
				},
				{ cities: ["Abbeville", "Albert", "Amiens"], stateName: "Somme" },
				{ cities: [], stateName: "Sophia Antipolis" },
				{ cities: ["Souvans"], stateName: "Souvans" },
				{
					cities: [
						"Albi",
						"Carmaux",
						"Castres",
						"Gaillac",
						"Graulhet",
						"Mazamet",
					],
					stateName: "Tarn",
				},
				{
					cities: ["Castelsarassin", "Moissac", "Montauban"],
					stateName: "Tarn-et-Garonne",
				},
				{ cities: [], stateName: "Territoire de Belfort" },
				{ cities: ["Chamberet"], stateName: "Treignac" },
				{ cities: [], stateName: "Upper Normandy" },
				{
					cities: [
						"Argenteuil",
						"Arnouville-les-Gonesse",
						"Beauchamps",
						"Bezons",
						"Cergy",
						"Cormeilles-en-Parisis",
						"Deuil-la-Barre",
						"Domont",
						"Eaubonne",
						"Enghien-les-Bains",
						"Eragny",
						"Ermont",
						"Ezanville",
						"Fosses",
						"Franconville",
						"Garges-les-Gonesse",
						"Gonesse",
						"Goussainville",
						"Herblay",
						"Jouy-le-Moutier",
						"L'Isle-Adam",
						"Montigny-les-Cormeilles",
						"Montmagny",
						"Montmorency",
						"Osny",
						"Persan",
						"Pontoise",
						"Saint-Brice-sous-Foret",
						"Saint-Gratien",
						"Saint-Leu-la-Foret",
						"Saint-Ouen-l'Aumone",
						"Sannois",
						"Sarcelles",
						"Soisy-sous-Montmorency",
						"Taverny",
						"Vaureal",
						"Villiers-le-Bel",
					],
					stateName: "Val-d'Oise",
				},
				{
					cities: [
						"Alfortville",
						"Arcueil",
						"Boissy-Saint-Leger",
						"Bonneuil",
						"Bry-sur-Marne",
						"Cachan",
						"Champigny-sur-Marne",
						"Charenton-le-Pont",
						"Chennevieres-sur-Marne",
						"Chevilly-Larue",
						"Choisy-le-Roi",
						"Creteil",
						"Fontenay-sous-Bois",
						"Fresnes",
						"Gentilly",
						"Ivry-sur-Seine",
						"Joinville-le-Pont",
						"L'Hay-les-Roses",
						"La Queue-en-Brie",
						"Le Kremlin-Bicetre",
						"Le Perreux-sur-Marne",
						"Le Plessis-Trevise",
						"Limeil-Brevannes",
						"Maisons-Alfort",
						"Nogent-sur-Marne",
						"Orly",
						"Ormesson-sur-Marne",
						"Saint-Mande",
						"Saint-Maur-des-Fosses",
						"Saint-Maurice",
						"Sucy-en-Brie",
						"Thiais",
						"Valenton",
						"Villejuif",
						"Villeneuve-Saint-Georges",
						"Villeneuve-le-Roi",
						"Villiers-sur-Marne",
						"Vincennes",
						"Vitry-sur-Seine",
					],
					stateName: "Val-de-Marne",
				},
				{
					cities: [
						"Brignoles",
						"Draguignan",
						"Frejus",
						"Hyeres",
						"La Crau",
						"La Garde",
						"La Seyne-sur-Mer",
						"La Valette-du-Var",
						"Le Pradet",
						"Ollioules",
						"Roquebrune-sur-Argens",
						"Saint-Maximin-la-Sainte-Baume",
						"Saint-Raphael",
						"Sainte-Maxime",
						"Sanary-sur-Mer",
						"Six-Fours-les-Plages",
						"Sollies-Pont",
						"Toulon",
					],
					stateName: "Var",
				},
				{
					cities: [
						"Apt",
						"Avignon",
						"Bollene",
						"Carpentras",
						"Cavaillon",
						"L'Isle-sur-la-Sorgue",
						"Le Pontet",
						"Orange",
						"Pertuis",
						"Sorgues",
						"Valreas",
					],
					stateName: "Vaucluse",
				},
				{ cities: ["Vellise"], stateName: "Vellise" },
				{
					cities: [
						"Challans",
						"Chateau-d'Olonne",
						"Fontenay-le-Comte",
						"La Chasnis",
						"La Roche-sur-Yon",
						"Les Herbiers",
						"Les Sables-d'Olonne",
						"Longeville-sur-Mer",
					],
					stateName: "Vendee",
				},
				{ cities: ["Chatellerault", "Poitiers"], stateName: "Vienne" },
				{
					cities: ["Epinal", "Gerardmer", "Remiremont", "Saint-Die"],
					stateName: "Vosges",
				},
				{
					cities: ["Auxerre", "Avallon", "Joigny", "Sens"],
					stateName: "Yonne",
				},
				{
					cities: [
						"Acheres",
						"Andresy",
						"Aubergenville",
						"Bois-d'Arcy",
						"Carrieres-sous-Poissy",
						"Carrieres-sur-Seine",
						"Chanteloup-les-Vignes",
						"Chatou",
						"Conflans-Sainte-Honorine",
						"Croissy-sur-Seine",
						"Elancourt",
						"Fontenay-le-Fleury",
						"Guyancourt",
						"Houilles",
						"La Celle-Saint-Cloud",
						"Le Chesnay",
						"Le Pecq",
						"Le Vesinet",
						"Les Clayes-sous-Bois",
						"Les Mureaux",
						"Limay",
						"Maisons-Laffitte",
						"Mantes-la-Jolie",
						"Mantes-la-Ville",
						"Marly-le-Roi",
						"Maurepas",
						"Montesson",
						"Montigny-le-Bretonneux",
						"Plaisir",
						"Poissy",
						"Rambouillet",
						"Saint-Cyr-l'Ecole",
						"Saint-Germain-en-Laye",
						"Sartrouville",
						"Trappes",
						"Triel-sur-Seine",
						"Velizy-Villacoublay",
						"Verneuil-sur-Seine",
						"Versailles",
						"Viroflay",
						"Voisins-le-Bretonneux",
					],
					stateName: "Yvelines",
				},
			],
			countryName: "France",
		},
		{
			states: [
				{
					cities: [
						"Camopi",
						"Cayenne",
						"Iracoubo",
						"Kourou",
						"Macouria",
						"Matoury",
						"Remire-Montjoly",
						"Roura",
						"Saint-Georges",
						"Sinnamary",
					],
					stateName: "Cayenne",
				},
				{
					cities: [
						"Apatou",
						"Grand-Santi",
						"Mana",
						"Maripasoula",
						"Saint-Laurent-du-Maroni",
					],
					stateName: "Saint-Laurent-du-Maroni",
				},
			],
			countryName: "French Guiana",
		},
		{
			states: [
				{ cities: [], stateName: "Iles du Vent" },
				{ cities: [], stateName: "Iles sous le Vent" },
				{
					cities: [
						"Atuona",
						"Fatu-Hiva",
						"Hakahao",
						"Hakamaii",
						"Hatiheu",
						"Tahuata",
						"Taiohae",
						"Taipivai",
						"Ua-Huka",
					],
					stateName: "Marquesas",
				},
				{
					cities: [
						"Ahe",
						"Amanu",
						"Anaa",
						"Apataki",
						"Arutua",
						"Faaite",
						"Fakahima",
						"Fakarava",
						"Fangatau",
						"Hao",
						"Hereheretue",
						"Hikueru",
						"Katiu",
						"Kauehi",
						"Kaukura",
						"Makatea",
						"Makemo",
						"Manihi",
						"Marokau",
						"Napuka",
						"Niau",
						"Nukutavake",
						"Puamau",
						"Pukapuka",
						"Pukarua",
						"Rangiroa",
						"Raroia",
						"Reao",
						"Rikitea",
						"Taenga",
						"Takapoto",
						"Takaroa",
						"Tatakoto",
						"Tepoto",
						"Tikehau",
						"Tureia",
						"Vahitahi",
						"Vairaatea",
					],
					stateName: "Tuamotu",
				},
				{
					cities: [
						"Amaru",
						"Anapoto",
						"Anatonu",
						"Auti",
						"Avera",
						"Mahu",
						"Mataura",
						"Moerai",
						"Mutuaura",
						"Rairua",
						"Rapa",
						"Taahuaia",
						"Vaiuru",
					],
					stateName: "Tubuai",
				},
			],
			countryName: "French Polynesia",
		},
		{
			states: [
				{ cities: ["Martin-de-Vivies"], stateName: "Amsterdam" },
				{ cities: [], stateName: "Crozet Islands" },
				{ cities: ["Port-aux-Francais"], stateName: "Kerguelen" },
			],
			countryName: "French Southern Territories",
		},
		{
			states: [
				{
					cities: ["Cocobeach", "Kango", "Libreville", "Nkan", "Ntoum"],
					stateName: "Estuaire",
				},
				{
					cities: ["Lekoni", "Masuku", "Moanda", "Mounana", "Okandja"],
					stateName: "Haut-Ogooue",
				},
				{ cities: ["Lambarene", "Ndjole"], stateName: "Moyen-Ogooue" },
				{
					cities: ["Fougamou", "Mbigou", "Mimongo", "Mouila", "Ndende"],
					stateName: "Ngounie",
				},
				{ cities: ["Mayumba", "Tchibanga", "Tsogni"], stateName: "Nyanga" },
				{ cities: ["Booue", "Makokou", "Mekambo"], stateName: "Ogooue-Ivindo" },
				{ cities: ["Koulamoutou", "Lastoursville"], stateName: "Ogooue-Lolo" },
				{
					cities: ["Gamba", "Omboue", "Port-Gentil", "Sette Cama"],
					stateName: "Ogooue-Maritime",
				},
				{
					cities: ["Bitam", "Lalara", "Medouneu", "Minvoul", "Mitzic", "Oyem"],
					stateName: "Woleu-Ntem",
				},
			],
			countryName: "Gabon",
		},
		{
			states: [
				{ cities: ["Banjul"], stateName: "Banjul" },
				{
					cities: ["Basse", "Gambissara", "Sabi", "Salikeni"],
					stateName: "Basse",
				},
				{
					cities: ["Brikama", "Brufut", "Gunjur", "Sukuta"],
					stateName: "Brikama",
				},
				{ cities: ["Bansang", "Janjanbureh"], stateName: "Janjanbureh" },
				{ cities: ["Bakau", "Serekunda"], stateName: "Kanifing" },
				{
					cities: ["Barra", "Essau", "Farafenni", "Kerewan", "Lamin"],
					stateName: "Kerewan",
				},
				{ cities: ["Kuntaur"], stateName: "Kuntaur" },
				{ cities: ["Mansakonko"], stateName: "Mansakonko" },
			],
			countryName: "Gambia The",
		},
		{
			states: [
				{
					cities: [
						"Ahali Atoni",
						"Bihvinta",
						"Gagra",
						"Gali",
						"Gudauta",
						"Gulripshi",
						"Ochamchira",
						"Suhumi",
						"Tkvarcheli",
					],
					stateName: "Abhasia",
				},
				{
					cities: ["Batumi", "Dioknisi", "Kobuleti", "Mahindzhauri"],
					stateName: "Ajaria",
				},
				{ cities: ["Lanchhuti", "Ozurgeti"], stateName: "Guria" },
				{
					cities: [
						"Bagdadi",
						"Chaltubo",
						"Chiatura",
						"Honi",
						"Kutaisi",
						"Sachhere",
						"Samtredia",
						"Terzhola",
						"Tkibuli",
						"Vani",
						"Zestaponi",
					],
					stateName: "Imereti",
				},
				{
					cities: [
						"Ahmeta",
						"Cnori",
						"Dedoplisckaro",
						"Gurdzhaani",
						"Kvareli",
						"Lagodehi",
						"Sagaredzho",
						"Signahi",
						"Telavi",
					],
					stateName: "Kaheti",
				},
				{ cities: [], stateName: "Kvemo Kartli" },
				{ cities: ["Dusheti", "Mcheta"], stateName: "Mcheta-Mtianeti" },
				{ cities: ["Ambrolauri", "Cageri", "Oni"], stateName: "Racha" },
				{ cities: [], stateName: "Samagrelo-Zemo Svaneti" },
				{
					cities: ["Ahalcihe", "Ahalkalaki", "Borzhomi", "Ninocminda", "Vale"],
					stateName: "Samche-Zhavaheti",
				},
				{ cities: [], stateName: "Shida Kartli" },
				{ cities: ["Tbilisi"], stateName: "Tbilisi" },
			],
			countryName: "Georgia",
		},
		{
			states: [
				{ cities: [], stateName: "Auvergne" },
				{
					cities: [
						"Aalen",
						"Achern",
						"Aichtal",
						"Albstadt",
						"Aldingen",
						"Allmersbach",
						"Alpirsbach",
						"Altensteig",
						"Altlussheim",
						"Ammerbuch",
						"Appenweier",
						"Asperg",
						"Backnang",
						"Bad Durrheim",
						"Bad Friedrichshall",
						"Bad Krozingen",
						"Bad Liebenzell",
						"Bad Mergentheim",
						"Bad Rappenau",
						"Bad Sackingen",
						"Bad Schonborn",
						"Bad Urach",
						"Bad Waldsee",
						"Bad Wurzach",
						"Baden-Baden",
						"Bahlingen",
						"Baiersbronn",
						"Balgheim",
						"Balingen",
						"Ballrechten-Dottingen",
						"Besigheim",
						"Biberach",
						"Bietigheim-Bissingen",
						"Binzen",
						"Birkenfeld",
						"Bisingen",
						"Bitz",
						"Blaubeuren",
						"Blaustein",
						"Blumberg",
						"Boblingen",
						"Bodelshausen",
						"Bonndorf",
						"Bonnigheim",
						"Bopfingen",
						"Bottingen",
						"Brackenheim",
						"Breisach",
						"Bretten",
						"Bretzfeld",
						"Bruchsal",
						"Bruhl",
						"Buchen",
						"Buchenbach",
						"Buhl",
						"Burgstetten",
						"Burladingen",
						"Calw",
						"Crailsheim",
						"Denkendorf",
						"Denzlingen",
						"Dettingen",
						"Dietenheim",
						"Ditzingen",
						"Donaueschingen",
						"Donzdorf",
						"Dornhan",
						"Dornstadt",
						"Dornstetten",
						"Dossenheim",
						"Durbheim",
						"Durmersheim",
						"Eberbach",
						"Ebersbach",
						"Edingen",
						"Edingen-Neckarhausen",
						"Eggenstein-Leopoldshafen",
						"Ehingen",
						"Eislingen",
						"Ellhofen",
						"Ellwangen",
						"Emmendingen",
						"Emmingen-Liptingen",
						"Engen",
						"Eningen",
						"Eppelheim",
						"Eppingen",
						"Erbach",
						"Eriskirch",
						"Eschach",
						"Esslingen",
						"Ettenheim",
						"Ettlingen",
						"Fellbach",
						"Filderstadt",
						"Freiberg",
						"Freiburg",
						"Freudenstadt",
						"Frickenhausen",
						"Fridingen",
						"Friedrichshafen",
						"Friesenheim",
						"Frittlingen",
						"Furtwangen",
						"Gaggenau",
						"Gaildorf",
						"Gartringen",
						"Gaufelden",
						"Geislingen",
						"Gengenbach",
						"Gerlingen",
						"Gernsbach",
						"Gerstetten",
						"Giengen",
						"Goppingen",
						"Gottmadingen",
						"Graben-Neudorf",
						"Grafenberg",
						"Grafenhausen",
						"Grenzach-Wyhlen",
						"GroBbettlingen",
						"Grunkraut",
						"Gschwend",
						"Guglingen",
						"Gundelfingen",
						"Gutach",
						"Haigerloch",
						"Haiterbach",
						"Harmersabch",
						"Hausach",
						"Hechingen",
						"Heddesheim",
						"Heidelberg",
						"Heidenheim",
						"Heilbronn",
						"Hemsbach",
						"Herbrechtingen",
						"Herrenberg",
						"Heubach",
						"Hirschberg",
						"Hockenheim",
						"Holzgerlingen",
						"Horb",
						"Hufingen",
						"Huttlingen",
						"Isny",
						"Ispringen",
						"Jungingen",
						"Karlsbad",
						"Karlsruhe",
						"Kehl",
						"Kernen",
						"Ketsch",
						"Kieselbronn",
						"Kirchberg an der Jagst",
						"Kirchheim",
						"Kirchzarten",
						"Kisslegg",
						"Klettgau",
						"Kongen",
						"Konigsbach-Stein",
						"Konigsbronn",
						"Konstanz",
						"Korb",
						"Korntal-Munchingen",
						"Kornwestheim",
						"Kraichtal",
						"Kressbronn an Bodensee",
						"Kronau",
						"Kuessaberg",
						"Kunzelsau",
						"Ladenburg",
						"Lahr",
						"Laichingen",
						"Langenau",
						"Lauda-Konigshofen",
						"Lauffen",
						"Laupheim",
						"Leimen",
						"Leinfelden-Echterdingen",
						"Leingarten",
						"Lenningen",
						"Lenzkirch",
						"Leonberg",
						"Leutenbach",
						"Leutkirch",
						"Lichtenstein",
						"Linkenheim-Hochstetten",
						"Lorch",
						"Lorrach",
						"Ludwigsburg",
						"Malsch",
						"Mannheim",
						"Marbach",
						"Markdorf",
						"Markgroningen",
						"Maulburg",
						"MeBstetten",
						"Meckenbeuren",
						"Meckesheim",
						"Meersburg",
						"Mengen",
						"Metzingen",
						"Mockmuhl",
						"Moglingen",
						"Monsheim",
						"Mosbach",
						"Mossingen",
						"Muhlacker",
						"Mullheim",
						"Munsingen",
						"Murrhardt",
						"Nagold",
						"Neckargemund",
						"Neckarsulm",
						"Neresheim",
						"Neuenburg",
						"Neuhausen",
						"Niederstetten",
						"Niefern-Oschelbronn",
						"NuBloch",
						"Nurtingen",
						"Oberhausen-Rheinhausen",
						"Oberkirch",
						"Oberndorf",
						"Oberstenfeld",
						"Obersulm",
						"Ochsenhausen",
						"Offenburg",
						"Ofterdingen",
						"Oftersheim",
						"Oggelshausen",
						"Ohringen",
						"Olbronn-Durrn",
						"Oppenweiler",
						"Ostfildern",
						"Ostringen",
						"Otisheim",
						"Pfalzgrafenweiler",
						"Pfinztal",
						"Pforzheim",
						"Pfullendorf",
						"Pfullingen",
						"Philippsburg",
						"Plankstadt",
						"Pleidelsheim",
						"Pliezhausen",
						"Plochingen",
						"Pluderhausen",
						"Radolfzell",
						"Rastatt",
						"Ravensburg",
						"Reilingen",
						"Remchingen",
						"Remseck",
						"Remshalden",
						"Renchen",
						"Renningen",
						"Reutlingen",
						"Rheinau",
						"Rheinfelden",
						"Rheinmunster",
						"Rheinstetten",
						"Riederich",
						"Riedlingen",
						"Rielasingen-Worblingen",
						"Rosenfeld",
						"Rottenburg",
						"Rottweil",
						"Rudersberg",
						"Rutesheim",
						"Sachsenheim",
						"Salem",
						"Sandhausen",
						"Sankt Georgen",
						"Sankt Leon-Rot",
						"Saulgau",
						"Scheer",
						"Schlierbach",
						"Schonaich",
						"Schopfheim",
						"Schorndorf",
						"Schramberg",
						"Schriesheim",
						"Schwabisch Gmund",
						"Schwabisch Hall",
						"Schwaigern",
						"Schwetzingen",
						"Schwieberdingen",
						"Seitingen-Oberflacht",
						"Sexau",
						"Sigmaringen",
						"Sindelfingen",
						"Singen",
						"Sinsheim",
						"Sinzheim",
						"Sonnenbuhl",
						"Sontheim",
						"Spaichingen",
						"Stegen",
						"Steinen",
						"Steinheim",
						"Steinmauern",
						"Stockach",
						"Straubenhardt",
						"Stutensee",
						"Stuttgart",
						"SuBen",
						"Sulz",
						"Sulzfeld",
						"Tamm",
						"Tauberbischofsheim",
						"Teningen",
						"Tettnang",
						"Titisee-Neustadt",
						"Trossingen",
						"Tubingen",
						"Tuningen",
						"Tuttlingen",
						"Uberlingen",
						"Ubstadt-Weiher",
						"Uhingen",
						"Ulm",
						"Umkirch",
						"Vaihingen",
						"Villingen-Schwenningen",
						"Villingendorf",
						"Waghausel",
						"Waiblingen",
						"Waldbronn",
						"Waldkirch",
						"Waldlaubersheim",
						"Waldshut-Tiengen",
						"Walldorf",
						"Walldurn",
						"Walzbachtal",
						"Wangen",
						"Wehr",
						"Weikersheim",
						"Weil",
						"Weil am Rhein",
						"Weil der Stadt",
						"Weil im Schonbuch",
						"Weilheim",
						"Weingarten",
						"Weinheim",
						"Weinsberg",
						"Weinstadt",
						"Wellendingen",
						"Welzheim",
						"Wendlingen",
						"Wernau",
						"Wertheim",
						"Wiesloch",
						"Wildbad",
						"Wildberg",
						"Winnenden",
						"Wolpertshausen",
						"Zuzenhausen",
					],
					stateName: "Baden-Wurttemberg",
				},
				{
					cities: [
						"Ahorn",
						"Allershausen",
						"Attenhofen",
						"Bad Birnbach",
						"Bad Endorf",
						"Bad Gronenbach",
						"Barbing",
						"Benediktbeuern",
						"Breitenberg",
						"Bruckberg",
						"Brunnthal",
						"Burgheim",
						"Chiemsee",
						"Emersacker",
						"Eresing",
						"Fahrenzhausen",
						"Faulbach",
						"Finning",
						"Forstinning",
						"Georgensgmund",
						"Haldenwang",
						"Heideck",
						"Heimenkirch",
						"Hergensweiler",
						"Hirschau",
						"Iffeldorf",
						"Karlstein",
						"Kleinheubach",
						"Kleinwallstadt",
						"Kotz",
						"Krailling",
						"Langenbach",
						"Laudenbach",
						"Leutershausen",
						"Mammendorf",
						"Mombris",
						"Munnerstadt",
						"Neu-Ulm",
						"Niederlauer",
						"Obernburg",
						"Oberpfaffenhofen",
						"Ostallgau",
						"Prittriching",
						"Prutting",
						"Pullach",
						"Putzbrunn",
						"Randersacker",
						"Rednitzhembach",
						"Ronsberg",
						"Ruckersdorf",
						"Schaufling",
						"Schonberg",
						"Seefeld",
						"Sengenthal",
						"Sondheim v.d.Rhon",
						"Steinwiesen",
						"Stockheim",
						"Tagmersheim",
						"Uettingen",
						"Unterfohring",
						"Weibensberg",
						"Wiesthal",
						"Wildflecken",
						"Wolfertschwenden",
						"Zorneding",
					],
					stateName: "Bavaria",
				},
				{
					cities: [
						"Abensberg",
						"Aichach",
						"Ainring",
						"Altdorf",
						"Altotting",
						"Altusried",
						"Alzenau",
						"Amberg",
						"Amerang",
						"Ansbach",
						"Aschaffenburg",
						"Augsburg",
						"Bad Aibling",
						"Bad Kissingen",
						"Bad Neustadt",
						"Bad Reichenhall",
						"Bad Tolz",
						"Bad Windsheim",
						"Bad Worishofen",
						"Bamberg",
						"Bayreuth",
						"Bobingen",
						"Bogen",
						"Bruckmuhl",
						"Buchloe",
						"Burghausen",
						"Burgkirchen",
						"Burglengenfeld",
						"Burgthann",
						"Buttenheim",
						"Cadolzburg",
						"Castell",
						"Cham",
						"Coburg",
						"Dachau",
						"Deggendorf",
						"DieBen",
						"Diedorf",
						"Dietmannsried",
						"Dietramszell",
						"Dillingen",
						"Dingolfing",
						"Dinkelsbuhl",
						"Dombuhl",
						"Donauworth",
						"Dorfen",
						"Ebersberg",
						"Eching",
						"Eckental",
						"Eggenfelden",
						"Eichenau",
						"Eichstatt",
						"Elchingen",
						"Erding",
						"Ergolding",
						"Erlangen",
						"Erlenbach",
						"Essenbach",
						"Feldafing",
						"Feldkirchen-Westerham",
						"Feucht",
						"Feuchtwangen",
						"Forchheim",
						"Freilassing",
						"Freising",
						"Fridolfing",
						"Friedberg",
						"Furstenfeldbruck",
						"Furth",
						"Furth im Wald",
						"Fussen",
						"Gachenbach",
						"Gaimersheim",
						"Garching",
						"Garmisch-Partenkirchen",
						"Gauting",
						"Gemunden",
						"Geretsried",
						"Germering",
						"Gersthofen",
						"Gilching",
						"Goldbach",
						"Grafelfing",
						"Grafenau",
						"Grafing",
						"GroBostheim",
						"Grobenstadt",
						"Grobenzell",
						"Grunwald",
						"Gunzburg",
						"Gunzenhausen",
						"HaBfurt",
						"Haar",
						"Hammelburg",
						"Hasloch",
						"Hauzenberg",
						"Helmbrechts",
						"Henfenfeld",
						"Hersbruck",
						"Herzogenaurach",
						"Hilpoltstein",
						"Hirschaid",
						"Hochstadt",
						"Hof",
						"Holzkirchen",
						"Hosbach",
						"Illertissen",
						"Immenstadt",
						"Ingolstadt",
						"Inning",
						"Ismaning",
						"Karlsfeld",
						"Karlstadt",
						"Kaufbeuren",
						"Kelheim",
						"Kempten",
						"Kiefersfelden",
						"Kirchheim",
						"Kissing",
						"Kitzingen",
						"Kleinostheim",
						"Klingenberg",
						"Kolbermoor",
						"Konigsbrunn",
						"Kreuzwertheim",
						"Kronach",
						"Krumbach",
						"Kulmbach",
						"Kummersbruck",
						"Landau",
						"Landsberg",
						"Landshut",
						"Langenzenn",
						"Lappersdorf",
						"Lauf",
						"Lauingen",
						"Leinburg",
						"Lenggries",
						"Lichtenfels",
						"Lindach",
						"Lindau",
						"Lindenberg",
						"Lohr",
						"Mainburg",
						"Maisach",
						"Manching",
						"Markt Schwaben",
						"Marktheidenfeld",
						"Marktoberdorf",
						"Marktredwitz",
						"Maxhutte-Haidhof",
						"Meitingen",
						"Memmingen",
						"Mering",
						"Miesbach",
						"Miltenberg",
						"Mindelheim",
						"Moosburg",
						"Muhldorf",
						"Munchberg",
						"Munchen",
						"Munningen",
						"Murnau",
						"Naila",
						"Neubiberg",
						"Neuburg",
						"Neufahrn",
						"Neumarkt",
						"Neuried",
						"NeusaB",
						"Neustadt",
						"Neutraubling",
						"Nordlingen",
						"Nuremberg",
						"Nurnberg",
						"Oberasbach",
						"Oberhaching",
						"OberschleiBheim",
						"Oberstdorf",
						"Ochsenfurt",
						"Olching",
						"Osterhofen",
						"Ostheim",
						"Ottobrunn",
						"Parsberg",
						"Passau",
						"Pegnitz",
						"PeiBenberg",
						"Peiting",
						"Pentling",
						"Penzberg",
						"Pfaffenhofen",
						"Pfarrkirchen",
						"Planegg",
						"Plattling",
						"Pocking",
						"Poing",
						"Polling",
						"Pommelsbrunn",
						"Prien",
						"Puchheim",
						"Rannersdorf",
						"Raubling",
						"Regen",
						"Regensburg",
						"Regenstauf",
						"Rehau",
						"Rodental",
						"Roding",
						"Rosenheim",
						"Roth",
						"Rothenbach",
						"Rothenburg",
						"Ruhstorf",
						"Schnelldorf",
						"Schongau",
						"Schrobenhausen",
						"Schwabach",
						"Schwabmunchen",
						"Schwandorf",
						"Schwarzenbach",
						"Schweinfurt",
						"Selb",
						"Selbitz",
						"Senden",
						"Simbach",
						"Sonthofen",
						"Spalt",
						"Stadtbergen",
						"Staffelstein",
						"Starnberg",
						"Stein",
						"Stephanskirchen",
						"Straubing",
						"Sulzbach-Rosenberg",
						"Taufkirchen",
						"Thansau",
						"Tirschenreuth",
						"Toging",
						"Traunreut",
						"Traunstein",
						"Treuchtlingen",
						"Trostberg",
						"Tutzing",
						"Unterbergen",
						"Unterhaching",
						"UnterschleiBheim",
						"Valley",
						"Vaterstetten",
						"Veitshochheim",
						"Viechtach",
						"Vilgertshofen",
						"Vilsbiburg",
						"Vilshofen",
						"Vohringen",
						"Volkach",
						"Waldkirchen",
						"Waldkraiburg",
						"Walkertshofen",
						"Wasserburg",
						"WeiBenburg",
						"WeiBenhorn",
						"Weiden",
						"Weidenberg",
						"Weilheim",
						"Wendelstein",
						"Werneck",
						"Wessling",
						"Wolfratshausen",
						"Wolnzach",
						"Wunsiedel",
						"Wurzburg",
						"Zirndorf",
						"Zwiesel",
					],
					stateName: "Bayern",
				},
				{ cities: [], stateName: "Beilstein Wurtt" },
				{ cities: ["Berlin", "Panketal", "Steinfeld"], stateName: "Berlin" },
				{
					cities: [
						"Angermunde",
						"Bad Freienwalde",
						"Bad Liebenwerda",
						"Barnim",
						"Beeskow",
						"Bernau",
						"Blankenfelde",
						"Brandenburg",
						"Brieselang",
						"Cottbus",
						"Dahlewitz",
						"Dahme",
						"Eberswalde",
						"Eisenhuttenstadt",
						"Elsterwerda",
						"Erkner",
						"Falkensee",
						"Finsterwalde",
						"Forst",
						"Frankfurt",
						"Fredersdorf-Vogelsdorf",
						"Furstenwalde",
						"Glienicke",
						"Gransee",
						"GroBraschen",
						"Guben",
						"Heidesee",
						"Hennigsdorf",
						"Herzberg",
						"Hohen Neuendorf",
						"Jacobsdorf",
						"Juterbog",
						"Kleinmachnow",
						"Kolkwitz",
						"Konigs Wusterhausen",
						"Kyritz",
						"Lauchhammer",
						"Lubben",
						"Lubbenau",
						"Luckenwalde",
						"Ludwigsfelde",
						"Nauen",
						"Neuenhagen",
						"Neuruppin",
						"Oranienburg",
						"Perleberg",
						"Petershagen-Eggersdorf",
						"Potsdam",
						"Premnitz",
						"Prenzlau",
						"Pritzwalk",
						"Rathenow",
						"Rudersdorf",
						"Schonefeld",
						"Schoneiche",
						"Schwedt",
						"Schwielowsee",
						"Senftenberg",
						"Spremberg",
						"Strausberg",
						"Teltow",
						"Templin",
						"Velten",
						"Werder",
						"Wildau",
						"Wittenberge",
						"Wittstock",
						"Zehdenick",
						"Zepernick",
					],
					stateName: "Brandenburg",
				},
				{ cities: ["Bremen", "Bremerhaven"], stateName: "Bremen" },
				{ cities: [], stateName: "Dreisbach" },
				{ cities: [], stateName: "Freistaat Bayern" },
				{ cities: ["Berne", "Hamburg"], stateName: "Hamburg" },
				{ cities: ["Diekholzen"], stateName: "Hannover" },
				{ cities: ["Heroldstatt"], stateName: "Heroldstatt" },
				{
					cities: [
						"ABlar",
						"Alsbach-HÃÆÃÂ¤hnlein",
						"Alsfeld",
						"Altenstadt",
						"Angelburg",
						"Arolsen",
						"Asslar",
						"Babenhausen",
						"Bad Camberg",
						"Bad Hersfeld",
						"Bad Homburg",
						"Bad Nauheim",
						"Bad Orb",
						"Bad Schwalbach",
						"Bad Soden",
						"Bad Soden-Salmunster",
						"Bad Sooden-Allendorf",
						"Bad Vilbel",
						"Bad Wildungen",
						"Baunatal",
						"Bebra",
						"Beerfelden",
						"Bensheim",
						"Berkatal",
						"Biblis",
						"Bickenbach",
						"Biebertal",
						"Biedenkopf",
						"Birkenau",
						"Bischofsheim",
						"Borken",
						"Braunfels",
						"Breidenbach",
						"Bruchkobel",
						"Budingen",
						"Burstadt",
						"Buseck",
						"Buttelborn",
						"Butzbach",
						"Darmstadt",
						"Dautphetal",
						"Dieburg",
						"Dietzenbach",
						"Dillenburg",
						"Dreieich",
						"Egelsbach",
						"Eichenzell",
						"Eltville",
						"Eppstein",
						"Erbach",
						"Erlensee",
						"Erzhausen",
						"Eschborn",
						"Eschenburg",
						"Eschwege",
						"Felsberg",
						"Fernwald",
						"Florsheim",
						"Frankenberg",
						"Frankfurt",
						"Freigericht",
						"Friedberg",
						"Friedrichsdorf",
						"Fritzlar",
						"Fulda",
						"Fuldabruck",
						"Fuldatal",
						"Geisenheim",
						"Gelnhausen",
						"Gernsheim",
						"GieBen",
						"Giessen",
						"Ginsheim-Gustavsburg",
						"Gladenbach",
						"Griesheim",
						"GroB-Gerau",
						"GroB-Umstadt",
						"GroB-Zimmern",
						"Grossenluder",
						"Gruenberg",
						"Grunberg",
						"Grundau",
						"Hadamar",
						"Haiger",
						"Hainburg",
						"Hanau",
						"Hattersheim",
						"Heppenheim",
						"Herborn",
						"Hessisch Lichtenau",
						"Heuchelheim",
						"Heusenstamm",
						"Hochheim",
						"Hochst",
						"Hofbieber",
						"Hofgeismar",
						"Hofheim",
						"Homberg",
						"Hunfeld",
						"Hunfelden",
						"Hungen",
						"Huttenberg",
						"Idstein",
						"Karben",
						"Kassel",
						"Kaufungen",
						"Kelkheim",
						"Kelsterbach",
						"Kirchhain",
						"Konigstein",
						"Korbach",
						"Kriftel",
						"Kronberg",
						"Kunzell",
						"Lahnau",
						"Lahntal",
						"Lampertheim",
						"Langen",
						"Langenselbold",
						"Langgons",
						"Laubach",
						"Lauterbach",
						"Lich",
						"Limburg",
						"Linden",
						"Lindenholzhausen",
						"Linsengericht",
						"Lohfelden",
						"Lollar",
						"Lorsch",
						"Maintal",
						"Marburg",
						"Melsungen",
						"Mengerskirchen",
						"Michelstadt",
						"Morfelden-Walldorf",
						"Morlenbach",
						"Mucke",
						"Muhlheim",
						"Muhltal",
						"Munster",
						"Nauheim",
						"Neu-Anspach",
						"Neu-Isenburg",
						"Neuhof",
						"Neustadt",
						"Nidda",
						"Niddatal",
						"Nidderau",
						"Niederdorfelden",
						"Niedernhausen",
						"Niestetal",
						"Ober-Ramstedt",
						"Obertshausen",
						"Oberursel",
						"Oestrich-Winkel",
						"Offenbach",
						"Petersberg",
						"Pfungstadt",
						"Pohlheim",
						"Raunheim",
						"Reichelsheim",
						"Reinheim",
						"Reiskirchen",
						"Riedstadt",
						"Rimbach",
						"RoBdorf",
						"Rodenbach",
						"Rodermark",
						"Rodgau",
						"Rosbach",
						"Rotenburg",
						"Rudesheim",
						"Runkel",
						"Russelsheim",
						"Schauenburg",
						"Schlangenbad",
						"Schlitz",
						"Schluchtern",
						"Schoneck",
						"Schotten",
						"Schwalbach",
						"Schwalbach am Taunus",
						"Schwalmstadt",
						"Seeheim-Jugenheim",
						"Seligenstadt",
						"Selters",
						"Sinntal",
						"Solms",
						"Sontra",
						"Spangenberg",
						"Stadtallendorf",
						"Steinau",
						"Steinbach",
						"Taunusstein",
						"Trebur",
						"Ulrichstein",
						"Usingen",
						"Vellmar",
						"Viernheim",
						"Volkmarsen",
						"Wachtersbach",
						"Wald-Michelbach",
						"Waldbrunn",
						"Waldems",
						"Wehrheim",
						"Weilburg",
						"Weilmunster",
						"Weiterstadt",
						"Wettenberg",
						"Wetter",
						"Wetzlar",
						"Wiesbaden",
						"Witzenhausen",
						"Wolfersheim",
						"Wolfhagen",
						"Zwingenberg",
					],
					stateName: "Hessen",
				},
				{ cities: ["Kortenberg"], stateName: "Kortenberg" },
				{ cities: ["Laasdorf"], stateName: "Laasdorf" },
				{ cities: [], stateName: "Land Baden-Wurttemberg" },
				{ cities: [], stateName: "Land Bayern" },
				{ cities: [], stateName: "Land Brandenburg" },
				{ cities: [], stateName: "Land Hessen" },
				{ cities: [], stateName: "Land Mecklenburg-Vorpommern" },
				{ cities: [], stateName: "Land Nordrhein-Westfalen" },
				{ cities: [], stateName: "Land Rheinland-Pfalz" },
				{ cities: [], stateName: "Land Sachsen" },
				{ cities: [], stateName: "Land Sachsen-Anhalt" },
				{ cities: [], stateName: "Land Thuringen" },
				{ cities: [], stateName: "Lower Saxony" },
				{
					cities: [
						"Anklam",
						"Bad Doberan",
						"Barth",
						"Bergen",
						"Boizenburg",
						"Butzow",
						"Demmin",
						"Gagelow",
						"Gallin",
						"Grabow",
						"Greifswald",
						"Grevesmuhlen",
						"Grimmen",
						"Gustrow",
						"Hagenow",
						"Konigsee",
						"Lubtheen",
						"Ludersdorf",
						"Ludwigslust",
						"Malchin",
						"Neubrandenburg",
						"Neustrelitz",
						"Parchim",
						"Pasewalk",
						"Ribnitz-Damgarten",
						"Rostock",
						"SaBnitz",
						"Schweina",
						"Schwerin",
						"Selmsdorf",
						"Stralsund",
						"Teterow",
						"Torgelow",
						"Ueckermunde",
						"Waren",
						"Wismar",
						"Wolgast",
						"Zarrentin",
					],
					stateName: "Mecklenburg-Vorpommern",
				},
				{ cities: ["Mulfingen"], stateName: "Mulfingen" },
				{ cities: ["Grafing bei Munchen"], stateName: "Munich" },
				{ cities: ["Neubeuern"], stateName: "Neubeuern" },
				{
					cities: [
						"Achim",
						"Adendorf",
						"Aerzen",
						"Alfeld",
						"Ankum",
						"Apen",
						"Aurich",
						"Bad Bentheim",
						"Bad Bevensen",
						"Bad Essen",
						"Bad Gandersheim",
						"Bad Harzburg",
						"Bad Iburg",
						"Bad Laer",
						"Bad Lauterberg",
						"Bad Munder",
						"Bad Nenndorf",
						"Bad Pyrmont",
						"Bad Sachsa",
						"Bad Salzdetfurth",
						"Bad Zwischenahn",
						"BarBel",
						"Barsinghausen",
						"Bassum",
						"Beesten",
						"Belm",
						"Bergen",
						"Bissendorf",
						"Bleckede",
						"Bockenem",
						"Bohmte",
						"Bovenden",
						"Brake",
						"Bramsche",
						"Braunschweig",
						"Bremervorde",
						"Brockel",
						"Brunswick",
						"Buchholz",
						"Buckeburg",
						"Burgdorf",
						"Burgwedel",
						"Buxtehude",
						"Celle",
						"Clausthal-Zellerfeld",
						"Clenze",
						"Cloppenburg",
						"Cremlingen",
						"Cuxhaven",
						"Dahlenburg",
						"Damme",
						"Dannenberg",
						"Dassel",
						"Deinste",
						"Delmenhorst",
						"Diepholz",
						"Dinklage",
						"Dorverden",
						"Dransfeld",
						"Drochtersen",
						"Duderstadt",
						"Edemissen",
						"Edewecht",
						"Einbeck",
						"Elsfleth",
						"Elze",
						"Emden",
						"Emlichheim",
						"Emmerthal",
						"Emsburen",
						"Eppendorf",
						"Fallingbostel",
						"Frellstedt",
						"Friedeburg",
						"Friedland",
						"Friesoythe",
						"Ganderkesee",
						"Garbsen",
						"Garrel",
						"Geeste",
						"Gehrden",
						"Georgsmarienhutte",
						"Gifhorn",
						"Gleichen",
						"Goslar",
						"Gottingen",
						"GroBefehn",
						"GroBenkneten",
						"Hagen",
						"Hambergen",
						"Hameln",
						"Hannover",
						"Hannoversch Munden",
						"Haren",
						"Harsum",
						"Hasbergen",
						"Haselunne",
						"Hatten",
						"Hauslingen",
						"Helmstedt",
						"Hemmingen",
						"Herzberg",
						"Hessisch Oldendorf",
						"Hildesheim",
						"Hilter",
						"Hittfeld",
						"Holzminden",
						"Hoya",
						"Hude",
						"Ihlow",
						"Ilsede",
						"Isernhagen",
						"Jade",
						"Jever",
						"Jork",
						"Kalefeld",
						"Kirchlinteln",
						"Knesebeck",
						"Konigslutter",
						"Krummhorn",
						"Laatzen",
						"Lahstedt",
						"Langelsheim",
						"Langen",
						"Langenhagen",
						"Langwedel",
						"Lastrup",
						"Leer",
						"Lehre",
						"Lehrte",
						"Lemforde",
						"Lengede",
						"Liebenburg",
						"Lilienthal",
						"Lingen",
						"Lohne",
						"Loningen",
						"Lorup",
						"Loxstedt",
						"Luchow",
						"Luneburg",
						"Melle",
						"Meppen",
						"Moormerland",
						"Munster",
						"Neu Wulmstorf",
						"Neustadt",
						"Nienburg",
						"Norden",
						"Nordenham",
						"Nordhorn",
						"Nordstemmen",
						"Norten-Hardenberg",
						"Northeim",
						"Obernkirchen",
						"Oldenburg",
						"Osnabruck",
						"Ostercappeln",
						"Osterholz-Scharmbeck",
						"Osterode",
						"Ostrhauderfehn",
						"Ottersberg",
						"Oyten",
						"Papenburg",
						"Pattensen",
						"Peine",
						"Quakenbruck",
						"Rastede",
						"Rehburg-Loccum",
						"Rhauderfehn",
						"Rinteln",
						"Ritterhude",
						"Ronnenberg",
						"Rosdorf",
						"Rosengarten",
						"Rotenburg",
						"Salzgitter",
						"Salzhemmendorf",
						"Sarstedt",
						"Saterland",
						"ScheeBel",
						"Schiffdorf",
						"Schneverdingen",
						"Schoningen",
						"Schortens",
						"Schuttorf",
						"Schwanewede",
						"Seelze",
						"Seesen",
						"Seevetal",
						"Sehnde",
						"Soltau",
						"Springe",
						"Stade",
						"Stadthagen",
						"Stadtoldendorf",
						"Stelle",
						"Stuhr",
						"Sudbrookmerland",
						"Sulingen",
						"Syke",
						"Tarmstedt",
						"Tostedt",
						"Twistringen",
						"Uchte",
						"Uelzen",
						"Uetze",
						"Uplengen",
						"Uslar",
						"Varel",
						"Vechelde",
						"Vechta",
						"Verden",
						"Vienenburg",
						"Visselhovede",
						"Walkenried",
						"Wallenhorst",
						"Walsrode",
						"Wangerland",
						"Wardenburg",
						"Wedemark",
						"Weener",
						"Wendeburg",
						"Wennigsen",
						"Westerstede",
						"Westoverledingen",
						"Weyhe",
						"Wiefelstede",
						"Wiesmoor",
						"Wildeshausen",
						"Wilhelmshaven",
						"Winsen",
						"Wittingen",
						"Wittmund",
						"Wolfenbuttel",
						"Wolfsburg",
						"Worpswede",
						"Wunstorf",
						"Zetel",
						"Zeven",
					],
					stateName: "Niedersachsen",
				},
				{ cities: ["Middenbeemster"], stateName: "Noord-Holland" },
				{
					cities: [
						"Aachen",
						"Ahaus",
						"Ahlen",
						"Aldenhoven",
						"Alfter",
						"Alpen",
						"Alsdorf",
						"Altena",
						"Altendorf",
						"Anrochte",
						"Arnsberg",
						"Ascheberg",
						"Attendorn",
						"Augustdorf",
						"Bad Berleburg",
						"Bad Driburg",
						"Bad Honnef",
						"Bad Laasphe",
						"Bad Lippspringe",
						"Bad Munstereifel",
						"Bad Oeynhausen",
						"Bad Salzuflen",
						"Bad Sassendorf",
						"Baesweiler",
						"Balve",
						"Barntrup",
						"Beckum",
						"Bedburg",
						"Bedburg-Hau",
						"Bergheim",
						"Bergisch Gladbach",
						"Bergkamen",
						"Bergneustadt",
						"Bestwig",
						"Beverungen",
						"Bielefeld",
						"Billerbeck",
						"Blomberg",
						"Bocholt",
						"Bochum",
						"Bocket",
						"Bonen",
						"Bonn",
						"Borchen",
						"Borgentreich",
						"Borgholzhausen",
						"Borken",
						"Bornheim",
						"Bottrop",
						"Brakel",
						"Brilon",
						"Bruggen",
						"Bruhl",
						"Bunde",
						"Burbach",
						"Buren",
						"Burscheid",
						"Castrop-Rauxel",
						"Coesfeld",
						"Cologne",
						"Datteln",
						"Delbruck",
						"Denklingen",
						"Detmold",
						"Dingden",
						"Dinslaken",
						"Dormagen",
						"Dorsten",
						"Dortmund",
						"Drensteinfurt",
						"Drolshagen",
						"Duisburg",
						"Dulmen",
						"Duren",
						"Dusseldorf",
						"Eitorf",
						"Elsdorf",
						"Emmerich",
						"Emsdetten",
						"Engelskirchen",
						"Enger",
						"Ennepetal",
						"Ennigerloh",
						"Ense",
						"Erftstadt",
						"Erkelenz",
						"Erkrath",
						"Erwitte",
						"Espelkamp",
						"Essen",
						"Euskirchen",
						"Extertal",
						"Finnentrop",
						"Frechen",
						"Freudenberg",
						"Frondenberg",
						"Gangelt",
						"Geilenkirchen",
						"Geldern",
						"Gelsenkirchen",
						"Gescher",
						"Geseke",
						"Gevelsberg",
						"Gladbeck",
						"Goch",
						"Grefrath",
						"Greven",
						"Grevenbroich",
						"Gronau",
						"Gummersbach",
						"Gutersloh",
						"Haan",
						"Hagen",
						"Halle",
						"Haltern",
						"Halver",
						"Hamm",
						"Hamminkeln",
						"Harsewinkel",
						"Hattingen",
						"Havixbeck",
						"Heiligenhaus",
						"Heinsberg",
						"Hemer",
						"Hennef",
						"Herdecke",
						"Herford",
						"Herne",
						"Herten",
						"Herzebrock-Clarholz",
						"Herzogenrath",
						"Hiddenhausen",
						"Hilchenbach",
						"Hilden",
						"Hille",
						"Holzwickede",
						"Horn-Bad Meinberg",
						"Horstel",
						"Hovelhof",
						"Hoxter",
						"Huckelhoven",
						"Huckeswagen",
						"Hullhorst",
						"Hunxe",
						"Hurth",
						"Ibbenburen",
						"Iserlohn",
						"Isselburg",
						"Issum",
						"Juchen",
						"Julich",
						"Kaarst",
						"Kalkar",
						"Kall",
						"Kalletal",
						"Kamen",
						"Kamp-Lintfort",
						"Kempen",
						"Kerken",
						"Kerpen",
						"Kevelaer",
						"Kierspe",
						"Kirchhundem",
						"Kirchlengern",
						"Kleve",
						"Koln",
						"Konigswinter",
						"Korschenbroich",
						"Krefeld",
						"Kreuzau",
						"Kreuztal",
						"Kurten",
						"Lage",
						"Langenfeld",
						"Langerwehe",
						"Leichlingen",
						"Lemgo",
						"Lengerich",
						"Lennestadt",
						"Leopoldshohe",
						"Leverkusen",
						"Lichtenau",
						"Lindlar",
						"Linnich",
						"Lippetal",
						"Lippstadt",
						"Lohmar",
						"Lohne",
						"Lotte",
						"Lubbecke",
						"Ludenscheid",
						"Ludinghausen",
						"Lugde",
						"Lunen",
						"Marienheide",
						"Marl",
						"Marsberg",
						"Mechernich",
						"Meckenheim",
						"Meerbusch",
						"Meinerzhagen",
						"Menden",
						"Meschede",
						"Mettingen",
						"Mettmann",
						"Minden",
						"Moers",
						"Mohnesee",
						"Monchengladbach",
						"Monheim",
						"Monschau",
						"Morsbach",
						"Much",
						"Mulheim",
						"Munster",
						"Netphen",
						"Nettetal",
						"Neuenkirchen",
						"Neuenrade",
						"Neukirchen-Vluyn",
						"Neunkirchen",
						"Neunkirchen-Seelscheid",
						"Neuss",
						"Nideggen",
						"Niederkassel",
						"Niederkruchten",
						"Niederzier",
						"Nordkirchen",
						"Norvenich",
						"Nottuln",
						"Numbrecht",
						"Oberhausen",
						"Ochtrup",
						"Odenthal",
						"Oelde",
						"Oer-Erkenschwick",
						"Oerlinghausen",
						"Olfen",
						"Olpe",
						"Olsberg",
						"Overath",
						"Paderborn",
						"Petershagen",
						"Plettenberg",
						"Porta Westfalica",
						"PreuBisch Oldendorf",
						"Pulheim",
						"Radevormwald",
						"Raesfeld",
						"Rahden",
						"Ratingen",
						"Recke",
						"Recklinghausen",
						"Rees",
						"Reichshof",
						"Reken",
						"Remscheid",
						"Rheda-Wiedenbruck",
						"Rhede",
						"Rheinbach",
						"Rheinberg",
						"Rheine",
						"Rietberg",
						"Rommerskirchen",
						"Rosendahl",
						"Rosrath",
						"Ruthen",
						"Salzkotten",
						"Sassenberg",
						"Schalksmuhle",
						"Schermbeck",
						"Schieder-Schwalenberg",
						"Schleiden",
						"SchloB Holte-Stukenbrock",
						"Schmallenberg",
						"Schwalmtal",
						"Schwelm",
						"Schwerte",
						"Selm",
						"Senden",
						"Sendenhorst",
						"Siegburg",
						"Siegen",
						"Simmerath",
						"Soest",
						"Solingen",
						"Sonsbeck",
						"Spenge",
						"Sprockhovel",
						"Stadtlohn",
						"Steinfurt",
						"Steinhagen",
						"Steinheim",
						"Stemwede",
						"Stolberg",
						"Straelen",
						"Sundern",
						"Swisttal",
						"Tecklenburg",
						"Telgte",
						"Tonisvorst",
						"Troisdorf",
						"Ubach-Palenberg",
						"Unna",
						"Velbert",
						"Velen",
						"Verl",
						"Versmold",
						"Viersen",
						"Vlotho",
						"Voerde",
						"Vreden",
						"Wachtberg",
						"Wachtendonk",
						"Wadersloh",
						"Waldbrol",
						"Waltrop",
						"Warburg",
						"Warendorf",
						"Warstein",
						"Wassenberg",
						"Weeze",
						"Wegberg",
						"Weilerswist",
						"Welver",
						"Wenden",
						"Werdohl",
						"Werl",
						"Wermelskirchen",
						"Werne",
						"Werther",
						"Wesel",
						"Wesseling",
						"Westerkappeln",
						"Wetter",
						"Wickede",
						"Wiehl",
						"Willich",
						"Wilnsdorf",
						"Windeck",
						"Winterberg",
						"Wipperfurth",
						"Witten",
						"Wulfrath",
						"Wunnenberg",
						"Wuppertal",
						"Wurselen",
						"Xanten",
						"Zulpich",
					],
					stateName: "Nordrhein-Westfalen",
				},
				{ cities: [], stateName: "North Rhine-Westphalia" },
				{ cities: ["Herzberg am Harz"], stateName: "Osterode" },
				{
					cities: [
						"Alzey",
						"Andernach",
						"Bad Durkheim",
						"Bad Ems",
						"Bad Kreuznach",
						"Bad Neuenahr-Ahrweiler",
						"Bendorf",
						"Betzdorf",
						"Bingen",
						"Bitburg",
						"Bobenheim-Roxheim",
						"Bohl-Iggelheim",
						"Boppard",
						"Daun",
						"Diez",
						"Eisenberg",
						"Essingen",
						"Frankenthal",
						"Gau-Odernheim",
						"Germersheim",
						"Grunstadt",
						"HaBloch",
						"Hahnstatten",
						"Hallschlag",
						"Herschbach",
						"Herxheim",
						"Hirschhorn",
						"Hohr-Grenzhausen",
						"Holzheim",
						"Idar-Oberstein",
						"Ingelheim",
						"Kaisersesch",
						"Kaiserslautern",
						"Kastellaun",
						"Kindsbach",
						"Kirchen",
						"Kirn",
						"Koblenz",
						"Lahnstein",
						"Landau",
						"Limburgerhof",
						"Luckenburg",
						"Ludwigshafen",
						"Mainz",
						"Mayen",
						"Montabaur",
						"Morbach",
						"Mulheim-Karlich",
						"Mundersbach",
						"Mutterstadt",
						"Nassau",
						"Neitersen",
						"Neustadt",
						"Neuwied",
						"Niederzissen",
						"Pirmasens",
						"Plaidt",
						"Remagen",
						"Schifferstadt",
						"Schoenenberg Kuebelberg",
						"Sinzig",
						"Speyer",
						"St. Goar",
						"Trier",
						"Vallendar",
						"Winterbach",
						"Wittlich",
						"Worms",
						"Worth",
						"Zweibrucken",
					],
					stateName: "Rheinland-Pfalz",
				},
				{
					cities: [
						"Adenau",
						"Anhausen",
						"Barbelroth",
						"Berndroth",
						"Bernkastel-Kues",
						"Burgbrohl",
						"Dieblich",
						"Dierdorf",
						"Dreisbach",
						"Elsoff",
						"Enkenbach-Alsenborn",
						"Etzbach",
						"Flonheim",
						"Fohren",
						"Grafschaft",
						"Hochspeyer",
						"Leiningen",
						"Moschheim",
						"Murlenbach",
						"Neuhofen",
						"Nievern",
						"Norken",
						"Oberlahr",
						"Otterstadt",
						"Rennerod",
						"Rheinbreitbach",
						"Rieschweiler-Muhlbach",
						"Saarburg",
						"Stahlhofen",
						"Steinebach",
						"Weinsheim",
						"Winnweiler",
						"Wissen",
					],
					stateName: "Rhineland-Palatinate",
				},
				{
					cities: [
						"Beckingen",
						"Bexbach",
						"Blieskastel",
						"Dillingen",
						"Duppenweiler",
						"Eppelborn",
						"Friedrichsthal",
						"GroBrosseln",
						"Heusweiler",
						"Homburg",
						"Illingen",
						"Kirkel",
						"Kleinblittersdorf",
						"Lebach",
						"Losheim",
						"Mandelbachtal",
						"Marpingen",
						"Merchweiler",
						"Merzig",
						"Mettlach",
						"Nalbach",
						"Neunkirchen",
						"Nohfelden",
						"Nonnweiler",
						"Oberthal",
						"Ottweiler",
						"Puttlingen",
						"Quierschied",
						"Rehlingen-Siersburg",
						"Riegelsberg",
						"Saarbrucken",
						"Saarlouis",
						"Saarwellingen",
						"Sankt Ingbert",
						"Sankt Wendel",
						"Schiffweiler",
						"Schmelz",
						"Schwalbach",
						"Spiesen-Elversberg",
						"Sulzbach",
						"Tholey",
						"Uberherrn",
						"Volklingen",
						"Wadern",
						"Wadgassen",
						"Wallerfangen",
						"Weiskirchen",
					],
					stateName: "Saarland",
				},
				{
					cities: [
						"Annaberg-Buchholz",
						"Aue",
						"Auerbach",
						"Bautzen",
						"Bischofswerda",
						"Borna",
						"Brand-Erbisdorf",
						"Burgstadt",
						"Chemnitz",
						"Coswig",
						"Crimmitschau",
						"Delitzsch",
						"Dobeln",
						"Dresden",
						"Ebersbach",
						"Eilenburg",
						"Falkenstein",
						"Floha",
						"Frankenberg",
						"Freiberg",
						"Freital",
						"Friedewald",
						"Glauchau",
						"Gorlitz",
						"Grimma",
						"GroBenhain",
						"Groditz",
						"Hainichen",
						"Heidenau",
						"Hirschstein",
						"Hohenstein-Ernstthal",
						"Hoyerswerda",
						"Kamenz",
						"Klingenthal",
						"Leipzig",
						"Lichtenstein",
						"Limbach-Oberfrohna",
						"LoBnitz",
						"Lobau",
						"Lugau",
						"Marienberg",
						"Markkleeberg",
						"Meerane",
						"MeiBen",
						"Mittweida",
						"Muldenhammer",
						"Neustadt",
						"Niesky",
						"Oelsnitz",
						"Olbernhau",
						"Olbersdorf",
						"Oschatz",
						"Pirna",
						"Plauen",
						"Radeberg",
						"Radebeul",
						"Reichenbach",
						"Riesa",
						"Rietschen",
						"Schkeuditz",
						"Schneeberg",
						"Schwarzenberg",
						"Sebnitz",
						"Stollberg",
						"Taubenheim",
						"Taucha",
						"Thalheim",
						"Torgau",
						"Waldheim",
						"WeiBwasser",
						"Werdau",
						"Wilkau-HaBlau",
						"Wurzen",
						"Zittau",
						"Zschopau",
						"Zwickau",
						"Zwonitz",
					],
					stateName: "Sachsen",
				},
				{
					cities: [
						"Aken",
						"Aschersleben",
						"Bad Durrenberg",
						"Bebitz",
						"Bernburg",
						"Bitterfeld",
						"Blankenburg",
						"Braunsbedra",
						"Burg",
						"Calbe",
						"Coswig",
						"Dessau",
						"Eisleben",
						"Gardelegen",
						"Genthin",
						"Gommern",
						"Grafenhainichen",
						"Halberstadt",
						"Haldensleben",
						"Halle",
						"Hettstedt",
						"Heyrothsberge",
						"Hotensleben",
						"Kothen",
						"Leuna",
						"Magdeburg",
						"Merseburg",
						"Naumburg",
						"Oschersleben",
						"Osterburg",
						"Osterwieck",
						"Quedlinburg",
						"Querfurt",
						"Raguhn",
						"RoBlau",
						"Salzwedel",
						"Sangerhausen",
						"Schonebeck",
						"StaBfurt",
						"Stendal",
						"Tangermunde",
						"Thale",
						"WeiBenfels",
						"Wittenberg",
						"Wolfen",
						"Wolmirstedt",
						"Zeitz",
						"Zerbst",
					],
					stateName: "Sachsen-Anhalt",
				},
				{
					cities: [
						"Bad Lausick",
						"Bernsdorf",
						"Borde-Hakel",
						"Gelenau",
						"Groberkmannsdorf ",
						"Hartha",
						"Kreischa",
						"Malschwitz",
						"Naunhof",
						"Pausa",
						"Seiffen",
						"Stutzengrun",
					],
					stateName: "Saxony",
				},
				{
					cities: [
						"Ahrensbok",
						"Ahrensburg",
						"Altenholz",
						"Alveslohe",
						"Ammersbek",
						"Bad Bramstedt",
						"Bad Oldesloe",
						"Bad Schwartau",
						"Bad Segeberg",
						"Bargteheide",
						"Barmstedt",
						"Barsbuttel",
						"Bredstedt",
						"Brunsbuttel",
						"Budelsdorf",
						"Eckernforde",
						"Eddelak",
						"Elmshorn",
						"Eutin",
						"Flensburg",
						"Friedrichstadt",
						"Geesthacht",
						"Glinde",
						"Gluckstadt",
						"Grob Pampau",
						"Halstenbek",
						"Hamfelde",
						"Harrislee",
						"Hartenholm",
						"Heide",
						"Heiligenhafen",
						"Henstedt-Ulzburg",
						"Honenwestedt",
						"Husum",
						"Itzehoe",
						"Kaltenkirchen",
						"Kappeln",
						"Kiel",
						"Kronshagen",
						"Lauenburg",
						"Lensahn",
						"Lubeck",
						"Malente",
						"Mielkendorf",
						"Molfsee",
						"Molln",
						"Neuenbrook",
						"Neumunster",
						"Neustadt",
						"Norderstedt",
						"Oldenburg",
						"Oststeinbek",
						"Pinneberg",
						"Plon",
						"Preetz",
						"Quickborn",
						"Ratekau",
						"Ratzeburg",
						"Reinbek",
						"Reinfeld",
						"Rellingen",
						"Rendsburg",
						"Rethwisch",
						"Satrup",
						"Scharbeutz",
						"Schenefeld",
						"Schleswig",
						"Schmalfeld",
						"Schoenkirchen",
						"Schwarzenbek",
						"Seefeld",
						"Sievershutten",
						"Stockelsdorf",
						"Tangstedt",
						"Timmendorfer Strand",
						"Tornesch",
						"Travemunde",
						"Uetersen",
						"Wahlstedt",
						"Wedel",
						"Wentorf",
						"Westerland",
						"Westerronfeld",
						"Wohltorf",
						"Wotersen",
					],
					stateName: "Schleswig-Holstein",
				},
				{
					cities: [
						"Altenburg",
						"Apolda",
						"Arnstadt",
						"Bad Frankenhausen",
						"Bad Langensalza",
						"Bad Salzungen",
						"Cursdorf",
						"Dornburg",
						"Eisenach",
						"Eisenberg",
						"Erfurt",
						"Gera",
						"Geschwenda",
						"Gotha",
						"Greiz",
						"Heiligenstadt",
						"Hermsdorf",
						"Hildburghausen",
						"Ilmenau",
						"Immelborn",
						"Jena",
						"Leinefelde",
						"Leutenberg",
						"Meiningen",
						"Meuselwitz",
						"Muhlhausen",
						"Neustadt",
						"Nordhausen",
						"PoBneck",
						"Rosenthal",
						"Rositz",
						"Rudolstadt",
						"Ruhla",
						"Saalfeld",
						"Schmalkalden",
						"Schmolln",
						"Sommerda",
						"Sondershausen",
						"Sonneberg",
						"Suhl",
						"Triptis",
						"Uhlstadt",
						"Waltershausen",
						"Weida",
						"Weimar",
						"Wernigerode",
						"Wormstedt",
						"Zella-Mehlis",
						"Zeulenroda",
					],
					stateName: "Thuringia",
				},
				{ cities: ["Webling"], stateName: "Webling" },
				{ cities: ["Neustadt"], stateName: "Weinstrabe" },
				{ cities: ["Schlobborn"], stateName: "schlobborn" },
			],
			countryName: "Germany",
		},
		{
			states: [
				{
					cities: [
						"Agogo",
						"Bekwai",
						"Konongo",
						"Kumasi",
						"Mampong",
						"Mankranso",
						"Obuasi",
						"Ofinso",
						"Tafo",
					],
					stateName: "Ashanti",
				},
				{
					cities: [
						"Bechem",
						"Berekum",
						"Duayaw Nkwanta",
						"Kintampo",
						"Sunyani",
						"Techiman",
						"Wenchi",
					],
					stateName: "Brong-Ahafo",
				},
				{
					cities: [
						"Apam",
						"Cape Coast",
						"Dunkwa",
						"Elmina",
						"Foso",
						"Komenda",
						"Mauri",
						"Mumford",
						"Nyakrom",
						"Okitsiu",
						"Saltpond",
						"Swedru",
						"Winneba",
					],
					stateName: "Central",
				},
				{
					cities: [
						"Aburi",
						"Ada",
						"Akim Swedru",
						"Akropong",
						"Asamankese",
						"Begoro",
						"Kade",
						"Kibi",
						"Koforidua",
						"Mpraeso",
						"Nkawkaw",
						"Nsawam",
						"Oda",
						"Somanya",
						"Suhum",
					],
					stateName: "Eastern",
				},
				{ cities: [], stateName: "Greater Accra" },
				{
					cities: ["Kpandae", "Salaga", "Savelugu", "Tamale", "Yendi"],
					stateName: "Northern",
				},
				{ cities: [], stateName: "Upper East" },
				{ cities: [], stateName: "Upper West" },
				{
					cities: [
						"Aflao",
						"Anloga",
						"Ho",
						"Hohoe",
						"Keta",
						"Kete-Krachi",
						"Kpandu",
					],
					stateName: "Volta",
				},
				{
					cities: [
						"Aboso",
						"Anomabu",
						"Axim",
						"Bibiani",
						"Prestea",
						"Sekondi",
						"Shama",
						"Takoradi",
						"Tarkwa",
					],
					stateName: "Western",
				},
			],
			countryName: "Ghana",
		},
		{
			states: [{ cities: ["Gibraltar"], stateName: "Gibraltar" }],
			countryName: "Gibraltar",
		},
		{
			states: [
				{ cities: ["Elassonos"], stateName: "Acharnes" },
				{ cities: ["Aiyion", "Patra"], stateName: "Ahaia" },
				{ cities: [], stateName: "Aitolia kai Akarnania" },
				{ cities: ["Argos", "Navplion"], stateName: "Argolis" },
				{ cities: ["Tripoli"], stateName: "Arkadia" },
				{ cities: ["Arta"], stateName: "Arta" },
				{
					cities: [
						"Acharnes",
						"Agios Ioannis Rentis",
						"Drapetsona",
						"Koropi",
						"Lavrion",
						"Mandra",
						"Spata",
					],
					stateName: "Attica",
				},
				{
					cities: [
						"Aharna",
						"Aiyaleo",
						"Alimos",
						"Amarousion",
						"Ano Liosia",
						"Aryiroupoli",
						"Aspropirgos",
						"Athina",
						"Athinai",
						"Ayia Barbara",
						"Ayia Paraskevi",
						"Ayios Anaryiros",
						"Ayios Dimitrios",
						"Dafne",
						"Elevsis",
						"Ellenikon",
						"Galatsion",
						"Glifada",
						"Haidarion",
						"Halandrion",
						"Holargos",
						"Ilioupoli",
						"Iraklion",
						"Kaisariani",
						"Kallithea",
						"Kamateron",
						"Keratea",
						"Keratsinion",
						"Kifisia",
						"Koridallos",
						"Kropion",
						"Markopoulos Mesogaia",
						"Maroussi",
						"Megara",
						"Melission",
						"Metamorfosios",
						"Moshatos",
						"Nea Filedelfia",
						"Nea Ionia",
						"Nea Liosia",
						"Nea Smirni",
						"Nikaia",
						"Palaion Faliron",
						"Perama",
						"Peristerion",
						"Petroupoli",
						"Pevka",
						"Piraeus",
						"Salamis",
						"Tavros",
						"Viron",
						"Voula",
						"Vrilission",
						"Zografos",
					],
					stateName: "Attiki",
				},
				{ cities: [], stateName: "Ayion Oros" },
				{ cities: ["Heraklion"], stateName: "Crete" },
				{
					cities: ["Arhangelos", "Ialysos", "Kos", "Rodos"],
					stateName: "Dodekanisos",
				},
				{ cities: ["Drama"], stateName: "Drama" },
				{ cities: ["Chalkis"], stateName: "Evia" },
				{ cities: ["Karpenisi"], stateName: "Evritania" },
				{ cities: ["Alexandroupoli", "Orestias"], stateName: "Evros" },
				{ cities: ["Halkida"], stateName: "Evvoia" },
				{ cities: ["Florina"], stateName: "Florina" },
				{ cities: ["Amfissa"], stateName: "Fokis" },
				{ cities: ["Lamia"], stateName: "Fthiotis" },
				{ cities: ["Grevena"], stateName: "Grevena" },
				{ cities: ["Halandri"], stateName: "Halandri" },
				{
					cities: ["Lakkoma", "N. Kallikrateia", "Poliyiros"],
					stateName: "Halkidiki",
				},
				{ cities: ["Hania"], stateName: "Hania" },
				{ cities: ["Crete"], stateName: "Heraklion" },
				{ cities: ["Hios"], stateName: "Hios" },
				{ cities: ["Pirgos"], stateName: "Ilia" },
				{ cities: ["Veroia"], stateName: "Imathia" },
				{ cities: ["Ioannina"], stateName: "Ioannina" },
				{ cities: [], stateName: "Iraklion" },
				{ cities: ["Karditsa"], stateName: "Karditsa" },
				{ cities: ["Kastoria"], stateName: "Kastoria" },
				{ cities: ["Kavala"], stateName: "Kavala" },
				{ cities: ["Agioi Theodoroi", "Argostolion"], stateName: "Kefallinia" },
				{ cities: ["Kerkira"], stateName: "Kerkira" },
				{ cities: ["Ermoupoli", "Fira", "Mikonos"], stateName: "Kiklades" },
				{ cities: ["Kilkis"], stateName: "Kilkis" },
				{ cities: ["Korinthos"], stateName: "Korinthia" },
				{ cities: ["Kozani", "Ptolemais"], stateName: "Kozani" },
				{ cities: ["Sparti"], stateName: "Lakonia" },
				{ cities: ["Larisa", "Larissa"], stateName: "Larisa" },
				{
					cities: ["Ayios Nikolaos", "Ierapetra", "Sitia"],
					stateName: "Lasithi",
				},
				{ cities: ["Mitilini"], stateName: "Lesvos" },
				{ cities: ["Levkas"], stateName: "Levkas" },
				{ cities: ["Volos"], stateName: "Magnisia" },
				{ cities: ["Kalamata"], stateName: "Messinia" },
				{ cities: [], stateName: "Nomos Attikis" },
				{ cities: [], stateName: "Nomos Zakynthou" },
				{ cities: ["Edessa", "Yiannitsa"], stateName: "Pella" },
				{ cities: ["Katerini"], stateName: "Pieria" },
				{ cities: ["Acharne", "Pallini"], stateName: "Piraios" },
				{ cities: ["Preveza"], stateName: "Preveza" },
				{ cities: ["Rethimnon"], stateName: "Rethimni" },
				{ cities: ["Komotini"], stateName: "Rodopi" },
				{ cities: ["Samos"], stateName: "Samos" },
				{ cities: ["Serrai"], stateName: "Serrai" },
				{ cities: ["Igoumenitsa"], stateName: "Thesprotia" },
				{
					cities: [
						"Ampelokipa",
						"Kalamaria",
						"Neapoli",
						"Oristiada",
						"Thessaloniki",
					],
					stateName: "Thessaloniki",
				},
				{ cities: ["Trikala"], stateName: "Trikala" },
				{ cities: ["Levadia", "Thivai"], stateName: "Voiotia" },
				{ cities: [], stateName: "West Greece" },
				{ cities: ["Xanthi"], stateName: "Xanthi" },
				{ cities: ["Zakinthos"], stateName: "Zakinthos" },
			],
			countryName: "Greece",
		},
		{
			states: [
				{
					cities: ["Aasiaat", "Akunnaaq", "Kitsissuarsuit"],
					stateName: "Aasiaat",
				},
				{
					cities: [
						"Ikkatteq",
						"Isortoq",
						"Kulusuk",
						"Kuumiut",
						"Qernertuarssuit",
						"Sermiligaaq",
						"Tasiilaq",
						"Tiniteqilaaq",
					],
					stateName: "Ammassalik",
				},
				{
					cities: ["Illoqqortoormiut", "Itterajivit", "Uunarteq"],
					stateName: "Illoqqortoormiut",
				},
				{
					cities: ["Ilimanaq", "Ilulissat", "Oqaatsut", "Qeqertaq", "Saqqaq"],
					stateName: "Ilulissat",
				},
				{ cities: ["Ivittuut", "Kangilinnguit"], stateName: "Ivittuut" },
				{
					cities: [
						"Attu",
						"Iginniarfik",
						"Ikerasaarsuk",
						"Kangaatsiaq",
						"Niaqornaarsuk",
					],
					stateName: "Kangaatsiaq",
				},
				{
					cities: ["Atammik", "Kangaamiut", "Maniitsoq", "Napasoq"],
					stateName: "Maniitsoq",
				},
				{
					cities: [
						"Aappilattoq",
						"Akuliaruseq",
						"Alluitsoq",
						"Alluitsup Paa",
						"Ammassivik",
						"Ikerasassuaq",
						"Nanortalik",
						"Narsarmijit",
						"Nuugaarsuk",
						"Qallimiut",
						"Qortortorsuaq",
						"Tasiusaq",
					],
					stateName: "Nanortalik",
				},
				{
					cities: [
						"Amannguit",
						"Atarnaatsoq",
						"Eqaluit Ilua",
						"Igaliku",
						"Igaliku Kujalleq",
						"Inneruulalik",
						"Issormiut",
						"Iterlak",
						"Kangerlua",
						"Narsaq",
						"Narsarsuaq",
						"Nunataaq",
						"Qassiarsuk",
						"Qinngua",
						"Qinngua Kangilleq",
						"Qolortup Itinnera",
						"Sillisit",
						"Tasiusaq",
						"Timerliit",
						"Uummannartuuaraq",
					],
					stateName: "Narsaq",
				},
				{
					cities: [
						"Kangerluarsoruseq",
						"Kapisillit",
						"Neriunaq",
						"Nuuk",
						"Qeqertarsuatsiaat",
						"Qooqqut",
						"Qoornoq",
					],
					stateName: "Nuuk",
				},
				{ cities: ["Arsuk", "Paamiut"], stateName: "Paamiut" },
				{
					cities: [
						"Moriusaq",
						"Qaanaaq",
						"Qeqertarsuaq",
						"Qeqertat",
						"Savissivik",
						"Siorapaluk",
					],
					stateName: "Qaanaaq",
				},
				{
					cities: [
						"Eqalugaarsuit",
						"Illorsuit",
						"Kangerluarsorujuk",
						"Kangerluarsorujuup Qinngua",
						"Qanisartuut",
						"Qaqortokolook",
						"Qaqortoq",
						"Qassimiut",
						"Saarloq",
						"Saqqamiut",
						"Tasiluk",
						"Upernaviarsuk",
					],
					stateName: "Qaqortoq",
				},
				{ cities: ["Ikamiut", "Qasigiannguit"], stateName: "Qasigiannguit" },
				{ cities: ["Kangerluk", "Qeqertarsuaq"], stateName: "Qeqertarsuaq" },
				{
					cities: ["Itilleq", "Kangerlussuaq", "Sarfannguit", "Sisimiut"],
					stateName: "Sisimiut",
				},
				{ cities: [], stateName: "Udenfor kommunal inddeling" },
				{
					cities: [
						"Aappilattoq",
						"Ikerakuuk",
						"Innarsuit",
						"Kangersuatsiaq",
						"Kullorsuaq",
						"Naajaat",
						"Nutaarmiut",
						"Nuusuaq",
						"Tasiusaq",
						"Upernavik",
						"Upernavik Kujalleq",
					],
					stateName: "Upernavik",
				},
				{
					cities: [
						"Ikerasak",
						"Illorsuit",
						"Niaqornat",
						"Nuugaatsiaq",
						"Qaarsut",
						"Saattut",
						"Ukkusissat",
						"Uummannaq",
					],
					stateName: "Uummannaq",
				},
			],
			countryName: "Greenland",
		},
		{
			states: [
				{ cities: [], stateName: "Carriacou-Petite Martinique" },
				{ cities: [], stateName: "Saint Andrew" },
				{ cities: [], stateName: "Saint Davids" },
				{ cities: [], stateName: "Saint George's" },
				{ cities: [], stateName: "Saint John" },
				{ cities: [], stateName: "Saint Mark" },
				{ cities: [], stateName: "Saint Patrick" },
			],
			countryName: "Grenada",
		},
		{
			states: [
				{
					cities: [
						"Baie-Mahault",
						"Baillif",
						"Basse-Terre",
						"Bouillante",
						"Capesterre-Belle-Eau",
						"Gourbeyre",
						"Lamentin",
						"Petit-Bourg",
						"Pointe-Noire",
						"Saint-Claude",
						"Sainte-Rose",
						"Trois-Rivieres",
						"Vieux-Habitants",
					],
					stateName: "Basse-Terre",
				},
				{
					cities: [
						"Anse-Bertrand",
						"Le Gosier",
						"Le Moule",
						"Les Abymes",
						"Morne-a-l'Eau",
						"Petit-Canal",
						"Point-a-Pitre",
						"Port-Louis",
						"Saint-Francois",
						"Sainte-Anne",
					],
					stateName: "Grande-Terre",
				},
				{ cities: [], stateName: "Iles des Saintes" },
				{ cities: [], stateName: "La Desirade" },
				{ cities: ["Grand-Bourg"], stateName: "Marie-Galante" },
				{ cities: [], stateName: "Saint Barthelemy" },
				{ cities: [], stateName: "Saint Martin" },
			],
			countryName: "Guadeloupe",
		},
		{
			states: [
				{ cities: [], stateName: "Agana Heights" },
				{ cities: ["Agat"], stateName: "Agat" },
				{ cities: ["Barrigada", "Barrigada Heights"], stateName: "Barrigada" },
				{ cities: ["Chalan Pago", "Ordot"], stateName: "Chalan-Pago-Ordot" },
				{
					cities: ["Astumbo", "Dededo", "Finegayan Station"],
					stateName: "Dededo",
				},
				{ cities: ["Agana", "Agana Station"], stateName: "Hagatna" },
				{ cities: ["Inarajan"], stateName: "Inarajan" },
				{ cities: ["Mangilao"], stateName: "Mangilao" },
				{ cities: ["Merizo"], stateName: "Merizo" },
				{ cities: ["Mongmong", "Toto"], stateName: "Mongmong-Toto-Maite" },
				{ cities: [], stateName: "Santa Rita" },
				{ cities: ["Sinajana"], stateName: "Sinajana" },
				{ cities: ["Talofofo"], stateName: "Talofofo" },
				{ cities: ["Tamuning"], stateName: "Tamuning" },
				{ cities: ["Anderson Air Force Base", "Yigo"], stateName: "Yigo" },
				{ cities: ["Yona"], stateName: "Yona" },
			],
			countryName: "Guam",
		},
		{
			states: [
				{ cities: [], stateName: "Alta Verapaz" },
				{ cities: [], stateName: "Baja Verapaz" },
				{
					cities: ["Chimaltenango", "Comalapa", "Itzapa", "Patzun"],
					stateName: "Chimaltenango",
				},
				{ cities: ["Chiquimula", "Esquipulas"], stateName: "Chiquimula" },
				{ cities: [], stateName: "El Progreso" },
				{
					cities: [
						"Cotzumalguapa",
						"Escuintla",
						"Palin",
						"San Jose",
						"Tiquisate",
					],
					stateName: "Escuintla",
				},
				{
					cities: [
						"Amatitlan",
						"Chinautla",
						"Guatemala",
						"Mixco",
						"Petapa",
						"Villa Nueva",
					],
					stateName: "Guatemala",
				},
				{ cities: ["Huehuetenango"], stateName: "Huehuetenango" },
				{ cities: ["Puerto Barrios"], stateName: "Izabal" },
				{ cities: ["Jalapa"], stateName: "Jalapa" },
				{ cities: ["Asuncion Mita", "Jutiapa"], stateName: "Jutiapa" },
				{ cities: ["Flores", "San Benito"], stateName: "Peten" },
				{ cities: ["Quezaltenango"], stateName: "Quezaltenango" },
				{ cities: ["Quiche"], stateName: "Quiche" },
				{ cities: ["Retalhuleu"], stateName: "Retalhuleu" },
				{
					cities: [
						"Antigua",
						"Ciudad Vieja",
						"Jocotenango",
						"Santa Maria de Jesus",
						"Sumpango",
					],
					stateName: "Sacatepequez",
				},
				{ cities: [], stateName: "San Marcos" },
				{ cities: [], stateName: "Santa Rosa" },
				{ cities: ["Atitlan", "Solola"], stateName: "Solola" },
				{ cities: ["Mazatenango"], stateName: "Suchitepequez" },
				{ cities: ["Totonicapan"], stateName: "Totonicapan" },
				{ cities: ["Zacapa"], stateName: "Zacapa" },
			],
			countryName: "Guatemala",
		},
		{
			states: [
				{ cities: ["Saint Anne's"], stateName: "Alderney" },
				{ cities: ["Castle"], stateName: "Castel" },
				{ cities: ["Forest"], stateName: "Forest" },
				{ cities: [], stateName: "Saint Andrew" },
				{ cities: [], stateName: "Saint Martin" },
				{ cities: [], stateName: "Saint Peter Port" },
				{ cities: [], stateName: "Saint Pierre du Bois" },
				{ cities: [], stateName: "Saint Sampson" },
				{ cities: [], stateName: "Saint Saviour" },
				{ cities: ["Sark"], stateName: "Sark" },
				{ cities: ["Torteval"], stateName: "Torteval" },
				{ cities: ["Vale"], stateName: "Vale" },
			],
			countryName: "Guernsey and Alderney",
		},
		{
			states: [
				{ cities: ["Beyla"], stateName: "Beyla" },
				{ cities: ["Boffa"], stateName: "Boffa" },
				{ cities: ["Boke"], stateName: "Boke" },
				{ cities: ["Conakry"], stateName: "Conakry" },
				{ cities: ["Coyah"], stateName: "Coyah" },
				{ cities: ["Dabola"], stateName: "Dabola" },
				{ cities: ["Dalaba"], stateName: "Dalaba" },
				{ cities: ["Dinguiraye"], stateName: "Dinguiraye" },
				{ cities: ["Faranah"], stateName: "Faranah" },
				{ cities: ["Forecariah"], stateName: "Forecariah" },
				{ cities: ["Fria"], stateName: "Fria" },
				{ cities: ["Gaoual"], stateName: "Gaoual" },
				{ cities: ["Guekedou"], stateName: "Gueckedou" },
				{ cities: ["Kankan"], stateName: "Kankan" },
				{ cities: ["Kerouane"], stateName: "Kerouane" },
				{ cities: ["Kindia"], stateName: "Kindia" },
				{ cities: ["Kissidougou"], stateName: "Kissidougou" },
				{ cities: ["Koubia"], stateName: "Koubia" },
				{ cities: ["Koundara"], stateName: "Koundara" },
				{ cities: ["Kouroussa"], stateName: "Kouroussa" },
				{ cities: ["Labe"], stateName: "Labe" },
				{ cities: ["Lola"], stateName: "Lola" },
				{ cities: ["Macenta"], stateName: "Macenta" },
				{ cities: ["Mali"], stateName: "Mali" },
				{ cities: ["Mamou"], stateName: "Mamou" },
				{ cities: ["Mandiana"], stateName: "Mandiana" },
				{ cities: ["Nzerekore"], stateName: "Nzerekore" },
				{ cities: ["Pita"], stateName: "Pita" },
				{ cities: ["Siguiri"], stateName: "Siguiri" },
				{ cities: ["Telimele"], stateName: "Telimele" },
				{ cities: ["Tougue"], stateName: "Tougue" },
				{ cities: ["Yomou"], stateName: "Yomou" },
			],
			countryName: "Guinea",
		},
		{
			states: [
				{ cities: ["Bafata"], stateName: "Bafata" },
				{ cities: ["Bissau"], stateName: "Bissau" },
				{ cities: ["Bolama", "Bubaque"], stateName: "Bolama" },
				{ cities: ["Cacheu", "Canchungo"], stateName: "Cacheu" },
				{ cities: ["Gabu"], stateName: "Gabu" },
				{ cities: ["Bissora", "Farim", "Mansoa"], stateName: "Oio" },
				{ cities: ["Buba", "Fulacunda", "Quebo"], stateName: "Quinara" },
				{ cities: ["Catio"], stateName: "Tombali" },
			],
			countryName: "Guinea-Bissau",
		},
		{
			states: [
				{ cities: ["Mabaruma", "Morawhanna"], stateName: "Barima-Waini" },
				{
					cities: ["Bartica", "Issano", "Kamarang"],
					stateName: "Cuyuni-Mazaruni",
				},
				{
					cities: ["Georgetown", "Mahaica", "Paradise", "Queenstown"],
					stateName: "Demerara-Mahaica",
				},
				{ cities: [], stateName: "East Berbice-Corentyne" },
				{ cities: [], stateName: "Essequibo Islands-West Demerar" },
				{
					cities: ["Fort Wellington", "Mahaicony", "Rosignol"],
					stateName: "Mahaica-Berbice",
				},
				{
					cities: ["Anna Regina", "Charity", "Suddie"],
					stateName: "Pomeroon-Supenaam",
				},
				{ cities: ["Mahdia", "Tumatumari"], stateName: "Potaro-Siparuni" },
				{ cities: [], stateName: "Upper Demerara-Berbice" },
				{ cities: [], stateName: "Upper Takutu-Upper Essequibo" },
			],
			countryName: "Guyana",
		},
		{
			states: [
				{
					cities: [
						"Desdunes",
						"Dessalines",
						"Gonaives",
						"Gros-Morne",
						"L'Artibonite",
						"Saint-Marc",
						"Saint-Michel-de-l'Atalaye",
						"Saint-Raphael",
						"Verrettes",
					],
					stateName: "Artibonite",
				},
				{ cities: ["Hinche", "Mirebalais"], stateName: "Centre" },
				{
					cities: ["Anse-d'Hainault", "Dame Marie", "Jeremie", "Miragoane"],
					stateName: "Grand'Anse",
				},
				{
					cities: [
						"Cap-Haitien",
						"Croix-des-Bouquets",
						"Grande Riviere du Nord",
						"Limbe",
						"Pignon",
					],
					stateName: "Nord",
				},
				{
					cities: ["Derac", "Fort-Liberte", "Ouanaminthe", "Trou-du-Nord"],
					stateName: "Nord-Est",
				},
				{
					cities: ["Port-de-Paix", "Saint-Louis-du-Nord"],
					stateName: "Nord-Ouest",
				},
				{
					cities: [
						"Anse-a-Galets",
						"Carrefour",
						"Delmas",
						"Kenscoff",
						"Lascahobas",
						"Leogane",
						"Petionville",
						"Petit Goave",
						"Port-au-Prince",
					],
					stateName: "Ouest",
				},
				{ cities: ["Aquin", "Les Cayes"], stateName: "Sud" },
				{ cities: ["Jacmel"], stateName: "Sud-Est" },
			],
			countryName: "Haiti",
		},
		{
			states: [{ cities: [], stateName: "Heard and McDonald Islands" }],
			countryName: "Heard and McDonald Islands",
		},
		{
			states: [
				{ cities: ["La Ceiba", "Olanchito", "Tela"], stateName: "Atlantida" },
				{
					cities: ["Choluteca", "El Triunfo", "Pespire"],
					stateName: "Choluteca",
				},
				{ cities: ["Sonaguera", "Tocoa", "Trujillo"], stateName: "Colon" },
				{ cities: ["Comayagua", "Siguatepeque"], stateName: "Comayagua" },
				{
					cities: [
						"Copan",
						"Corquin",
						"Dulce Nombre",
						"El Paraiso",
						"San Antonio",
						"San Nicolas",
						"Santa Rosa de Copan",
					],
					stateName: "Copan",
				},
				{
					cities: [
						"Choloma",
						"La Lima",
						"Omoa",
						"Puerto Cortes",
						"San Pedro Sula",
					],
					stateName: "Cortes",
				},
				{ cities: [], stateName: "Distrito Central" },
				{ cities: [], stateName: "El Paraiso" },
				{ cities: [], stateName: "Francisco Morazan" },
				{ cities: [], stateName: "Gracias a Dios" },
				{
					cities: ["Intibuca", "La Esperanza", "Utila"],
					stateName: "Intibuca",
				},
				{ cities: [], stateName: "Islas de la Bahia" },
				{ cities: [], stateName: "La Paz" },
				{ cities: ["Gracias"], stateName: "Lempira" },
				{
					cities: ["Ocotepeque", "San Marcos", "Sinuapa"],
					stateName: "Ocotepeque",
				},
				{ cities: ["Catacamas", "Juticalpa"], stateName: "Olancho" },
				{ cities: [], stateName: "Santa Barbara" },
				{
					cities: ["Amapala", "Langue", "Nacaome", "San Lorenzo"],
					stateName: "Valle",
				},
				{
					cities: ["El Progreso", "Morazan", "Santa Rita", "Yoro"],
					stateName: "Yoro",
				},
			],
			countryName: "Honduras",
		},
		{
			states: [{ cities: [], stateName: "Hong Kong" }],
			countryName: "Hong Kong S.A.R.",
		},
		{
			states: [
				{
					cities: [
						"Akaszto",
						"Bacsalmas",
						"Bacsbokod",
						"Baja",
						"Bugac",
						"Davod",
						"Dunapataj",
						"Dunavecse",
						"Fulopszallas",
						"Hajos",
						"Harta",
						"Izsak",
						"Jakabszallas",
						"Janoshalma",
						"Kalocsa",
						"Kecel",
						"Kecskemet",
						"Kiskoros",
						"Kiskunfelegyhaza",
						"Kiskunhalas",
						"Kiskunmajsa",
						"Kunfeherto",
						"Kunszentmiklos",
						"Lajosmizse",
						"Lakitelek",
						"Madaras",
						"Melykut",
						"Nagybaracska",
						"Orgovany",
						"Palmonostora",
						"Solt",
						"Soltvadkert",
						"Sukosd",
						"Szabadszallas",
						"Szalkszentmarton",
						"Tass",
						"Tiszakecske",
						"Tompa",
					],
					stateName: "Bacs-Kiskun",
				},
				{
					cities: [
						"Beremend",
						"Boly",
						"Dunaszekcso",
						"Harkany",
						"Hosszuheteny",
						"Komlo",
						"Magocs",
						"Mohacs",
						"Pecs",
						"Pecsvarad",
						"Sasd",
						"Sellye",
						"Siklos",
						"Szentlorinc",
						"Szigetvar",
						"Vajszlo",
						"Villany",
					],
					stateName: "Baranya",
				},
				{
					cities: [
						"Battonya",
						"Bekes",
						"Bekescsaba",
						"Bekessamson",
						"Bekesszentandras",
						"Csorvas",
						"Devavanya",
						"Doboz",
						"Elek",
						"Endrod",
						"Fuzesgyarmat",
						"Gyula",
						"Ketegyhaza",
						"Kondoros",
						"Korosladany",
						"Kunagota",
						"Lokoshaza",
						"Mezobereny",
						"Mezohegyes",
						"Mezokovacshaza",
						"Nagyszenas",
						"Oroshaza",
						"Sarkad",
						"Szabadkigyos",
						"Szarvas",
						"Szeghalom",
						"Totkomlos",
						"Veszto",
					],
					stateName: "Bekes",
				},
				{
					cities: [
						"Abaujszanto",
						"Arlo",
						"Bogacs",
						"Cigand",
						"Edeleny",
						"Emod",
						"Encs",
						"Gonc",
						"Karcsa",
						"Kazincbarcika",
						"Mad",
						"Megyaszo",
						"Mezokeresztes",
						"Mezokovesd",
						"Miskolc",
						"Monok",
						"Nyekladhaza",
						"Olaszliszka",
						"Onod",
						"Ozd",
						"Putnok",
						"Rudabanya",
						"Sajokaza",
						"Sajolad",
						"Sajoszentpeter",
						"Saly",
						"Sarospatak",
						"Satoraljaujhely",
						"Szendro",
						"Szentistvan",
						"Szerencs",
						"Szihalom",
						"Szikszo",
						"Taktaharkany",
						"Taktaszada",
						"Tallya",
						"Tarcal",
						"Tiszaluc",
						"Tiszaujvaros",
						"Tokaj",
						"Tolcsva",
					],
					stateName: "Borsod-Abauj-Zemplen",
				},
				{ cities: ["Budapest"], stateName: "Budapest" },
				{
					cities: [
						"Csongrad",
						"Fabiansebestyen",
						"Foldeak",
						"Hodmezovasarhely",
						"Kiskundorozsma",
						"Kistelek",
						"Kiszombor",
						"Mako",
						"Mindszent",
						"Morahalom",
						"Pusztaszer",
						"Roszke",
						"Sandorfalva",
						"Szatymaz",
						"Szeged",
						"Szegvar",
						"Szekkutas",
						"Szentes",
					],
					stateName: "Csongrad",
				},
				{
					cities: [
						"Aba",
						"Adony",
						"Alap",
						"Apostag",
						"Bakonycsernye",
						"Bicske",
						"Bodajk",
						"Cece",
						"Csakvar",
						"Deg",
						"Dios",
						"Dunaujvaros",
						"Enying",
						"Ercsi",
						"Etyek",
						"Fehervarcsurgo",
						"Lovasbereny",
						"Martonvasar",
						"Mezofalva",
						"Mezoszilas",
						"Mor",
						"Pazmand",
						"Polgardi",
						"Pusztavam",
						"Rackeresztur",
						"Sarbogard",
						"Seregelyes",
						"Soponya",
						"Szabadbattyan",
						"Szekesfehervar",
						"Val",
					],
					stateName: "Fejer",
				},
				{
					cities: [
						"Asvanyraro",
						"Beled",
						"Bosarkany",
						"Csorna",
						"Fertod",
						"Fertorakos",
						"Fertoszentmiklos",
						"Gyor",
						"Gyorujbarat",
						"Hunyadi u.",
						"Kapuvar",
						"Lebeny",
						"Mihalyi",
						"Mosonmagyarovar",
						"Nyul",
						"Pannonhalma",
						"Rajka",
						"Sopron",
						"Szany",
						"Tet",
					],
					stateName: "Gyor-Moson-Sopron",
				},
				{
					cities: [
						"Balmazujvaros",
						"Barand",
						"Berettyoujfalu",
						"Biharkeresztes",
						"Biharnagybajom",
						"Debrecen",
						"Derecske",
						"Egyek",
						"Foldes",
						"Hajduboszormeny",
						"Hajdudorog",
						"Hajduhadhaz",
						"Hajdusamson",
						"Hajduszoboszlo",
						"Hajduszovat",
						"Hortobagy",
						"Hosszupalyi",
						"Kaba",
						"Komadi",
						"Mikepercs",
						"Monostorpalyi",
						"Nadudvar",
						"Nagyleta",
						"Nyirabrany",
						"Nyiracsad",
						"Nyiradony",
						"Polgar",
						"Puspokladany",
						"Sarretudvari",
						"Tiszacsege",
						"Ujfeherto",
						"Vamospercs",
					],
					stateName: "Hajdu-Bihar",
				},
				{
					cities: [
						"Abasar",
						"Andornaktalya",
						"Belapatfalva",
						"Domoszlo",
						"Eger",
						"Erdotelek",
						"Felsotarkany",
						"Fuzesabony",
						"Gyongyos",
						"Gyongyospata",
						"Gyongyossolymos",
						"Gyongyostarjan",
						"Hatvan",
						"Heves",
						"Kal",
						"Lorinci",
						"Matraderecske",
						"Parad",
						"Petervasara",
						"Recsk",
						"Sirok",
						"Tarnalelesz",
						"Verpelet",
					],
					stateName: "Heves",
				},
				{
					cities: [
						"Abadszalok",
						"Besenyszog",
						"Cserkeszolo",
						"Fegyvernek",
						"Hegyeshalom",
						"Jaszalsoszentgyorgy",
						"Jaszapati",
						"Jaszarokszallas",
						"Jaszbereny",
						"Jaszfenyzaru",
						"Jaszjakohalma",
						"Jaszkiser",
						"Jaszladany",
						"Jaszszentandras",
						"Karcag",
						"Kenderes",
						"Kisujszallas",
						"Kunhegyes",
						"Kunmadaras",
						"Kunszentmarton",
						"Martfu",
						"Mezotur",
						"Ocsod",
						"Szolnok",
						"Tiszabura",
						"Tiszafoldvar",
						"Tiszafured",
						"Tiszapuspoki",
						"Tiszaroff",
						"Tiszasuly",
						"Torokszentmiklos",
						"Toszeg",
						"Turkeve",
						"Ujszasz",
					],
					stateName: "Jasz-Nagykun-Szolnok",
				},
				{
					cities: [
						"Acs",
						"Almasfuzito",
						"Babolna",
						"Bajna",
						"Dorog",
						"Esztergom",
						"Kesztolc",
						"Kisber",
						"Komarom",
						"Kornye",
						"Labatlan",
						"Mocsa",
						"Nagyigmand",
						"Nyergesujfalu",
						"Oroszlany",
						"Sarisap",
						"Tardos",
						"Tarjan",
						"Tata",
						"Tatabanya",
						"Tokod",
						"Vertesszolos",
					],
					stateName: "Komarom-Esztergom",
				},
				{
					cities: [
						"Balassagyarmat",
						"Batonyterenye",
						"Bercel",
						"Bujak",
						"Diosjeno",
						"Karancskeszi",
						"Matraverebely",
						"Nagyoroszi",
						"Paszto",
						"Retsag",
						"Romhany",
						"Salgotarjan",
						"Szecseny",
						"Tar",
					],
					stateName: "Nograd",
				},
				{
					cities: [
						"Abony",
						"Albertirsa",
						"Aszod",
						"Biatorbagy",
						"Budakalasz",
						"Budakeszi",
						"Budaors",
						"Bugyi",
						"Cegled",
						"Csobanka",
						"Dabas",
						"Domsod",
						"Dunabogdany",
						"Dunaharaszti",
						"Dunakeszi",
						"Erd",
						"Forro",
						"Fot",
						"Galgaheviz",
						"God",
						"Godollo",
						"Gyomro",
						"Hevizgyork",
						"Isaszeg",
						"Jaszkarajeno",
						"Kiskunlachaza",
						"Kocser",
						"Koka",
						"Kosd",
						"Maglod",
						"Monor",
						"Nagykata",
						"Nagykoros",
						"Nagykovacsi",
						"Nagymaros",
						"Nagytarcsa",
						"Nyaregyhaza",
						"Ocsa",
						"Orbottyan",
						"Orkeny",
						"Paty",
						"Pecel",
						"Perbal",
						"Pilis",
						"Pilisborosjeno",
						"Piliscsaba",
						"Pilisszanto",
						"Pilisszentivan",
						"Pilisszentkereszt",
						"Pilisvorosvar",
						"Pomaz",
						"Racalmas",
						"Rackeve",
						"Solymar",
						"Soskut",
						"Szada",
						"Szazhalombatta",
						"Szentendre",
						"Szentmartonkata",
						"Szigetcsep",
						"Szigetszentmiklos",
						"Szigetujfalu",
						"Szob",
						"Tahitofalu",
						"Tapiobicske",
						"Tapioszecso",
						"Tapioszele",
						"Toalmas",
						"Torokbalint",
						"Tortel",
						"Tura",
						"Ullo",
						"Uri",
						"Urom",
						"Vac",
						"Vecses",
						"Veresegyhaz",
						"Verocemaros",
						"Visegrad",
						"Zsambek",
						"Zsambok",
					],
					stateName: "Pest",
				},
				{
					cities: [
						"Adand",
						"Balatonfoldvar",
						"Balatonoszod",
						"Balatonszabadi",
						"Balatonszarszo",
						"Barcs",
						"Berzence",
						"Boglarlelle",
						"Bohonye",
						"Csurgo",
						"Fonyod",
						"Kaposvar",
						"Karad",
						"Kethely",
						"Lengyeltoti",
						"Marcali",
						"Nagyatad",
						"Nagybajom",
						"Siofok",
						"Somogyvar",
						"Tab",
						"Zamardi",
					],
					stateName: "Somogy",
				},
				{
					cities: [
						"Ajak",
						"Baktaloranthaza",
						"Balkany",
						"Buj",
						"Demecser",
						"Dombrad",
						"Fehergyarmat",
						"Ibrany",
						"Kemecse",
						"Kisvarda",
						"Kotaj",
						"Mandok",
						"Mariapocs",
						"Mateszalka",
						"Nagyecsed",
						"Nagyhalasz",
						"Nagykallo",
						"Nyirbator",
						"Nyirbeltek",
						"Nyiregyhaza",
						"Nyirmada",
						"Nyirpazony",
						"Nyirtelek",
						"Ofeherto",
						"Rakamaz",
						"Tarpa",
						"Tiszabercel",
						"Tiszalok",
						"Tiszavasvari",
						"Tuzser",
						"Vaja",
						"Vasarosnameny",
						"Zahony",
					],
					stateName: "Szabolcs-Szatmar-Bereg",
				},
				{
					cities: [
						"Bataszek",
						"Bonyhad",
						"Decs",
						"Dombovar",
						"Dunafoldvar",
						"Fadd",
						"Gyonk",
						"Hogyesz",
						"Iregszemcse",
						"Madocsa",
						"Nagymagocs",
						"Nagymanyok",
						"Ozora",
						"Paks",
						"Pincehely",
						"Simontornya",
						"Szekszard",
						"Szentgal",
						"Tamasi",
						"Tengelic",
						"Tolna",
						"Zomba",
					],
					stateName: "Tolna",
				},
				{
					cities: [
						"Buk",
						"Celldomolk",
						"Csepreg",
						"Gencsapati",
						"Jak",
						"Janoshaza",
						"Kormend",
						"Koszeg",
						"Sarvar",
						"Szentgotthard",
						"Szombathely",
						"Vasvar",
						"Vep",
					],
					stateName: "Vas",
				},
				{
					cities: [
						"Ajka",
						"Badacsonytomaj",
						"Balatonalmadi",
						"Balatonfured",
						"Balatonfuzfo",
						"Balatonkenese",
						"Band",
						"Berhida",
						"Csabrendek",
						"Devecser",
						"Herend",
						"Papa",
						"Sumeg",
						"Tapolca",
						"Urkut",
						"Varpalota",
						"Veszprem",
						"Zirc",
					],
					stateName: "Veszprem",
				},
				{
					cities: [
						"Becsehely",
						"Heviz",
						"Keszthely",
						"Lenti",
						"Letenye",
						"Nagykanizsa",
						"Sarmellek",
						"Turje",
						"Zalaegerszeg",
						"Zalakomar",
						"Zalalovo",
						"Zalaszentgrot",
					],
					stateName: "Zala",
				},
			],
			countryName: "Hungary",
		},
		{
			states: [
				{
					cities: [
						"Bakkafjor ur",
						"Borgarfjor ur",
						"Brei dalsvik",
						"Djupivogur",
						"Egilssta ir",
						"Eskifjor ur",
						"Faskru sfjor ur",
						"Fellabar",
						"Hallormssta ur",
						"Hofn",
						"Nesjakauptun",
						"Neskaupsta ur",
						"Rey arfjor ur",
						"Sey isfjor ur",
						"Sto varfjor ur",
						"Vopnafjor ur",
					],
					stateName: "Austurland",
				},
				{ cities: [], stateName: "Gullbringusysla" },
				{ cities: [], stateName: "Hofu borgarsva i" },
				{ cities: [], stateName: "Nor urland eystra" },
				{ cities: [], stateName: "Nor urland vestra" },
				{ cities: [], stateName: "Su urland" },
				{ cities: [], stateName: "Su urnes" },
				{ cities: [], stateName: "Vestfir ir" },
				{
					cities: [
						"Akranes",
						"Borgarnes",
						"Bu ardalur",
						"Grundarfjor ur",
						"Hellissandur",
						"Hvanneyri",
						"Olafsvik",
						"Rif",
						"Stykkisholmur",
					],
					stateName: "Vesturland",
				},
			],
			countryName: "Iceland",
		},
		{
			states: [
				{
					cities: ["Bombuflat", "Garacharma", "Port Blair", "Rangat"],
					stateName: "Andaman and Nicobar Islands",
				},
				{
					cities: [
						"Addanki",
						"Adivivaram",
						"Adoni",
						"Aganampudi",
						"Ajjaram",
						"Akividu",
						"Akkarampalle",
						"Akkayapalle",
						"Akkireddipalem",
						"Alampur",
						"Amalapuram",
						"Amudalavalasa",
						"Amur",
						"Anakapalle",
						"Anantapur",
						"Andole",
						"Atmakur",
						"Attili",
						"Avanigadda",
						"Badepalli",
						"Badvel",
						"Balapur",
						"Bandarulanka",
						"Banganapalle",
						"Bapatla",
						"Bapulapadu",
						"Belampalli",
						"Bestavaripeta",
						"Betamcherla",
						"Bhattiprolu",
						"Bhimavaram",
						"Bhimunipatnam",
						"Bobbili",
						"Bombuflat",
						"Bommuru",
						"Bugganipalle",
						"Challapalle",
						"Chandur",
						"Chatakonda",
						"Chemmumiahpet",
						"Chidiga",
						"Chilakaluripet",
						"Chimakurthy",
						"Chinagadila",
						"Chinagantyada",
						"Chinnachawk",
						"Chintalavalasa",
						"Chipurupalle",
						"Chirala",
						"Chittoor",
						"Chodavaram",
						"Choutuppal",
						"Chunchupalle",
						"Cuddapah",
						"Cumbum",
						"Darnakal",
						"Dasnapur",
						"Dauleshwaram",
						"Dharmavaram",
						"Dhone",
						"Dommara Nandyal",
						"Dowlaiswaram",
						"East Godavari Dist.",
						"Eddumailaram",
						"Edulapuram",
						"Ekambara kuppam",
						"Eluru",
						"Enikapadu",
						"Fakirtakya",
						"Farrukhnagar",
						"Gaddiannaram",
						"Gajapathinagaram",
						"Gajularega",
						"Gajuvaka",
						"Gannavaram",
						"Garacharma",
						"Garimellapadu",
						"Giddalur",
						"Godavarikhani",
						"Gopalapatnam",
						"Gopalur",
						"Gorrekunta",
						"Gudivada",
						"Gudur",
						"Guntakal",
						"Guntur",
						"Guti",
						"Hindupur",
						"Hukumpeta",
						"Ichchapuram",
						"Isnapur",
						"Jaggayyapeta",
						"Jallaram Kamanpur",
						"Jammalamadugu",
						"Jangampalli",
						"Jarjapupeta",
						"Kadiri",
						"Kaikalur",
						"Kakinada",
						"Kallur",
						"Kalyandurg",
						"Kamalapuram",
						"Kamareddi",
						"Kanapaka",
						"Kanigiri",
						"Kanithi",
						"Kankipadu",
						"Kantabamsuguda",
						"Kanuru",
						"Karnul",
						"Katheru",
						"Kavali",
						"Kazipet",
						"Khanapuram Haveli",
						"Kodar",
						"Kollapur",
						"Kondapalem",
						"Kondapalle",
						"Kondukur",
						"Kosgi",
						"Kothavalasa",
						"Kottapalli",
						"Kovur",
						"Kovurpalle",
						"Kovvur",
						"Krishna",
						"Kuppam",
						"Kurmannapalem",
						"Kurnool",
						"Lakshettipet",
						"Lalbahadur Nagar",
						"Machavaram",
						"Macherla",
						"Machilipatnam",
						"Madanapalle",
						"Madaram",
						"Madhuravada",
						"Madikonda",
						"Madugule",
						"Mahabubnagar",
						"Mahbubabad",
						"Malkajgiri",
						"Mamilapalle",
						"Mancheral",
						"Mandapeta",
						"Mandasa",
						"Mangalagiri",
						"Manthani",
						"Markapur",
						"Marturu",
						"Metpalli",
						"Mindi",
						"Mirpet",
						"Moragudi",
						"Mothugudam",
						"Nagari",
						"Nagireddipalle",
						"Nandigama",
						"Nandikotkur",
						"Nandyal",
						"Narasannapeta",
						"Narasapur",
						"Narasaraopet",
						"Narayanavanam",
						"Narsapur",
						"Narsingi",
						"Narsipatnam",
						"Naspur",
						"Nathayyapalem",
						"Nayudupeta",
						"Nelimaria",
						"Nellore",
						"Nidadavole",
						"Nuzvid",
						"Omerkhan daira",
						"Ongole",
						"Osmania University",
						"Pakala",
						"Palakole",
						"Palakurthi",
						"Palasa",
						"Palempalle",
						"Palkonda",
						"Palmaner",
						"Pamur",
						"Panjim",
						"Papampeta",
						"Parasamba",
						"Parvatipuram",
						"Patancheru",
						"Payakaraopet",
						"Pedagantyada",
						"Pedana",
						"Peddapuram",
						"Pendurthi",
						"Penugonda",
						"Penukonda",
						"Phirangipuram",
						"Pithapuram",
						"Ponnur",
						"Port Blair",
						"Pothinamallayyapalem",
						"Prakasam",
						"Prasadampadu",
						"Prasantinilayam",
						"Proddatur",
						"Pulivendla",
						"Punganuru",
						"Puttur",
						"Qutubullapur",
						"Rajahmundry",
						"Rajamahendri",
						"Rajampet",
						"Rajendranagar",
						"Rajoli",
						"Ramachandrapuram",
						"Ramanayyapeta",
						"Ramapuram",
						"Ramarajupalli",
						"Ramavarappadu",
						"Rameswaram",
						"Rampachodavaram",
						"Ravulapalam",
						"Rayachoti",
						"Rayadrug",
						"Razam",
						"Razole",
						"Renigunta",
						"Repalle",
						"Rishikonda",
						"Salur",
						"Samalkot",
						"Sattenapalle",
						"Seetharampuram",
						"Serilungampalle",
						"Shankarampet",
						"Shar",
						"Singarayakonda",
						"Sirpur",
						"Sirsilla",
						"Sompeta",
						"Sriharikota",
						"Srikakulam",
						"Srikalahasti",
						"Sriramnagar",
						"Sriramsagar",
						"Srisailam",
						"Srisailamgudem Devasthanam",
						"Sulurpeta",
						"Suriapet",
						"Suryaraopet",
						"Tadepalle",
						"Tadepalligudem",
						"Tadpatri",
						"Tallapalle",
						"Tanuku",
						"Tekkali",
						"Tenali",
						"Tigalapahad",
						"Tiruchanur",
						"Tirumala",
						"Tirupati",
						"Tirvuru",
						"Trimulgherry",
						"Tuni",
						"Turangi",
						"Ukkayapalli",
						"Ukkunagaram",
						"Uppal Kalan",
						"Upper Sileru",
						"Uravakonda",
						"Vadlapudi",
						"Vaparala",
						"Vemalwada",
						"Venkatagiri",
						"Venkatapuram",
						"Vepagunta",
						"Vetapalem",
						"Vijayapuri",
						"Vijayapuri South",
						"Vijayawada",
						"Vinukonda",
						"Visakhapatnam",
						"Vizianagaram",
						"Vuyyuru",
						"Wanparti",
						"West Godavari Dist.",
						"Yadagirigutta",
						"Yarada",
						"Yellamanchili",
						"Yemmiganur",
						"Yenamalakudru",
						"Yendada",
						"Yerraguntla",
					],
					stateName: "Andhra Pradesh",
				},
				{
					cities: [
						"Along",
						"Basar",
						"Bondila",
						"Changlang",
						"Daporijo",
						"Deomali",
						"Itanagar",
						"Jairampur",
						"Khonsa",
						"Naharlagun",
						"Namsai",
						"Pasighat",
						"Roing",
						"Seppa",
						"Tawang",
						"Tezu",
						"Ziro",
					],
					stateName: "Arunachal Pradesh",
				},
				{
					cities: [
						"Abhayapuri",
						"Ambikapur",
						"Amguri",
						"Anand Nagar",
						"Badarpur",
						"Badarpur Railway Town",
						"Bahbari Gaon",
						"Bamun Sualkuchi",
						"Barbari",
						"Barpathar",
						"Barpeta",
						"Barpeta Road",
						"Basugaon",
						"Bihpuria",
						"Bijni",
						"Bilasipara",
						"Biswanath Chariali",
						"Bohori",
						"Bokajan",
						"Bokokhat",
						"Bongaigaon",
						"Bongaigaon Petro-chemical Town",
						"Borgolai",
						"Chabua",
						"Chandrapur Bagicha",
						"Chapar",
						"Chekonidhara",
						"Choto Haibor",
						"Dergaon",
						"Dharapur",
						"Dhekiajuli",
						"Dhemaji",
						"Dhing",
						"Dhubri",
						"Dhuburi",
						"Dibrugarh",
						"Digboi",
						"Digboi Oil Town",
						"Dimaruguri",
						"Diphu",
						"Dispur",
						"Doboka",
						"Dokmoka",
						"Donkamokan",
						"Duliagaon",
						"Duliajan",
						"Duliajan No.1",
						"Dum Duma",
						"Durga Nagar",
						"Gauripur",
						"Goalpara",
						"Gohpur",
						"Golaghat",
						"Golakganj",
						"Gossaigaon",
						"Guwahati",
						"Haflong",
						"Hailakandi",
						"Hamren",
						"Hauli",
						"Hauraghat",
						"Hojai",
						"Jagiroad",
						"Jagiroad Paper Mill",
						"Jogighopa",
						"Jonai Bazar",
						"Jorhat",
						"Kampur Town",
						"Kamrup",
						"Kanakpur",
						"Karimganj",
						"Kharijapikon",
						"Kharupetia",
						"Kochpara",
						"Kokrajhar",
						"Kumar Kaibarta Gaon",
						"Lakhimpur",
						"Lakhipur",
						"Lala",
						"Lanka",
						"Lido Tikok",
						"Lido Town",
						"Lumding",
						"Lumding Railway Colony",
						"Mahur",
						"Maibong",
						"Majgaon",
						"Makum",
						"Mangaldai",
						"Mankachar",
						"Margherita",
						"Mariani",
						"Marigaon",
						"Moran",
						"Moranhat",
						"Nagaon",
						"Naharkatia",
						"Nalbari",
						"Namrup",
						"Naubaisa Gaon",
						"Nazira",
						"New Bongaigaon Railway Colony",
						"Niz-Hajo",
						"North Guwahati",
						"Numaligarh",
						"Palasbari",
						"Panchgram",
						"Pathsala",
						"Raha",
						"Rangapara",
						"Rangia",
						"Salakati",
						"Sapatgram",
						"Sarthebari",
						"Sarupathar",
						"Sarupathar Bengali",
						"Senchoagaon",
						"Sibsagar",
						"Silapathar",
						"Silchar",
						"Silchar Part-X",
						"Sonari",
						"Sorbhog",
						"Sualkuchi",
						"Tangla",
						"Tezpur",
						"Tihu",
						"Tinsukia",
						"Titabor",
						"Udalguri",
						"Umrangso",
						"Uttar Krishnapur Part-I",
					],
					stateName: "Assam",
				},
				{
					cities: [
						"Amarpur",
						"Ara",
						"Araria",
						"Areraj",
						"Asarganj",
						"Aurangabad",
						"Bagaha",
						"Bahadurganj",
						"Bairgania",
						"Bakhtiyarpur",
						"Banka",
						"Banmankhi",
						"Bar Bigha",
						"Barauli",
						"Barauni Oil Township",
						"Barh",
						"Barhiya",
						"Bariapur",
						"Baruni",
						"Begusarai",
						"Behea",
						"Belsand",
						"Bettiah",
						"Bhabua",
						"Bhagalpur",
						"Bhimnagar",
						"Bhojpur",
						"Bihar",
						"Bihar Sharif",
						"Bihariganj",
						"Bikramganj",
						"Birpur",
						"Bodh Gaya",
						"Buxar",
						"Chakia",
						"Chanpatia",
						"Chhapra",
						"Chhatapur",
						"Colgong",
						"Dalsingh Sarai",
						"Darbhanga",
						"Daudnagar",
						"Dehri",
						"Dhaka",
						"Dighwara",
						"Dinapur",
						"Dinapur Cantonment",
						"Dumra",
						"Dumraon",
						"Fatwa",
						"Forbesganj",
						"Gaya",
						"Gazipur",
						"Ghoghardiha",
						"Gogri Jamalpur",
						"Gopalganj",
						"Habibpur",
						"Hajipur",
						"Hasanpur",
						"Hazaribagh",
						"Hilsa",
						"Hisua",
						"Islampur",
						"Jagdispur",
						"Jahanabad",
						"Jamalpur",
						"Jamhaur",
						"Jamui",
						"Janakpur Road",
						"Janpur",
						"Jaynagar",
						"Jha Jha",
						"Jhanjharpur",
						"Jogbani",
						"Kanti",
						"Kasba",
						"Kataiya",
						"Katihar",
						"Khagaria",
						"Khagaul",
						"Kharagpur",
						"Khusrupur",
						"Kishanganj",
						"Koath",
						"Koilwar",
						"Lakhisarai",
						"Lalganj",
						"Lauthaha",
						"Madhepura",
						"Madhubani",
						"Maharajganj",
						"Mahnar Bazar",
						"Mairwa",
						"Makhdumpur",
						"Maner",
						"Manihari",
						"Marhaura",
						"Masaurhi",
						"Mirganj",
						"Mohiuddinagar",
						"Mokama",
						"Motihari",
						"Motipur",
						"Munger",
						"Murliganj",
						"Muzaffarpur",
						"Nabinagar",
						"Narkatiaganj",
						"Nasriganj",
						"Natwar",
						"Naugachhia",
						"Nawada",
						"Nirmali",
						"Nokha",
						"Paharpur",
						"Patna",
						"Phulwari",
						"Piro",
						"Purnia",
						"Pusa",
						"Rafiganj",
						"Raghunathpur",
						"Rajgir",
						"Ramnagar",
						"Raxaul",
						"Revelganj",
						"Rusera",
						"Sagauli",
						"Saharsa",
						"Samastipur",
						"Sasaram",
						"Shahpur",
						"Shaikhpura",
						"Sherghati",
						"Shivhar",
						"Silao",
						"Sitamarhi",
						"Siwan",
						"Sonepur",
						"Sultanganj",
						"Supaul",
						"Teghra",
						"Tekari",
						"Thakurganj",
						"Vaishali",
						"Waris Aliganj",
					],
					stateName: "Bihar",
				},
				{ cities: ["Chandigarh"], stateName: "Chandigarh" },
				{
					cities: [
						"Ahiwara",
						"Akaltara",
						"Ambagarh Chauki",
						"Ambikapur",
						"Arang",
						"Bade Bacheli",
						"Bagbahara",
						"Baikunthpur",
						"Balod",
						"Baloda",
						"Baloda Bazar",
						"Banarsi",
						"Basna",
						"Bemetra",
						"Bhanpuri",
						"Bhatapara",
						"Bhatgaon",
						"Bhilai",
						"Bilaspur",
						"Bilha",
						"Birgaon",
						"Bodri",
						"Champa",
						"Charcha",
						"Charoda",
						"Chhuikhadan",
						"Chirmiri",
						"Dantewada",
						"Deori",
						"Dhamdha",
						"Dhamtari",
						"Dharamjaigarh",
						"Dipka",
						"Doman Hill Colliery",
						"Dongargaon",
						"Dongragarh",
						"Durg",
						"Frezarpur",
						"Gandai",
						"Gariaband",
						"Gaurela",
						"Gelhapani",
						"Gharghoda",
						"Gidam",
						"Gobra Nawapara",
						"Gogaon",
						"Hatkachora",
						"Jagdalpur",
						"Jamui",
						"Jashpurnagar",
						"Jhagrakhand",
						"Kanker",
						"Katghora",
						"Kawardha",
						"Khairagarh",
						"Khamhria",
						"Kharod",
						"Kharsia",
						"Khonga Pani",
						"Kirandu",
						"Kirandul",
						"Kohka",
						"Kondagaon",
						"Korba",
						"Korea",
						"Koria Block",
						"Kota",
						"Kumhari",
						"Kumud Katta",
						"Kurasia",
						"Kurud",
						"Lingiyadih",
						"Lormi",
						"Mahasamund",
						"Mahendragarh",
						"Mehmand",
						"Mongra",
						"Mowa",
						"Mungeli",
						"Nailajanjgir",
						"Namna Kalan",
						"Naya Baradwar",
						"Pandariya",
						"Patan",
						"Pathalgaon",
						"Pendra",
						"Phunderdihari",
						"Pithora",
						"Raigarh",
						"Raipur",
						"Rajgamar",
						"Rajhara",
						"Rajnandgaon",
						"Ramanuj Ganj",
						"Ratanpur",
						"Sakti",
						"Saraipali",
						"Sarajpur",
						"Sarangarh",
						"Shivrinarayan",
						"Simga",
						"Sirgiti",
						"Takhatpur",
						"Telgaon",
						"Tildanewra",
						"Urla",
						"Vishrampur",
					],
					stateName: "Chhattisgarh",
				},
				{ cities: ["Amli", "Silvassa"], stateName: "Dadra and Nagar Haveli" },
				{ cities: ["Daman", "Diu"], stateName: "Daman and Diu" },
				{ cities: ["Delhi", "New Delhi"], stateName: "Delhi" },
				{
					cities: [
						"Aldona",
						"Altinho",
						"Aquem",
						"Arpora",
						"Bambolim",
						"Bandora",
						"Bardez",
						"Benaulim",
						"Betora",
						"Bicholim",
						"Calapor",
						"Candolim",
						"Caranzalem",
						"Carapur",
						"Chicalim",
						"Chimbel",
						"Chinchinim",
						"Colvale",
						"Corlim",
						"Cortalim",
						"Cuncolim",
						"Curchorem",
						"Curti",
						"Davorlim",
						"Dona Paula",
						"Goa",
						"Guirim",
						"Jua",
						"Kalangat",
						"Kankon",
						"Kundaim",
						"Loutulim",
						"Madgaon",
						"Mapusa",
						"Margao",
						"Margaon",
						"Miramar",
						"Morjim",
						"Mormugao",
						"Navelim",
						"Pale",
						"Panaji",
						"Parcem",
						"Parra",
						"Penha de Franca",
						"Pernem",
						"Pilerne",
						"Pissurlem",
						"Ponda",
						"Porvorim",
						"Quepem",
						"Queula",
						"Raia",
						"Reis Magos",
						"Salcette",
						"Saligao",
						"Sancoale",
						"Sanguem",
						"Sanquelim",
						"Sanvordem",
						"Sao Jose-de-Areal",
						"Sattari",
						"Serula",
						"Sinquerim",
						"Siolim",
						"Taleigao",
						"Tivim",
						"Valpoi",
						"Varca",
						"Vasco",
						"Verna",
					],
					stateName: "Goa",
				},
				{
					cities: [
						"Abrama",
						"Adalaj",
						"Adityana",
						"Advana",
						"Ahmedabad",
						"Ahwa",
						"Alang",
						"Ambaji",
						"Ambaliyasan",
						"Amod",
						"Amreli",
						"Amroli",
						"Anand",
						"Andada",
						"Anjar",
						"Anklav",
						"Ankleshwar",
						"Anklesvar INA",
						"Antaliya",
						"Arambhada",
						"Asarma",
						"Atul",
						"Babra",
						"Bag-e-Firdosh",
						"Bagasara",
						"Bahadarpar",
						"Bajipura",
						"Bajva",
						"Balasinor",
						"Banaskantha",
						"Bansda",
						"Bantva",
						"Bardoli",
						"Barwala",
						"Bayad",
						"Bechar",
						"Bedi",
						"Beyt",
						"Bhachau",
						"Bhanvad",
						"Bharuch",
						"Bharuch INA",
						"Bhavnagar",
						"Bhayavadar",
						"Bhestan",
						"Bhuj",
						"Bilimora",
						"Bilkha",
						"Billimora",
						"Bodakdev",
						"Bodeli",
						"Bopal",
						"Boria",
						"Boriavi",
						"Borsad",
						"Botad",
						"Cambay",
						"Chaklasi",
						"Chala",
						"Chalala",
						"Chalthan",
						"Chanasma",
						"Chandisar",
						"Chandkheda",
						"Chanod",
						"Chaya",
						"Chenpur",
						"Chhapi",
						"Chhaprabhatha",
						"Chhatral",
						"Chhota Udepur",
						"Chikhli",
						"Chiloda",
						"Chorvad",
						"Chotila",
						"Dabhoi",
						"Dadara",
						"Dahod",
						"Dakor",
						"Damnagar",
						"Deesa",
						"Delvada",
						"Devgadh Baria",
						"Devsar",
						"Dhandhuka",
						"Dhanera",
						"Dhangdhra",
						"Dhansura",
						"Dharampur",
						"Dhari",
						"Dhola",
						"Dholka",
						"Dholka Rural",
						"Dhoraji",
						"Dhrangadhra",
						"Dhrol",
						"Dhuva",
						"Dhuwaran",
						"Digvijaygram",
						"Disa",
						"Dungar",
						"Dungarpur",
						"Dungra",
						"Dwarka",
						"Flelanganj",
						"GSFC Complex",
						"Gadhda",
						"Gandevi",
						"Gandhidham",
						"Gandhinagar",
						"Gariadhar",
						"Ghogha",
						"Godhra",
						"Gondal",
						"Hajira INA",
						"Halol",
						"Halvad",
						"Hansot",
						"Harij",
						"Himatnagar",
						"Ichchhapor",
						"Idar",
						"Jafrabad",
						"Jalalpore",
						"Jambusar",
						"Jamjodhpur",
						"Jamnagar",
						"Jasdan",
						"Jawaharnagar",
						"Jetalsar",
						"Jetpur",
						"Jodiya",
						"Joshipura",
						"Junagadh",
						"Kadi",
						"Kadodara",
						"Kalavad",
						"Kali",
						"Kaliawadi",
						"Kalol",
						"Kalol INA",
						"Kandla",
						"Kanjari",
						"Kanodar",
						"Kapadwanj",
						"Karachiya",
						"Karamsad",
						"Karjan",
						"Kathial",
						"Kathor",
						"Katpar",
						"Kavant",
						"Keshod",
						"Kevadiya",
						"Khambhaliya",
						"Khambhat",
						"Kharaghoda",
						"Khed Brahma",
						"Kheda",
						"Kheralu",
						"Kodinar",
						"Kosamba",
						"Kundla",
						"Kutch",
						"Kutiyana",
						"Lakhtar",
						"Lalpur",
						"Lambha",
						"Lathi",
						"Limbdi",
						"Limla",
						"Lunavada",
						"Madhapar",
						"Maflipur",
						"Mahemdavad",
						"Mahudha",
						"Mahuva",
						"Mahuvar",
						"Makarba",
						"Makarpura",
						"Makassar",
						"Maktampur",
						"Malia",
						"Malpur",
						"Manavadar",
						"Mandal",
						"Mandvi",
						"Mangrol",
						"Mansa",
						"Meghraj",
						"Mehsana",
						"Mendarla",
						"Mithapur",
						"Modasa",
						"Mogravadi",
						"Morbi",
						"Morvi",
						"Mundra",
						"Nadiad",
						"Naliya",
						"Nanakvada",
						"Nandej",
						"Nandesari",
						"Nandesari INA",
						"Naroda",
						"Navagadh",
						"Navagam Ghed",
						"Navsari",
						"Ode",
						"Okaf",
						"Okha",
						"Olpad",
						"Paddhari",
						"Padra",
						"Palanpur",
						"Palej",
						"Pali",
						"Palitana",
						"Paliyad",
						"Pandesara",
						"Panoli",
						"Pardi",
						"Parnera",
						"Parvat",
						"Patan",
						"Patdi",
						"Petlad",
						"Petrochemical Complex",
						"Porbandar",
						"Prantij",
						"Radhanpur",
						"Raiya",
						"Rajkot",
						"Rajpipla",
						"Rajula",
						"Ramod",
						"Ranavav",
						"Ranoli",
						"Rapar",
						"Sahij",
						"Salaya",
						"Sanand",
						"Sankheda",
						"Santrampur",
						"Saribujrang",
						"Sarigam INA",
						"Sayan",
						"Sayla",
						"Shahpur",
						"Shahwadi",
						"Shapar",
						"Shivrajpur",
						"Siddhapur",
						"Sidhpur",
						"Sihor",
						"Sika",
						"Singarva",
						"Sinor",
						"Sojitra",
						"Sola",
						"Songadh",
						"Suraj Karadi",
						"Surat",
						"Surendranagar",
						"Talaja",
						"Talala",
						"Talod",
						"Tankara",
						"Tarsali",
						"Thangadh",
						"Tharad",
						"Thasra",
						"Udyognagar",
						"Ukai",
						"Umbergaon",
						"Umbergaon INA",
						"Umrala",
						"Umreth",
						"Un",
						"Una",
						"Unjha",
						"Upleta",
						"Utran",
						"Uttarsanda",
						"V.U. Nagar",
						"V.V. Nagar",
						"Vadia",
						"Vadla",
						"Vadnagar",
						"Vadodara",
						"Vaghodia INA",
						"Valbhipur",
						"Vallabh Vidyanagar",
						"Valsad",
						"Valsad INA",
						"Vanthali",
						"Vapi",
						"Vapi INA",
						"Vartej",
						"Vasad",
						"Vasna Borsad INA",
						"Vaso",
						"Veraval",
						"Vidyanagar",
						"Vijalpor",
						"Vijapur",
						"Vinchhiya",
						"Vinzol",
						"Virpur",
						"Visavadar",
						"Visnagar",
						"Vyara",
						"Wadhwan",
						"Waghai",
						"Waghodia",
						"Wankaner",
						"Zalod",
					],
					stateName: "Gujarat",
				},
				{
					cities: [
						"Ambala",
						"Ambala Cantt",
						"Asan Khurd",
						"Asandh",
						"Ateli",
						"Babiyal",
						"Bahadurgarh",
						"Ballabgarh",
						"Barwala",
						"Bawal",
						"Bawani Khera",
						"Beri",
						"Bhiwani",
						"Bilaspur",
						"Buria",
						"Charkhi Dadri",
						"Chhachhrauli",
						"Chita",
						"Dabwali",
						"Dharuhera",
						"Dundahera",
						"Ellenabad",
						"Farakhpur",
						"Faridabad",
						"Farrukhnagar",
						"Fatehabad",
						"Firozpur Jhirka",
						"Gannaur",
						"Ghraunda",
						"Gohana",
						"Gurgaon",
						"Haileymandi",
						"Hansi",
						"Hasanpur",
						"Hathin",
						"Hisar",
						"Hissar",
						"Hodal",
						"Indri",
						"Jagadhri",
						"Jakhal Mandi",
						"Jhajjar",
						"Jind",
						"Julana",
						"Kaithal",
						"Kalanur",
						"Kalanwali",
						"Kalayat",
						"Kalka",
						"Kanina",
						"Kansepur",
						"Kardhan",
						"Karnal",
						"Kharkhoda",
						"Kheri Sampla",
						"Kundli",
						"Kurukshetra",
						"Ladrawan",
						"Ladwa",
						"Loharu",
						"Maham",
						"Mahendragarh",
						"Mustafabad",
						"Nagai Chaudhry",
						"Narayangarh",
						"Narnaul",
						"Narnaund",
						"Narwana",
						"Nilokheri",
						"Nuh",
						"Palwal",
						"Panchkula",
						"Panipat",
						"Panipat Taraf Ansar",
						"Panipat Taraf Makhdum Zadgan",
						"Panipat Taraf Rajputan",
						"Pehowa",
						"Pinjaur",
						"Punahana",
						"Pundri",
						"Radaur",
						"Raipur Rani",
						"Rania",
						"Ratiya",
						"Rewari",
						"Rohtak",
						"Ropar",
						"Sadauri",
						"Safidon",
						"Samalkha",
						"Sankhol",
						"Sasauli",
						"Shahabad",
						"Sirsa",
						"Siwani",
						"Sohna",
						"Sonipat",
						"Sukhrali",
						"Taoru",
						"Taraori",
						"Tauru",
						"Thanesar",
						"Tilpat",
						"Tohana",
						"Tosham",
						"Uchana",
						"Uklana Mandi",
						"Uncha Siwana",
						"Yamunanagar",
					],
					stateName: "Haryana",
				},
				{
					cities: [
						"Arki",
						"Baddi",
						"Bakloh",
						"Banjar",
						"Bhota",
						"Bhuntar",
						"Bilaspur",
						"Chamba",
						"Chaupal",
						"Chuari Khas",
						"Dagshai",
						"Dalhousie",
						"Dalhousie Cantonment",
						"Damtal",
						"Daulatpur",
						"Dera Gopipur",
						"Dhalli",
						"Dharamshala",
						"Gagret",
						"Ghamarwin",
						"Hamirpur",
						"Jawala Mukhi",
						"Jogindarnagar",
						"Jubbal",
						"Jutogh",
						"Kala Amb",
						"Kalpa",
						"Kangra",
						"Kasauli",
						"Kot Khai",
						"Kullu",
						"Kulu",
						"Manali",
						"Mandi",
						"Mant Khas",
						"Mehatpur Basdehra",
						"Nadaun",
						"Nagrota",
						"Nahan",
						"Naina Devi",
						"Nalagarh",
						"Narkanda",
						"Nurpur",
						"Palampur",
						"Pandoh",
						"Paonta Sahib",
						"Parwanoo",
						"Parwanu",
						"Rajgarh",
						"Rampur",
						"Rawalsar",
						"Rohru",
						"Sabathu",
						"Santokhgarh",
						"Sarahan",
						"Sarka Ghat",
						"Seoni",
						"Shimla",
						"Sirmaur",
						"Solan",
						"Solon",
						"Sundarnagar",
						"Sundernagar",
						"Talai",
						"Theog",
						"Tira Sujanpur",
						"Una",
						"Yol",
					],
					stateName: "Himachal Pradesh",
				},
				{
					cities: [
						"Achabal",
						"Akhnur",
						"Anantnag",
						"Arnia",
						"Awantipora",
						"Badami Bagh",
						"Bandipur",
						"Banihal",
						"Baramula",
						"Baramulla",
						"Bari Brahmana",
						"Bashohli",
						"Batote",
						"Bhaderwah",
						"Bijbiara",
						"Billawar",
						"Birwah",
						"Bishna",
						"Budgam",
						"Charari Sharief",
						"Chenani",
						"Doda",
						"Duru-Verinag",
						"Gandarbat",
						"Gho Manhasan",
						"Gorah Salathian",
						"Gulmarg",
						"Hajan",
						"Handwara",
						"Hiranagar",
						"Jammu",
						"Jammu Cantonment",
						"Jammu Tawi",
						"Jourian",
						"Kargil",
						"Kathua",
						"Katra",
						"Khan Sahib",
						"Khour",
						"Khrew",
						"Kishtwar",
						"Kud",
						"Kukernag",
						"Kulgam",
						"Kunzer",
						"Kupwara",
						"Lakhenpur",
						"Leh",
						"Magam",
						"Mattan",
						"Naushehra",
						"Pahalgam",
						"Pampore",
						"Parole",
						"Pattan",
						"Pulwama",
						"Punch",
						"Qazigund",
						"Rajauri",
						"Ramban",
						"Ramgarh",
						"Ramnagar",
						"Ranbirsingh Pora",
						"Reasi",
						"Rehambal",
						"Samba",
						"Shupiyan",
						"Sopur",
						"Srinagar",
						"Sumbal",
						"Sunderbani",
						"Talwara",
						"Thanamandi",
						"Tral",
						"Udhampur",
						"Uri",
						"Vijaypur",
					],
					stateName: "Jammu and Kashmir",
				},
				{
					cities: [
						"Adityapur",
						"Amlabad",
						"Angarpathar",
						"Ara",
						"Babua Kalan",
						"Bagbahra",
						"Baliapur",
						"Baliari",
						"Balkundra",
						"Bandhgora",
						"Barajamda",
						"Barhi",
						"Barka Kana",
						"Barki Saraiya",
						"Barughutu",
						"Barwadih",
						"Basaria",
						"Basukinath",
						"Bermo",
						"Bhagatdih",
						"Bhaurah",
						"Bhojudih",
						"Bhuli",
						"Bokaro",
						"Borio Bazar",
						"Bundu",
						"Chaibasa",
						"Chaitudih",
						"Chakradharpur",
						"Chakulia",
						"Chandaur",
						"Chandil",
						"Chandrapura",
						"Chas",
						"Chatra",
						"Chhatatanr",
						"Chhotaputki",
						"Chiria",
						"Chirkunda",
						"Churi",
						"Daltenganj",
						"Danguwapasi",
						"Dari",
						"Deoghar",
						"Deorikalan",
						"Devghar",
						"Dhanbad",
						"Dhanwar",
						"Dhaunsar",
						"Dugda",
						"Dumarkunda",
						"Dumka",
						"Egarkunr",
						"Gadhra",
						"Garwa",
						"Ghatsila",
						"Ghorabandha",
						"Gidi",
						"Giridih",
						"Gobindpur",
						"Godda",
						"Godhar",
						"Golphalbari",
						"Gomoh",
						"Gua",
						"Gumia",
						"Gumla",
						"Haludbani",
						"Hazaribag",
						"Hesla",
						"Husainabad",
						"Isri",
						"Jadugora",
						"Jagannathpur",
						"Jamadoba",
						"Jamshedpur",
						"Jamtara",
						"Jarangdih",
						"Jaridih",
						"Jasidih",
						"Jena",
						"Jharia",
						"Jharia Khas",
						"Jhinkpani",
						"Jhumri Tilaiya",
						"Jorapokhar",
						"Jugsalai",
						"Kailudih",
						"Kalikapur",
						"Kandra",
						"Kanke",
						"Katras",
						"Kedla",
						"Kenduadih",
						"Kharkhari",
						"Kharsawan",
						"Khelari",
						"Khunti",
						"Kiri Buru",
						"Kiriburu",
						"Kodarma",
						"Kuju",
						"Kurpania",
						"Kustai",
						"Lakarka",
						"Lapanga",
						"Latehar",
						"Lohardaga",
						"Loiya",
						"Loyabad",
						"Madhupur",
						"Mahesh Mundi",
						"Maithon",
						"Malkera",
						"Mango",
						"Manoharpur",
						"Marma",
						"Meghahatuburu Forest village",
						"Mera",
						"Meru",
						"Mihijam",
						"Mugma",
						"Muri",
						"Mushabani",
						"Nagri Kalan",
						"Netarhat",
						"Nirsa",
						"Noamundi",
						"Okni",
						"Orla",
						"Pakaur",
						"Palamau",
						"Palawa",
						"Panchet",
						"Panrra",
						"Paratdih",
						"Pathardih",
						"Patratu",
						"Phusro",
						"Pondar Kanali",
						"Rajmahal",
						"Ramgarh",
						"Ranchi",
						"Ray",
						"Rehla",
						"Religara",
						"Rohraband",
						"Sahibganj",
						"Sahnidih",
						"Saraidhela",
						"Saraikela",
						"Sarjamda",
						"Saunda",
						"Sewai",
						"Sijhua",
						"Sijua",
						"Simdega",
						"Sindari",
						"Sinduria",
						"Sini",
						"Sirka",
						"Siuliban",
						"Surubera",
						"Tati",
						"Tenudam",
						"Tisra",
						"Topa",
						"Topchanchi",
					],
					stateName: "Jharkhand",
				},
				{
					cities: [
						"Adityanagar",
						"Adityapatna",
						"Afzalpur",
						"Ajjampur",
						"Aland",
						"Almatti Sitimani",
						"Alnavar",
						"Alur",
						"Ambikanagara",
						"Anekal",
						"Ankola",
						"Annigeri",
						"Arkalgud",
						"Arsikere",
						"Athni",
						"Aurad",
						"Badagavettu",
						"Badami",
						"Bagalkot",
						"Bagepalli",
						"Bailhongal",
						"Baindur",
						"Bajala",
						"Bajpe",
						"Banavar",
						"Bangarapet",
						"Bankapura",
						"Bannur",
						"Bantwal",
						"Basavakalyan",
						"Basavana Bagevadi",
						"Belagula",
						"Belakavadiq",
						"Belgaum",
						"Belgaum Cantonment",
						"Bellary",
						"Belluru",
						"Beltangadi",
						"Belur",
						"Belvata",
						"Bengaluru",
						"Bhadravati",
						"Bhalki",
						"Bhatkal",
						"Bhimarayanagudi",
						"Bhogadi",
						"Bidar",
						"Bijapur",
						"Bilgi",
						"Birur",
						"Bommanahalli",
						"Bommasandra",
						"Byadgi",
						"Byatarayanapura",
						"Chakranagar Colony",
						"Challakere",
						"Chamrajnagar",
						"Chamundi Betta",
						"Channagiri",
						"Channapatna",
						"Channarayapatna",
						"Chickballapur",
						"Chik Ballapur",
						"Chikkaballapur",
						"Chikmagalur",
						"Chiknayakanhalli",
						"Chikodi",
						"Chincholi",
						"Chintamani",
						"Chitaguppa",
						"Chitapur",
						"Chitradurga",
						"Coorg",
						"Dandeli",
						"Dargajogihalli",
						"Dasarahalli",
						"Davangere",
						"Devadurga",
						"Devagiri",
						"Devanhalli",
						"Dharwar",
						"Dhupdal",
						"Dod Ballapur",
						"Donimalai",
						"Gadag",
						"Gajendragarh",
						"Ganeshgudi",
						"Gangawati",
						"Gangoli",
						"Gauribidanur",
						"Gokak",
						"Gokak Falls",
						"Gonikoppal",
						"Gorur",
						"Gottikere",
						"Gubbi",
						"Gudibanda",
						"Gulbarga",
						"Guledgudda",
						"Gundlupet",
						"Gurmatkal",
						"Haliyal",
						"Hangal",
						"Harihar",
						"Harpanahalli",
						"Hassan",
						"Hatti",
						"Hatti Gold Mines",
						"Haveri",
						"Hebbagodi",
						"Hebbalu",
						"Hebri",
						"Heggadadevanakote",
						"Herohalli",
						"Hidkal",
						"Hindalgi",
						"Hirekerur",
						"Hiriyur",
						"Holalkere",
						"Hole Narsipur",
						"Homnabad",
						"Honavar",
						"Honnali",
						"Hosakote",
						"Hosanagara",
						"Hosangadi",
						"Hosdurga",
						"Hoskote",
						"Hospet",
						"Hubli",
						"Hukeri",
						"Hunasagi",
						"Hunasamaranahalli",
						"Hungund",
						"Hunsur",
						"Huvina Hadagalli",
						"Ilkal",
						"Indi",
						"Jagalur",
						"Jamkhandi",
						"Jevargi",
						"Jog Falls",
						"Kabini Colony",
						"Kadur",
						"Kalghatgi",
						"Kamalapuram",
						"Kampli",
						"Kanakapura",
						"Kangrali BK",
						"Kangrali KH",
						"Kannur",
						"Karkala",
						"Karwar",
						"Kemminja",
						"Kengeri",
						"Kerur",
						"Khanapur",
						"Kodigenahalli",
						"Kodiyal",
						"Kodlipet",
						"Kolar",
						"Kollegal",
						"Konanakunte",
						"Konanur",
						"Konnur",
						"Koppa",
						"Koppal",
						"Koratagere",
						"Kotekara",
						"Kothnur",
						"Kotturu",
						"Krishnapura",
						"Krishnarajanagar",
						"Krishnarajapura",
						"Krishnarajasagara",
						"Krishnarajpet",
						"Kudchi",
						"Kudligi",
						"Kudremukh",
						"Kumsi",
						"Kumta",
						"Kundapura",
						"Kundgol",
						"Kunigal",
						"Kurgunta",
						"Kushalnagar",
						"Kushtagi",
						"Kyathanahalli",
						"Lakshmeshwar",
						"Lingsugur",
						"Londa",
						"Maddur",
						"Madhugiri",
						"Madikeri",
						"Magadi",
						"Magod Falls",
						"Mahadeswara Hills",
						"Mahadevapura",
						"Mahalingpur",
						"Maisuru",
						"Maisuru Cantonment",
						"Malavalli",
						"Mallar",
						"Malpe",
						"Malur",
						"Manchenahalli",
						"Mandya",
						"Mangalore",
						"Mangaluru",
						"Manipal",
						"Manvi",
						"Maski",
						"Mastikatte Colony",
						"Mayakonda",
						"Melukote",
						"Molakalmuru",
						"Mudalgi",
						"Mudbidri",
						"Muddebihal",
						"Mudgal",
						"Mudhol",
						"Mudigere",
						"Mudushedde",
						"Mulbagal",
						"Mulgund",
						"Mulki",
						"Mulur",
						"Mundargi",
						"Mundgod",
						"Munirabad",
						"Munnur",
						"Murudeshwara",
						"Mysore",
						"Nagamangala",
						"Nanjangud",
						"Naragund",
						"Narasimharajapura",
						"Naravi",
						"Narayanpur",
						"Naregal",
						"Navalgund",
						"Nelmangala",
						"Nipani",
						"Nitte",
						"Nyamati",
						"Padu",
						"Pandavapura",
						"Pattanagere",
						"Pavagada",
						"Piriyapatna",
						"Ponnampet",
						"Puttur",
						"Rabkavi",
						"Raichur",
						"Ramanagaram",
						"Ramdurg",
						"Ranibennur",
						"Raybag",
						"Robertsonpet",
						"Ron",
						"Sadalgi",
						"Sagar",
						"Sakleshpur",
						"Saligram",
						"Sandur",
						"Sanivarsante",
						"Sankeshwar",
						"Sargur",
						"Sathyamangala",
						"Saundatti Yellamma",
						"Savanur",
						"Sedam",
						"Shahabad",
						"Shahabad A.C.C.",
						"Shahapur",
						"Shahpur",
						"Shaktinagar",
						"Shiggaon",
						"Shikarpur",
						"Shimoga",
						"Shirhatti",
						"Shorapur",
						"Shravanabelagola",
						"Shrirangapattana",
						"Siddapur",
						"Sidlaghatta",
						"Sindgi",
						"Sindhnur",
						"Sira",
						"Sirakoppa",
						"Sirsi",
						"Siruguppa",
						"Someshwar",
						"Somvarpet",
						"Sorab",
						"Sringeri",
						"Srinivaspur",
						"Sulya",
						"Suntikopa",
						"Talikota",
						"Tarikera",
						"Tekkalakota",
						"Terdal",
						"Thokur",
						"Thumbe",
						"Tiptur",
						"Tirthahalli",
						"Tirumakudal Narsipur",
						"Tonse",
						"Tumkur",
						"Turuvekere",
						"Udupi",
						"Ullal",
						"Uttarahalli",
						"Venkatapura",
						"Vijayapura",
						"Virarajendrapet",
						"Wadi",
						"Wadi A.C.C.",
						"Yadgir",
						"Yelahanka",
						"Yelandur",
						"Yelbarga",
						"Yellapur",
						"Yenagudde",
					],
					stateName: "Karnataka",
				},
				{ cities: [], stateName: "Kenmore" },
				{
					cities: [
						"Adimaly",
						"Adoor",
						"Adur",
						"Akathiyur",
						"Alangad",
						"Alappuzha",
						"Aluva",
						"Ancharakandy",
						"Angamaly",
						"Aroor",
						"Arukutti",
						"Attingal",
						"Avinissery",
						"Azhikode North",
						"Azhikode South",
						"Azhiyur",
						"Balussery",
						"Bangramanjeshwar",
						"Beypur",
						"Brahmakulam",
						"Chala",
						"Chalakudi",
						"Changanacheri",
						"Chauwara",
						"Chavakkad",
						"Chelakkara",
						"Chelora",
						"Chendamangalam",
						"Chengamanad",
						"Chengannur",
						"Cheranallur",
						"Cheriyakadavu",
						"Cherthala",
						"Cherukunnu",
						"Cheruthazham",
						"Cheruvannur",
						"Cheruvattur",
						"Chevvur",
						"Chirakkal",
						"Chittur",
						"Chockli",
						"Churnikkara",
						"Dharmadam",
						"Edappal",
						"Edathala",
						"Elayavur",
						"Elur",
						"Eranholi",
						"Erattupetta",
						"Ernakulam",
						"Eruvatti",
						"Ettumanoor",
						"Feroke",
						"Guruvayur",
						"Haripad",
						"Hosabettu",
						"Idukki",
						"Iringaprom",
						"Irinjalakuda",
						"Iriveri",
						"Kadachira",
						"Kadalundi",
						"Kadamakkudy",
						"Kadirur",
						"Kadungallur",
						"Kakkodi",
						"Kalady",
						"Kalamassery",
						"Kalliasseri",
						"Kalpetta",
						"Kanhangad",
						"Kanhirode",
						"Kanjikkuzhi",
						"Kanjikode",
						"Kanjirappalli",
						"Kannadiparamba",
						"Kannangad",
						"Kannapuram",
						"Kannur",
						"Kannur Cantonment",
						"Karunagappally",
						"Karuvamyhuruthy",
						"Kasaragod",
						"Kasargod",
						"Kattappana",
						"Kayamkulam",
						"Kedamangalam",
						"Kochi",
						"Kodamthuruthu",
						"Kodungallur",
						"Koduvally",
						"Koduvayur",
						"Kokkothamangalam",
						"Kolazhy",
						"Kollam",
						"Komalapuram",
						"Koothattukulam",
						"Koratty",
						"Kothamangalam",
						"Kottarakkara",
						"Kottayam",
						"Kottayam Malabar",
						"Kottuvally",
						"Koyilandi",
						"Kozhikode",
						"Kudappanakunnu",
						"Kudlu",
						"Kumarakom",
						"Kumily",
						"Kunnamangalam",
						"Kunnamkulam",
						"Kurikkad",
						"Kurkkanchery",
						"Kuthuparamba",
						"Kuttakulam",
						"Kuttikkattur",
						"Kuttur",
						"Malappuram",
						"Mallappally",
						"Manjeri",
						"Manjeshwar",
						"Mannancherry",
						"Mannar",
						"Mannarakkat",
						"Maradu",
						"Marathakkara",
						"Marutharod",
						"Mattannur",
						"Mavelikara",
						"Mavilayi",
						"Mavur",
						"Methala",
						"Muhamma",
						"Mulavukad",
						"Mundakayam",
						"Munderi",
						"Munnar",
						"Muthakunnam",
						"Muvattupuzha",
						"Muzhappilangad",
						"Nadapuram",
						"Nadathara",
						"Narath",
						"Nattakam",
						"Nedumangad",
						"Nenmenikkara",
						"New Mahe",
						"Neyyattinkara",
						"Nileshwar",
						"Olavanna",
						"Ottapalam",
						"Ottappalam",
						"Paduvilayi",
						"Palai",
						"Palakkad",
						"Palayad",
						"Palissery",
						"Pallikkunnu",
						"Paluvai",
						"Panniyannur",
						"Pantalam",
						"Panthiramkavu",
						"Panur",
						"Pappinisseri",
						"Parassala",
						"Paravur",
						"Pathanamthitta",
						"Pathanapuram",
						"Pathiriyad",
						"Pattambi",
						"Pattiom",
						"Pavaratty",
						"Payyannur",
						"Peermade",
						"Perakam",
						"Peralasseri",
						"Peringathur",
						"Perinthalmanna",
						"Perole",
						"Perumanna",
						"Perumbaikadu",
						"Perumbavoor",
						"Pinarayi",
						"Piravam",
						"Ponnani",
						"Pottore",
						"Pudukad",
						"Punalur",
						"Puranattukara",
						"Puthunagaram",
						"Puthuppariyaram",
						"Puzhathi",
						"Ramanattukara",
						"Shoranur",
						"Sultans Battery",
						"Sulthan Bathery",
						"Talipparamba",
						"Thaikkad",
						"Thalassery",
						"Thannirmukkam",
						"Theyyalingal",
						"Thiruvalla",
						"Thiruvananthapuram",
						"Thiruvankulam",
						"Thodupuzha",
						"Thottada",
						"Thrippunithura",
						"Thrissur",
						"Tirur",
						"Udma",
						"Vadakara",
						"Vaikam",
						"Valapattam",
						"Vallachira",
						"Varam",
						"Varappuzha",
						"Varkala",
						"Vayalar",
						"Vazhakkala",
						"Venmanad",
						"Villiappally",
						"Wayanad",
					],
					stateName: "Kerala",
				},
				{
					cities: ["Agethi", "Amini", "Androth Island", "Kavaratti", "Minicoy"],
					stateName: "Lakshadweep",
				},
				{
					cities: [
						"Agar",
						"Ajaigarh",
						"Akoda",
						"Akodia",
						"Alampur",
						"Alirajpur",
						"Alot",
						"Amanganj",
						"Amarkantak",
						"Amarpatan",
						"Amarwara",
						"Ambada",
						"Ambah",
						"Amla",
						"Amlai",
						"Anjad",
						"Antri",
						"Anuppur",
						"Aron",
						"Ashoknagar",
						"Ashta",
						"Babai",
						"Bada Malhera",
						"Badagaon",
						"Badagoan",
						"Badarwas",
						"Badawada",
						"Badi",
						"Badkuhi",
						"Badnagar",
						"Badnawar",
						"Badod",
						"Badoda",
						"Badra",
						"Bagh",
						"Bagli",
						"Baihar",
						"Baikunthpur",
						"Bakswaha",
						"Balaghat",
						"Baldeogarh",
						"Bamaniya",
						"Bamhani",
						"Bamor",
						"Bamora",
						"Banda",
						"Bangawan",
						"Bansatar Kheda",
						"Baraily",
						"Barela",
						"Barghat",
						"Bargi",
						"Barhi",
						"Barigarh",
						"Barwaha",
						"Barwani",
						"Basoda",
						"Begamganj",
						"Beohari",
						"Berasia",
						"Betma",
						"Betul",
						"Betul Bazar",
						"Bhainsdehi",
						"Bhamodi",
						"Bhander",
						"Bhanpura",
						"Bharveli",
						"Bhaurasa",
						"Bhavra",
						"Bhedaghat",
						"Bhikangaon",
						"Bhilakhedi",
						"Bhind",
						"Bhitarwar",
						"Bhopal",
						"Bhuibandh",
						"Biaora",
						"Bijawar",
						"Bijeypur",
						"Bijrauni",
						"Bijuri",
						"Bilaua",
						"Bilpura",
						"Bina Railway Colony",
						"Bina-Etawa",
						"Birsinghpur",
						"Boda",
						"Budhni",
						"Burhanpur",
						"Burhar",
						"Chachaura Binaganj",
						"Chakghat",
						"Chandameta Butar",
						"Chanderi",
						"Chandia",
						"Chandla",
						"Chaurai Khas",
						"Chhatarpur",
						"Chhindwara",
						"Chhota Chhindwara",
						"Chichli",
						"Chitrakut",
						"Churhat",
						"Daboh",
						"Dabra",
						"Damoh",
						"Damua",
						"Datia",
						"Deodara",
						"Deori",
						"Deori Khas",
						"Depalpur",
						"Devendranagar",
						"Devhara",
						"Dewas",
						"Dhamnod",
						"Dhana",
						"Dhanpuri",
						"Dhar",
						"Dharampuri",
						"Dighawani",
						"Diken",
						"Dindori",
						"Dola",
						"Dumar Kachhar",
						"Dungariya Chhapara",
						"Gadarwara",
						"Gairatganj",
						"Gandhi Sagar Hydel Colony",
						"Ganjbasoda",
						"Garhakota",
						"Garhi Malhara",
						"Garoth",
						"Gautapura",
						"Ghansor",
						"Ghuwara",
						"Gogaon",
						"Gogapur",
						"Gohad",
						"Gormi",
						"Govindgarh",
						"Guna",
						"Gurh",
						"Gwalior",
						"Hanumana",
						"Harda",
						"Harpalpur",
						"Harrai",
						"Harsud",
						"Hatod",
						"Hatpipalya",
						"Hatta",
						"Hindoria",
						"Hirapur",
						"Hoshangabad",
						"Ichhawar",
						"Iklehra",
						"Indergarh",
						"Indore",
						"Isagarh",
						"Itarsi",
						"Jabalpur",
						"Jabalpur Cantonment",
						"Jabalpur G.C.F",
						"Jaisinghnagar",
						"Jaithari",
						"Jaitwara",
						"Jamai",
						"Jaora",
						"Jatachhapar",
						"Jatara",
						"Jawad",
						"Jawar",
						"Jeronkhalsa",
						"Jhabua",
						"Jhundpura",
						"Jiran",
						"Jirapur",
						"Jobat",
						"Joura",
						"Kailaras",
						"Kaimur",
						"Kakarhati",
						"Kalichhapar",
						"Kanad",
						"Kannod",
						"Kantaphod",
						"Kareli",
						"Karera",
						"Kari",
						"Karnawad",
						"Karrapur",
						"Kasrawad",
						"Katangi",
						"Katni",
						"Kelhauri",
						"Khachrod",
						"Khajuraho",
						"Khamaria",
						"Khand",
						"Khandwa",
						"Khaniyadhana",
						"Khargapur",
						"Khargone",
						"Khategaon",
						"Khetia",
						"Khilchipur",
						"Khirkiya",
						"Khujner",
						"Khurai",
						"Kolaras",
						"Kotar",
						"Kothi",
						"Kotma",
						"Kukshi",
						"Kumbhraj",
						"Kurwai",
						"Lahar",
						"Lakhnadon",
						"Lateri",
						"Laundi",
						"Lidhora Khas",
						"Lodhikheda",
						"Loharda",
						"Machalpur",
						"Madhogarh",
						"Maharajpur",
						"Maheshwar",
						"Mahidpur",
						"Maihar",
						"Majholi",
						"Makronia",
						"Maksi",
						"Malaj Khand",
						"Malanpur",
						"Malhargarh",
						"Manasa",
						"Manawar",
						"Mandav",
						"Mandideep",
						"Mandla",
						"Mandleshwar",
						"Mandsaur",
						"Manegaon",
						"Mangawan",
						"Manglaya Sadak",
						"Manpur",
						"Mau",
						"Mauganj",
						"Meghnagar",
						"Mehara Gaon",
						"Mehgaon",
						"Mhaugaon",
						"Mhow",
						"Mihona",
						"Mohgaon",
						"Morar",
						"Morena",
						"Morwa",
						"Multai",
						"Mundi",
						"Mungaoli",
						"Murwara",
						"Nagda",
						"Nagod",
						"Nagri",
						"Naigarhi",
						"Nainpur",
						"Nalkheda",
						"Namli",
						"Narayangarh",
						"Narsimhapur",
						"Narsingarh",
						"Narsinghpur",
						"Narwar",
						"Nasrullaganj",
						"Naudhia",
						"Naugaon",
						"Naurozabad",
						"Neemuch",
						"Nepa Nagar",
						"Neuton Chikhli Kalan",
						"Nimach",
						"Niwari",
						"Obedullaganj",
						"Omkareshwar",
						"Orachha",
						"Ordinance Factory Itarsi",
						"Pachmarhi",
						"Pachmarhi Cantonment",
						"Pachore",
						"Palchorai",
						"Palda",
						"Palera",
						"Pali",
						"Panagar",
						"Panara",
						"Pandaria",
						"Pandhana",
						"Pandhurna",
						"Panna",
						"Pansemal",
						"Parasia",
						"Pasan",
						"Patan",
						"Patharia",
						"Pawai",
						"Petlawad",
						"Phuph Kalan",
						"Pichhore",
						"Pipariya",
						"Pipliya Mandi",
						"Piploda",
						"Pithampur",
						"Polay Kalan",
						"Porsa",
						"Prithvipur",
						"Raghogarh",
						"Rahatgarh",
						"Raisen",
						"Rajakhedi",
						"Rajgarh",
						"Rajnagar",
						"Rajpur",
						"Rampur Baghelan",
						"Rampur Naikin",
						"Rampura",
						"Ranapur",
						"Ranipura",
						"Ratangarh",
						"Ratlam",
						"Ratlam Kasba",
						"Rau",
						"Rehli",
						"Rehti",
						"Rewa",
						"Sabalgarh",
						"Sagar",
						"Sagar Cantonment",
						"Sailana",
						"Sanawad",
						"Sanchi",
						"Sanwer",
						"Sarangpur",
						"Sardarpur",
						"Sarni",
						"Satai",
						"Satna",
						"Satwas",
						"Sausar",
						"Sehore",
						"Semaria",
						"Sendhwa",
						"Seondha",
						"Seoni",
						"Seoni Malwa",
						"Sethia",
						"Shahdol",
						"Shahgarh",
						"Shahpur",
						"Shahpura",
						"Shajapur",
						"Shamgarh",
						"Sheopur",
						"Shivpuri",
						"Shujalpur",
						"Sidhi",
						"Sihora",
						"Singolo",
						"Singrauli",
						"Sinhasa",
						"Sirgora",
						"Sirmaur",
						"Sironj",
						"Sitamau",
						"Sohagpur",
						"Sonkatch",
						"Soyatkalan",
						"Suhagi",
						"Sultanpur",
						"Susner",
						"Suthaliya",
						"Tal",
						"Talen",
						"Tarana",
						"Taricharkalan",
						"Tekanpur",
						"Tendukheda",
						"Teonthar",
						"Thandia",
						"Tikamgarh",
						"Timarni",
						"Tirodi",
						"Udaipura",
						"Ujjain",
						"Ukwa",
						"Umaria",
						"Unchahara",
						"Unhel",
						"Vehicle Factory Jabalpur",
						"Vidisha",
						"Vijayraghavgarh",
						"Waraseoni",
					],
					stateName: "Madhya Pradesh",
				},
				{
					cities: [
						"Achalpur",
						"Aheri",
						"Ahmadnagar Cantonment",
						"Ahmadpur",
						"Ahmednagar",
						"Ajra",
						"Akalkot",
						"Akkalkuwa",
						"Akola",
						"Akot",
						"Alandi",
						"Alibag",
						"Allapalli",
						"Alore",
						"Amalner",
						"Ambad",
						"Ambajogai",
						"Ambernath",
						"Ambivali Tarf Wankhal",
						"Amgaon",
						"Amravati",
						"Anjangaon",
						"Arvi",
						"Ashta",
						"Ashti",
						"Aurangabad",
						"Aurangabad Cantonment",
						"Ausa",
						"Babhulgaon",
						"Badlapur",
						"Balapur",
						"Ballarpur",
						"Baramati",
						"Barshi",
						"Basmat",
						"Beed",
						"Bhadravati",
						"Bhagur",
						"Bhandara",
						"Bhigvan",
						"Bhingar",
						"Bhiwandi",
						"Bhokhardan",
						"Bhor",
						"Bhosari",
						"Bhum",
						"Bhusawal",
						"Bid",
						"Biloli",
						"Birwadi",
						"Boisar",
						"Bop Khel",
						"Brahmapuri",
						"Budhgaon",
						"Buldana",
						"Buldhana",
						"Butibori",
						"Chakan",
						"Chalisgaon",
						"Chandrapur",
						"Chandur",
						"Chandur Bazar",
						"Chandvad",
						"Chicholi",
						"Chikhala",
						"Chikhaldara",
						"Chikhli",
						"Chinchani",
						"Chinchwad",
						"Chiplun",
						"Chopda",
						"Dabhol",
						"Dahance",
						"Dahanu",
						"Daharu",
						"Dapoli Camp",
						"Darwa",
						"Daryapur",
						"Dattapur",
						"Daund",
						"Davlameti",
						"Deglur",
						"Dehu Road",
						"Deolali",
						"Deolali Pravara",
						"Deoli",
						"Desaiganj",
						"Deulgaon Raja",
						"Dewhadi",
						"Dharangaon",
						"Dharmabad",
						"Dharur",
						"Dhatau",
						"Dhule",
						"Digdoh",
						"Diglur",
						"Digras",
						"Dombivli",
						"Dondaicha",
						"Dudhani",
						"Durgapur",
						"Dyane",
						"Edandol",
						"Eklahare",
						"Faizpur",
						"Fekari",
						"Gadchiroli",
						"Gadhinghaj",
						"Gandhi Nagar",
						"Ganeshpur",
						"Gangakher",
						"Gangapur",
						"Gevrai",
						"Ghatanji",
						"Ghoti",
						"Ghugus",
						"Ghulewadi",
						"Godoli",
						"Gondia",
						"Guhagar",
						"Hadgaon",
						"Harnai Beach",
						"Hinganghat",
						"Hingoli",
						"Hupari",
						"Ichalkaranji",
						"Igatpuri",
						"Indapur",
						"Jaisinghpur",
						"Jalgaon",
						"Jalna",
						"Jamkhed",
						"Jawhar",
						"Jaysingpur",
						"Jejuri",
						"Jintur",
						"Junnar",
						"Kabnur",
						"Kagal",
						"Kalamb",
						"Kalamnuri",
						"Kalas",
						"Kalmeshwar",
						"Kalundre",
						"Kalyan",
						"Kamthi",
						"Kamthi Cantonment",
						"Kandari",
						"Kandhar",
						"Kandri",
						"Kandri II",
						"Kanhan",
						"Kankavli",
						"Kannad",
						"Karad",
						"Karanja",
						"Karanje Tarf",
						"Karivali",
						"Karjat",
						"Karmala",
						"Kasara Budruk",
						"Katai",
						"Katkar",
						"Katol",
						"Kegaon",
						"Khadkale",
						"Khadki",
						"Khamgaon",
						"Khapa",
						"Kharadi",
						"Kharakvasla",
						"Khed",
						"Kherdi",
						"Khoni",
						"Khopoli",
						"Khuldabad",
						"Kinwat",
						"Kodoli",
						"Kolhapur",
						"Kon",
						"Kondumal",
						"Kopargaon",
						"Kopharad",
						"Koradi",
						"Koregaon",
						"Korochi",
						"Kudal",
						"Kundaim",
						"Kundalwadi",
						"Kurandvad",
						"Kurduvadi",
						"Kusgaon Budruk",
						"Lanja",
						"Lasalgaon",
						"Latur",
						"Loha",
						"Lohegaon",
						"Lonar",
						"Lonavala",
						"Madhavnagar",
						"Mahabaleshwar",
						"Mahad",
						"Mahadula",
						"Maindargi",
						"Majalgaon",
						"Malegaon",
						"Malgaon",
						"Malkapur",
						"Malwan",
						"Manadur",
						"Manchar",
						"Mangalvedhe",
						"Mangrul Pir",
						"Manmad",
						"Manor",
						"Mansar",
						"Manwath",
						"Mapuca",
						"Matheran",
						"Mehkar",
						"Mhasla",
						"Mhaswad",
						"Mira Bhayandar",
						"Miraj",
						"Mohpa",
						"Mohpada",
						"Moram",
						"Morshi",
						"Mowad",
						"Mudkhed",
						"Mukhed",
						"Mul",
						"Mulshi",
						"Mumbai",
						"Murbad",
						"Murgud",
						"Murtijapur",
						"Murud",
						"Nachane",
						"Nagardeole",
						"Nagothane",
						"Nagpur",
						"Nakoda",
						"Nalasopara",
						"Naldurg",
						"Nanded",
						"Nandgaon",
						"Nandura",
						"Nandurbar",
						"Narkhed",
						"Nashik",
						"Navapur",
						"Navi Mumbai",
						"Navi Mumbai Panvel",
						"Neral",
						"Nigdi",
						"Nilanga",
						"Nildoh",
						"Nimbhore",
						"Ojhar",
						"Osmanabad",
						"Pachgaon",
						"Pachora",
						"Padagha",
						"Paithan",
						"Palghar",
						"Pali",
						"Panchgani",
						"Pandhakarwada",
						"Pandharpur",
						"Panhala",
						"Panvel",
						"Paranda",
						"Parbhani",
						"Parli",
						"Parola",
						"Partur",
						"Pasthal",
						"Patan",
						"Pathardi",
						"Pathri",
						"Patur",
						"Pawni",
						"Pen",
						"Pethumri",
						"Phaltan",
						"Pimpri",
						"Poladpur",
						"Pulgaon",
						"Pune",
						"Pune Cantonment",
						"Purna",
						"Purushottamnagar",
						"Pusad",
						"Rahimatpur",
						"Rahta Pimplas",
						"Rahuri",
						"Raigad",
						"Rajapur",
						"Rajgurunagar",
						"Rajur",
						"Rajura",
						"Ramtek",
						"Ratnagiri",
						"Ravalgaon",
						"Raver",
						"Revadanda",
						"Risod",
						"Roha Ashtami",
						"Sakri",
						"Sandor",
						"Sangamner",
						"Sangli",
						"Sangole",
						"Sasti",
						"Sasvad",
						"Satana",
						"Satara",
						"Savantvadi",
						"Savda",
						"Savner",
						"Sawari Jawharnagar",
						"Selu",
						"Shahada",
						"Shahapur",
						"Shegaon",
						"Shelar",
						"Shendurjana",
						"Shirdi",
						"Shirgaon",
						"Shirpur",
						"Shirur",
						"Shirwal",
						"Shivatkar",
						"Shrigonda",
						"Shrirampur",
						"Shrirampur Rural",
						"Sillewada",
						"Sillod",
						"Sindhudurg",
						"Sindi",
						"Sindi Turf Hindnagar",
						"Sindkhed Raja",
						"Singnapur",
						"Sinnar",
						"Sirur",
						"Sitasawangi",
						"Solapur",
						"Sonai",
						"Sonegaon",
						"Soyagaon",
						"Srivardhan",
						"Surgana",
						"Talegaon Dabhade",
						"Taloda",
						"Taloja",
						"Talwade",
						"Tarapur",
						"Tasgaon",
						"Tathavade",
						"Tekadi",
						"Telhara",
						"Thane",
						"Tirira",
						"Totaladoh",
						"Trimbak",
						"Tuljapur",
						"Tumsar",
						"Uchgaon",
						"Udgir",
						"Ulhasnagar",
						"Umarga",
						"Umarkhed",
						"Umarsara",
						"Umbar Pada Nandade",
						"Umred",
						"Umri Pragane Balapur",
						"Uran",
						"Uran Islampur",
						"Utekhol",
						"Vada",
						"Vadgaon",
						"Vadgaon Kasba",
						"Vaijapur",
						"Vanvadi",
						"Varangaon",
						"Vasai",
						"Vasantnagar",
						"Vashind",
						"Vengurla",
						"Virar",
						"Visapur",
						"Vite",
						"Vithalwadi",
						"Wadi",
						"Waghapur",
						"Wai",
						"Wajegaon",
						"Walani",
						"Wanadongri",
						"Wani",
						"Wardha",
						"Warora",
						"Warthi",
						"Warud",
						"Washim",
						"Yaval",
						"Yavatmal",
						"Yeola",
						"Yerkheda",
					],
					stateName: "Maharashtra",
				},
				{
					cities: [
						"Andro",
						"Bijoy Govinda",
						"Bishnupur",
						"Churachandpur",
						"Heriok",
						"Imphal",
						"Jiribam",
						"Kakching",
						"Kakching Khunou",
						"Khongman",
						"Kumbi",
						"Kwakta",
						"Lamai",
						"Lamjaotongba",
						"Lamshang",
						"Lilong",
						"Mayang Imphal",
						"Moirang",
						"Moreh",
						"Nambol",
						"Naoriya Pakhanglakpa",
						"Ningthoukhong",
						"Oinam",
						"Porompat",
						"Samurou",
						"Sekmai Bazar",
						"Senapati",
						"Sikhong Sekmai",
						"Sugnu",
						"Thongkhong Laxmi Bazar",
						"Thoubal",
						"Torban",
						"Wangjing",
						"Wangoi",
						"Yairipok",
					],
					stateName: "Manipur",
				},
				{
					cities: [
						"Baghmara",
						"Cherrapunji",
						"Jawai",
						"Madanrting",
						"Mairang",
						"Mawlai",
						"Nongmynsong",
						"Nongpoh",
						"Nongstoin",
						"Nongthymmai",
						"Pynthorumkhrah",
						"Resubelpara",
						"Shillong",
						"Shillong Cantonment",
						"Tura",
						"Williamnagar",
					],
					stateName: "Meghalaya",
				},
				{
					cities: [
						"Aizawl",
						"Bairabi",
						"Biate",
						"Champhai",
						"Darlawn",
						"Hnahthial",
						"Kawnpui",
						"Khawhai",
						"Khawzawl",
						"Kolasib",
						"Lengpui",
						"Lunglei",
						"Mamit",
						"North Vanlaiphai",
						"Saiha",
						"Sairang",
						"Saitul",
						"Serchhip",
						"Thenzawl",
						"Tlabung",
						"Vairengte",
						"Zawlnuam",
					],
					stateName: "Mizoram",
				},
				{
					cities: [
						"Chumukedima",
						"Dimapur",
						"Kohima",
						"Mokokchung",
						"Mon",
						"Phek",
						"Tuensang",
						"Wokha",
						"Zunheboto",
					],
					stateName: "Nagaland",
				},
				{ cities: [], stateName: "Narora" },
				{ cities: [], stateName: "Natwar" },
				{
					cities: [
						"Anandapur",
						"Angul",
						"Aska",
						"Athgarh",
						"Athmallik",
						"Balagoda",
						"Balangir",
						"Balasore",
						"Baleshwar",
						"Balimeta",
						"Balugaon",
						"Banapur",
						"Bangura",
						"Banki",
						"Banposh",
						"Barbil",
						"Bargarh",
						"Baripada",
						"Barpali",
						"Basudebpur",
						"Baudh",
						"Belagachhia",
						"Belaguntha",
						"Belpahar",
						"Berhampur",
						"Bhadrak",
						"Bhanjanagar",
						"Bhawanipatna",
						"Bhuban",
						"Bhubaneswar",
						"Binika",
						"Birmitrapur",
						"Bishama Katek",
						"Bolangir",
						"Brahmapur",
						"Brajrajnagar",
						"Buguda",
						"Burla",
						"Byasanagar",
						"Champua",
						"Chandapur",
						"Chandbali",
						"Chandili",
						"Charibatia",
						"Chatrapur",
						"Chikitigarh",
						"Chitrakonda",
						"Choudwar",
						"Cuttack",
						"Dadhapatna",
						"Daitari",
						"Damanjodi",
						"Deogarh",
						"Deracolliery",
						"Dhamanagar",
						"Dhenkanal",
						"Digapahandi",
						"Dungamal",
						"Fertilizer Corporation of Indi",
						"Ganjam",
						"Ghantapada",
						"Gopalpur",
						"Gudari",
						"Gunupur",
						"Hatibandha",
						"Hinjilikatu",
						"Hirakud",
						"Jagatsinghapur",
						"Jajpur",
						"Jalda",
						"Jaleswar",
						"Jatni",
						"Jaypur",
						"Jeypore",
						"Jharsuguda",
						"Jhumpura",
						"Joda",
						"Junagarh",
						"Kamakhyanagar",
						"Kantabanji",
						"Kantilo",
						"Karanja",
						"Kashinagara",
						"Kataka",
						"Kavisuryanagar",
						"Kendrapara",
						"Kendujhar",
						"Keonjhar",
						"Kesinga",
						"Khaliapali",
						"Khalikote",
						"Khandaparha",
						"Kharhial",
						"Kharhial Road",
						"Khatiguda",
						"Khurda",
						"Kochinda",
						"Kodala",
						"Konark",
						"Koraput",
						"Kotaparh",
						"Lanjigarh",
						"Lattikata",
						"Makundapur",
						"Malkangiri",
						"Mukhiguda",
						"Nabarangpur",
						"Nalco",
						"Naurangapur",
						"Nayagarh",
						"Nilagiri",
						"Nimaparha",
						"Nuapada",
						"Nuapatna",
						"OCL Industrialship",
						"Padampur",
						"Paradip",
						"Paradwip",
						"Parlakimidi",
						"Patamundai",
						"Patnagarh",
						"Phulabani",
						"Pipili",
						"Polasara",
						"Pratapsasan",
						"Puri",
						"Purushottampur",
						"Rairangpur",
						"Raj Gangpur",
						"Rambha",
						"Raurkela",
						"Raurkela Civil Township",
						"Rayagada",
						"Redhakhol",
						"Remuna",
						"Rengali",
						"Rourkela",
						"Sambalpur",
						"Sinapali",
						"Sonepur",
						"Sorada",
						"Soro",
						"Sunabeda",
						"Sundargarh",
						"Talcher",
						"Talcher Thermal Power Station ",
						"Tarabha",
						"Tensa",
						"Titlagarh",
						"Udala",
						"Udayagiri",
						"Umarkot",
						"Vikrampur",
					],
					stateName: "Odisha",
				},
				{ cities: [], stateName: "Paschim Medinipur" },
				{
					cities: [
						"Ariankuppam",
						"Karaikal",
						"Kurumbapet",
						"Mahe",
						"Ozhukarai",
						"Pondicherry",
						"Villianur",
						"Yanam",
					],
					stateName: "Pondicherry",
				},
				{
					cities: [
						"Abohar",
						"Adampur",
						"Ahmedgarh",
						"Ajnala",
						"Akalgarh",
						"Alawalpur",
						"Amloh",
						"Amritsar",
						"Amritsar Cantonment",
						"Anandpur Sahib",
						"Badhni Kalan",
						"Bagh Purana",
						"Balachaur",
						"Banaur",
						"Banga",
						"Banur",
						"Baretta",
						"Bariwala",
						"Barnala",
						"Bassi Pathana",
						"Batala",
						"Bathinda",
						"Begowal",
						"Behrampur",
						"Bhabat",
						"Bhadur",
						"Bhankharpur",
						"Bharoli Kalan",
						"Bhawanigarh",
						"Bhikhi",
						"Bhikhiwind",
						"Bhisiana",
						"Bhogpur",
						"Bhuch",
						"Bhulath",
						"Budha Theh",
						"Budhlada",
						"Chima",
						"Chohal",
						"Dasuya",
						"Daulatpur",
						"Dera Baba Nanak",
						"Dera Bassi",
						"Dhanaula",
						"Dharam Kot",
						"Dhariwal",
						"Dhilwan",
						"Dhuri",
						"Dinanagar",
						"Dirba",
						"Doraha",
						"Faridkot",
						"Fateh Nangal",
						"Fatehgarh Churian",
						"Fatehgarh Sahib",
						"Fazilka",
						"Firozpur",
						"Firozpur Cantonment",
						"Gardhiwala",
						"Garhshankar",
						"Ghagga",
						"Ghanaur",
						"Giddarbaha",
						"Gobindgarh",
						"Goniana",
						"Goraya",
						"Gurdaspur",
						"Guru Har Sahai",
						"Hajipur",
						"Handiaya",
						"Hariana",
						"Hoshiarpur",
						"Hussainpur",
						"Jagraon",
						"Jaitu",
						"Jalalabad",
						"Jalandhar",
						"Jalandhar Cantonment",
						"Jandiala",
						"Jugial",
						"Kalanaur",
						"Kapurthala",
						"Karoran",
						"Kartarpur",
						"Khamanon",
						"Khanauri",
						"Khanna",
						"Kharar",
						"Khem Karan",
						"Kot Fatta",
						"Kot Isa Khan",
						"Kot Kapura",
						"Kotkapura",
						"Kurali",
						"Lalru",
						"Lehra Gaga",
						"Lodhian Khas",
						"Longowal",
						"Ludhiana",
						"Machhiwara",
						"Mahilpur",
						"Majitha",
						"Makhu",
						"Malaut",
						"Malerkotla",
						"Maloud",
						"Mandi Gobindgarh",
						"Mansa",
						"Maur",
						"Moga",
						"Mohali",
						"Moonak",
						"Morinda",
						"Mukerian",
						"Muktsar",
						"Mullanpur Dakha",
						"Mullanpur Garibdas",
						"Munak",
						"Muradpura",
						"Nabha",
						"Nakodar",
						"Nangal",
						"Nawashahr",
						"Naya Nangal",
						"Nehon",
						"Nurmahal",
						"Pathankot",
						"Patiala",
						"Patti",
						"Pattran",
						"Payal",
						"Phagwara",
						"Phillaur",
						"Qadian",
						"Rahon",
						"Raikot",
						"Raja Sansi",
						"Rajpura",
						"Ram Das",
						"Raman",
						"Rampura",
						"Rayya",
						"Rupnagar",
						"Rurki Kasba",
						"Sahnewal",
						"Samana",
						"Samrala",
						"Sanaur",
						"Sangat",
						"Sangrur",
						"Sansarpur",
						"Sardulgarh",
						"Shahkot",
						"Sham Churasi",
						"Shekhpura",
						"Sirhind",
						"Sri Hargobindpur",
						"Sujanpur",
						"Sultanpur Lodhi",
						"Sunam",
						"Talwandi Bhai",
						"Talwara",
						"Tappa",
						"Tarn Taran",
						"Urmar Tanda",
						"Zira",
						"Zirakpur",
					],
					stateName: "Punjab",
				},
				{
					cities: [
						"Abu Road",
						"Ajmer",
						"Aklera",
						"Alwar",
						"Amet",
						"Antah",
						"Anupgarh",
						"Asind",
						"Bagar",
						"Bagru",
						"Bahror",
						"Bakani",
						"Bali",
						"Balotra",
						"Bandikui",
						"Banswara",
						"Baran",
						"Bari",
						"Bari Sadri",
						"Barmer",
						"Basi",
						"Basni Belima",
						"Baswa",
						"Bayana",
						"Beawar",
						"Begun",
						"Bhadasar",
						"Bhadra",
						"Bhalariya",
						"Bharatpur",
						"Bhasawar",
						"Bhawani Mandi",
						"Bhawri",
						"Bhilwara",
						"Bhindar",
						"Bhinmal",
						"Bhiwadi",
						"Bijoliya Kalan",
						"Bikaner",
						"Bilara",
						"Bissau",
						"Borkhera",
						"Budhpura",
						"Bundi",
						"Chatsu",
						"Chechat",
						"Chhabra",
						"Chhapar",
						"Chhipa Barod",
						"Chhoti Sadri",
						"Chirawa",
						"Chittaurgarh",
						"Chittorgarh",
						"Chomun",
						"Churu",
						"Daosa",
						"Dariba",
						"Dausa",
						"Deoli",
						"Deshnok",
						"Devgarh",
						"Devli",
						"Dhariawad",
						"Dhaulpur",
						"Dholpur",
						"Didwana",
						"Dig",
						"Dungargarh",
						"Dungarpur",
						"Falna",
						"Fatehnagar",
						"Fatehpur",
						"Gajsinghpur",
						"Galiakot",
						"Ganganagar",
						"Gangapur",
						"Goredi Chancha",
						"Gothra",
						"Govindgarh",
						"Gulabpura",
						"Hanumangarh",
						"Hindaun",
						"Indragarh",
						"Jahazpur",
						"Jaipur",
						"Jaisalmer",
						"Jaiselmer",
						"Jaitaran",
						"Jalore",
						"Jhalawar",
						"Jhalrapatan",
						"Jhunjhunun",
						"Jobner",
						"Jodhpur",
						"Kaithun",
						"Kaman",
						"Kankroli",
						"Kanor",
						"Kapasan",
						"Kaprain",
						"Karanpura",
						"Karauli",
						"Kekri",
						"Keshorai Patan",
						"Kesrisinghpur",
						"Khairthal",
						"Khandela",
						"Khanpur",
						"Kherli",
						"Kherliganj",
						"Kherwara Chhaoni",
						"Khetri",
						"Kiranipura",
						"Kishangarh",
						"Kishangarh Ranwal",
						"Kolvi Rajendrapura",
						"Kot Putli",
						"Kota",
						"Kuchaman",
						"Kuchera",
						"Kumbhalgarh",
						"Kumbhkot",
						"Kumher",
						"Kushalgarh",
						"Lachhmangarh",
						"Ladnun",
						"Lakheri",
						"Lalsot",
						"Losal",
						"Madanganj",
						"Mahu Kalan",
						"Mahwa",
						"Makrana",
						"Malpura",
						"Mandal",
						"Mandalgarh",
						"Mandawar",
						"Mandwa",
						"Mangrol",
						"Manohar Thana",
						"Manoharpur",
						"Marwar",
						"Merta",
						"Modak",
						"Mount Abu",
						"Mukandgarh",
						"Mundwa",
						"Nadbai",
						"Naenwa",
						"Nagar",
						"Nagaur",
						"Napasar",
						"Naraina",
						"Nasirabad",
						"Nathdwara",
						"Nawa",
						"Nawalgarh",
						"Neem Ka Thana",
						"Neemrana",
						"Newa Talai",
						"Nimaj",
						"Nimbahera",
						"Niwai",
						"Nohar",
						"Nokha",
						"One SGM",
						"Padampur",
						"Pali",
						"Partapur",
						"Parvatsar",
						"Pasoond",
						"Phalna",
						"Phalodi",
						"Phulera",
						"Pilani",
						"Pilibanga",
						"Pindwara",
						"Pipalia Kalan",
						"Pipar",
						"Pirawa",
						"Pokaran",
						"Pratapgarh",
						"Pushkar",
						"Raipur",
						"Raisinghnagar",
						"Rajakhera",
						"Rajaldesar",
						"Rajgarh",
						"Rajsamand",
						"Ramganj Mandi",
						"Ramgarh",
						"Rani",
						"Raniwara",
						"Ratan Nagar",
						"Ratangarh",
						"Rawatbhata",
						"Rawatsar",
						"Rikhabdev",
						"Ringas",
						"Sadri",
						"Sadulshahar",
						"Sagwara",
						"Salumbar",
						"Sambhar",
						"Samdari",
						"Sanchor",
						"Sangariya",
						"Sangod",
						"Sardarshahr",
						"Sarwar",
						"Satal Kheri",
						"Sawai Madhopur",
						"Sewan Kalan",
						"Shahpura",
						"Sheoganj",
						"Sikar",
						"Sirohi",
						"Siwana",
						"Sogariya",
						"Sojat",
						"Sojat Road",
						"Sri Madhopur",
						"Sriganganagar",
						"Sujangarh",
						"Suket",
						"Sumerpur",
						"Sunel",
						"Surajgarh",
						"Suratgarh",
						"Swaroopganj",
						"Takhatgarh",
						"Taranagar",
						"Three STR",
						"Tijara",
						"Toda Bhim",
						"Toda Raisingh",
						"Todra",
						"Tonk",
						"Udaipur",
						"Udpura",
						"Uniara",
						"Vanasthali",
						"Vidyavihar",
						"Vijainagar",
						"Viratnagar",
						"Wer",
					],
					stateName: "Rajasthan",
				},
				{
					cities: [
						"Gangtok",
						"Gezing",
						"Jorethang",
						"Mangan",
						"Namchi",
						"Naya Bazar",
						"No City",
						"Rangpo",
						"Sikkim",
						"Singtam",
						"Upper Tadong",
					],
					stateName: "Sikkim",
				},
				{
					cities: [
						"Abiramam",
						"Achampudur",
						"Acharapakkam",
						"Acharipallam",
						"Achipatti",
						"Adikaratti",
						"Adiramapattinam",
						"Aduturai",
						"Adyar",
						"Agaram",
						"Agasthiswaram",
						"Akkaraipettai",
						"Alagappapuram",
						"Alagapuri",
						"Alampalayam",
						"Alandur",
						"Alanganallur",
						"Alangayam",
						"Alangudi",
						"Alangulam",
						"Alanthurai",
						"Alapakkam",
						"Allapuram",
						"Alur",
						"Alwar Tirunagari",
						"Alwarkurichi",
						"Ambasamudram",
						"Ambur",
						"Ammainaickanur",
						"Ammaparikuppam",
						"Ammapettai",
						"Ammavarikuppam",
						"Ammur",
						"Anaimalai",
						"Anaiyur",
						"Anakaputhur",
						"Ananthapuram",
						"Andanappettai",
						"Andipalayam",
						"Andippatti",
						"Anjugramam",
						"Annamalainagar",
						"Annavasal",
						"Annur",
						"Anthiyur",
						"Appakudal",
						"Arachalur",
						"Arakandanallur",
						"Arakonam",
						"Aralvaimozhi",
						"Arani",
						"Arani Road",
						"Arantangi",
						"Arasiramani",
						"Aravakurichi",
						"Aravankadu",
						"Arcot",
						"Arimalam",
						"Ariyalur",
						"Ariyappampalayam",
						"Ariyur",
						"Arni",
						"Arulmigu Thirumuruganpundi",
						"Arumanai",
						"Arumbavur",
						"Arumuganeri",
						"Aruppukkottai",
						"Ashokapuram",
						"Athani",
						"Athanur",
						"Athimarapatti",
						"Athipattu",
						"Athur",
						"Attayyampatti",
						"Attur",
						"Auroville",
						"Avadattur",
						"Avadi",
						"Avalpundurai",
						"Avaniapuram",
						"Avinashi",
						"Ayakudi",
						"Ayanadaippu",
						"Aygudi",
						"Ayothiapattinam",
						"Ayyalur",
						"Ayyampalayam",
						"Ayyampettai",
						"Azhagiapandiapuram",
						"Balakrishnampatti",
						"Balakrishnapuram",
						"Balapallam",
						"Balasamudram",
						"Bargur",
						"Belur",
						"Berhatty",
						"Bhavani",
						"Bhawanisagar",
						"Bhuvanagiri",
						"Bikketti",
						"Bodinayakkanur",
						"Brahmana Periya Agraharam",
						"Buthapandi",
						"Buthipuram",
						"Chatrapatti",
						"Chembarambakkam",
						"Chengalpattu",
						"Chengam",
						"Chennai",
						"Chennasamudram",
						"Chennimalai",
						"Cheranmadevi",
						"Cheruvanki",
						"Chetpet",
						"Chettiarpatti",
						"Chettipalaiyam",
						"Chettipalayam Cantonment",
						"Chettithangal",
						"Cheyur",
						"Cheyyar",
						"Chidambaram",
						"Chinalapatti",
						"Chinna Anuppanadi",
						"Chinna Salem",
						"Chinnakkampalayam",
						"Chinnammanur",
						"Chinnampalaiyam",
						"Chinnasekkadu",
						"Chinnavedampatti",
						"Chitlapakkam",
						"Chittodu",
						"Cholapuram",
						"Coimbatore",
						"Coonoor",
						"Courtalam",
						"Cuddalore",
						"Dalavaipatti",
						"Darasuram",
						"Denkanikottai",
						"Desur",
						"Devadanapatti",
						"Devakkottai",
						"Devakottai",
						"Devanangurichi",
						"Devarshola",
						"Devasthanam",
						"Dhalavoipuram",
						"Dhali",
						"Dhaliyur",
						"Dharapadavedu",
						"Dharapuram",
						"Dharmapuri",
						"Dindigul",
						"Dusi",
						"Edaganasalai",
						"Edaikodu",
						"Edakalinadu",
						"Elathur",
						"Elayirampannai",
						"Elumalai",
						"Eral",
						"Eraniel",
						"Eriodu",
						"Erode",
						"Erumaipatti",
						"Eruvadi",
						"Ethapur",
						"Ettaiyapuram",
						"Ettimadai",
						"Ezhudesam",
						"Ganapathipuram",
						"Gandhi Nagar",
						"Gangaikondan",
						"Gangavalli",
						"Ganguvarpatti",
						"Gingi",
						"Gopalasamudram",
						"Gopichettipalaiyam",
						"Gudalur",
						"Gudiyattam",
						"Guduvanchery",
						"Gummidipoondi",
						"Hanumanthampatti",
						"Harur",
						"Harveypatti",
						"Highways",
						"Hosur",
						"Hubbathala",
						"Huligal",
						"Idappadi",
						"Idikarai",
						"Ilampillai",
						"Ilanji",
						"Iluppaiyurani",
						"Iluppur",
						"Inam Karur",
						"Injambakkam",
						"Irugur",
						"Jaffrabad",
						"Jagathala",
						"Jalakandapuram",
						"Jalladiampet",
						"Jambai",
						"Jayankondam",
						"Jolarpet",
						"Kadambur",
						"Kadathur",
						"Kadayal",
						"Kadayampatti",
						"Kadayanallur",
						"Kadiapatti",
						"Kalakkad",
						"Kalambur",
						"Kalapatti",
						"Kalappanaickenpatti",
						"Kalavai",
						"Kalinjur",
						"Kaliyakkavilai",
						"Kallakkurichi",
						"Kallakudi",
						"Kallidaikurichchi",
						"Kallukuttam",
						"Kallupatti",
						"Kalpakkam",
						"Kalugumalai",
						"Kamayagoundanpatti",
						"Kambainallur",
						"Kambam",
						"Kamuthi",
						"Kanadukathan",
						"Kanakkampalayam",
						"Kanam",
						"Kanchipuram",
						"Kandanur",
						"Kangayam",
						"Kangayampalayam",
						"Kangeyanallur",
						"Kaniyur",
						"Kanjikoil",
						"Kannadendal",
						"Kannamangalam",
						"Kannampalayam",
						"Kannankurichi",
						"Kannapalaiyam",
						"Kannivadi",
						"Kanyakumari",
						"Kappiyarai",
						"Karaikkudi",
						"Karamadai",
						"Karambakkam",
						"Karambakkudi",
						"Kariamangalam",
						"Kariapatti",
						"Karugampattur",
						"Karumandi Chellipalayam",
						"Karumathampatti",
						"Karumbakkam",
						"Karungal",
						"Karunguzhi",
						"Karuppur",
						"Karur",
						"Kasipalaiyam",
						"Kasipalayam G",
						"Kathirvedu",
						"Kathujuganapalli",
						"Katpadi",
						"Kattivakkam",
						"Kattumannarkoil",
						"Kattupakkam",
						"Kattuputhur",
						"Kaveripakkam",
						"Kaveripattinam",
						"Kavundampalaiyam",
						"Kavundampalayam",
						"Kayalpattinam",
						"Kayattar",
						"Kelamangalam",
						"Kelambakkam",
						"Kembainaickenpalayam",
						"Kethi",
						"Kilakarai",
						"Kilampadi",
						"Kilkulam",
						"Kilkunda",
						"Killiyur",
						"Killlai",
						"Kilpennathur",
						"Kilvelur",
						"Kinathukadavu",
						"Kiramangalam",
						"Kiranur",
						"Kiripatti",
						"Kizhapavur",
						"Kmarasamipatti",
						"Kochadai",
						"Kodaikanal",
						"Kodambakkam",
						"Kodavasal",
						"Kodumudi",
						"Kolachal",
						"Kolappalur",
						"Kolathupalayam",
						"Kolathur",
						"Kollankodu",
						"Kollankoil",
						"Komaralingam",
						"Komarapalayam",
						"Kombai",
						"Konakkarai",
						"Konavattam",
						"Kondalampatti",
						"Konganapuram",
						"Koradacheri",
						"Korampallam",
						"Kotagiri",
						"Kothinallur",
						"Kottaiyur",
						"Kottakuppam",
						"Kottaram",
						"Kottivakkam",
						"Kottur",
						"Kovilpatti",
						"Koyampattur",
						"Krishnagiri",
						"Krishnarayapuram",
						"Krishnasamudram",
						"Kuchanur",
						"Kuhalur",
						"Kulasekarappattinam",
						"Kulasekarapuram",
						"Kulithalai",
						"Kumarapalaiyam",
						"Kumarapalayam",
						"Kumarapuram",
						"Kumbakonam",
						"Kundrathur",
						"Kuniyamuthur",
						"Kunnathur",
						"Kunur",
						"Kuraikundu",
						"Kurichi",
						"Kurinjippadi",
						"Kurudampalaiyam",
						"Kurumbalur",
						"Kuthalam",
						"Kuthappar",
						"Kuttalam",
						"Kuttanallur",
						"Kuzhithurai",
						"Labbaikudikadu",
						"Lakkampatti",
						"Lalgudi",
						"Lalpet",
						"Llayangudi",
						"Madambakkam",
						"Madanur",
						"Madathukulam",
						"Madhavaram",
						"Madippakkam",
						"Madukkarai",
						"Madukkur",
						"Madurai",
						"Maduranthakam",
						"Maduravoyal",
						"Mahabalipuram",
						"Makkinanpatti",
						"Mallamuppampatti",
						"Mallankinaru",
						"Mallapuram",
						"Mallasamudram",
						"Mallur",
						"Mamallapuram",
						"Mamsapuram",
						"Manachanallur",
						"Manali",
						"Manalmedu",
						"Manalurpet",
						"Manamadurai",
						"Manapakkam",
						"Manapparai",
						"Manavalakurichi",
						"Mandaikadu",
						"Mandapam",
						"Mangadu",
						"Mangalam",
						"Mangalampet",
						"Manimutharu",
						"Mannargudi",
						"Mappilaiurani",
						"Maraimalai Nagar",
						"Marakkanam",
						"Maramangalathupatti",
						"Marandahalli",
						"Markayankottai",
						"Marudur",
						"Marungur",
						"Masinigudi",
						"Mathigiri",
						"Mattur",
						"Mayiladuthurai",
						"Mecheri",
						"Melacheval",
						"Melachokkanathapuram",
						"Melagaram",
						"Melamadai",
						"Melamaiyur",
						"Melanattam",
						"Melathiruppanthuruthi",
						"Melattur",
						"Melmananbedu",
						"Melpattampakkam",
						"Melur",
						"Melvisharam",
						"Mettupalayam",
						"Mettur",
						"Meyyanur",
						"Milavittan",
						"Minakshipuram",
						"Minambakkam",
						"Minjur",
						"Modakurichi",
						"Mohanur",
						"Mopperipalayam",
						"Mudalur",
						"Mudichur",
						"Mudukulathur",
						"Mukasipidariyur",
						"Mukkudal",
						"Mulagumudu",
						"Mulakaraipatti",
						"Mulanur",
						"Mullakkadu",
						"Muruganpalayam",
						"Musiri",
						"Muthupet",
						"Muthur",
						"Muttayyapuram",
						"Muttupet",
						"Muvarasampettai",
						"Myladi",
						"Mylapore",
						"Nadukkuthagai",
						"Naduvattam",
						"Nagapattinam",
						"Nagavakulam",
						"Nagercoil",
						"Nagojanahalli",
						"Nallampatti",
						"Nallur",
						"Namagiripettai",
						"Namakkal",
						"Nambiyur",
						"Nambutalai",
						"Nandambakkam",
						"Nandivaram",
						"Nangavalli",
						"Nangavaram",
						"Nanguneri",
						"Nanjikottai",
						"Nannilam",
						"Naranammalpuram",
						"Naranapuram",
						"Narasimhanaickenpalayam",
						"Narasingapuram",
						"Narasojipatti",
						"Naravarikuppam",
						"Nasiyanur",
						"Natham",
						"Nathampannai",
						"Natrampalli",
						"Nattam",
						"Nattapettai",
						"Nattarasankottai",
						"Navalpattu",
						"Nazarethpettai",
						"Nazerath",
						"Neikkarapatti",
						"Neiyyur",
						"Nellikkuppam",
						"Nelliyalam",
						"Nemili",
						"Nemilicheri",
						"Neripperichal",
						"Nerkunram",
						"Nerkuppai",
						"Nerunjipettai",
						"Neykkarappatti",
						"Neyveli",
						"Nidamangalam",
						"Nilagiri",
						"Nilakkottai",
						"Nilankarai",
						"Odaipatti",
						"Odaiyakulam",
						"Oddanchatram",
						"Odugathur",
						"Oggiyamduraipakkam",
						"Olagadam",
						"Omalur",
						"Ooty",
						"Orathanadu",
						"Othakadai",
						"Othakalmandapam",
						"Ottapparai",
						"Pacode",
						"Padaividu",
						"Padianallur",
						"Padirikuppam",
						"Padmanabhapuram",
						"Padririvedu",
						"Palaganangudy",
						"Palaimpatti",
						"Palakkodu",
						"Palamedu",
						"Palani",
						"Palani Chettipatti",
						"Palavakkam",
						"Palavansathu",
						"Palayakayal",
						"Palayam",
						"Palayamkottai",
						"Palladam",
						"Pallapalayam",
						"Pallapatti",
						"Pallattur",
						"Pallavaram",
						"Pallikaranai",
						"Pallikonda",
						"Pallipalaiyam",
						"Pallipalaiyam Agraharam",
						"Pallipattu",
						"Pammal",
						"Panagudi",
						"Panaimarathupatti",
						"Panapakkam",
						"Panboli",
						"Pandamangalam",
						"Pannaikadu",
						"Pannaipuram",
						"Pannuratti",
						"Panruti",
						"Papanasam",
						"Pappankurichi",
						"Papparapatti",
						"Pappireddipatti",
						"Paramakkudi",
						"Paramankurichi",
						"Paramathi",
						"Parangippettai",
						"Paravai",
						"Pasur",
						"Pathamadai",
						"Pattinam",
						"Pattiviranpatti",
						"Pattukkottai",
						"Pazhugal",
						"Pennadam",
						"Pennagaram",
						"Pennathur",
						"Peraiyur",
						"Peralam",
						"Perambalur",
						"Peranamallur",
						"Peravurani",
						"Periyakodiveri",
						"Periyakulam",
						"Periyanayakkanpalaiyam",
						"Periyanegamam",
						"Periyapatti",
						"Periyasemur",
						"Pernambut",
						"Perumagalur",
						"Perumandi",
						"Perumuchi",
						"Perundurai",
						"Perungalathur",
						"Perungudi",
						"Perungulam",
						"Perur",
						"Perur Chettipalaiyam",
						"Pethampalayam",
						"Pethanaickenpalayam",
						"Pillanallur",
						"Pirkankaranai",
						"Polichalur",
						"Pollachi",
						"Polur",
						"Ponmani",
						"Ponnamaravathi",
						"Ponnampatti",
						"Ponneri",
						"Porur",
						"Pothanur",
						"Pothatturpettai",
						"Pudukadai",
						"Pudukkottai Cantonment",
						"Pudukottai",
						"Pudupalaiyam Aghraharam",
						"Pudupalayam",
						"Pudupatti",
						"Pudupattinam",
						"Pudur",
						"Puduvayal",
						"Pulambadi",
						"Pulampatti",
						"Puliyampatti",
						"Puliyankudi",
						"Puliyur",
						"Pullampadi",
						"Puluvapatti",
						"Punamalli",
						"Punjai Puliyampatti",
						"Punjai Thottakurichi",
						"Punjaipugalur",
						"Puthalam",
						"Putteri",
						"Puvalur",
						"Puzhal",
						"Puzhithivakkam",
						"Rajapalayam",
						"Ramanathapuram",
						"Ramapuram",
						"Rameswaram",
						"Ranipet",
						"Rasipuram",
						"Rayagiri",
						"Rithapuram",
						"Rosalpatti",
						"Rudravathi",
						"Sadayankuppam",
						"Saint Thomas Mount",
						"Salangapalayam",
						"Salem",
						"Samalapuram",
						"Samathur",
						"Sambavar Vadagarai",
						"Sankaramanallur",
						"Sankarankoil",
						"Sankarapuram",
						"Sankari",
						"Sankarnagar",
						"Saravanampatti",
						"Sarcarsamakulam",
						"Sathiyavijayanagaram",
						"Sathuvachari",
						"Sathyamangalam",
						"Sattankulam",
						"Sattur",
						"Sayalgudi",
						"Sayapuram",
						"Seithur",
						"Sembakkam",
						"Semmipalayam",
						"Sennirkuppam",
						"Senthamangalam",
						"Sentharapatti",
						"Senur",
						"Sethiathoppu",
						"Sevilimedu",
						"Sevugampatti",
						"Shenbakkam",
						"Shencottai",
						"Shenkottai",
						"Sholavandan",
						"Sholinganallur",
						"Sholingur",
						"Sholur",
						"Sikkarayapuram",
						"Singampuneri",
						"Singanallur",
						"Singaperumalkoil",
						"Sirapalli",
						"Sirkali",
						"Sirugamani",
						"Sirumugai",
						"Sithayankottai",
						"Sithurajapuram",
						"Sivaganga",
						"Sivagiri",
						"Sivakasi",
						"Sivanthipuram",
						"Sivur",
						"Soranjeri",
						"South Kannanur",
						"South Kodikulam",
						"Srimushnam",
						"Sriperumpudur",
						"Sriramapuram",
						"Srirangam",
						"Srivaikuntam",
						"Srivilliputtur",
						"Suchindram",
						"Suliswaranpatti",
						"Sulur",
						"Sundarapandiam",
						"Sundarapandiapuram",
						"Surampatti",
						"Surandai",
						"Suriyampalayam",
						"Swamimalai",
						"TNPL Pugalur",
						"Tambaram",
						"Taramangalam",
						"Tattayyangarpettai",
						"Tayilupatti",
						"Tenkasi",
						"Thadikombu",
						"Thakkolam",
						"Thalainayar",
						"Thalakudi",
						"Thamaraikulam",
						"Thammampatti",
						"Thanjavur",
						"Thanthoni",
						"Tharangambadi",
						"Thedavur",
						"Thenambakkam",
						"Thengampudur",
						"Theni",
						"Theni Allinagaram",
						"Thenkarai",
						"Thenthamaraikulam",
						"Thenthiruperai",
						"Thesur",
						"Thevaram",
						"Thevur",
						"Thiagadurgam",
						"Thiagarajar Colony",
						"Thingalnagar",
						"Thiruchirapalli",
						"Thirukarungudi",
						"Thirukazhukundram",
						"Thirumalayampalayam",
						"Thirumazhisai",
						"Thirunagar",
						"Thirunageswaram",
						"Thirunindravur",
						"Thirunirmalai",
						"Thiruparankundram",
						"Thiruparappu",
						"Thiruporur",
						"Thiruppanandal",
						"Thirupuvanam",
						"Thiruthangal",
						"Thiruthuraipundi",
						"Thiruvaivaru",
						"Thiruvalam",
						"Thiruvarur",
						"Thiruvattaru",
						"Thiruvenkatam",
						"Thiruvennainallur",
						"Thiruvithankodu",
						"Thisayanvilai",
						"Thittacheri",
						"Thondamuthur",
						"Thorapadi",
						"Thottipalayam",
						"Thottiyam",
						"Thudiyalur",
						"Thuthipattu",
						"Thuvakudi",
						"Timiri",
						"Tindivanam",
						"Tinnanur",
						"Tiruchchendur",
						"Tiruchengode",
						"Tirukkalukkundram",
						"Tirukkattuppalli",
						"Tirukkoyilur",
						"Tirumangalam",
						"Tirumullaivasal",
						"Tirumuruganpundi",
						"Tirunageswaram",
						"Tirunelveli",
						"Tirupathur",
						"Tirupattur",
						"Tiruppuvanam",
						"Tirupur",
						"Tirusulam",
						"Tiruttani",
						"Tiruvallur",
						"Tiruvannamalai",
						"Tiruverambur",
						"Tiruverkadu",
						"Tiruvethipuram",
						"Tiruvidaimarudur",
						"Tiruvottiyur",
						"Tittakudi",
						"Tondi",
						"Turaiyur",
						"Tuticorin",
						"Udagamandalam",
						"Udagamandalam Valley",
						"Udankudi",
						"Udayarpalayam",
						"Udumalaipettai",
						"Udumalpet",
						"Ullur",
						"Ulundurpettai",
						"Unjalaur",
						"Unnamalaikadai",
						"Uppidamangalam",
						"Uppiliapuram",
						"Urachikkottai",
						"Urapakkam",
						"Usilampatti",
						"Uthangarai",
						"Uthayendram",
						"Uthiramerur",
						"Uthukkottai",
						"Uttamapalaiyam",
						"Uttukkuli",
						"Vadakarai Kizhpadugai",
						"Vadakkanandal",
						"Vadakku Valliyur",
						"Vadalur",
						"Vadamadurai",
						"Vadavalli",
						"Vadipatti",
						"Vadugapatti",
						"Vaithiswarankoil",
						"Valangaiman",
						"Valasaravakkam",
						"Valavanur",
						"Vallam",
						"Valparai",
						"Valvaithankoshtam",
						"Vanavasi",
						"Vandalur",
						"Vandavasi",
						"Vandiyur",
						"Vaniputhur",
						"Vaniyambadi",
						"Varadarajanpettai",
						"Varadharajapuram",
						"Vasudevanallur",
						"Vathirairuppu",
						"Vattalkundu",
						"Vazhapadi",
						"Vedapatti",
						"Vedaranniyam",
						"Vedasandur",
						"Velampalaiyam",
						"Velankanni",
						"Vellakinar",
						"Vellakoil",
						"Vellalapatti",
						"Vellalur",
						"Vellanur",
						"Vellimalai",
						"Vellore",
						"Vellottamparappu",
						"Velluru",
						"Vengampudur",
						"Vengathur",
						"Vengavasal",
						"Venghatur",
						"Venkarai",
						"Vennanthur",
						"Veppathur",
						"Verkilambi",
						"Vettaikaranpudur",
						"Vettavalam",
						"Vijayapuri",
						"Vikramasingapuram",
						"Vikravandi",
						"Vilangudi",
						"Vilankurichi",
						"Vilapakkam",
						"Vilathikulam",
						"Vilavur",
						"Villukuri",
						"Villupuram",
						"Viraganur",
						"Virakeralam",
						"Virakkalpudur",
						"Virapandi",
						"Virapandi Cantonment",
						"Virappanchatram",
						"Viravanallur",
						"Virudambattu",
						"Virudhachalam",
						"Virudhunagar",
						"Virupakshipuram",
						"Viswanatham",
						"Vriddhachalam",
						"Walajabad",
						"Walajapet",
						"Wellington",
						"Yercaud",
						"Zamin Uthukuli",
					],
					stateName: "Tamil Nadu",
				},
				{
					cities: [
						"Achampet",
						"Adilabad",
						"Armoor",
						"Asifabad",
						"Badepally",
						"Banswada",
						"Bellampalli",
						"Bhadrachalam",
						"Bhainsa",
						"Bhongir",
						"Bhupalpally",
						"Bodhan",
						"Bollaram",
						"Devarkonda",
						"Farooqnagar",
						"Gadwal",
						"Gajwel",
						"Ghatkesar",
						"Hyderabad",
						"Jagtial",
						"Jangaon",
						"Kagaznagar",
						"Kalwakurthy",
						"Kamareddy",
						"Karimnagar",
						"Khammam",
						"Kodada",
						"Koratla",
						"Kottagudem",
						"Kyathampalle",
						"Madhira",
						"Mahabubabad",
						"Mahbubnagar",
						"Mancherial",
						"Mandamarri",
						"Manuguru",
						"Medak",
						"Medchal",
						"Miryalaguda",
						"Nagar Karnul",
						"Nakrekal",
						"Nalgonda",
						"Narayanpet",
						"Narsampet",
						"Nirmal",
						"Nizamabad",
						"Palwancha",
						"Peddapalli",
						"Ramagundam",
						"Ranga Reddy district",
						"Sadasivpet",
						"Sangareddy",
						"Sarapaka",
						"Sathupalle",
						"Secunderabad",
						"Siddipet",
						"Singapur",
						"Sircilla",
						"Suryapet",
						"Tandur",
						"Vemulawada",
						"Vikarabad",
						"Wanaparthy",
						"Warangal",
						"Yellandu",
						"Zahirabad",
					],
					stateName: "Telangana",
				},
				{
					cities: [
						"Agartala",
						"Amarpur",
						"Ambassa",
						"Badharghat",
						"Belonia",
						"Dharmanagar",
						"Gakulnagar",
						"Gandhigram",
						"Indranagar",
						"Jogendranagar",
						"Kailasahar",
						"Kamalpur",
						"Kanchanpur",
						"Khowai",
						"Kumarghat",
						"Kunjaban",
						"Narsingarh",
						"Pratapgarh",
						"Ranir Bazar",
						"Sabrum",
						"Sonamura",
						"Teliamura",
						"Udaipur",
					],
					stateName: "Tripura",
				},
				{
					cities: [
						"Achhalda",
						"Achhnera",
						"Adari",
						"Afzalgarh",
						"Agarwal Mandi",
						"Agra",
						"Agra Cantonment",
						"Ahraura",
						"Ailum",
						"Air Force Area",
						"Ajhuwa",
						"Akbarpur",
						"Alapur",
						"Aliganj",
						"Aligarh",
						"Allahabad",
						"Allahabad Cantonment",
						"Allahganj",
						"Amanpur",
						"Ambahta",
						"Amethi",
						"Amila",
						"Amilo",
						"Aminagar Sarai",
						"Aminagar Urf Bhurbaral",
						"Amraudha",
						"Amroha",
						"Anandnagar",
						"Anpara",
						"Antu",
						"Anupshahr",
						"Aonla",
						"Armapur Estate",
						"Ashokpuram",
						"Ashrafpur Kichhauchha",
						"Atarra",
						"Atasu",
						"Atrauli",
						"Atraulia",
						"Auraiya",
						"Aurangabad",
						"Aurangabad Bangar",
						"Auras",
						"Awagarh",
						"Ayodhya",
						"Azamgarh",
						"Azizpur",
						"Azmatgarh",
						"Babarpur Ajitmal",
						"Baberu",
						"Babina",
						"Babrala",
						"Babugarh",
						"Bachhiowan",
						"Bachhraon",
						"Bad",
						"Badaun",
						"Baghpat",
						"Bah",
						"Bahadurganj",
						"Baheri",
						"Bahjoi",
						"Bahraich",
						"Bahsuma",
						"Bahua",
						"Bajna",
						"Bakewar",
						"Bakiabad",
						"Baldeo",
						"Ballia",
						"Balrampur",
						"Banat",
						"Banda",
						"Bangarmau",
						"Banki",
						"Bansdih",
						"Bansgaon",
						"Bansi",
						"Barabanki",
						"Baragaon",
						"Baraut",
						"Bareilly",
						"Bareilly Cantonment",
						"Barhalganj",
						"Barhani",
						"Barhapur",
						"Barkhera",
						"Barsana",
						"Barva Sagar",
						"Barwar",
						"Basti",
						"Begumabad Budhana",
						"Behat",
						"Behta Hajipur",
						"Bela",
						"Belthara",
						"Beniganj",
						"Beswan",
						"Bewar",
						"Bhadarsa",
						"Bhadohi",
						"Bhagwantnagar",
						"Bharatganj",
						"Bhargain",
						"Bharthana",
						"Bharuhana",
						"Bharwari",
						"Bhatni Bazar",
						"Bhatpar Rani",
						"Bhawan Bahadurnagar",
						"Bhinga",
						"Bhojpur Dharampur",
						"Bhokarhedi",
						"Bhongaon",
						"Bhulepur",
						"Bidhuna",
						"Bighapur",
						"Bijnor",
						"Bijpur",
						"Bikapur",
						"Bilari",
						"Bilaspur",
						"Bilgram",
						"Bilhaur",
						"Bilram",
						"Bilrayaganj",
						"Bilsanda",
						"Bilsi",
						"Bindki",
						"Bisalpur",
						"Bisanda Buzurg",
						"Bisauli",
						"Bisharatganj",
						"Bisokhar",
						"Biswan",
						"Bithur",
						"Budaun",
						"Bugrasi",
						"Bulandshahar",
						"Burhana",
						"Chail",
						"Chak Imam Ali",
						"Chakeri",
						"Chakia",
						"Chandauli",
						"Chandausi",
						"Chandpur",
						"Charkhari",
						"Charthawal",
						"Chaumuhan",
						"Chhaprauli",
						"Chhara Rafatpur",
						"Chharprauli",
						"Chhata",
						"Chhatari",
						"Chhibramau",
						"Chhutmalpur",
						"Chilkana Sultanpur",
						"Chirgaon",
						"Chit Baragaon",
						"Chitrakut Dham",
						"Chopan",
						"Choubepur Kalan",
						"Chunar",
						"Churk Ghurma",
						"Colonelganj",
						"Dadri",
						"Dalmau",
						"Dankaur",
						"Dariyabad",
						"Dasna",
						"Dataganj",
						"Daurala",
						"Dayal Bagh",
						"Deoband",
						"Deoranian",
						"Deoria",
						"Dewa",
						"Dhampur",
						"Dhanauha",
						"Dhanauli",
						"Dhanaura",
						"Dharoti Khurd",
						"Dhauratanda",
						"Dhaurhra",
						"Dibai",
						"Dibiyapur",
						"Dildarnagar Fatehpur",
						"Do Ghat",
						"Dohrighat",
						"Dostpur",
						"Dudhinagar",
						"Dulhipur",
						"Dundwaraganj",
						"Ekdil",
						"Erich",
						"Etah",
						"Etawah",
						"Faizabad",
						"Faizabad Cantonment",
						"Faizganj",
						"Farah",
						"Faridnagar",
						"Faridpur",
						"Faridpur Cantonment",
						"Fariha",
						"Farrukhabad",
						"Fatehabad",
						"Fatehganj Pashchimi",
						"Fatehganj Purvi",
						"Fatehgarh",
						"Fatehpur",
						"Fatehpur Chaurasi",
						"Fatehpur Sikri",
						"Firozabad",
						"Gajraula",
						"Ganga Ghat",
						"Gangapur",
						"Gangoh",
						"Ganj Muradabad",
						"Garautha",
						"Garhi Pukhta",
						"Garhmukteshwar",
						"Gaura Barahaj",
						"Gauri Bazar",
						"Gausganj",
						"Gawan",
						"Ghatampur",
						"Ghaziabad",
						"Ghazipur",
						"Ghiror",
						"Ghorawal",
						"Ghosi",
						"Ghosia Bazar",
						"Ghughuli",
						"Gohand",
						"Gokul",
						"Gola Bazar",
						"Gola Gokarannath",
						"Gonda",
						"Gopamau",
						"Gopiganj",
						"Gorakhpur",
						"Gosainganj",
						"Govardhan",
						"Greater Noida",
						"Gulaothi",
						"Gulariya",
						"Gulariya Bhindara",
						"Gunnaur",
						"Gursahaiganj",
						"Gursarai",
						"Gyanpur",
						"Hafizpur",
						"Haidergarh",
						"Haldaur",
						"Hamirpur",
						"Handia",
						"Hapur",
						"Hardoi",
						"Harduaganj",
						"Hargaon",
						"Hariharpur",
						"Harraiya",
						"Hasanpur",
						"Hasayan",
						"Hastinapur",
						"Hata",
						"Hathras",
						"Hyderabad",
						"Ibrahimpur",
						"Iglas",
						"Ikauna",
						"Iltifatganj Bazar",
						"Indian Telephone Industry Mank",
						"Islamnagar",
						"Itaunja",
						"Itimadpur",
						"Jagner",
						"Jahanabad",
						"Jahangirabad",
						"Jahangirpur",
						"Jais",
						"Jaithara",
						"Jalalabad",
						"Jalali",
						"Jalalpur",
						"Jalaun",
						"Jalesar",
						"Jamshila",
						"Jangipur",
						"Jansath",
						"Jarwal",
						"Jasrana",
						"Jaswantnagar",
						"Jatari",
						"Jaunpur",
						"Jewar",
						"Jhalu",
						"Jhansi",
						"Jhansi Cantonment",
						"Jhansi Railway Settlement",
						"Jhinjhak",
						"Jhinjhana",
						"Jhusi",
						"Jhusi Kohna",
						"Jiyanpur",
						"Joya",
						"Jyoti Khuria",
						"Jyotiba Phule Nagar",
						"Kabrai",
						"Kachhauna Patseni",
						"Kachhla",
						"Kachhwa",
						"Kadaura",
						"Kadipur",
						"Kailashpur",
						"Kaimganj",
						"Kairana",
						"Kakgaina",
						"Kakod",
						"Kakori",
						"Kakrala",
						"Kalinagar",
						"Kalpi",
						"Kamalganj",
						"Kampil",
						"Kandhla",
						"Kandwa",
						"Kannauj",
						"Kanpur",
						"Kant",
						"Kanth",
						"Kaptanganj",
						"Karaon",
						"Karari",
						"Karhal",
						"Karnawal",
						"Kasganj",
						"Katariya",
						"Katghar Lalganj",
						"Kathera",
						"Katra",
						"Katra Medniganj",
						"Kauriaganj",
						"Kemri",
						"Kerakat",
						"Khadda",
						"Khaga",
						"Khailar",
						"Khair",
						"Khairabad",
						"Khairagarh",
						"Khalilabad",
						"Khamaria",
						"Khanpur",
						"Kharela",
						"Khargupur",
						"Khariya",
						"Kharkhoda",
						"Khatauli",
						"Khatauli Rural",
						"Khekra",
						"Kheri",
						"Kheta Sarai",
						"Khudaganj",
						"Khurja",
						"Khutar",
						"Kiraoli",
						"Kiratpur",
						"Kishanpur",
						"Kishni",
						"Kithaur",
						"Koiripur",
						"Konch",
						"Kopaganj",
						"Kora Jahanabad",
						"Korwa",
						"Kosi Kalan",
						"Kota",
						"Kotra",
						"Kotwa",
						"Kulpahar",
						"Kunda",
						"Kundarki",
						"Kunwargaon",
						"Kurara",
						"Kurawali",
						"Kursath",
						"Kurthi Jafarpur",
						"Kushinagar",
						"Kusmara",
						"Laharpur",
						"Lakhimpur",
						"Lakhna",
						"Lalganj",
						"Lalitpur",
						"Lar",
						"Lawar",
						"Ledwa Mahuwa",
						"Lohta",
						"Loni",
						"Lucknow",
						"Machhlishahr",
						"Madhoganj",
						"Madhogarh",
						"Maghar",
						"Mahaban",
						"Maharajganj",
						"Mahmudabad",
						"Mahoba",
						"Maholi",
						"Mahona",
						"Mahroni",
						"Mailani",
						"Mainpuri",
						"Majhara Pipar Ehatmali",
						"Majhauli Raj",
						"Malihabad",
						"Mallanwam",
						"Mandawar",
						"Manikpur",
						"Maniyar",
						"Manjhanpur",
						"Mankapur",
						"Marehra",
						"Mariahu",
						"Maruadih",
						"Maswasi",
						"Mataundh",
						"Mathu",
						"Mathura",
						"Mathura Cantonment",
						"Mau",
						"Mau Aima",
						"Maudaha",
						"Mauranipur",
						"Maurawan",
						"Mawana",
						"Meerut",
						"Mehnagar",
						"Mehndawal",
						"Mendu",
						"Milak",
						"Miranpur",
						"Mirat",
						"Mirat Cantonment",
						"Mirganj",
						"Mirzapur",
						"Misrikh",
						"Modinagar",
						"Mogra Badshahpur",
						"Mohan",
						"Mohanpur",
						"Mohiuddinpur",
						"Moradabad",
						"Moth",
						"Mubarakpur",
						"Mughal Sarai",
						"Mughal Sarai Railway Settlemen",
						"Muhammadabad",
						"Muhammadi",
						"Mukrampur Khema",
						"Mundia",
						"Mundora",
						"Muradnagar",
						"Mursan",
						"Musafirkhana",
						"Muzaffarnagar",
						"Nadigaon",
						"Nagina",
						"Nagram",
						"Nai Bazar",
						"Nainana Jat",
						"Najibabad",
						"Nakur",
						"Nanaunta",
						"Nandgaon",
						"Nanpara",
						"Naraini",
						"Narauli",
						"Naraura",
						"Naugawan Sadat",
						"Nautanwa",
						"Nawabganj",
						"Nichlaul",
						"Nidhauli Kalan",
						"Nihtaur",
						"Nindaura",
						"Niwari",
						"Nizamabad",
						"Noida",
						"Northern Railway Colony",
						"Nurpur",
						"Nyoria Husenpur",
						"Nyotini",
						"Obra",
						"Oel Dhakwa",
						"Orai",
						"Oran",
						"Ordinance Factory Muradnagar",
						"Pachperwa",
						"Padrauna",
						"Pahasu",
						"Paintepur",
						"Pali",
						"Palia Kalan",
						"Parasi",
						"Parichha",
						"Parichhatgarh",
						"Parsadepur",
						"Patala",
						"Patiyali",
						"Patti",
						"Pawayan",
						"Phalauda",
						"Phaphund",
						"Phulpur",
						"Phulwaria",
						"Pihani",
						"Pilibhit",
						"Pilkana",
						"Pilkhuwa",
						"Pinahat",
						"Pipalsana Chaudhari",
						"Pipiganj",
						"Pipraich",
						"Pipri",
						"Pratapgarh",
						"Pukhrayan",
						"Puranpur",
						"Purdil Nagar",
						"Purqazi",
						"Purwa",
						"Qasimpur",
						"Rabupura",
						"Radha Kund",
						"Rae Bareilly",
						"Raja Ka Rampur",
						"Rajapur",
						"Ramkola",
						"Ramnagar",
						"Rampur",
						"Rampur Bhawanipur",
						"Rampur Karkhana",
						"Rampur Maniharan",
						"Rampura",
						"Ranipur",
						"Rashidpur Garhi",
						"Rasra",
						"Rasulabad",
						"Rath",
						"Raya",
						"Renukut",
						"Reoti",
						"Richha",
						"Risia Bazar",
						"Rithora",
						"Robertsganj",
						"Roza",
						"Rudarpur",
						"Rudauli",
						"Rudayan",
						"Rura",
						"Rustamnagar Sahaspur",
						"Sabatwar",
						"Sadabad",
						"Sadat",
						"Safipur",
						"Sahanpur",
						"Saharanpur",
						"Sahaspur",
						"Sahaswan",
						"Sahawar",
						"Sahibabad",
						"Sahjanwa",
						"Sahpau",
						"Saidpur",
						"Sainthal",
						"Saiyadraja",
						"Sakhanu",
						"Sakit",
						"Salarpur Khadar",
						"Salimpur",
						"Salon",
						"Sambhal",
						"Sambhawali",
						"Samdhan",
						"Samthar",
						"Sandi",
						"Sandila",
						"Sarai Mir",
						"Sarai akil",
						"Sarauli",
						"Sardhana",
						"Sarila",
						"Sarsawan",
						"Sasni",
						"Satrikh",
						"Saunkh",
						"Saurikh",
						"Seohara",
						"Sewal Khas",
						"Sewarhi",
						"Shahabad",
						"Shahganj",
						"Shahi",
						"Shahjahanpur",
						"Shahjahanpur Cantonment",
						"Shahpur",
						"Shamli",
						"Shamsabad",
						"Shankargarh",
						"Shergarh",
						"Sherkot",
						"Shikarpur",
						"Shikohabad",
						"Shisgarh",
						"Shivdaspur",
						"Shivli",
						"Shivrajpur",
						"Shohratgarh",
						"Siddhanur",
						"Siddharthnagar",
						"Sidhauli",
						"Sidhpura",
						"Sikandarabad",
						"Sikandarpur",
						"Sikandra",
						"Sikandra Rao",
						"Singahi Bhiraura",
						"Sirathu",
						"Sirsa",
						"Sirsaganj",
						"Sirsi",
						"Sisauli",
						"Siswa Bazar",
						"Sitapur",
						"Siyana",
						"Som",
						"Sonbhadra",
						"Soron",
						"Suar",
						"Sukhmalpur Nizamabad",
						"Sultanpur",
						"Sumerpur",
						"Suriyawan",
						"Swamibagh",
						"Tajpur",
						"Talbahat",
						"Talgram",
						"Tambaur",
						"Tanda",
						"Tatarpur Lallu",
						"Tetribazar",
						"Thakurdwara",
						"Thana Bhawan",
						"Thiriya Nizamat Khan",
						"Tikaitnagar",
						"Tikri",
						"Tilhar",
						"Tindwari",
						"Tirwaganj",
						"Titron",
						"Tori Fatehpur",
						"Tulsipur",
						"Tundla",
						"Tundla Kham",
						"Tundla Railway Colony",
						"Ugu",
						"Ujhani",
						"Ujhari",
						"Umri",
						"Umri Kalan",
						"Un",
						"Unchahar",
						"Unnao",
						"Usaihat",
						"Usawan",
						"Utraula",
						"Varanasi",
						"Varanasi Cantonment",
						"Vijaigarh",
						"Vrindavan",
						"Wazirganj",
						"Zafarabad",
						"Zaidpur",
						"Zamania",
					],
					stateName: "Uttar Pradesh",
				},
				{
					cities: [
						"Almora",
						"Almora Cantonment",
						"Badrinathpuri",
						"Bageshwar",
						"Bah Bazar",
						"Banbasa",
						"Bandia",
						"Barkot",
						"Bazpur",
						"Bhim Tal",
						"Bhowali",
						"Chakrata",
						"Chamba",
						"Chamoli and Gopeshwar",
						"Champawat",
						"Clement Town",
						"Dehra Dun Cantonment",
						"Dehradun",
						"Dehrakhas",
						"Devaprayag",
						"Dhaluwala",
						"Dhandera",
						"Dharchula",
						"Dharchula Dehat",
						"Didihat",
						"Dineshpur",
						"Doiwala",
						"Dugadda",
						"Dwarahat",
						"Gadarpur",
						"Gangotri",
						"Gauchar",
						"Haldwani",
						"Haridwar",
						"Herbertpur",
						"Jaspur",
						"Jhabrera",
						"Joshimath",
						"Kachnal Gosain",
						"Kaladungi",
						"Kalagarh",
						"Karnaprayang",
						"Kashipur",
						"Kashirampur",
						"Kausani",
						"Kedarnath",
						"Kelakhera",
						"Khatima",
						"Kichha",
						"Kirtinagar",
						"Kotdwara",
						"Laksar",
						"Lalkuan",
						"Landaura",
						"Landhaura Cantonment",
						"Lensdaun",
						"Logahat",
						"Mahua Dabra Haripura",
						"Mahua Kheraganj",
						"Manglaur",
						"Masuri",
						"Mohanpur Mohammadpur",
						"Muni Ki Reti",
						"Nagla",
						"Nainital",
						"Nainital Cantonment",
						"Nandaprayang",
						"Narendranagar",
						"Pauri",
						"Pithoragarh",
						"Pratitnagar",
						"Raipur",
						"Raiwala",
						"Ramnagar",
						"Ranikhet",
						"Ranipur",
						"Rishikesh",
						"Rishikesh Cantonment",
						"Roorkee",
						"Rudraprayag",
						"Rudrapur",
						"Rurki",
						"Rurki Cantonment",
						"Shaktigarh",
						"Sitarganj",
						"Srinagar",
						"Sultanpur",
						"Tanakpur",
						"Tehri",
						"Udham Singh Nagar",
						"Uttarkashi",
						"Vikasnagar",
						"Virbhadra",
					],
					stateName: "Uttarakhand",
				},
				{ cities: [], stateName: "Vaishali" },
				{
					cities: [
						"24 Parganas (n)",
						"24 Parganas (s)",
						"Adra",
						"Ahmadpur",
						"Aiho",
						"Aistala",
						"Alipur Duar",
						"Alipur Duar Railway Junction",
						"Alpur",
						"Amalhara",
						"Amkula",
						"Amlagora",
						"Amodghata",
						"Amtala",
						"Andul",
						"Anksa",
						"Ankurhati",
						"Anup Nagar",
						"Arambagh",
						"Argari",
						"Arsha",
						"Asansol",
						"Ashoknagar Kalyangarh",
						"Aurangabad",
						"Bablari Dewanganj",
						"Badhagachhi",
						"Baduria",
						"Baghdogra",
						"Bagnan",
						"Bagra",
						"Bagula",
						"Baharampur",
						"Bahirgram",
						"Bahula",
						"Baidyabati",
						"Bairatisal",
						"Baj Baj",
						"Bakreswar",
						"Balaram Pota",
						"Balarampur",
						"Bali Chak",
						"Ballavpur",
						"Bally",
						"Balurghat",
						"Bamunari",
						"Banarhat Tea Garden",
						"Bandel",
						"Bangaon",
						"Bankra",
						"Bankura",
						"Bansbaria",
						"Banshra",
						"Banupur",
						"Bara Bamonia",
						"Barakpur",
						"Barakpur Cantonment",
						"Baranagar",
						"Barasat",
						"Barddhaman",
						"Barijhati",
						"Barjora",
						"Barrackpore",
						"Baruihuda",
						"Baruipur",
						"Barunda",
						"Basirhat",
						"Baska",
						"Begampur",
						"Beldanga",
						"Beldubi",
						"Belebathan",
						"Beliator",
						"Bhadreswar",
						"Bhandardaha",
						"Bhangar Raghunathpur",
						"Bhangri Pratham Khanda",
						"Bhanowara",
						"Bhatpara",
						"Bholar Dabri",
						"Bidhannagar",
						"Bidyadharpur",
						"Biki Hakola",
						"Bilandapur",
						"Bilpahari",
						"Bipra Noapara",
						"Birlapur",
						"Birnagar",
						"Bisarpara",
						"Bishnupur",
						"Bolpur",
						"Bongaon",
						"Bowali",
						"Burdwan",
						"Canning",
						"Cart Road",
						"Chachanda",
						"Chak Bankola",
						"Chak Enayetnagar",
						"Chak Kashipur",
						"Chakalampur",
						"Chakbansberia",
						"Chakdaha",
						"Chakpara",
						"Champahati",
						"Champdani",
						"Chamrail",
						"Chandannagar",
						"Chandpur",
						"Chandrakona",
						"Chapari",
						"Chapui",
						"Char Brahmanagar",
						"Char Maijdia",
						"Charka",
						"Chata Kalikapur",
						"Chauhati",
						"Checha Khata",
						"Chelad",
						"Chhora",
						"Chikrand",
						"Chittaranjan",
						"Contai",
						"Cooch Behar",
						"Dainhat",
						"Dakshin Baguan",
						"Dakshin Jhapardaha",
						"Dakshin Rajyadharpur",
						"Dakshin Raypur",
						"Dalkola",
						"Dalurband",
						"Darap Pur",
						"Darjiling",
						"Daulatpur",
						"Debipur",
						"Defahat",
						"Deora",
						"Deulia",
						"Dhakuria",
						"Dhandadihi",
						"Dhanyakuria",
						"Dharmapur",
						"Dhatri Gram",
						"Dhuilya",
						"Dhulagari",
						"Dhulian",
						"Dhupgari",
						"Dhusaripara",
						"Diamond Harbour",
						"Digha",
						"Dignala",
						"Dinhata",
						"Dubrajpur",
						"Dumjor",
						"Durgapur",
						"Durllabhganj",
						"Egra",
						"Eksara",
						"Falakata",
						"Farakka",
						"Fatellapur",
						"Fort Gloster",
						"Gabberia",
						"Gadigachha",
						"Gairkata",
						"Gangarampur",
						"Garalgachha",
						"Garbeta Amlagora",
						"Garhbeta",
						"Garshyamnagar",
						"Garui",
						"Garulia",
						"Gayespur",
						"Ghatal",
						"Ghorsala",
						"Goaljan",
						"Goasafat",
						"Gobardanga",
						"Gobindapur",
						"Gopalpur",
						"Gopinathpur",
						"Gora Bazar",
						"Guma",
						"Gurdaha",
						"Guriahati",
						"Guskhara",
						"Habra",
						"Haldia",
						"Haldibari",
						"Halisahar",
						"Haora",
						"Harharia Chak",
						"Harindanga",
						"Haringhata",
						"Haripur",
						"Harishpur",
						"Hatgachha",
						"Hatsimla",
						"Hijuli",
						"Hindustan Cables Town",
						"Hooghly",
						"Howrah",
						"Hugli-Chunchura",
						"Humaipur",
						"Ichha Pur Defence Estate",
						"Ingraj Bazar",
						"Islampur",
						"Jafarpur",
						"Jagadanandapur",
						"Jagdishpur",
						"Jagtaj",
						"Jala Kendua",
						"Jaldhaka",
						"Jalkhura",
						"Jalpaiguri",
						"Jamuria",
						"Jangipur",
						"Jaygaon",
						"Jaynagar-Majilpur",
						"Jemari",
						"Jemari Township",
						"Jetia",
						"Jhalida",
						"Jhargram",
						"Jhorhat",
						"Jiaganj-Azimganj",
						"Joka",
						"Jot Kamal",
						"Kachu Pukur",
						"Kajora",
						"Kakdihi",
						"Kakdwip",
						"Kalaikunda",
						"Kalara",
						"Kalimpong",
						"Kaliyaganj",
						"Kalna",
						"Kalyani",
						"Kamarhati",
						"Kanaipur",
						"Kanchrapara",
						"Kandi",
						"Kanki",
						"Kankuria",
						"Kantlia",
						"Kanyanagar",
						"Karimpur",
						"Karsiyang",
						"Kasba",
						"Kasimbazar",
						"Katwa",
						"Kaugachhi",
						"Kenda",
						"Kendra Khottamdi",
						"Kendua",
						"Kesabpur",
						"Khagrabari",
						"Khalia",
						"Khalor",
						"Khandra",
						"Khantora",
						"Kharagpur",
						"Kharagpur Railway Settlement",
						"Kharar",
						"Khardaha",
						"Khari Mala Khagrabari",
						"Kharsarai",
						"Khatra",
						"Khodarampur",
						"Kodalia",
						"Kolaghat",
						"Kolaghat Thermal Power Project",
						"Kolkata",
						"Konardihi",
						"Konnogar",
						"Krishnanagar",
						"Krishnapur",
						"Kshidirpur",
						"Kshirpai",
						"Kulihanda",
						"Kulti",
						"Kunustara",
						"Kuperskem",
						"Madanpur",
						"Madhusudanpur",
						"Madhyamgram",
						"Maheshtala",
						"Mahiari",
						"Mahikpur",
						"Mahira",
						"Mahishadal",
						"Mainaguri",
						"Makardaha",
						"Mal",
						"Malda",
						"Mandarbani",
						"Mansinhapur",
						"Masila",
						"Maslandapur",
						"Mathabhanga",
						"Mekliganj",
						"Memari",
						"Midnapur",
						"Mirik",
						"Monoharpur",
						"Mrigala",
						"Muragachha",
						"Murgathaul",
						"Murshidabad",
						"Nabadhai Dutta Pukur",
						"Nabagram",
						"Nabgram",
						"Nachhratpur Katabari",
						"Nadia",
						"Naihati",
						"Nalhati",
						"Nasra",
						"Natibpur",
						"Naupala",
						"Navadwip",
						"Nebadhai Duttapukur",
						"New Barrackpore",
						"Ni Barakpur",
						"Nibra",
						"Noapara",
						"Nokpul",
						"North Barakpur",
						"Odlabari",
						"Old Maldah",
						"Ondal",
						"Pairagachha",
						"Palashban",
						"Panchla",
						"Panchpara",
						"Pandua",
						"Pangachhiya",
						"Paniara",
						"Panihati",
						"Panuhat",
						"Par Beliya",
						"Parashkol",
						"Parasia",
						"Parbbatipur",
						"Parui",
						"Paschim Jitpur",
						"Paschim Punro Para",
						"Patrasaer",
						"Pattabong Tea Garden",
						"Patuli",
						"Patulia",
						"Phulia",
						"Podara",
						"Port Blair",
						"Prayagpur",
						"Pujali",
						"Purba Medinipur",
						"Purba Tajpur",
						"Purulia",
						"Raghudebbati",
						"Raghudebpur",
						"Raghunathchak",
						"Raghunathpur",
						"Raghunathpur-Dankuni",
						"Raghunathpur-Magra",
						"Raigachhi",
						"Raiganj",
						"Raipur",
						"Rajarhat Gopalpur",
						"Rajpur",
						"Ramchandrapur",
						"Ramjibanpur",
						"Ramnagar",
						"Rampur Hat",
						"Ranaghat",
						"Raniganj",
						"Ratibati",
						"Raypur",
						"Rishra",
						"Rishra Cantonment",
						"Ruiya",
						"Sahajadpur",
						"Sahapur",
						"Sainthia",
						"Salap",
						"Sankarpur",
						"Sankrail",
						"Santoshpur",
						"Saontaidih",
						"Sarenga",
						"Sarpi",
						"Satigachha",
						"Serpur",
						"Shankhanagar",
						"Shantipur",
						"Shrirampur",
						"Siduli",
						"Siliguri",
						"Simla",
						"Singur",
						"Sirsha",
						"Siuri",
						"Sobhaganj",
						"Sodpur",
						"Sonamukhi",
						"Sonatikiri",
						"Srikantabati",
						"Srirampur",
						"Sukdal",
						"Taherpur",
						"Taki",
						"Talbandha",
						"Tamluk",
						"Tarakeswar",
						"Tentulberia",
						"Tentulkuli",
						"Thermal Power Project",
						"Tinsukia",
						"Titagarh",
						"Tufanganj",
						"Ukhra",
						"Ula",
						"Ulubaria",
						"Uttar Durgapur",
						"Uttar Goara",
						"Uttar Kalas",
						"Uttar Kamakhyaguri",
						"Uttar Latabari",
						"Uttar Mahammadpur",
						"Uttar Pirpur",
						"Uttar Raypur",
						"Uttarpara-Kotrung",
					],
					stateName: "West Bengal",
				},
			],
			countryName: "India",
		},
		{
			states: [
				{
					cities: ["Banda Aceh", "Bireun", "Langsa", "Lhokseumawe", "Meulaboh"],
					stateName: "Aceh",
				},
				{
					cities: [
						"Denpasar",
						"Karangasem",
						"Klungkung",
						"Kuta",
						"Negara",
						"Singaraja",
						"Tabanan",
						"Ubud",
					],
					stateName: "Bali",
				},
				{
					cities: [
						"Manggar",
						"Mentok",
						"Pangkal Pinang",
						"Sungai Liat",
						"Tanjung Pandan",
						"Toboali-Rias",
					],
					stateName: "Bangka-Belitung",
				},
				{
					cities: [
						"Cikupa",
						"Cilegon",
						"Ciputat",
						"Curug",
						"Kresek",
						"Labuhan",
						"Pandegelang",
						"Pondok Aren",
						"Rangkasbitung",
						"Serang",
						"Serpong",
						"Tangerang",
						"Teluknaga",
					],
					stateName: "Banten",
				},
				{ cities: ["Bengkulu", "Curup"], stateName: "Bengkulu" },
				{ cities: ["Gandaria"], stateName: "Gandaria" },
				{ cities: ["Gorontalo"], stateName: "Gorontalo" },
				{ cities: ["Cengkareng", "Jakarta"], stateName: "Jakarta" },
				{
					cities: ["Jambi", "Kualatungka", "Simpang", "Sungaipenuh"],
					stateName: "Jambi",
				},
				{ cities: [], stateName: "Jawa Barat" },
				{ cities: [], stateName: "Jawa Tengah" },
				{ cities: [], stateName: "Jawa Timur" },
				{ cities: [], stateName: "Kalimantan Barat" },
				{ cities: [], stateName: "Kalimantan Selatan" },
				{ cities: [], stateName: "Kalimantan Tengah" },
				{ cities: [], stateName: "Kalimantan Timur" },
				{ cities: ["Kendal"], stateName: "Kendal" },
				{
					cities: [
						"Bandar Lampung",
						"Kota Bumi",
						"Metro",
						"Pringsewu",
						"Terbanggi Besar",
					],
					stateName: "Lampung",
				},
				{ cities: ["Amahai", "Ambon", "Tual"], stateName: "Maluku" },
				{ cities: ["Amahai", "Ambon", "Tual"], stateName: "Maluku Utara" },
				{ cities: [], stateName: "Nusa Tenggara Barat" },
				{ cities: [], stateName: "Nusa Tenggara Timur" },
				{
					cities: [
						"Aberpura",
						"Biak",
						"Jaya Pura",
						"Manokwari",
						"Merauke",
						"Sorong",
					],
					stateName: "Papua",
				},
				{
					cities: [
						"Balaipungut",
						"Bengkalis",
						"Dumai",
						"Duri",
						"Pekan Baru",
						"Selatpanjang",
						"Tanjung Balai-Meral",
						"Tembilahan",
					],
					stateName: "Riau",
				},
				{
					cities: [
						"Balaipungut",
						"Bengkalis",
						"Dumai",
						"Duri",
						"Pekan Baru",
						"Selatpanjang",
						"Tanjung Balai-Meral",
						"Tembilahan",
					],
					stateName: "Riau Kepulauan",
				},
				{ cities: ["Solo"], stateName: "Solo" },
				{ cities: [], stateName: "Sulawesi Selatan" },
				{ cities: [], stateName: "Sulawesi Tengah" },
				{ cities: [], stateName: "Sulawesi Tenggara" },
				{ cities: [], stateName: "Sulawesi Utara" },
				{ cities: [], stateName: "Sumatera Barat" },
				{ cities: [], stateName: "Sumatera Selatan" },
				{ cities: [], stateName: "Sumatera Utara" },
				{
					cities: [
						"Bambanglipuro",
						"Banguntapan",
						"Bantul",
						"Depok",
						"Gamping",
						"Godean",
						"Jetis",
						"Kasihan",
						"Ngaglik",
						"Pandak",
						"Pundong",
						"Sewon",
						"Seyegan",
						"Sleman",
						"Srandakan",
						"Wonosari",
						"Yogyakarta",
					],
					stateName: "Yogyakarta",
				},
			],
			countryName: "Indonesia",
		},
		{
			states: [
				{
					cities: ["Ardabil", "Garmi", "Khalkhal", "Meshkinshahr", "Parsabad"],
					stateName: "Ardabil",
				},
				{ cities: [], stateName: "Azarbayjan-e Bakhtari" },
				{ cities: [], stateName: "Azarbayjan-e Khavari" },
				{
					cities: [
						"Bandar-e Gonaveh",
						"Borazjan",
						"Bushehr",
						"Dashti",
						"Dir",
						"Khormuj",
						"Kongan",
						"Tangestan",
					],
					stateName: "Bushehr",
				},
				{ cities: [], stateName: "Chahar Mahal-e Bakhtiari" },
				{
					cities: [
						"Ardistan",
						"Dorchehpiyaz",
						"Dowlatabad",
						"Esfahan",
						"Falavarjan",
						"Faridan",
						"Fereydunshahr",
						"Fuladshahr",
						"Golara",
						"Golpayegan",
						"Kashan",
						"Kelishad",
						"Khomeynishahr",
						"Khonsar",
						"Khuresgan",
						"Mobarakeh",
						"Na'in",
						"Najafabad",
						"Natnaz",
						"Qahdarijan",
						"Rehnan",
						"Semirom",
						"Shahinshahr",
						"Shahreza",
						"Zarinshahr",
					],
					stateName: "Esfahan",
				},
				{
					cities: [
						"Abadeh",
						"Akbarabad",
						"Darab",
						"Eqlid",
						"Estehban",
						"Fasa",
						"Firuzabad",
						"Gerash",
						"Jahrom",
						"Kazerun",
						"Lar",
						"Marv Dasht",
						"Neyriz",
						"Nurabad",
						"Qa'emiyeh",
						"Sepidan",
						"Shiraz",
					],
					stateName: "Fars",
				},
				{
					cities: [
						"Astaneh-ye Ashrafiyeh",
						"Astara",
						"Bandar-e Anzali",
						"Faman",
						"Hashtpar",
						"Lahijan",
						"Langarud",
						"Rasht",
						"Rudbar",
						"Rudsar",
						"Sawma'eh Sara",
					],
					stateName: "Gilan",
				},
				{
					cities: [
						"Aq Qal'eh",
						"Azad Shahr",
						"Bandar-e Torkaman",
						"Gonbad-e Qabus",
						"Gorgan",
					],
					stateName: "Golestan",
				},
				{
					cities: [
						"Asadabad",
						"Bahar",
						"Hamadan",
						"Malayer",
						"Nahavand",
						"Tuysarkan",
					],
					stateName: "Hamadan",
				},
				{
					cities: [
						"Bandar Abbas",
						"Bandar-e 'Abbas",
						"Bandar-e Lengeh",
						"Gheshm",
						"Jask",
						"Kish",
						"Kish Island",
						"Minab",
					],
					stateName: "Hormozgan",
				},
				{
					cities: [
						"Abdanan",
						"Darrehshahr",
						"Dehloran",
						"Ilam",
						"Ivan",
						"Mehran",
					],
					stateName: "Ilam",
				},
				{
					cities: [
						"Baft",
						"Bam",
						"Bardsir",
						"Jiroft",
						"Kahnuj",
						"Kerman",
						"Rafsanjan",
						"Ravar",
						"Shahr-e Babak",
						"Sirjan",
						"Zarand",
					],
					stateName: "Kerman",
				},
				{
					cities: [
						"Eslamabad",
						"Gilan-e Garb",
						"Harsin",
						"Javanrud",
						"Kangavar",
						"Kermanshah",
						"Paveh",
						"Sahneh",
						"Sar-e-Pol-e-Zohab",
						"Sonqor",
					],
					stateName: "Kermanshah",
				},
				{
					cities: [
						"Birjand",
						"Bojnurd",
						"Chenaran",
						"Darreh Gaz",
						"Esfarayen",
						"Fariman",
						"Ferdus",
						"Gha'nat",
						"Gonabad",
						"Kashmar",
						"Mashad",
						"Mashhad",
						"Neyshabur",
						"Qayen",
						"Quchan",
						"Sabzevar",
						"Sarakhs",
						"Shirvan",
						"Tabas",
						"Tayyebat",
						"Torbat-e Heydariyeh",
						"Torbat-e Jam",
					],
					stateName: "Khorasan",
				},
				{
					cities: [
						"Abadan",
						"Agha Jari",
						"Ahvaz",
						"Ahwaz",
						"Andimeshk",
						"Bandar-e Emam Khomeyni",
						"Bandar-e Mahshahr",
						"Behbahan",
						"Dezful",
						"Ezeh",
						"Hendijan",
						"Khorramshahr",
						"Masjed-e Soleyman",
						"Omidiyeh",
						"Ramhormoz",
						"Ramshir",
						"Shadegan",
						"Shush",
						"Shushtar",
						"Susangerd",
					],
					stateName: "Khuzestan",
				},
				{ cities: [], stateName: "Kohgiluyeh-e Boyerahmad" },
				{
					cities: [
						"Baneh",
						"Bijar",
						"Kamyaran",
						"Marivan",
						"Qorveh",
						"Sanandaj",
						"Saqqez",
					],
					stateName: "Kordestan",
				},
				{
					cities: [
						"Alashtar",
						"Aligudarz",
						"Azna",
						"Borujerd",
						"Do Rud",
						"Khorramabad",
						"Kuhdasht",
						"Nurabad",
					],
					stateName: "Lorestan",
				},
				{
					cities: [
						"Arak",
						"Ashtian",
						"Delijan",
						"Khomeyn",
						"Mahallat",
						"Sarband",
						"Saveh",
						"Tafresh",
					],
					stateName: "Markazi",
				},
				{
					cities: [
						"Aliabad",
						"Amir Kala",
						"Amol",
						"Babol",
						"Babol Sar",
						"Behshahr",
						"Chalus",
						"Fereydunkenar",
						"Juybar",
						"Kalaleh",
						"Kordkuy",
						"Mahmudabad",
						"Minudasht",
						"Neka",
						"Nur",
						"Nushahr",
						"Qa'emshahr",
						"Ramsar",
						"Sari",
						"Savadkuh",
						"Tonekabon",
					],
					stateName: "Mazandaran",
				},
				{ cities: [], stateName: "Ostan-e Esfahan" },
				{
					cities: ["Abhar", "Abyek", "Qazvin", "Takestan"],
					stateName: "Qazvin",
				},
				{ cities: ["Qom"], stateName: "Qom" },
				{
					cities: ["Damghan", "Garmsar", "Semnan", "Shahrud"],
					stateName: "Semnan",
				},
				{ cities: [], stateName: "Sistan-e Baluchestan" },
				{
					cities: [
						"Damavand",
						"Eqbaliyeh",
						"Eslamshahr",
						"Hashtgerd",
						"Karaj",
						"Mahdasht",
						"Malard",
						"Mohammadiyeh",
						"Nazarabad",
						"Pakdasht",
						"Pishva",
						"Qarchak",
						"Qods",
						"Robat Karim",
						"Shahriyar",
						"Tehran",
						"Varamin",
					],
					stateName: "Tehran",
				},
				{
					cities: ["Ardakan", "Bafq", "Mehriz", "Meybod", "Taft", "Yazd"],
					stateName: "Yazd",
				},
				{ cities: ["Alvand", "Khorramdarreh", "Zanjan"], stateName: "Zanjan" },
			],
			countryName: "Iran",
		},
		{
			states: [
				{
					cities: [
						"Jurf-as-Sakhr",
						"Saddat-al-Hindiyah",
						"al-Hillah",
						"al-Madhatiyah",
						"al-Musayyib",
						"al-Qasim",
					],
					stateName: "Babil",
				},
				{ cities: ["Baghdad"], stateName: "Baghdad" },
				{ cities: ["Dahuk", "Zakhu"], stateName: "Dahuk" },
				{ cities: [], stateName: "Dhi Qar" },
				{
					cities: [
						"Ba'qubah",
						"Hanaqin",
						"Jalula''",
						"Kifri",
						"Mandali",
						"al-Fuhud",
						"al-Khalis",
						"al-Miqdadiyah",
						"ash-Shatrah",
					],
					stateName: "Diyala",
				},
				{ cities: ["Ankawa"], stateName: "Erbil" },
				{ cities: ["Chaqalawa", "Irbil", "Rawanduz"], stateName: "Irbil" },
				{ cities: ["Karbala", "al-Hindiyah"], stateName: "Karbala" },
				{ cities: ["Erbil"], stateName: "Kurdistan" },
				{
					cities: ["'Ali al Gharbi", "al-'Amarah", "al-Majarr-al-Kabir"],
					stateName: "Maysan",
				},
				{
					cities: [
						"Qarah Qush",
						"Sinjar",
						"Tall 'Afar",
						"Tall Kayf",
						"al-Mawsil",
						"ash-Shaykhan",
					],
					stateName: "Ninawa",
				},
				{
					cities: [
						"Balad",
						"Bayji",
						"Dhalu'iyah",
						"Samarra",
						"Tikrit",
						"Tuz",
						"ad-Dujayl",
					],
					stateName: "Salah-ad-Din",
				},
				{
					cities: [
						"al-'Aziziyah",
						"al-Hayy",
						"al-Kut",
						"an-Nu'maniyah",
						"as-Suwayrah",
					],
					stateName: "Wasit",
				},
				{
					cities: [
						"'Anah",
						"Hit",
						"Rawah",
						"al-Fallujah",
						"al-Habbaniyah",
						"al-Hadithah",
						"ar-Ramadi",
						"ar-Rutbah",
					],
					stateName: "al-Anbar",
				},
				{
					cities: [
						"Abu al-Khasib",
						"Harithah",
						"Shatt-al-'Arab",
						"al-Basrah",
						"al-Faw",
						"al-Qurnah",
						"az-Zubayr",
					],
					stateName: "al-Basrah",
				},
				{ cities: ["ar-Rumaythah", "as-Samawah"], stateName: "al-Muthanna" },
				{
					cities: [
						"'Afak",
						"ad-Diwaniyah",
						"al-Ghammas",
						"al-Hamzah",
						"ash-Shamiyah",
						"ash-Shinafiyah",
					],
					stateName: "al-Qadisiyah",
				},
				{
					cities: ["al-Kufah", "al-Mishkhab", "an-Najaf"],
					stateName: "an-Najaf",
				},
				{
					cities: [
						"Chamchamal",
						"Halabjah",
						"Kusanjaq",
						"Panjwin",
						"Qal'at Dizeh",
						"as-Sulaymaniyah",
					],
					stateName: "as-Sulaymaniyah",
				},
				{ cities: ["'Aqrah", "Kirkuk"], stateName: "at-Ta'mim" },
			],
			countryName: "Iraq",
		},
		{
			states: [
				{ cities: ["Moira"], stateName: "Armagh" },
				{ cities: ["Bagenalstown", "Carlow", "Tullow"], stateName: "Carlow" },
				{
					cities: ["Bailieborough", "Belturbet", "Cavan", "Coothill"],
					stateName: "Cavan",
				},
				{
					cities: [
						"Ennis",
						"Kilkee",
						"Kilrush",
						"Newmarket-on-Fergus",
						"Shannon",
					],
					stateName: "Clare",
				},
				{
					cities: [
						"Bandon",
						"Bantry",
						"Blarney",
						"Carrigaline",
						"Charleville",
						"Clonakilty",
						"Cobh",
						"Cork",
						"Drishane",
						"Dunmanway",
						"Fermoy",
						"Kanturk",
						"Kinsale",
						"Macroom",
						"Mallow",
						"Midleton",
						"Millstreet",
						"Mitchelstown",
						"Passage West",
						"Skibbereen",
						"Youghal",
					],
					stateName: "Cork",
				},
				{
					cities: [
						"Ballybofey",
						"Ballyshannon",
						"Buncrana",
						"Bundoran",
						"Carndonagh",
						"Donegal",
						"Killybegs",
						"Letterkenny",
						"Lifford",
						"Moville",
					],
					stateName: "Donegal",
				},
				{
					cities: [
						"Balbriggan",
						"Ballsbridge",
						"Dublin",
						"Leixlip",
						"Lucan",
						"Malahide",
						"Portrane",
						"Rathcoole",
						"Rush",
						"Skerries",
						"Swords",
					],
					stateName: "Dublin",
				},
				{
					cities: [
						"Athenry",
						"Ballinasloe",
						"Clifden",
						"Galway",
						"Gort",
						"Loughrea",
						"Tuam",
					],
					stateName: "Galway",
				},
				{
					cities: [
						"Ballybunion",
						"Cahirciveen",
						"Castleisland",
						"Dingle",
						"Kenmare",
						"Killarney",
						"Killorglin",
						"Listowel",
						"Tralee",
					],
					stateName: "Kerry",
				},
				{
					cities: [
						"Athy",
						"Celbridge",
						"Clane",
						"Kilcock",
						"Kilcullen",
						"Kildare",
						"Maynooth",
						"Monasterevan",
						"Naas",
						"Newbridge",
					],
					stateName: "Kildare",
				},
				{
					cities: ["Callan", "Castlecomer", "Kilkenny", "Thomastown"],
					stateName: "Kilkenny",
				},
				{
					cities: [
						"Abbeyleix",
						"Mountmellick",
						"Mountrath",
						"Port Laoise",
						"Portarlington",
					],
					stateName: "Laois",
				},
				{ cities: ["Meath"], stateName: "Leinster" },
				{ cities: ["Carrick-on-Shannon"], stateName: "Leitrim" },
				{
					cities: [
						"Abbeyfeale",
						"Kilmallock",
						"Limerick",
						"Newcastle",
						"Rathkeale",
					],
					stateName: "Limerick",
				},
				{ cities: [], stateName: "Loch Garman" },
				{ cities: ["Granard", "Longford", "Moate"], stateName: "Longford" },
				{
					cities: ["Ardee", "Drogheda", "Drumcar", "Dundalk"],
					stateName: "Louth",
				},
				{
					cities: [
						"Ballina",
						"Ballinrobe",
						"Ballyhaunis",
						"Castlebar",
						"Claremorris",
						"Swinford",
						"Westport",
					],
					stateName: "Mayo",
				},
				{
					cities: [
						"Ashbourne",
						"Duleek",
						"Dunboyne",
						"Dunshaughlin",
						"Kells",
						"Laytown",
						"Navan",
						"Trim",
					],
					stateName: "Meath",
				},
				{
					cities: ["Carrickmacross", "Castleblayney", "Clones", "Monaghan"],
					stateName: "Monaghan",
				},
				{
					cities: [
						"Banagher",
						"Birr",
						"Clara",
						"Edenderry",
						"Kilcormac",
						"Tullamore",
					],
					stateName: "Offaly",
				},
				{
					cities: ["Ballaghaderreen", "Boyle", "Castlerea", "Roscommon"],
					stateName: "Roscommon",
				},
				{ cities: ["Sligo"], stateName: "Sligo" },
				{ cities: [], stateName: "Tipperary North Riding" },
				{ cities: [], stateName: "Tipperary South Riding" },
				{ cities: ["Co Tyrone", "Downpatrick"], stateName: "Ulster" },
				{
					cities: ["Dungarvan", "Tramore", "Waterford"],
					stateName: "Waterford",
				},
				{ cities: ["Athlone", "Mullingar"], stateName: "Westmeath" },
				{
					cities: ["Enniscorthy", "Gorey", "New Ross", "Wexford"],
					stateName: "Wexford",
				},
				{
					cities: [
						"Arklow",
						"Baltinglass",
						"Blessington",
						"Bray",
						"Greystones",
						"Kilcoole",
						"Newtownmountkennedy",
						"Rathdrum",
						"Wicklow",
					],
					stateName: "Wicklow",
				},
			],
			countryName: "Ireland",
		},
		{
			states: [
				{ cities: [], stateName: "Beit Hanania" },
				{ cities: [], stateName: "Ben Gurion Airport" },
				{ cities: ["Bethlehem"], stateName: "Bethlehem" },
				{ cities: ["Caesarea"], stateName: "Caesarea" },
				{ cities: ["Petach Tikva", "Ramallah"], stateName: "Centre" },
				{ cities: ["Gaza"], stateName: "Gaza" },
				{
					cities: [
						"'Arad",
						"'Omer",
						"Ashdod",
						"Ashqelon",
						"Be'er Sheva",
						"Beersheba",
						"Bene Ayish",
						"Dimona",
						"Elat",
						"Gan Yavne",
						"Nahal `Oz",
						"Netivot",
						"Ofaqim",
						"Qiryat Gat",
						"Qiryat Mal'akhi",
						"Sederot",
						"Yeroham",
						"kiryat Malachi",
					],
					stateName: "Hadaron",
				},
				{ cities: [], stateName: "Haifa District" },
				{
					cities: [
						"Be'er Ya'aqov",
						"Beit Shemesh",
						"Bene Beraq",
						"Bnei Brak",
						"Even Yehuda",
						"Fureidis",
						"Gat Rimon",
						"Gedera",
						"Givat Shmuel",
						"Hibat Zion",
						"Hod HaSharon",
						"Hogar",
						"Jaljulye",
						"Jatt",
						"Kafar Qasem",
						"Kefar Sava",
						"Kefar Yona",
						"Kfar Saba",
						"Kiryag Bialik",
						"Lod",
						"Mazkeret Batya",
						"Modi'in",
						"Nes Ziyyona",
						"Ness Ziona",
						"Netanya",
						"Nordiya",
						"Pardesiyya",
						"Petakh Tiqwa",
						"Qadima",
						"Qalansawe",
						"Qiryat 'Eqron",
						"Ra'anana",
						"Ramla",
						"Rehovot",
						"Rekhovot",
						"Rinnatya",
						"Rishon LeZiyyon",
						"Rosh HaAyin",
						"Shoham",
						"Tayibe",
						"Tire",
						"Tsur Igal",
						"Udim",
						"Yavne",
						"Yehud",
					],
					stateName: "Hamerkaz",
				},
				{
					cities: [
						"'Afula",
						"'Akko",
						"'Arrabe",
						"'Ein Mahel",
						"'Ilut",
						"Abu Sinan",
						"Basmat Tab'un",
						"Beit Jann",
						"Bet She'an",
						"Bi'ne",
						"Bir-al-Maksur",
						"Bu'eine-Nujeidat",
						"Dabburye",
						"Dayr Hannah",
						"Dayr-al-Asad",
						"Hazor HaGelilit",
						"I'billin",
						"Iksal",
						"Judeide-Maker",
						"Kabul",
						"Kafar Kanna",
						"Kafar Manda",
						"Kafar Yasif",
						"Karmiel",
						"Kisra-Sumei",
						"Ma'alot Tarshikha",
						"Majd-al-Kurum",
						"Migdal Ha'Emeq",
						"Mugar",
						"Nahariyya",
						"Nahef",
						"Nazerat",
						"Nazerat 'Illit",
						"Qiryat Shemona",
						"Qiryat Tiv'on",
						"Rame",
						"Reine",
						"Sakhnin",
						"Shefar'am",
						"Tamra",
						"Tiberias",
						"Tur'an",
						"Yirka",
						"Yoqne'am Illit",
						"Zefat",
						"Zur Yigal",
					],
					stateName: "Hazafon",
				},
				{ cities: ["Hebron"], stateName: "Hebron" },
				{ cities: ["Sgula"], stateName: "Jaffa" },
				{ cities: ["Jerusalem"], stateName: "Jerusalem" },
				{
					cities: [
						"'Ar'ara",
						"'Isifya",
						"Baqa al-Gharbiyyah",
						"Binyamina",
						"Daliyat-al-Karmil",
						"Jizr-az-Zarqa",
						"Khadera",
						"Khefa",
						"Nesher",
						"Or 'Aqiva",
						"Pardes Khanna-Karkur",
						"Qiryat Atta",
						"Qiryat Bialik",
						"Qiryat Motzkin",
						"Qiryat Yam",
						"Rekhasim",
						"Tirat Karmel",
						"Umm-al-Fahm",
						"Zikhron Ya'aqov",
					],
					stateName: "Khefa",
				},
				{ cities: [], stateName: "Kiryat Yam" },
				{ cities: [], stateName: "Lower Galilee" },
				{ cities: ["Qalqilya"], stateName: "Qalqilya" },
				{ cities: [], stateName: "Talme Elazar" },
				{ cities: [], stateName: "Tel Aviv" },
				{ cities: ["Hadera", "Kiryag Bialik"], stateName: "Tsafon" },
				{ cities: [], stateName: "Umm El Fahem" },
				{
					cities: ["Bet Shemesh", "Mevasserat Ziyyon", "Yerushalayim"],
					stateName: "Yerushalayim",
				},
			],
			countryName: "Israel",
		},
		{
			states: [
				{ cities: ["Meta", "Miano"], stateName: "Abruzzi" },
				{
					cities: [
						"Alba Adriatica",
						"Atessa",
						"Atri",
						"Avezzano",
						"Celano",
						"Cepagatti",
						"Chieti",
						"Citta Sant'Angelo",
						"Francavilla al Mare",
						"Giulianova",
						"Guardiagrele",
						"L'Aquila",
						"Lanciano",
						"Martinsicuro",
						"Montesilvano",
						"Montorio al Vomano",
						"Mosciano Sant'Angelo",
						"Ortona",
						"Penne",
						"Pescara",
						"Pineto",
						"Roseto degli Abruzzi",
						"San Giovanni Teatino",
						"San Salvo",
						"Sant'Egidio alla Vibrata",
						"Silvi",
						"Spoltore",
						"Sulmona",
						"Teramo",
						"Vasto",
					],
					stateName: "Abruzzo",
				},
				{
					cities: [
						"Agrigento",
						"Alessandria della Rocca",
						"Aragona",
						"Bivona",
						"Burgio",
						"Calamonaci",
						"Caltabellotta",
						"Camastra",
						"Cammarata",
						"Campobello di Licata",
						"CanicattÃÆÃÂ¬",
						"Casteltermini",
						"Castrofilippo",
						"Cattolica Eraclea",
						"Cianciana",
						"Comitini",
						"Favara",
						"Grotte",
						"Joppolo Giancaxio",
						"Lampedusa e Linosa",
						"Licata",
						"Lucca Sicula",
						"Menfi",
						"Montallegro",
						"Montevago",
						"Naro",
						"Palma di Montechiaro",
						"Porto Empedocle",
						"Racalmuto",
						"Raffadali",
						"Ravanusa",
						"Realmonte",
						"Ribera",
						"Sambuca di Sicilia",
						"San Biagio Platani",
						"San Giovanni Gemini",
						"Sant Angelo Muxaro",
						"Santa Elisabetta",
						"Santa Margherita di Belice",
						"Santo Stefano Quisquina",
						"Sciacca",
						"Siculiana",
						"Villafranca Sicula",
					],
					stateName: "Agrigento",
				},
				{
					cities: ["Castellazzo Bormida", "Gavi", "Villanova Monferrato"],
					stateName: "Alessandria",
				},
				{ cities: ["Camerano", "Castelplanio"], stateName: "Ancona" },
				{
					cities: ["Capolona", "Montevarchi", "Subbiano"],
					stateName: "Arezzo",
				},
				{ cities: [], stateName: "Ascoli Piceno" },
				{ cities: ["Buttigliera d'Asti"], stateName: "Asti" },
				{
					cities: [
						"Flumeri",
						"Nusco",
						"Prata di Principato Ultra",
						"Villanova del Battista",
					],
					stateName: "Avellino",
				},
				{ cities: [], stateName: "Bari" },
				{
					cities: [
						"Avigliano",
						"Bernalda",
						"Ferrandina",
						"Lauria",
						"Lavello",
						"Matera",
						"Melfi",
						"Montescaglioso",
						"Pisticci",
						"Policoro",
						"Potenza",
						"Rionero in Vulture",
						"Venosa",
					],
					stateName: "Basilicata",
				},
				{
					cities: ["Belluno", "D'alpago", "Longarone", "Pedavena"],
					stateName: "Belluno",
				},
				{ cities: ["San Bartolomeo"], stateName: "Benevento" },
				{
					cities: [
						"",
						"Bagnatica",
						"Bergamo",
						"Bolgare",
						"Bottanuco",
						"Brignano Gera d'Adda",
						"Calcio",
						"Caravaggio",
						"Chiuduno",
						"Ciserano",
						"Comun Nuovo",
						"Costa di Mezzate",
						"Gandino",
						"Grassobbio",
						"Grumello Del Monte",
						"Grumello del Monte",
						"Lallio",
						"Levate",
						"Lurano",
						"Mapello",
						"Pagazzano",
						"Ponteranica",
						"Pontida",
						"Sant Omobono Imagna",
						"Torre Pallavicina",
						"Trescore Balneario",
						"Verdellino",
						"Zingonia",
					],
					stateName: "Bergamo",
				},
				{
					cities: [
						"Camburzano",
						"Crevacuore",
						"Gaglianico",
						"Sandigliano",
						"Vigliano Biellese",
					],
					stateName: "Biella",
				},
				{
					cities: [
						"Anzola dell'Emilia",
						"Bologna",
						"Borgo Tossignano",
						"Casalfiumanese",
						"Castiglione Dei Pepoli ",
						"Funo",
						"Loiano",
						"Monterenzio",
						"Osteria Grande",
					],
					stateName: "Bologna",
				},
				{ cities: ["Frangarto"], stateName: "Bolzano" },
				{
					cities: [
						"Agnosine",
						"Brescia",
						"Capriano del Colle",
						"Capriolo",
						"Castegnato",
						"Castelcovati",
						"Cellatica",
						"Coccaglio",
						"Comezzano-Cizzago",
						"Erbusco",
						"Flero",
						"Lavenone",
						"Longhena",
						"Maclodio",
						"Muscoline",
						"Padenghe sul Garda",
						"Paderno Franciacorta",
						"Paratico",
						"Passirano",
						"Polaveno",
						"Poncarale",
						"Prevalle",
						"Provaglio dIseo",
						"Roncadelle",
						"Verolavecchia",
						"Visano",
					],
					stateName: "Brescia",
				},
				{ cities: ["San Donaci"], stateName: "Brindisi" },
				{
					cities: [
						"Acri",
						"Amantea",
						"Bagnara Calabra",
						"Belvedere Marittimo",
						"Bisignano",
						"Bovalino",
						"Cariati",
						"Cassano allo Ionio",
						"Castrolibero",
						"Castrovillari",
						"Catanzaro",
						"Cetraro",
						"Ciro Marina",
						"Cittanova",
						"Corigliano Calabro",
						"Cosenza",
						"Crosia",
						"Crotone",
						"Cutro",
						"Fuscaldo",
						"Gioia Tauro",
						"Isola di Capo Rizzuto",
						"Lamezia Terme",
						"Locri",
						"Luzzi",
						"Melito di Porto Salvo",
						"Mendicino",
						"Montalto Uffugo",
						"Palmi",
						"Paola",
						"Petilia Policastro",
						"Pizzo",
						"Polistena",
						"Reggio di Calabria",
						"Rende",
						"Rosarno",
						"Rossano",
						"San Giovanni in Fiore",
						"Scalea",
						"Sellia Marina",
						"Siderno",
						"Soverato",
						"Taurianova",
						"Trebisacce",
						"Vibo Valentia",
						"Villa San Giovanni",
					],
					stateName: "Calabria",
				},
				{
					cities: [
						"Acerra",
						"Afragola",
						"Agropoli",
						"Angri",
						"Ariano Irpino",
						"Arzano",
						"Atripalda",
						"Avellino",
						"Aversa",
						"Bacoli",
						"Barano d'Ischia",
						"Baronissi",
						"Battipaglia",
						"Bellizzi",
						"Benevento",
						"Boscoreale",
						"Boscotrecase",
						"Brusciano",
						"Caivano",
						"Calvizzano",
						"Campagna",
						"Capaccio",
						"Capua",
						"Cardito",
						"Carinola",
						"Casagiove",
						"Casal di Principe",
						"Casalnuovo di Napoli",
						"Casaluce",
						"Casandrino",
						"Casavatore",
						"Caserta",
						"Casoria",
						"Castel San Giorgio",
						"Castel Volturno",
						"Castellammare di Stabia",
						"Cava de' Tirreni",
						"Cercola",
						"Cervinara",
						"Cicciano",
						"Crispano",
						"Eboli",
						"Ercolano",
						"Fisciano",
						"Forio",
						"Frattamaggiore",
						"Frattaminore",
						"Frignano",
						"Giffoni Valle Piana",
						"Giugliano in Campania",
						"Gragnano",
						"Gricignano di Aversa",
						"Grottaminarda",
						"Grumo Nevano",
						"Ischia",
						"Lusciano",
						"Macerata Campania",
						"Maddaloni",
						"Marano di Napoli",
						"Marcianise",
						"Marigliano",
						"Massa Lubrense",
						"Melito di Napoli",
						"Mercato San Severino",
						"Mercogliano",
						"Meta",
						"Mirabella Eclano",
						"Mondragone",
						"Monte di Procida",
						"Montecorvino Rovella",
						"Monteforte Irpino",
						"Montesarchio",
						"Montoro Inferiore",
						"Mugnano di Napoli",
						"Naples",
						"Napoli",
						"Nocera Inferiore",
						"Nocera Superiore",
						"Nola",
						"Orta di Atella",
						"Ottaviano",
						"Pagani",
						"Palma Campania",
						"Parete",
						"Pellezzano",
						"Piano di Sorrento",
						"Piedimonte Matese",
						"Poggiomarino",
						"Pollena Trocchia",
						"Pomigliano d'Arco",
						"Pompei",
						"Pontecagnano",
						"Portici",
						"Positano",
						"Pozzuoli",
						"Procida",
						"Qualiano",
						"Quarto",
						"Roccapiemonte",
						"Sala Consilina",
						"Salerno",
						"San Cipriano d'Aversa",
						"San Felice a Cancello",
						"San Gennaro Vesuviano",
						"San Giorgio a Cremano",
						"San Giorgio del Sannio",
						"San Giuseppe Vesuviano",
						"San Marcellino",
						"San Marzano sul Sarno",
						"San Nicola",
						"San Prisco",
						"San Sebastiano al Vesuvio",
						"San Valentino Torio",
						"Sant Antimo",
						"Sant'Agata de' Goti",
						"Sant'Agnello",
						"Sant'Anastasia",
						"Sant'Antonio Abate",
						"Sant'Arpino",
						"Sant'Egidio del Monte Albino",
						"Santa Maria Capua Vetere",
						"Santa Maria a Vico",
						"Santa Maria la Carita",
						"Sarno",
						"Saviano",
						"Scafati",
						"Sessa Aurunca",
						"Siano",
						"Solofra",
						"Somma Vesuviana",
						"Sorrento",
						"Teano",
						"Teggiano",
						"Terzigno",
						"Teverola",
						"Torre Annunziata",
						"Torre del Greco",
						"Trecase",
						"Trentola-Ducenta",
						"Vallo della Lucania",
						"Vico Equense",
						"Vietri sul Mare",
						"Villa Literno",
						"Villaricca",
						"Volla",
					],
					stateName: "Campania",
				},
				{ cities: ["Cartoceto"], stateName: "Cartoceto" },
				{ cities: ["Carinaro", "San Marco Evangelista"], stateName: "Caserta" },
				{ cities: ["Fiandaca Di Acireale", "San Cono"], stateName: "Catania" },
				{
					cities: [
						"Altino",
						"Archi",
						"Ari",
						"Arielli",
						"Atessa",
						"Bomba",
						"Borrello",
						"Bucchianico",
						"Canosa Sannita",
						"Carpineto Sinello",
						"Carunchio",
						"Casacanditella",
						"Casalanguida",
						"Casalbordino",
						"Casalincontrada",
						"Casoli",
						"Castel Frentano",
						"Castelguidone",
						"Castiglione Messer Marino",
						"Celenza sul Trigno",
						"Chieti",
						"Civitaluparella",
						"Civitella Messer Raimondo",
						"Colledimacine",
						"Colledimezzo",
						"Crecchio",
						"Cupello",
						"Dogliola",
						"Fallo",
						"Fara Filiorum Petri",
						"Fara San Martino",
						"Filetto",
						"Fossacesia",
						"Fraine",
						"Francavilla al Mare",
						"Fresagrandinaria",
						"Frisa",
						"Furci",
						"Gamberale",
						"Gessopalena",
						"Gissi",
						"Giuliano Teatino",
						"Guardiagrele",
						"Guilmi",
						"Lama dei Peligni",
						"Lanciano",
						"Lentella",
						"Lettopalena",
						"Liscia",
						"Miglianico",
						"Montazzoli",
						"Montebello sul Sangro",
						"Monteferrante",
						"Montelapiano",
						"Montenerodomo",
						"Monteodorisio",
						"Mozzagrogna",
						"Orsogna",
						"Ortona",
						"Paglieta",
						"Palena",
						"Palmoli",
						"Palombaro",
						"Pennadomo",
						"Pennapiedimonte",
						"Perano",
						"Pietraferrazzana",
						"Pizzoferrato",
						"Poggiofiorito",
						"Pollutri",
						"Pretoro",
						"Quadri",
						"Rapino",
						"Ripa Teatina",
						"Rocca San Giovanni",
						"Roccamontepiano",
						"Roccascalegna",
						"Roccaspinalveti",
						"Roio del Sangro",
						"Rosello",
						"San Buono",
						"San Giovanni Lipioni",
						"San Giovanni Teatino",
						"San Martino sulla Marrucina",
						"San Salvo",
						"San Vito Chietino",
						"Sant Eusanio del Sangro",
						"Santa Maria Imbaro",
						"Scerni",
						"Schiavi di Abruzzo",
						"Taranta Peligna",
						"Tollo",
						"Torino di Sangro",
						"Tornareccio",
						"Torrebruna",
						"Torrevecchia Teatina",
						"Torricella Peligna",
						"Treglio",
						"Tufillo",
						"Vacri",
						"Vasto",
						"Villa Santa Maria",
						"Villalfonsina",
						"Villamagna",
					],
					stateName: "Chieti",
				},
				{
					cities: [
						"Albavilla",
						"Cadorago",
						"Carimate",
						"Castelmarte",
						"Cavaria",
						"Cernobbio",
						"Comocrea",
						"Dongo",
						"Gironico",
						"Grandate",
						"Lurago dErba",
						"Mozzate",
						"Novedrate",
						"Orsenigo",
						"Turate",
						"Uggiate",
					],
					stateName: "Como",
				},
				{ cities: ["Corso del Tirreno", "Mangone"], stateName: "Cosenza" },
				{
					cities: [
						"Casalbuttano",
						"Casalmaggiore",
						"Castelverde",
						"Madignano",
						"Pieve San Giacomo",
					],
					stateName: "Cremona",
				},
				{
					cities: [
						"Bandito",
						"Bra",
						"Casalgrasso",
						"Cossano Belbo",
						"Magliano Alpi",
						"Mondovi",
						"Roddi",
						"Santa Vittoria d'Alba",
						"Verduno",
					],
					stateName: "Cuneo",
				},
				{
					cities: [
						"Alfonsine",
						"Argelato",
						"Argenta",
						"Bagnacavallo",
						"Bagnolo in Piano",
						"Bellaria-Igea Marina",
						"Bertinoro",
						"Bologna",
						"Bondeno",
						"Budrio",
						"Calderara di Reno",
						"Carpi",
						"Casalecchio di Reno",
						"Casalgrande",
						"Castel Bolognese",
						"Castel Maggiore",
						"Castel San Giovanni",
						"Castel San Pietro Terme",
						"Castelfranco Emilia",
						"Castellarano",
						"Castelnovo ne' Monti",
						"Castelnuovo Rangone",
						"Castelvetro di Modena",
						"Castenaso",
						"Cattolica",
						"Cavriago",
						"Cento",
						"Cervia",
						"Cesena",
						"Cesenatico",
						"Codigoro",
						"Collecchio",
						"Comacchio",
						"Concordia sulla Secchia",
						"Conselice",
						"Copparo",
						"Coriano",
						"Correggio",
						"Crespellano",
						"Crevalcore",
						"Faenza",
						"Ferrara",
						"Fidenza",
						"Finale Emilia",
						"Fiorano Modenese",
						"Fiorenzuola d'Arda",
						"Forli",
						"Forlimpopoli",
						"Formigine",
						"Gambettola",
						"Granarolo dell'Emilia",
						"Guastalla",
						"Imola",
						"Langhirano",
						"Lugo",
						"Luzzara",
						"Maranello",
						"Massa Lombarda",
						"Medesano",
						"Medicina",
						"Meldola",
						"Mirandola",
						"Misano Adriatico",
						"Modena",
						"Molinella",
						"Monte San Pietro",
						"Montecchio Emilia",
						"Montechiarugolo",
						"Noceto",
						"Nonantola",
						"Novellara",
						"Novi di Modena",
						"Ozzano dell'Emilia",
						"Parma",
						"Pavullo nel Frignano",
						"Piacenza",
						"Pianoro",
						"Ponticino",
						"Portomaggiore",
						"Quattro Castella",
						"Ravenna",
						"Reggio nell'Emilia",
						"Reggiolo",
						"Riccione",
						"Rimini",
						"Rottofreno",
						"Rubiera",
						"Russi",
						"Salsomaggiore Terme",
						"San Felice sul Panaro",
						"San Giovanni in Persiceto",
						"San Lazzaro di Savena",
						"San Mauro Pascoli",
						"San Pietro in Casale",
						"Sant'Ilario d'Enza",
						"Santarcangelo di Romagna",
						"Sasso Marconi",
						"Sassuolo",
						"Savignano sul Panaro",
						"Savignano sul Rubicone",
						"Scandiano",
						"Soliera",
						"Sorbolo",
						"Spilamberto",
						"Verucchio",
						"Vignola",
						"Zola Predosa",
					],
					stateName: "Emilia-Romagna",
				},
				{ cities: ["Saint Agostino"], stateName: "Ferrara" },
				{
					cities: ["Capalle", "Firenze", "Pelago", "San Donnino", "Scarperia"],
					stateName: "Firenze",
				},
				{ cities: ["Scandicci", "Sesto Fiorentino"], stateName: "Florence" },
				{ cities: [], stateName: "Forli-Cesena " },
				{ cities: [], stateName: "Friuli-Venezia Giulia" },
				{
					cities: ["Casalvieri", "Frosinone", "Sgurgola"],
					stateName: "Frosinone",
				},
				{ cities: ["Genoa", "Moneglia"], stateName: "Genoa" },
				{
					cities: ["Romans d'Isonzo", "Savogna d'Isonzo"],
					stateName: "Gorizia",
				},
				{ cities: ["Magliano de Marsi"], stateName: "L'Aquila" },
				{
					cities: [
						"Alatri",
						"Albano Laziale",
						"Anagni",
						"Anguillara Sabazia",
						"Anzio",
						"Aprilia",
						"Ardea",
						"Ariccia",
						"Artena",
						"Boville Ernica",
						"Bracciano",
						"Campagnano di Roma",
						"Cassino",
						"Cave",
						"Ceccano",
						"Ceprano",
						"Cerveteri",
						"Ciampino",
						"Cisterna",
						"Civita Castellana",
						"Civitavecchia",
						"Colleferro",
						"Cori",
						"Fara in Sabina",
						"Ferentino",
						"Fiano Romano",
						"Fiuggi",
						"Fiumicino",
						"Fondi",
						"Fonte Nuova",
						"Formello",
						"Formia",
						"Frascati",
						"Frosinone",
						"Gaeta",
						"Genzano di Roma",
						"Grottaferrata",
						"Guidonia",
						"Isola del Liri",
						"Itri",
						"Ladispoli",
						"Lanuvio",
						"Lariano",
						"Latina",
						"Marino",
						"Mentana",
						"Minturno",
						"Monte San Giovanni Campano",
						"Montefiascone",
						"Monterotondo",
						"Nettuno",
						"Palestrina",
						"Palombara Sabina",
						"Pomezia",
						"Pontecorvo",
						"Pontinia",
						"Priverno",
						"Rieti",
						"Rocca Priora",
						"Rocca di Papa",
						"Roma",
						"Rome",
						"Sabaudia",
						"San Cesareo",
						"Santa Marinella",
						"Segni",
						"Sezze",
						"Sora",
						"Soriano nel Cimino",
						"Subiaco",
						"Tarquinia",
						"Terracina",
						"Tivoli",
						"Valmontone",
						"Velletri",
						"Veroli",
						"Vetralla",
						"Viterbo",
						"Zagarolo",
					],
					stateName: "Lazio",
				},
				{ cities: ["Acquarica del Capo"], stateName: "Lecce" },
				{
					cities: [
						"Airuno",
						"Bosisio Parini",
						"Lecco",
						"Margno",
						"Osnago",
						"Sirone",
					],
					stateName: "Lecco",
				},
				{
					cities: [
						"Airuno",
						"Bosisio Parini",
						"Lecco",
						"Margno",
						"Osnago",
						"Sirone",
					],
					stateName: "Lecco Province",
				},
				{
					cities: [
						"Alassio",
						"Albenga",
						"Albisola Superiore",
						"Arcola",
						"Arenzano",
						"Bordighera",
						"Borgonuovo",
						"Cairo Montenotte",
						"Chiavari",
						"Cogoleto",
						"Finale Ligure",
						"Genova",
						"Imperia",
						"La Spezia",
						"Lavagna",
						"Lerici",
						"Loano",
						"Ortonovo",
						"Pietra Ligure",
						"Rapallo",
						"Recco",
						"San Remo",
						"Santa Margherita Ligure",
						"Santo Stefano di Magra",
						"Sarzana",
						"Savona",
						"Sestri Levante",
						"Taggia",
						"Varazze",
						"Ventimiglia",
					],
					stateName: "Liguria",
				},
				{ cities: ["Somaglia"], stateName: "Lodi" },
				{
					cities: [
						"Abbiategrasso",
						"Agrate Brianza",
						"Albiate",
						"Albino",
						"Albizzate",
						"Alzano Lombardo",
						"Arcisate",
						"Arconate",
						"Arcore",
						"Arese",
						"Arluno",
						"Asola",
						"Bagnolo Mella",
						"Ballabio",
						"Bareggio",
						"Basiglio",
						"Bedizzole",
						"Bergamo",
						"Bernareggio",
						"Besana in Brianza",
						"Besozzo",
						"Biassono",
						"Bienate",
						"Bollate",
						"Botticino",
						"Bovisio-Masciago",
						"Brembilla",
						"Brescia",
						"Bresso",
						"Broni",
						"Brugherio",
						"Buccinasco",
						"Bussero",
						"Busto Arsizio",
						"Busto Garolfo",
						"Cairate",
						"Calcinato",
						"Calolziocorte",
						"Calusco d'Adda",
						"Canegrate",
						"Cantu",
						"Capriolo",
						"Carate Brianza",
						"Caravaggio",
						"Cardano al Campo",
						"Caronno Pertusella",
						"Carpenedolo",
						"Carugate",
						"Carvico",
						"Casalmaggiore",
						"Casalpusterlengo",
						"Casatenovo",
						"Casazza",
						"Casnigo",
						"Cassano Magnago",
						"Cassano d'Adda",
						"Cassina de' Pecchi",
						"Castano Primo",
						"Castel Goffredo",
						"Castel Mella",
						"Castellanza",
						"Castelleone",
						"Castelli Calepio",
						"Castenedolo",
						"Castiglione delle Stiviere",
						"Cazzago San Martino",
						"Cene",
						"Cermenate",
						"Cernusco sul Naviglio",
						"Cerro Maggiore",
						"Cesano Boscone",
						"Cesano Maderno",
						"Cesate",
						"Chiari",
						"Cilavegna",
						"Cinisello Balsamo",
						"Cislago",
						"Clusone",
						"Codogno",
						"Cologno Monzese",
						"Cologno al Serio",
						"Como",
						"Concesio",
						"Concorezzo",
						"Corbetta",
						"Cormano",
						"Cornaredo",
						"Cornate d'Adda",
						"Corsico",
						"Corte dei Cortesi",
						"Costa Volpino",
						"Crema",
						"Cremona",
						"Crocetta",
						"Curtatone",
						"Cusano Milanino",
						"Dalmine",
						"Darfo",
						"Desenzano del Garda",
						"Desio",
						"Erba",
						"Fagnano Olona",
						"Fino Mornasco",
						"Gaggiano",
						"Galbiate",
						"Gallarate",
						"Gambolo",
						"Garbagnate Milanese",
						"Gardone Val Trompia",
						"Garlasco",
						"Gavardo",
						"Gavirate",
						"Gerenzano",
						"Ghedi",
						"Giussano",
						"Goito",
						"Gonzaga",
						"Gorgonzola",
						"Gussago",
						"Gussola",
						"Induno Olona",
						"Inveruno",
						"Inzago",
						"Iseo",
						"Isola Dovarese",
						"Lacchiarella",
						"Lainate",
						"Laveno-Mombello",
						"Lecco",
						"Leffe",
						"Legnano",
						"Leno",
						"Lentate sul Seveso",
						"Limbiate",
						"Lissone",
						"Locate di Triulzi",
						"Lodi",
						"Lomazzo",
						"Lonate Pozzolo",
						"Lonato",
						"Luino",
						"Lumezzane",
						"Lurate Caccivio",
						"Magenta",
						"Malnate",
						"Mandello del Lario",
						"Manerbio",
						"Mantova",
						"Mariano Comense",
						"Martinengo",
						"Mazzano",
						"Meda",
						"Mediglia",
						"Melegnano",
						"Melzo",
						"Merate",
						"Milano",
						"Molteno",
						"Montichiari",
						"Monza",
						"Morbegno",
						"Mornago",
						"Mortara",
						"Muggio",
						"Nave",
						"Nembro",
						"Nerviano",
						"Nova Milanese",
						"Novate Milanese",
						"Olgiate Comasco",
						"Olgiate Olona",
						"Opera",
						"Orzinuovi",
						"Osio Sotto",
						"Ospitaletto",
						"Paderno Dugnano",
						"Palazzolo sull'Oglio",
						"Pandino",
						"Parabiago",
						"Paullo",
						"Pavia",
						"Pero",
						"Peschiera Borromeo",
						"Pessano con Bornago",
						"Pieve Emanuele",
						"Pioltello",
						"Ponte Nossa",
						"Ponte San Pietro",
						"Porto Mantovano",
						"Pozzolengo",
						"Rescaldina",
						"Rezzato",
						"Rho",
						"Rivarolo Mantovano",
						"Rodano",
						"Romano di Lombardia",
						"Rovato",
						"Rozzano",
						"Saletto",
						"Salo",
						"Samarate",
						"San Donato Milanese",
						"San Giuliano Milanese",
						"Sant'Angelo Lodigiano",
						"Sarezzo",
						"Saronno",
						"Scanzorosciate",
						"Sedriano",
						"Segrate",
						"Senago",
						"Seregno",
						"Seriate",
						"Sesto Calende",
						"Sesto San Giovanni",
						"Settimo Milanese",
						"Seveso",
						"Sirmione",
						"Solaro",
						"Somma Lombardo",
						"Sondrio",
						"Soresina",
						"Sorisole",
						"Stezzano",
						"Stradella",
						"Suzzara",
						"Tirano",
						"Tornata",
						"Tradate",
						"Travagliato",
						"Treviglio",
						"Treviolo",
						"Trezzano sul Naviglio",
						"Trezzo",
						"Tromello",
						"Uboldo",
						"Urgnano",
						"Usmate Velate",
						"Valmadrera",
						"Varedo",
						"Varese",
						"Verano Brianza",
						"Vergiate",
						"Viadana",
						"Vigevano",
						"Vignate",
						"Villa Carcina",
						"Villa Guardia",
						"Villasanta",
						"Vimercate",
						"Vimodrone",
						"Virgilio",
						"Voghera",
						"Zibido San Giacomo",
						"Zogno",
					],
					stateName: "Lombardia",
				},
				{
					cities: [
						"Barasso",
						"Bergamo",
						"Bolladello",
						"Capergnanica",
						"Costa Masnaga",
						"Medolago",
						"Nibionno",
						"Rodano",
						"Sordio",
						"Torre d'Isola",
						"Varese",
						"Villongo",
						"milan",
					],
					stateName: "Lombardy",
				},
				{ cities: ["Colmurano", "Monte San Giusto"], stateName: "Macerata" },
				{
					cities: [
						"Castel",
						"Gazoldo",
						"Marmirolo",
						"Monzambano",
						"Ostiglia",
						"Pegognaga",
						"Poggio Rusco",
						"Quistello",
						"Roverbella",
						"Suzzara",
					],
					stateName: "Mantova",
				},
				{
					cities: [
						"Ancona",
						"Ascoli Piceno",
						"Barchi",
						"Cagli",
						"Castelfidardo",
						"Chiaravalle",
						"Cingoli",
						"Civitanova Marche",
						"Corridonia",
						"Fabriano",
						"Falconara Marittima",
						"Fano",
						"Fermo",
						"Filottrano",
						"Folignano",
						"Fossombrone",
						"Grottammare",
						"Jesi",
						"Loreto",
						"Macerata",
						"Matelica",
						"Mondavio",
						"Mondolfo",
						"Montappone",
						"Montecosaro",
						"Montegranaro",
						"Montemarciano",
						"Monteprandone",
						"Morrovalle",
						"Osimo",
						"Pesaro",
						"Polverigi",
						"Porto Recanati",
						"Porto San Giorgio",
						"Porto Sant'Elpidio",
						"Potenza Picena",
						"Recanati",
						"San Benedetto del Tronto",
						"San Severino Marche",
						"Sant'Elpidio a Mare",
						"Senigallia",
						"Tolentino",
						"Treia",
						"Urbino",
					],
					stateName: "Marche",
				},
				{ cities: ["Cumiana", "Giammoro"], stateName: "Messina" },
				{
					cities: [
						"Assago",
						"Besana in Brianza",
						"Biassono",
						"Burago Molgora",
						"Cernusco Sul Naviglio",
						"Cologno Monzese",
						"Concorezzo",
						"Cornaredo",
						"Cuggiono",
						"Cusago",
						"Foro Buonaparte",
						"Gessate",
						"Gorgonzola",
						"Liscate",
						"Magenta",
						"Milan",
						"Noviglio",
						"Passirana Di Rho",
						"Pregnana Milane",
						"San Donato Milanese",
						"Seregno",
						"Trezzo Sull'adda",
						"Tribiano",
						"Vaprio d'Adda",
						"Vermezzo",
					],
					stateName: "Milan",
				},
				{
					cities: [
						"Bomporto",
						"Campogalliano",
						"Cavezzo",
						"Medolla",
						"Nonantola",
						"San Possidonio",
						"Sassuolo",
						"Spilamberto",
					],
					stateName: "Modena",
				},
				{
					cities: [
						"Bojano",
						"Campobasso",
						"Cantalupo",
						"Isernia",
						"Termoli",
						"Venafro",
					],
					stateName: "Molise",
				},
				{ cities: [], stateName: "Molteno" },
				{ cities: ["Montenegro"], stateName: "Montenegro" },
				{ cities: [], stateName: "Monza and Brianza" },
				{ cities: ["Forio d'Ischia"], stateName: "Naples" },
				{ cities: ["Bogogno", "Invorio", "Pombia"], stateName: "Novara" },
				{
					cities: [
						"Bagnoli di Sopra",
						"Bovolenta",
						"Casale Di Scodosia",
						"Cervarese Santa Croce",
						"Fontaniva",
						"Galliera Veneta",
						"Legnaro",
						"Limena",
						"Loreggia",
						"Massanzago",
						"Onara",
						"Ponso",
						"Portogallo",
						"Tribano",
					],
					stateName: "Padova",
				},
				{
					cities: [
						"Baganzola",
						"Busseto",
						"Casale Di Mezzani",
						"Fontevivo",
						"Solignano",
						"Torrile",
					],
					stateName: "Parma",
				},
				{
					cities: ["Codevilla", "Marcignago", "Pavia", "Siziano"],
					stateName: "Pavia",
				},
				{
					cities: ["Pianello", "Ponte Felcino", "Zanica"],
					stateName: "Perugia",
				},
				{
					cities: ["Gradara", "Monte Porzio", "Pergola", "Tavullia"],
					stateName: "Pesaro-Urbino",
				},
				{
					cities: ["Alseno", "Gossolengo", "Vigolzone"],
					stateName: "Piacenza",
				},
				{
					cities: [
						"Armeno",
						"Bergamasco",
						"Caselette",
						"Rosta",
						"San Damiano",
						"Spinetta Marengo",
					],
					stateName: "Piedmont",
				},
				{
					cities: [
						"Acqui Terme",
						"Alba",
						"Alessandria",
						"Alpignano",
						"Andezeno",
						"Andonno",
						"Arona",
						"Asti",
						"Avigliana",
						"Baveno",
						"Beinasco",
						"Bellinzago Novarese",
						"Biella",
						"Borgaro Torinese",
						"Borgo San Dalmazzo",
						"Borgomanero",
						"Borgosesia",
						"Boves",
						"Bra",
						"Busca",
						"Cameri",
						"Canelli",
						"Carignano",
						"Carmagnola",
						"Casale Monferrato",
						"Caselle Torinese",
						"Castellamonte",
						"Castelletto sopra Ticino",
						"Chieri",
						"Chivasso",
						"Cirie",
						"Collegno",
						"Cossato",
						"Cuneo",
						"Cuorgne",
						"Domodossola",
						"Druento",
						"Fossano",
						"Galliate",
						"Gassino Torinese",
						"Gattinara",
						"Giaveno",
						"Grugliasco",
						"Ivrea",
						"Leini",
						"LusigliÃÆÃÂ¨",
						"Marano Ticino",
						"Mergozzo",
						"Moncalieri",
						"Mondovi",
						"Mongrando",
						"Nichelino",
						"Nizza Monferrato",
						"Novara",
						"Novi Ligure",
						"Oleggio",
						"Omegna",
						"Orbassano",
						"Ovada",
						"Pianezza",
						"Pinerolo",
						"Pino Torinese",
						"Piossasco",
						"Poirino",
						"Racconigi",
						"Rivalta di Torino",
						"Rivarolo Canavese",
						"Rivoli",
						"Saluzzo",
						"San Maurizio",
						"San Mauro Torinese",
						"Sandigliano",
						"Santena",
						"Santhia",
						"Savigliano",
						"Settimo Torinese",
						"Torino",
						"Tortona",
						"Trecate",
						"Trofarello",
						"Valduggia",
						"Valenza",
						"Venaria Reale",
						"Verbania",
						"Vercelli",
						"Vigliano Biellese",
						"Vinovo",
						"Volpiano",
					],
					stateName: "Piemonte",
				},
				{ cities: ["Perignano", "Ponte a Egola"], stateName: "Pisa" },
				{ cities: ["San Quirino"], stateName: "Pordenone" },
				{ cities: ["Latronico"], stateName: "Potenza" },
				{
					cities: [
						"Acquaviva delle Fonti",
						"Adelfia",
						"Alberobello",
						"Altamura",
						"Andria",
						"Apricena",
						"Aradeo",
						"Bari",
						"Barletta",
						"Bisceglie",
						"Bitetto",
						"Bitonto",
						"Bitritto",
						"Brindisi",
						"Campi Salentina",
						"Canosa di Puglia",
						"Capurso",
						"Carmiano",
						"Carovigno",
						"Casamassima",
						"Casarano",
						"Cassano delle Murge",
						"Castellana Grotte",
						"Castellaneta",
						"Cavallino",
						"Ceglie Messapica",
						"Cerignola",
						"Cisternino",
						"Conversano",
						"Copertino",
						"Corato",
						"Crispiano",
						"Cutrofiano",
						"Erchie",
						"Fasano",
						"Foggia",
						"Francavilla Fontana",
						"Galatina",
						"Galatone",
						"Gallipoli",
						"Ginosa",
						"Gioia del Colle",
						"Giovinazzo",
						"Gravina in Puglia",
						"Grottaglie",
						"Grumo Appula",
						"Laterza",
						"Latiano",
						"Lecce",
						"Leverano",
						"Lizzanello",
						"Lizzano",
						"Locorotondo",
						"Lucera",
						"Maglie",
						"Manduria",
						"Manfredonia",
						"Margherita di Savoia",
						"Martano",
						"Martina Franca",
						"Massafra",
						"Matino",
						"Melendugno",
						"Mesagne",
						"Minervino Murge",
						"Modugno",
						"Mola di Bari",
						"Molfetta",
						"Monopoli",
						"Monte Sant'Angelo",
						"Monteroni di Lecce",
						"Mottola",
						"Nardo",
						"Neviano",
						"Noci",
						"Noicattaro",
						"Novoli",
						"Oria",
						"Orta Nova",
						"Ostuni",
						"Palagiano",
						"Palo del Colle",
						"Parabita",
						"Polignano a Mare",
						"Pulsano",
						"Putignano",
					],
					stateName: "Puglia",
				},
				{ cities: [], stateName: "Reggio Emilia" },
				{ cities: [], stateName: "Rimini" },
				{ cities: [], stateName: "Roma" },
				{ cities: [], stateName: "Salerno" },
				{ cities: [], stateName: "Sardegna" },
				{ cities: [], stateName: "Sassari" },
				{ cities: [], stateName: "Savona" },
				{ cities: [], stateName: "Sicilia" },
				{ cities: [], stateName: "Siena" },
				{ cities: [], stateName: "Sondrio" },
				{ cities: [], stateName: "South Tyrol" },
				{ cities: [], stateName: "Taranto" },
				{ cities: [], stateName: "Teramo" },
				{ cities: [], stateName: "Torino" },
				{ cities: [], stateName: "Toscana" },
				{ cities: [], stateName: "Trapani" },
				{ cities: [], stateName: "Trentino-Alto Adige" },
				{ cities: [], stateName: "Trento" },
				{ cities: [], stateName: "Treviso" },
				{ cities: [], stateName: "Udine" },
				{ cities: [], stateName: "Umbria" },
				{ cities: [], stateName: "Valle d'Aosta" },
				{ cities: [], stateName: "Varese" },
				{ cities: [], stateName: "Veneto" },
				{ cities: [], stateName: "Venezia" },
				{ cities: [], stateName: "Verbano-Cusio-Ossola" },
				{ cities: [], stateName: "Vercelli" },
				{ cities: [], stateName: "Verona" },
				{ cities: [], stateName: "Vicenza" },
				{ cities: [], stateName: "Viterbo" },
			],
			countryName: "Italy",
		},
		{
			states: [
				{ cities: [], stateName: "Buxoro Viloyati" },
				{ cities: [], stateName: "Clarendon" },
				{ cities: [], stateName: "Hanover" },
				{ cities: [], stateName: "Kingston" },
				{ cities: [], stateName: "Manchester" },
				{ cities: [], stateName: "Portland" },
				{ cities: [], stateName: "Saint Andrews" },
				{ cities: [], stateName: "Saint Ann" },
				{ cities: [], stateName: "Saint Catherine" },
				{ cities: [], stateName: "Saint Elizabeth" },
				{ cities: [], stateName: "Saint James" },
				{ cities: [], stateName: "Saint Mary" },
				{ cities: [], stateName: "Saint Thomas" },
				{ cities: [], stateName: "Trelawney" },
				{ cities: [], stateName: "Westmoreland" },
			],
			countryName: "Jamaica",
		},
		{
			states: [
				{ cities: [], stateName: "Aichi" },
				{ cities: [], stateName: "Akita" },
				{ cities: [], stateName: "Aomori" },
				{ cities: [], stateName: "Chiba" },
				{ cities: [], stateName: "Ehime" },
				{ cities: [], stateName: "Fukui" },
				{ cities: [], stateName: "Fukuoka" },
				{ cities: [], stateName: "Fukushima" },
				{ cities: [], stateName: "Gifu" },
				{ cities: [], stateName: "Gumma" },
				{ cities: [], stateName: "Hiroshima" },
				{ cities: [], stateName: "Hokkaido" },
				{ cities: [], stateName: "Hyogo" },
				{ cities: [], stateName: "Ibaraki" },
				{ cities: [], stateName: "Ishikawa" },
				{ cities: [], stateName: "Iwate" },
				{ cities: [], stateName: "Kagawa" },
				{ cities: [], stateName: "Kagoshima" },
				{ cities: [], stateName: "Kanagawa" },
				{ cities: [], stateName: "Kanto" },
				{ cities: [], stateName: "Kochi" },
				{ cities: [], stateName: "Kumamoto" },
				{ cities: [], stateName: "Kyoto" },
				{ cities: [], stateName: "Mie" },
				{ cities: [], stateName: "Miyagi" },
				{ cities: [], stateName: "Miyazaki" },
				{ cities: [], stateName: "Nagano" },
				{ cities: [], stateName: "Nagasaki" },
				{ cities: [], stateName: "Nara" },
				{ cities: [], stateName: "Niigata" },
				{ cities: [], stateName: "Oita" },
				{ cities: [], stateName: "Okayama" },
				{ cities: [], stateName: "Okinawa" },
				{ cities: [], stateName: "Osaka" },
				{ cities: [], stateName: "Saga" },
				{ cities: [], stateName: "Saitama" },
				{ cities: [], stateName: "Shiga" },
				{ cities: [], stateName: "Shimane" },
				{ cities: [], stateName: "Shizuoka" },
				{ cities: [], stateName: "Tochigi" },
				{ cities: [], stateName: "Tokushima" },
				{ cities: [], stateName: "Tokyo" },
				{ cities: [], stateName: "Tottori" },
				{ cities: [], stateName: "Toyama" },
				{ cities: [], stateName: "Wakayama" },
				{ cities: [], stateName: "Yamagata" },
				{ cities: [], stateName: "Yamaguchi" },
				{ cities: [], stateName: "Yamanashi" },
			],
			countryName: "Japan",
		},
		{
			states: [
				{ cities: [], stateName: "Grouville" },
				{ cities: [], stateName: "Saint Brelade" },
				{ cities: [], stateName: "Saint Clement" },
				{ cities: [], stateName: "Saint Helier" },
				{ cities: [], stateName: "Saint John" },
				{ cities: [], stateName: "Saint Lawrence" },
				{ cities: [], stateName: "Saint Martin" },
				{ cities: [], stateName: "Saint Mary" },
				{ cities: [], stateName: "Saint Peter" },
				{ cities: [], stateName: "Saint Saviour" },
				{ cities: [], stateName: "Trinity" },
			],
			countryName: "Jersey",
		},
		{
			states: [
				{ cities: [], stateName: "'Ajlun" },
				{ cities: [], stateName: "Amman" },
				{ cities: [], stateName: "Irbid" },
				{ cities: [], stateName: "Jarash" },
				{ cities: [], stateName: "Ma'an" },
				{ cities: [], stateName: "Madaba" },
				{ cities: [], stateName: "al-'Aqabah" },
				{ cities: [], stateName: "al-Balqa'" },
				{ cities: [], stateName: "al-Karak" },
				{ cities: [], stateName: "al-Mafraq" },
				{ cities: [], stateName: "at-Tafilah" },
				{ cities: [], stateName: "az-Zarqa'" },
			],
			countryName: "Jordan",
		},
		{
			states: [
				{ cities: [], stateName: "Akmecet" },
				{ cities: [], stateName: "Akmola" },
				{ cities: [], stateName: "Aktobe" },
				{ cities: [], stateName: "Almati" },
				{ cities: [], stateName: "Atirau" },
				{ cities: [], stateName: "Batis Kazakstan" },
				{ cities: [], stateName: "Burlinsky Region" },
				{ cities: [], stateName: "Karagandi" },
				{ cities: [], stateName: "Kostanay" },
				{ cities: [], stateName: "Mankistau" },
				{ cities: [], stateName: "Ontustik Kazakstan" },
				{ cities: [], stateName: "Pavlodar" },
				{ cities: [], stateName: "Sigis Kazakstan" },
				{ cities: [], stateName: "Soltustik Kazakstan" },
				{ cities: [], stateName: "Taraz" },
			],
			countryName: "Kazakhstan",
		},
		{
			states: [
				{ cities: [], stateName: "Central" },
				{ cities: [], stateName: "Coast" },
				{ cities: [], stateName: "Eastern" },
				{ cities: [], stateName: "Nairobi" },
				{ cities: [], stateName: "North Eastern" },
				{ cities: [], stateName: "Nyanza" },
				{ cities: [], stateName: "Rift Valley" },
				{ cities: [], stateName: "Western" },
			],
			countryName: "Kenya",
		},
		{
			states: [
				{ cities: [], stateName: "Abaiang" },
				{ cities: [], stateName: "Abemana" },
				{ cities: [], stateName: "Aranuka" },
				{ cities: [], stateName: "Arorae" },
				{ cities: [], stateName: "Banaba" },
				{ cities: [], stateName: "Beru" },
				{ cities: [], stateName: "Butaritari" },
				{ cities: [], stateName: "Kiritimati" },
				{ cities: [], stateName: "Kuria" },
				{ cities: [], stateName: "Maiana" },
				{ cities: [], stateName: "Makin" },
				{ cities: [], stateName: "Marakei" },
				{ cities: [], stateName: "Nikunau" },
				{ cities: [], stateName: "Nonouti" },
				{ cities: [], stateName: "Onotoa" },
				{ cities: [], stateName: "Phoenix Islands" },
				{ cities: [], stateName: "Tabiteuea North" },
				{ cities: [], stateName: "Tabiteuea South" },
				{ cities: [], stateName: "Tabuaeran" },
				{ cities: [], stateName: "Tamana" },
				{ cities: [], stateName: "Tarawa North" },
				{ cities: [], stateName: "Tarawa South" },
				{ cities: [], stateName: "Teraina" },
			],
			countryName: "Kiribati",
		},
		{
			states: [
				{ cities: [], stateName: "Chagangdo" },
				{ cities: [], stateName: "Hamgyeongbukto" },
				{ cities: [], stateName: "Hamgyeongnamdo" },
				{ cities: [], stateName: "Hwanghaebukto" },
				{ cities: [], stateName: "Hwanghaenamdo" },
				{ cities: [], stateName: "Kaeseong" },
				{ cities: [], stateName: "Kangweon" },
				{ cities: [], stateName: "Nampo" },
				{ cities: [], stateName: "Pyeonganbukto" },
				{ cities: [], stateName: "Pyeongannamdo" },
				{ cities: [], stateName: "Pyeongyang" },
				{ cities: [], stateName: "Yanggang" },
			],
			countryName: "Korea North",
		},
		{
			states: [
				{ cities: [], stateName: "Busan" },
				{ cities: [], stateName: "Cheju" },
				{ cities: [], stateName: "Chollabuk" },
				{ cities: [], stateName: "Chollanam" },
				{ cities: [], stateName: "Chungbuk" },
				{ cities: [], stateName: "Chungcheongbuk" },
				{ cities: [], stateName: "Chungcheongnam" },
				{ cities: [], stateName: "Chungnam" },
				{ cities: [], stateName: "Daegu" },
				{ cities: [], stateName: "Gangwon-do" },
				{ cities: [], stateName: "Goyang-si" },
				{ cities: [], stateName: "Gyeonggi-do" },
				{ cities: [], stateName: "Gyeongsang " },
				{ cities: [], stateName: "Gyeongsangnam-do" },
				{ cities: [], stateName: "Incheon" },
				{ cities: [], stateName: "Jeju-Si" },
				{ cities: [], stateName: "Jeonbuk" },
				{ cities: [], stateName: "Kangweon" },
				{ cities: [], stateName: "Kwangju" },
				{ cities: [], stateName: "Kyeonggi" },
				{ cities: [], stateName: "Kyeongsangbuk" },
				{ cities: [], stateName: "Kyeongsangnam" },
				{ cities: [], stateName: "Kyonggi-do" },
				{ cities: [], stateName: "Kyungbuk-Do" },
				{ cities: [], stateName: "Kyunggi-Do" },
				{ cities: [], stateName: "Kyunggi-do" },
				{ cities: [], stateName: "Pusan" },
				{ cities: [], stateName: "Seoul" },
				{ cities: [], stateName: "Sudogwon" },
				{ cities: [], stateName: "Taegu" },
				{ cities: [], stateName: "Taejeon" },
				{ cities: [], stateName: "Taejon-gwangyoksi" },
				{ cities: [], stateName: "Ulsan" },
				{ cities: [], stateName: "Wonju" },
				{ cities: [], stateName: "gwangyoksi" },
			],
			countryName: "Korea South",
		},
		{
			states: [
				{ cities: [], stateName: "Al Asimah" },
				{ cities: [], stateName: "Hawalli" },
				{ cities: [], stateName: "Mishref" },
				{ cities: [], stateName: "Qadesiya" },
				{ cities: [], stateName: "Safat" },
				{ cities: [], stateName: "Salmiya" },
				{ cities: [], stateName: "al-Ahmadi" },
				{ cities: [], stateName: "al-Farwaniyah" },
				{ cities: [], stateName: "al-Jahra" },
				{ cities: [], stateName: "al-Kuwayt" },
			],
			countryName: "Kuwait",
		},
		{
			states: [
				{ cities: [], stateName: "Batken" },
				{ cities: [], stateName: "Bishkek" },
				{ cities: [], stateName: "Chui" },
				{ cities: [], stateName: "Issyk-Kul" },
				{ cities: [], stateName: "Jalal-Abad" },
				{ cities: [], stateName: "Naryn" },
				{ cities: [], stateName: "Osh" },
				{ cities: [], stateName: "Talas" },
			],
			countryName: "Kyrgyzstan",
		},
		{
			states: [
				{ cities: [], stateName: "Attopu" },
				{ cities: [], stateName: "Bokeo" },
				{ cities: [], stateName: "Bolikhamsay" },
				{ cities: [], stateName: "Champasak" },
				{ cities: [], stateName: "Houaphanh" },
				{ cities: [], stateName: "Khammouane" },
				{ cities: [], stateName: "Luang Nam Tha" },
				{ cities: [], stateName: "Luang Prabang" },
				{ cities: [], stateName: "Oudomxay" },
				{ cities: [], stateName: "Phongsaly" },
				{ cities: [], stateName: "Saravan" },
				{ cities: [], stateName: "Savannakhet" },
				{ cities: [], stateName: "Sekong" },
				{ cities: [], stateName: "Viangchan Prefecture" },
				{ cities: [], stateName: "Viangchan Province" },
				{ cities: [], stateName: "Xaignabury" },
				{ cities: [], stateName: "Xiang Khuang" },
			],
			countryName: "Laos",
		},
		{
			states: [
				{ cities: [], stateName: "Aizkraukles" },
				{ cities: [], stateName: "Aluksnes" },
				{ cities: [], stateName: "Balvu" },
				{ cities: [], stateName: "Bauskas" },
				{ cities: [], stateName: "Cesu" },
				{ cities: [], stateName: "Daugavpils" },
				{ cities: [], stateName: "Daugavpils City" },
				{ cities: [], stateName: "Dobeles" },
				{ cities: [], stateName: "Gulbenes" },
				{ cities: [], stateName: "Jekabspils" },
				{ cities: [], stateName: "Jelgava" },
				{ cities: [], stateName: "Jelgavas" },
				{ cities: [], stateName: "Jurmala City" },
				{ cities: [], stateName: "Kraslavas" },
				{ cities: [], stateName: "Kuldigas" },
				{ cities: [], stateName: "Liepaja" },
				{ cities: [], stateName: "Liepajas" },
				{ cities: [], stateName: "Limbazhu" },
				{ cities: [], stateName: "Ludzas" },
				{ cities: [], stateName: "Madonas" },
				{ cities: [], stateName: "Ogres" },
				{ cities: [], stateName: "Preilu" },
				{ cities: [], stateName: "Rezekne" },
				{ cities: [], stateName: "Rezeknes" },
				{ cities: [], stateName: "Riga" },
				{ cities: [], stateName: "Rigas" },
				{ cities: [], stateName: "Saldus" },
				{ cities: [], stateName: "Talsu" },
				{ cities: [], stateName: "Tukuma" },
				{ cities: [], stateName: "Valkas" },
				{ cities: [], stateName: "Valmieras" },
				{ cities: [], stateName: "Ventspils" },
				{ cities: [], stateName: "Ventspils City" },
			],
			countryName: "Latvia",
		},
		{
			states: [
				{ cities: [], stateName: "Beirut" },
				{ cities: [], stateName: "Jabal Lubnan" },
				{ cities: [], stateName: "Mohafazat Liban-Nord" },
				{ cities: [], stateName: "Mohafazat Mont-Liban" },
				{ cities: [], stateName: "Sidon" },
				{ cities: [], stateName: "al-Biqa" },
				{ cities: [], stateName: "al-Janub" },
				{ cities: [], stateName: "an-Nabatiyah" },
				{ cities: [], stateName: "ash-Shamal" },
			],
			countryName: "Lebanon",
		},
		{
			states: [
				{ cities: [], stateName: "Berea" },
				{ cities: [], stateName: "Butha-Buthe" },
				{ cities: [], stateName: "Leribe" },
				{ cities: [], stateName: "Mafeteng" },
				{ cities: [], stateName: "Maseru" },
				{ cities: [], stateName: "Mohale's Hoek" },
				{ cities: [], stateName: "Mokhotlong" },
				{ cities: [], stateName: "Qacha's Nek" },
				{ cities: [], stateName: "Quthing" },
				{ cities: [], stateName: "Thaba-Tseka" },
			],
			countryName: "Lesotho",
		},
		{
			states: [
				{ cities: [], stateName: "Bomi" },
				{ cities: [], stateName: "Bong" },
				{ cities: [], stateName: "Grand Bassa" },
				{ cities: [], stateName: "Grand Cape Mount" },
				{ cities: [], stateName: "Grand Gedeh" },
				{ cities: [], stateName: "Loffa" },
				{ cities: [], stateName: "Margibi" },
				{ cities: [], stateName: "Maryland and Grand Kru" },
				{ cities: [], stateName: "Montserrado" },
				{ cities: [], stateName: "Nimba" },
				{ cities: [], stateName: "Rivercess" },
				{ cities: [], stateName: "Sinoe" },
			],
			countryName: "Liberia",
		},
		{
			states: [
				{ cities: [], stateName: "Ajdabiya" },
				{ cities: [], stateName: "Banghazi" },
				{ cities: [], stateName: "Darnah" },
				{ cities: [], stateName: "Ghadamis" },
				{ cities: [], stateName: "Gharyan" },
				{ cities: [], stateName: "Misratah" },
				{ cities: [], stateName: "Murzuq" },
				{ cities: [], stateName: "Sabha" },
				{ cities: [], stateName: "Sawfajjin" },
				{ cities: [], stateName: "Surt" },
				{ cities: [], stateName: "Tarabulus" },
				{ cities: [], stateName: "Tarhunah" },
				{ cities: [], stateName: "Tripolitania" },
				{ cities: [], stateName: "Tubruq" },
				{ cities: [], stateName: "Yafran" },
				{ cities: [], stateName: "Zlitan" },
				{ cities: [], stateName: "al-'Aziziyah" },
				{ cities: [], stateName: "al-Fatih" },
				{ cities: [], stateName: "al-Jabal al Akhdar" },
				{ cities: [], stateName: "al-Jufrah" },
				{ cities: [], stateName: "al-Khums" },
				{ cities: [], stateName: "al-Kufrah" },
				{ cities: [], stateName: "an-Nuqat al-Khams" },
				{ cities: [], stateName: "ash-Shati'" },
				{ cities: [], stateName: "az-Zawiyah" },
			],
			countryName: "Libya",
		},
		{
			states: [
				{ cities: [], stateName: "Balzers" },
				{ cities: [], stateName: "Eschen" },
				{ cities: [], stateName: "Gamprin" },
				{ cities: [], stateName: "Mauren" },
				{ cities: [], stateName: "Planken" },
				{ cities: [], stateName: "Ruggell" },
				{ cities: [], stateName: "Schaan" },
				{ cities: [], stateName: "Schellenberg" },
				{ cities: [], stateName: "Triesen" },
				{ cities: [], stateName: "Triesenberg" },
				{ cities: [], stateName: "Vaduz" },
			],
			countryName: "Liechtenstein",
		},
		{
			states: [
				{ cities: [], stateName: "Alytaus" },
				{ cities: [], stateName: "Anyksciai" },
				{ cities: [], stateName: "Kauno" },
				{ cities: [], stateName: "Klaipedos" },
				{ cities: [], stateName: "Marijampoles" },
				{ cities: [], stateName: "Panevezhio" },
				{ cities: [], stateName: "Panevezys" },
				{ cities: [], stateName: "Shiauliu" },
				{ cities: [], stateName: "Taurages" },
				{ cities: [], stateName: "Telshiu" },
				{ cities: [], stateName: "Telsiai" },
				{ cities: [], stateName: "Utenos" },
				{ cities: [], stateName: "Vilniaus" },
			],
			countryName: "Lithuania",
		},
		{
			states: [
				{ cities: [], stateName: "Capellen" },
				{ cities: [], stateName: "Clervaux" },
				{ cities: [], stateName: "Diekirch" },
				{ cities: [], stateName: "Echternach" },
				{ cities: [], stateName: "Esch-sur-Alzette" },
				{ cities: [], stateName: "Grevenmacher" },
				{ cities: [], stateName: "Luxembourg" },
				{ cities: [], stateName: "Mersch" },
				{ cities: [], stateName: "Redange" },
				{ cities: [], stateName: "Remich" },
				{ cities: [], stateName: "Vianden" },
				{ cities: [], stateName: "Wiltz" },
			],
			countryName: "Luxembourg",
		},
		{
			states: [{ cities: [], stateName: "Macau" }],
			countryName: "Macau S.A.R.",
		},
		{
			states: [
				{ cities: [], stateName: "Berovo" },
				{ cities: [], stateName: "Bitola" },
				{ cities: [], stateName: "Brod" },
				{ cities: [], stateName: "Debar" },
				{ cities: [], stateName: "Delchevo" },
				{ cities: [], stateName: "Demir Hisar" },
				{ cities: [], stateName: "Gevgelija" },
				{ cities: [], stateName: "Gostivar" },
				{ cities: [], stateName: "Kavadarci" },
				{ cities: [], stateName: "Kichevo" },
				{ cities: [], stateName: "Kochani" },
				{ cities: [], stateName: "Kratovo" },
				{ cities: [], stateName: "Kriva Palanka" },
				{ cities: [], stateName: "Krushevo" },
				{ cities: [], stateName: "Kumanovo" },
				{ cities: [], stateName: "Negotino" },
				{ cities: [], stateName: "Ohrid" },
				{ cities: [], stateName: "Prilep" },
				{ cities: [], stateName: "Probishtip" },
				{ cities: [], stateName: "Radovish" },
				{ cities: [], stateName: "Resen" },
				{ cities: [], stateName: "Shtip" },
				{ cities: [], stateName: "Skopje" },
				{ cities: [], stateName: "Struga" },
				{ cities: [], stateName: "Strumica" },
				{ cities: [], stateName: "Sveti Nikole" },
				{ cities: [], stateName: "Tetovo" },
				{ cities: [], stateName: "Valandovo" },
				{ cities: [], stateName: "Veles" },
				{ cities: [], stateName: "Vinica" },
			],
			countryName: "Macedonia",
		},
		{
			states: [
				{ cities: [], stateName: "Antananarivo" },
				{ cities: [], stateName: "Antsiranana" },
				{ cities: [], stateName: "Fianarantsoa" },
				{ cities: [], stateName: "Mahajanga" },
				{ cities: [], stateName: "Toamasina" },
				{ cities: [], stateName: "Toliary" },
			],
			countryName: "Madagascar",
		},
		{
			states: [
				{ cities: [], stateName: "Balaka" },
				{ cities: [], stateName: "Blantyre City" },
				{ cities: [], stateName: "Chikwawa" },
				{ cities: [], stateName: "Chiradzulu" },
				{ cities: [], stateName: "Chitipa" },
				{ cities: [], stateName: "Dedza" },
				{ cities: [], stateName: "Dowa" },
				{ cities: [], stateName: "Karonga" },
				{ cities: [], stateName: "Kasungu" },
				{ cities: [], stateName: "Lilongwe City" },
				{ cities: [], stateName: "Machinga" },
				{ cities: [], stateName: "Mangochi" },
				{ cities: [], stateName: "Mchinji" },
				{ cities: [], stateName: "Mulanje" },
				{ cities: [], stateName: "Mwanza" },
				{ cities: [], stateName: "Mzimba" },
				{ cities: [], stateName: "Mzuzu City" },
				{ cities: [], stateName: "Nkhata Bay" },
				{ cities: [], stateName: "Nkhotakota" },
				{ cities: [], stateName: "Nsanje" },
				{ cities: [], stateName: "Ntcheu" },
				{ cities: [], stateName: "Ntchisi" },
				{ cities: [], stateName: "Phalombe" },
				{ cities: [], stateName: "Rumphi" },
				{ cities: [], stateName: "Salima" },
				{ cities: [], stateName: "Thyolo" },
				{ cities: [], stateName: "Zomba Municipality" },
			],
			countryName: "Malawi",
		},
		{
			states: [
				{ cities: [], stateName: "Johor" },
				{ cities: [], stateName: "Kedah" },
				{ cities: [], stateName: "Kelantan" },
				{ cities: [], stateName: "Kuala Lumpur" },
				{ cities: [], stateName: "Labuan" },
				{ cities: [], stateName: "Melaka" },
				{ cities: [], stateName: "Negeri Johor" },
				{ cities: [], stateName: "Negeri Sembilan" },
				{ cities: [], stateName: "Pahang" },
				{ cities: [], stateName: "Penang" },
				{ cities: [], stateName: "Perak" },
				{ cities: [], stateName: "Perlis" },
				{ cities: [], stateName: "Pulau Pinang" },
				{ cities: [], stateName: "Sabah" },
				{ cities: [], stateName: "Sarawak" },
				{ cities: [], stateName: "Selangor" },
				{ cities: [], stateName: "Sembilan" },
				{ cities: [], stateName: "Terengganu" },
			],
			countryName: "Malaysia",
		},
		{
			states: [
				{ cities: [], stateName: "Alif Alif" },
				{ cities: [], stateName: "Alif Dhaal" },
				{ cities: [], stateName: "Baa" },
				{ cities: [], stateName: "Dhaal" },
				{ cities: [], stateName: "Faaf" },
				{ cities: [], stateName: "Gaaf Alif" },
				{ cities: [], stateName: "Gaaf Dhaal" },
				{ cities: [], stateName: "Ghaviyani" },
				{ cities: [], stateName: "Haa Alif" },
				{ cities: [], stateName: "Haa Dhaal" },
				{ cities: [], stateName: "Kaaf" },
				{ cities: [], stateName: "Laam" },
				{ cities: [], stateName: "Lhaviyani" },
				{ cities: [], stateName: "Male" },
				{ cities: [], stateName: "Miim" },
				{ cities: [], stateName: "Nuun" },
				{ cities: [], stateName: "Raa" },
				{ cities: [], stateName: "Shaviyani" },
				{ cities: [], stateName: "Siin" },
				{ cities: [], stateName: "Thaa" },
				{ cities: [], stateName: "Vaav" },
			],
			countryName: "Maldives",
		},
		{
			states: [
				{ cities: [], stateName: "Bamako" },
				{ cities: [], stateName: "Gao" },
				{ cities: [], stateName: "Kayes" },
				{ cities: [], stateName: "Kidal" },
				{ cities: [], stateName: "Koulikoro" },
				{ cities: [], stateName: "Mopti" },
				{ cities: [], stateName: "Segou" },
				{ cities: [], stateName: "Sikasso" },
				{ cities: [], stateName: "Tombouctou" },
			],
			countryName: "Mali",
		},
		{
			states: [
				{ cities: [], stateName: "Gozo and Comino" },
				{ cities: [], stateName: "Inner Harbour" },
				{ cities: [], stateName: "Northern" },
				{ cities: [], stateName: "Outer Harbour" },
				{ cities: [], stateName: "South Eastern" },
				{ cities: [], stateName: "Valletta" },
				{ cities: [], stateName: "Western" },
			],
			countryName: "Malta",
		},
		{
			states: [
				{ cities: [], stateName: "Castletown" },
				{ cities: [], stateName: "Douglas" },
				{ cities: [], stateName: "Laxey" },
				{ cities: [], stateName: "Onchan" },
				{ cities: [], stateName: "Peel" },
				{ cities: [], stateName: "Port Erin" },
				{ cities: [], stateName: "Port Saint Mary" },
				{ cities: [], stateName: "Ramsey" },
			],
			countryName: "Man (Isle of)",
		},
		{
			states: [
				{ cities: [], stateName: "Ailinlaplap" },
				{ cities: [], stateName: "Ailuk" },
				{ cities: [], stateName: "Arno" },
				{ cities: [], stateName: "Aur" },
				{ cities: [], stateName: "Bikini" },
				{ cities: [], stateName: "Ebon" },
				{ cities: [], stateName: "Enewetak" },
				{ cities: [], stateName: "Jabat" },
				{ cities: [], stateName: "Jaluit" },
				{ cities: [], stateName: "Kili" },
				{ cities: [], stateName: "Kwajalein" },
				{ cities: [], stateName: "Lae" },
				{ cities: [], stateName: "Lib" },
				{ cities: [], stateName: "Likiep" },
				{ cities: [], stateName: "Majuro" },
				{ cities: [], stateName: "Maloelap" },
				{ cities: [], stateName: "Mejit" },
				{ cities: [], stateName: "Mili" },
				{ cities: [], stateName: "Namorik" },
				{ cities: [], stateName: "Namu" },
				{ cities: [], stateName: "Rongelap" },
				{ cities: [], stateName: "Ujae" },
				{ cities: [], stateName: "Utrik" },
				{ cities: [], stateName: "Wotho" },
				{ cities: [], stateName: "Wotje" },
			],
			countryName: "Marshall Islands",
		},
		{
			states: [
				{ cities: [], stateName: "Fort-de-France" },
				{ cities: [], stateName: "La Trinite" },
				{ cities: [], stateName: "Le Marin" },
				{ cities: [], stateName: "Saint-Pierre" },
			],
			countryName: "Martinique",
		},
		{
			states: [
				{ cities: [], stateName: "Adrar" },
				{ cities: [], stateName: "Assaba" },
				{ cities: [], stateName: "Brakna" },
				{ cities: [], stateName: "Dhakhlat Nawadibu" },
				{ cities: [], stateName: "Hudh-al-Gharbi" },
				{ cities: [], stateName: "Hudh-ash-Sharqi" },
				{ cities: [], stateName: "Inshiri" },
				{ cities: [], stateName: "Nawakshut" },
				{ cities: [], stateName: "Qidimagha" },
				{ cities: [], stateName: "Qurqul" },
				{ cities: [], stateName: "Taqant" },
				{ cities: [], stateName: "Tiris Zammur" },
				{ cities: [], stateName: "Trarza" },
			],
			countryName: "Mauritania",
		},
		{
			states: [
				{ cities: [], stateName: "Black River" },
				{ cities: [], stateName: "Eau Coulee" },
				{ cities: [], stateName: "Flacq" },
				{ cities: [], stateName: "Floreal" },
				{ cities: [], stateName: "Grand Port" },
				{ cities: [], stateName: "Moka" },
				{ cities: [], stateName: "Pamplempousses" },
				{ cities: [], stateName: "Plaines Wilhelm" },
				{ cities: [], stateName: "Port Louis" },
				{ cities: [], stateName: "Riviere du Rempart" },
				{ cities: [], stateName: "Rodrigues" },
				{ cities: [], stateName: "Rose Hill" },
				{ cities: [], stateName: "Savanne" },
			],
			countryName: "Mauritius",
		},
		{
			states: [
				{ cities: [], stateName: "Mayotte" },
				{ cities: [], stateName: "Pamanzi" },
			],
			countryName: "Mayotte",
		},
		{
			states: [
				{ cities: [], stateName: "Aguascalientes" },
				{ cities: [], stateName: "Baja California" },
				{ cities: [], stateName: "Baja California Sur" },
				{ cities: [], stateName: "Campeche" },
				{ cities: [], stateName: "Chiapas" },
				{ cities: [], stateName: "Chihuahua" },
				{ cities: [], stateName: "Coahuila" },
				{ cities: [], stateName: "Colima" },
				{ cities: [], stateName: "Distrito Federal" },
				{ cities: [], stateName: "Durango" },
				{ cities: [], stateName: "Estado de Mexico" },
				{ cities: [], stateName: "Guanajuato" },
				{ cities: [], stateName: "Guerrero" },
				{ cities: [], stateName: "Hidalgo" },
				{ cities: [], stateName: "Jalisco" },
				{ cities: [], stateName: "Mexico" },
				{ cities: [], stateName: "Michoacan" },
				{ cities: [], stateName: "Morelos" },
				{ cities: [], stateName: "Nayarit" },
				{ cities: [], stateName: "Nuevo Leon" },
				{ cities: [], stateName: "Oaxaca" },
				{ cities: [], stateName: "Puebla" },
				{ cities: [], stateName: "Queretaro" },
				{ cities: [], stateName: "Quintana Roo" },
				{ cities: [], stateName: "San Luis Potosi" },
				{ cities: [], stateName: "Sinaloa" },
				{ cities: [], stateName: "Sonora" },
				{ cities: [], stateName: "Tabasco" },
				{ cities: [], stateName: "Tamaulipas" },
				{ cities: [], stateName: "Tlaxcala" },
				{ cities: [], stateName: "Veracruz" },
				{ cities: [], stateName: "Yucatan" },
				{ cities: [], stateName: "Zacatecas" },
			],
			countryName: "Mexico",
		},
		{
			states: [
				{ cities: [], stateName: "Chuuk" },
				{ cities: [], stateName: "Kusaie" },
				{ cities: [], stateName: "Pohnpei" },
				{ cities: [], stateName: "Yap" },
			],
			countryName: "Micronesia",
		},
		{
			states: [
				{ cities: [], stateName: "Balti" },
				{ cities: [], stateName: "Cahul" },
				{ cities: [], stateName: "Chisinau" },
				{ cities: [], stateName: "Chisinau Oras" },
				{ cities: [], stateName: "Edinet" },
				{ cities: [], stateName: "Gagauzia" },
				{ cities: [], stateName: "Lapusna" },
				{ cities: [], stateName: "Orhei" },
				{ cities: [], stateName: "Soroca" },
				{ cities: [], stateName: "Taraclia" },
				{ cities: [], stateName: "Tighina" },
				{ cities: [], stateName: "Transnistria" },
				{ cities: [], stateName: "Ungheni" },
			],
			countryName: "Moldova",
		},
		{
			states: [
				{ cities: [], stateName: "Fontvieille" },
				{ cities: [], stateName: "La Condamine" },
				{ cities: [], stateName: "Monaco-Ville" },
				{ cities: [], stateName: "Monte Carlo" },
			],
			countryName: "Monaco",
		},
		{
			states: [
				{ cities: [], stateName: "Arhangaj" },
				{ cities: [], stateName: "Bajan-Olgij" },
				{ cities: [], stateName: "Bajanhongor" },
				{ cities: [], stateName: "Bulgan" },
				{ cities: [], stateName: "Darhan-Uul" },
				{ cities: [], stateName: "Dornod" },
				{ cities: [], stateName: "Dornogovi" },
				{ cities: [], stateName: "Dundgovi" },
				{ cities: [], stateName: "Govi-Altaj" },
				{ cities: [], stateName: "Govisumber" },
				{ cities: [], stateName: "Hentij" },
				{ cities: [], stateName: "Hovd" },
				{ cities: [], stateName: "Hovsgol" },
				{ cities: [], stateName: "Omnogovi" },
				{ cities: [], stateName: "Orhon" },
				{ cities: [], stateName: "Ovorhangaj" },
				{ cities: [], stateName: "Selenge" },
				{ cities: [], stateName: "Suhbaatar" },
				{ cities: [], stateName: "Tov" },
				{ cities: [], stateName: "Ulaanbaatar" },
				{ cities: [], stateName: "Uvs" },
				{ cities: [], stateName: "Zavhan" },
			],
			countryName: "Mongolia",
		},
		{
			states: [{ cities: [], stateName: "Montserrat" }],
			countryName: "Montserrat",
		},
		{
			states: [
				{ cities: [], stateName: "Agadir" },
				{ cities: [], stateName: "Casablanca" },
				{ cities: [], stateName: "Chaouia-Ouardigha" },
				{ cities: [], stateName: "Doukkala-Abda" },
				{ cities: [], stateName: "Fes-Boulemane" },
				{ cities: [], stateName: "Gharb-Chrarda-Beni Hssen" },
				{ cities: [], stateName: "Guelmim" },
				{ cities: [], stateName: "Kenitra" },
				{ cities: [], stateName: "Marrakech-Tensift-Al Haouz" },
				{ cities: [], stateName: "Meknes-Tafilalet" },
				{ cities: [], stateName: "Oriental" },
				{ cities: [], stateName: "Oujda" },
				{ cities: [], stateName: "Province de Tanger" },
				{ cities: [], stateName: "Rabat-Sale-Zammour-Zaer" },
				{ cities: [], stateName: "Sala Al Jadida" },
				{ cities: [], stateName: "Settat" },
				{ cities: [], stateName: "Souss Massa-Draa" },
				{ cities: [], stateName: "Tadla-Azilal" },
				{ cities: [], stateName: "Tangier-Tetouan" },
				{ cities: [], stateName: "Taza-Al Hoceima-Taounate" },
				{ cities: [], stateName: "Wilaya de Casablanca" },
				{ cities: [], stateName: "Wilaya de Rabat-Sale" },
			],
			countryName: "Morocco",
		},
		{
			states: [
				{ cities: [], stateName: "Cabo Delgado" },
				{ cities: [], stateName: "Gaza" },
				{ cities: [], stateName: "Inhambane" },
				{ cities: [], stateName: "Manica" },
				{ cities: [], stateName: "Maputo" },
				{ cities: [], stateName: "Maputo Provincia" },
				{ cities: [], stateName: "Nampula" },
				{ cities: [], stateName: "Niassa" },
				{ cities: [], stateName: "Sofala" },
				{ cities: [], stateName: "Tete" },
				{ cities: [], stateName: "Zambezia" },
			],
			countryName: "Mozambique",
		},
		{
			states: [
				{ cities: [], stateName: "Ayeyarwady" },
				{ cities: [], stateName: "Bago" },
				{ cities: [], stateName: "Chin" },
				{ cities: [], stateName: "Kachin" },
				{ cities: [], stateName: "Kayah" },
				{ cities: [], stateName: "Kayin" },
				{ cities: [], stateName: "Magway" },
				{ cities: [], stateName: "Mandalay" },
				{ cities: [], stateName: "Mon" },
				{ cities: [], stateName: "Nay Pyi Taw" },
				{ cities: [], stateName: "Rakhine" },
				{ cities: [], stateName: "Sagaing" },
				{ cities: [], stateName: "Shan" },
				{ cities: [], stateName: "Tanintharyi" },
				{ cities: [], stateName: "Yangon" },
			],
			countryName: "Myanmar",
		},
		{
			states: [
				{ cities: [], stateName: "Caprivi" },
				{ cities: [], stateName: "Erongo" },
				{ cities: [], stateName: "Hardap" },
				{ cities: [], stateName: "Karas" },
				{ cities: [], stateName: "Kavango" },
				{ cities: [], stateName: "Khomas" },
				{ cities: [], stateName: "Kunene" },
				{ cities: [], stateName: "Ohangwena" },
				{ cities: [], stateName: "Omaheke" },
				{ cities: [], stateName: "Omusati" },
				{ cities: [], stateName: "Oshana" },
				{ cities: [], stateName: "Oshikoto" },
				{ cities: [], stateName: "Otjozondjupa" },
			],
			countryName: "Namibia",
		},
		{ states: [{ cities: [], stateName: "Yaren" }], countryName: "Nauru" },
		{
			states: [
				{ cities: [], stateName: "Bagmati" },
				{ cities: [], stateName: "Bheri" },
				{ cities: [], stateName: "Dhawalagiri" },
				{ cities: [], stateName: "Gandaki" },
				{ cities: [], stateName: "Janakpur" },
				{ cities: [], stateName: "Karnali" },
				{ cities: [], stateName: "Koshi" },
				{ cities: [], stateName: "Lumbini" },
				{ cities: [], stateName: "Mahakali" },
				{ cities: [], stateName: "Mechi" },
				{ cities: [], stateName: "Narayani" },
				{ cities: [], stateName: "Rapti" },
				{ cities: [], stateName: "Sagarmatha" },
				{ cities: [], stateName: "Seti" },
			],
			countryName: "Nepal",
		},
		{
			states: [
				{ cities: [], stateName: "Bonaire" },
				{ cities: [], stateName: "Curacao" },
				{ cities: [], stateName: "Saba" },
				{ cities: [], stateName: "Sint Eustatius" },
				{ cities: [], stateName: "Sint Maarten" },
			],
			countryName: "Netherlands Antilles",
		},
		{
			states: [
				{ cities: [], stateName: "Amsterdam" },
				{ cities: [], stateName: "Benelux" },
				{ cities: [], stateName: "Drenthe" },
				{ cities: [], stateName: "Flevoland" },
				{ cities: [], stateName: "Friesland" },
				{ cities: [], stateName: "Gelderland" },
				{ cities: [], stateName: "Groningen" },
				{ cities: [], stateName: "Limburg" },
				{ cities: [], stateName: "Noord-Brabant" },
				{ cities: [], stateName: "Noord-Holland" },
				{ cities: [], stateName: "Overijssel" },
				{ cities: [], stateName: "South Holland" },
				{ cities: [], stateName: "Utrecht" },
				{ cities: [], stateName: "Zeeland" },
				{ cities: [], stateName: "Zuid-Holland" },
			],
			countryName: "Netherlands The",
		},
		{
			states: [
				{ cities: [], stateName: "Iles" },
				{ cities: [], stateName: "Nord" },
				{ cities: [], stateName: "Sud" },
			],
			countryName: "New Caledonia",
		},
		{
			states: [
				{ cities: [], stateName: "Area Outside Region" },
				{ cities: [], stateName: "Auckland" },
				{ cities: [], stateName: "Bay of Plenty" },
				{ cities: [], stateName: "Canterbury" },
				{ cities: [], stateName: "Christchurch" },
				{ cities: [], stateName: "Gisborne" },
				{ cities: [], stateName: "Hawke's Bay" },
				{ cities: [], stateName: "Manawatu-Wanganui" },
				{ cities: [], stateName: "Marlborough" },
				{ cities: [], stateName: "Nelson" },
				{ cities: [], stateName: "Northland" },
				{ cities: [], stateName: "Otago" },
				{ cities: [], stateName: "Rodney" },
				{ cities: [], stateName: "Southland" },
				{ cities: [], stateName: "Taranaki" },
				{ cities: [], stateName: "Tasman" },
				{ cities: [], stateName: "Waikato" },
				{ cities: [], stateName: "Wellington" },
				{ cities: [], stateName: "West Coast" },
			],
			countryName: "New Zealand",
		},
		{
			states: [
				{ cities: [], stateName: "Atlantico Norte" },
				{ cities: [], stateName: "Atlantico Sur" },
				{ cities: [], stateName: "Boaco" },
				{ cities: [], stateName: "Carazo" },
				{ cities: [], stateName: "Chinandega" },
				{ cities: [], stateName: "Chontales" },
				{ cities: [], stateName: "Esteli" },
				{ cities: [], stateName: "Granada" },
				{ cities: [], stateName: "Jinotega" },
				{ cities: [], stateName: "Leon" },
				{ cities: [], stateName: "Madriz" },
				{ cities: [], stateName: "Managua" },
				{ cities: [], stateName: "Masaya" },
				{ cities: [], stateName: "Matagalpa" },
				{ cities: [], stateName: "Nueva Segovia" },
				{ cities: [], stateName: "Rio San Juan" },
				{ cities: [], stateName: "Rivas" },
			],
			countryName: "Nicaragua",
		},
		{
			states: [
				{ cities: [], stateName: "Agadez" },
				{ cities: [], stateName: "Diffa" },
				{ cities: [], stateName: "Dosso" },
				{ cities: [], stateName: "Maradi" },
				{ cities: [], stateName: "Niamey" },
				{ cities: [], stateName: "Tahoua" },
				{ cities: [], stateName: "Tillabery" },
				{ cities: [], stateName: "Zinder" },
			],
			countryName: "Niger",
		},
		{
			states: [
				{ cities: [], stateName: "Abia" },
				{ cities: [], stateName: "Abuja Federal Capital Territor" },
				{ cities: [], stateName: "Adamawa" },
				{ cities: [], stateName: "Akwa Ibom" },
				{ cities: [], stateName: "Anambra" },
				{ cities: [], stateName: "Bauchi" },
				{ cities: [], stateName: "Bayelsa" },
				{ cities: [], stateName: "Benue" },
				{ cities: [], stateName: "Borno" },
				{ cities: [], stateName: "Cross River" },
				{ cities: [], stateName: "Delta" },
				{ cities: [], stateName: "Ebonyi" },
				{ cities: [], stateName: "Edo" },
				{ cities: [], stateName: "Ekiti" },
				{ cities: [], stateName: "Enugu" },
				{ cities: [], stateName: "Gombe" },
				{ cities: [], stateName: "Imo" },
				{ cities: [], stateName: "Jigawa" },
				{ cities: [], stateName: "Kaduna" },
				{ cities: [], stateName: "Kano" },
				{ cities: [], stateName: "Katsina" },
				{ cities: [], stateName: "Kebbi" },
				{ cities: [], stateName: "Kogi" },
				{ cities: [], stateName: "Kwara" },
				{ cities: [], stateName: "Lagos" },
				{ cities: [], stateName: "Nassarawa" },
				{ cities: [], stateName: "Niger" },
				{ cities: [], stateName: "Ogun" },
				{ cities: [], stateName: "Ondo" },
				{ cities: [], stateName: "Osun" },
				{ cities: [], stateName: "Oyo" },
				{ cities: [], stateName: "Plateau" },
				{ cities: [], stateName: "Rivers" },
				{ cities: [], stateName: "Sokoto" },
				{ cities: [], stateName: "Taraba" },
				{ cities: [], stateName: "Yobe" },
				{ cities: [], stateName: "Zamfara" },
			],
			countryName: "Nigeria",
		},
		{ states: [{ cities: [], stateName: "Niue" }], countryName: "Niue" },
		{
			states: [{ cities: [], stateName: "Norfolk Island" }],
			countryName: "Norfolk Island",
		},
		{
			states: [
				{ cities: [], stateName: "Northern Islands" },
				{ cities: [], stateName: "Rota" },
				{ cities: [], stateName: "Saipan" },
				{ cities: [], stateName: "Tinian" },
			],
			countryName: "Northern Mariana Islands",
		},
		{
			states: [
				{ cities: [], stateName: "Akershus" },
				{ cities: [], stateName: "Aust Agder" },
				{ cities: [], stateName: "Bergen" },
				{ cities: [], stateName: "Buskerud" },
				{ cities: [], stateName: "Finnmark" },
				{ cities: [], stateName: "Hedmark" },
				{ cities: [], stateName: "Hordaland" },
				{ cities: [], stateName: "Moere og Romsdal" },
				{ cities: [], stateName: "Nord Trondelag" },
				{ cities: [], stateName: "Nordland" },
				{ cities: [], stateName: "Oestfold" },
				{ cities: [], stateName: "Oppland" },
				{ cities: [], stateName: "Oslo" },
				{ cities: [], stateName: "Rogaland" },
				{ cities: [], stateName: "Soer Troendelag" },
				{ cities: [], stateName: "Sogn og Fjordane" },
				{ cities: [], stateName: "Stavern" },
				{ cities: [], stateName: "Sykkylven" },
				{ cities: [], stateName: "Telemark" },
				{ cities: [], stateName: "Troms" },
				{ cities: [], stateName: "Vest Agder" },
				{ cities: [], stateName: "Vestfold" },
				{ cities: [], stateName: "ÃÆÃËstfold" },
			],
			countryName: "Norway",
		},
		{
			states: [
				{ cities: [], stateName: "Al Buraimi" },
				{ cities: [], stateName: "Dhufar" },
				{ cities: [], stateName: "Masqat" },
				{ cities: [], stateName: "Musandam" },
				{ cities: [], stateName: "Rusayl" },
				{ cities: [], stateName: "Wadi Kabir" },
				{ cities: [], stateName: "ad-Dakhiliyah" },
				{ cities: [], stateName: "adh-Dhahirah" },
				{ cities: [], stateName: "al-Batinah" },
				{ cities: [], stateName: "ash-Sharqiyah" },
			],
			countryName: "Oman",
		},
		{
			states: [
				{ cities: [], stateName: "Baluchistan" },
				{ cities: [], stateName: "Federal Capital Area" },
				{ cities: [], stateName: "Federally administered Tribal " },
				{ cities: [], stateName: "North-West Frontier" },
				{ cities: [], stateName: "Northern Areas" },
				{
					cities: [
						"Abohar",
						"Adampur",
						"Ahmedgarh",
						"Ajnala",
						"Akalgarh",
						"Alawalpur",
						"Amloh",
						"Amritsar",
						"Amritsar Cantonment",
						"Anandpur Sahib",
						"Badhni Kalan",
						"Bagh Purana",
						"Balachaur",
						"Banaur",
						"Banga",
						"Banur",
						"Baretta",
						"Bariwala",
						"Barnala",
						"Bassi Pathana",
						"Batala",
						"Bathinda",
						"Begowal",
						"Behrampur",
						"Bhabat",
						"Bhadur",
						"Bhankharpur",
						"Bharoli Kalan",
						"Bhawanigarh",
						"Bhikhi",
						"Bhikhiwind",
						"Bhisiana",
						"Bhogpur",
						"Bhuch",
						"Bhulath",
						"Budha Theh",
						"Budhlada",
						"Chima",
						"Chohal",
						"Dasuya",
						"Daulatpur",
						"Dera Baba Nanak",
						"Dera Bassi",
						"Dhanaula",
						"Dharam Kot",
						"Dhariwal",
						"Dhilwan",
						"Dhuri",
						"Dinanagar",
						"Dirba",
						"Doraha",
						"Faridkot",
						"Fateh Nangal",
						"Fatehgarh Churian",
						"Fatehgarh Sahib",
						"Fazilka",
						"Firozpur",
						"Firozpur Cantonment",
						"Gardhiwala",
						"Garhshankar",
						"Ghagga",
						"Ghanaur",
						"Giddarbaha",
						"Gobindgarh",
						"Goniana",
						"Goraya",
						"Gurdaspur",
						"Guru Har Sahai",
						"Hajipur",
						"Handiaya",
						"Hariana",
						"Hoshiarpur",
						"Hussainpur",
						"Jagraon",
						"Jaitu",
						"Jalalabad",
						"Jalandhar",
						"Jalandhar Cantonment",
						"Jandiala",
						"Jugial",
						"Kalanaur",
						"Kapurthala",
						"Karoran",
						"Kartarpur",
						"Khamanon",
						"Khanauri",
						"Khanna",
						"Kharar",
						"Khem Karan",
						"Kot Fatta",
						"Kot Isa Khan",
						"Kot Kapura",
						"Kotkapura",
						"Kurali",
						"Lalru",
						"Lehra Gaga",
						"Lodhian Khas",
						"Longowal",
						"Ludhiana",
						"Machhiwara",
						"Mahilpur",
						"Majitha",
						"Makhu",
						"Malaut",
						"Malerkotla",
						"Maloud",
						"Mandi Gobindgarh",
						"Mansa",
						"Maur",
						"Moga",
						"Mohali",
						"Moonak",
						"Morinda",
						"Mukerian",
						"Muktsar",
						"Mullanpur Dakha",
						"Mullanpur Garibdas",
						"Munak",
						"Muradpura",
						"Nabha",
						"Nakodar",
						"Nangal",
						"Nawashahr",
						"Naya Nangal",
						"Nehon",
						"Nurmahal",
						"Pathankot",
						"Patiala",
						"Patti",
						"Pattran",
						"Payal",
						"Phagwara",
						"Phillaur",
						"Qadian",
						"Rahon",
						"Raikot",
						"Raja Sansi",
						"Rajpura",
						"Ram Das",
						"Raman",
						"Rampura",
						"Rayya",
						"Rupnagar",
						"Rurki Kasba",
						"Sahnewal",
						"Samana",
						"Samrala",
						"Sanaur",
						"Sangat",
						"Sangrur",
						"Sansarpur",
						"Sardulgarh",
						"Shahkot",
						"Sham Churasi",
						"Shekhpura",
						"Sirhind",
						"Sri Hargobindpur",
						"Sujanpur",
						"Sultanpur Lodhi",
						"Sunam",
						"Talwandi Bhai",
						"Talwara",
						"Tappa",
						"Tarn Taran",
						"Urmar Tanda",
						"Zira",
						"Zirakpur",
					],
					stateName: "Punjab",
				},
				{ cities: [], stateName: "Sind" },
			],
			countryName: "Pakistan",
		},
		{
			states: [
				{ cities: [], stateName: "Aimeliik" },
				{ cities: [], stateName: "Airai" },
				{ cities: [], stateName: "Angaur" },
				{ cities: [], stateName: "Hatobohei" },
				{ cities: [], stateName: "Kayangel" },
				{ cities: [], stateName: "Koror" },
				{ cities: [], stateName: "Melekeok" },
				{ cities: [], stateName: "Ngaraard" },
				{ cities: [], stateName: "Ngardmau" },
				{ cities: [], stateName: "Ngaremlengui" },
				{ cities: [], stateName: "Ngatpang" },
				{ cities: [], stateName: "Ngchesar" },
				{ cities: [], stateName: "Ngerchelong" },
				{ cities: [], stateName: "Ngiwal" },
				{ cities: [], stateName: "Peleliu" },
				{ cities: [], stateName: "Sonsorol" },
			],
			countryName: "Palau",
		},
		{
			states: [
				{ cities: [], stateName: "Ariha" },
				{ cities: [], stateName: "Bayt Lahm" },
				{ cities: [], stateName: "Bethlehem" },
				{ cities: [], stateName: "Dayr-al-Balah" },
				{ cities: [], stateName: "Ghazzah" },
				{ cities: [], stateName: "Ghazzah ash-Shamaliyah" },
				{ cities: [], stateName: "Janin" },
				{ cities: [], stateName: "Khan Yunis" },
				{ cities: [], stateName: "Nabulus" },
				{ cities: [], stateName: "Qalqilyah" },
				{ cities: [], stateName: "Rafah" },
				{ cities: [], stateName: "Ram Allah wal-Birah" },
				{ cities: [], stateName: "Salfit" },
				{ cities: [], stateName: "Tubas" },
				{ cities: [], stateName: "Tulkarm" },
				{ cities: [], stateName: "al-Khalil" },
				{ cities: [], stateName: "al-Quds" },
			],
			countryName: "Palestinian Territory Occupied",
		},
		{
			states: [
				{ cities: [], stateName: "Bocas del Toro" },
				{ cities: [], stateName: "Chiriqui" },
				{ cities: [], stateName: "Cocle" },
				{ cities: [], stateName: "Colon" },
				{ cities: [], stateName: "Darien" },
				{ cities: [], stateName: "Embera" },
				{ cities: [], stateName: "Herrera" },
				{ cities: [], stateName: "Kuna Yala" },
				{ cities: [], stateName: "Los Santos" },
				{ cities: [], stateName: "Ngobe Bugle" },
				{ cities: [], stateName: "Panama" },
				{ cities: [], stateName: "Veraguas" },
			],
			countryName: "Panama",
		},
		{
			states: [
				{ cities: [], stateName: "East New Britain" },
				{ cities: [], stateName: "East Sepik" },
				{ cities: [], stateName: "Eastern Highlands" },
				{ cities: [], stateName: "Enga" },
				{ cities: [], stateName: "Fly River" },
				{ cities: [], stateName: "Gulf" },
				{ cities: [], stateName: "Madang" },
				{ cities: [], stateName: "Manus" },
				{ cities: [], stateName: "Milne Bay" },
				{ cities: [], stateName: "Morobe" },
				{ cities: [], stateName: "National Capital District" },
				{ cities: [], stateName: "New Ireland" },
				{ cities: [], stateName: "North Solomons" },
				{ cities: [], stateName: "Oro" },
				{ cities: [], stateName: "Sandaun" },
				{ cities: [], stateName: "Simbu" },
				{ cities: [], stateName: "Southern Highlands" },
				{ cities: [], stateName: "West New Britain" },
				{ cities: [], stateName: "Western Highlands" },
			],
			countryName: "Papua new Guinea",
		},
		{
			states: [
				{ cities: [], stateName: "Alto Paraguay" },
				{ cities: [], stateName: "Alto Parana" },
				{ cities: [], stateName: "Amambay" },
				{ cities: [], stateName: "Asuncion" },
				{ cities: [], stateName: "Boqueron" },
				{ cities: [], stateName: "Caaguazu" },
				{ cities: [], stateName: "Caazapa" },
				{ cities: [], stateName: "Canendiyu" },
				{ cities: [], stateName: "Central" },
				{ cities: [], stateName: "Concepcion" },
				{ cities: [], stateName: "Cordillera" },
				{ cities: [], stateName: "Guaira" },
				{ cities: [], stateName: "Itapua" },
				{ cities: [], stateName: "Misiones" },
				{ cities: [], stateName: "Neembucu" },
				{ cities: [], stateName: "Paraguari" },
				{ cities: [], stateName: "Presidente Hayes" },
				{ cities: [], stateName: "San Pedro" },
			],
			countryName: "Paraguay",
		},
		{
			states: [
				{ cities: [], stateName: "Amazonas" },
				{ cities: [], stateName: "Ancash" },
				{ cities: [], stateName: "Apurimac" },
				{ cities: [], stateName: "Arequipa" },
				{ cities: [], stateName: "Ayacucho" },
				{ cities: [], stateName: "Cajamarca" },
				{ cities: [], stateName: "Cusco" },
				{ cities: [], stateName: "Huancavelica" },
				{ cities: [], stateName: "Huanuco" },
				{ cities: [], stateName: "Ica" },
				{ cities: [], stateName: "Junin" },
				{ cities: [], stateName: "La Libertad" },
				{ cities: [], stateName: "Lambayeque" },
				{ cities: [], stateName: "Lima y Callao" },
				{ cities: [], stateName: "Loreto" },
				{ cities: [], stateName: "Madre de Dios" },
				{ cities: [], stateName: "Moquegua" },
				{ cities: [], stateName: "Pasco" },
				{ cities: [], stateName: "Piura" },
				{ cities: [], stateName: "Puno" },
				{ cities: [], stateName: "San Martin" },
				{ cities: [], stateName: "Tacna" },
				{ cities: [], stateName: "Tumbes" },
				{ cities: [], stateName: "Ucayali" },
			],
			countryName: "Peru",
		},
		{
			states: [
				{ cities: [], stateName: "Batangas" },
				{ cities: [], stateName: "Bicol" },
				{ cities: [], stateName: "Bulacan" },
				{ cities: [], stateName: "Cagayan" },
				{ cities: [], stateName: "Caraga" },
				{ cities: [], stateName: "Central Luzon" },
				{ cities: [], stateName: "Central Mindanao" },
				{ cities: [], stateName: "Central Visayas" },
				{ cities: [], stateName: "Cordillera" },
				{ cities: [], stateName: "Davao" },
				{ cities: [], stateName: "Eastern Visayas" },
				{ cities: [], stateName: "Greater Metropolitan Area" },
				{ cities: [], stateName: "Ilocos" },
				{ cities: [], stateName: "Laguna" },
				{ cities: [], stateName: "Luzon" },
				{ cities: [], stateName: "Mactan" },
				{ cities: [], stateName: "Metropolitan Manila Area" },
				{ cities: [], stateName: "Muslim Mindanao" },
				{ cities: [], stateName: "Northern Mindanao" },
				{ cities: [], stateName: "Southern Mindanao" },
				{ cities: [], stateName: "Southern Tagalog" },
				{ cities: [], stateName: "Western Mindanao" },
				{ cities: [], stateName: "Western Visayas" },
			],
			countryName: "Philippines",
		},
		{
			states: [{ cities: [], stateName: "Pitcairn Island" }],
			countryName: "Pitcairn Island",
		},
		{
			states: [
				{ cities: [], stateName: "Biale Blota" },
				{ cities: [], stateName: "Dobroszyce" },
				{ cities: [], stateName: "Dolnoslaskie" },
				{ cities: [], stateName: "Dziekanow Lesny" },
				{ cities: [], stateName: "Hopowo" },
				{ cities: [], stateName: "Kartuzy" },
				{ cities: [], stateName: "Koscian" },
				{ cities: [], stateName: "Krakow" },
				{ cities: [], stateName: "Kujawsko-Pomorskie" },
				{ cities: [], stateName: "Lodzkie" },
				{ cities: [], stateName: "Lubelskie" },
				{ cities: [], stateName: "Lubuskie" },
				{ cities: [], stateName: "Malomice" },
				{ cities: [], stateName: "Malopolskie" },
				{ cities: [], stateName: "Mazowieckie" },
				{ cities: [], stateName: "Mirkow" },
				{ cities: [], stateName: "Opolskie" },
				{ cities: [], stateName: "Ostrowiec" },
				{ cities: [], stateName: "Podkarpackie" },
				{ cities: [], stateName: "Podlaskie" },
				{ cities: [], stateName: "Polska" },
				{ cities: [], stateName: "Pomorskie" },
				{ cities: [], stateName: "Poznan" },
				{ cities: [], stateName: "Pruszkow" },
				{ cities: [], stateName: "Rymanowska" },
				{ cities: [], stateName: "Rzeszow" },
				{ cities: [], stateName: "Slaskie" },
				{ cities: [], stateName: "Stare Pole" },
				{ cities: [], stateName: "Swietokrzyskie" },
				{ cities: [], stateName: "Warminsko-Mazurskie" },
				{ cities: [], stateName: "Warsaw" },
				{ cities: [], stateName: "Wejherowo" },
				{ cities: [], stateName: "Wielkopolskie" },
				{ cities: [], stateName: "Wroclaw" },
				{ cities: [], stateName: "Zachodnio-Pomorskie" },
				{ cities: [], stateName: "Zukowo" },
			],
			countryName: "Poland",
		},
		{
			states: [
				{ cities: [], stateName: "Abrantes" },
				{ cities: [], stateName: "Acores" },
				{ cities: [], stateName: "Alentejo" },
				{ cities: [], stateName: "Algarve" },
				{ cities: [], stateName: "Braga" },
				{ cities: [], stateName: "Centro" },
				{ cities: [], stateName: "Distrito de Leiria" },
				{ cities: [], stateName: "Distrito de Viana do Castelo" },
				{ cities: [], stateName: "Distrito de Vila Real" },
				{ cities: [], stateName: "Distrito do Porto" },
				{ cities: [], stateName: "Lisboa e Vale do Tejo" },
				{ cities: [], stateName: "Madeira" },
				{ cities: [], stateName: "Norte" },
				{ cities: [], stateName: "Paivas" },
			],
			countryName: "Portugal",
		},
		{
			states: [
				{ cities: [], stateName: "Arecibo" },
				{ cities: [], stateName: "Bayamon" },
				{ cities: [], stateName: "Carolina" },
				{ cities: [], stateName: "Florida" },
				{ cities: [], stateName: "Guayama" },
				{ cities: [], stateName: "Humacao" },
				{ cities: [], stateName: "Mayaguez-Aguadilla" },
				{ cities: [], stateName: "Ponce" },
				{ cities: [], stateName: "Salinas" },
				{ cities: [], stateName: "San Juan" },
			],
			countryName: "Puerto Rico",
		},
		{
			states: [
				{ cities: [], stateName: "Doha" },
				{ cities: [], stateName: "Jarian-al-Batnah" },
				{ cities: [], stateName: "Umm Salal" },
				{ cities: [], stateName: "ad-Dawhah" },
				{ cities: [], stateName: "al-Ghuwayriyah" },
				{ cities: [], stateName: "al-Jumayliyah" },
				{ cities: [], stateName: "al-Khawr" },
				{ cities: [], stateName: "al-Wakrah" },
				{ cities: [], stateName: "ar-Rayyan" },
				{ cities: [], stateName: "ash-Shamal" },
			],
			countryName: "Qatar",
		},
		{
			states: [
				{ cities: [], stateName: "Saint-Benoit" },
				{ cities: [], stateName: "Saint-Denis" },
				{ cities: [], stateName: "Saint-Paul" },
				{ cities: [], stateName: "Saint-Pierre" },
			],
			countryName: "Reunion",
		},
		{
			states: [
				{ cities: [], stateName: "Alba" },
				{ cities: [], stateName: "Arad" },
				{ cities: [], stateName: "Arges" },
				{ cities: [], stateName: "Bacau" },
				{ cities: [], stateName: "Bihor" },
				{ cities: [], stateName: "Bistrita-Nasaud" },
				{ cities: [], stateName: "Botosani" },
				{ cities: [], stateName: "Braila" },
				{ cities: [], stateName: "Brasov" },
				{ cities: [], stateName: "Bucuresti" },
				{ cities: [], stateName: "Buzau" },
				{ cities: [], stateName: "Calarasi" },
				{ cities: [], stateName: "Caras-Severin" },
				{ cities: [], stateName: "Cluj" },
				{ cities: [], stateName: "Constanta" },
				{ cities: [], stateName: "Covasna" },
				{ cities: [], stateName: "Dambovita" },
				{ cities: [], stateName: "Dolj" },
				{ cities: [], stateName: "Galati" },
				{ cities: [], stateName: "Giurgiu" },
				{ cities: [], stateName: "Gorj" },
				{ cities: [], stateName: "Harghita" },
				{ cities: [], stateName: "Hunedoara" },
				{ cities: [], stateName: "Ialomita" },
				{ cities: [], stateName: "Iasi" },
				{ cities: [], stateName: "Ilfov" },
				{ cities: [], stateName: "Maramures" },
				{ cities: [], stateName: "Mehedinti" },
				{ cities: [], stateName: "Mures" },
				{ cities: [], stateName: "Neamt" },
				{ cities: [], stateName: "Olt" },
				{ cities: [], stateName: "Prahova" },
				{ cities: [], stateName: "Salaj" },
				{ cities: [], stateName: "Satu Mare" },
				{ cities: [], stateName: "Sibiu" },
				{ cities: [], stateName: "Sondelor" },
				{ cities: [], stateName: "Suceava" },
				{ cities: [], stateName: "Teleorman" },
				{ cities: [], stateName: "Timis" },
				{ cities: [], stateName: "Tulcea" },
				{ cities: [], stateName: "Valcea" },
				{ cities: [], stateName: "Vaslui" },
				{ cities: [], stateName: "Vrancea" },
			],
			countryName: "Romania",
		},
		{
			states: [
				{ cities: [], stateName: "Adygeja" },
				{ cities: [], stateName: "Aga" },
				{ cities: [], stateName: "Alanija" },
				{ cities: [], stateName: "Altaj" },
				{ cities: [], stateName: "Amur" },
				{ cities: [], stateName: "Arhangelsk" },
				{ cities: [], stateName: "Astrahan" },
				{ cities: [], stateName: "Bashkortostan" },
				{ cities: [], stateName: "Belgorod" },
				{ cities: [], stateName: "Brjansk" },
				{ cities: [], stateName: "Burjatija" },
				{ cities: [], stateName: "Chechenija" },
				{ cities: [], stateName: "Cheljabinsk" },
				{ cities: [], stateName: "Chita" },
				{ cities: [], stateName: "Chukotka" },
				{ cities: [], stateName: "Chuvashija" },
				{ cities: [], stateName: "Dagestan" },
				{ cities: [], stateName: "Evenkija" },
				{ cities: [], stateName: "Gorno-Altaj" },
				{ cities: [], stateName: "Habarovsk" },
				{ cities: [], stateName: "Hakasija" },
				{ cities: [], stateName: "Hanty-Mansija" },
				{ cities: [], stateName: "Ingusetija" },
				{ cities: [], stateName: "Irkutsk" },
				{ cities: [], stateName: "Ivanovo" },
				{ cities: [], stateName: "Jamalo-Nenets" },
				{ cities: [], stateName: "Jaroslavl" },
				{ cities: [], stateName: "Jevrej" },
				{ cities: [], stateName: "Kabardino-Balkarija" },
				{ cities: [], stateName: "Kaliningrad" },
				{ cities: [], stateName: "Kalmykija" },
				{ cities: [], stateName: "Kaluga" },
				{ cities: [], stateName: "Kamchatka" },
				{ cities: [], stateName: "Karachaj-Cherkessija" },
				{ cities: [], stateName: "Karelija" },
				{ cities: [], stateName: "Kemerovo" },
				{ cities: [], stateName: "Khabarovskiy Kray" },
				{ cities: [], stateName: "Kirov" },
				{ cities: [], stateName: "Komi" },
				{ cities: [], stateName: "Komi-Permjakija" },
				{ cities: [], stateName: "Korjakija" },
				{ cities: [], stateName: "Kostroma" },
				{ cities: [], stateName: "Krasnodar" },
				{ cities: [], stateName: "Krasnojarsk" },
				{ cities: [], stateName: "Krasnoyarskiy Kray" },
				{ cities: [], stateName: "Kurgan" },
				{ cities: [], stateName: "Kursk" },
				{ cities: [], stateName: "Leningrad" },
				{ cities: [], stateName: "Lipeck" },
				{ cities: [], stateName: "Magadan" },
				{ cities: [], stateName: "Marij El" },
				{ cities: [], stateName: "Mordovija" },
				{ cities: [], stateName: "Moscow" },
				{ cities: [], stateName: "Moskovskaja Oblast" },
				{ cities: [], stateName: "Moskovskaya Oblast" },
				{ cities: [], stateName: "Moskva" },
				{ cities: [], stateName: "Murmansk" },
				{ cities: [], stateName: "Nenets" },
				{ cities: [], stateName: "Nizhnij Novgorod" },
				{ cities: [], stateName: "Novgorod" },
				{ cities: [], stateName: "Novokusnezk" },
				{ cities: [], stateName: "Novosibirsk" },
				{ cities: [], stateName: "Omsk" },
				{ cities: [], stateName: "Orenburg" },
				{ cities: [], stateName: "Orjol" },
				{ cities: [], stateName: "Penza" },
				{ cities: [], stateName: "Perm" },
				{ cities: [], stateName: "Primorje" },
				{ cities: [], stateName: "Pskov" },
				{ cities: [], stateName: "Pskovskaya Oblast" },
				{ cities: [], stateName: "Rjazan" },
				{ cities: [], stateName: "Rostov" },
				{ cities: [], stateName: "Saha" },
				{ cities: [], stateName: "Sahalin" },
				{ cities: [], stateName: "Samara" },
				{ cities: [], stateName: "Samarskaya" },
				{ cities: [], stateName: "Sankt-Peterburg" },
				{ cities: [], stateName: "Saratov" },
				{ cities: [], stateName: "Smolensk" },
				{ cities: [], stateName: "Stavropol" },
				{ cities: [], stateName: "Sverdlovsk" },
				{ cities: [], stateName: "Tajmyrija" },
				{ cities: [], stateName: "Tambov" },
				{ cities: [], stateName: "Tatarstan" },
				{ cities: [], stateName: "Tjumen" },
				{ cities: [], stateName: "Tomsk" },
				{ cities: [], stateName: "Tula" },
				{ cities: [], stateName: "Tver" },
				{ cities: [], stateName: "Tyva" },
				{ cities: [], stateName: "Udmurtija" },
				{ cities: [], stateName: "Uljanovsk" },
				{ cities: [], stateName: "Ulyanovskaya Oblast" },
				{ cities: [], stateName: "Ust-Orda" },
				{ cities: [], stateName: "Vladimir" },
				{ cities: [], stateName: "Volgograd" },
				{ cities: [], stateName: "Vologda" },
				{ cities: [], stateName: "Voronezh" },
			],
			countryName: "Russia",
		},
		{
			states: [
				{ cities: [], stateName: "Butare" },
				{ cities: [], stateName: "Byumba" },
				{ cities: [], stateName: "Cyangugu" },
				{ cities: [], stateName: "Gikongoro" },
				{ cities: [], stateName: "Gisenyi" },
				{ cities: [], stateName: "Gitarama" },
				{ cities: [], stateName: "Kibungo" },
				{ cities: [], stateName: "Kibuye" },
				{ cities: [], stateName: "Kigali-ngali" },
				{ cities: [], stateName: "Ruhengeri" },
			],
			countryName: "Rwanda",
		},
		{
			states: [
				{ cities: [], stateName: "Ascension" },
				{ cities: [], stateName: "Gough Island" },
				{ cities: [], stateName: "Saint Helena" },
				{ cities: [], stateName: "Tristan da Cunha" },
			],
			countryName: "Saint Helena",
		},
		{
			states: [
				{ cities: [], stateName: "Christ Church Nichola Town" },
				{ cities: [], stateName: "Saint Anne Sandy Point" },
				{ cities: [], stateName: "Saint George Basseterre" },
				{ cities: [], stateName: "Saint George Gingerland" },
				{ cities: [], stateName: "Saint James Windward" },
				{ cities: [], stateName: "Saint John Capesterre" },
				{ cities: [], stateName: "Saint John Figtree" },
				{ cities: [], stateName: "Saint Mary Cayon" },
				{ cities: [], stateName: "Saint Paul Capesterre" },
				{ cities: [], stateName: "Saint Paul Charlestown" },
				{ cities: [], stateName: "Saint Peter Basseterre" },
				{ cities: [], stateName: "Saint Thomas Lowland" },
				{ cities: [], stateName: "Saint Thomas Middle Island" },
				{ cities: [], stateName: "Trinity Palmetto Point" },
			],
			countryName: "Saint Kitts And Nevis",
		},
		{
			states: [
				{ cities: [], stateName: "Anse-la-Raye" },
				{ cities: [], stateName: "Canaries" },
				{ cities: [], stateName: "Castries" },
				{ cities: [], stateName: "Choiseul" },
				{ cities: [], stateName: "Dennery" },
				{ cities: [], stateName: "Gros Inlet" },
				{ cities: [], stateName: "Laborie" },
				{ cities: [], stateName: "Micoud" },
				{ cities: [], stateName: "Soufriere" },
				{ cities: [], stateName: "Vieux Fort" },
			],
			countryName: "Saint Lucia",
		},
		{
			states: [
				{ cities: [], stateName: "Miquelon-Langlade" },
				{ cities: [], stateName: "Saint-Pierre" },
			],
			countryName: "Saint Pierre and Miquelon",
		},
		{
			states: [
				{ cities: [], stateName: "Charlotte" },
				{ cities: [], stateName: "Grenadines" },
				{ cities: [], stateName: "Saint Andrew" },
				{ cities: [], stateName: "Saint David" },
				{ cities: [], stateName: "Saint George" },
				{ cities: [], stateName: "Saint Patrick" },
			],
			countryName: "Saint Vincent And The Grenadines",
		},
		{
			states: [
				{ cities: [], stateName: "A'ana" },
				{ cities: [], stateName: "Aiga-i-le-Tai" },
				{ cities: [], stateName: "Atua" },
				{ cities: [], stateName: "Fa'asaleleaga" },
				{ cities: [], stateName: "Gaga'emauga" },
				{ cities: [], stateName: "Gagaifomauga" },
				{ cities: [], stateName: "Palauli" },
				{ cities: [], stateName: "Satupa'itea" },
				{ cities: [], stateName: "Tuamasaga" },
				{ cities: [], stateName: "Va'a-o-Fonoti" },
				{ cities: [], stateName: "Vaisigano" },
			],
			countryName: "Samoa",
		},
		{
			states: [
				{ cities: [], stateName: "Acquaviva" },
				{ cities: [], stateName: "Borgo Maggiore" },
				{ cities: [], stateName: "Chiesanuova" },
				{ cities: [], stateName: "Domagnano" },
				{ cities: [], stateName: "Faetano" },
				{ cities: [], stateName: "Fiorentino" },
				{ cities: [], stateName: "Montegiardino" },
				{ cities: [], stateName: "San Marino" },
				{ cities: [], stateName: "Serravalle" },
			],
			countryName: "San Marino",
		},
		{
			states: [
				{ cities: [], stateName: "Agua Grande" },
				{ cities: [], stateName: "Cantagalo" },
				{ cities: [], stateName: "Lemba" },
				{ cities: [], stateName: "Lobata" },
				{ cities: [], stateName: "Me-Zochi" },
				{ cities: [], stateName: "Pague" },
			],
			countryName: "Sao Tome and Principe",
		},
		{
			states: [
				{ cities: [], stateName: "Al Khobar" },
				{ cities: [], stateName: "Aseer" },
				{ cities: [], stateName: "Ash Sharqiyah" },
				{ cities: [], stateName: "Asir" },
				{ cities: [], stateName: "Central Province" },
				{ cities: [], stateName: "Eastern Province" },
				{ cities: [], stateName: "Ha'il" },
				{ cities: [], stateName: "Jawf" },
				{ cities: [], stateName: "Jizan" },
				{ cities: [], stateName: "Makkah" },
				{ cities: [], stateName: "Najran" },
				{ cities: [], stateName: "Qasim" },
				{ cities: [], stateName: "Tabuk" },
				{ cities: [], stateName: "Western Province" },
				{ cities: [], stateName: "al-Bahah" },
				{ cities: [], stateName: "al-Hudud-ash-Shamaliyah" },
				{ cities: [], stateName: "al-Madinah" },
				{ cities: [], stateName: "ar-Riyad" },
			],
			countryName: "Saudi Arabia",
		},
		{
			states: [
				{ cities: [], stateName: "Dakar" },
				{ cities: [], stateName: "Diourbel" },
				{ cities: [], stateName: "Fatick" },
				{ cities: [], stateName: "Kaolack" },
				{ cities: [], stateName: "Kolda" },
				{ cities: [], stateName: "Louga" },
				{ cities: [], stateName: "Saint-Louis" },
				{ cities: [], stateName: "Tambacounda" },
				{ cities: [], stateName: "Thies" },
				{ cities: [], stateName: "Ziguinchor" },
			],
			countryName: "Senegal",
		},
		{
			states: [
				{ cities: [], stateName: "Central Serbia" },
				{ cities: [], stateName: "Kosovo and Metohija" },
				{ cities: [], stateName: "Vojvodina" },
			],
			countryName: "Serbia",
		},
		{
			states: [
				{ cities: [], stateName: "Anse Boileau" },
				{ cities: [], stateName: "Anse Royale" },
				{ cities: [], stateName: "Cascade" },
				{ cities: [], stateName: "Takamaka" },
				{ cities: [], stateName: "Victoria" },
			],
			countryName: "Seychelles",
		},
		{
			states: [
				{ cities: [], stateName: "Eastern" },
				{ cities: [], stateName: "Northern" },
				{ cities: [], stateName: "Southern" },
				{ cities: [], stateName: "Western" },
			],
			countryName: "Sierra Leone",
		},
		{
			states: [{ cities: [], stateName: "Singapore" }],
			countryName: "Singapore",
		},
		{
			states: [
				{ cities: [], stateName: "Banskobystricky" },
				{ cities: [], stateName: "Bratislavsky" },
				{ cities: [], stateName: "Kosicky" },
				{ cities: [], stateName: "Nitriansky" },
				{ cities: [], stateName: "Presovsky" },
				{ cities: [], stateName: "Trenciansky" },
				{ cities: [], stateName: "Trnavsky" },
				{ cities: [], stateName: "Zilinsky" },
			],
			countryName: "Slovakia",
		},
		{
			states: [
				{ cities: [], stateName: "Benedikt" },
				{ cities: [], stateName: "Gorenjska" },
				{ cities: [], stateName: "Gorishka" },
				{ cities: [], stateName: "Jugovzhodna Slovenija" },
				{ cities: [], stateName: "Koroshka" },
				{ cities: [], stateName: "Notranjsko-krashka" },
				{ cities: [], stateName: "Obalno-krashka" },
				{ cities: [], stateName: "Obcina Domzale" },
				{ cities: [], stateName: "Obcina Vitanje" },
				{ cities: [], stateName: "Osrednjeslovenska" },
				{ cities: [], stateName: "Podravska" },
				{ cities: [], stateName: "Pomurska" },
				{ cities: [], stateName: "Savinjska" },
				{ cities: [], stateName: "Slovenian Littoral" },
				{ cities: [], stateName: "Spodnjeposavska" },
				{ cities: [], stateName: "Zasavska" },
			],
			countryName: "Slovenia",
		},
		{
			states: [{ cities: [], stateName: "Pitcairn" }],
			countryName: "Smaller Territories of the UK",
		},
		{
			states: [
				{ cities: [], stateName: "Central" },
				{ cities: [], stateName: "Choiseul" },
				{ cities: [], stateName: "Guadalcanal" },
				{ cities: [], stateName: "Isabel" },
				{ cities: [], stateName: "Makira and Ulawa" },
				{ cities: [], stateName: "Malaita" },
				{ cities: [], stateName: "Rennell and Bellona" },
				{ cities: [], stateName: "Temotu" },
				{ cities: [], stateName: "Western" },
			],
			countryName: "Solomon Islands",
		},
		{
			states: [
				{ cities: [], stateName: "Awdal" },
				{ cities: [], stateName: "Bakol" },
				{ cities: [], stateName: "Banadir" },
				{ cities: [], stateName: "Bari" },
				{ cities: [], stateName: "Bay" },
				{ cities: [], stateName: "Galgudug" },
				{ cities: [], stateName: "Gedo" },
				{ cities: [], stateName: "Hiran" },
				{ cities: [], stateName: "Jubbada Hose" },
				{ cities: [], stateName: "Jubbadha Dexe" },
				{ cities: [], stateName: "Mudug" },
				{ cities: [], stateName: "Nugal" },
				{ cities: [], stateName: "Sanag" },
				{ cities: [], stateName: "Shabellaha Dhexe" },
				{ cities: [], stateName: "Shabellaha Hose" },
				{ cities: [], stateName: "Togdher" },
				{ cities: [], stateName: "Woqoyi Galbed" },
			],
			countryName: "Somalia",
		},
		{
			states: [
				{ cities: [], stateName: "Eastern Cape" },
				{ cities: [], stateName: "Free State" },
				{ cities: [], stateName: "Gauteng" },
				{ cities: [], stateName: "Kempton Park" },
				{ cities: [], stateName: "Kramerville" },
				{ cities: [], stateName: "KwaZulu Natal" },
				{ cities: [], stateName: "Limpopo" },
				{ cities: [], stateName: "Mpumalanga" },
				{ cities: [], stateName: "North West" },
				{ cities: [], stateName: "Northern Cape" },
				{ cities: [], stateName: "Parow" },
				{ cities: [], stateName: "Table View" },
				{ cities: [], stateName: "Umtentweni" },
				{ cities: [], stateName: "Western Cape" },
			],
			countryName: "South Africa",
		},
		{
			states: [{ cities: [], stateName: "South Georgia" }],
			countryName: "South Georgia",
		},
		{
			states: [{ cities: [], stateName: "Central Equatoria" }],
			countryName: "South Sudan",
		},
		{
			states: [
				{ cities: [], stateName: "A Coruna" },
				{ cities: [], stateName: "Alacant" },
				{ cities: [], stateName: "Alava" },
				{ cities: [], stateName: "Albacete" },
				{ cities: [], stateName: "Almeria" },
				{ cities: [], stateName: "Andalucia" },
				{ cities: [], stateName: "Asturias" },
				{ cities: [], stateName: "Avila" },
				{ cities: [], stateName: "Badajoz" },
				{ cities: [], stateName: "Balears" },
				{ cities: [], stateName: "Barcelona" },
				{ cities: [], stateName: "Bertamirans" },
				{ cities: [], stateName: "Biscay" },
				{ cities: [], stateName: "Burgos" },
				{ cities: [], stateName: "Caceres" },
				{ cities: [], stateName: "Cadiz" },
				{ cities: [], stateName: "Cantabria" },
				{ cities: [], stateName: "Castello" },
				{ cities: [], stateName: "Catalunya" },
				{ cities: [], stateName: "Ceuta" },
				{ cities: [], stateName: "Ciudad Real" },
				{ cities: [], stateName: "Comunidad Autonoma de Canarias" },
				{ cities: [], stateName: "Comunidad Autonoma de Cataluna" },
				{ cities: [], stateName: "Comunidad Autonoma de Galicia" },
				{ cities: [], stateName: "Comunidad Autonoma de las Isla" },
				{ cities: [], stateName: "Comunidad Autonoma del Princip" },
				{ cities: [], stateName: "Comunidad Valenciana" },
				{ cities: [], stateName: "Cordoba" },
				{ cities: [], stateName: "Cuenca" },
				{ cities: [], stateName: "Gipuzkoa" },
				{ cities: [], stateName: "Girona" },
				{ cities: [], stateName: "Granada" },
				{ cities: [], stateName: "Guadalajara" },
				{ cities: [], stateName: "Guipuzcoa" },
				{ cities: [], stateName: "Huelva" },
				{ cities: [], stateName: "Huesca" },
				{ cities: [], stateName: "Jaen" },
				{ cities: [], stateName: "La Rioja" },
				{ cities: [], stateName: "Las Palmas" },
				{ cities: [], stateName: "Leon" },
				{ cities: [], stateName: "Lerida" },
				{ cities: [], stateName: "Lleida" },
				{ cities: [], stateName: "Lugo" },
				{ cities: [], stateName: "Madrid" },
				{ cities: [], stateName: "Malaga" },
				{ cities: [], stateName: "Melilla" },
				{ cities: [], stateName: "Murcia" },
				{ cities: [], stateName: "Navarra" },
				{ cities: [], stateName: "Ourense" },
				{ cities: [], stateName: "Pais Vasco" },
				{ cities: [], stateName: "Palencia" },
				{ cities: [], stateName: "Pontevedra" },
				{ cities: [], stateName: "Salamanca" },
				{ cities: [], stateName: "Santa Cruz de Tenerife" },
				{ cities: [], stateName: "Segovia" },
				{ cities: [], stateName: "Sevilla" },
				{ cities: [], stateName: "Soria" },
				{ cities: [], stateName: "Tarragona" },
				{ cities: [], stateName: "Tenerife" },
				{ cities: [], stateName: "Teruel" },
				{ cities: [], stateName: "Toledo" },
				{ cities: [], stateName: "Valencia" },
				{ cities: [], stateName: "Valladolid" },
				{ cities: [], stateName: "Vizcaya" },
				{ cities: [], stateName: "Zamora" },
				{ cities: [], stateName: "Zaragoza" },
			],
			countryName: "Spain",
		},
		{
			states: [
				{ cities: [], stateName: "Amparai" },
				{ cities: [], stateName: "Anuradhapuraya" },
				{ cities: [], stateName: "Badulla" },
				{ cities: [], stateName: "Boralesgamuwa" },
				{ cities: [], stateName: "Colombo" },
				{ cities: [], stateName: "Galla" },
				{ cities: [], stateName: "Gampaha" },
				{ cities: [], stateName: "Hambantota" },
				{ cities: [], stateName: "Kalatura" },
				{ cities: [], stateName: "Kegalla" },
				{ cities: [], stateName: "Kilinochchi" },
				{ cities: [], stateName: "Kurunegala" },
				{ cities: [], stateName: "Madakalpuwa" },
				{ cities: [], stateName: "Maha Nuwara" },
				{ cities: [], stateName: "Malwana" },
				{ cities: [], stateName: "Mannarama" },
				{ cities: [], stateName: "Matale" },
				{ cities: [], stateName: "Matara" },
				{ cities: [], stateName: "Monaragala" },
				{ cities: [], stateName: "Mullaitivu" },
				{ cities: [], stateName: "North Eastern Province" },
				{ cities: [], stateName: "North Western Province" },
				{ cities: [], stateName: "Nuwara Eliya" },
				{ cities: [], stateName: "Polonnaruwa" },
				{ cities: [], stateName: "Puttalama" },
				{ cities: [], stateName: "Ratnapuraya" },
				{ cities: [], stateName: "Southern Province" },
				{ cities: [], stateName: "Tirikunamalaya" },
				{ cities: [], stateName: "Tuscany" },
				{ cities: [], stateName: "Vavuniyawa" },
				{ cities: [], stateName: "Western Province" },
				{ cities: [], stateName: "Yapanaya" },
				{ cities: [], stateName: "kadawatha" },
			],
			countryName: "Sri Lanka",
		},
		{
			states: [
				{ cities: [], stateName: "A'ali-an-Nil" },
				{ cities: [], stateName: "Bahr-al-Jabal" },
				{ cities: [], stateName: "Central Equatoria" },
				{ cities: [], stateName: "Gharb Bahr-al-Ghazal" },
				{ cities: [], stateName: "Gharb Darfur" },
				{ cities: [], stateName: "Gharb Kurdufan" },
				{ cities: [], stateName: "Gharb-al-Istiwa'iyah" },
				{ cities: [], stateName: "Janub Darfur" },
				{ cities: [], stateName: "Janub Kurdufan" },
				{ cities: [], stateName: "Junqali" },
				{ cities: [], stateName: "Kassala" },
				{ cities: [], stateName: "Nahr-an-Nil" },
				{ cities: [], stateName: "Shamal Bahr-al-Ghazal" },
				{ cities: [], stateName: "Shamal Darfur" },
				{ cities: [], stateName: "Shamal Kurdufan" },
				{ cities: [], stateName: "Sharq-al-Istiwa'iyah" },
				{ cities: [], stateName: "Sinnar" },
				{ cities: [], stateName: "Warab" },
				{ cities: [], stateName: "Wilayat al Khartum" },
				{ cities: [], stateName: "al-Bahr-al-Ahmar" },
				{ cities: [], stateName: "al-Buhayrat" },
				{ cities: [], stateName: "al-Jazirah" },
				{ cities: [], stateName: "al-Khartum" },
				{ cities: [], stateName: "al-Qadarif" },
				{ cities: [], stateName: "al-Wahdah" },
				{ cities: [], stateName: "an-Nil-al-Abyad" },
				{ cities: [], stateName: "an-Nil-al-Azraq" },
				{ cities: [], stateName: "ash-Shamaliyah" },
			],
			countryName: "Sudan",
		},
		{
			states: [
				{ cities: [], stateName: "Brokopondo" },
				{ cities: [], stateName: "Commewijne" },
				{ cities: [], stateName: "Coronie" },
				{ cities: [], stateName: "Marowijne" },
				{ cities: [], stateName: "Nickerie" },
				{ cities: [], stateName: "Para" },
				{ cities: [], stateName: "Paramaribo" },
				{ cities: [], stateName: "Saramacca" },
				{ cities: [], stateName: "Wanica" },
			],
			countryName: "Suriname",
		},
		{
			states: [{ cities: [], stateName: "Svalbard" }],
			countryName: "Svalbard And Jan Mayen Islands",
		},
		{
			states: [
				{ cities: [], stateName: "Hhohho" },
				{ cities: [], stateName: "Lubombo" },
				{ cities: [], stateName: "Manzini" },
				{ cities: [], stateName: "Shiselweni" },
			],
			countryName: "Swaziland",
		},
		{
			states: [
				{ cities: [], stateName: "Alvsborgs Lan" },
				{ cities: [], stateName: "Angermanland" },
				{ cities: [], stateName: "Blekinge" },
				{ cities: [], stateName: "Bohuslan" },
				{ cities: [], stateName: "Dalarna" },
				{ cities: [], stateName: "Gavleborg" },
				{ cities: [], stateName: "Gaza" },
				{ cities: [], stateName: "Gotland" },
				{ cities: [], stateName: "Halland" },
				{ cities: [], stateName: "Jamtland" },
				{ cities: [], stateName: "Jonkoping" },
				{ cities: [], stateName: "Kalmar" },
				{ cities: [], stateName: "Kristianstads" },
				{ cities: [], stateName: "Kronoberg" },
				{ cities: [], stateName: "Norrbotten" },
				{ cities: [], stateName: "Orebro" },
				{ cities: [], stateName: "Ostergotland" },
				{ cities: [], stateName: "Saltsjo-Boo" },
				{ cities: [], stateName: "Skane" },
				{ cities: [], stateName: "Smaland" },
				{ cities: [], stateName: "Sodermanland" },
				{ cities: [], stateName: "Stockholm" },
				{ cities: [], stateName: "Uppsala" },
				{ cities: [], stateName: "Varmland" },
				{ cities: [], stateName: "Vasterbotten" },
				{ cities: [], stateName: "Vastergotland" },
				{ cities: [], stateName: "Vasternorrland" },
				{ cities: [], stateName: "Vastmanland" },
				{ cities: [], stateName: "Vastra Gotaland" },
			],
			countryName: "Sweden",
		},
		{
			states: [
				{ cities: [], stateName: "Aargau" },
				{ cities: [], stateName: "Appenzell Inner-Rhoden" },
				{ cities: [], stateName: "Appenzell-Ausser Rhoden" },
				{ cities: [], stateName: "Basel-Landschaft" },
				{ cities: [], stateName: "Basel-Stadt" },
				{ cities: [], stateName: "Bern" },
				{ cities: [], stateName: "Canton Ticino" },
				{ cities: [], stateName: "Fribourg" },
				{ cities: [], stateName: "Geneve" },
				{ cities: [], stateName: "Glarus" },
				{ cities: [], stateName: "Graubunden" },
				{ cities: [], stateName: "Heerbrugg" },
				{ cities: [], stateName: "Jura" },
				{ cities: [], stateName: "Kanton Aargau" },
				{ cities: [], stateName: "Luzern" },
				{ cities: [], stateName: "Morbio Inferiore" },
				{ cities: [], stateName: "Muhen" },
				{ cities: [], stateName: "Neuchatel" },
				{ cities: [], stateName: "Nidwalden" },
				{ cities: [], stateName: "Obwalden" },
				{ cities: [], stateName: "Sankt Gallen" },
				{ cities: [], stateName: "Schaffhausen" },
				{ cities: [], stateName: "Schwyz" },
				{ cities: [], stateName: "Solothurn" },
				{ cities: [], stateName: "Thurgau" },
				{ cities: [], stateName: "Ticino" },
				{ cities: [], stateName: "Uri" },
				{ cities: [], stateName: "Valais" },
				{ cities: [], stateName: "Vaud" },
				{ cities: [], stateName: "Vauffelin" },
				{ cities: [], stateName: "Zug" },
				{ cities: [], stateName: "Zurich" },
			],
			countryName: "Switzerland",
		},
		{
			states: [
				{ cities: [], stateName: "Aleppo" },
				{ cities: [], stateName: "Dar'a" },
				{ cities: [], stateName: "Dayr-az-Zawr" },
				{ cities: [], stateName: "Dimashq" },
				{ cities: [], stateName: "Halab" },
				{ cities: [], stateName: "Hamah" },
				{ cities: [], stateName: "Hims" },
				{ cities: [], stateName: "Idlib" },
				{ cities: [], stateName: "Madinat Dimashq" },
				{ cities: [], stateName: "Tartus" },
				{ cities: [], stateName: "al-Hasakah" },
				{ cities: [], stateName: "al-Ladhiqiyah" },
				{ cities: [], stateName: "al-Qunaytirah" },
				{ cities: [], stateName: "ar-Raqqah" },
				{ cities: [], stateName: "as-Suwayda" },
			],
			countryName: "Syria",
		},
		{
			states: [
				{ cities: [], stateName: "Changhwa" },
				{ cities: [], stateName: "Chiayi Hsien" },
				{ cities: [], stateName: "Chiayi Shih" },
				{ cities: [], stateName: "Eastern Taipei" },
				{ cities: [], stateName: "Hsinchu Hsien" },
				{ cities: [], stateName: "Hsinchu Shih" },
				{ cities: [], stateName: "Hualien" },
				{ cities: [], stateName: "Ilan" },
				{ cities: [], stateName: "Kaohsiung Hsien" },
				{ cities: [], stateName: "Kaohsiung Shih" },
				{ cities: [], stateName: "Keelung Shih" },
				{ cities: [], stateName: "Kinmen" },
				{ cities: [], stateName: "Miaoli" },
				{ cities: [], stateName: "Nantou" },
				{ cities: [], stateName: "Northern Taiwan" },
				{ cities: [], stateName: "Penghu" },
				{ cities: [], stateName: "Pingtung" },
				{ cities: [], stateName: "Taichung" },
				{ cities: [], stateName: "Taichung Hsien" },
				{ cities: [], stateName: "Taichung Shih" },
				{ cities: [], stateName: "Tainan Hsien" },
				{ cities: [], stateName: "Tainan Shih" },
				{ cities: [], stateName: "Taipei Hsien" },
				{ cities: [], stateName: "Taipei Shih / Taipei Hsien" },
				{ cities: [], stateName: "Taitung" },
				{ cities: [], stateName: "Taoyuan" },
				{ cities: [], stateName: "Yilan" },
				{ cities: [], stateName: "Yun-Lin Hsien" },
				{ cities: [], stateName: "Yunlin" },
			],
			countryName: "Taiwan",
		},
		{
			states: [
				{ cities: [], stateName: "Dushanbe" },
				{ cities: [], stateName: "Gorno-Badakhshan" },
				{ cities: [], stateName: "Karotegin" },
				{ cities: [], stateName: "Khatlon" },
				{ cities: [], stateName: "Sughd" },
			],
			countryName: "Tajikistan",
		},
		{
			states: [
				{ cities: [], stateName: "Arusha" },
				{ cities: [], stateName: "Dar es Salaam" },
				{ cities: [], stateName: "Dodoma" },
				{ cities: [], stateName: "Iringa" },
				{ cities: [], stateName: "Kagera" },
				{ cities: [], stateName: "Kigoma" },
				{ cities: [], stateName: "Kilimanjaro" },
				{ cities: [], stateName: "Lindi" },
				{ cities: [], stateName: "Mara" },
				{ cities: [], stateName: "Mbeya" },
				{ cities: [], stateName: "Morogoro" },
				{ cities: [], stateName: "Mtwara" },
				{ cities: [], stateName: "Mwanza" },
				{ cities: [], stateName: "Pwani" },
				{ cities: [], stateName: "Rukwa" },
				{ cities: [], stateName: "Ruvuma" },
				{ cities: [], stateName: "Shinyanga" },
				{ cities: [], stateName: "Singida" },
				{ cities: [], stateName: "Tabora" },
				{ cities: [], stateName: "Tanga" },
				{ cities: [], stateName: "Zanzibar and Pemba" },
			],
			countryName: "Tanzania",
		},
		{
			states: [
				{ cities: [], stateName: "Amnat Charoen" },
				{ cities: [], stateName: "Ang Thong" },
				{ cities: [], stateName: "Bangkok" },
				{ cities: [], stateName: "Buri Ram" },
				{ cities: [], stateName: "Chachoengsao" },
				{ cities: [], stateName: "Chai Nat" },
				{ cities: [], stateName: "Chaiyaphum" },
				{ cities: [], stateName: "Changwat Chaiyaphum" },
				{ cities: [], stateName: "Chanthaburi" },
				{ cities: [], stateName: "Chiang Mai" },
				{ cities: [], stateName: "Chiang Rai" },
				{ cities: [], stateName: "Chon Buri" },
				{ cities: [], stateName: "Chumphon" },
				{ cities: [], stateName: "Kalasin" },
				{ cities: [], stateName: "Kamphaeng Phet" },
				{ cities: [], stateName: "Kanchanaburi" },
				{ cities: [], stateName: "Khon Kaen" },
				{ cities: [], stateName: "Krabi" },
				{ cities: [], stateName: "Krung Thep" },
				{ cities: [], stateName: "Lampang" },
				{ cities: [], stateName: "Lamphun" },
				{ cities: [], stateName: "Loei" },
				{ cities: [], stateName: "Lop Buri" },
				{ cities: [], stateName: "Mae Hong Son" },
				{ cities: [], stateName: "Maha Sarakham" },
				{ cities: [], stateName: "Mukdahan" },
				{ cities: [], stateName: "Nakhon Nayok" },
				{ cities: [], stateName: "Nakhon Pathom" },
				{ cities: [], stateName: "Nakhon Phanom" },
				{ cities: [], stateName: "Nakhon Ratchasima" },
				{ cities: [], stateName: "Nakhon Sawan" },
				{ cities: [], stateName: "Nakhon Si Thammarat" },
				{ cities: [], stateName: "Nan" },
				{ cities: [], stateName: "Narathiwat" },
				{ cities: [], stateName: "Nong Bua Lam Phu" },
				{ cities: [], stateName: "Nong Khai" },
				{ cities: [], stateName: "Nonthaburi" },
				{ cities: [], stateName: "Pathum Thani" },
				{ cities: [], stateName: "Pattani" },
				{ cities: [], stateName: "Phangnga" },
				{ cities: [], stateName: "Phatthalung" },
				{ cities: [], stateName: "Phayao" },
				{ cities: [], stateName: "Phetchabun" },
				{ cities: [], stateName: "Phetchaburi" },
				{ cities: [], stateName: "Phichit" },
				{ cities: [], stateName: "Phitsanulok" },
				{ cities: [], stateName: "Phra Nakhon Si Ayutthaya" },
				{ cities: [], stateName: "Phrae" },
				{ cities: [], stateName: "Phuket" },
				{ cities: [], stateName: "Prachin Buri" },
				{ cities: [], stateName: "Prachuap Khiri Khan" },
				{ cities: [], stateName: "Ranong" },
				{ cities: [], stateName: "Ratchaburi" },
				{ cities: [], stateName: "Rayong" },
				{ cities: [], stateName: "Roi Et" },
				{ cities: [], stateName: "Sa Kaeo" },
				{ cities: [], stateName: "Sakon Nakhon" },
				{ cities: [], stateName: "Samut Prakan" },
				{ cities: [], stateName: "Samut Sakhon" },
				{ cities: [], stateName: "Samut Songkhran" },
				{ cities: [], stateName: "Saraburi" },
				{ cities: [], stateName: "Satun" },
				{ cities: [], stateName: "Si Sa Ket" },
				{ cities: [], stateName: "Sing Buri" },
				{ cities: [], stateName: "Songkhla" },
				{ cities: [], stateName: "Sukhothai" },
				{ cities: [], stateName: "Suphan Buri" },
				{ cities: [], stateName: "Surat Thani" },
				{ cities: [], stateName: "Surin" },
				{ cities: [], stateName: "Tak" },
				{ cities: [], stateName: "Trang" },
				{ cities: [], stateName: "Trat" },
				{ cities: [], stateName: "Ubon Ratchathani" },
				{ cities: [], stateName: "Udon Thani" },
				{ cities: [], stateName: "Uthai Thani" },
				{ cities: [], stateName: "Uttaradit" },
				{ cities: [], stateName: "Yala" },
				{ cities: [], stateName: "Yasothon" },
			],
			countryName: "Thailand",
		},
		{
			states: [
				{ cities: [], stateName: "Centre" },
				{ cities: [], stateName: "Kara" },
				{ cities: [], stateName: "Maritime" },
				{ cities: [], stateName: "Plateaux" },
				{ cities: [], stateName: "Savanes" },
			],
			countryName: "Togo",
		},
		{
			states: [
				{ cities: [], stateName: "Atafu" },
				{ cities: [], stateName: "Fakaofo" },
				{ cities: [], stateName: "Nukunonu" },
			],
			countryName: "Tokelau",
		},
		{
			states: [
				{ cities: [], stateName: "Eua" },
				{ cities: [], stateName: "Ha'apai" },
				{ cities: [], stateName: "Niuas" },
				{ cities: [], stateName: "Tongatapu" },
				{ cities: [], stateName: "Vava'u" },
			],
			countryName: "Tonga",
		},
		{
			states: [
				{ cities: [], stateName: "Arima-Tunapuna-Piarco" },
				{ cities: [], stateName: "Caroni" },
				{ cities: [], stateName: "Chaguanas" },
				{ cities: [], stateName: "Couva-Tabaquite-Talparo" },
				{ cities: [], stateName: "Diego Martin" },
				{ cities: [], stateName: "Glencoe" },
				{ cities: [], stateName: "Penal Debe" },
				{ cities: [], stateName: "Point Fortin" },
				{ cities: [], stateName: "Port of Spain" },
				{ cities: [], stateName: "Princes Town" },
				{ cities: [], stateName: "Saint George" },
				{ cities: [], stateName: "San Fernando" },
				{ cities: [], stateName: "San Juan" },
				{ cities: [], stateName: "Sangre Grande" },
				{ cities: [], stateName: "Siparia" },
				{ cities: [], stateName: "Tobago" },
			],
			countryName: "Trinidad And Tobago",
		},
		{
			states: [
				{ cities: [], stateName: "Aryanah" },
				{ cities: [], stateName: "Bajah" },
				{ cities: [], stateName: "Bin 'Arus" },
				{ cities: [], stateName: "Binzart" },
				{ cities: [], stateName: "Gouvernorat de Ariana" },
				{ cities: [], stateName: "Gouvernorat de Nabeul" },
				{ cities: [], stateName: "Gouvernorat de Sousse" },
				{ cities: [], stateName: "Hammamet Yasmine" },
				{ cities: [], stateName: "Jundubah" },
				{ cities: [], stateName: "Madaniyin" },
				{ cities: [], stateName: "Manubah" },
				{ cities: [], stateName: "Monastir" },
				{ cities: [], stateName: "Nabul" },
				{ cities: [], stateName: "Qabis" },
				{ cities: [], stateName: "Qafsah" },
				{ cities: [], stateName: "Qibili" },
				{ cities: [], stateName: "Safaqis" },
				{ cities: [], stateName: "Sfax" },
				{ cities: [], stateName: "Sidi Bu Zayd" },
				{ cities: [], stateName: "Silyanah" },
				{ cities: [], stateName: "Susah" },
				{ cities: [], stateName: "Tatawin" },
				{ cities: [], stateName: "Tawzar" },
				{ cities: [], stateName: "Tunis" },
				{ cities: [], stateName: "Zaghwan" },
				{ cities: [], stateName: "al-Kaf" },
				{ cities: [], stateName: "al-Mahdiyah" },
				{ cities: [], stateName: "al-Munastir" },
				{ cities: [], stateName: "al-Qasrayn" },
				{ cities: [], stateName: "al-Qayrawan" },
			],
			countryName: "Tunisia",
		},
		{
			states: [
				{ cities: [], stateName: "Adana" },
				{ cities: [], stateName: "Adiyaman" },
				{ cities: [], stateName: "Afyon" },
				{ cities: [], stateName: "Agri" },
				{ cities: [], stateName: "Aksaray" },
				{ cities: [], stateName: "Amasya" },
				{ cities: [], stateName: "Ankara" },
				{ cities: [], stateName: "Antalya" },
				{ cities: [], stateName: "Ardahan" },
				{ cities: [], stateName: "Artvin" },
				{ cities: [], stateName: "Aydin" },
				{ cities: [], stateName: "Balikesir" },
				{ cities: [], stateName: "Bartin" },
				{ cities: [], stateName: "Batman" },
				{ cities: [], stateName: "Bayburt" },
				{ cities: [], stateName: "Bilecik" },
				{ cities: [], stateName: "Bingol" },
				{ cities: [], stateName: "Bitlis" },
				{ cities: [], stateName: "Bolu" },
				{ cities: [], stateName: "Burdur" },
				{ cities: [], stateName: "Bursa" },
				{ cities: [], stateName: "Canakkale" },
				{ cities: [], stateName: "Cankiri" },
				{ cities: [], stateName: "Corum" },
				{ cities: [], stateName: "Denizli" },
				{ cities: [], stateName: "Diyarbakir" },
				{ cities: [], stateName: "Duzce" },
				{ cities: [], stateName: "Edirne" },
				{ cities: [], stateName: "Elazig" },
				{ cities: [], stateName: "Erzincan" },
				{ cities: [], stateName: "Erzurum" },
				{ cities: [], stateName: "Eskisehir" },
				{ cities: [], stateName: "Gaziantep" },
				{ cities: [], stateName: "Giresun" },
				{ cities: [], stateName: "Gumushane" },
				{ cities: [], stateName: "Hakkari" },
				{ cities: [], stateName: "Hatay" },
				{ cities: [], stateName: "Icel" },
				{ cities: [], stateName: "Igdir" },
				{ cities: [], stateName: "Isparta" },
				{ cities: [], stateName: "Istanbul" },
				{ cities: [], stateName: "Izmir" },
				{ cities: [], stateName: "Kahramanmaras" },
				{ cities: [], stateName: "Karabuk" },
				{ cities: [], stateName: "Karaman" },
				{ cities: [], stateName: "Kars" },
				{ cities: [], stateName: "Karsiyaka" },
				{ cities: [], stateName: "Kastamonu" },
				{ cities: [], stateName: "Kayseri" },
				{ cities: [], stateName: "Kilis" },
				{ cities: [], stateName: "Kirikkale" },
				{ cities: [], stateName: "Kirklareli" },
				{ cities: [], stateName: "Kirsehir" },
				{ cities: [], stateName: "Kocaeli" },
				{ cities: [], stateName: "Konya" },
				{ cities: [], stateName: "Kutahya" },
				{ cities: [], stateName: "Lefkosa" },
				{ cities: [], stateName: "Malatya" },
				{ cities: [], stateName: "Manisa" },
				{ cities: [], stateName: "Mardin" },
				{ cities: [], stateName: "Mugla" },
				{ cities: [], stateName: "Mus" },
				{ cities: [], stateName: "Nevsehir" },
				{ cities: [], stateName: "Nigde" },
				{ cities: [], stateName: "Ordu" },
				{ cities: [], stateName: "Osmaniye" },
				{ cities: [], stateName: "Rize" },
				{ cities: [], stateName: "Sakarya" },
				{ cities: [], stateName: "Samsun" },
				{ cities: [], stateName: "Sanliurfa" },
				{ cities: [], stateName: "Siirt" },
				{ cities: [], stateName: "Sinop" },
				{ cities: [], stateName: "Sirnak" },
				{ cities: [], stateName: "Sivas" },
				{ cities: [], stateName: "Tekirdag" },
				{ cities: [], stateName: "Tokat" },
				{ cities: [], stateName: "Trabzon" },
				{ cities: [], stateName: "Tunceli" },
				{ cities: [], stateName: "Usak" },
				{ cities: [], stateName: "Van" },
				{ cities: [], stateName: "Yalova" },
				{ cities: [], stateName: "Yozgat" },
				{ cities: [], stateName: "Zonguldak" },
			],
			countryName: "Turkey",
		},
		{
			states: [
				{ cities: [], stateName: "Ahal" },
				{ cities: [], stateName: "Asgabat" },
				{ cities: [], stateName: "Balkan" },
				{ cities: [], stateName: "Dasoguz" },
				{ cities: [], stateName: "Lebap" },
				{ cities: [], stateName: "Mari" },
			],
			countryName: "Turkmenistan",
		},
		{
			states: [
				{ cities: [], stateName: "Grand Turk" },
				{ cities: [], stateName: "South Caicos and East Caicos" },
			],
			countryName: "Turks And Caicos Islands",
		},
		{
			states: [
				{ cities: [], stateName: "Funafuti" },
				{ cities: [], stateName: "Nanumanga" },
				{ cities: [], stateName: "Nanumea" },
				{ cities: [], stateName: "Niutao" },
				{ cities: [], stateName: "Nui" },
				{ cities: [], stateName: "Nukufetau" },
				{ cities: [], stateName: "Nukulaelae" },
				{ cities: [], stateName: "Vaitupu" },
			],
			countryName: "Tuvalu",
		},
		{
			states: [
				{ cities: [], stateName: "Central" },
				{ cities: [], stateName: "Eastern" },
				{ cities: [], stateName: "Northern" },
				{ cities: [], stateName: "Western" },
			],
			countryName: "Uganda",
		},
		{
			states: [
				{ cities: [], stateName: "Cherkas'ka" },
				{ cities: [], stateName: "Chernihivs'ka" },
				{ cities: [], stateName: "Chernivets'ka" },
				{ cities: [], stateName: "Crimea" },
				{ cities: [], stateName: "Dnipropetrovska" },
				{ cities: [], stateName: "Donets'ka" },
				{ cities: [], stateName: "Ivano-Frankivs'ka" },
				{ cities: [], stateName: "Kharkiv" },
				{ cities: [], stateName: "Kharkov" },
				{ cities: [], stateName: "Khersonska" },
				{ cities: [], stateName: "Khmel'nyts'ka" },
				{ cities: [], stateName: "Kirovohrad" },
				{ cities: [], stateName: "Krym" },
				{ cities: [], stateName: "Kyyiv" },
				{ cities: [], stateName: "Kyyivs'ka" },
				{ cities: [], stateName: "L'vivs'ka" },
				{ cities: [], stateName: "Luhans'ka" },
				{ cities: [], stateName: "Mykolayivs'ka" },
				{ cities: [], stateName: "Odes'ka" },
				{ cities: [], stateName: "Odessa" },
				{ cities: [], stateName: "Poltavs'ka" },
				{ cities: [], stateName: "Rivnens'ka" },
				{ cities: [], stateName: "Sevastopol'" },
				{ cities: [], stateName: "Sums'ka" },
				{ cities: [], stateName: "Ternopil's'ka" },
				{ cities: [], stateName: "Volyns'ka" },
				{ cities: [], stateName: "Vynnyts'ka" },
				{ cities: [], stateName: "Zakarpats'ka" },
				{ cities: [], stateName: "Zaporizhia" },
				{ cities: [], stateName: "Zhytomyrs'ka" },
			],
			countryName: "Ukraine",
		},
		{
			states: [
				{ cities: [], stateName: "Abu Zabi" },
				{ cities: [], stateName: "Ajman" },
				{ cities: [], stateName: "Dubai" },
				{ cities: [], stateName: "Ras al-Khaymah" },
				{ cities: [], stateName: "Sharjah" },
				{ cities: [], stateName: "Sharjha" },
				{ cities: [], stateName: "Umm al Qaywayn" },
				{ cities: [], stateName: "al-Fujayrah" },
				{ cities: [], stateName: "ash-Shariqah" },
			],
			countryName: "United Arab Emirates",
		},
		{
			states: [
				{ cities: [], stateName: "Aberdeen" },
				{ cities: [], stateName: "Aberdeenshire" },
				{ cities: [], stateName: "Argyll" },
				{ cities: [], stateName: "Armagh" },
				{ cities: [], stateName: "Bedfordshire" },
				{ cities: [], stateName: "Belfast" },
				{ cities: [], stateName: "Berkshire" },
				{ cities: [], stateName: "Birmingham" },
				{ cities: [], stateName: "Brechin" },
				{ cities: [], stateName: "Bridgnorth" },
				{ cities: [], stateName: "Bristol" },
				{ cities: [], stateName: "Buckinghamshire" },
				{ cities: [], stateName: "Cambridge" },
				{ cities: [], stateName: "Cambridgeshire" },
				{ cities: [], stateName: "Channel Islands" },
				{ cities: [], stateName: "Cheshire" },
				{ cities: [], stateName: "Cleveland" },
				{ cities: [], stateName: "Co Fermanagh" },
				{ cities: [], stateName: "Conwy" },
				{ cities: [], stateName: "Cornwall" },
				{ cities: [], stateName: "Coventry" },
				{ cities: [], stateName: "Craven Arms" },
				{ cities: [], stateName: "Cumbria" },
				{ cities: [], stateName: "Denbighshire" },
				{ cities: [], stateName: "Derby" },
				{ cities: [], stateName: "Derbyshire" },
				{ cities: [], stateName: "Devon" },
				{ cities: [], stateName: "Dial Code Dungannon" },
				{ cities: [], stateName: "Didcot" },
				{ cities: [], stateName: "Dorset" },
				{ cities: [], stateName: "Dunbartonshire" },
				{ cities: [], stateName: "Durham" },
				{ cities: [], stateName: "East Dunbartonshire" },
				{ cities: [], stateName: "East Lothian" },
				{ cities: [], stateName: "East Midlands" },
				{ cities: [], stateName: "East Sussex" },
				{ cities: [], stateName: "East Yorkshire" },
				{ cities: [], stateName: "England" },
				{ cities: [], stateName: "Essex" },
				{ cities: [], stateName: "Fermanagh" },
				{ cities: [], stateName: "Fife" },
				{ cities: [], stateName: "Flintshire" },
				{ cities: [], stateName: "Fulham" },
				{ cities: [], stateName: "Gainsborough" },
				{ cities: [], stateName: "Glocestershire" },
				{ cities: [], stateName: "Gwent" },
				{ cities: [], stateName: "Hampshire" },
				{ cities: [], stateName: "Hants" },
				{ cities: [], stateName: "Herefordshire" },
				{ cities: [], stateName: "Hertfordshire" },
				{ cities: [], stateName: "Ireland" },
				{ cities: [], stateName: "Isle Of Man" },
				{ cities: [], stateName: "Isle of Wight" },
				{ cities: [], stateName: "Kenford" },
				{ cities: [], stateName: "Kent" },
				{ cities: [], stateName: "Kilmarnock" },
				{ cities: [], stateName: "Lanarkshire" },
				{ cities: [], stateName: "Lancashire" },
				{ cities: [], stateName: "Leicestershire" },
				{ cities: [], stateName: "Lincolnshire" },
				{ cities: [], stateName: "Llanymynech" },
				{ cities: [], stateName: "London" },
				{ cities: [], stateName: "Ludlow" },
				{ cities: [], stateName: "Manchester" },
				{ cities: [], stateName: "Mayfair" },
				{ cities: [], stateName: "Merseyside" },
				{ cities: [], stateName: "Mid Glamorgan" },
				{ cities: [], stateName: "Middlesex" },
				{ cities: [], stateName: "Mildenhall" },
				{ cities: [], stateName: "Monmouthshire" },
				{ cities: [], stateName: "Newton Stewart" },
				{ cities: [], stateName: "Norfolk" },
				{ cities: [], stateName: "North Humberside" },
				{ cities: [], stateName: "North Yorkshire" },
				{ cities: [], stateName: "Northamptonshire" },
				{ cities: [], stateName: "Northants" },
				{ cities: [], stateName: "Northern Ireland" },
				{ cities: [], stateName: "Northumberland" },
				{ cities: [], stateName: "Nottinghamshire" },
				{ cities: [], stateName: "Oxford" },
				{ cities: [], stateName: "Powys" },
				{ cities: [], stateName: "Roos-shire" },
				{ cities: [], stateName: "SUSSEX" },
				{ cities: [], stateName: "Sark" },
				{ cities: [], stateName: "Scotland" },
				{ cities: [], stateName: "Scottish Borders" },
				{ cities: [], stateName: "Shropshire" },
				{ cities: [], stateName: "Somerset" },
				{ cities: [], stateName: "South Glamorgan" },
				{ cities: [], stateName: "South Wales" },
				{ cities: [], stateName: "South Yorkshire" },
				{ cities: [], stateName: "Southwell" },
				{ cities: [], stateName: "Staffordshire" },
				{ cities: [], stateName: "Strabane" },
				{ cities: [], stateName: "Suffolk" },
				{ cities: [], stateName: "Surrey" },
				{ cities: [], stateName: "Sussex" },
				{ cities: [], stateName: "Twickenham" },
				{ cities: [], stateName: "Tyne and Wear" },
				{ cities: [], stateName: "Tyrone" },
				{ cities: [], stateName: "Utah" },
				{ cities: [], stateName: "Wales" },
				{ cities: [], stateName: "Warwickshire" },
				{ cities: [], stateName: "West Lothian" },
				{ cities: [], stateName: "West Midlands" },
				{ cities: [], stateName: "West Sussex" },
				{ cities: [], stateName: "West Yorkshire" },
				{ cities: [], stateName: "Whissendine" },
				{ cities: [], stateName: "Wiltshire" },
				{ cities: [], stateName: "Wokingham" },
				{ cities: [], stateName: "Worcestershire" },
				{ cities: [], stateName: "Wrexham" },
				{ cities: [], stateName: "Wurttemberg" },
				{ cities: [], stateName: "Yorkshire" },
			],
			countryName: "United Kingdom",
		},
		{
			states: [
				{ cities: [], stateName: "Alabama" },
				{ cities: [], stateName: "Alaska" },
				{ cities: [], stateName: "Arizona" },
				{ cities: [], stateName: "Arkansas" },
				{ cities: [], stateName: "Byram" },
				{ cities: [], stateName: "California" },
				{ cities: [], stateName: "Cokato" },
				{ cities: [], stateName: "Colorado" },
				{ cities: [], stateName: "Connecticut" },
				{ cities: [], stateName: "Delaware" },
				{ cities: [], stateName: "District of Columbia" },
				{ cities: [], stateName: "Florida" },
				{ cities: [], stateName: "Georgia" },
				{ cities: [], stateName: "Hawaii" },
				{ cities: [], stateName: "Idaho" },
				{ cities: [], stateName: "Illinois" },
				{ cities: [], stateName: "Indiana" },
				{ cities: [], stateName: "Iowa" },
				{ cities: [], stateName: "Kansas" },
				{ cities: [], stateName: "Kentucky" },
				{ cities: [], stateName: "Louisiana" },
				{ cities: [], stateName: "Lowa" },
				{ cities: [], stateName: "Maine" },
				{ cities: [], stateName: "Maryland" },
				{ cities: [], stateName: "Massachusetts" },
				{ cities: [], stateName: "Medfield" },
				{ cities: [], stateName: "Michigan" },
				{ cities: [], stateName: "Minnesota" },
				{ cities: [], stateName: "Mississippi" },
				{ cities: [], stateName: "Missouri" },
				{ cities: [], stateName: "Montana" },
				{ cities: [], stateName: "Nebraska" },
				{ cities: [], stateName: "Nevada" },
				{ cities: [], stateName: "New Hampshire" },
				{ cities: [], stateName: "New Jersey" },
				{ cities: [], stateName: "New Jersy" },
				{ cities: [], stateName: "New Mexico" },
				{ cities: [], stateName: "New York" },
				{ cities: [], stateName: "North Carolina" },
				{ cities: [], stateName: "North Dakota" },
				{ cities: [], stateName: "Ohio" },
				{ cities: [], stateName: "Oklahoma" },
				{ cities: [], stateName: "Ontario" },
				{ cities: [], stateName: "Oregon" },
				{ cities: [], stateName: "Pennsylvania" },
				{ cities: [], stateName: "Ramey" },
				{ cities: [], stateName: "Rhode Island" },
				{ cities: [], stateName: "South Carolina" },
				{ cities: [], stateName: "South Dakota" },
				{ cities: [], stateName: "Sublimity" },
				{ cities: [], stateName: "Tennessee" },
				{ cities: [], stateName: "Texas" },
				{ cities: [], stateName: "Trimble" },
				{ cities: [], stateName: "Utah" },
				{ cities: [], stateName: "Vermont" },
				{ cities: [], stateName: "Virginia" },
				{ cities: [], stateName: "Washington" },
				{ cities: [], stateName: "West Virginia" },
				{ cities: [], stateName: "Wisconsin" },
				{ cities: [], stateName: "Wyoming" },
			],
			countryName: "United states",
		},
		{
			states: [{ cities: [], stateName: "United states Minor Outlying I" }],
			countryName: "United states Minor Outlying Islands",
		},
		{
			states: [
				{ cities: [], stateName: "Artigas" },
				{ cities: [], stateName: "Canelones" },
				{ cities: [], stateName: "Cerro Largo" },
				{ cities: [], stateName: "Colonia" },
				{ cities: [], stateName: "Durazno" },
				{ cities: [], stateName: "FLorida" },
				{ cities: [], stateName: "Flores" },
				{ cities: [], stateName: "Lavalleja" },
				{ cities: [], stateName: "Maldonado" },
				{ cities: [], stateName: "Montevideo" },
				{ cities: [], stateName: "Paysandu" },
				{ cities: [], stateName: "Rio Negro" },
				{ cities: [], stateName: "Rivera" },
				{ cities: [], stateName: "Rocha" },
				{ cities: [], stateName: "Salto" },
				{ cities: [], stateName: "San Jose" },
				{ cities: [], stateName: "Soriano" },
				{ cities: [], stateName: "Tacuarembo" },
				{ cities: [], stateName: "Treinta y Tres" },
			],
			countryName: "Uruguay",
		},
		{
			states: [
				{ cities: [], stateName: "Andijon" },
				{ cities: [], stateName: "Buhoro" },
				{ cities: [], stateName: "Buxoro Viloyati" },
				{ cities: [], stateName: "Cizah" },
				{ cities: [], stateName: "Fargona" },
				{ cities: [], stateName: "Horazm" },
				{ cities: [], stateName: "Kaskadar" },
				{ cities: [], stateName: "Korakalpogiston" },
				{ cities: [], stateName: "Namangan" },
				{ cities: [], stateName: "Navoi" },
				{ cities: [], stateName: "Samarkand" },
				{ cities: [], stateName: "Sirdare" },
				{ cities: [], stateName: "Surhondar" },
				{ cities: [], stateName: "Toskent" },
			],
			countryName: "Uzbekistan",
		},
		{
			states: [
				{ cities: [], stateName: "Malampa" },
				{ cities: [], stateName: "Penama" },
				{ cities: [], stateName: "Sanma" },
				{ cities: [], stateName: "Shefa" },
				{ cities: [], stateName: "Tafea" },
				{ cities: [], stateName: "Torba" },
			],
			countryName: "Vanuatu",
		},
		{
			states: [{ cities: [], stateName: "Vatican City State (Holy See)" }],
			countryName: "Vatican City State (Holy See)",
		},
		{
			states: [
				{ cities: [], stateName: "Amazonas" },
				{ cities: [], stateName: "Anzoategui" },
				{ cities: [], stateName: "Apure" },
				{ cities: [], stateName: "Aragua" },
				{ cities: [], stateName: "Barinas" },
				{ cities: [], stateName: "Bolivar" },
				{ cities: [], stateName: "Carabobo" },
				{ cities: [], stateName: "Cojedes" },
				{ cities: [], stateName: "Delta Amacuro" },
				{ cities: [], stateName: "Distrito Federal" },
				{ cities: [], stateName: "Falcon" },
				{ cities: [], stateName: "Guarico" },
				{ cities: [], stateName: "Lara" },
				{ cities: [], stateName: "Merida" },
				{ cities: [], stateName: "Miranda" },
				{ cities: [], stateName: "Monagas" },
				{ cities: [], stateName: "Nueva Esparta" },
				{ cities: [], stateName: "Portuguesa" },
				{ cities: [], stateName: "Sucre" },
				{ cities: [], stateName: "Tachira" },
				{ cities: [], stateName: "Trujillo" },
				{ cities: [], stateName: "Vargas" },
				{ cities: [], stateName: "Yaracuy" },
				{ cities: [], stateName: "Zulia" },
			],
			countryName: "Venezuela",
		},
		{
			states: [
				{ cities: [], stateName: "Bac Giang" },
				{ cities: [], stateName: "Binh Dinh" },
				{ cities: [], stateName: "Binh Duong" },
				{ cities: [], stateName: "Da Nang" },
				{ cities: [], stateName: "Dong Bang Song Cuu Long" },
				{ cities: [], stateName: "Dong Bang Song Hong" },
				{ cities: [], stateName: "Dong Nai" },
				{ cities: [], stateName: "Dong Nam Bo" },
				{ cities: [], stateName: "Duyen Hai Mien Trung" },
				{ cities: [], stateName: "Hanoi" },
				{ cities: [], stateName: "Hung Yen" },
				{ cities: [], stateName: "Khu Bon Cu" },
				{ cities: [], stateName: "Long An" },
				{ cities: [], stateName: "Mien Nui Va Trung Du" },
				{ cities: [], stateName: "Thai Nguyen" },
				{ cities: [], stateName: "Thanh Pho Ho Chi Minh" },
				{ cities: [], stateName: "Thu Do Ha Noi" },
				{ cities: [], stateName: "Tinh Can Tho" },
				{ cities: [], stateName: "Tinh Da Nang" },
				{ cities: [], stateName: "Tinh Gia Lai" },
			],
			countryName: "Vietnam",
		},
		{
			states: [
				{ cities: [], stateName: "Anegada" },
				{ cities: [], stateName: "Jost van Dyke" },
				{ cities: [], stateName: "Tortola" },
			],
			countryName: "Virgin Islands (British)",
		},
		{
			states: [
				{ cities: [], stateName: "Saint Croix" },
				{ cities: [], stateName: "Saint John" },
				{ cities: [], stateName: "Saint Thomas" },
			],
			countryName: "Virgin Islands (US)",
		},
		{
			states: [
				{ cities: [], stateName: "Alo" },
				{ cities: [], stateName: "Singave" },
				{ cities: [], stateName: "Wallis" },
			],
			countryName: "Wallis And Futuna Islands",
		},
		{
			states: [
				{ cities: [], stateName: "Bu Jaydur" },
				{ cities: [], stateName: "Wad-adh-Dhahab" },
				{ cities: [], stateName: "al-'Ayun" },
				{ cities: [], stateName: "as-Samarah" },
			],
			countryName: "Western Sahara",
		},
		{
			states: [
				{ cities: [], stateName: "'Adan" },
				{ cities: [], stateName: "Abyan" },
				{ cities: [], stateName: "Dhamar" },
				{ cities: [], stateName: "Hadramaut" },
				{ cities: [], stateName: "Hajjah" },
				{ cities: [], stateName: "Hudaydah" },
				{ cities: [], stateName: "Ibb" },
				{ cities: [], stateName: "Lahij" },
				{ cities: [], stateName: "Ma'rib" },
				{ cities: [], stateName: "Madinat San'a" },
				{ cities: [], stateName: "Sa'dah" },
				{ cities: [], stateName: "Sana" },
				{ cities: [], stateName: "Shabwah" },
				{ cities: [], stateName: "Ta'izz" },
				{ cities: [], stateName: "al-Bayda" },
				{ cities: [], stateName: "al-Hudaydah" },
				{ cities: [], stateName: "al-Jawf" },
				{ cities: [], stateName: "al-Mahrah" },
				{ cities: [], stateName: "al-Mahwit" },
			],
			countryName: "Yemen",
		},
		{
			states: [
				{ cities: [], stateName: "Central Serbia" },
				{ cities: [], stateName: "Kosovo and Metohija" },
				{ cities: [], stateName: "Montenegro" },
				{ cities: [], stateName: "Republic of Serbia" },
				{ cities: [], stateName: "Serbia" },
				{ cities: [], stateName: "Vojvodina" },
			],
			countryName: "Yugoslavia",
		},
		{
			states: [
				{ cities: [], stateName: "Central" },
				{ cities: [], stateName: "Copperbelt" },
				{ cities: [], stateName: "Eastern" },
				{ cities: [], stateName: "Luapala" },
				{ cities: [], stateName: "Lusaka" },
				{ cities: [], stateName: "North-Western" },
				{ cities: [], stateName: "Northern" },
				{ cities: [], stateName: "Southern" },
				{ cities: [], stateName: "Western" },
			],
			countryName: "Zambia",
		},
		{
			states: [
				{ cities: [], stateName: "Bulawayo" },
				{ cities: [], stateName: "Harare" },
				{ cities: [], stateName: "Manicaland" },
				{ cities: [], stateName: "Mashonaland Central" },
				{ cities: [], stateName: "Mashonaland East" },
				{ cities: [], stateName: "Mashonaland West" },
				{ cities: [], stateName: "Masvingo" },
				{ cities: [], stateName: "Matabeleland North" },
				{ cities: [], stateName: "Matabeleland South" },
				{ cities: [], stateName: "Midlands" },
			],
			countryName: "Zimbabwe",
		},
	],
};
