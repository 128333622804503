import React from "react";
import { useHistory } from "react-router-dom";
import MaterialTable from "material-table";

/* icon imports */
import tableIcons from "../../utils/materialTableIcons";

/* local imports */
import useEntityList from "../../utils/useEntityList";
import sizes from "../mockData/companySizes";

const OrganizationsTable = () => {
	const history = useHistory();

	const { data: organizationList, isLoading: orgLoading } = useEntityList({
		resource: "/organizations",
		params: {
			limit: 10,
			page: 1,
		},
	});

	return (
		<MaterialTable
			title=""
			icons={tableIcons}
			columns={[
				{ field: "name", title: "Organization Name" },
				{
					field: "industry",
					title: "Industry",
				},
				{
					field: "size",
					title: "Size",
					// eslint-disable-next-line
					render: (rowData) =>
						`${sizes.find((s) => s.value === rowData.size)?.name}`,
				},
				{
					field: "users",
					title: "Registered Users",
					render: (rowData) => `${rowData.users.length}`,
				},
				{
					field: "origin",
					title: "Based in",
					// eslint-disable-next-line
					render: (rowData) => `${rowData.city}, ${rowData.country}`,
				},
			]}
			actions={[
				{
					icon: tableIcons.Add,
					tooltip: "Add New Organization",
					isFreeAction: true,
					onClick: () => history.push("/home/organization/create"),
				},
			]}
			options={{
				search: false,
				actionsColumnIndex: 10,
				// exportButton: true,
				// searchFieldAlignment: "left",
			}}
			onRowClick={(e, rowData) =>
				history.push(`/home/organization/${rowData.id}`)
			}
			localization={{
				header: {
					actions: "",
				},
			}}
			isLoading={orgLoading}
			data={organizationList?.organizations || []}
		/>
	);
};

export default OrganizationsTable;
