import React from "react";
import { CircleMarker, Marker, GeoJSON, Popup, Tooltip } from "react-leaflet";
import { Typography } from "@material-ui/core";
import { NoMapDataAvailable } from "../Leaflet";

const getRouteMap = (args) => {
	const { geometry = null, terminals, markerIcon } = args;

	const getODMarkers = () => {
		const features = geometry?.features || [];
		if (features.length) {
			const line = features.find(
				(feature) => feature?.geometry?.type == "LineString"
			);
			const lastEl = line?.geometry?.coordinates?.length - 1;
			const lineStart = line?.geometry?.coordinates?.[0] || [0, 0];
			const lineEnd = line?.geometry?.coordinates?.[lastEl] || [0, 1];

			const originLatLng = L.marker([lineStart[1], lineStart[0]]).getLatLng();
			const destinationLatLng = L.marker([lineEnd[1], lineEnd[0]]).getLatLng();

			return (
				<div>
					<CircleMarker
						center={originLatLng}
						radius={10}
						color={"#3e3636"}
						fillOpacity={1}
						opacity={0.3}
						weight={10}
					/>
					<CircleMarker
						center={destinationLatLng}
						radius={10}
						color={"#ff1f5a"}
						fillOpacity={1}
						opacity={0.3}
						weight={17}
					/>
				</div>
			);
		}
		return null;
	};

	const getStopPoints = () => {
		if (geometry) {
			const stops = geometry?.features?.filter(
				(feature) =>
					feature.properties && feature.properties.type == "StopPoint"
			);
			return stops.map(({ geometry, properties }, key) => (
				<Marker
					key={key}
					icon={markerIcon}
					position={[geometry.coordinates[1], geometry.coordinates[0]]}
					offsetLeft={-20}
					offsetTop={-10}
				>
					<Popup minWidth={40}>
						<div>{`${properties.S_BOARD || 0} boarded, ${
							properties.S_ALIGHT || 0
						} alighted`}</div>
					</Popup>
				</Marker>
			));
		}
		return null;
	};

	const getTerminals = () =>
		terminals.map((terminal, key) => (
			<GeoJSON key={key} data={terminal.data}>
				<Tooltip permanent opacity={0.8}>
					<Typography>{terminal.label}</Typography>
				</Tooltip>
			</GeoJSON>
		));

	const renderLine = () => (
		<GeoJSON
			weight={5}
			data={geometry.features.find(
				(feature) => feature.geometry.type == "LineString"
			)}
		/>
	);

	if (!geometry && !terminals.length) {
		return <NoMapDataAvailable />;
	}
	return (
		<div>
			{geometry && getODMarkers()}
			{geometry && getStopPoints()}
			{terminals && getTerminals()}
			{geometry && renderLine()}
		</div>
	);
};
export default getRouteMap;
