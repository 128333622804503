import React from "react";
import { Grid, Button, MenuItem, TextField } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import qs from "qs";

/* local imports */
import TwoColumnForm from "../shared/TwoColumnForm";
import industries from "../mockData/industryList";
import sizes from "../mockData/companySizes";
import countries from "../mockData/countries";
import InlineAlert from "../shared/InlineAlert";

import { axiosPrivate } from "../../utils/api";

const OrganizationSettings = (props) => {
	const { orgID, orgLoading, preload: org, setPreload } = props;
	const { handleSubmit, errors, control, watch, reset } = useForm({
		defaultValues: {
			organization: { ...org?.organization },
		},
		mode: "onBlur",
	});
	const watchCountry = watch("organization.country");
	const [result, setResult] = React.useState({ res: null, err: null });

	const onSubmit = (data) => {
		axiosPrivate
			.put(`/organizations/${orgID}`, {
				...data.organization,
				params: { organization: orgID },
			})
			.then((res) => {
				console.log("PUT return :>> ", res.data);
				// dispatch(refetchProjects({ token: authToken, orgId }));
				setResult((r) => ({ ...r, res: res.data }));
				setTimeout(() => {
					setResult((r) => ({ ...r, res: null }));
				}, 1500);
			})
			.catch((err) => {
				setResult((r) => ({ ...r, err: err.response.data }));
				setTimeout(() => {
					setResult((r) => ({ ...r, err: null }));
				}, 2000);
			});
	};

	React.useEffect(() => {
		if (result.res) {
			setPreload(result.res.organization);
			reset({ organization: result.res.organization });
		}
	}, [result, reset, setPreload]);

	React.useEffect(() => {
		if (org) {
			console.log("org has changed");
			reset(org);
		}
	}, [reset, org]);

	return (
		<>
			<TwoColumnForm
				title="Settings"
				subtitle=""
				onSubmit={onSubmit}
				handleSubmit={handleSubmit}
				isLoading={orgLoading}
				cardActions={() => (
					<Button variant="contained" color="primary" type="submit">
						Save
					</Button>
				)}
			>
				<InlineAlert type="success" showAlert={result.res}>
					{result.res?.message
						? result.res.message
						: "Your changes have been saved."}
				</InlineAlert>
				<InlineAlert type="error" showAlert={result.err}>
					{result.err?.message
						? result.err.message
						: "An error occured while saving, please contact an Administrator."}
				</InlineAlert>
				<Grid container spacing={1}>
					<Grid item xs={12} md={6}>
						<Controller
							as={
								<TextField
									// inputRef={register}
									variant="outlined"
									fullWidth
									margin="dense"
									defaultValue={org?.organization?.name || ""}
									label="Organization Name"
									name="organization.name"
									error={!!errors.organization?.name}
									helperText={errors?.organization?.name?.message}
								/>
							}
							defaultValue={org?.organization?.name || ""}
							label="Organization Name"
							name="organization.name"
							control={control}
						/>
					</Grid>
					<Grid item xs={12} md={4}>
						<Controller
							as={
								<TextField
									variant="outlined"
									fullWidth
									select
									margin="dense"
									error={!!errors.org_industry}
									helperText={errors?.org_industry?.message}
								>
									{industries.map((industry) => (
										<MenuItem
											key={industry.value
												.toLowerCase()
												.replace(/[^A-Z0-9]+/gi, "_")}
											value={industry.value
												.toLowerCase()
												.replace(/[^A-Z0-9]+/gi, "_")}
										>
											{industry.name}
										</MenuItem>
									))}
								</TextField>
							}
							defaultValue={org?.organization?.industry || ""}
							label="Industry"
							name="organization.industry"
							control={control}
						/>
					</Grid>
					<Grid item xs={12} md={2}>
						<Controller
							as={
								<TextField
									variant="outlined"
									fullWidth
									select
									margin="dense"
									error={!!errors.org_size}
									helperText={errors?.org_size?.message}
								>
									{sizes.map((option) => (
										<MenuItem key={option.value} value={option.value}>
											{option.name}
										</MenuItem>
									))}
								</TextField>
							}
							defaultValue={org?.organization?.size || ""}
							label="Size"
							name="organization.size"
							control={control}
						/>
					</Grid>
					<Grid item xs={12} md={4}>
						<Controller
							as={
								<TextField
									variant="outlined"
									fullWidth
									select
									margin="dense"
									error={!!errors.organization?.country}
									helperText={errors?.organization?.country?.message}
								>
									{countries.countries.map((country) => (
										<MenuItem
											key={country.countryName}
											value={country.countryName}
										>
											{country.countryName}
										</MenuItem>
									))}
								</TextField>
							}
							rules={{
								required: {
									value: true,
									message: "We'd love to find out where you're based!",
								},
							}}
							defaultValue={org?.organization?.country || ""}
							label="Country"
							name="organization.country"
							control={control}
						/>
					</Grid>
					<Grid item xs={12} md={4}>
						<Controller
							as={
								<TextField
									variant="outlined"
									fullWidth
									select
									margin="dense"
									disabled={!watchCountry}
									error={!!errors.organization?.city}
									helperText={errors?.organization?.city?.message}
								>
									<MenuItem value="">Select city</MenuItem>
									{countries.countries
										.filter(
											(country) => country.countryName === watchCountry
										)[0]
										?.states.map((state) => (
											<MenuItem key={state.stateName} value={state.stateName}>
												{state.stateName}
											</MenuItem>
										))}
								</TextField>
							}
							rules={{
								required: {
									value: true,
									message: "The city associated with the project is required",
								},
							}}
							defaultValue={org?.organization?.city || ""}
							label="City"
							name="organization.city"
							control={control}
						/>
					</Grid>
				</Grid>
			</TwoColumnForm>
		</>
	);
};

export default OrganizationSettings;
