import React from "react";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { makeStyles, LinearProgress } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import MaterialTable from "material-table";
import * as _datefns from "date-fns";

/* icon imports */
import { useFetchOnce, tableIcons, useEntityList } from "../../utils";

/* local imports */

const useStyles = makeStyles((theme) => ({
	lowAuth: {
		backgroundColor: theme.palette.twind.red[300],
	},
	medAuth: {
		backgroundColor: theme.palette.twind.orange[300],
	},
	highAuth: {
		backgroundColor: theme.palette.twind.teal[300],
	},
	barColor: {
		backgroundColor: theme.palette.twind.gray[200],
	},
}));

const RolesTable = () => {
	const classes = useStyles();
	const history = useHistory();
	const { id: orgID } = useParams();
	const { data: perms, isFetching: permsLoading } = useFetchOnce({
		resource: "/acl/permissions",
		params: { limit: "all", grouped: true, organization: orgID },
	});
	const isAdmin = useSelector((state) => state.user.isAdmin);

	const { data: rolesList, isLoading: rolesLoading } = useEntityList({
		resource: "/acl/roles",
		params: {
			limit: 10,
			page: 1,
			organization: orgID,
		},
	});

	return (
		<MaterialTable
			title=""
			icons={tableIcons}
			columns={[
				{ field: "name", title: "Role Name" },
				{
					field: "permissions",
					title: "Authority Level",
					// eslint-disable-next-line
					render: (rowData) => {
						const authLevel = Math.round(
							(rowData.permissions.length / perms?.count) * 100
						);
						const authColor =
							authLevel > 90
								? classes.highAuth
								: authLevel > 60
								? classes.medAuth
								: classes.lowAuth;
						return (
							<LinearProgress
								variant="determinate"
								value={authLevel}
								classes={{
									colorPrimary: classes.barColor,
									barColorPrimary: authColor,
								}}
							/>
						);
					},
				},
				{
					field: "created_at",
					title: "Created On",
					// eslint-disable-next-line
					render: (rowData) => (
						<div>
							{_datefns.formatDistanceToNow(new Date(rowData.created_at))} ago
						</div>
					),
				},
			]}
			actions={[
				{
					icon: tableIcons.Add,
					tooltip: "Add New Role",
					isFreeAction: true,
					onClick: () =>
						history.push({
							pathname: "/home/role/create",
							state: { organization: orgID },
						}),
					disabled: !isAdmin,
				},
			]}
			options={{
				search: false,
				actionsColumnIndex: 10,
				// exportButton: true,
				// searchFieldAlignment: "left",
			}}
			onRowClick={(e, rowData) =>
				history.push({
					pathname: `/home/role/${rowData.id}`,
					state: {
						name: rowData.name,
						permissions: rowData.permissions,
						organization: orgID,
					},
				})
			}
			localization={{
				header: {
					actions: "",
				},
			}}
			isLoading={rolesLoading}
			data={rolesList?.roles ?? []}
		/>
	);
};

export default RolesTable;
