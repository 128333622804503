import _ from "lodash";
import React from "react";
import { Typography } from "@material-ui/core";
import { CircleMarker, GeoJSON, useMap, Tooltip } from "react-leaflet";

import { NoMapDataAvailable } from "../Leaflet";
import theme from "@/theme";

const geoToFeature = (geometry, properties = {}) => ({
	type: "Feature",
	geometry,
	properties,
});

const getIdSightingsMap = ({ sightingsMapData }) => {
	const {
		sightings,
		selectedSightingId,
		nearbyTerminals,
		terminalView,
		focusedTerminal,
	} = sightingsMapData;
	const map = useMap();
	const nearbyTerminalRefs = React.useRef({});

	React.useEffect(() => {
		const layer = _.get(nearbyTerminalRefs.current, [focusedTerminal]);
		if (layer) {
			map.fitBounds(layer.getBounds(), { animate: true });
		}
	}, [focusedTerminal]);

	const terminalFeatures = {
		origin: nearbyTerminals?.origin?.map((t) =>
			geoToFeature(_.omit(t.geometry, "crs"), _.omit(t, "geometry"))
		),
		destination: nearbyTerminals?.destination?.map((t) =>
			geoToFeature(_.omit(t.geometry, "crs"), _.omit(t, "geometry"))
		),
	};

	if (!sightings?.length) {
		return <NoMapDataAvailable />;
	}

	return (
		<div>
			{/* <LayersControl position="topright">
        <LayersControl.Overlay name="Valid Terminals Near Sightings"> */}
			{terminalFeatures?.origin?.map((t) => (
				<NearbyTerminal
					name={t.properties.name}
					geometry={t}
					showTooltip={terminalView}
					proximity="origin"
					layerRef={(layer) =>
						(nearbyTerminalRefs.current[t.properties.id] = layer)
					}
				/>
			))}
			{terminalFeatures?.destination?.map((t) => (
				<NearbyTerminal
					name={t.properties.name}
					geometry={t}
					showTooltip={terminalView}
					proximity="destination"
					layerRef={(layer) =>
						(nearbyTerminalRefs.current[t.properties.id] = layer)
					}
				/>
			))}
			{/* </LayersControl.Overlay> */}
			{sightings?.map((s) => (
				<SightingLayer sighting={s} selected={selectedSightingId === s.id} />
			))}
			{/* </LayersControl> */}
		</div>
	);
};

export default getIdSightingsMap;

const invertLatLng = (coords) => [coords[1], coords[0]];

const SightingLayer = ({ sighting, selected }) => {
	const originColor = "#2A81CB";
	const destinationColor = "#2AAD27";
	const selectedColor = "#FFD326";
	const rejectedColor = theme.palette.twind.red[300];

	const rejected = sighting?.status === "rejected" ? true : false;

	const origin = sighting?.origin_id_terminal
		? {
				name: sighting.origin_id_terminal?.name,
				type: "terminal",
				geometry: sighting.origin_id_terminal?.geometry,
				latlng: sighting.origin_id_terminal?.geometry.coordinates[0].map(
					(coords) => invertLatLng(coords)
				),
				color: originColor,
		  }
		: {
				name: sighting.raw_origin_name,
				type: "raw",
				latlng: invertLatLng(sighting?.raw_origin_geometry.coordinates),
				color: originColor,
		  };

	const destination = sighting?.destination_id_terminal
		? {
				name: sighting.destination_id_terminal?.name,
				type: "terminal",
				geometry: sighting.destination_id_terminal?.geometry,
				latlng: sighting.destination_id_terminal?.geometry.coordinates[0].map(
					(coords) => invertLatLng(coords)
				),
				color: destinationColor,
		  }
		: {
				name: sighting.raw_destination_name,
				type: "raw",
				latlng: invertLatLng(sighting.raw_destination_geometry.coordinates),
				color: destinationColor,
		  };
	const od = [origin, destination];

	return (
		<>
			{od.map(({ name, type, latlng, color, geometry }) => {
				const fillColor = selected
					? selectedColor
					: rejected
					? rejectedColor
					: color;
				return type === "raw" ? (
					<CircleMarker
						pathOptions={{
							color: fillColor,
							fillOpacity: 0.7,
							opacity: 0.3,
						}}
						center={latlng}
						radius={7}
						weight={7}
					>
						{selected ? (
							<Tooltip permanent opacity={0.8}>
								<Typography variant="overline">Suggested</Typography>
								<Typography variant="h5">{name}</Typography>
							</Tooltip>
						) : null}
					</CircleMarker>
				) : type === "terminal" ? (
					<GeoJSON
						data={geometry}
						pathOptions={{
							color: fillColor,
							fillOpacity: selected ? 0.9 : 0.7,
							opacity: 0.3,
						}}
					>
						{selected ? (
							<Tooltip permanent opacity={0.8}>
								<Typography variant="overline">Terminal</Typography>
								<Typography variant="h5">{name}</Typography>
							</Tooltip>
						) : null}
					</GeoJSON>
				) : null;
			})}
		</>
	);
};

const GeoJSONWithPermamentTooltip = ({
	permanent,
	geoJSONProps,
	tooltipProps,
	children,
}) => (
	<>
		{!permanent ? (
			<GeoJSON {...geoJSONProps}>
				<Tooltip {...tooltipProps}>{children}</Tooltip>
			</GeoJSON>
		) : null}
		{permanent ? (
			<GeoJSON {...geoJSONProps}>
				<Tooltip {...tooltipProps} permanent>
					{children}
				</Tooltip>
			</GeoJSON>
		) : null}
	</>
);

const NearbyTerminal = ({
	name,
	geometry,
	showTooltip,
	proximity,
	layerRef,
}) => (
	<GeoJSONWithPermamentTooltip
		geoJSONProps={{
			ref: layerRef,
			data: geometry,
			pathOptions: {
				color: theme.palette.twind.gray[700],
				fillOpacity: 0.9,
				opacity: 0.3,
			},
		}}
		tooltipProps={{
			placement: "top",
			opacity: 0.8,
		}}
		permanent={showTooltip}
	>
		<Typography variant="overline">
			{`Terminal Near ${
				proximity === "origin"
					? "Origin"
					: proximity === "destination"
					? "Destination"
					: "Unknown"
			}`}
		</Typography>
		<Typography variant="h5">{name}</Typography>
	</GeoJSONWithPermamentTooltip>
);

// <GeoJSON
//   data={t}
//   pathOptions={{
//     color: theme.palette.twind.gray[700],
//     fillOpacity: 0.9,
//     opacity: 0.3,
//   }}
// >
//   <Tooltip permanent={terminalView} opacity={0.8}>
//     <Typography variant="overline">Terminal Near Origin</Typography>
//     <Typography variant="h5">{t.properties.name}</Typography>
//   </Tooltip>
// </GeoJSON>;
