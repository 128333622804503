import React from "react";
import { Grid, Container, Typography, Divider } from "@material-ui/core";

/* local imports */
import { useStyles } from "./styles";
import OrganizationsTable from "./OrganizationsTable";
import RoleTemplatesTable from "./RoleTemplatesTable";
import Footer from "../../layout/Footer";

/* federated imports */
/* eslint-disable */
import { TitleBar } from "@/shared-components";
/* eslint-enable */

const AdminDashboard = () => {
	const classes = useStyles();
	return (
		<main className={classes.root}>
			<TitleBar
				maxWidth="lg"
				renderTitle={() => <div>Dashboard</div>}
				overline="RouteLab overview"
				// renderActions={() => <SearchInput placeholder="Find Service" />}
			/>
			<Container maxWidth="lg" className={classes.content}>
				<Grid container spacing={8}>
					<Grid item xs={2}>
						<Typography variant="button">Organizations</Typography>
					</Grid>
					<Grid item xs={10}>
						<OrganizationsTable />
					</Grid>
				</Grid>
				<Divider style={{ margin: "3rem 0" }} />
				<Grid container spacing={8}>
					<Grid item xs={2}>
						<Typography variant="button">Role Templates</Typography>
					</Grid>
					<Grid item xs={10}>
						<RoleTemplatesTable />
					</Grid>
				</Grid>
				<Divider style={{ margin: "3rem 0" }} />
				<Footer />
			</Container>
		</main>
	);
};

export default AdminDashboard;
