import React from "react";
import {
	Button,
	Container,
	Grid,
	makeStyles,
	MenuItem,
} from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

/* local imports */
import TwoColumnForm from "../shared/TwoColumnForm";
import userList from "../mockData/userList";
import industries from "../mockData/industryList";
import sizes from "../mockData/companySizes";
import countries from "../mockData/countries";

/* federated imports */
/* eslint-disable */
import { TitleBar, Form, Input } from "@/shared-components";
/* eslint-enable */

const useStyles = makeStyles((theme) => ({
	content: {
		marginTop: "2rem",
	},
}));
const CreateOrganization = (props) => {
	const classes = useStyles();
	// const history = useHistory();
	// const dispatch = useDispatch();
	// const authToken = useSelector((state) => state.auth.accessToken);
	// const orgId = useSelector((state) => state.user.organization.id);
	// const [result, setResult] = React.useState({ res: null, err: null });

	// const onSubmit = (data) => {
	//   const config = {
	//     method: "POST",
	//     headers: {
	//       Authorization: authToken,
	//       "Content-Type": "application/json",
	//       "x-client-type": "RouteLab-v1.0-alpha",
	//     },
	//     body: JSON.stringify(data.project),
	//   };

	//   fetch(`${baseURL}/organizations/${orgId}/projects`, config)
	//     .then((res) => {
	//       if (!res.ok) {
	//         throw Error(res.statusText);
	//       }
	//       return res.json();
	//     })
	//     .then((data) => {
	//       console.log("post return :>> ", data);
	//       dispatch(refetchProjects({ token: authToken, orgId }));
	//       setResult((r) => ({ ...r, res: data }));
	//       setTimeout(() => {
	//         history.push("/home");
	//       }, 1500);
	//     })
	//     .catch((err) => {
	//       setResult((r) => ({ ...r, err }));
	//       setTimeout(() => {
	//         setResult((r) => ({ ...r, err: null }));
	//       }, 2000);
	//     });
	// };
	const { register, handleSubmit, errors, control, watch } = useForm({
		mode: "onBlur",
	});
	const watchCountry = watch("organization.country");

	return (
		<>
			<TitleBar
				maxWidth="lg"
				// renderTitle={() => <div>{selectedUser.name}</div>}
				// overline="Edit Role's Permissions"
				// renderActions={() => <SearchInput placeholder="Find Service" />}
				renderTitle={() => <div>Create new Organization</div>}
				overline=""
			/>
			<Container maxWidth="lg" className={classes.content}>
				<TwoColumnForm
					title="General"
					subtitle="Main organization credentails"
					handleSubmit={handleSubmit}
					onSubmit={onSubmit}
					cardActions={() => (
						<>
							<Button variant="contained" color="primary" type="submit">
								Save
							</Button>
						</>
					)}
				>
					<Grid container spacing={1}>
						<Grid item xs={12} md={6}>
							<Input
								className={classes.textField}
								ref={register({
									required: {
										value: true,
										message: "Please enter an organization name",
									},
								})}
								label="Company Name"
								name="organization.name"
								type="text"
								margin="dense"
								error={!!errors.organization?.name}
								helperText={errors?.organization?.name?.message}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<Controller
								as={
									<Input
										className={classes.textField}
										select
										margin="dense"
										error={!!errors.organization?.industry}
										helperText={errors?.organization?.industry?.message}
									>
										{industries.map((industry) => (
											<MenuItem
												key={industry.value
													.toLowerCase()
													.replace(/[^A-Z0-9]+/gi, "_")}
												value={industry.value
													.toLowerCase()
													.replace(/[^A-Z0-9]+/gi, "_")}
											>
												{industry.name}
											</MenuItem>
										))}
									</Input>
								}
								defaultValue="transportation"
								label="Industry"
								name="organization.industry"
								control={control}
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<Controller
								as={
									<Input
										className={classes.textField}
										select
										margin="dense"
										error={!!errors.organization?.size}
										helperText={errors?.organization?.size?.message}
									>
										{sizes.map((option) => (
											<MenuItem key={option.value} value={option.value}>
												{option.name}
											</MenuItem>
										))}
									</Input>
								}
								label="Size"
								name="organization.size"
								control={control}
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<Controller
								as={
									<Input
										className={classes.textField}
										select
										margin="dense"
										error={!!errors.organization?.country}
										helperText={errors?.organization?.country?.message}
									>
										{countries.countries.map((country) => (
											<MenuItem
												key={country.countryName}
												value={country.countryName}
											>
												{country.countryName}
											</MenuItem>
										))}
									</Input>
								}
								rules={{
									required: {
										value: true,
										message: "We'd love to find out where you're based!",
									},
								}}
								label="Country"
								name="organization.country"
								control={control}
							/>
						</Grid>
						{/* {watchCountry && ( */}
						<Grid item xs={12} md={4}>
							<Controller
								as={
									<Input
										className={classes.textField}
										select
										defaultValue=""
										margin="dense"
										disabled={!watchCountry}
										error={!!errors.organization?.city}
										helperText={errors?.organization?.city?.message}
									>
										<MenuItem value="">Select city</MenuItem>
										{countries.countries
											.filter(
												(country) => country.countryName === watchCountry
											)[0]
											?.states.map((state) => (
												<MenuItem key={state.stateName} value={state.stateName}>
													{state.stateName}
												</MenuItem>
											))}
									</Input>
								}
								rules={{
									required: {
										value: true,
										message: "The city associated with the project is required",
									},
								}}
								label="City"
								name="organization.city"
								control={control}
							/>
						</Grid>
						{/* )} */}

						{/* {isLoading && (
<CircularProgress
  className={classes.signInButton}
  size={25}
/>
)} */}
					</Grid>
				</TwoColumnForm>

				{/* <Divider style={{ margin: "3rem 0" }} /> */}
			</Container>
		</>
	);
};

export default CreateOrganization;
