import useSWR from "swr";
import qs from "qs";

/* local imports */
import { axiosPrivate } from "./api";

const useEntityList = ({
	resource = "/",
	params = null,
	fetchCondition = true,
}) => {
	const targetURL = `${resource}${params ? "?" + qs.stringify(params) : ""}`;
	const { data, error } = useSWR(fetchCondition ? [targetURL] : null, (url) =>
		axiosPrivate
			.get(url)
			.then((res) => {
				// console.log("RESPONSE", res);
				return res.data.data;
			})
			.catch((err) => {
				console.log("ERR", err);
				throw err;
			})
	);

	return { data: data, isLoading: !error && !data, isError: error };
};

export default useEntityList;
