import React, { useEffect } from "react";
import {
	AppBar,
	Toolbar,
	Typography,
	CssBaseline,
	makeStyles,
	IconButton,
} from "@material-ui/core";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as _datefns from "date-fns";

/* icon imports */
import AccountIcon from "@material-ui/icons/AccountCircleSharp";
import AppsIcon from "@material-ui/icons/AppsSharp";
import MapIcon from "@material-ui/icons/MapSharp";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import MenuIcon from "@material-ui/icons/Reorder";
import ShareIcon from "@material-ui/icons/Share";

/* federated imports */
/* eslint-disable */
import theme from "@/theme";
import {
	logout,
	setActiveProject,
	setActiveDataset,
	setSidebarOpen,
} from "@/redux";
import { IconMenuButton, TextMenuButton } from "@/shared-components";
/* eslint-enable */

const useStyles = makeStyles(() => ({
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		display: "flex",
		flexDirection: "row",
		alignContent: "center",
		justifyContent: "space-between",
	},
	listRoot: {
		height: "100%",
		width: 325,
	},
	menuWidth: 325,
	ml_2: { marginLeft: "2rem" },
	mr_2: { marginRight: "2rem" },
	mr_1: { marginRight: "0.5rem" },
	ml_1: { marginLeft: "0.5rem" },
	logoCollapsed: {
		width: 148,
		textAlign: "left",
		paddingLeft: "2rem",
		textDecoration: "none",
		borderRight: (props) =>
			props.hasSwitcher ? `1px solid ${theme.palette.twind.gray[800]}` : "",
	},
	logoLink: {
		width: 192,
		textAlign: "center",
		textDecoration: "none",
		borderRight: (props) =>
			props.hasSwitcher ? `1px solid ${theme.palette.twind.gray[800]}` : "",
	},
}));

const PORT = "port";
const OBSERVER = "observer";
const SNAPPER = "snapper";

const Header = () => {
	const dispatch = useDispatch();
	const { service } = useParams();
	const classes = useStyles({ hasSwitcher: !!service });
	const orgID = useSelector((state) => state?.user?.organization?.id);
	const activeProject = useSelector((state) => state?.user?.activeProject);
	const activeDataset = useSelector((state) => state?.user?.activeDataset);
	const isAdmin = useSelector((state) => state?.user?.isAdmin);

	const handleSignOut = (e) => {
		e.preventDefault();
		dispatch(logout());
	};
	// console.log({ service });
	let services = [
		{
			name: "Observer",
			component: Link,
			to: `/${activeProject?.id}/observer/home`,
			startIcon: MapIcon,
		},
		{
			name: "Port",
			component: Link,
			to: `/${activeDataset?.id}/port/home`,
			startIcon: ShareIcon,
		},
		// {
		//   name: "Lens",
		//   component: Link,
		//   to: `/${activeProject?.id}/lens`,
		//   startIcon: EyeIcon,
		// },
		// {
		//   name: "Snapr",
		//   component: Link,
		//   to: `/${activeProject?.id}/snapr`,
		//   startIcon: TimelineIcon,
		// },
		// {
		//   name: "Authentication",
		//   component: Link,
		//   to: "/login",
		//   startIcon: LockIcon,
		// },
	];
	if (!activeProject) {
		services.map((s) => (s.to = "/home/project/create"));
	}
	const adminPanel = {
		name: "Admin Panel",
		component: Link,
		to: "/admin",
		startIcon: AccountTreeIcon,
	};
	const serviceSwitcherItems = isAdmin
		? [...services, adminPanel]
		: [...services];

	const hasSidebar = useSelector((state) => state.global.hasSidebar);
	const isSidebarOpen = useSelector((state) => state.global.isSidebarOpen);
	const handleSidebar = () => {
		dispatch(setSidebarOpen(!isSidebarOpen));
	};

	return (
		<React.StrictMode>
			{/* <ThemeProvider theme={theme}> */}
			<CssBaseline />
			<AppBar position="fixed" className={classes.appBar}>
				<Toolbar variant="dense" disableGutters>
					{hasSidebar && (
						<IconButton
							color="inherit"
							aria-label="menu"
							// className={classes.ml_1}
							onClick={handleSidebar}
						>
							<MenuIcon fontSize="small" />
						</IconButton>
					)}
					<Link
						to="/home"
						className={!hasSidebar ? classes.logoLink : classes.logoCollapsed}
						// className={classes.logoCollapsed}
					>
						<Typography
							variant="h6"
							style={{ color: theme.palette.twind.gray[100] }}
						>
							Route<b>Lab</b>
						</Typography>
					</Link>
					<Switcher service={service} />
				</Toolbar>
				<Toolbar variant="dense">
					<IconMenuButton
						id="account-settings"
						className={classes.mr_1}
						iconType={AccountIcon}
						items={[
							{
								name: "My Account",
								component: Link,
								to: "/home/user/me",
							},
							{
								name: "My Organization",
								component: Link,
								to: `/home/organization/${orgID}`,
							},
							{
								name: "Billing & Quota",
								component: Link,
								to: "/home/billing",
								disabled: true,
							},
							{ name: "Sign Out", handleClick: handleSignOut },
						]}
					/>
					<IconMenuButton
						id="service-switcher"
						iconType={AppsIcon}
						items={serviceSwitcherItems}
					/>
				</Toolbar>
			</AppBar>
			{/* </ThemeProvider> */}
		</React.StrictMode>
	);
};

export default Header;

const Switcher = (props) => {
	const { service } = props;
	console.log(service);
	switch (service) {
		case OBSERVER:
		case SNAPPER:
			return <ProjectSwitcher {...props} />;
		case PORT:
			return <DatasetSwitcher {...props} />;

		default:
			return null;
	}
};
const ProjectSwitcher = ({ service }) => {
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const activeProject = useSelector((state) => state?.user?.activeProject);
	const projectList = useSelector((state) => state?.user?.projects);
	const { id } = useParams();

	useEffect(() => {
		const projectFromURL = projectList.find((p) => p.id === id);
		if (projectFromURL === undefined) history.push("/home");
		else dispatch(setActiveProject(projectFromURL));
	}, [id]);
	const projectDropdownItems = projectList.map((p) => ({
		...p,
		name: `${p.name} in ${p.city}, ${p.country}`,
		value: `Created on ${_datefns.format(new Date(p.created_at), "PPPP")}`,
		handleItemClick: () => {
			dispatch(setActiveProject(p));
			history.push(`/${p.id}/observer/home`);
		},
		handleSettingsClick: () => history.push(`/home/project/${p.id}`),
	}));

	return (
		<TextMenuButton
			className={classes.ml_2}
			buttonText={`${activeProject?.name ?? "Create New Project"}`}
			items={projectDropdownItems}
			handleAddNew={() => history.push("/home/project/create")}
		/>
	);
};
const DatasetSwitcher = ({ service }) => {
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const activeDataset = useSelector((state) => state?.user?.activeDataset);
	const datasetList = useSelector((state) => state?.user?.datasets || []);

	const { id } = useParams();

	useEffect(() => {
		const datasetFromURL = datasetList.find((d) => d.id === id);
		if (datasetFromURL === undefined) history.push("/home");
		else dispatch(setActiveDataset(datasetFromURL));
	}, [id]);

	const datasetDropdownItems = datasetList.map((d) => ({
		...d,
		name: `${d.name}`,
		value: `Created on ${_datefns.format(new Date(d.created_at), "PPPP")}`,
		handleItemClick: () => {
			dispatch(setActiveDataset(d));
			history.push(`/${d.id}/port/home`);
		},
		handleSettingsClick: () => history.push(`/home/dataset/${d.id}`),
	}));

	return (
		<TextMenuButton
			className={classes.ml_2}
			buttonText={`${activeDataset?.name ?? "Create New Dataset"}`}
			items={datasetDropdownItems}
			addNewText="Add new Dataset"
			handleAddNew={() => history.push("/home/dataset/create")}
		/>
	);
};
