import React from "react";
import { Switch, Route } from "react-router-dom";

/* local imports */
import Services from "./Home/Services";
import UserDetails from "./Users/UserDetails";
import RoleDetails from "./Roles/RoleDetails";
import CreateRole from "./Roles/CreateRole";
import OrganizationDetails from "./Organization/OrganizationDetails";
import CreateOrganization from "./Organization/CreateOrganization";
import { CreateProject, ProjectDetails } from "./Project";
import { CreateDataset, DatasetDetails } from "./Dataset";

const UserRoutes = (props) => (
	<Switch>
		<Route
			exact
			path={`${props.basename}/user/create`}
			render={(props) => <UserDetails {...props} pageType="create" />}
		/>
		<Route
			exact
			path={`${props.basename}/user/me`}
			render={(props) => <UserDetails {...props} pageType="myAccount" />}
		/>
		<Route
			path={`${props.basename}/user/:id`}
			component={UserDetails}
			pageType="edit"
		/>
	</Switch>
);
const RBACRoutes = (props) => (
	<Switch>
		<Route
			exact
			path={`${props.basename}/role/create`}
			component={CreateRole}
		/>
		<Route path={`${props.basename}/role/:id`} component={RoleDetails} />
	</Switch>
);
const OrganizationRoutes = (props) => (
	<Switch>
		<Route
			exact
			path={`${props.basename}/organization/create`}
			component={CreateOrganization}
		/>
		<Route
			path={`${props.basename}/organization/:id`}
			component={OrganizationDetails}
		/>
	</Switch>
);

const ProjectRoutes = (props) => (
	<Switch>
		<Route
			exact
			path={`${props.basename}/project/create`}
			component={CreateProject}
		/>
		<Route path={`${props.basename}/project/:id`} component={ProjectDetails} />
	</Switch>
);
const DatasetRoutes = (props) => (
	<Switch>
		<Route
			exact
			path={`${props.basename}/dataset/create`}
			component={CreateDataset}
		/>
		<Route path={`${props.basename}/dataset/:id`} component={DatasetDetails} />
	</Switch>
);

const Routes = (props) => {
	const { basename } = props;
	return (
		<Switch>
			<Route
				render={(props) => <UserRoutes {...props} basename={basename} />}
				path={`${basename}/user`}
			/>
			<Route
				render={(props) => <RBACRoutes {...props} basename={basename} />}
				path={`${basename}/role`}
			/>
			<Route
				render={(props) => (
					<OrganizationRoutes {...props} basename={basename} />
				)}
				path={`${basename}/organization`}
			/>
			<Route
				render={(props) => <ProjectRoutes {...props} basename={basename} />}
				path={`${basename}/project`}
			/>
			<Route
				render={(props) => <DatasetRoutes {...props} basename={basename} />}
				path={`${basename}/dataset`}
			/>
			<Route path={`${basename}`} component={Services} />
		</Switch>
	);
};

export default Routes;
