import React, { useEffect } from "react";
import { Container, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

/* icon imports */

/* local imports */
import Routes from "../Routes";
import Footer from "../../layout/Footer";

/* federated imports */
/* eslint-disable */
// import theme from "@/theme";
/* eslint-enable */

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: 32,
		height: "100%",
		[theme.breakpoints.up("sm")]: {
			paddingTop: "3rem",
		},
		overflowX: "hidden",
	},
	content: {
		height: "100%",
	},
}));

const Home = () => {
	const classes = useStyles();
	// const auth = useSelector((state) => state.auth);
	// const user = useSelector((state) => state.user);
	// console.log("auth", auth);
	// console.log("user", user);
	// const dispatch = useDispatch();
	return (
		<div className={classes.root}>
			<ScrollToTop />
			<main className={classes.content}>
				<Routes basename="/home" />
				<Container maxWidth="lg">
					<Footer />
				</Container>
			</main>
		</div>
	);
};

export default Home;

const ScrollToTop = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return null;
};
