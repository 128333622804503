import React from "react";
import { Marker } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { NoMapDataAvailable } from "../Leaflet";
import("react-leaflet-markercluster/dist/styles.min.css");

const getMarkerClusterMap = (args) => {
	const { geometry } = args;
	if (!geometry) {
		return <NoMapDataAvailable />;
	}
	const swapCoords = (c) => [c[1], c[0]];
	return (
		<MarkerClusterGroup showCoverageOnHover={false}>
			{geometry.map((geo, idx) => (
				<Marker
					key={`markerCluster-m${idx}`}
					position={swapCoords(geo.coordinates)}
				/>
			))}
		</MarkerClusterGroup>
	);
};

export default getMarkerClusterMap;
