import React, { forwardRef } from "react";
import TextField from "@material-ui/core/TextField";

const Input = forwardRef(({ children, ...props }, ref) => {
	return (
		<TextField
			variant="outlined"
			margin="normal"
			inputRef={ref}
			fullWidth
			{...props}
		>
			{children}
		</TextField>
	);
});

export default Input;
