import React from "react";
import {
	Button,
	Container,
	Divider,
	FormControl,
	FormGroup,
	FormLabel,
	Grid,
	LinearProgress,
	makeStyles,
	Typography,
	useTheme,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

/* local imports */
import TwoColumnForm from "../shared/TwoColumnForm";
import FormCheckbox from "../shared/FormCheckbox";
import InlineAlert from "../shared/InlineAlert";
import { useFetchOnce } from "../../utils";

/* federated imports */
/* eslint-disable */
// import theme from "@/theme";
import { TitleBar, Form, Input } from "@/shared-components";
import { axiosPrivate } from "../../utils/api";
/* eslint-enable */

const useStyles = makeStyles((theme) => ({
	content: {
		marginTop: "2rem",
	},
	lowAuth: {
		backgroundColor: theme.palette.twind.red[300],
	},
	medAuth: {
		backgroundColor: theme.palette.twind.orange[300],
	},
	highAuth: {
		backgroundColor: theme.palette.twind.teal[300],
	},
	barColor: {
		backgroundColor: theme.palette.twind.gray[200],
	},
	floatingCard: {
		// padding: theme.spacing(2),
		"&:last-child": { padding: theme.spacing(2) },
	},
	sticky: {
		position: "fixed",
		top: 48,
		zIndex: 100,
	},
}));

const CreateRole = () => {
	const classes = useStyles();
	const theme = useTheme();
	const history = useHistory();
	const authToken = useSelector((state) => state.auth.accessToken);
	const orgId = useSelector((state) => state.user.organization.id);
	const preload = location?.state || {
		organization: orgId,
	};

	const { register, handleSubmit, errors, watch, setValue } = useForm({
		mode: "onBlur",
		defaultValues: {
			role: {
				permissions: [],
			},
		},
	});

	const watchSelectedPermissions = watch("role.permissions");
	const [selectedPermissions, setSelectedPermissions] = React.useState(
		new Set()
	);

	const handleSelect = (evt, newVal) => {
		console.log("selection made", newVal, evt.target.value);

		const uniqueSelections = selectedPermissions;
		if (newVal) {
			uniqueSelections.add(evt.target.value);
		} else {
			uniqueSelections.delete(evt.target.value);
		}
		setSelectedPermissions(uniqueSelections);
	};

	const handleSelectAll = (groupKey) => {
		console.log("selectAll", groupKey);

		// update form State
		const prevValuesArr = [...selectedPermissions];
		const selectedGroupPerms = Object.entries(
			permsList.permissions[groupKey]
		).flatMap(([, permissions]) => permissions.map((p) => p._id));

		if (prevValuesArr) {
			setValue("role.permissions", [...prevValuesArr, ...selectedGroupPerms], {
				shouldValidate: true,
			});
		} else {
			setValue("role.permissions", selectedGroupPerms, {
				shouldValidate: true,
			});
		}

		// update local state so that UI re-renders and Checkbox updates
		let prevValuesSet = selectedPermissions;
		selectedGroupPerms.map((sp) => prevValuesSet.add(sp));
		setSelectedPermissions(prevValuesSet);
	};

	const {
		data: permsList,
		isFetching: permsLoading,
		error: permsErr,
	} = useFetchOnce({
		resource: "/acl/permissions",
		params: { limit: "all", grouped: true, organization: preload.organization },
	});

	// console.log(JSON.stringify(permsList), permsLoading, permsErr);

	//   console.log("formState :>> ", formState);
	// console.log("permsList :>> ", permsList);

	// const resourceGroups = Object.keys(permsList?.permissions ??);

	const authLevel = (selectedPermissions.size / permsList?.count) * 100 || 0;
	const authColor =
		authLevel > 80
			? classes.highAuth
			: authLevel > 50
			? classes.medAuth
			: classes.lowAuth;

	const [result, setResult] = React.useState({ res: null, err: null });
	const [isSubmitting, setIsSubmitting] = React.useState(false);
	const onSubmit = (data) => {
		setIsSubmitting(true);
		const params = { organization: preload.organization };
		axiosPrivate
			.post("/acl/roles", { ...data.role, params })
			.then((res) => {
				setResult((r) => ({ ...r, res: res.data }));
				setIsSubmitting(false);
				setTimeout(() => {
					history.push("/home");
				}, 1500);
			})
			.catch((err) => {
				setResult((r) => ({ ...r, err: err.response.data }));
				setIsSubmitting(false);
				setTimeout(() => {
					setResult((r) => ({ ...r, err: null }));
				}, 2000);
			});
	};

	// TODO: Figure out how to make first TwoColumnForm stick to top of page onScroll
	// const [isSticky, setSticky] = React.useState(false);
	// const floatingCardRef = React.useRef(null);
	// const handleScroll = (evt) => {
	//   console.log("scrolling!", evt.keyCode);
	//   if (floatingCardRef.current) {
	//     setSticky(floatingCardRef.current.getBoundingClientRect().top <= 0);
	//   }
	// };

	// React.useEffect(() => {
	//   window.addEventListener("scroll", handleScroll);
	//   console.log("mount event listener");

	//   return () => {
	//     window.removeEventListener("scroll", () => handleScroll);
	//     console.log("unmounting event listener");
	//   };
	// }, []);

	// console.log(isSticky, floatingCardRef);
	console.log("selectedPermissions :>> ", selectedPermissions);

	return (
		<>
			<TitleBar
				maxWidth="lg"
				renderTitle={() => <div>Create new role</div>}
				overline=""
			/>
			<Container maxWidth="lg" className={classes.content}>
				<TwoColumnForm
					title=""
					subtitle=""
					handleSubmit={handleSubmit}
					onSubmit={onSubmit}
					className={classes.floatingCard}
					// className={`${classes.floatingCard} ${
					//   isSticky ? `${classes.sticky}` : ""
					// }`}
					// cardActions={() => <></>}
				>
					<InlineAlert type="success" showAlert={result.res}>
						{result.res?.message
							? result.res.message
							: "Role created successfully."}
					</InlineAlert>
					<InlineAlert type="error" showAlert={result.err}>
						{result.err?.message
							? result.err.message
							: "An error occured while saving, please contact an Administrator."}
					</InlineAlert>
					<Grid container spacing={3} alignItems="center">
						<Grid item xs={12} md={5}>
							<Input
								ref={register({
									required: {
										value: true,
										message: "Role name cannot be left empty",
									},
								})}
								margin="dense"
								defaultValue=""
								label="Role Name"
								name="role.name"
								error={!!errors.role?.name}
								helperText={errors?.role?.name?.message}
							/>
						</Grid>
						<Grid item xs={12} md={5}>
							{/* authority level */}
							<Typography variant="body2" color="inherit">
								Authority Level
							</Typography>
							<LinearProgress
								variant="determinate"
								value={authLevel}
								style={{ marginTop: "0.5rem" }}
								classes={{
									colorPrimary: classes.barColor,
									barColorPrimary: authColor,
								}}
							/>
						</Grid>
						<Grid item container xs={12} md={2} justify="center">
							<Button
								variant="contained"
								color="primary"
								type="submit"
								fullWidth
								disabled={isSubmitting}
							>
								Submit
							</Button>
						</Grid>
					</Grid>
				</TwoColumnForm>
				<Divider style={{ margin: "3rem 0" }} />
				<TwoColumnForm
					title="General"
					subtitle="This set of permissions governs a user's access level across the entire RouteLab ecosystem"
					handleSubmit={handleSubmit}
					onSubmit={onSubmit}
					isLoading={permsLoading}
					cardActions={() => (
						<>
							<Button
								variant="text"
								color="inherit"
								onClick={() => handleSelectAll("General")}
							>
								Select All
							</Button>
						</>
					)}
				>
					<Grid container spacing={3}>
						{permsList &&
							Object.entries(permsList.permissions["General"]).map(
								([group, perms]) => (
									<Grid item xs={12} sm={6} md={4} key={group}>
										<FormControl component="fieldset">
											<FormLabel
												component="legend"
												style={{
													fontWeight: theme.typography.fontWeightBold,
												}}
											>
												{group}
											</FormLabel>
											<FormGroup>
												{perms.map((p) => (
													<FormCheckbox
														register={register}
														value={p}
														name={`role.permissions`}
														key={p?._id}
														onChange={handleSelect}
														checked={selectedPermissions.has(p?._id)}
													/>
												))}
											</FormGroup>
										</FormControl>
									</Grid>
								)
							)}
					</Grid>
				</TwoColumnForm>
				<Divider style={{ margin: "3rem 0" }} />
				<TwoColumnForm
					title="Access Control"
					subtitle="This set of permissions governs a user's ability to change Access Control Level settings"
					handleSubmit={handleSubmit}
					onSubmit={onSubmit}
					isLoading={permsLoading}
					cardActions={() => (
						<>
							<Button
								variant="text"
								color="inherit"
								onClick={() => handleSelectAll("Admin")}
							>
								Select All
							</Button>
						</>
					)}
				>
					<Grid container spacing={3}>
						{permsList &&
							Object.entries(permsList.permissions["Admin"]).map(
								([group, perms]) => (
									<Grid item xs={12} md={12} key={group}>
										<FormControl component="fieldset">
											<FormLabel
												component="legend"
												style={{
													fontWeight: theme.typography.fontWeightBold,
												}}
											>
												{group}
											</FormLabel>
											<FormGroup>
												{perms.map((p) => (
													<FormCheckbox
														register={register}
														value={p}
														name={`role.permissions`}
														key={p?._id}
														onChange={handleSelect}
														checked={selectedPermissions.has(p?._id)}
													/>
												))}
											</FormGroup>
										</FormControl>
									</Grid>
								)
							)}
					</Grid>
				</TwoColumnForm>
				<Divider style={{ margin: "3rem 0" }} />
				<TwoColumnForm
					title="Observer"
					subtitle="This set of permissions governs a user's access level within Observer"
					handleSubmit={handleSubmit}
					onSubmit={onSubmit}
					isLoading={permsLoading}
					cardActions={() => (
						<>
							<Button
								variant="text"
								color="inherit"
								onClick={() => handleSelectAll("Observer")}
							>
								Select All
							</Button>
						</>
					)}
				>
					<Grid container spacing={3}>
						{permsList &&
							Object.entries(permsList.permissions["Observer"]).map(
								([group, perms]) => (
									<Grid item xs={12} md={3} key={group}>
										<FormControl component="fieldset">
											<FormLabel
												component="legend"
												style={{
													fontWeight: theme.typography.fontWeightBold,
												}}
											>
												{group}
											</FormLabel>
											<FormGroup>
												{perms.map((p) => (
													<FormCheckbox
														register={register}
														value={p}
														name={`role.permissions`}
														key={p?._id}
														onChange={handleSelect}
														checked={selectedPermissions.has(p?._id)}
													/>
												))}
											</FormGroup>
										</FormControl>
									</Grid>
								)
							)}
					</Grid>
				</TwoColumnForm>
			</Container>
		</>
	);
};

export default CreateRole;
