import React, { useEffect } from "react";
import { CircleMarker, Marker, GeoJSON, Popup, Tooltip } from "react-leaflet";
import { Typography } from "@material-ui/core";
import { NoMapDataAvailable } from "../Leaflet";
import getGeoJSONMap from "./genericGeoJSON";

const getOnboardSurveyMap = (args) => {
	const {
		geometry,
		lineString,
		terminals,
		midStops,
		// fetchTripDistance,
	} = args;
	// // const features = _.get(geometry, "features", []);
	// const features = geometry?.features || [];
	// const line = features.find(
	//   (feature) => feature.geometry.type == "LineString"
	// );
	// const lastEl = line.geometry.coordinates.length - 1;
	// const lineStart = line?.geometry?.coordinates?.[0] || [0, 0];
	// const lineEnd = line?.geometry?.coordinates?.[lastEl] || [0, 1];
	// // const lineStart = _.get(line, ["geometry", "coordinates", 0], [0, 0]);
	// // const lineEnd = _.get(
	// //   line,
	// //   ["geometry", "coordinates", lastEl],
	// //   [0, 1]
	// // );
	// const originLatLng = L.marker([lineStart[1], lineStart[0]]).getLatLng();
	// const destinationLatLng = L.marker([lineEnd[1], lineEnd[0]]).getLatLng();

	// const stops = geometry.features.filter(
	//   (feature) => feature.properties && feature.properties.type == "StopPoint"
	// );

	const getODMarkers = () => {
		// const features = geometry?.features || [];
		if (lineString && lineString.coordinates.length) {
			// const line = features.find(
			//   (feature) => feature?.geometry?.type == "LineString"
			// );
			const lastEl = lineString?.coordinates?.length - 1;
			const lineStart = lineString?.coordinates?.[0] || [0, 0];
			const lineEnd = lineString?.coordinates?.[lastEl] || [0, 1];

			const originLatLng = L.marker([lineStart[1], lineStart[0]]).getLatLng();
			const destinationLatLng = L.marker([lineEnd[1], lineEnd[0]]).getLatLng();

			// useEffect(() => {
			//   fetchTripDistance(originLatLng, destinationLatLng, "km", 2);
			// }, []);

			return (
				<div>
					<CircleMarker
						center={originLatLng}
						radius={10}
						color={"#3e3636"}
						fillOpacity={1}
						opacity={0.3}
						weight={10}
					/>
					<CircleMarker
						center={destinationLatLng}
						radius={10}
						color={"#ff1f5a"}
						fillOpacity={1}
						opacity={0.3}
						weight={17}
					/>
				</div>
			);
		}
		return null;
	};

	const getStopPoints = () => {
		if (midStops.length) {
			return midStops.map((stop, index) => (
				<Marker
					key={index}
					position={[
						stop.geometry.coordinates[1],
						stop.geometry.coordinates[0],
					]}
					offsetLeft={-20}
					offsetTop={-10}
				>
					<Popup minWidth={40}>
						<div>{`${stop.board || 0} boarded, ${
							stop.alight || 0
						} alighted`}</div>
					</Popup>
				</Marker>
			));
		}
		return null;
	};

	const hasGeometry = (obj) => obj.data;
	const getTerminals = () =>
		terminals.map((terminal, key) => {
			if (terminal.data)
				return (
					<GeoJSON key={key} data={terminal.data}>
						<Tooltip permanent opacity={0.8}>
							<Typography>{terminal.label}</Typography>
						</Tooltip>
					</GeoJSON>
				);
		});

	const renderLine = () => <GeoJSON weight={5} data={lineString} />;

	if (
		!geometry &&
		!terminals.length &&
		!terminals.some(hasGeometry) &&
		!lineString?.coordinates?.length &&
		!midStops?.length
	) {
		return <NoMapDataAvailable />;
	}
	return (
		<div>
			{lineString?.coordinates?.length && getODMarkers()}
			{lineString?.coordinates?.length && renderLine()}
			{midStops.length && getStopPoints()}
			{terminals.length && getTerminals()}
			{geometry && getGeoJSONMap({ geometry })}
		</div>
	);
};

export default getOnboardSurveyMap;
