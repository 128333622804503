import React from "react";
import { Typography, Link as MUILink } from "@material-ui/core";

const CopyrightFooter = () => {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{"Copyright © "}
			<MUILink color="inherit" href="https://transportforcairo.com/">
				Transport for Cairo
			</MUILink>{" "}
			{new Date().getFullYear()}
			{"."}
		</Typography>
	);
};

export default CopyrightFooter;
