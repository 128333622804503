import React from "react";
import { Typography, useTheme, Container, makeStyles } from "@material-ui/core";
import Tag from "./Tag/Tag";
import theme from "@/theme";

const useStyles = makeStyles(() => ({
	root: {
		backgroundColor: theme.palette.twind.gray[1000],
		minHeight: "12rem",
		padding: "1.5rem",
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-end",
	},
	titleContainer: {
		display: "flex",
		justifyContent: "space-between",
		[theme.breakpoints.down("md")]: {
			flexDirection: "column",
		},
	},
	title: {
		display: "flex",
		maxWidth: "65%",
		[theme.breakpoints.down("md")]: {
			maxWidth: "100%",
		},
	},
	actionsContainer: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-end",
		[theme.breakpoints.down("md")]: {
			marginTop: "2rem",
		},
	},
	content: {
		marginTop: "3rem",
	},
	removeContentMargin: {
		marginTop: "3rem",
		marginLeft: 0,
		marginRight: 0,
	},
	tag: {
		display: "inline-block",
		marginLeft: "0.75rem",
		marginBottom: "0",
	},
}));

const TitleBar = (props) => {
	const {
		renderTitle = () => "",
		overline = "",
		tags = [
			{
				textColor: "#276749",
				bgColor: "#48BB78",
				text: "Finished",
				active: false,
			},
		],
		maxWidth = "xl",
		renderActions = () => {},
		disableCentered = false,
	} = props;
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Container
				maxWidth={maxWidth}
				className={
					disableCentered ? classes.removeContentMargin : classes.content
				}
			>
				<div className={classes.titleContainer}>
					<div className={classes.title}>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
							}}
						>
							<Typography variant="body2" style={{ color: "white" }}>
								{overline}
							</Typography>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
								}}
							>
								<Typography
									variant="h1"
									style={{
										color: "white",
										marginTop: "0.5rem",
										fontSize: "3rem",
										fontWeight: 600,
										lineHeight: "3.2rem",
									}}
								>
									{renderTitle()}
									{tags.map((tag, idx) => {
										const { active: showTag, ...tagProps } = tag;
										if (showTag)
											return (
												<Tag key={idx} className={classes.tag} {...tagProps} />
											);
									})}
								</Typography>
							</div>
						</div>
					</div>
					<div className={classes.actionsContainer}>{renderActions()}</div>
				</div>
			</Container>
		</div>
	);
};

export default TitleBar;
