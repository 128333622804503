import React from "react";
import MaterialTable from "material-table";
import * as _datefns from "date-fns";
import { useHistory, useParams } from "react-router-dom";

/* icon imports */
import tableIcons from "../../utils/materialTableIcons";

const ProjectsTable = (props) => {
	const history = useHistory();
	const { id: orgID } = useParams();
	const { projects } = props;

	return (
		<MaterialTable
			title=""
			icons={tableIcons}
			columns={[
				{ field: "name", title: "Project Name" },
				{
					field: "country",
					title: "Country",
				},
				{
					field: "city",
					title: "City",
				},
				{
					field: "updated_at",
					title: "Last Updated",
					// eslint-disable-next-line
					render: (rowData) =>
						`${_datefns.formatDistanceToNow(
							new Date(rowData?.updated_at || null)
						)} ago`,
				},
			]}
			actions={[
				{
					icon: tableIcons.Add,
					tooltip: "Add New Project",
					isFreeAction: true,
					onClick: () =>
						history.push({
							pathname: "/home/project/create",
							state: { orgID },
						}),
				},
			]}
			options={{
				search: false,
				actionsColumnIndex: 10,
				// exportButton: true,
				// searchFieldAlignment: "left",
			}}
			onRowClick={(_, rowData) => history.push(`/home/project/${rowData.id}`)}
			localization={{
				header: {
					actions: "",
				},
			}}
			data={projects || []}
		/>
	);
};

export default ProjectsTable;
