import { Button, CircularProgress } from "@material-ui/core";
import React from "react";
import TwoColumnForm from "./TwoColumnForm";

const TwoColumnFormSkeleton = (props) => {
	const { title } = props;
	return (
		<TwoColumnForm
			title={title}
			subtitle=""
			onSubmit={() => {}}
			handleSubmit={() => {}}
			cardActions={() => (
				<Button disabled variant="contained" color="primary" type="submit">
					Save
				</Button>
			)}
		>
			<div style={{ display: "flex", justifyContent: "center" }}>
				<CircularProgress size={25} />
			</div>
		</TwoColumnForm>
	);
};

export default TwoColumnFormSkeleton;
