import React from "react";
import {
	Button,
	Container,
	Grid,
	makeStyles,
	MenuItem,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

/* local imports */
import TwoColumnForm from "../shared/TwoColumnForm";
import countries from "../mockData/countries";
import timezones from "../mockData/timezones";
import InlineAlert from "../shared/InlineAlert";

/* federated imports */
/* eslint-disable */
import { TitleBar, Input } from "@/shared-components";
import { refetchProjects } from "@/redux";
import { axiosPrivate } from "../../utils/api";
/* eslint-enable */

const useStyles = makeStyles(() => ({
	content: {
		marginTop: "2rem",
	},
}));

const CreateProject = () => {
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const authToken = useSelector((state) => state.auth.accessToken);
	const orgId = useSelector((state) => state.user.organization.id);
	const [result, setResult] = React.useState({ res: null, err: null });

	const onSubmit = (data) => {
		const config = {
			method: "POST",
			headers: {
				Authorization: authToken,
				"Content-Type": "application/json",
				"x-client-type": "RouteLab-v1.0-alpha",
			},
			body: JSON.stringify(data.project),
		};

		axiosPrivate
			.post(`/organizations/${orgId}/projects`, { ...data.project })
			.then((res) => {
				console.log("POST return :>> ", res.data);
				dispatch(refetchProjects({ token: authToken, orgId }));
				setResult((r) => ({ ...r, res: res.data }));
				setTimeout(() => {
					history.push("/home");
				}, 1500);
			})
			.catch((err) => {
				setResult((r) => ({ ...r, err: err.response.data }));
				setTimeout(() => {
					setResult((r) => ({ ...r, err: null }));
				}, 2000);
			});
	};
	const { register, handleSubmit, errors, control, watch } = useForm({
		mode: "onBlur",
	});
	const watchCountry = watch("project.country", false);

	return (
		<>
			<TitleBar
				renderTitle={() => <div>Create new project</div>}
				maxWidth="lg"
				overline=""
			/>
			<Container maxWidth="lg" className={classes.content}>
				<TwoColumnForm
					title="Basic"
					subtitle="Initial project parameters"
					handleSubmit={handleSubmit}
					onSubmit={onSubmit}
					cardActions={() => (
						<>
							<Button variant="contained" color="primary" type="submit">
								Save
							</Button>
						</>
					)}
				>
					<InlineAlert type="success" showAlert={result.res}>
						{result.res?.message ? result.res.message : "Project created."}
					</InlineAlert>
					<InlineAlert type="error" showAlert={result.err}>
						{result.err?.message
							? result.err.message
							: "An error occured while saving, please contact an Administrator."}
					</InlineAlert>
					<Grid container spacing={1}>
						<Grid item xs={12} md={8}>
							<Input
								margin="dense"
								ref={register({
									required: {
										value: true,
										message: "Don't forget to give your project a name!",
									},
								})}
								label="Project name"
								name="project.name"
								type="text"
								error={!!errors.project?.name}
								helperText={errors?.project?.name?.message}
							/>
						</Grid>
						<Grid item xs={12} md={3}>
							<Controller
								as={
									<Input
										select
										margin="dense"
										ref={register}
										label="Language"
										name="project.lang"
										error={!!errors.project?.lang}
										helperText={errors?.project?.lang?.message}
									>
										<MenuItem value="en">English</MenuItem>
										<MenuItem value="ar">Arabic</MenuItem>
									</Input>
								}
								defaultValue="en"
								label="Language"
								name="project.lang"
								control={control}
							/>
						</Grid>
						<Grid item xs={12} md={5}>
							<Controller
								as={
									<Input
										margin="dense"
										select
										error={!!errors.project?.country}
										helperText={errors?.project?.country?.message}
									>
										{countries.countries.map((country) => (
											<MenuItem
												key={country.countryName}
												value={country.countryName}
											>
												{country.countryName}
											</MenuItem>
										))}
									</Input>
								}
								rules={{
									required: {
										value: true,
										message:
											"The country associated with the project is required",
									},
								}}
								label="Country"
								name="project.country"
								control={control}
							/>
						</Grid>

						<Grid item xs={12} md={4}>
							<Controller
								as={
									<Input
										margin="dense"
										select
										disabled={!watchCountry}
										error={!!errors.project?.city}
										helperText={errors?.project?.city?.message}
									>
										{countries.countries
											.filter(
												(country) => country.countryName === watchCountry
											)[0]
											?.states.map((state) => (
												<MenuItem key={state.stateName} value={state.stateName}>
													{state.stateName}
												</MenuItem>
											))}
									</Input>
								}
								rules={{
									required: {
										value: true,
										message: "The city associated with the project is required",
									},
								}}
								label="City"
								name="project.city"
								control={control}
							/>
						</Grid>
						<Grid item xs={12} md={3}>
							<Controller
								as={
									<Input
										margin="dense"
										select
										error={!!errors.project?.timezone}
										helperText={errors?.project?.timezone?.message}
									>
										{timezones.map((zone, idx) => (
											<MenuItem key={idx} value={zone.value}>
												{zone.label}
											</MenuItem>
										))}
									</Input>
								}
								rules={{
									required: {
										value: true,
										message:
											"The timezone associated with the project is required",
									},
								}}
								label="Timezone"
								name="project.timezone"
								control={control}
							/>
						</Grid>
					</Grid>
				</TwoColumnForm>

				{/* <Divider style={{ margin: "3rem 0" }} /> */}
			</Container>
		</>
	);
};

export default CreateProject;
