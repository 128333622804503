export default [
	{
		name: "Self-employed",
		value: "self_employed",
	},
	{
		name: "1-10 employees",
		value: "1_10",
	},
	{
		name: "11-50 employees",
		value: "11_50",
	},
	{
		name: "51-200 employees",
		value: "51_200",
	},
	{
		name: "201-500 employees",
		value: "201_500",
	},
	{
		name: "501-1000 employees",
		value: "501_1000",
	},
	{
		name: "1001-5000 employees",
		value: "1001_5000",
	},
	{
		name: "5001-10,000 employees",
		value: "5001_10000",
	},
	{
		name: "10,001+ employees",
		value: "10001",
	},
];
