import { configureStore } from "@reduxjs/toolkit";
import { loadState, saveState } from "../utils/localStorage";
import { authReducer, userReducer, globalReducer } from "./slices";

const persistedState = loadState("appState");

const store = configureStore({
	reducer: {
		global: globalReducer,
		auth: authReducer,
		user: userReducer,
	},
	preloadedState: persistedState,
});

store.subscribe(() => saveState("appState", store.getState()));

export default store;
