import React from "react";
import {
	Typography,
	makeStyles,
	useTheme,
	Box,
	Button,
	Popover,
	ListItem,
	ListItemText,
	IconButton,
	List as MUIList,
	Divider,
	CardActionArea,
	ListItemSecondaryAction,
} from "@material-ui/core";

/* icon imports */
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import AddIcon from "@material-ui/icons/Add";
import SettingsIcon from "@material-ui/icons/Settings";
import theme from "@/theme";

const useStyles = makeStyles(() => ({
	root: {
		height: "100%",
		width: 325,
	},
}));

const TextMenuButton = (props) => {
	const {
		className,
		buttonText,
		buttonSize = "small",
		items = [
			{
				id: 1,
				name: "Kampala, Uganda",
				value: "Created " + new Date().toLocaleDateString(),
				handleSettingsClick: () => alert("Open Kampala, Uganda's settings"),
				handleItemClick: () => alert("Switch to Kampala, Uganda"),
			},
			{
				id: 2,
				name: "Cape Town, South Africa",
				value: "Created " + new Date().toLocaleDateString(),
				href: () => alert("Open Cape Town, South Africa's settings"),
				handleItemClick: () => alert("Switch to Cape Town, South Africa"),
			},
		],
		addNewText = "Add new Project",
		handleAddNew,
	} = props;
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	return (
		<Box className={className}>
			<Button
				size={buttonSize}
				variant="text"
				color="inherit"
				onClick={handleClick}
				endIcon={<KeyboardArrowDownIcon />}
			>
				{buttonText}
			</Button>
			<Popover
				id={id}
				open={open}
				disableScrollLock={true}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
			>
				<div className={classes.root}>
					{Boolean(items.length) && (
						<>
							<MUIList>
								{items.map((item, i) => (
									<ListItem
										button
										divider={i < items.length - 1}
										key={item.id}
										onClick={() => {
											item.handleItemClick();
											handleClose();
										}}
									>
										<ListItemText primary={item.name} secondary={item.value} />
										<ListItemSecondaryAction>
											<IconButton
												edge="end"
												style={{ color: theme.palette.twind.gray[500] }}
												onClick={item.handleSettingsClick}
											>
												<SettingsIcon />
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								))}
							</MUIList>
							<Divider />
						</>
					)}

					<CardActionArea style={{ padding: "0.5rem" }} onClick={handleAddNew}>
						<Typography
							color="primary"
							variant="button"
							style={{
								display: "flex",
								alignItems: "center",
								fontSize: 13,
								padding: "0.25rem 0",
							}}
						>
							<AddIcon style={{ fontSize: 18, marginRight: "0.5rem" }} />
							{addNewText}
						</Typography>
					</CardActionArea>
				</div>
			</Popover>
		</Box>
	);
};
export default TextMenuButton;
