import React, { useState } from "react";
import {
	Card,
	CardHeader,
	CardContent,
	CardActions,
	Button,
	Divider,
	List as MUIList,
	ListItem,
	ListItemAvatar,
	ListItemText,
	IconButton,
	makeStyles,
} from "@material-ui/core";
import { formatDistanceToNow } from "date-fns";

/* icon imports */
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import MoreVertIcon from "@material-ui/icons/MoreVert";

const useStyles = makeStyles(() => ({
	root: {
		height: "100%",
	},
	content: {
		padding: 0,
	},
	image: {
		height: 48,
		width: 48,
	},
	actions: {
		justifyContent: "flex-end",
	},
}));

const mockData = [
	{
		id: 1,
		name: "Dropbox",
		imageUrl: "/images/products/product_1.png",
		updatedAt: new Date().getTime(),
	},
	{
		id: 2,
		name: "Medium Corporation",
		imageUrl: "/images/products/product_2.png",
		updatedAt: new Date().getTime(),
	},
	{
		id: 3,
		name: "Slack",
		imageUrl: "/images/products/product_3.png",
		updatedAt: new Date().getTime(),
	},
	{
		id: 4,
		name: "Lyft",
		imageUrl: "/images/products/product_4.png",
		updatedAt: new Date().getTime(),
	},
	{
		id: 5,
		name: "GitHub",
		imageUrl: "/images/products/product_5.png",
		updatedAt: new Date().getTime(),
	},
];

const List = (props) => {
	const {
		className,
		title,
		items,
		withFooter = false,
		renderHeader = null,
		renderFooter = null,
		...rest
	} = props;

	const classes = useStyles();

	const [products] = useState(mockData);

	return (
		<Card {...rest} className={`${classes.root}, ${className}`}>
			{renderHeader ? (
				renderHeader
			) : (
				<CardHeader
					// subtitle={`${products.length} in total`}
					title={title}
				/>
			)}
			<Divider />
			<CardContent className={classes.content}>
				<MUIList>
					{items.map((item, i) => (
						<ListItem
							divider={i < items.length - 1}
							key={item.id ? item.id : i}
						>
							{/* <ListItemAvatar>
                <img
                  alt="Product"
                  className={classes.image}
                  src={item.imageUrl}
                />
              </ListItemAvatar> */}
							<ListItemText
								primary={item.name}
								// secondary={`Updated ${formatDistanceToNow(
								//   new Date(item.updatedAt)
								// )} ago`}
								secondary={item.value}
							/>
							{/* <IconButton edge="end" size="small">
                <MoreVertIcon />
              </IconButton> */}
						</ListItem>
					))}
				</MUIList>
			</CardContent>
			{withFooter ? (
				renderFooter ? (
					renderFooter
				) : (
					<>
						<Divider />
						<CardActions className={classes.actions}>
							<Button color="primary" size="small" variant="text">
								View all <ArrowRightIcon />
							</Button>
						</CardActions>
					</>
				)
			) : null}
		</Card>
	);
};

export default List;
