import React from "react";
import { Container, makeStyles } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import * as _datefns from "date-fns";

/* local imports */
import DatasetSettings from "./components/DatasetSettings";

/* federated imports */
/* eslint-disable */
import { TitleBar } from "@/shared-components";
/* eslint-enable */

const useStyles = makeStyles(() => ({
	content: {
		marginTop: "2rem",
	},
}));

const DatasetDetails = () => {
	const classes = useStyles();
	const { id: datasetId } = useParams();
	const dataset = useSelector((state) =>
		state.user.datasets.find((d) => d.id === datasetId)
	);

	return (
		<>
			<TitleBar
				maxWidth="lg"
				renderTitle={() => <div>{`${dataset?.name}`}</div>}
				overline={`Last updated ${_datefns.formatDistanceToNow(
					new Date(dataset?.updated_at || null)
				)} ago`}
			/>
			<Container maxWidth="lg" className={classes.content}>
				<DatasetSettings />
			</Container>
		</>
	);
};

export default DatasetDetails;
