import React, { useEffect } from "react";
import L from "leaflet";
import { Typography } from "@material-ui/core";
import {
	useMap,
	GeoJSON,
	Popup,
	Tooltip,
	Marker,
	LayersControl,
} from "react-leaflet";

import MarkerClusterGroup from "react-leaflet-markercluster";
import "react-leaflet-markercluster/dist/styles.min.css";

import { Link } from "react-router-dom";

import { NoMapDataAvailable } from "../Leaflet";
import theme from "@/theme";
import "./legend.css";
import "./markerCluster.css";

const getODMap = (args) => {
	const { zones, districts } = args;

	if (!zones.length && !districts) {
		return <NoMapDataAvailable />;
	}

	const samplesArr = zones.map((z) => z.survey_count);
	const maxSamples = Math.max(...samplesArr);
	const swapCoords = (c) => [c[1], c[0]];
	const centroid = (points) => {
		const average = (arr) => arr.reduce((a, b) => a + b, 0) / arr.length;
		return [average(points.map((p) => p[0])), average(points.map((p) => p[1]))];
	};

	return (
		<>
			<LayersControl collapsed={false} position="topright">
				<LayersControl.Overlay name="Show remaining surveys">
					<MarkerClusterGroup showCoverageOnHover={true}>
						{zones
							.map((z, idx1) => {
								const coordinates =
									z.geometry.type == "MultiPolygon"
										? z.geometry.coordinates[0][0]
										: z.geometry.coordinates[0];
								return Array(
									Math.max(z.survey_count - z.submitted_survey_count, 0)
								)
									.fill(0)
									.map((_, idx2) => (
										<Marker
											key={`markerCluster-m${z.id}-${idx1}-${idx2}`}
											position={swapCoords(centroid(coordinates))}
										/>
									));
							})
							.flat()}
					</MarkerClusterGroup>
				</LayersControl.Overlay>
			</LayersControl>
			<Zones data={zones} maxSamples={maxSamples} />
			{districts && <Districts data={districts} />}
			<Legend />
		</>
	);
};

export default getODMap;

const getZoneColor = (completion) => {
	if (completion === 0) {
		// return theme.palette.ibmBlue[100];
		return theme.palette.twind.gray[500];
	}
	if (completion < 0.25) {
		// return theme.palette.ibmBlue[100];
		return "#FFAFD2";
	}
	if (completion < 0.5) {
		// return theme.palette.ibmBlue[300];
		return "#EE5396";
	}
	if (completion < 0.75) {
		// return theme.palette.ibmBlue[500];
		return "#D02670";
	}
	if (completion < 1) {
		// return theme.palette.ibmBlue[700];
		return "#740937";
	}
	if (completion >= 1) {
		return theme.palette.twind.green[500];
	}
};

const Zones = ({ data }) =>
	data.map((z, idx) => {
		const completion = z.submitted_survey_count / z.survey_count;
		return (
			<GeoJSON
				key={z.id || idx}
				style={() => ({
					color: getZoneColor(completion),
				})}
				data={z.geometry}
				// center={[z.geometry.coordinates[1], z.geometry.coordinates[0]]}
				// radius={getRadius(z.survey_count, maxSamples)}
				// color={"#3182CE"}
				//   fillOpacity={0.7}
				//   opacity={0.3}
				//   weight={35}
			>
				<Tooltip>
					<Typography variant="h5">
						{z.name ? z.name : z.name_local ? z.name_local : "-"}
					</Typography>
					<Typography variant="body2">
						{+z.submitted_survey_count} of {+z.survey_count} samples collected (
						{(completion * 100).toFixed(2)}%)
					</Typography>
				</Tooltip>
				<Popup>
					<div>
						<Typography variant="h4">
							{z.name ? z.name : z.name_local ? z.name_local : "-"}
						</Typography>
						<Typography variant="body2">
							{+z.submitted_survey_count} of {+z.survey_count} samples collected
							({(completion * 100).toFixed(2)}%)
						</Typography>
						<Link to={z.href}>View Details</Link>
					</div>
				</Popup>
			</GeoJSON>
		);
	});

const Districts = ({ data }) => (
	<GeoJSON
		data={data}
		style={() => ({
			color: "#CBD5E0",
			opacity: 0.8,
			fillOpacity: 0.4,
		})}
	/>
);
// const getRadius = (src, max) => {
//   const fraction = src / max;
//   const maxRadius = 20;
//   const minRadius = 3;
//   return minRadius + Math.floor(fraction * maxRadius);
// };

const Legend = () => {
	const map = useMap();

	useEffect(() => {
		const legend = L.control({ position: "bottomright" });

		legend.onAdd = () => {
			const div = L.DomUtil.create("div", "info legend");
			const grades = [-0.01, 0, 0.25, 0.5, 0.75, 1];
			let labels = [];
			let from;
			let to;

			for (let i = 0; i < grades.length; i++) {
				from = grades[i];
				to = grades[i + 1];

				labels.push(
					'<i style="background:' +
						getZoneColor(from + 0.01) +
						'"></i> ' +
						(i === 0 ? "None" : `${from * 100}`) +
						(i === 0 ? "" : to ? "&ndash;" + `${to * 100}%` : `%+`)
				);
			}

			div.innerHTML = labels.join("<br>");
			return div;
		};

		legend.addTo(map);
	}, [map]);
	return null;
};
