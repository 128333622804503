import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Typography, Link, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(4),
	},
}));

const Footer = (props) => {
	const { className, ...rest } = props;

	const classes = useStyles();

	return (
		<div {...rest} className={clsx(classes.root, className)}>
			<Typography variant="body1">
				{"Copyright © "}
				<Link color="inherit" href="https://transportforcairo.com/">
					Transport for Cairo
				</Link>{" "}
				{new Date().getFullYear()}
				{"."}
			</Typography>
			<Typography variant="caption">
				Made with{" "}
				<span role="img" aria-labelledby="heart">
					🖤
				</span>{" "}
				for the environment.
			</Typography>
		</div>
	);
};

Footer.propTypes = {
	className: PropTypes.string,
};

export default Footer;
