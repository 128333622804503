import React from "react";
import clsx from "clsx";
import { Paper, Input, makeStyles } from "@material-ui/core";

/* icon imports */
import SearchIcon from "@material-ui/icons/Search";

/* local imports */
import theme from "@/theme";
import Form from "./FormElements/Form";

const useStyles = makeStyles(() => ({
	root: {
		borderRadius: "4px",
		alignItems: "center",
		padding: "0.25rem",
		display: "flex",
		// width: 420,
		width: "18rem",
	},
	icon: {
		marginRight: theme.spacing(1),
		color: theme.palette.twind.gray[500],
	},
	input: {
		flexGrow: 1,
		fontSize: "14px",
		lineHeight: "16px",
		letterSpacing: "-0.05px",
	},
	disableFormStyles: {
		width: "inherit",
		marginTop: 0,
	},
}));
const SearchInput = (props) => {
	const {
		className,
		handleSubmit = () => {},
		onSubmit,
		InputProps,
		...rest
	} = props;

	const classes = useStyles();

	return (
		<Form
			onSubmit={handleSubmit(onSubmit)}
			className={classes.disableFormStyles}
		>
			<Paper {...rest} className={clsx(classes.root, className)}>
				<SearchIcon className={classes.icon} />
				<Input
					className={classes.input}
					name="search"
					disableUnderline
					{...InputProps}
				/>
			</Paper>
		</Form>
	);
};

export default SearchInput;
