import { applyMiddleware, createSlice } from "@reduxjs/toolkit";
import { axiosPublic } from "../../utils/api";
import { getUserData } from "./user";

const authSlice = createSlice({
	name: "auth",
	initialState: {
		isAuthenticated: false,
		accessToken: null,
	},
	reducers: {
		login: (state, action) => {
			state.isAuthenticated = true;
			state.accessToken = action.payload.access_token;
		},
		logout: (state) => {
			state.isAuthenticated = false;
			state.accessToken = null;
		},
	},
});

export const { login, logout } = authSlice.actions;

export const asyncLogin = (payload, cb) => async (dispatch) => {
	await axiosPublic
		.post("auth/login", payload)
		.then((res) => {
			console.log(res);
			dispatch(login(res.data));
			dispatch(getUserData());
			setTimeout(() => cb("SUCCESS", res.data), 100);
		})
		.catch((error) => {
			cb("FAILED", error.response.data);
		});
};

export const asyncRegister = (payload, cb) => async (dispatch) => {
	await axiosPublic
		.post("auth/register", payload)
		.then(({ data }) => {
			dispatch(login(data));
			dispatch(getUserData());
			setTimeout(() => cb("SUCCESS", data), 100);
		})
		.catch((error) => cb("FAILED", error.response.data));
};

export const asyncRefreshToken = () => async (dispatch) =>
	axiosPublic
		.post("/auth/refresh_token")
		.then((res) => {
			dispatch(login(res.data));
		})
		.catch((err) => {
			console.log(
				"Failed to refresh access token. Refresh token has expired.",
				err
			);
			dispatch(logout());
		});
const authReducer = authSlice.reducer;
export default authReducer;
