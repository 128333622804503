import React from "react";
import clsx from "clsx";
import MaterialTable from "material-table";
import * as _datefns from "date-fns";
import { useHistory, useParams } from "react-router-dom";

/* icon imports */
import tableIcons from "../../utils/materialTableIcons";

const UsersTable = (props) => {
	const history = useHistory();
	const { id: orgID } = useParams();
	const { users } = props;

	return (
		<MaterialTable
			title=""
			icons={tableIcons}
			columns={[
				{ field: "name", title: "User Name" },
				{
					field: "email",
					title: "Email",
				},
				{
					field: "updated_at",
					title: "Last Updated",
					// eslint-disable-next-line
					render: (rowData) =>
						`${_datefns.formatDistanceToNow(
							new Date(rowData?.updated_at || null)
						)} ago`,
				},
			]}
			actions={[
				{
					icon: tableIcons.Add,
					tooltip: "Add New User",
					isFreeAction: true,
					onClick: () =>
						history.push({ pathname: "/home/user/create", state: { orgID } }),
				},
			]}
			options={{
				search: false,
				actionsColumnIndex: 10,
				// exportButton: true,
				// searchFieldAlignment: "left",
			}}
			onRowClick={(e, rowData) => history.push(`/home/user/${rowData.id}`)}
			localization={{
				header: {
					actions: "",
				},
			}}
			data={users || []}
		/>
	);
};

export default UsersTable;
