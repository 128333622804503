import React from "react";
import {
	Button,
	FormControl,
	FormHelperText,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	useTheme,
} from "@material-ui/core";
import { Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
/* local imports */
import TwoColumnForm from "../shared/TwoColumnForm";
import InlineAlert from "../shared/InlineAlert";
import { useEntityList } from "../../utils";

/* federated imports */
/* eslint-disable */
import { axiosPrivate } from "../../utils/api";
import { Input } from "@/shared-components";
/* eslint-enable */

const CreateUser = (props) => {
	const history = useHistory();
	const theme = useTheme();
	const authToken = useSelector((state) => state.auth.accessToken);
	const location = useLocation();
	const { orgID } = location?.state || null;

	const { register, errors, control, handleSubmit, resourceURL, getValues } =
		props;

	const [isSubmitting, setIsSubmitting] = React.useState(false);
	const [result, setResult] = React.useState({ res: null, err: null });

	const { data: rolesList, isLoading: rolesLoading } = useEntityList({
		resource: "/acl/roles",
		params: {
			limit: "all",
			organization: orgID,
		},
	});

	const onSubmit = (data) => {
		const handleAdd = (url, config, cb = () => {}) =>
			axiosPrivate
				.post(url, config)
				.then((res) => {
					console.log("POST return :>> ", res.data.data);
					setResult((r) => ({ ...r, res: res.data.data }));
					setIsSubmitting(false);
					try {
						cb(data.data.user.id);
					} catch (err) {
						history.goBack();
					}
					setTimeout(() => {
						setResult((r) => ({ ...r, res: null }));
					}, 1500);
				})
				.catch((err) => {
					console.log("err :>> ", err.response.data);
					setResult((r) => ({ ...r, err: err.response.data }));
					setIsSubmitting(false);

					setTimeout(() => {
						setResult((r) => ({ ...r, err: null }));
					}, 3000);
				});

		const payload = {
			...data.user,
			organization: orgID,
		};
		const params = { organization: orgID };
		setIsSubmitting(true);
		handleAdd(resourceURL, { ...payload, params }, (userId) => {
			const submittedRoles = data.user.roles.filter((r) => r.length > 0);
			if (submittedRoles.length) {
				setIsSubmitting(true);
				handleAdd(`/acl/roles/users/${userId}`, {
					params,
					roles: submittedRoles,
				});
			}
		});
	};

	return (
		<>
			<TwoColumnForm
				title="Basics"
				subtitle="Manage user credentails"
				handleSubmit={handleSubmit}
				onSubmit={onSubmit}
				isLoading={rolesLoading}
				cardActions={() => (
					<>
						<Button
							variant="contained"
							color="primary"
							type="submit"
							disabled={isSubmitting}
						>
							Save
						</Button>
					</>
				)}
			>
				<InlineAlert type="success" showAlert={result.res}>
					{result.res?.message
						? result.res.message
						: "User created. Redirecting back to home."}
				</InlineAlert>
				<InlineAlert type="error" showAlert={result.err}>
					{result.err?.message
						? result.err.message
						: "An error occured while saving, please contact an Administrator."}
				</InlineAlert>
				<Grid container spacing={1}>
					<Grid item xs={12} md={7}>
						<Input
							ref={register({
								required: {
									value: true,
									message: "Don't forget to fill out your name!",
								},
							})}
							autoComplete="name"
							label="Name"
							name="user.name"
							type="text"
							margin="dense"
							error={!!errors?.user?.name}
							helperText={errors?.user?.name?.message}
						/>
					</Grid>
					<Grid item xs={12} md={5}>
						<Input
							ref={register({
								required: {
									value: true,
									message: "An email address is required",
								},
								pattern: {
									value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
									message:
										"Hmmm... that email address doesn't look quite right 🤔",
								},
							})}
							autoComplete="email"
							label="Work email"
							name="user.email"
							type="text"
							margin="dense"
							error={!!errors?.user?.email}
							helperText={errors?.user?.email?.message}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<Input
							ref={register({
								required: {
									value: true,
									message: "Password cannot be empty",
								},
								minLength: {
									value: 6,
									message: "Password should be at least 6 characters long",
								},
							})}
							autoComplete="password"
							label="Password"
							name="user.password"
							type="password"
							margin="dense"
							error={!!errors?.user?.password}
							helperText={errors?.user?.password?.message}
						/>
					</Grid>
					<Grid item xs={12} md={4}>
						<FormControl
							fullWidth
							variant="outlined"
							style={{ marginTop: theme.spacing(1) }}
							error={!!errors?.user?.roles}
						>
							<InputLabel id="role-select">Roles</InputLabel>
							<Controller
								as={
									<Select
										multiple
										fullWidth
										labelId="role-select"
										label="Role"
										margin="dense"
									>
										<MenuItem disabled value="none">
											Select Role(s)
										</MenuItem>
										{rolesList?.roles?.map((role) => (
											<MenuItem key={role.id} value={role.id}>
												{role.name}
											</MenuItem>
										))}
									</Select>
								}
								name="user.roles"
								defaultValue={[""]}
								control={control}
								rules={{
									required: {
										value: true,
									},
									validate: () => {
										return Boolean(
											getValues("user.roles").filter((r) => r.length > 0).length
										);
									},
								}}
							/>
							<FormHelperText>
								{!!errors?.user?.roles && "Must select at least one role"}
							</FormHelperText>
						</FormControl>
					</Grid>
				</Grid>
			</TwoColumnForm>
		</>
	);
};

export default CreateUser;
