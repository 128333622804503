import MuiButton from "./MuiButton";
import MuiIconButton from "./MuiIconButton";
import MuiPaper from "./MuiPaper";
import MuiTableCell from "./MuiTableCell";
import MuiTableHead from "./MuiTableHead";
import MuiTypography from "./MuiTypography";
import palette from "../palette";

export default {
	MuiCssBaseline: {
		"@global": {
			"@font-face": [
				'"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
			],
		},
	},
	MuiAppBar: {
		colorPrimary: {
			backgroundColor: palette.twind.gray[1000],
			borderBottom: `1px solid ${palette.twind.gray[800]}`,
		},
	},
	MuiButton,
	MuiIconButton,
	MuiPaper,
	MuiTableCell,
	MuiTableHead,
	MuiTypography,
};
