import { makeStyles } from "@material-ui/core";
import SideImage from "../../assets/map_vector.png";
import SidebarLogo from "../../assets/tfc_logo.png";

export const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.default,
		height: "100vh",
	},
	grid: {
		height: "100%",
	},
	quoteContainer: {
		[theme.breakpoints.down("md")]: {
			display: "none",
		},
	},
	quote: {
		backgroundColor: theme.palette.neutral,
		height: "100%",
		display: "flex",
		flexWrap: "wrap",
		justifyContent: "center",
		alignItems: "center",
		backgroundImage: `url(${SideImage})`,
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center",
	},
	quoteInner: {
		textAlign: "center",
		flexBasis: "600px",
		marginTop: "auto",
		padding: "0.5em",
	},
	quoteText: {
		color: theme.palette.white,
		fontWeight: 300,
		"&::before": {
			fontFamily: "Georgia, serif",
			fontSize: "250px",
			position: "absolute",
			fontStyle: "italic",
			opacity: "70%",
			margin: "0.15em 0",
			content: '"\\201C"',
		},
	},
	name: {
		marginTop: theme.spacing(3),
		color: theme.palette.white,
	},
	bio: {
		color: theme.palette.white,
	},
	contentContainer: {},
	content: {
		height: "100%",
		display: "flex",
		flexDirection: "column",
	},
	contentBody: {
		flexGrow: 1,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		// [theme.breakpoints.down("md")]: {
		//   justifyContent: "center",
		// },
	},
	form: {
		paddingLeft: 100,
		paddingRight: 100,
		paddingBottom: 125,
		flexBasis: 864,
		[theme.breakpoints.down("sm")]: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
		},
	},
	title: {
		marginTop: theme.spacing(3),
	},
	socialButtons: {
		marginTop: theme.spacing(3),
	},
	socialIcon: {
		marginRight: theme.spacing(1),
	},
	sugestion: {
		marginTop: theme.spacing(2),
	},
	textField: {
		marginTop: theme.spacing(2),
	},
	signInButton: {
		marginTop: theme.spacing(2),
	},
	sidebarLogoImage: {
		width: "250px",
		marginTop: "auto",
		marginBottom: "1em",
	},
}));
