const BASEMAPS = {
	OSM_DEFAULT: "osm_default",
	OSM_HOT: "osm_hot",
	CARTO: "cartodb",
	OSM_DEUTSCH: "osm_de",
	ESRI_WORLDIMG: "esri_worldimagery",
	ESRI_STREET: "esri_worldstreetmap",
	GOOGLE: "google",
};
const tileOptions = [
	{ id: BASEMAPS.OSM_DEFAULT, title: "OSM Default" },
	{ id: BASEMAPS.OSM_HOT, title: "OSM HOT" },
	{ id: BASEMAPS.CARTO, title: "OSM Carto" },
	{ id: BASEMAPS.OSM_DEUTSCH, title: "OSM Deutschland" },
	{ id: BASEMAPS.ESRI_STREET, title: "ESRI World Street Imagery" },
	{ id: BASEMAPS.ESRI_WORLDIMG, title: "ESRI Satellite Imagery" },
	{ id: BASEMAPS.GOOGLE, title: "Google" },
];

const getTileLayer = (baseMap) => {
	switch (baseMap) {
		case BASEMAPS.OSM_DEUTSCH:
			return {
				attribution:
					'&copy; <a href="http://openstreetmap.org">OSM</a>, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>',
				url: "https://{s}.tile.openstreetmap.de/tiles/osmde/{z}/{x}/{y}.png",
			};
		case BASEMAPS.OSM_HOT:
			return {
				attribution:
					'Tiles style by <a href="https://www.hotosm.org/" target="_blank">Humanitarian OpenStreetMap Team</a> hosted by <a href="https://openstreetmap.fr/" target="_blank">OpenStreetMap France</a>',
				url: "https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png",
			};
		case BASEMAPS.GOOGLE:
			return {
				attribution: "google",
				url: "http://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}",
			};
		case BASEMAPS.CARTO:
			return {
				attribution:
					"Map tiles by Carto, under CC BY 3.0. Data by OpenStreetMap, under ODbL.",
				url: "https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png",
			};
		case BASEMAPS.ESRI_WORLDIMG:
			return {
				attribution:
					"Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community",
				url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
			};
		case BASEMAPS.ESRI_STREET:
			return {
				attribution:
					"Tiles &copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), TomTom, 2012",
				url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}",
			};
		case BASEMAPS.OSM_DEFAULT:
		default:
			return {
				url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
				attribution:
					'&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
			};
	}
};

export { BASEMAPS, tileOptions, getTileLayer };
